import React from "react";
import styled from "styled-components";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";

const COLORS = {
  5: "#1B0076",
  4: "#493391",
  3: "#7666AD",
  2: "#A499C8",
  1: "#E8E5F1",
};

interface Item {
  score: number;
  label: string;
  percentage: number;
}

export interface Props {
  data: Item[];
}

export const RatingRanges: React.FC<Props> = ({ data }) => {
  const isMobile = useMobileMediaQuery();
  return (
    <Container>
      <RangesContainer>
        {data.map((item) => (
          <Range
            key={item.score}
            percentage={item.percentage}
            color={COLORS[item.score]}
          >
            {!isMobile && item.percentage > 0 && (
              <Percentage>{item.percentage}%</Percentage>
            )}
          </Range>
        ))}
      </RangesContainer>
      <ScoresContainer>
        {data.map((item) => (
          <Score key={item.score} color={COLORS[item.score]}>
            <div></div>
            <p>
              {item.label}
              {isMobile && <Percentage>({item.percentage}%)</Percentage>}
            </p>
          </Score>
        ))}
      </ScoresContainer>
    </Container>
  );
};

export const Component = RatingRanges;

interface ScoreProps {
  color: string;
}

const Score = styled.div<ScoreProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  div {
    background-color: ${({ color }) => color};
    height: 20px;
    width: 20px;
  }

  p {
    color: #1b0076;
  }

  ${({ theme }) => theme.media.mobile} {
    flex-basis: 50%;
  }
`;

const ScoresContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

interface RangeProps {
  percentage: number;
  color: string;
}

const Percentage = styled.span`
  color: #666666;
  font-size: 15px;
`;
const Range = styled.div<RangeProps>`
  position: relative;
  height: 50px;
  width: ${({ percentage }) => `${percentage}%`};
  background-color: ${({ color }) => color};

  ${Percentage} {
    position: absolute;
    bottom: -30px;
    width: 100%;
    text-align: center;
  }
`;
const RangesContainer = styled.div`
  display: flex;
  gap: 2px;
  margin-bottom: 50px;
`;

const Container = styled.div``;
