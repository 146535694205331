import { useEffect, useState } from "react";
import { ArtistInfo } from "../Components/SongsQuestion/types";
import { getArtistInfo } from "../firestore/getArtistInfo";

export const useArtistInfo = ({
  artistGroupId,
  artistName,
}: {
  artistGroupId: string;
  artistName: string;
}) => {
  const [artistInfo, setArtistInfo] = useState<ArtistInfo>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setArtistInfo(await getArtistInfo({ artistGroupId, artistName }));
      } catch (err) {
        console.error(
          "error calling setfan-artistinfo",
          (err as Error).message,
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [artistGroupId, artistName]);
  return { artistInfo, loading };
};
