import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import { AudienceIntegrationModal } from "Routes/Settings/Components/ArtistIntegrations/mailchimp/MailchimpModals";

interface Request {
  state: string;
  code: string;
}

interface Response {
  artistGroupId?: string;
}

export const Mailchimp = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const state = params.has("state") ? params.get("state") : null;
  const code = params.has("code") ? params.get("code") : null;

  useEffect(() => {
    httpsCallable<Request, Response>(
      getFunctions(),
      "oauth-mailchimp-callback",
    )({
      state,
      code,
    })
      .then(({ data }) => {
        const { artistGroupId } = data;
        let redirect = "/";
        if (artistGroupId) {
          const url = new URL(window.location.href);
          url.pathname = `/${artistGroupId}/settings`;
          url.search = "?mailchimpStatus=connected";
          redirect = url.href;
        }
        window.location.href = redirect;
      })
      .catch((error) => {
        console.error(error);
        handleError(state);
      });
  }, [code, state]);

  const handleError = (artistGroupId: string | null) => {
    if (!artistGroupId) {
      console.log("Missing artist group id, redirecting to root");
      window.location.pathname = "/";
      return;
    }
    console.log("Error connecting to mailchimp, redirecting to settings");
    setDoc(
      doc(
        getFirestore(),
        `artist_groups/${artistGroupId}/artist_group_private/mailchimp`,
      ),
      {
        syncStatus: "error",
      },
    );
    window.location.pathname = `/${artistGroupId}/settings`;
  };

  // dummy props. Will be replaced after redirect
  // NOTE: Do not pass actual artist group id here,
  // it is used to set the modal status to connecting to mailchimp
  return (
    <AudienceIntegrationModal
      artistGroupId=""
      artistName=""
      isOpen={true}
      onClose={() => {}}
      selectedList={null}
      setSelectedList={() => {}}
    />
  );
};
