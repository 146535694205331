import { doc, getFirestore, setDoc } from "firebase/firestore";
import { ConcertData } from "hooks/useUpcomingConcerts";
import { useState } from "react";
import { ConcertDetails } from "Routes/SetLive/Duplicate/types";
import { getEvent } from "../utils";
import { publishBuilderEvent } from "./publishBuilderEvent";
import { softDeleteConcert } from "./softDeleteConcert";
import { updateBuilderEventFields } from "./updateBuilderEventFields";
import { BuilderEventData } from "./useSetLiveBuilderEvents";

interface Options {
  artistId: string;
  events?: BuilderEventData[];
  onSuccess?: () => void;
}

interface ExecuteOptions {
  concert: ConcertData;
  replacement: ConcertDetails<Date> | ConcertData;
}

export const useReplaceConcert = ({
  artistId,
  events = [],
  onSuccess,
}: Options) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const withExistingConcert = async (
    concert: ConcertData,
    replacement: ConcertData,
  ) => {
    try {
      setLoading(true);

      await setDoc(replacement.ref, replacement, { merge: true });

      const replacementEvent = getEvent(replacement, events);

      if (replacementEvent) {
        await publishBuilderEvent(replacementEvent, "deleted");
      }

      const event = getEvent(concert, events);

      if (event) {
        await updateBuilderEventFields(replacement.ref, event);

        if (event.publishedId) {
          await publishBuilderEvent(event, "published");
        }
      }

      await softDeleteConcert(concert);

      onSuccess?.();
    } catch (e) {
      console.log("#withExistingConcert: ", e);
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const withNewConcert = async (
    concert: ConcertData,
    replacement: ConcertDetails<Date>,
  ) => {
    try {
      setLoading(true);

      const nextConcertRef = doc(
        getFirestore(),
        "artist_groups",
        artistId,
        "concerts",
        replacement.id,
      );

      await setDoc(nextConcertRef, replacement);

      const event = getEvent(concert, events);

      if (event) {
        await updateBuilderEventFields(nextConcertRef, event);

        if (event.publishedId) {
          await publishBuilderEvent(event, "published");
        }
      }

      await softDeleteConcert(concert);

      onSuccess?.();
    } catch (e) {
      console.log("#withNewConcert: ", e);
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const execute = async ({ concert, replacement }: ExecuteOptions) => {
    if ("ref" in replacement) {
      await withExistingConcert(concert, replacement);
    } else {
      await withNewConcert(concert, replacement);
    }
  };

  return { execute, loading, error } as const;
};
