import { LayoutProps } from "./types";

export const AngularLayout = ({
  image,
  backgroundColor,
  buttonColor,
  width,
  height,
  onClick,
}: LayoutProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width ?? 163}
    height={height ?? 352}
    viewBox="0 0 163 352"
    fill="none"
    onClick={onClick}
  >
    <g clipPath="url(#angular-layout_svg__a)">
      <rect
        width={162.666}
        height={351.608}
        fill={backgroundColor}
        rx={8.342}
      />
      <path
        fill="url(#angular-layout_svg__b)"
        d="M9.177 12.93h145.148v122.625H9.177z"
      />
      <path
        fill="#fff"
        d="M9.177 121.374h145.148v48.8H9.177zM9.177 178.515h145.148v161.831H9.177z"
      />
      <path fill={buttonColor} d="M22.523 310.733h119.288v18.352H22.523z" />
      <path
        fill={backgroundColor}
        d="m148.068 334.09 6.256-6.256v12.513h-12.512z"
      />
      <path fill="#fff" d="m25.444 313.653-2.92 2.919v-5.839h5.84z" />
      <path
        fill={backgroundColor}
        d="m148.068 163.917 6.256-6.256v12.513h-12.512z"
      />
      <path fill="#fff" d="m138.892 326.165 2.92-2.919v5.839h-5.839z" />
      <rect width={55} height={7} x={22} y={132} fill="#D9D9D9" rx={1.5} />
      <rect width={42} height={5} x={22} y={153} fill="#D9D9D9" rx={2.5} />
      <rect width={121} height={5} x={22} y={146} fill="#D9D9D9" rx={2.5} />
      <rect width={120} height={6} x={22} y={191} fill="#D9D9D9" rx={1.5} />
      <rect width={45} height={6} x={22} y={200} fill="#D9D9D9" rx={1.5} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M26.234 222.673c1.751 0 3.17-1.493 3.17-3.336S27.985 216 26.234 216s-3.17 1.494-3.17 3.337 1.42 3.336 3.17 3.336m0-.417c1.532 0 2.774-1.307 2.774-2.919s-1.242-2.92-2.774-2.92-2.773 1.307-2.773 2.92 1.242 2.919 2.773 2.919M26.33 241.443c1.793 0 3.247-1.494 3.247-3.337s-1.454-3.336-3.247-3.336-3.247 1.493-3.247 3.336 1.454 3.337 3.247 3.337m0-.417c1.57 0 2.841-1.307 2.841-2.92s-1.272-2.919-2.84-2.919c-1.57 0-2.842 1.307-2.842 2.919s1.272 2.92 2.841 2.92"
        clipRule="evenodd"
      />
      <ellipse cx={26.33} cy={238.107} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M26.33 260.212c1.793 0 3.247-1.494 3.247-3.337s-1.454-3.337-3.247-3.337-3.247 1.494-3.247 3.337 1.454 3.337 3.247 3.337m0-.418c1.568 0 2.84-1.307 2.84-2.919s-1.272-2.92-2.84-2.92c-1.57 0-2.842 1.307-2.842 2.92s1.272 2.919 2.841 2.919"
        clipRule="evenodd"
      />
      <ellipse cx={26.33} cy={256.875} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M26.33 278.98c1.793 0 3.247-1.494 3.247-3.337 0-1.842-1.454-3.336-3.247-3.336s-3.247 1.494-3.247 3.336c0 1.843 1.454 3.337 3.247 3.337m0-.417c1.57 0 2.841-1.307 2.841-2.92s-1.272-2.919-2.84-2.919c-1.57 0-2.842 1.307-2.842 2.919s1.272 2.92 2.841 2.92"
        clipRule="evenodd"
      />
      <ellipse cx={26.33} cy={275.644} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M26.33 297.748c1.793 0 3.247-1.494 3.247-3.337s-1.454-3.337-3.247-3.337-3.247 1.494-3.247 3.337 1.454 3.337 3.247 3.337m0-.417c1.57 0 2.841-1.308 2.841-2.92 0-1.613-1.272-2.92-2.84-2.92-1.57 0-2.842 1.307-2.842 2.92s1.272 2.92 2.841 2.92"
        clipRule="evenodd"
      />
      <ellipse cx={26.33} cy={294.411} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <rect width={27} height={5} x={34} y={216.363} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={34} y={235.363} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={34} y={254.363} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={34} y={273.363} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={34} y={291.363} fill="#D9D9D9" rx={2.5} />
      <rect width={30} height={6} x={66} y={316} fill="#fff" rx={3} />
    </g>
    <rect
      width={161.666}
      height={350.608}
      x={0.5}
      y={0.5}
      stroke="#CDCDCD"
      rx={7.842}
    />
    <defs>
      <clipPath id="angular-layout_svg__a">
        <rect width={162.666} height={351.608} fill="#fff" rx={8.342} />
      </clipPath>
      <pattern
        id="angular-layout_svg__b"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use
          xlinkHref="#angular-layout_svg__c"
          transform="matrix(.00315 0 0 .00373 -.118 0)"
        />
      </pattern>
      <image
        href={image}
        id="angular-layout_svg__c"
        width={392}
        height={268}
        preserveAspectRatio="xMidYMid slice"
      />
    </defs>
  </svg>
);
