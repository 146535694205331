import * as React from "react";
import { SVGProps } from "react";
export const SvgAccordionOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.4571068,6.54289322 C16.6306731,6.71645957 16.6499583,6.98588397 16.5149622,7.18075211 L16.4571068,7.25 L10.1035534,13.6035534 L3.75,7.25 C3.55473785,7.05473785 3.55473785,6.73815536 3.75,6.54289322 C3.92356635,6.36932687 4.19299075,6.35004172 4.38785889,6.48503777 L4.45710678,6.54289322 L10.1035534,12.1894466 L15.75,6.54289322 C15.9235664,6.36932687 16.1929908,6.35004172 16.3878589,6.48503777 L16.4571068,6.54289322 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
