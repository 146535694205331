import type { Timestamp as BaseTimestamp } from "firebase/firestore";

export type Timestamp = Omit<BaseTimestamp, "toJSON">;

export const ArtistSignupSteps = [
  "email",
  "artistSearch",
  "artistConfirm",
  "artistAffiliation",
  "contactInfo",
  "additionalInfo",
  "verificationSent",
  "enterPhone",
  "verifyPhone",
  "accountCreated",
] as const;

export const AffiliationMap = {
  artist: "Artist",
  "management-company": "Management Rep",
  marketing: "Marketing Rep",
  label: "Label Rep",
  crew: "Tour Crew Rep",
  other: "Other",
} as const;

export type Affiliation = keyof typeof AffiliationMap;
export type AffiliationDisplay = (typeof AffiliationMap)[Affiliation];

export type ArtistSignupStep = (typeof ArtistSignupSteps)[number];

export const ArtistSizes = ["tiny", "small", "medium", "large"] as const;
export type ArtistSize = (typeof ArtistSizes)[number];

export const ArtistGroupRoles = ["artist", "admin", "manager"] as const;
export type ArtistGroupRole = (typeof ArtistGroupRoles)[number];

export interface ArtistSignup {
  uid: string;

  email?: string;
  phone?: string;
  emailVerified?: boolean;
  phoneVerified?: boolean;

  firstName?: string;
  lastName?: string;
  affiliation?: Affiliation;
  companyName?: string;
  title?: string;

  companyLink?: string;
  profileLink?: string;
  workAndPlan?: string;

  steps?: ArtistSignupStep[];

  artist?: {
    avatarSrc: string;
    label: string;
  };

  spotifyArtistId?: string;
  size?: ArtistSize;

  artistName?: string;
  artistImage?: string;

  action: "new" | "add-user" | "request-access";
  status: "incomplete" | "submitted" | "approved" | "rejected";

  reviewedBy?: string;
  reviewedAt?: Timestamp;
  lastEmailSentAt?: Timestamp

  createdAt: Timestamp;
}

export const AccountRequestActions = [
  "init",
  "verify",
  "artist",
  "password",
  "add-user",
  "request-access",
  "send-access-email",
] as const;
export type AccountAction = (typeof AccountRequestActions)[number];

export const AccountRequestAccessRequestStatues = [
  "approve",
  "reject",
] as const;
export type AccountRequestAccessRequestStatus =
  (typeof AccountRequestAccessRequestStatues)[number];

export interface AccountRequestAccessRequest {
  action: Extract<AccountAction, "request-access">;
  signupId: string;
  status: AccountRequestAccessRequestStatus;
  reason?: string;
  admin?: boolean;
}

export type AccountRequestAccessReponse = {
  status: "ok" | "error";
  message?: string;
};
