import React from "react";
import styled from "styled-components";

import { Spinner } from "melodies-source/Spinner";
import { Body1, H3 } from "melodies-source/Text";
import { RefreshIcon } from "Components/RefreshIcon";
import { formatNumber } from "Utils/format";

interface Props {
  label: string;
  total: number;
  loading: boolean;
  error: boolean;
}

export const Item: React.FC<Props> = ({ label, total, loading, error }) => {
  return (
    <Container>
      {loading ? (
        <StyledSpinner />
      ) : error ? (
        <StyledRefreshIcon />
      ) : (
        <Total>{formatNumber(total)}</Total>
      )}
      <Label>{label}</Label>
    </Container>
  );
};

const StyledRefreshIcon = styled(RefreshIcon)`
  color: var(--main-color);
`;

const StyledSpinner = styled(Spinner)`
  margin: 10px 0 15px;
  transform: scale(0.7);
`;

const Label = styled(Body1)`
  color: var(--secondary-text-color);
  white-space: nowrap;
`;

const Total = styled(H3)`
  color: var(--main-color);
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 1 1 200px;
`;
