import * as React from "react";
import { SVGProps } from "react";
export const SvgDragLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5,18 C10.3284271,18 11,18.6715729 11,19.5 C11,20.3284271 10.3284271,21 9.5,21 C8.67157288,21 8,20.3284271 8,19.5 C8,18.6715729 8.67157288,18 9.5,18 Z M14.5,18 C15.3284271,18 16,18.6715729 16,19.5 C16,20.3284271 15.3284271,21 14.5,21 C13.6715729,21 13,20.3284271 13,19.5 C13,18.6715729 13.6715729,18 14.5,18 Z M9.5,13 C10.3284271,13 11,13.6715729 11,14.5 C11,15.3284271 10.3284271,16 9.5,16 C8.67157288,16 8,15.3284271 8,14.5 C8,13.6715729 8.67157288,13 9.5,13 Z M14.5,13 C15.3284271,13 16,13.6715729 16,14.5 C16,15.3284271 15.3284271,16 14.5,16 C13.6715729,16 13,15.3284271 13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z M9.5,8 C10.3284271,8 11,8.67157288 11,9.5 C11,10.3284271 10.3284271,11 9.5,11 C8.67157288,11 8,10.3284271 8,9.5 C8,8.67157288 8.67157288,8 9.5,8 Z M14.5,8 C15.3284271,8 16,8.67157288 16,9.5 C16,10.3284271 15.3284271,11 14.5,11 C13.6715729,11 13,10.3284271 13,9.5 C13,8.67157288 13.6715729,8 14.5,8 Z M9.5,3 C10.3284271,3 11,3.67157288 11,4.5 C11,5.32842712 10.3284271,6 9.5,6 C8.67157288,6 8,5.32842712 8,4.5 C8,3.67157288 8.67157288,3 9.5,3 Z M14.5,3 C15.3284271,3 16,3.67157288 16,4.5 C16,5.32842712 15.3284271,6 14.5,6 C13.6715729,6 13,5.32842712 13,4.5 C13,3.67157288 13.6715729,3 14.5,3 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
