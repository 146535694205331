import styled from "styled-components";

export const ActionText = styled.span`
  color: #1b0076;
  font-weight: 600;
  font-family: Poppins;
  font-size: 16px;
  text-decoration-line: underline;
  cursor: pointer;
`;
