import { useDocumentData } from "react-firebase-hooks/firestore";
import { SettingsRowHeader } from "../SettingsRow";
import styled from "styled-components";
import MailchimpIntegration from "./mailchimp/MailchimpIntegration";
import KlaviyoIntegration from "./klaviyo/KlaviyoIntegration";
import CommunityIntegration from "./community/CommunityIntegration";
import UMGIntegration from "./umg/UMGIntegration";
import LayloIntegration from "./laylo/LayloIntegration";
import StripeIntegration from "./stripe/StripeIntegration";
import { UMGDocument } from "./umg/types";
import { doc, getFirestore } from "firebase/firestore";
import { useArtistContext } from "Components";

const ArtistIntegrations = () => {
  const { id: artistGroupId } = useArtistContext();

  const [umgDoc] = useDocumentData<UMGDocument>(
    doc(
      getFirestore(),
      `artist_groups/${artistGroupId}/artist_group_private/umg`,
    ) as any,
  );

  return (
    <ArtistIntegrationsContainer>
      <SettingsRowHeader>Integrations</SettingsRowHeader>
      <IntegrationsContainer>
        <StripeIntegration />
        <KlaviyoIntegration />
        <CommunityIntegration />
        <MailchimpIntegration />
        <LayloIntegration />
        {umgDoc?.uiVisible && <UMGIntegration umgDoc={umgDoc} />}
      </IntegrationsContainer>
    </ArtistIntegrationsContainer>
  );
};

export default ArtistIntegrations;

const ArtistIntegrationsContainer = styled.section`
  padding: 16px 0;
`;

const IntegrationsContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
`;
