import { ConcertData } from "hooks/useUpcomingConcerts";
import { useState } from "react";
import { BulkForm } from "Routes/SetLive/Duplicate/BulkForm";
import { Controls } from "Routes/SetLive/Duplicate/Controls";
import { SingleForm } from "Routes/SetLive/Duplicate/SingleForm";
import { ConcertDetails } from "Routes/SetLive/Duplicate/types";
import { getDateTime } from "Utils/date";

const omit = ({ locationAddedManually, ...concert }: ConcertDetails<Date>) =>
  concert;

interface AddConcertProps {
  concerts?: ConcertData[];
  onCancel?: () => void;
  onSubmit?: (v: ConcertDetails<Date>) => void;
  onBulkSubmit?: (v: ConcertDetails<Date>[]) => void;
}

export const AddConcert = ({
  concerts = [],
  onCancel,
  onSubmit,
  onBulkSubmit,
}: AddConcertProps) => {
  const [single, setIsSingle] = useState(true);
  const controls = <Controls single={single} onSingleChange={setIsSingle} />;
  const upcoming = concerts.map(({ date }) => ({ date: getDateTime(date) }));

  return single ? (
    <SingleForm
      controls={controls}
      onCancel={onCancel}
      onCreate={(v) => onSubmit?.(omit(v))}
    />
  ) : (
    <BulkForm
      upcoming={upcoming}
      controls={controls}
      onCancel={onCancel}
      onSubmit={(v) => onBulkSubmit?.(v.map(omit))}
    />
  );
};
