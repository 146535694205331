import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { doc, getFirestore } from "firebase/firestore";
import { useArtistContext } from "Components";
import IntegrationCard from "../IntegrationCard";
import klaviyoHeader from "./klaviyo-header.png";
import klaviyoButton from "./klaviyo-button.png";
import styled, { CSSProperties } from "styled-components";
import firebase from "firebase/compat/app";
import { DateTime } from "luxon";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Button } from "melodies-source/Button";
import { useDesktopMediaQuery } from "hooks";
import { KlaviyoIntegrationStatus, KlaviyoDocument } from "./types";
import { KlaviyoIntegrationModal, KlaviyoRemovalModal } from "./KlaviyoModals";
import LastSyncIcon from "../LastSyncIcon";

const KlaviyoIntegration = ({ sx }: { sx?: CSSProperties }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [integrationStatus, setIntegrationStatus] =
    useState<KlaviyoIntegrationStatus>(KlaviyoIntegrationStatus.NOT_CONNECTED);
  const [removalModalOpen, setRemovalModalOpen] = useState(false);

  const { id: artistGroupId } = useArtistContext();

  const [klaviyoDoc] = useDocumentData<KlaviyoDocument>(
    doc(
      getFirestore(),
      `artist_groups/${artistGroupId}/artist_group_private/klaviyo`,
    ),
  );

  const lastSynced: DateTime | undefined = klaviyoDoc?.lastSynced
    ? DateTime.fromMillis(klaviyoDoc.lastSynced.toMillis())
    : undefined;

  useEffect(() => {
    if (!!klaviyoDoc?.secretPath) {
      setIntegrationStatus(KlaviyoIntegrationStatus.CONNECTED);
    } else {
      setIntegrationStatus(KlaviyoIntegrationStatus.NOT_CONNECTED);
    }
  }, [klaviyoDoc]);

  return (
    <>
      <KlaviyoIntegrationModal
        artistGroupId={artistGroupId}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <KlaviyoRemovalModal
        artistGroupId={artistGroupId}
        onClose={() => {
          setRemovalModalOpen(false);
          setIntegrationStatus(KlaviyoIntegrationStatus.NOT_CONNECTED);
        }}
        isOpen={removalModalOpen}
      />
      <KlaviyoIntegrationCard
        integrationStatus={integrationStatus}
        lastSynced={lastSynced}
        onRemoveKlaviyo={() => setRemovalModalOpen(true)}
        setModalOpen={setModalOpen}
        sx={sx}
      />
    </>
  );
};

export default KlaviyoIntegration;

const KlaviyoIntegrationCard = ({
  integrationStatus,
  lastSynced,
  onRemoveKlaviyo,
  setModalOpen,
  sx,
}: {
  integrationStatus: KlaviyoIntegrationStatus;
  lastSynced?: DateTime;
  onRemoveKlaviyo: () => void;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  sx?: CSSProperties;
}) => {
  const isDesktop = useDesktopMediaQuery();

  if (integrationStatus === KlaviyoIntegrationStatus.CONNECTED) {
    return (
      <IntegrationCard
        header={<img src={klaviyoHeader} alt="klaviyo logo" />}
        body={
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <LastSyncIcon />
              <StatusText isDesktop={isDesktop}>
                Last Sync Time:{" "}
                {lastSynced
                  ? lastSynced.toLocaleString(DateTime.TIME_SIMPLE)
                  : "Never Synced"}
              </StatusText>
            </div>
            <BodyText>You're connected to Klaviyo!</BodyText>
          </>
        }
        footer={
          <FooterText
            style={{ marginTop: 9, textAlign: "center" }}
            onClick={onRemoveKlaviyo}
          >
            Remove Klaviyo
          </FooterText>
        }
        sx={sx}
      />
    );
  }

  // klaviyo is not yet connected
  return (
    <IntegrationCard
      header={<img src={klaviyoHeader} alt="klaviyo logo" />}
      footer={
        <Button onClick={() => setModalOpen(true)}>
          <span style={{ marginRight: "0.35rem" }}>Connect to</span>
          <img src={klaviyoButton} height={24} alt="connect to klaviyo" />
        </Button>
      }
      body="SET can automatically sync your contacts to your Klaviyo account. Click the button below to get started."
      sx={sx}
    />
  );
};

const BodyText = styled.div`
  font-size: 10px;
  line-height: 170%;
  font-weight: 400;
  text-align: center;
  color: #666;
`;

const FooterText = styled.div`
  color: #180171;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0 auto;
  padding-bottom: 5px;
  cursor: pointer;
  bottom: 0;
`;

const StatusText = styled.div<{ isDesktop: boolean }>`
  color: #635bff;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 12px)" : "13px")};
  line-height: 19.5px;
  margin-left: 0.25rem;
`;
