import { pluralize } from "Utils/pluralize";
import { FieldArray, Form, Formik } from "formik";
import Fuse from "fuse.js";
import { Button } from "melodies-source/Button";
import { SvgSearchAlt } from "melodies-source/Svgs/SearchAlt";
import { Body2 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { useMemo, useState } from "react";
import { SvgCloseLarge } from "svg/CloseLarge";
import { SongRow } from "./SongRow";
import { SortableList } from "./SortableList";
import {
  Section,
  SectionFooter,
  SectionHeader,
  SectionList,
  SectionTitle,
  StickyModalFooter,
} from "./components";
import { Album, Song } from "./types";

const FUSE_OPTIONS: Fuse.IFuseOptions<SongWithIndexes> = {
  isCaseSensitive: false,
  includeScore: true,
  includeMatches: true,
  ignoreLocation: true,
  shouldSort: true,
  threshold: 0.3,
  keys: ["name", "album", "artist"],
};

interface SongWithIndexes extends Song {
  albumIndex: number;
  songIndex: number;
}

interface AllSongsFormValues {
  songs: SongWithIndexes[];
}

interface AllSongsFormProps {
  albums: Album[];
  onCancel?: () => void;
  onSubmit?: (albums: Album[]) => void;
}

export const AllSongsForm = ({
  albums,
  onCancel,
  onSubmit,
}: AllSongsFormProps) => {
  const [searchText, setSearchText] = useState("");

  const allSongs = useMemo(
    () =>
      structuredClone(albums)
        .map((album, albumIndex) =>
          album.songs.map((song, songIndex) => ({
            ...song,
            albumIndex,
            songIndex,
          })),
        )
        .flat()
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)),
    [],
  );

  const handleSubmit = (values: AllSongsFormValues) => {
    onSubmit?.(
      albums.map((album, index) => ({
        ...album,
        visible: !!values.songs.filter(
          ({ albumIndex, visible }) => albumIndex === index && visible,
        ).length,
        songs: values.songs
          .filter(({ albumIndex }) => albumIndex === index)
          .sort((a, b) => a.songIndex - b.songIndex)
          .map(({ albumIndex, songIndex, ...song }) => song),
      })),
    );
  };

  return (
    <Formik<AllSongsFormValues>
      initialValues={{ songs: allSongs }}
      validationSchema={null}
      onSubmit={handleSubmit}
    >
      {({ values }) => {
        const selected = values.songs.filter(({ visible }) => visible).length;
        const fuse = new Fuse<SongWithIndexes>(values.songs, FUSE_OPTIONS);

        return (
          <Form>
            <SectionList>
              <Section>
                <SectionHeader
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <SectionTitle>
                    <SvgSearchAlt /> Search for Tracks
                  </SectionTitle>
                  <Body2>
                    {selected} of {pluralize(values.songs.length, "Track")}{" "}
                    Selected
                  </Body2>
                </SectionHeader>
                <TextInput
                  leftIcon={<SvgSearchAlt />}
                  rightIcon={
                    searchText && (
                      <SvgCloseLarge
                        style={{ cursor: "pointer" }}
                        onClick={() => setSearchText("")}
                      />
                    )
                  }
                  placeholder="Type Track Name..."
                  value={searchText}
                  onChange={(v) => setSearchText(v)}
                />
                <FieldArray name="songs">
                  {(helpers) => (
                    <SortableList id="all-songs" helpers={helpers}>
                      {searchText
                        ? fuse
                            .search(searchText)
                            .map((value) => (
                              <SongRow
                                key={value.refIndex}
                                index={value.refIndex}
                                song={value.item}
                                draggable={false}
                                helpers={helpers}
                              />
                            ))
                        : values.songs.map((song, index) => (
                            <SongRow
                              key={index}
                              index={index}
                              song={song}
                              draggable={false}
                              helpers={helpers}
                            />
                          ))}
                    </SortableList>
                  )}
                </FieldArray>
              </Section>
              <StickyModalFooter>
                <SectionFooter>
                  <Button variant="tertiary" type="button" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button type="submit" style={{ width: 140 }}>
                    Save
                  </Button>
                </SectionFooter>
              </StickyModalFooter>
            </SectionList>
          </Form>
        );
      }}
    </Formik>
  );
};
