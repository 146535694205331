import * as React from "react";
import { SVGProps } from "react";
export const SvgLockAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15,9 C15.5522847,9 16,9.44771525 16,10 L16,17 C16,17.5522847 15.5522847,18 15,18 L5,18 C4.44771525,18 4,17.5522847 4,17 L4,10 C4,9.44771525 4.44771525,9 5,9 L15,9 Z M10,2 C12.209139,2 14,3.790861 14,6 L14,8 L13,8 L13,6 C13,4.34314575 11.6568542,3 10,3 C8.34314575,3 7,4.34314575 7,6 L7,8 L6,8 L6,6 C6,3.790861 7.790861,2 10,2 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
