import * as React from "react";
import { SVGProps } from "react";
export const SvgLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.80131412,3.20970461 L9.70710678,3.29289322 L3,10 L9.70710678,16.7071068 C10.0976311,17.0976311 10.7307961,17.0976311 11.1213203,16.7071068 C11.4818043,16.3466228 11.5095338,15.7793918 11.204509,15.3871006 L11.1213203,15.2928932 L6.83021356,11 L17.0042136,11 L17.0042136,9 L6.82821356,9 L11.1213203,4.70710678 C11.4818043,4.34662282 11.5095338,3.77939176 11.204509,3.38710056 L11.1213203,3.29289322 C10.7608364,2.93240926 10.1936053,2.90467972 9.80131412,3.20970461 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
