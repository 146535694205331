import styled from "styled-components";
import Modal from "../modal";
import { Button } from "melodies-source/Button";
import { useState } from "react";
import { useDesktopMediaQuery } from "hooks";
import { CircularLoader } from "Components";
import { httpsCallable, getFunctions } from "firebase/functions";
import { TextInput } from "melodies-source/TextInput";
import { Select } from "melodies-source/Select";
import { Textarea } from "melodies-source/Textarea";
import { Checkbox } from "melodies-source/Selectable";
import { doc, setDoc, getFirestore, updateDoc, deleteField } from "firebase/firestore";
import { CommunityDocument } from "./types";
import { Spinner } from "melodies-source/Spinner";

export const CommunityIntegrationModal = ({
  artistGroupId,
  isOpen,
  onClose,
  communityDoc,
}: {
  artistGroupId: string;
  isOpen: boolean;
  onClose: () => void;
  communityDoc?: CommunityDocument;
}) => {
  const [status, setStatus] = useState<
    "submitRequest" | "submitting" | "requestSubmitted" | "apiKeyAdded"
  >("submitRequest");
  const [communityAccountName, setCommunityAccountName] = useState("");
  const [haveAccess, setHaveAccess] = useState(true);
  const [backFillRequested, setBackFillRequested] = useState(true);
  const [customizedWelcomeText, setCustomizedWelcomeText] = useState("");
  const [opt, setOpt] = useState(true);

  const isDesktop = useDesktopMediaQuery();

  const ref = doc(
    getFirestore(),
    `artist_groups/${artistGroupId}/artist_group_private/community`,
  );

  const onSubmit = async () => {
    setStatus("submitting");
    await httpsCallable(
      getFunctions(),
      "services-communityActions",
    )({
      action: "submitRequest",
      artistGroupId,
      communityAccountName,
      haveAccess,
      backFillRequested,
      customizedWelcomeText,
      opt,
    });
    setStatus("requestSubmitted");

    await setDoc(
      ref,
      {
        requestedAt: new Date(),
      },
      { merge: true },
    );
  };

  const onSubmitCancelRequest = async () => {
    await updateDoc(ref, {
      capital: deleteField(),
      requestedBy: deleteField(),
      requestedByName: deleteField(),
      requestedByEmail: deleteField(),
      requestedAt: deleteField(),
      requestedForArtistGroupName: deleteField(),
      communityAccountName: deleteField(),
      haveAccess: deleteField(),
      backFillRequested: deleteField(),
      opt: deleteField(),
    });
  }

  const requestSubmitted = !!communityDoc?.requestedAt;

  return (
    <Modal isOpen={isOpen} onClose={onClose} service="community">
      <>
        <BodyText isDesktop={isDesktop}>
          To integrate with the Community, approval is needed. Clicking the
          button below will submit a request to Community to enable the
          integration
        </BodyText>
        <br />
        <InputLabel isDesktop={isDesktop}>Community Account Name</InputLabel>
        <TextInput
          placeholder="This is the Artist's name. Not your personal name"
          value={communityAccountName}
          onChange={(text) => setCommunityAccountName(text)}
        />
        <br />
        <InputLabel isDesktop={isDesktop}>
          Do you personally have access to the Community account?
        </InputLabel>
        <Select
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
          ]}
          value={haveAccess ? "yes" : "no"}
          onChange={(val) => setHaveAccess(val === "yes")}
        />
        <br />
        <InputLabel isDesktop={isDesktop}>
          Do you want to backfill existing contacts?
        </InputLabel>
        <Select
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
          ]}
          value={backFillRequested ? "yes" : "no"}
          onChange={(val) => setBackFillRequested(val === "yes")}
        />
        <br />
        <InputLabel isDesktop={isDesktop}>Customized Welcome Text</InputLabel>
        <Textarea
          value={customizedWelcomeText}
          onChange={setCustomizedWelcomeText}
          style={{ width: "100%" }}
          placeholder='(Optional) If left blank, the message will default to "Hey it&apos;s [Artist Name]! Thanks for joining my Community! [Required Legal Terms]"'
        />
        <br />
        <Checkbox
          style={{ textAlign: "left" }}
          label="By checking this box the above named artist and artist representative agree that they will use the Community API with SET.Live and allow Community to generate and manage the Community API access token on behalf of the Community Leader."
          value={opt}
          onChange={() => setOpt(!opt)}
        />
        <br />
        <FooterText isDesktop={isDesktop}>
          Not already using Community?{" "}
          <a href="https://www.community.com/setlive" target="__blank">
            Click here
          </a>{" "}
          to learn more.
        </FooterText>
        <br />
        <div style={{ display: "flex", justifyContent: "space-between", "gap": "10px" }}>
          <StyledButton onClick={onSubmit} disabled={requestSubmitted || !opt}>
            {requestSubmitted ? "Request Submitted" : status === "submitting" ? <Spinner /> : "Submit Request"}
          </StyledButton>
          <StyledButton onClick={onSubmitCancelRequest} disabled={!requestSubmitted}>
            Cancel Request
          </StyledButton>
        </div>
      </>
    </Modal>
  );
};

// const onBackfillClickHandler = async (
//   artistGroupId: string,
//   onClose: () => void,
// ) => {
//   await firebase
//     .firestore()
//     .collection(
//       `/artist_groups/${artistGroupId}/artist_group_private/community/backfills`,
//     )
//     .add({ backfillRequest: new Date() });

//   onClose();
// };

export const CommunityRemovalModal = ({
  artistGroupId,
  onClose,
  isOpen,
}: {
  artistGroupId: string;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [removingCommunity, setRemovingCommunity] = useState(false);
  const [removalError, setRemovalError] = useState("");

  const isDesktop = useDesktopMediaQuery();

  const onRemoveCommunity = async () => {
    setRemovingCommunity(true);

    setRemovalError("");

    try {
      await httpsCallable(
        getFunctions(),
        "services-communityActions",
      )({
        action: "remove",
        artistGroupId,
      });

      onClose();
    } catch (error) {
      console.error((error as Error).message);

      setRemovalError(
        `Error removing Community account: ${(error as Error).message}`,
      );
    }

    setRemovingCommunity(false);
  };

  const modalBody = () => {
    if (removalError) {
      return (
        <HeaderText style={{ color: "#e45c52" }} isDesktop={isDesktop}>
          {removalError}
        </HeaderText>
      );
    }

    if (removingCommunity) {
      return (
        <>
          <CircularLoader height={30} sx={{ marginRight: "0.5rem" }} />
          <br />
          <BodyText isDesktop={isDesktop}>
            Removing Community account...
          </BodyText>
        </>
      );
    }
    return (
      <HeaderText isDesktop={isDesktop}>
        Are you sure you want to unlink your Community account?
      </HeaderText>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} service="community">
      {modalBody()}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginTop: 40,
        }}
      >
        <Button
          onClick={() => onRemoveCommunity()}
          style={{ marginRight: "2rem" }}
          disabled={removingCommunity}
        >
          Confirm
        </Button>
        <Button
          variant="secondary"
          onClick={onClose}
          disabled={removingCommunity}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

const BodyText = styled.div<{ isDesktop: boolean }>`
  font-weight: 400;
  font-size: ${({ isDesktop }) => (isDesktop ? "17px" : "12px")};
  line-height: ${({ isDesktop }) => (isDesktop ? "25.5px" : "18px")};
  margin-bottom: 10px;
`;

const ErrorText = styled.div<{ isDesktop: boolean }>`
  color: #e45c52;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 12px)" : "13px")};
  line-height: 19.5px;
  margin-top: 5px;
  align-self: flex-start;
  margin-left: 10px;
`;

const FooterText = styled.div<{ isDesktop: boolean }>`
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "13px" : "10px")};
  line-height: 15px;
`;

const HeaderText = styled.div<{ isDesktop: boolean }>`
  font-weight: 600;
  font-size: ${({ isDesktop }) => (isDesktop ? "20px" : "17px")};
  line-height: ${({ isDesktop }) => (isDesktop ? "30px" : "25.5px")};
`;

const StyledButton = styled(Button)`
  max-width: 385px;
`;

const InputLabel = styled.div<{ isDesktop: boolean }>`
  font-weight: 400;
  margin-right: auto;
  font-size: ${({ isDesktop }) => (isDesktop ? "14px" : "10px")};
  line-height: ${({ isDesktop }) => (isDesktop ? "25.5px" : "18px")};
`;
