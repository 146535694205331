import React, { useState } from "react";
import styled from "styled-components";
import { Body1, Body2 } from "melodies-source/Text";
import { toast } from "react-hot-toast";
import { Member } from ".";
import { getDateTimeString } from "./getDateTimeString";
import { useArtistContext } from "Components";
import firebase from "firebase/compat/app";
import {
  AccountRequestAccessReponse,
  AccountRequestAccessRequest,
  AffiliationMap,
} from "models/artistSignup";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Checkbox } from "melodies-source/Selectable";
import { Modal } from "melodies-source/Modal";
import { Button } from "melodies-source/Button";

interface Props {
  member: Member;
}

export const ReviewAccessRequest: React.FC<Props> = ({ member }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [makeAdmin, setMakeAdmin] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const { artistGroup } = useArtistContext();

  const toggleModal = () => setModalOpen((open) => !open);

  const handleReview = async (
    status: AccountRequestAccessRequest["status"],
  ) => {
    (status === "approve" ? setApproveLoading : setRejectLoading)(true);
    const app = firebase.app();
    const response = await httpsCallable<
      AccountRequestAccessRequest,
      AccountRequestAccessReponse
    >(
      getFunctions(app, "us-west2"),
      "signup-account",
    )({
      action: "request-access",
      signupId: member.signupId,
      status,
      ...(status === "approve" && { admin: makeAdmin }),
    });
    (status === "approve" ? setApproveLoading : setRejectLoading)(false);
    toggleModal();
    if (response.data.status === "ok") {
      if (status === "approve") {
        toast.success(`${member.name}'s access request was approved`);
      } else {
        toast(`${member.name}'s access request was denied`);
      }
    } else {
      toast.error(`An error has occured`);
    }
  };

  return (
    <>
      <Button text onClick={toggleModal} style={{ padding: 0, height: "auto" }}>
        Review Request
      </Button>
      <Modal
        isOpen={modalOpen}
        onClose={toggleModal}
        header="Review Access Request"
      >
        <Container>
          <Body1>
            {member.name} requested access to {artistGroup?.group?.name} artist
            account on SET ({getDateTimeString(member.requestedAt)}). Please
            review the submitted information and approve or deny this request.
          </Body1>
          <MemberInfo>
            <div>
              <Body2>Email:</Body2>
              <Body1>{member.email}</Body1>
            </div>
            <div>
              <Body2>Name:</Body2>
              <Body1>{member.name}</Body1>
            </div>
            <div>
              <Body2>Choose what best describes you:</Body2>
              <Body1>{AffiliationMap[member.affiliation]}</Body1>
            </div>
            <div>
              <Body2>Title:</Body2>
              <Body1>{member.title}</Body1>
            </div>
            <div>
              <Body2>Company:</Body2>
              <Body1>{member.company}</Body1>
            </div>
            {member.size === "large" && (
              <>
                <div>
                  <Body2>Applicant Works with Company:</Body2>
                  <Body1>{member.profileLink}</Body1>
                </div>
                <div>
                  <Body2>Company Works with Artist:</Body2>
                  <Body1>{member.companyLink}</Body1>
                </div>
                <div>
                  <Body2>How Company Works with this Artist:</Body2>
                  <Body1>{member.workAndPlan}</Body1>
                </div>
              </>
            )}
          </MemberInfo>
          <Checkbox
            description="Admin users are able to add and remove other users on the account and may have access to billing information and fan PII."
            label="Make Admin"
            value={makeAdmin}
            onChange={setMakeAdmin}
          />
          <Buttons>
            <Button
              variant="tertiary"
              onClick={() => handleReview("reject")}
              loading={rejectLoading}
            >
              Deny
            </Button>
            <Button
              onClick={() => handleReview("approve")}
              loading={approveLoading}
            >
              Approve
            </Button>
          </Buttons>
        </Container>
      </Modal>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${Body2} {
    color: #999999;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 25px;

  button {
    width: auto;
  }
`;
