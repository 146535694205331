import React from "react";
import styled from "styled-components";
import { Body1, H3 } from "melodies-source/Text";
import { ReactComponent as UsersIcon } from "assets/svg/users.svg";
import { DownloadCsv } from "../../Components/DownloadCsv";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const FanDetailsCard: React.FC = () => {
  const { customApp } = useCustomAppContext();

  const eventTypeSingular = customApp?.event.type.singular || "Show";
  const eventAudiencePlural = customApp?.event.audience.plural || "Fans";

  return (
    <Container>
      <Content>
        <UsersIcon style={{ height: 40, width: 80 }} />
        <H3>Who came to your {eventTypeSingular.toLowerCase()}? </H3>
        <Body1>
          Click here to get a pre-built list of all the{" "}
          {eventAudiencePlural.toLowerCase()} from this{" "}
          {eventTypeSingular.toLowerCase()}.
        </Body1>
        <DownloadCsv />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46%;
  background: ${({ theme }) =>
    theme.custom?.pages.reporting.setlive.fanReportCard.background ||
    "#f3f3f3"};
  border-radius: 20px;
  padding: 40px;

  ${({ theme }) => theme.media.tablet} {
    padding: 25px;
  }

  h3 {
    margin-top: 10px;
  }

  h3,
  p {
    color: var(--text-color);
    text-align: center;
  }

  p {
    margin-top: 5px;
  }

  button {
    margin-top: 20px;
    background: transparent !important;
    border: 1px solid var(--text-color);
    color: var(--text-color);
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 300px;
  min-width: 100px;
  color: var(--text-color);
`;
