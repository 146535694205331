import styled, { css } from "styled-components";
import { firestore } from "@musicaudienceexchange/ap-interface";
import { formatPhoneNumber } from "Utils";
import Skeleton from "react-loading-skeleton";
import { useDesktopMediaQuery } from "hooks";
const { Fan } = firestore;

export const FanRow = ({ fan }) => {
  const fanModel = Fan.raw(fan, { silent: true });
  const isDesktop = useDesktopMediaQuery();
  const { age, location, phone, name, email, gender, createdAt } = fanModel;
  return (
    <Container>
      <Element>{name}</Element>
      <Element>{location}</Element>
      {isDesktop && (
        <>
          <Element>{age === 0 ? "" : age}</Element>
          <Element>{gender}</Element>
          <Element>{createdAt?.setZone("local")?.toFormat("DD")}</Element>
        </>
      )}
      <Element>{formatPhoneNumber(phone)}</Element>
      <Element>{email}</Element>
    </Container>
  );
};

export const FanRowLoader = () => (
  <Container>
    <Element>
      <Skeleton />
    </Element>
    <Element>
      <Skeleton />
    </Element>
    <Element>
      <Skeleton />
    </Element>
    <Element>
      <Skeleton />
    </Element>
    <Element>
      <Skeleton />
    </Element>
    <Element>
      <Skeleton />
    </Element>
    <Element>
      <Skeleton />
    </Element>
  </Container>
);

const Container = styled.div(({ theme }) => {
  const { border, hover } = theme.custom?.pages.contacts.table.row || {};
  return css`
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    box-shadow: inset 0 -1px 0 0 ${border?.color || "#e6e9eb"};

    &:hover {
      background-color: ${hover?.background || "#f2f5f7"};
    }

    ${theme.media.desktop} {
      flex-direction: row;
      justify-content: space-between;
    }
  `;
});

const Element = styled.div`
  color: inherit;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
`;
