export const SvgSadCloud = () => (
  <svg
    width="100px"
    height="100px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Icon/Product/MAX/Reporting/Metric Importer, Easter Egg - Sad</title>
    <defs>
      <filter
        x="-29.5%"
        y="-29.5%"
        width="159.1%"
        height="159.1%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feOffset
          dx="0"
          dy="1"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="1"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
      <path
        d="M5,15 C2.23857625,15 0,12.7614237 0,10 C0,7.48474699 1.85724473,5.40327673 4.2751686,5.05215482 C5.11784342,2.13425344 7.80968091,0 11,0 C14.1903191,0 16.8821566,2.13425344 17.7256306,5.05287847 C20.1427553,5.40327673 22,7.48474699 22,10 C22,12.7614237 19.7614237,15 17,15 L17,15 Z"
        id="path-2"
      ></path>
      <radialGradient
        cx="0%"
        cy="-2.64909517e-06%"
        fx="0%"
        fy="-2.64909517e-06%"
        r="107.185281%"
        id="radialGradient-4"
      >
        <stop stopColor="#FFFFFF" offset="0%"></stop>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"></stop>
      </radialGradient>
      <path
        d="M8,4 C8.09420482,4 8.18536718,4.01953949 8.2717841,4.05606401 C8.10995826,4.13861077 8,4.30646432 8,4.5 C8,4.77614237 8.22385763,5 8.5,5 C8.65735732,5 8.79773679,4.92730916 8.88939084,4.81367504 C8.95993599,5.01840277 9,5.2521833 9,5.5 C9,6.32842712 8.55228475,7 8,7 C7.44771525,7 7,6.32842712 7,5.5 C7,4.67157288 7.44771525,4 8,4 Z"
        id="path-5"
      ></path>
      <filter
        x="-12.5%"
        y="-8.3%"
        width="125.0%"
        height="116.7%"
        filterUnits="objectBoundingBox"
        id="filter-6"
      >
        <feOffset
          dx="0"
          dy="-0.5"
          in="SourceAlpha"
          result="shadowOffsetInner1"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowInnerInner1"
        ></feColorMatrix>
      </filter>
      <path
        d="M14,4 C14.0942048,4 14.1853672,4.01953949 14.2717841,4.05606401 C14.1099583,4.13861077 14,4.30646432 14,4.5 C14,4.77614237 14.2238576,5 14.5,5 C14.6573573,5 14.7977368,4.92730916 14.8893908,4.81367504 C14.959936,5.01840277 15,5.2521833 15,5.5 C15,6.32842712 14.5522847,7 14,7 C13.4477153,7 13,6.32842712 13,5.5 C13,4.67157288 13.4477153,4 14,4 Z"
        id="path-7"
      ></path>
      <filter
        x="-12.5%"
        y="-8.3%"
        width="125.0%"
        height="116.7%"
        filterUnits="objectBoundingBox"
        id="filter-8"
      >
        <feOffset
          dx="0"
          dy="-0.5"
          in="SourceAlpha"
          result="shadowOffsetInner1"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowInnerInner1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Icon/Product/MAX/Reporting/Metric-Importer,-Easter-Egg---Sad"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Group-39"
        filter="url(#filter-1)"
        transform="translate(9.000000, 9.000000)"
      >
        <path
          d="M15.9090909,22 C16.4545455,22 17,21.1962783 17,20.3333333 C17,19.7580367 16.6363636,18.6469256 15.9090909,17 C15.9090909,17.9722222 15.7878788,18.6666667 15.5454545,19.0833333 C15.1818182,19.7083333 15,20.125 15,20.75 C15,21.375 15.3636364,22 15.9090909,22 Z"
          id="Oval"
          fill="#1F95ED"
        ></path>
        <path
          d="M10.9090909,19 C11.4545455,19 12,18.1962783 12,17.3333333 C12,16.7580367 11.6363636,15.6469256 10.9090909,14 C10.9090909,14.9722222 10.7878788,15.6666667 10.5454545,16.0833333 C10.1818182,16.7083333 10,17.125 10,17.75 C10,18.375 10.3636364,19 10.9090909,19 Z"
          id="Oval"
          fill="#1F95ED"
        ></path>
        <path
          d="M5.90909091,21 C6.45454545,21 7,20.1962783 7,19.3333333 C7,18.7580367 6.63636364,17.6469256 5.90909091,16 C5.90909091,16.9722222 5.78787879,17.6666667 5.54545455,18.0833333 C5.18181818,18.7083333 5,19.125 5,19.75 C5,20.375 5.36363636,21 5.90909091,21 Z"
          id="Oval"
          fill="#1F95ED"
        ></path>
        <g id="Combined-Shape">
          <mask id="mask-3" fill="white">
            <use xlinkHref="#path-2"></use>
          </mask>
          <use id="Mask" fill="#E6E9EB" xlinkHref="#path-2"></use>
          <g mask="url(#mask-3)" fill="url(#radialGradient-4)" opacity="0.45">
            <path d="M19.4960832,0.000595209414 C19.8250423,1.27889569 20,2.61901606 20,4 C20,12.836556 12.836556,20 4,20 C2.61901606,20 1.27889569,19.8250423 0.000595209414,19.4960832 L0,0 Z"></path>
          </g>
        </g>
        <path
          d="M5.68907222,11.9084507 C5.61395007,11.9677196 5.52105724,12.0000084 5.4253695,12.0000084 C5.19044441,12.0000084 5,11.8095382 5,11.5746131 L5,10.7463501 C5,10.4820094 5.10466387,10.2284219 5.29110904,10.0410341 C6.15637159,9.17138338 8.05933524,8.73655803 11,8.73655803 C13.9406261,8.73655803 15.8435784,9.17137195 16.7088568,10.0409998 C16.8953044,10.2283846 17,10.4819711 17,10.7463113 L17,11.5746131 C17,11.6703009 16.967737,11.7631937 16.9084243,11.8382813 C16.7628044,12.0226305 16.4953116,12.0540268 16.3109278,11.9084507 C15.3218803,11.1271889 13.551571,10.736558 11,10.736558 C8.44842898,10.736558 6.67811972,11.1271889 5.68907222,11.9084507 Z"
          id="Rectangle-52"
          fill="#000000"
        ></path>
        <g id="Combined-Shape">
          <use fill="#000000" fillRule="evenodd" xlinkHref="#path-5"></use>
          <use
            fill="black"
            fillOpacity="1"
            filter="url(#filter-6)"
            xlinkHref="#path-5"
          ></use>
        </g>
        <g id="Combined-Shape">
          <use fill="#000000" fillRule="evenodd" xlinkHref="#path-7"></use>
          <use
            fill="black"
            fillOpacity="1"
            filter="url(#filter-8)"
            xlinkHref="#path-7"
          ></use>
        </g>
      </g>
    </g>
  </svg>
);
