import { createContext, useContext, useState } from "react";
import { DocumentModel, useDocumentModel } from "hooks/useModel";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { useDocument } from "react-firebase-hooks/firestore";
import {
  Model,
  validator,
  TransformValidateDateTime,
  TransformValidateModel,
} from "@musicaudienceexchange/toccata";
import { getFirestore, doc } from "firebase/firestore";
import { SocialLinkName, StreamingService } from "@max/common";

export class Coordinates extends Model {
  @validator.IsLatitude()
  latitude?: number;

  @validator.IsLongitude()
  longitude?: number;
}

export const CsvExportStatuses = [
  "initial",
  "generating",
  "available",
  "failed",
] as const;

export type CsvExportStatus = (typeof CsvExportStatuses)[number];

class Event extends Model {
  @validator.IsNumber()
  @validator.IsOptional()
  version?: number;

  @TransformValidateDateTime()
  startsAt: DateTime;

  @TransformValidateModel(Coordinates)
  coordinates: Coordinates;

  @validator.IsNotEmpty()
  @validator.IsString()
  venue: string;

  @validator.IsNotEmpty()
  @validator.IsString()
  address: string;

  @TransformValidateDateTime()
  @validator.IsOptional()
  endedAt: DateTime | null;

  @validator.IsOptional()
  entriesExportStatus?: CsvExportStatus;
}

type Totals = {
  all: number;
  inVenue: number;
  noLocation: number;
};

interface PriorityProps {
  [key: string]: number;
}

const ViewPropTypes = [
  "requests",
  "encore",
  "support",
  "entries",
  "dashboard",
  "sweeps",
  "auction",
  "conversation",
  "settings",
] as const;
export type ViewProps = (typeof ViewPropTypes)[number];

interface ObjectLiteral<T> {
  [key: string]: T;
}

interface Visits {
  total: number;
  users: ObjectLiteral<number>;
}

interface ClicksData {
  merch?: ObjectLiteral<number>;
  social?: ObjectLiteral<number>;
  streaming?: ObjectLiteral<number>;
  register?: ObjectLiteral<number>;
}

interface ClickDetails {
  name: string;
  count: number;
}

interface ClickCategory {
  name: string;
  total: number;
  detail: ClickDetails[];
}

interface Vote<T = number> {
  label?: string;
  options?: Record<string, string>;
  votes?: Record<string, T>;
  total?: number;
}

type Votes<T = number> = {
  total?: number;
  items?: {
    vote?: Vote<T>;
  } & { [id: string]: Vote<T> | undefined };
};

interface Impressions {
  [label: string]: number;
}

type EmailAnalyticsClick = {
  [key in `social.${SocialLinkName}`]?: number;
} & {
  [key in `streaming.${StreamingService}`]?: number;
} & {
  [key: `merch.${string}`]: number;
} & {
  setfan_postshow?: number;
  unsubscribe?: number;
  other?: number;
};

interface EmailAnalytics {
  opened?: number;
  delivered?: number;
  clicked?: EmailAnalyticsClick;
}

interface EntryStats {
  zips?: Record<string, number>;
  totals?: Totals;
  times?: Record<string, number>;
}

interface EntryStatsByKey {
  email?: EntryStats;
  phone?: EntryStats;
}

interface ArtistEventContextProps {
  event: DocumentModel<Event>;
  eventId: string;
  eventLoading: boolean;
  setPriority?: (val: PriorityProps) => void;
  priority?: any;
  requestsTotalCents?: number;
  view: ViewProps;
  setView: React.Dispatch<React.SetStateAction<ViewProps>>;
  encorePaymentAllowed?: boolean;
  proximityLimit?: number;
  totalEntriesInVenue?: number;
  auctionItems?: any[];
  auctionWinners?: any[];
  totals?: Totals;
  totalsLoading?: boolean;
  totalsError?: boolean;
  clicks?: ClickCategory[];
  clicksLoading?: boolean;
  clicksError?: boolean;
  impressions?: Impressions;
  impressionsLoading?: boolean;
  impressionsError?: boolean;
  visits?: Visits;
  visitsLoading?: boolean;
  visitsError?: boolean;
  votes?: Votes;
  votesLoading?: boolean;
  votesError?: boolean;
  emailAnalytics?: EmailAnalytics;
  emailAnalyticsLoading?: boolean;
  emailAnalyticsError?: boolean;
}

export const ArtistEventContext = createContext<ArtistEventContextProps>(
  {} as ArtistEventContextProps,
);

export const ArtistEventController = ({ eventId, children }) => {
  const [view, setView] = useState<ViewProps>("dashboard");
  const [eventSnapshot, eventLoading] = useDocumentModel(
    Event,
    doc(getFirestore(), `setlive_events/${eventId}`),
  );

  const event = {
    ...eventSnapshot,
    startsAt: eventSnapshot?.startsAt?.toLocal(),
  };

  const history = useHistory();

  if (!eventLoading && event === undefined) {
    history.push("/");
  }
  const services = {
    event,
    eventLoading,
    view,
    setView,
    eventId,
    children,
  };

  // if (!event.type) {
  //   return null; //TODO: actually validate this loading process/show a loader
  // }

  return <ArtistEventProviderLite {...services} />;
};

export const ArtistEventProviderLite = ({
  eventId,
  event,
  eventLoading,
  view,
  setView,
  children,
}) => {
  const eventVersion = event.version === 2 ? 2 : 1;
  const entriesPath =
    eventVersion === 2
      ? `set_fresh_events/${eventId}/analytics/entries`
      : `setlive_events/${eventId}/analytics/entries`;
  const uniqueKey = eventVersion === 2 ? "phone" : undefined;

  let [entriesSnapshot, entriesLoading, entriesSnapshotError] = useDocument(
    doc(getFirestore(), entriesPath),
  );

  let totals = { inVenue: 0, all: 0, noLocation: 0 };
  const totalsLoading = entriesLoading;
  let totalsError = true;

  if (entriesSnapshot) {
    totalsError = false;
    if (entriesSnapshot.exists()) {
      const data = entriesSnapshot.data();
      const stats =
        (eventVersion === 2
          ? (data as EntryStatsByKey)?.[uniqueKey]
          : (data as EntryStats)) || {};
      totals = { ...totals, ...stats.totals };
    }
  }

  if (!entriesLoading && (!entriesSnapshot || entriesSnapshotError))
    console.log({ entriesSnapshot, entriesSnapshotError });

  const [clicksSnapshot, clicksLoading, clicksSnapshotError] = useDocument(
    doc(getFirestore(), `set_fresh_events/${eventId}/analytics/click`),
  );

  if (!clicksLoading && (!clicksSnapshot || clicksSnapshotError))
    console.log({ clicksSnapshot, clicksSnapshotError });

  let clicks = null;
  let clicksError = true;

  if (clicksSnapshot) {
    clicksError = false;

    if (clicksSnapshot.exists) {
      const clicksData: ClicksData = clicksSnapshot.data();
      if (clicksData?.register) delete clicksData?.register;

      const labels = {
        merch: "Merchandise",
        social: "Social",
        facebook: "Facebook",
        instagram: "Instagram",
        tiktok: "TikTok",
        twitter: "Twitter",
        streaming: "Streaming",
        amazon: "Amazon Music",
        apple: "Apple Music",
        deezer: "Deezer",
        spotify: "Spotify",
        youtube: "Youtube",
      };

      const categoriesToOmit = ["tab"];
      const keysToOmit = ["open", "title", "subtitle"];
      const categoriesWithLabelKey = ["merch", "tab"];

      clicks = clicksData
        ? (Object.entries(clicksData) as Entries<typeof clicksData>)
            .filter(
              ([key, value]) =>
                !categoriesToOmit.includes(key) &&
                Object.keys(value).length > 0,
            )
            .map(([key, value]) => {
              for (const [k] of Object.entries(value)) {
                if (keysToOmit.includes(k)) delete value[k];
              }
              return {
                name: labels[key] ?? key,
                total: Object.values(value).reduce(
                  (total, elem) => total + elem,
                  0,
                ),
                detail: Object.entries(value)
                  .map(([key2, value2]) => ({
                    name: categoriesWithLabelKey.includes(key)
                      ? key2
                      : labels[key2] ?? key,
                    count: value2,
                  }))
                  .sort((a, b) => a.name.localeCompare(b.name)),
              };
            })
            .sort((a, b) => a.name.localeCompare(b.name))
        : [];
    }
  }

  const [
    emailAnalyticsSnapshot,
    emailAnalyticsLoading,
    emailAnalyticsSnapshotError,
  ] = useDocument(
    doc(getFirestore(), `set_fresh_events/${eventId}/analytics/email`),
  );

  if (
    !emailAnalyticsLoading &&
    (!emailAnalyticsSnapshot || emailAnalyticsSnapshotError)
  )
    console.log({ emailAnalyticsSnapshot, emailAnalyticsSnapshotError });

  let emailAnalytics: EmailAnalytics = null;
  let emailAnalyticsError = true;

  if (emailAnalyticsSnapshot) {
    emailAnalyticsError = false;
    if (emailAnalyticsSnapshot.exists()) {
      emailAnalytics = emailAnalyticsSnapshot.data();
    }
  }

  const [visitsSnapshot, visitsLoading, visitsSnapshotError] = useDocument(
    doc(getFirestore(), `set_fresh_events/${eventId}/analytics/visit`),
  );

  if (!visitsLoading && (!visitsSnapshot || visitsSnapshotError))
    console.log({ visitsSnapshot, visitsSnapshotError });

  let visitsError = true;
  let visits = null;

  if (visitsSnapshot) {
    visitsError = false;
    if (visitsSnapshot.exists()) visits = visitsSnapshot.data();
  }

  let [votesSnapshot, votesLoading, votesSnapshotError] = useDocument(
    doc(getFirestore(), `set_fresh_events/${eventId}/analytics/votes`),
  );

  if (!votesLoading && (!votesSnapshot || votesSnapshotError))
    console.log({ votesSnapshot, votesSnapshotError });

  let votes: Votes | null = null;
  let votesError = true;

  if (votesSnapshot) {
    votesError = false;

    if (votesSnapshot.exists()) {
      const voteItems = votesSnapshot.data();
      for (const [k, v] of Object.entries(voteItems ?? {})) {
        // If a "vote" object doesn't have any keys, it is invalid
        if (Object.keys(v).length < 1) {
          delete voteItems[k];
        }
      }

      votes = {
        total: 0,
        items: voteItems,
      };

      if (votes.items) {
        votes.total = 0;
        Object.keys(votes.items).forEach((key) => {
          votes.items[key].total = Object.values(
            votes.items[key]?.votes ?? {},
          ).reduce((t, v) => t + v, 0);
          votes.total += votes.items[key].total;
        });
      }
    }
  }

  let [impressionsSnapshot, impressionsLoading, impressionsSnapshotError] =
    useDocument(
      doc(getFirestore(), `set_fresh_events/${eventId}/analytics/card`),
    );

  if (!impressionsLoading && (!impressionsSnapshot || impressionsSnapshotError))
    console.log({ impressionsSnapshot, impressionsSnapshotError });

  let impressions = null;
  let impressionsError = true;

  if (impressionsSnapshot) {
    impressionsError = false;

    if (impressionsSnapshot.exists()) {
      impressions = impressionsSnapshot.data();
      for (const [k, v] of Object.entries(impressions)) {
        if (typeof v === "object") delete impressions[k];
      }
    }
  }

  const value = {
    event,
    eventId,
    eventLoading,
    view,
    setView,
    totals,
    totalsLoading,
    totalsError,
    proximityLimit: event?.sweeps?.proximityLimitInMiles ?? 1.25,
    clicks,
    clicksLoading,
    clicksError,
    visits,
    visitsLoading,
    visitsError,
    votes,
    votesLoading,
    votesError,
    impressions,
    impressionsLoading,
    impressionsError,
    emailAnalytics,
    emailAnalyticsLoading,
    emailAnalyticsError,
  };
  return (
    <ArtistEventContext.Provider value={value}>
      {children}
    </ArtistEventContext.Provider>
  );
};

export const useArtistEventContext = () => useContext(ArtistEventContext);
