import { ReactComponent as IconShirtPick } from "assets/svg/shirt-pick.svg";
import { SurveyTemplate } from "../../types";
import { getContestDefaults } from "../helpers";

export const pickFavDesign: SurveyTemplate = {
  icon: <IconShirtPick />,
  title: "Pick Your Favorite Design",
  description:
    "Ask your fans to choose their favorite merch design, and which items to make.",
  templateId: "pickFavDesign",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: "Pick Your Favorite Design",
        },
      },
      ...getContestDefaults(formState),
    },
    surveyQuestions: [
      {
        type: "ImageQuestion",
        required: true,
        showLabels: false,
        actionRequired: true,
        id: "question_1",
      },
    ],
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-merch---pi-f16927"
        : "pp-merch---pi-861121",
    fields: [
      {
        name: "merchPlan",
        type: "radio",
        label: "What's your current merch status?",
        options: ["Available now", "Gathering feedback"],
        getValidation: (fields) =>
          fields.merchPlan ? false : "This field cannot be left blank",
      },
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
