import * as React from "react";
import { SVGProps } from "react";
export const SvgConnected = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M2 9.45427529 2 8.53960971 6.36037961 8.53960971 7.97490859 12.969872 10.9169255 4 13.9415294 10.4551692 14.6396204 8.53960971 18 8.53960971 18 9.45427529 15.3603796 9.45427529 14.0584706 13.0267093 11.0830745 6.67656036 8.02509141 16 5.63962039 9.45427529z"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
