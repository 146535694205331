export const cityStateFromAddress = (address: string): string => {
  const locArr = address
    .split(",")
    .map((s) => s.trim())
    .filter((s) => s)
    .map((s) => s.replace(/\d+/g, "").trim());

  let state: string | undefined = "";

  for (const loc of locArr) {
    state = STATE_LIST[loc.toLowerCase()];
    if (state) break;
  }

  if (!state) return address;

  const possibles = locArr.filter((s) => !s.match(/\d+/g));
  const index = possibles.findIndex((s) => s.toUpperCase() === state);
  const city = index >= 0 && possibles.length > 1 ? possibles[index - 1] : "";

  return city && state ? `${city}, ${state}` : address;
};

export const truncateString = (input: string, maxLength?: number): string => {
  const limit = maxLength ?? 25;

  if (input.length <= limit) {
    return input;
  }

  return `${input.slice(0, limit)}...`;
};

const STATE_LIST = {
  al: "AL",
  ak: "AK",
  az: "AZ",
  ar: "AR",
  ca: "CA",
  co: "CO",
  ct: "CT",
  de: "DE",
  fl: "FL",
  ga: "GA",
  hi: "HI",
  id: "ID",
  il: "IL",
  in: "IN",
  ia: "IA",
  ks: "KS",
  ky: "KY",
  la: "LA",
  me: "ME",
  md: "MD",
  mi: "MI",
  mn: "MN",
  ms: "MS",
  mo: "MO",
  mt: "MT",
  ne: "NE",
  nv: "NV",
  nh: "NH",
  nj: "NJ",
  nm: "NM",
  ny: "NY",
  nc: "NC",
  nd: "ND",
  oh: "OH",
  ok: "OK",
  or: "OR",
  pa: "PA",
  ri: "RI",
  sc: "SC",
  sd: "SD",
  tn: "TN",
  tx: "TX",
  ut: "UT",
  vt: "VT",
  va: "VA",
  wa: "WA",
  wv: "WV",
  wi: "WI",
  wy: "WY",
};
