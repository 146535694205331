import { NoData } from "Components/NoData";
import { varHexToRGB } from "Utils";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { useTabletMediaQuery } from "hooks/useTabletMediaQuery";
import React from "react";
import ReactWordcloud, { OptionsProp } from "react-wordcloud";

export interface Item {
  text: string;
  value: number;
}

export interface Props {
  data: Item[];
  loading?: boolean;
}

export const WordCloudChart: React.FC<Props> = ({ data }) => {
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const maxValue = Math.max(...data.map((item) => item.value));

  const options: OptionsProp = {
    rotations: 0,
    rotationAngles: [0, 0],
    fontSizes: [14, isMobile ? 32 : isTablet ? 48 : 64],
    fontFamily: "Poppins",
    fontWeight: "500",
    padding: 10,
    enableTooltip: false,
    enableOptimizations: true,
    deterministic: true,
  };

  const callbacks = {
    getWordColor: (word) =>
      varHexToRGB("--main-color", Math.max(0.4, word.value / maxValue)),
  };

  return data.length === 0 ? (
    <NoData />
  ) : (
    <ReactWordcloud options={options} callbacks={callbacks} words={data} />
  );
};

export const Component = WordCloudChart;
