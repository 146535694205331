import React, { useState } from "react";
import styled from "styled-components";
import { H1, H3, Body1, H4 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { TwoColumns, useArtistContext } from "Components";
import { useDesktopMediaQuery } from "hooks";
import { Layout } from "../../Layout";
import { StepProps } from "../..";
import { setDoc } from "firebase/firestore";
import { ReactComponent as ComputerIcon } from "assets/svg/computer.svg";

export const Websites: React.FC<StepProps> = ({ handleBack, handleNext }) => {
  const { artistGroupDoc } = useArtistContext();
  const artistGroupData = artistGroupDoc?.data();
  const [website, setWebsite] = useState(artistGroupData?.website || "");
  const [merchStore, setMerchStore] = useState(artistGroupData?.shopUrl || "");
  const [loading, setLoading] = useState(false);
  const isDesktop = useDesktopMediaQuery();

  const Heading = isDesktop ? H1 : H3;
  const SubHeading = isDesktop ? H3 : H4;

  const handleNextButtonClick = async () => {
    setLoading(true);

    const data = {
      website,
      shopUrl: merchStore,
    };

    await setDoc(artistGroupDoc.ref, data, { merge: true });

    setLoading(false);
    handleNext();
  };

  return (
    <Layout
      step={3}
      buttons={{
        back: { onClick: handleBack },
        next: {
          text: "Invite Teammates",
          onClick: handleNextButtonClick,
          loading,
        },
      }}
    >
      {isDesktop && <ComputerIcon />}
      <Content>
        <Column>
          <HeadingContainer>
            {!isDesktop && <ComputerIcon />}
            <Heading>Your Websites</Heading>
          </HeadingContainer>
          <SubHeading>Share all your info and merch with your fans.</SubHeading>
          <Body1>
            To give you a head start setting up your account, MAX has found the
            following links for your wesbite and merch store.
            <br />
            <br />
            We can gather information on things like your streaming services and
            latest items for sale for use at future shows.
          </Body1>
        </Column>
        <Column>
          <TextInput
            label="Your Website"
            value={website}
            onChange={setWebsite}
          />
          <TextInput
            label="Your Merch Store"
            value={merchStore}
            onChange={setMerchStore}
          />
        </Column>
      </Content>
    </Layout>
  );
};

const Content = styled(TwoColumns)`
  margin: 20px 0 30px;

  ${({ theme }) => theme.media.mobile} {
    margin: 10px 0 0 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  svg {
    width: 30px;
    height: 30px;
  }
`;
