import { FlexColumn, FlexRow } from "Components/Flex";
import { Form, Formik } from "formik";
import { ConcertData } from "hooks/useUpcomingConcerts";
import { Button } from "melodies-source/Button";
import { Radio } from "melodies-source/Selectable";
import { SvgRightLarge } from "melodies-source/Svgs/RightLarge";
import { Body1, H4 } from "melodies-source/Text";
import { useState } from "react";
import { ConcertDetails } from "Routes/SetLive/Duplicate/types";
import { getDateTime, isSameISODate } from "Utils/date";
import { getDisplayAddress } from "./ConcertCard";
import { ConcertDisplay } from "./ConcertDisplay";

interface ReplaceConcertProps {
  candidate: ConcertDetails<Date> | ConcertData;
  concerts?: ConcertData[];
  keeping?: boolean;
  replacing?: boolean;
  onKeep?: () => void;
  onReplace?: (concert: ConcertData) => void;
}

export const ReplaceConcert = ({
  candidate,
  concerts = [],
  keeping,
  replacing,
  onKeep,
  onReplace,
}: ReplaceConcertProps) => {
  const [isPicking, setIsPicking] = useState(false);

  const sameDayConcerts = concerts
    .filter(({ id }) => id !== candidate.id)
    .filter(({ date }) => isSameISODate(date, candidate.date));

  return isPicking ? (
    <Formik
      initialValues={{
        concertId: "",
      }}
      onSubmit={(values) =>
        onReplace(sameDayConcerts.find((c) => c.id === values.concertId))
      }
    >
      {({ values, setFieldValue }) => (
        <Form>
          <FlexColumn gap="20px">
            <Body1>
              Choose which event you'd like to replace with your new one
            </Body1>
            <FlexColumn gap="10px">
              {sameDayConcerts.map((concert) => {
                const date = getDateTime(concert.date);

                return (
                  <Radio
                    key={concert.id}
                    value={values.concertId === concert.id}
                    label={concert.venue}
                    description={
                      <>
                        <Body1>{getDisplayAddress(concert)}</Body1>
                        <Body1>
                          {date.toFormat("h:mm a (ZZZZ)")} •{" "}
                          {date.toFormat("DDD")}
                        </Body1>
                      </>
                    }
                    onChange={() => setFieldValue("concertId", concert.id)}
                  />
                );
              })}
            </FlexColumn>
            <FlexRow gap="20px" xEnd>
              <Button disabled={!values.concertId} type="submit">
                Replace <SvgRightLarge />
              </Button>
            </FlexRow>
          </FlexColumn>
        </Form>
      )}
    </Formik>
  ) : (
    <FlexColumn gap="20px">
      <Body1>It looks like you already have an event on this day:</Body1>
      <H4>Your previously published event:</H4>
      {sameDayConcerts.map((concert) => (
        <ConcertDisplay key={concert.id} concert={concert} />
      ))}
      <H4>Your new event:</H4>
      <ConcertDisplay concert={candidate} isNew={true} />
      <Body1>
        Would you like to add this as an additional event, or replace the
        existing event with your new one?
      </Body1>
      <FlexRow gap="20px" xEnd>
        <Button
          variant="outlined"
          disabled={replacing}
          loading={keeping}
          onClick={onKeep}
        >
          {"ref" in candidate
            ? sameDayConcerts.length === 1
              ? "Keep Both"
              : "Keep All"
            : "Add New Event"}
        </Button>
        <Button
          disabled={keeping}
          loading={replacing}
          onClick={() => {
            if (sameDayConcerts.length === 1) {
              onReplace(sameDayConcerts[0]);
            } else {
              setIsPicking(true);
            }
          }}
        >
          {sameDayConcerts.length === 1 ? "Replace" : "Replace an Event"}
        </Button>
      </FlexRow>
    </FlexColumn>
  );
};
