import { ReactComponent as SvgFrame } from "assets/svg/frame.svg";
import styled from "styled-components";
import { FlexColumn } from "Components/Flex";
import { H3 } from "melodies-source/Text";
import { TextInput } from "Components";
import { useState } from "react";
import { Selectable } from "melodies-source/Selectable";
import { QuestionModalProps, QuestionTypeMeta } from "./BaseQuestionModal";
import { SvgHelp } from "melodies-source/Svgs/Help";
import { AddQuestionFooter } from "./Footer";
import { TextQuestionModule } from "@max/common/dist/setfan";

export const FreeResponseQuestionModal = ({
  existing,
  ...props
}: Omit<QuestionModalProps<TextQuestionModule>, "state">) => {
  const [questionText, setQuestionText] = useState(existing?.label.en ?? "");
  const [allowLongResponse, setAllowLongResponse] = useState(
    !!existing?.multiline,
  );
  const [required, setRequired] = useState(!!existing?.required);
  const [error, setError] = useState("");
  const [validationTriggered, setValidationTriggered] = useState(false);

  const onAdd = () => {
    setValidationTriggered(true);

    if (!questionText.length) {
      setError("This is a required field and cannot be blank");
      return;
    }

    props.dispatch({
      type: existing?.id ? "updateModule" : "addModule",
      payload: new TextQuestionModule({
        id: existing?.id,
        multiline: allowLongResponse,
        label: { en: questionText },
      }),
    });
    props.dispatch({ type: "closeModal" });
  };

  return (
    <>
      <QuestionCard>
        <IconContainer>
          <SvgHelp style={{ color: "#1B0076", width: 29, height: 29 }} />
        </IconContainer>
        <H3 style={{ color: "#1B0076", marginBottom: 24 }}>Question</H3>
        <TextInput
          label="What is your question?"
          placeholder="Type your question..."
          value={questionText}
          onChange={(text) => {
            if (validationTriggered && !text) {
              setError("This is a required field and cannot be blank");
            }
            if (text) {
              setError("");
            }
            setQuestionText(text);
          }}
          style={{ maxWidth: 550 }}
          hasError={!!error}
          helperText={error}
        />
        <Selectable
          variant="checkbox"
          label="Allow Long Response"
          value={allowLongResponse}
          onChange={(checked) => setAllowLongResponse(checked)}
          style={{ marginTop: 20 }}
        />
      </QuestionCard>
      <AddQuestionFooter
        required={required}
        setRequired={setRequired}
        confirmLabel={existing ? "Update" : "Add"}
        onCancel={() => props.dispatch({ type: "closeModal" })}
        onConfirm={onAdd}
        confirmDisabled={!questionText}
      />
    </>
  );
};

export const freeResponse: QuestionTypeMeta = {
  icon: <SvgFrame />,
  key: "freeResponseQuestion",
  header: "Free response",
  subHeader: "Let a participant enter their own free-form response",
  body: FreeResponseQuestionModal,
};

const IconContainer = styled.div`
  position: absolute;
  top: 25px;
  left: 26px;
`;

const QuestionCard = styled(FlexColumn)`
  border-radius: 12px;
  background: #f2f5f7;
  padding: 25px 26px 25px 67px;
  position: relative;
  margin-bottom: 32px;
`;
