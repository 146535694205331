import { Card } from "melodies-source/Card";
import styled, { CSSProperties } from "styled-components";

const FanStatusCard = ({
  children,
  sx,
  className,
}: {
  children: JSX.Element | JSX.Element[];
  sx?: CSSProperties;
  className?: string;
}) => {
  return (
    <DashboardCard style={sx} isElevated className={className}>
      <DashboardCardBody>{children}</DashboardCardBody>
    </DashboardCard>
  );
};

export default FanStatusCard;

const DashboardCard = styled(Card)`
  padding: 20px;
  background-color: var(--component-background-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1100px) {
    padding: 0px;
  }
`;

const DashboardCardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding-bottom: 15px;
  @media (max-width: 1100px) {
    padding: 5px;
  }
`;
