import React from "react";
import { Fields } from "../Components/Fields";
import { useArtistContext } from "Components";

export const AdvancedFields: React.FC = () => {
  const { artistGroupDoc, privateSettings } = useArtistContext();
  const artistGroupData = artistGroupDoc?.data();
  const privateSettingsData = privateSettings?.data();

  return (
    <Fields
      title="Admin Access Fields"
      fields={[
        {
          ref: artistGroupDoc?.ref,
          type: "text-input",
          label: "MAX Artist ID",
          placeholder: "Paste ID here",
          key: "maxArtistId",
          value: artistGroupData?.maxArtistId,
        },
        {
          ref: artistGroupDoc?.ref,
          type: "text-input",
          label: "Spotify Artist ID",
          placeholder: "Paste ID here",
          key: "spotifyArtistId",
          value: artistGroupData?.spotifyArtistId,
        },
        {
          ref: privateSettings?.ref,
          type: "select",
          label: "Post Show Email",
          key: "postShowEmail",
          value: privateSettingsData?.postShowEmail,
          options: [
            { value: "default", label: "Default" },
            { value: "disabled", label: "Disbaled" },
          ],
        },
      ]}
    />
  );
};
