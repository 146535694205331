import { REDIRECT_PATHS, TopBarLayout } from "Components";
import {
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import styled, { css } from "styled-components";
import { forwardRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  query,
  collection,
  where,
  getFirestore,
  Query,
  CollectionReference,
} from "firebase/firestore";
import { useUser } from "auth";
import * as Shoutout from "@musicaudienceexchange/shoutout-interface";
import defaultImg from "Components/Images/default.jpg";
import { SearchSvg } from "Components/Images/SearchSvg";
import { AddSvg } from "Components/Images/AddSvg";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { TextInput } from "melodies-source/TextInput";
import { Button as PrimaryButton } from "melodies-source/Button";
import { ArtistPendingCard } from "./ArtistPendingCard";
import { ArtistSignup } from "models/artistSignup";
import { H3, H4 } from "melodies-source/Text";
import { ReactComponent as ClockIcon } from "assets/svg/clock.svg";
import { useDesktopMediaQuery } from "hooks";
import NoArtist from "svg/NoArtist";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { Flex, FlexColumn } from "Components/Flex";

interface PendingArtistProps {
  image: string;
  name: string;
}

const PendingArtist = ({ image, name }: PendingArtistProps) => {
  const isDesktop = useDesktopMediaQuery();
  const Heading = isDesktop ? H3 : H4;

  return (
    <ArtistPendingContainer>
      <Avatar src={image || defaultImg}>
        <ArtistPendingOverlay>
          <ClockIcon />
          <Heading>Artist Pending Review</Heading>
        </ArtistPendingOverlay>
      </Avatar>
      <Name>{name}</Name>
    </ArtistPendingContainer>
  );
};

interface Props {
  allowedGroupIds?: string[];
}

export const ArtistSelect: React.FC<Props> = ({ allowedGroupIds }) => {
  const {
    user: { uid },
    claims,
  } = useUser();
  const isMobile = useMobileMediaQuery();
  const history = useHistory();
  const location = useLocation();
  const [search, setSearch] = useState("");
  //let lookup = firebase.firestore().collection("artist_groups");
  const collectionRef = collection(
    getFirestore(),
    "artist_groups",
  ) as CollectionReference<Shoutout.firestore.artists.ArtistGroup>;

  const lookup = !claims.admin
    ? query(
        collectionRef,
        where(`members.${uid}`, "array-contains-any", [
          "artist",
          "manager",
          "admin",
        ]),
      )
    : query(collectionRef);

  const [artistGroups, artistGroupsLoading] = useCollection(lookup);

  const [pendingSignups, pendingSignupsLoading] = useCollectionData(
    query(
      collection(getFirestore(), "artist_signups"),
      where("uid", "==", uid),
      where("status", "==", "submitted"),
      where("action", "==", "new"),
    ) as Query<ArtistSignup>,
  );

  const { customApp, language } = useCustomAppContext();

  if (artistGroupsLoading || pendingSignupsLoading) {
    return null;
  } else {
  }

  let docs = artistGroups.docs?.map((docs) => ({
    sortOrder: Number.MAX_SAFE_INTEGER,
    id: docs.id,
    ...docs?.data(),
  }));
  let pendingSignupsDocs = pendingSignups;

  if (docs?.length === 1 && pendingSignups?.length === 0) {
    history.replace(`/${docs[0].id}/`);
  }

  if (REDIRECT_PATHS.includes(location.pathname)) {
    history.push(`/${docs[0].id}${location.pathname}${location.search}`);
  }

  if (search) {
    docs = docs?.filter((doc) => {
      return doc?.name?.toLowerCase().includes(search.toLowerCase());
    });
    pendingSignupsDocs = pendingSignups?.filter((doc) =>
      doc.artistName?.toLowerCase().includes(search.toLowerCase()),
    );
  }

  let filteredDocs = docs?.sort((a, b) => a.sortOrder - b.sortOrder);

  if (!customApp) {
    filteredDocs = filteredDocs?.slice(0, 19);
  } else if (allowedGroupIds) {
    filteredDocs = filteredDocs.filter((doc) =>
      allowedGroupIds.includes(doc.id),
    );
  }

  const content = (
    <Container isCustomApp={!!customApp}>
      {docs?.length === 0 && pendingSignups?.length === 1 && search === "" ? (
        <ArtistPendingCard
          image={pendingSignups[0].artistImage || defaultImg}
        />
      ) : (
        <>
          {!customApp && (
            <Controls>
              <ControlsWrap hasSearch={docs?.length > 9 || !!search?.length}>
                <Header>Select Artist</Header>
                {docs?.length > 0 && (
                  <StyledButton
                    variant="outlined"
                    leftIcon={<AddSvg />}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_SIGNUP_URL}/?start`,
                        "_self",
                      )
                    }
                  >
                    Add an Artist
                  </StyledButton>
                )}
              </ControlsWrap>
              {(docs?.length > 9 || !!search?.length) && (
                <TextInput
                  placeholder={`Search for an Artist`}
                  value={search}
                  onChange={setSearch}
                  leftIcon={<SearchSvg />}
                  style={{
                    maxWidth: 600,
                    margin: isMobile ? "10px auto" : "40px auto 10px",
                  }}
                />
              )}
            </Controls>
          )}
          <ContainerPadding
            sm={docs?.length < 4 || isMobile}
            isCustomApp={!!customApp}
          >
            {filteredDocs?.map((doc) => (
              <Artist
                key={doc.id}
                //@ts-ignore
                artist={doc}
                isCustomApp={!!customApp}
              />
            ))}
            {!customApp && docs?.length > 19 && (
              <ArtistContainer isCustomApp={!!customApp}>
                <Avatar artistCounter>
                  <FlexColumn
                    xCenter
                    yCenter
                    style={{ width: "90%", height: "100%" }}
                  >
                    <Counter>{docs?.length - 19}</Counter>
                    more {language.event.owner.plural}
                  </FlexColumn>
                </Avatar>
                {/* </CounterCircle> */}
              </ArtistContainer>
            )}
            {!customApp &&
              pendingSignupsDocs?.map(({ artistImage, artistName }) => (
                <PendingArtist image={artistImage} name={artistName} />
              ))}
          </ContainerPadding>
          {!docs?.length &&
            !pendingSignupsDocs?.length &&
            (search ? (
              <NoResults>No results found</NoResults>
            ) : (
              <NoArtistContainer>
                <NoArtist />
                <H3 style={{ margin: "20px auto 30px", textAlign: "center" }}>
                  It looks like you don’t have any <br />{" "}
                  {customApp
                    ? customApp.event.owner.plural.toLowerCase()
                    : "artists"}{" "}
                  on your account yet.
                </H3>
                {!customApp && (
                  <PrimaryButton
                    style={{
                      width: "fit-content",
                    }}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_SIGNUP_URL}/?start`,
                        "_self",
                      )
                    }
                  >
                    <AddSvg />
                    Add an Artist
                  </PrimaryButton>
                )}
              </NoArtistContainer>
            ))}
        </>
      )}
    </Container>
  );
  return customApp ? content : <TopBarLayout>{content}</TopBarLayout>;
};

interface CustomAppProp {
  isCustomApp: boolean;
}

const Container = styled.div<CustomAppProp>`
  padding: ${({ isCustomApp }) => (isCustomApp ? 0 : "12px 18px 24px")};
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

interface ContainerPaddingProps extends CustomAppProp {
  sm?: boolean;
  md?: boolean;
}

const ContainerPadding = styled.div<ContainerPaddingProps>`
  width: 100%;
  max-width: 1550px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  z-index: 5;

  ${({ sm }) =>
    sm &&
    css`
      grid-template-columns: 1fr 1fr;
      max-width: 850px;
      gap: 5px;
    `}

  ${({ isCustomApp }) =>
    isCustomApp &&
    css`
      row-gap: 30px;
      column-gap: 5vw;

      ${({ theme }) => theme.media.tablet} {
        row-gap: 25px;
        column-gap: 10w;
      }

      ${({ theme }) => theme.media.mobile} {
        row-gap: 20px;
        column-gap: 20w;
      }
    `}
`;

const NoArtistContainer = styled.div`
  display: flex;
  margin: 100px auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 5;
  max-width: 400px;
`;
const Controls = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 5;
  max-width: 1550px;
  padding: 20px 30px;

  ${({ theme }) => theme.media.mobile} {
    padding: 10px 20px;
  }
`;
const ControlsWrap = styled.div<{ hasSearch: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  z-index: 5;
  margin-top: 15px;
  ${({ hasSearch }) =>
    hasSearch &&
    css`
      margin-bottom: 30px;
    `}
`;

const StyledButton = styled(PrimaryButton)`
  max-width: 200px;
  min-width: fit-content;
  @media (max-width: 768px) {
    border-color: transparent;
  }
`;
const Counter = styled.div`
  display: block;
  font-size: 32px;
`;
//@ts-ignore
const Artist = ({ artist, isCustomApp }) => {
  const data = artist;
  return (
    //@ts-ignore
    <ArtistContainer as={Link} to={`/${artist.id}/`} isCustomApp={isCustomApp}>
      <Avatar src={data?.assets?.icon?.path || defaultImg} />
      <Name>{data?.name}</Name>
    </ArtistContainer>
  );
};

const Header = styled.div`
  color: #333333;
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 39px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const ArtistContainer = styled.div<CustomAppProp>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding: 30px 10px 0;
  max-width: 340px;
  cursor: pointer;
  transition: all ease-in-out 100ms;

  &:hover {
    transform: scale(1.05);
  }

  ${({ isCustomApp }) =>
    isCustomApp &&
    css`
      padding: 0;
      max-width: unset;

      ${Avatar} {
        width: 100%;
      }
    `}
`;

const Avatar = styled.div<{ src?: string; artistCounter?: boolean }>`
  width: 90%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  border-radius: 300px;
  border: 2px solid white;
  box-shadow: 0 0px 17px 9px rgba(0, 0, 0, 0.16);
  aspect-ratio: 1/1 !important;
  z-index: 15;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    z-index: 20;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    object-fit: cover;
  }

  ${({ artistCounter }) =>
    artistCounter &&
    css`
      background-color: #dbe4e4d7;
      border: none;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: clamp(18px, 2.5vw, 25px);
      color: #999999;
      font-weight: 500;
    `}
`;

const Name = styled.div`
  color: var(--text-color);
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  padding: 20px;
  text-align: center;
  @media (max-width: 1100px) {
    font-size: clamp(13px, 2vw, 20px);
    line-height: 150%;
  }
`;

const ArtistPendingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px 0;
  max-width: 340px;
`;

const ArtistPendingOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  color: white;

  ${H3}, ${H4} {
    color: white;
    text-align: center;
  }

  ${H3} {
    width: 145px;
  }

  ${H4} {
    width: 120px;
  }

  ${({ theme }) => theme.media.mobile} {
    svg {
      width: 35px;
      height: 35px;
    }
  }
`;

const NoResults = styled(Header)`
  color: #666666;
  margin-top: 40px;
  z-index: 1;
`;
