import styled from "styled-components";
import { SvgCheck } from "../Svgs/Check";
import { getBgColor, getBorderColor } from "./utils";

const CheckIcon = styled(SvgCheck)`
  color: white;
  object-fit: contain;
`;

export const CheckIconContainer = styled.div<Props>`
  height: 14px;
  width: 14px;
  border: 1px solid white;
  border-radius: 2px;
  margin: 3px;

  background: ${({ isSelected, disabled, theme }) =>
    getBgColor(theme, isSelected, disabled)};

  border-color: ${({ isSelected, disabled, theme }) =>
    getBorderColor(theme, isSelected, disabled)};

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

type Props = {
  isSelected: boolean;
  disabled?: boolean;
};

export const CheckboxInput = ({ isSelected, disabled }: Props) => {
  return (
    <CheckIconContainer
      isSelected={isSelected}
      disabled={disabled}
      tabIndex={0}
    >
      {isSelected && <CheckIcon />}
    </CheckIconContainer>
  );
};
