import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { Button } from "melodies-source/Button";
import { H2, H3, Subtitle1 } from "melodies-source/Text";
import { Survey } from "models/survey";
import { Card } from "Components/Card";
import { useDesktopMediaQuery } from "hooks";
import { BarChart, BarChartLoader } from "../../Components/BarChart";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import Skeleton from "react-loading-skeleton";
import { formatNumber } from "Utils/format";
import { FlexColumn } from "Components/Flex";

interface Props {
  survey: Survey;
}

export const SurveyCard: React.FC<Props> = ({ survey }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isDesktop = useDesktopMediaQuery();
  const isMobile = useMobileMediaQuery();

  const responsesCount = survey.responses;

  const Header = isMobile ? HeaderMobile : HeaderDesktop;
  const ResponsesCount = isMobile
    ? ResponsesCountMobile
    : ResponsesCountDesktop;

  const responsesCountComponent = (
    <ResponsesCount>
      {`${formatNumber(responsesCount)} ${
        responsesCount === 1 ? "Response" : "Responses"
      }`}
    </ResponsesCount>
  );
  return (
    <StyledCard isElevated key={survey.label}>
      <Header>{survey.label}</Header>
      <Container>
        {!isDesktop && responsesCountComponent}
        <BarChart survey={survey} />
        <FlexColumn>
          {isDesktop && responsesCountComponent}
          <ViewDetailsButton
            variant="primary"
            onClick={() => history.push(`${pathname}/surveys/${survey.id}`)}
          >
            View Details
          </ViewDetailsButton>
        </FlexColumn>
      </Container>
    </StyledCard>
  );
};

export const SurveyCardLoader = () => {
  const isMobile = useMobileMediaQuery();
  const isDesktop = useDesktopMediaQuery();
  const Header = isMobile ? HeaderMobile : HeaderDesktop;

  const ResponsesCount = isMobile
    ? ResponsesCountMobile
    : ResponsesCountDesktop;
  const responsesCountComponent = (
    <ResponsesCount>
      <Skeleton />
    </ResponsesCount>
  );

  return (
    <StyledCard isElevated>
      <Header>
        <Skeleton width={300} />
      </Header>
      <Container>
        {!isDesktop && responsesCountComponent}
        <BarChartLoader />
        <div>
          {isDesktop && responsesCountComponent}
          <Skeleton
            width={isMobile ? "100%" : 180}
            height={isMobile ? 38 : 44}
          />
        </div>
      </Container>
    </StyledCard>
  );
};

const ViewDetailsButton = styled(Button)`
  width: 180px;
  align-self: flex-end;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    align-self: center;
  }
`;

const ResponsesCountStyle = css`
  color: var(--text-color);
  margin-bottom: 10px;

  ${({ theme }) => theme.media.mobile} {
    text-align: center;
  }
`;

const ResponsesCountDesktop = styled(H2)`
  ${ResponsesCountStyle}
`;

const ResponsesCountMobile = styled(Subtitle1)`
  ${ResponsesCountStyle}
`;

const HeaderStyle = css`
  color: var(--text-color);

  ${({ theme }) => theme.media.mobile} {
    text-align: center;
  }
`;

const HeaderDesktop = styled(H2)`
  ${HeaderStyle}
`;

const HeaderMobile = styled(H3)`
  ${HeaderStyle}
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
`;

const StyledCard = styled(Card)`
  padding: 31px 40px 24px 40px;
  box-shadow: 0px 4px 12px var(--box-shadow-color);

  ${({ theme }) => theme.media.mobile} {
    padding: 25px;
  }
`;
