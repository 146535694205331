import { CategoryOptionId } from "@max/common";
import { ReactComponent as SvgAcohol } from "assets/svg/alcohol.svg";
import { ReactComponent as SvgBag } from "assets/svg/bag.svg";
import { ReactComponent as SvgCar } from "assets/svg/car.svg";
import { ReactComponent as SvgEarth } from "assets/svg/earth.svg";
import { ReactComponent as SvgSpoonAndFork } from "assets/svg/spoon-and-fork.svg";
import { ReactComponent as SvgTv } from "assets/svg/tv.svg";

export const CATEGORIES_OPTIONS: readonly {
  id: CategoryOptionId;
  header: string;
  subHeader: string;
  icon: JSX.Element;
}[] = [
  {
    id: "demographics",
    header: "Demographics and Streaming",
    subHeader:
      "Discover the age and gender makeup of your fans along with their streaming and social habits.",
    icon: <SvgEarth />,
  },
  {
    id: "fooddrink",
    header: "Food and Drink",
    subHeader:
      "Ask fans about their favorite food, drink and restaurants to identify potential sponsors.",
    icon: <SvgSpoonAndFork />,
  },
  {
    id: "alcohol",
    header: "Alcohol",
    subHeader:
      "Learn about fans' alcoholic drink preferences to find a brand sponsor (asked of 21+ only).",
    icon: <SvgAcohol />,
  },
  {
    id: "clothing",
    header: "Clothing and Accessories",
    subHeader:
      "Find out what clothing, fashion, and makeup your fans like as well as how/where they shop.",
    icon: <SvgBag />,
  },
  {
    id: "vehicle",
    header: "Vehicles",
    subHeader:
      "Discover what vehicle your fans would like and what they think would be a good sponsor.",
    icon: <SvgCar />,
  },
  {
    id: "entertainment",
    header: "Lifestyle and Hobbies",
    subHeader:
      "Target your marketing and find a sponsor by asking questions about hobbies and gadgets.",
    icon: <SvgTv />,
  },
] as const;
