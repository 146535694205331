const HelpIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2ZM10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM10.6533 13.6251V15H9.35307V13.6251H10.6533ZM10.0127 5C10.9218 5 11.6469 5.26157 12.1882 5.78471C12.7294 6.30785 13 6.94053 13 7.68276C13 8.11201 12.9049 8.50771 12.7146 8.86989C12.5455 9.19182 12.2327 9.57557 11.7762 10.0212L11.4648 10.3176L11.2341 10.5448C11.062 10.7198 10.9403 10.859 10.8689 10.9624C10.7548 11.1279 10.6702 11.3179 10.6152 11.5325C10.5681 11.7165 10.5381 11.999 10.5251 12.3801L10.5201 12.5788H9.42283L9.41649 12.2166C9.41649 11.7427 9.47992 11.3336 9.60677 10.9893C9.69979 10.7299 9.84989 10.4684 10.0571 10.2046L10.1298 10.1168L10.3198 9.9087L10.5693 9.6574L10.8784 9.36284C11.2738 8.99396 11.5307 8.69998 11.649 8.48089C11.7674 8.26179 11.8266 8.02258 11.8266 7.76325C11.8266 7.29376 11.6533 6.88129 11.3066 6.52582C10.9598 6.17036 10.5349 5.99262 10.0317 5.99262C9.54545 5.99262 9.13953 6.15359 8.81395 6.47552C8.52455 6.76168 8.32368 7.19092 8.21134 7.76325L8.17336 7.98457L7 7.83702L7.03399 7.59236C7.16451 6.79455 7.46713 6.17412 7.94186 5.73105C8.46406 5.24368 9.15433 5 10.0127 5Z"
        fill="var(--secondary-color)"
      />
    </svg>
  );
};

export default HelpIcon;
