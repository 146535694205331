import { ReactComponent as IconFavortieTrack } from "assets/svg/favorite-track.svg";
import { SurveyTemplate } from "../../types";
import { getContestDefaults } from "../helpers";

export const favPrevRelease: SurveyTemplate = {
  icon: <IconFavortieTrack />,
  title: "Favorite Previous Release",
  description:
    "Ask fans to share their favorite past single or album by the artist",
  templateId: "favPrevRelease",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: "Favorite Previous Release",
        },
      },
      ...getContestDefaults(formState),
    },
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-sweeps---f-793be5"
        : "pp-favorite-p-def222",
    fields: [
      {
        name: "format",
        type: "radio",
        label: "What format are these releases?",
        options: ["Singles", "EPs", "Albums"],
        getValidation: (fields) =>
          fields.format ? false : "You must select an option.",
      },
      {
        name: "tracks",
        type: "textarea",
        label: "Paste in the list below",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.tracks ? false : "This field cannot be left blank",
      },
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
