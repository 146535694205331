import * as React from "react";
import { SVGProps } from "react";
export const SvgEditAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.106 3c.458 0 .898.183 1.219.506l2.17 2.17a1.723 1.723 0 0 1 0 2.437L8.57 16.035A2.834 2.834 0 0 1 6.594 17H3l.002-3.65c.06-.738.403-1.424.927-1.886l7.958-7.957A1.72 1.72 0 0 1 13.107 3ZM6.545 15.602c.374-.027.722-.2 1.001-.52l5.294-5.293-2.628-2.628-5.325 5.323a1.45 1.45 0 0 0-.487.922v2.195h2.145Zm4.657-9.43 2.628 2.627 1.675-1.675a.323.323 0 0 0 0-.457l-2.172-2.173a.32.32 0 0 0-.454 0l-1.677 1.677Z"
      fill="currentColor"
    />
  </svg>
);
