import React from "react";
import { Card } from "Components";
import styled from "styled-components";
import { H2, Body1 } from "melodies-source/Text";
import { useArtistEventContext } from "contexts/ArtistEventContext";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const Sweepstakes: React.FC = () => {
  const { eventId } = useArtistEventContext();
  const { customApp } = useCustomAppContext();

  return (
    <Card
      action={{
        text: "Go to Event Sweepstakes",
        onClick: () =>
          (window.location.href = `${
            customApp?.onsiteAppUrl || process.env.REACT_APP_BS_URL
          }/${eventId}`),
      }}
    >
      <Container>
        <H2>Sweepstakes</H2>
        <Content>
          <Body1>
            To select or contact winners please visit the sweepstakes tab on
            Backstage.
          </Body1>
        </Content>
      </Container>
    </Card>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  text-align: center;
`;

const Content = styled.div`
  padding: 20px 20px 0;

  ${Body1} {
    text-align: center;
    color: var(--text-color);
    font-size: 16px;
  }
`;
