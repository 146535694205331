import { MenuBar, SideBar, Footer, SetLiveNotification } from "Components";
import styled, { css } from "styled-components";
import { useDesktopMediaQuery } from "hooks";
import backdropImg from "Components/Images/SET_BG2.jpg";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const DefaultLayout = ({ children, menu: Menu = MenuBar }) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <Container>
      <Menu />
      {!isDesktop && <SetLiveNotification />}
      <Body>
        <SideBar />
        <Main>
          <Content>{children}</Content>
          <Footer />
        </Main>
      </Body>
    </Container>
  );
};

export const TopBarLayout = ({
  children,
  menu: Menu = MenuBar,
  withFooter = false,
}) => {
  const { customApp } = useCustomAppContext();
  return (
    <>
      {!customApp && (
        <Backdrop src={backdropImg} alt="background concert image" />
      )}
      <Container>
        <Menu />
        <Body>
          <Main>
            <Content>{children}</Content>
            {withFooter && <Footer />}
          </Main>
        </Body>
      </Container>
    </>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const Body = styled.div`
  position: relative;
  display: -webkit-inline-box;
  flex-grow: 1;
`;
const Main = styled.div(
  () => css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  ({ theme }) => {
    if (!theme.custom) return null;
    const { background } = theme.custom.layout.content;
    return css`
      background: ${background};
    `;
  },
);

const Content = styled.div`
  flex-grow: 1;
  padding-bottom: 40px;
`;
const Backdrop = styled.img`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
