import React from "react";
//import { useArtistEventContext } from "Routes/Artist/Components";
import { H2 } from "melodies-source/Text";
import { InfoBars } from "Components/InfoBars";
import styled from "styled-components";
import { NoData } from "Components/NoData";
import { RefreshIcon } from "Components/RefreshIcon";
import { Card } from "Components/Card";
import { Spinner } from "melodies-source/Spinner";
import { useArtistEventContext } from "contexts/ArtistEventContext";

export const Impressions: React.FC = () => {
  const { impressions, impressionsLoading, impressionsError } =
    useArtistEventContext();

  const list = impressions
    ? Object.entries(impressions)
        .filter(([label]) => !!label)
        .map(([label, value]) => ({
          label,
          value,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : [];

  return (
    <StyledCard isElevated>
      <H2>Impressions by Section</H2>
      {impressionsLoading ? (
        <StyledSpinner />
      ) : impressionsError ? (
        <StyledRefreshIcon />
      ) : list.length > 0 ? (
        <InfoBars data={list} twoCols impressions />
      ) : (
        <NoData />
      )}
    </StyledCard>
  );
};

const StyledRefreshIcon = styled(RefreshIcon)`
  margin-top: 5px;
`;

const StyledSpinner = styled(Spinner)`
  margin: 20px 0 15px;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  ${H2} {
    margin-bottom: 20px;
    text-align: center;
  }
`;
