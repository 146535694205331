import { CircularLoader, Divider } from "Components";
import { useUser } from "auth";
import {
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  reauthenticateWithCredential,
} from "firebase/auth";
import { useEmailLogin } from "hooks";
import { Button } from "melodies-source/Button";
import { Body1, Selected } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import * as yup from "yup";
import { ErrorMessageWarning } from "Components/ErrorMessageWarning";
import { yupPassword } from "Utils/yupStrictPassword";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";

const LoaderContainer = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface Values {
  currentPassword: string;
}

const validationsSchema = yup.object().shape({
  currentPassword: yupPassword,
});

interface RenewCredentialsFormProps {
  onSucess?(): void;
}

export const RenewCredentialsForm = ({
  onSucess,
}: RenewCredentialsFormProps) => {
  const { user } = useUser();
  const [signInMethods, setSignInMethods] = useState<string[]>([]);
  const [isInvalidCredential, setIsInvalidCredential] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const { login, ui, isSendingEmail } = useEmailLogin();
  const isLoading = isAuthenticating || isSendingEmail;

  const hasSignInMethods = !!signInMethods.length;
  const hasEmailSignIn = signInMethods.includes(
    EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
  );
  const hasPasswordSignIn = signInMethods.includes(
    EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
  );

  const handleSendEmail = async () => {
    await login(user.email, "/account/password");
  };

  const handleSubmit = async (values: Values) => {
    try {
      setIsAuthenticating(true);
      setIsInvalidCredential(false);
      const credentials = EmailAuthProvider.credential(
        user.email,
        values.currentPassword,
      );
      await reauthenticateWithCredential(user, credentials);
      onSucess?.();
    } catch (err) {
      setIsInvalidCredential(true);
    } finally {
      setIsAuthenticating(false);
    }
  };

  useEffect(() => {
    fetchSignInMethodsForEmail(getAuth(), user.email).then((signInMethods) =>
      setSignInMethods(signInMethods),
    );
  }, [user.email]);

  return (
    <div>
      {!hasSignInMethods && (
        <LoaderContainer>
          <CircularLoader />
        </LoaderContainer>
      )}
      {hasSignInMethods && (
        <Body1 style={{ marginBottom: 20 }}>
          {hasEmailSignIn && hasPasswordSignIn ? (
            <>
              To change your password you will need to renew your login by
              either sending a login email (via the link below) and clicking the
              link in the email or by entering your current password and hitting
              "Verify Login".
            </>
          ) : (
            <>
              To add a new password you will need to renew your login by sending
              a login email (via the link below) and clicking the link in the
              email.
            </>
          )}
        </Body1>
      )}
      {hasEmailSignIn && (
        <>
          <Button
            onClick={handleSendEmail}
            style={{ width: "100%" }}
            disabled={isLoading}
            loading={isSendingEmail}
          >
            Send Email
          </Button>
          {ui}
        </>
      )}
      {hasEmailSignIn && hasPasswordSignIn && (
        <div style={{ display: "flex", alignItems: "center", marginTop: 28 }}>
          <Divider />
          <Selected
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            OR
          </Selected>
          <Divider />
        </div>
      )}
      {hasPasswordSignIn && (
        <Formik<Values>
          initialValues={{ currentPassword: "" }}
          validationSchema={validationsSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ values, errors, handleChange }) => {
            return (
              <Form style={{ marginTop: 28 }}>
                <TextInput
                  label="Current Password"
                  type="password"
                  name="currentPassword"
                  placeholder="Enter password..."
                  value={values.currentPassword}
                  onChange={handleChange("currentPassword")}
                  hasError={!!errors.currentPassword}
                  helperText={errors.currentPassword}
                />
                {isInvalidCredential && (
                  <ErrorMessageWarning style={{ marginTop: 30 }}>
                    You&apos;ve entered an incorrect password.
                  </ErrorMessageWarning>
                )}
                <Button
                  type="submit"
                  variant="secondaryPrimary"
                  style={{ marginTop: 35, width: "100%" }}
                  disabled={isLoading}
                  loading={isAuthenticating}
                >
                  Verify Login
                </Button>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
