import { useEffect, useState } from "react";
import {
  RVValueEventHandler,
  RadialChart as RadialChartBase,
  RadialChartPoint,
} from "react-vis";
import styled, { css } from "styled-components";
import { DataPoint, chartColors } from "./common";
import { Legend } from "./legend";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { NoData } from "Components/NoData";

export interface Item {
  id: string;
  label: string;
  value: number;
}

export type Props = {
  data: DataPoint[];
  chartDimensions?: number;
  withVerticalLayout?: boolean;
  defaultSelectedItemLabel?: string;
  withChart?: boolean;
  legendLabel?: string;
  withLegend?: boolean;
  withValue?: boolean;
  onSelectItem?: (selectedItemLabel: string | undefined) => void;
};

export const DonutChart = ({
  data = [],
  defaultSelectedItemLabel,
  legendLabel,
  chartDimensions = 210,
  withVerticalLayout = true,
  withChart = true,
  withLegend = true,
  withValue = false,
  onSelectItem,
  ...props
}: Props) => {
  const [selectedItemLabel, setSelectedItemLabel] = useState<
    string | undefined
  >(undefined);
  const { customApp } = useCustomAppContext();

  useEffect(() => {
    setSelectedItemLabel(defaultSelectedItemLabel);
  }, [defaultSelectedItemLabel]);

  const chartData: RadialChartPoint[] = data.map((point, i) => {
    const opacity =
      point.label === selectedItemLabel ? 1 : selectedItemLabel ? 0.25 : 1;
    const total = data.reduce((sum, item) => (sum += item.value), 0);

    const colors = customApp?.theme.charts.donut.colors || chartColors;

    return {
      ...point,
      angle: (point.value / total) * 100,
      value: point.value,
      color: colors[i % colors.length],
      opacity: opacity,
      innerRadius: 220,
      radius: 318,
    };
  });

  const selectItem = (label: string | undefined) => {
    setSelectedItemLabel(label);
    if (onSelectItem) onSelectItem(label);
  };

  type HandleMouseOver = RVValueEventHandler<RadialChartPoint> | undefined;
  const handleMouseOver: HandleMouseOver = (item) => {
    const _selectedItemLabel = chartData.find(
      ({ label }) => label === item?.label,
    )?.label;

    selectItem(_selectedItemLabel);
  };

  const handleMouseLeave = () => {
    selectItem(undefined);
  };

  return data.length === 0 ? (
    <NoData />
  ) : (
    <DonutChartContainer withVerticalLayout={withVerticalLayout} {...props}>
      {withChart && (
        <DonutChartWrapper
          withVerticalLayout={withVerticalLayout}
          onMouseLeave={handleMouseLeave}
        >
          <DonutChartRadialChart
            height={chartDimensions}
            width={chartDimensions}
            data={chartData}
            colorType="literal"
            onValueMouseOver={handleMouseOver}
          />
        </DonutChartWrapper>
      )}
      {(withLegend || !withChart) && (
        <Legend
          label={legendLabel}
          withValue={withValue}
          data={data}
          selectedItemLabel={selectedItemLabel}
          setSelectedItemLabel={selectItem}
        />
      )}
    </DonutChartContainer>
  );
};

export const Component = DonutChart;

type ContainerProps = {
  withVerticalLayout?: boolean;
};

export const DonutChartContainer = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  ${({ withVerticalLayout }) =>
    withVerticalLayout &&
    css`
      flex-direction: column;
      gap: 20px;
    `}
`;

export const DonutChartWrapper = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DonutChartRadialChart = styled(RadialChartBase)`
  position: relative;
`;
