import styled from "styled-components";
import { useArtistContext, withAdminRequired } from "Components";
import { SettingsRow } from "../";
import { AddTeammateModal } from "./AddTeammateModal";
import { Teammate } from "./Teammate";
import { useDesktopMediaQuery } from "hooks";
import { AccessRequestArchive } from "./AccessRequestArchive";
import {
  query,
  collection,
  where,
  getFirestore,
  Query,
  QuerySnapshot,
  DocumentReference,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { ArtistSignup, Timestamp } from "models/artistSignup";
import { Button } from "melodies-source/Button";
import { useCustomAppContext } from "contexts/CustomAppContext";

export interface Member {
  uid: string;
  status: ArtistSignup["status"];
  name: string;
  email: string;
  signupId?: string;
  size?: ArtistSignup["size"];
  company?: string;
  title?: string;
  companyLink?: string;
  profileLink?: string;
  workAndPlan?: string;
  verified?: boolean;
  roles?: string[];
  affiliation?: string;
  action?: ArtistSignup["action"];
  requestedAt?: Timestamp;
  reviewedBy?: string;
  reviewedAt?: Timestamp;
  ref?: DocumentReference<unknown>;
}

export const ArtistTeammates = () => {
  const { artistGroup, id: artistGroupId, isAdmin } = useArtistContext();
  const isDesktop = useDesktopMediaQuery();
  const [requestAccessSignups] = useCollection(
    query(
      collection(getFirestore(), "artist_signups"),
      where("artistGroupId", "==", artistGroupId),
      where("action", "==", "request-access"),
      where("status", "==", "submitted"),
    ) as Query<ArtistSignup>,
  );
  const [accessRequestArchive] = useCollection(
    query(
      collection(getFirestore(), "artist_signups"),
      where("artistGroupId", "==", artistGroupId),
      where("action", "==", "request-access"),
      where("status", "in", ["approved", "rejected"]),
    ) as Query<ArtistSignup>,
  );

  const [addUserSignups] = useCollection(
    query(
      collection(getFirestore(), "artist_signups"),
      where("artistGroupId", "==", artistGroupId),
      where("action", "==", "add-user"),
      where("status", "==", "incomplete"),
    ) as Query<ArtistSignup>,
  );
  const { customApp, language } = useCustomAppContext();

  const getMembersFromSignups = (signups: QuerySnapshot<ArtistSignup>) =>
    (signups?.docs ? signups?.docs : [])
      .map((doc) => {
        const {
          uid,
          status,
          action,
          email,
          firstName,
          lastName,
          companyName,
          title,
          affiliation,
          createdAt,
          size,
          companyLink,
          profileLink,
          workAndPlan,
          reviewedBy,
          reviewedAt,
          lastEmailSentAt,
        } = doc.data();

        return {
          uid,
          signupId: doc.id,
          action,
          email,
          name: `${firstName} ${lastName}`,
          company: companyName,
          title,
          affiliation,
          status,
          requestedAt: lastEmailSentAt || createdAt,
          size,
          companyLink,
          profileLink,
          workAndPlan,
          reviewedBy,
          reviewedAt,
          ref: doc.ref,
        };
      })
      .sort(
        (a, b) =>
          (a.reviewedAt?.toMillis() || Number.MAX_VALUE) -
          (b.reviewedAt?.toMillis() || Number.MAX_VALUE),
      );

  const members: Member[] = [
    ...(artistGroup
      ? artistGroup?.members
          .map((member) => ({
            ...member,
            status: "approved" as const,
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      : []),
    ...getMembersFromSignups(requestAccessSignups),
    ...getMembersFromSignups(addUserSignups),
  ];

  const adminCount = (artistGroup?.members || []).filter(({ roles }) =>
    roles.includes("admin"),
  ).length;

  const membersArchive = getMembersFromSignups(accessRequestArchive);

  return (
    <SettingsRow
      title={"Teammates"}
      description={
        <>
          Your teammates will have access to the information in this{" "}
          {language.event.owner.singular.toLowerCase()}'s account including{" "}
          {customApp ? "contact" : "fan"} data.
          {!customApp && <AdminDescription />}
        </>
      }
      hasBorder={!customApp}
    >
      <TeamContainer isDesktop={isDesktop && !!artistGroup}>
        {members.map((member) => (
          <Teammate
            key={member.email}
            member={member}
            adminCount={adminCount}
          />
        ))}
      </TeamContainer>
      {isAdmin && (
        <Actions>
          <AddTeammateModal
            target={(props) => (
              <Button text {...props}>
                Add Another Teammate
              </Button>
            )}
          />
          {membersArchive.some((member) => member.status === "rejected") && (
            <AccessRequestArchive members={membersArchive} />
          )}
        </Actions>
      )}
    </SettingsRow>
  );
};

const AdminDescription = withAdminRequired(() => {
  return " Admin users also have the ability to change the Stripe account.";
});

const TeamContainer = styled.div<{ isDesktop: boolean }>``;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  ${(p) => p.theme.media.mobile} {
    flex-direction: column;
  }
`;
