import * as React from "react";
import { SVGProps } from "react";
export const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16,7 C16.5522847,7 17,7.44771525 17,8 L17,16 C17,16.5522847 16.5522847,17 16,17 L8,17 C7.44771525,17 7,16.5522847 7,16 L7,8 C7,7.44771525 7.44771525,7 8,7 L16,7 Z M12,3 C12.5522847,3 13,3.44771525 13,4 L13,6 L11,6 L11,5 L5,5 L5,11 L6,11 L6,13 L4,13 C3.44771525,13 3,12.5522847 3,12 L3,4 C3,3.44771525 3.44771525,3 4,3 L12,3 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
