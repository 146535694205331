import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Prompt as RouterPrompt } from "react-router-dom";

interface PromptEntry {
  id: string;
  when: boolean;
  message: string;
}

interface PromptContext {
  prompts: PromptEntry[];
  addPrompt: (id: string) => void;
  removePrompt: (id: string) => void;
  updatePrompt: (props: PromptEntry) => void;
}

const PromptContext = createContext<PromptContext>({
  prompts: [],
  addPrompt: () => {},
  removePrompt: () => {},
  updatePrompt: () => {},
});

export const PromptProvider = ({ children }: PropsWithChildren<{}>) => {
  const [prompts, setPrompts] = useState<PromptEntry[]>([]);

  const addPrompt = useCallback((id: string) => {
    setPrompts((p) => {
      const existing = p.find((entry) => entry.id === id);

      if (!existing) {
        return [{ id, when: false, message: "" }, ...p];
      }

      return p;
    });
  }, []);

  const removePrompt = useCallback((id: string) => {
    setPrompts((p) => p.filter((entry) => entry.id !== id));
  }, []);

  const updatePrompt = useCallback((props: PromptEntry) => {
    setPrompts((p) => {
      return p.map((entry) => {
        if (entry.id === props.id) {
          return props;
        }

        return entry;
      });
    });
  }, []);

  const currentPrompt = prompts.find((entry) => entry.when);

  return (
    <PromptContext.Provider
      value={{ prompts, addPrompt, removePrompt, updatePrompt }}
    >
      {currentPrompt && (
        <RouterPrompt
          when={currentPrompt.when}
          message={currentPrompt.message}
        />
      )}
      {children}
    </PromptContext.Provider>
  );
};

export const Prompt = (props: Omit<PromptEntry, "id">) => {
  const id = useMemo(() => window.crypto.randomUUID(), []);
  const context = useContext(PromptContext);

  useEffect(() => {
    context.addPrompt(id);
    return () => context.removePrompt(id);
  }, [id]);

  useEffect(() => {
    context.updatePrompt({ id, ...props });
  }, [id, props]);

  return null;
};
