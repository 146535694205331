import { FlexColumn } from "Components/Flex";
import styled, { css } from "styled-components";
import { Body2, H4 } from "melodies-source/Text";
import { SvgLockAlt } from "melodies-source/Svgs/LockAlt";
import { StyledCheck } from "./StyledCheck";

export type SelectionCardProps = {
  header: string;
  icon?: JSX.Element;
  onClick?: () => void;
  selected?: boolean;
  subHeader: string;
  className?: string;
  disabled?: boolean;
  locked?: boolean;
  children?: React.ReactNode;
};

export const SelectionCard = ({
  header,
  subHeader,
  icon,
  onClick,
  selected,
  className,
  disabled,
  locked,
  children,
  ...props
}: SelectionCardProps) => {
  return (
    <Container
      {...props}
      selected={selected || locked}
      disabled={disabled}
      className={className}
      {...(!disabled && { onClick })}
      {...(locked && { style: { pointerEvents: "none" } })}
    >
      <Content>
        {locked ? <StyledLock /> : selected ? <StyledCheck /> : null}
        {!!icon && <IconContainer>{icon}</IconContainer>}
        <FlexColumn>
          <H4>{header}</H4>
          <Body2>{subHeader}</Body2>
        </FlexColumn>
      </Content>
      {children}
    </Container>
  );
};

export const IconContainer = styled.div`
  margin-right: 16px;
  width: 44px;
  display: flex;
  justify-content: center;
`;

const StyledLock = styled(SvgLockAlt)`
  position: absolute;
  top: -8px;
  right: -5px;
  border-radius: 13px;
  background-color: #1b0076;
  width: 26px;
  height: 26px;
  color: #ffffff;
  padding: 4px;
`;

type ContainerProps = {
  selected?: boolean;
  disabled?: boolean;
};

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div<ContainerProps>`
  position: relative;
  background: #f4f8fa;
  border-radius: 24px;
  padding: 12px 24px;
  border: 2px solid #f4f8fa;

  display: flex;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;

  ${({ selected }) => selected && "border-color: #1B0076"};

  color: #1b0076;
  ${H4} {
    color: #1b0076;
  }
  ${Body2} {
    color: #666666;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      color: #999999;
      ${H4} {
        color: #999999;
      }
      ${Body2} {
        color: #cccccc;
      }
    `};
`;
