import { useArtistContext } from "Components";
import { useEffect, useState } from "react";
import IntegrationCard from "../IntegrationCard";
import styled, { CSSProperties } from "styled-components";
import { DateTime } from "luxon";
import { Button } from "melodies-source/Button";
import { useDesktopMediaQuery } from "hooks";
import {
  UMGIntegrationStatus,
  UMGDocument,
  UMGIntegrationCardProps,
} from "./types";
import { UMGIntegrationModal, UMGRemovalModal } from "./UMGModals";
import LastSyncIcon from "../LastSyncIcon";
import UMGLogo from "./UMGLogo";

const UMGIntegration = ({
  sx,
  umgDoc,
}: {
  sx?: CSSProperties;
  umgDoc: UMGDocument;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [integrationStatus, setIntegrationStatus] =
    useState<UMGIntegrationStatus>(UMGIntegrationStatus.NOT_CONNECTED);
  const [removalModalOpen, setRemovalModalOpen] = useState(false);

  const { id: artistGroupId } = useArtistContext();

  const lastSynced: DateTime | undefined = umgDoc?.lastSynced
    ? DateTime.fromMillis(umgDoc.lastSynced.toMillis())
    : undefined;

  useEffect(() => {
    if (
      umgDoc?.artistName &&
      umgDoc?.countryIsoCode &&
      (umgDoc?.sms || umgDoc?.email)
    ) {
      setIntegrationStatus(UMGIntegrationStatus.CONNECTED);
    } else {
      setIntegrationStatus(UMGIntegrationStatus.NOT_CONNECTED);
    }
  }, [umgDoc]);

  return (
    <>
      {modalOpen && (
        <UMGIntegrationModal
          artistGroupId={artistGroupId}
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
      <UMGRemovalModal
        artistGroupId={artistGroupId}
        onClose={() => {
          setRemovalModalOpen(false);
        }}
        isOpen={removalModalOpen}
      />
      <UMGIntegrationCard
        integrationStatus={integrationStatus}
        lastSynced={lastSynced}
        onRemoveUMG={() => setRemovalModalOpen(true)}
        setModalOpen={setModalOpen}
        sx={sx}
      />
    </>
  );
};

export default UMGIntegration;

const UMGIntegrationCard: React.FC<UMGIntegrationCardProps> = ({
  integrationStatus,
  lastSynced,
  onRemoveUMG,
  setModalOpen,
  sx,
}) => {
  const isDesktop = useDesktopMediaQuery();

  if (integrationStatus === UMGIntegrationStatus.CONNECTED) {
    return (
      <IntegrationCard
        header={<UMGLogo />}
        body={
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <LastSyncIcon />
              <StatusText isDesktop={isDesktop}>
                Last Sync Time:{" "}
                {lastSynced
                  ? lastSynced.toLocaleString(DateTime.TIME_SIMPLE)
                  : "Never Synced"}
              </StatusText>
            </div>
            <BodyText>You're connected to UMG!</BodyText>
          </>
        }
        footer={
          <FooterText
            style={{ marginTop: 9, textAlign: "center" }}
            onClick={onRemoveUMG}
          >
            Remove UMG
          </FooterText>
        }
        sx={sx}
      />
    );
  }

  // UMG is not yet connected
  return (
    <IntegrationCard
      header={<UMGLogo />}
      footer={
        <Button onClick={() => setModalOpen(true)}>
          <span style={{ marginRight: "0.35rem" }}>Connect to UMG</span>
          {/* <UMGLogo fill="white" /> */}
        </Button>
      }
      body="SET can automatically sync your contacts to your UMG account. Click the button below to get started."
      sx={sx}
    />
  );
};

const BodyText = styled.div`
  font-size: 10px;
  line-height: 170%;
  font-weight: 400;
  text-align: center;
  color: #666;
`;

const FooterText = styled.div`
  color: #180171;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0 auto;
  padding-bottom: 5px;
  cursor: pointer;
  bottom: 0;
`;

const StatusText = styled.div<{ isDesktop: boolean }>`
  color: #635bff;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 12px)" : "13px")};
  line-height: 19.5px;
  margin-left: 0.25rem;
`;
