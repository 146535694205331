import { useState } from "react";
import { updateDoc } from "firebase/firestore";
import styled from "styled-components";
import { useArtistContext } from "Components";
import { Modal } from "melodies-source/Modal";
import { TextInput } from "melodies-source/TextInput";
import { Confirmation } from "melodies-source/Confirmation";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const NameEditModal = ({ target: Target, ...props }) => {
  const { name: currentName, artistGroupDoc } = useArtistContext();
  const [name, setName] = useState(currentName);
  const [isOpen, setIsOpen] = useState(false);
  const { language } = useCustomAppContext();

  const handleSave = () => {
    updateDoc(artistGroupDoc.ref, { name });
    toggleModal();
  };

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <div>
      <Target {...props} onClick={toggleModal} />
      <Modal
        isOpen={isOpen}
        onClose={toggleModal}
        header={`Edit ${language.event.owner.singular} Name`}
      >
        <Confirmation
          confirmLabel="Save"
          onCancel={toggleModal}
          onConfirm={handleSave}
        >
          <TextInput value={name} onChange={setName} />
          <Note>
            Artist name is used across SET. services and may be seen by the
            general public when publishing content.
          </Note>
        </Confirmation>
      </Modal>
    </div>
  );
};

const Note = styled.p`
  margin-top: 10px;
  color: var(--secondary-text-color);
  font-family: Poppins;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 16px;
`;
