import styled from "styled-components";

type Position = "top-left" | "top-right" | "bottom-left" | "bottom-right";

const Badge = ({
  value,
  overflow,
  color,
  position = "top-right",
  onClick,
  children,
}: {
  value: string | number;
  overflow?: boolean;
  color?: string;
  position?: Position;
  onClick?: () => void;
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <Container onClick={onClick}>
      {children}
      {!!value && (
        <BadgeContainer color={color} position={position}>
          <BadgeText>
            {value}
            {overflow ? (
              <span style={{ verticalAlign: "super", fontSize: 9 }}>+</span>
            ) : undefined}
          </BadgeText>
        </BadgeContainer>
      )}
    </Container>
  );
};

export default Badge;

const Container = styled.div`
  position: relative;
`;

const BadgeContainer = styled.div<{ color?: string; position: Position }>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${({ color }) => color || "#e71e3d"};
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  ${({ position }) =>
    position === "top-left" || position === "top-right"
      ? "top: -9px"
      : "bottom: 6px"};

  ${({ position }) =>
    position === "top-right" || position === "bottom-right"
      ? "right: -6px"
      : "left: -8px"};
`;

const BadgeText = styled.span`
  color: #fff;
  font-family: Poppins;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;
