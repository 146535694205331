import { FlexColumn } from "Components/Flex";
import { Checkbox } from "melodies-source/Selectable";
import { SvgCheck } from "melodies-source/Svgs/Check";
import styled from "styled-components";
import { SelectedConcerts } from ".";
import { ListContainer } from "./ListContainer";
import { ConcertDetails, ListOption } from "./types";

interface CheckListProps {
  upcomingShows: ListOption[];
  unlinkedShows: ListOption[];
  manualShows: ConcertDetails<Date>[];
  setSelected: React.Dispatch<React.SetStateAction<SelectedConcerts>>;
  selected: SelectedConcerts;
}

export const CheckList = ({
  upcomingShows,
  unlinkedShows,
  manualShows,
  setSelected,
  selected,
}: CheckListProps) => {
  return (
    <FlexColumn style={{ height: "100%" }}>
      {(!!unlinkedShows.length || !!manualShows.length) && (
        <Checkbox
          label="Select/Deselect All"
          labelColor="navy"
          value={
            selected.unlinked.length + selected.manual.length ===
            unlinkedShows.length + manualShows.length
          }
          onChange={(checked) => {
            setSelected((s) => ({
              ...(checked ? s : { linked: [] }),
              unlinked: checked ? unlinkedShows.map(({ id }) => id) : [],
              manual: checked ? manualShows.map(({ id }) => id) : [],
            }));
          }}
        />
      )}
      <ListContainer>
        {upcomingShows
          .sort((a, b) => a.date.toMillis() - b.date.toMillis())
          .map((o) => {
            const type = o.isManual
              ? "manual"
              : o.setliveEventId
              ? "linked"
              : "unlinked";
            return (
              <Checkbox
                key={o.id}
                label={o.venue}
                labelColor="navy"
                description={
                  <>
                    {o.displayAddress}
                    <br />
                    {o.date.toFormat("ccc, LLL d, yyyy • h:mm a (ZZZZ)")}
                    {o.setliveEventId && (
                      <Badge>
                        <SvgCheck
                          style={{
                            width: 12,
                            height: 12,
                            margin: "2px 2px -2px 0",
                          }}
                        />
                        Has SET.Live Experience
                      </Badge>
                    )}
                  </>
                }
                onChange={(checked) => {
                  setSelected((s) => ({
                    ...s,
                    [type]: checked
                      ? s[type].concat(o.id)
                      : s[type].filter((v) => v !== o.id),
                  }));
                }}
                value={selected[type].includes(o.id)}
              />
            );
          })}
      </ListContainer>
    </FlexColumn>
  );
};

const Badge = styled.span`
  margin-top: 2px;
  display: block;
`;
