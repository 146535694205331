import { ReactComponent as TemplateIcon } from "assets/svg/todaysFit.svg";
import { SurveyTemplate } from "../../types";
import { getContestDefaults } from "../helpers";

export const todaysFit: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "Today's Fit",
  description:
    "Give your fans the chance to vote on which artist's merch you'll be wearing on the live.",
  templateId: "todaysFit",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: "Today's Fit",
        },
      },
      ...getContestDefaults(formState),
    },
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-livestream-d06416"
        : "pp-livestream-a54ffd",
    fields: [
      {
        name: "date",
        type: "text",
        label: "When does your livestream take place?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.date ? false : "This field cannot be left blank",
      },
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
