import React, { useState } from "react";
import { Layout } from "./Components/Layout";
import { ContactInfo } from "./ContactInfo";
import { Password } from "./Password";
import { AccountCreated } from "./AccountCreated";

const VIEWS = [ContactInfo, Password, AccountCreated];

export interface StepProps {
  handleBack: () => void;
  handleNext: () => void;
}

export const Signup: React.FC<React.PropsWithChildren<{}>> = () => {
  const [step, setStep] = useState(1);

  const handleBack = () => {
    if (step === 1) return;
    setStep((step) => step - 1);
  };

  const handleNext = () => {
    if (step === VIEWS.length) return;
    setStep((step) => step + 1);
  };

  const Step = VIEWS[step - 1];

  return (
    <Layout>
      <Step handleBack={handleBack} handleNext={handleNext} />
    </Layout>
  );
};
