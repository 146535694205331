import styled from "styled-components";
import { useFanTableContext } from "./FanTableContext";
import { Body1 } from "melodies-source/Text";
import { PopupSummary } from "./PopupSummary";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { formatNumber } from "Utils/format";

export const TableHeaderContent = () => {
  const { found, filterCount, loading } = useFanTableContext();
  const { customApp } = useCustomAppContext();
  const eventAudiencePlural = customApp ? "Contacts" : "Fans";

  const total = formatNumber(found);

  return (
    <Container>
      {filterCount
        ? `Your Search Results`
        : `Reach Out To Your ${eventAudiencePlural}`}
      <Small>
        {loading ? (
          <Body1>Loading contacts...</Body1>
        ) : found > 0 && filterCount ? (
          <PopupSummary />
        ) : (
          <Body1>
            There are {total} contacts in your list{" "}
            {filterCount ? "matching your search" : ""}
          </Body1>
        )}
      </Small>
    </Container>
  );
};

const Container = styled.div`
  color: var(--header-text-color);
  padding-right: 20;
`;

const Small = styled.div`
  display: flex;
  max-width: 700px;
  position: relative;
  line-height: 22.5px;
  font-weight: 400;
  color: var(--header-text-color);
  span {
    font-size: clamp(12px, 1vw, 14px);
  }
`;
