import firebase from "firebase/compat/app";
import { MailchimpLink, MailchimpList, MailchimpMergeTags } from "./types";

type MailchimpListResponse = {
  lists: MailchimpList[];
  links: MailchimpLink[];
  total_items: number;
  constraints: {
    may_create: boolean;
    max_instances: number;
    current_total_instances: number;
  };
};

export const isMailchimpConnected = async (artistGroupId: string) => {
  const mailchimpDoc = await firebase
    .firestore()
    .doc(`/artist_groups/${artistGroupId}/artist_group_private/mailchimp`)
    .get();

  if (!mailchimpDoc.exists) {
    return false;
  }

  if (mailchimpDoc.get("secretPath") && mailchimpDoc.get("serverPrefix")) {
    return true;
  }

  return false;
};

export const getAudienceListsForArtist = async (artistGroupId: string) => {
  const response = await firebase
    .functions()
    .httpsCallable("services-mailchimpActions")({
    action: "get_lists",
    artistGroupId,
  });

  return response.data as MailchimpListResponse;
};

export const getListMergeTagsForArtist = async (
  listId: string,
  artistGroupId: string,
  listName: string,
) => {
  const response: { data: MailchimpMergeTags } = await firebase
    .functions()
    .httpsCallable("services-mailchimpActions")({
    action: "get_list_merge_tags",
    artistGroupId,
    listId,
    listName,
  });

  if (!Object.keys(response.data).length) {
    throw new Error("no merge keys");
  }

  const mergeTags = Object.entries(response.data).reduce(
    (acc, [fieldKey, mergeTag]) => {
      // if merge tag is not already included in mailchimp, preselect
      acc[fieldKey] = { ...mergeTag, selected: !mergeTag.included };
      return acc;
    },
    {} as MailchimpMergeTags,
  );

  return { mergeTags };
};

export const addMergeTagsToList = async (
  listId: string,
  listName: string,
  mergeFields: MailchimpMergeTags,
  artistGroupId: string,
) => {
  const { data }: { data: { updated: number; error?: string } } = await firebase
    .functions()
    .httpsCallable("services-mailchimpActions")({
    action: "add_merge_tags_to_list",
    listId,
    listName,
    mergeFields,
    artistGroupId,
  });

  if (data.error) {
    throw new Error(data.error);
  }

  return data;
};

export const initiateBackfill = async (artistGroupId: string) => {
  await firebase
    .firestore()
    .collection(
      `/artist_groups/${artistGroupId}/artist_group_private/mailchimp/backfills`,
    )
    .add({ backfillRequest: new Date() });
};

export const removeMailchimpAccount = async (artistGroupId: string) =>
  firebase.functions().httpsCallable("services-mailchimpActions")({
    action: "remove_mailchimp_account",
    artistGroupId,
  });

export const startOauth = (artistGroupId: string) => {
  const url = new URL("https://login.mailchimp.com/oauth2/authorize");
  url.searchParams.append("response_type", "code");
  url.searchParams.append(
    "client_id",
    process.env.REACT_APP_MAILCHIMP_CLIENT_ID,
  );
  url.searchParams.append(
    "redirect_uri",
    process.env.REACT_APP_MAILCHIMP_REDIRECT_URI,
  );
  url.searchParams.append("state", artistGroupId);
  window.open(url, "_blank");
};
