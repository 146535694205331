import React from "react";
import styled, { css } from "styled-components";
import { Body1, Subtitle2, Selected, Body2, Caption } from "../Text";
import { CheckboxInput } from "./CheckboxInput";
import { RadioInput } from "./RadioInput";
import { CSSProperties } from "styled-components";
import { DefaultTheme } from "styled-components";

const variants = ["radio", "checkbox"] as const;
type Variant = (typeof variants)[number];

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 6px 0;
  gap: 12px;
`;

const HoverIndicator = styled.div`
  background: var(--hover-background-color);
  height: 30px;
  width: 30px;
  border-radius: 100%;
  display: none;
  position: absolute;
  pointer-events: none;
`;

const SelectableContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  border-radius: 100%;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  &:hover {
    ${HoverIndicator} {
      display: block;
    }
  }
`;

const LabelContainer = styled.div<{ disabled?: boolean }>`
  flex: 1;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.black40 : "var(--text-color)"};
`;

const Label = styled(Body1)<{
  disabled?: boolean;
  color?: keyof DefaultTheme["colors"];
}>`
  font-weight: 500;
  color: ${({ theme, color, disabled }) =>
    color && !disabled ? theme.colors[color] : undefined};
`;

export type SelectableProps = {
  label?: string | JSX.Element;
  labelColor?: keyof DefaultTheme["colors"];
  description?: string | JSX.Element;
  value: boolean;
  variant?: Variant;
  disabled?: boolean;
  style?: CSSProperties;
  onChange?: (v: boolean) => void;
  hasError?: boolean;
  helperText?: string;
};

export const Selectable = ({
  label,
  labelColor,
  value,
  onChange,
  disabled = false,
  variant = "radio",
  description,
  hasError = false,
  helperText,
  ...props
}: SelectableProps) => {
  const toggle = () => {
    if (!disabled) {
      onChange?.(!value);
    }
  };
  const textUI = (
    <LabelContainer onClick={toggle} disabled={disabled}>
      {label && (
        <Label color={labelColor} disabled={disabled}>
          {label}
        </Label>
      )}
      {description && <Body2>{description}</Body2>}
    </LabelContainer>
  );

  return (
    <div>
      <Container {...props}>
        <SelectableContainer disabled={disabled} onClick={toggle}>
          {!disabled && <HoverIndicator />}
          <div
            style={{ zIndex: 1 }}
            tabIndex={0}
            onKeyDown={({ code }) => {
              if (code === "Space" || code === "Enter") {
                toggle();
              }
            }}
          >
            {variant === "radio" && (
              <RadioInput isSelected={value} disabled={disabled} />
            )}
            {variant === "checkbox" && (
              <CheckboxInput isSelected={value} disabled={disabled} />
            )}
          </div>
        </SelectableContainer>
        {label && textUI}
      </Container>
      {helperText && (
        <Caption
          style={{
            marginTop: "4px",
            color: `${hasError ? "#FF0000" : "#ffffff"}`,
          }}
        >
          {helperText}
        </Caption>
      )}
    </div>
  );
};

export const Checkbox = ({ ...props }: Omit<SelectableProps, "variant">) => (
  <Selectable variant="checkbox" {...props} />
);

export const Radio = ({ ...props }: Omit<SelectableProps, "variant">) => (
  <Selectable variant="radio" {...props} />
);
