import { Caption, TextInput, useArtistContext } from "Components";
import { ReactComponent as AddAlbum } from "assets/svg/add-album.svg";
import { ReactComponent as AddTracks } from "assets/svg/add-tracks.svg";
import {
  ArrayHelpers,
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
} from "formik";
import { Button } from "melodies-source/Button";
import { ModalHeaderClose, ModalHeaderContainer } from "melodies-source/Modal";
import { Body1 } from "melodies-source/Text";
import { useMemo } from "react";
import { CoverField } from "./CoverField";
import { SongRow } from "./SongRow";
import { SortableList } from "./SortableList";
import { SpotifySearch } from "./SpotifySearch";
import {
  ModalTitle,
  Section,
  SectionFooter,
  SectionList,
  SectionTitle,
} from "./components";
import { ALBUM_SCHEMA, Album, Song } from "./types";

interface CreateAlbumFormProps {
  onCancel?: () => void;
  onSubmit?: (album: Album) => void;
}

export const CreateAlbumForm = ({
  onCancel,
  onSubmit,
}: CreateAlbumFormProps) => {
  const { name: artistName } = useArtistContext();

  const initialValues = useMemo<Album>(
    () => ({
      id: window.crypto.randomUUID(),
      name: "",
      artist: artistName,
      src: "",
      visible: true,
      deletable: true,
      songs: [],
    }),
    [artistName],
  );

  const handleSubmit = (values: Album) => {
    onSubmit?.(values);
  };

  return (
    <Formik<Album>
      initialValues={initialValues}
      validationSchema={ALBUM_SCHEMA}
      onSubmit={handleSubmit}
    >
      {({ values, touched, errors, setFieldValue }) => (
        <Form>
          <ModalHeaderContainer>
            <div>
              <ModalTitle color="navy">
                <AddTracks />{" "}
                {values.name ? `Add Tracks to “${values.name}”` : "Add Tracks"}
              </ModalTitle>
              <Body1>
                Add tracks that don’t appear in your library. You will be
                prompted to create a new album.
              </Body1>
            </div>
            <ModalHeaderClose onClose={onCancel} />
          </ModalHeaderContainer>
          <SectionList>
            <FieldArray name="songs">
              {(helpers: ArrayHelpers<Song[]>) => (
                <>
                  <SpotifySearch
                    type="track"
                    onSelect={(song) =>
                      helpers.push({ ...song, deletable: true })
                    }
                  >
                    <SortableList id="new-album" helpers={helpers}>
                      {values.songs.map((song, index) => (
                        <SongRow
                          key={index}
                          index={index}
                          song={song}
                          helpers={helpers}
                          editable={false}
                        />
                      ))}
                    </SortableList>
                    {touched.songs &&
                      errors.songs &&
                      typeof errors.songs === "string" && (
                        <Caption style={{ marginTop: 20 }} error>
                          {errors.songs}
                        </Caption>
                      )}
                  </SpotifySearch>
                  <Section>
                    <SectionTitle style={{ marginTop: -5, marginBottom: 15 }}>
                      <AddAlbum /> Create an Album for Your Tracks
                    </SectionTitle>
                    <div style={{ marginBottom: 26, maxWidth: "50%" }}>
                      <Field name="name">
                        {({ field, meta }: FieldProps) => (
                          <TextInput
                            label="Album Name"
                            hasError={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                            asString={false}
                            {...field}
                          />
                        )}
                      </Field>
                    </div>
                    <CoverField
                      src={values.src}
                      error={touched.src && errors.src}
                      onComplete={(url) => setFieldValue("src", url)}
                    />
                  </Section>
                </>
              )}
            </FieldArray>
            <SectionFooter>
              <Button variant="tertiary" type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" style={{ width: 140 }}>
                Save
              </Button>
            </SectionFooter>
          </SectionList>
        </Form>
      )}
    </Formik>
  );
};
