import {
  SurveyBuilderRootDocument,
  SurveyBuilderVersionDocument,
} from "@max/common/dist/setfan";
import { DocumentReference, doc, getFirestore } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";

export const useSurveyBuilder = (surveyId: string) => {
  const [survey, isLoadingSurvey] = useDocument(
    doc(
      getFirestore(),
      `set_fan_builder_surveys/${surveyId}`,
    ) as DocumentReference<SurveyBuilderRootDocument>,
  );

  const docRef = survey
    ? (doc(
        getFirestore(),
        `${survey.ref.path}/versions/${survey.data().draftId}`,
      ) as DocumentReference<SurveyBuilderVersionDocument>)
    : null;

  const [version, isLoadingVersion] = useDocument(docRef);

  return {
    survey,
    version,
    isLoading: isLoadingSurvey || isLoadingVersion,
  };
};
