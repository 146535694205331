import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  className?: string;
  gap?: string;
}

export const TwoColumns: React.FC<Props> = ({
  children,
  className,
  gap = "40px",
}) => {
  return (
    <Container className={className} gap={gap}>
      {children}
    </Container>
  );
};

type ContainerProps = Pick<Props, "gap">;

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ gap }) => gap};

  ${({ theme }) => theme.media.mobile} {
    grid-template-columns: 1fr;
  }
`;
