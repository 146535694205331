const AddArtist = ({ fillColor }: { fillColor?: string }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00018 17.2496C4.44383 17.2496 0.750183 13.556 0.750183 8.99963C0.750183 4.44328 4.44383 0.749634 9.00018 0.749634C13.5565 0.749634 17.2502 4.44328 17.2502 8.99963C17.2502 13.556 13.5565 17.2496 9.00018 17.2496ZM9.00001 15.75C12.7279 15.75 15.75 12.7279 15.75 8.99998C15.75 5.27206 12.7279 2.24998 9.00001 2.24998C5.27209 2.24998 2.25001 5.27206 2.25001 8.99998C2.25001 12.7279 5.27209 15.75 9.00001 15.75ZM12.7502 8.24963H9.75018V5.24963H8.25018V8.24963H5.25018V9.74963H8.25018V12.7496H9.75018V9.74963H12.7502V8.24963Z"
        fill={fillColor ?? "var(--main-color)"}
      />
    </svg>
  );
};

export default AddArtist;
