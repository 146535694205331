import { ReactComponent as TemplateIcon } from "assets/svg/favShortForm.svg";
import { SurveyTemplate } from "../../types";
import { getContestDefaults } from "../helpers";

export const favoriteShortFormContent: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "Favorite Short Form Content",
  description:
    "Find out what social media content is resonating with your fans.",
  templateId: "favoriteShortFormContent",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: "Favorite Short Form Content",
        },
      },
      ...getContestDefaults(formState),
    },
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes" ? "pp-marketing-6bf3bf" : "pp-marketing-cec27a",
    fields: [
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
