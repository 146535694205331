import React from "react";
import { FlexRow } from "Components/Flex";
import { SvgClock as ClockIcon } from "melodies-source/Svgs/Clock";
import styled from "styled-components";

export const NoData: React.FC = () => {
  return (
    <StyledFlexRow gap="5px" yCenter xCenter>
      <ClockIcon />
      <span>Insufficient Data</span>
    </StyledFlexRow>
  );
};

const StyledFlexRow = styled(FlexRow)`
  color: var(--text-color);

  span {
    font-size: 14px;
  }
`;
