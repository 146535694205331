import React from "react";
import styled, { css } from "styled-components";
import { FlexRow } from "Components/Flex";
import { Item } from ".";
import useIsMounted from "hooks/useIsMounted";

interface Props {
  total: number;
  item: Item;
}

export const Bar: React.FC<Props> = ({ total, item }) => {
  const { mounted } = useIsMounted();

  let percentage = 0;
  if (item.actual) {
    percentage = Math.round(item.actual * 100);
  } else {
    percentage = total === 0 ? 0 : Math.round((item.value / total) * 100);
  }

  return (
    <Container>
      <Name>{item.label}</Name>
      <FlexRow yCenter gap="4%" flex="1">
        <Background>
          <Fill percentage={!mounted ? 0 : percentage}>&nbsp;</Fill>
        </Background>
        <Value>{percentage}%</Value>
      </FlexRow>
    </Container>
  );
};

const Name = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  width: 130px;
  color: var(--main-color);
  word-break: break-word;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    max-width: 100%;
  }
`;

const Value = styled.span`
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  color: #666666;
  width: 50px;
`;

const Background = styled.div(({ theme }) => {
  const { background } = theme.custom?.charts.bar.bar || {};
  return css`
    background: ${background || "#eee"};
    border-radius: 10px;
    width: 95%;
    ${({ theme }) => theme.media.tablet} {
      width: 85%;
    }
  `;
});

interface FillProps {
  percentage: number;
}

const Fill = styled.div<FillProps>(({ percentage, theme }) => {
  const { secondary } = theme.custom?.colors || {};
  return css`
    height: 16px;
    background: ${secondary ||
    "linear-gradient(90deg, #2385d9 0%, #1ad65a 112.22%)"};
    border-radius: 10px;
    width: ${`${percentage}%`};

    transition: width 1s;
  `;
});

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;
