import { customPrizeOption } from "Routes/SetFan/constants/prizes";
import { PromptFormState } from "./types";

export const getContestDefaults = (formState: PromptFormState) =>
  formState.hasSweeps === "Yes"
    ? {
        isContest: {
          content: true,
        },
        hasContestCustomRules: {
          content: null,
        },
        contestPrize: {
          content: {
            en: customPrizeOption.value,
          },
        },
        contestCustomPrizeDescription: {
          content: {
            en: formState.prize,
          },
        },
      }
    : {};
