import styled from "styled-components";

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid ${(p) => p.theme.colors.black20};
  border-radius: 6px;
  width: 100%;
  min-height: 0;
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
`;
