import { useReducer } from "react";
import {
  AutocompleteModule,
  BuilderConfigModule,
  DropdownModule,
  HTMLModule,
  ImageBuilderConfigModule,
  ImageModule,
  ImageQuestionBuilderConfigModule,
  ImageQuestionModule,
  LabeledBuilderConfigModule,
  MultiSelectBuilderConfigModule,
  MultiSelectQuestionModule,
  RankedBuilderConfigModule,
  RankedQuestionModule,
  SelectBuilderConfigModule,
  SingleSelectBuilderConfigModule,
  SingleSelectQuestionModule,
  SongsQuestionBuilderConfigModule,
  SongsQuestionModule,
  SurveyQuestionModule,
  TextBuilderConfigModule,
  TextModule,
  TextQuestionBuilderConfigModule,
  TextQuestionModule,
} from "@max/common/dist/setfan";
import { QuestionType } from "Routes/SetFan/Components/Modal/AddQuestion/BaseQuestionModal";
import { cloneDeep } from "lodash";

export type SurveyModulesState = {
  isOpen: boolean;
  route: QuestionType | "";
  questions: SurveyQuestionModule[];
  existing?: SurveyQuestionModule;
};

export type SurveyModulesAction =
  | "closeModal"
  | "openModal"
  | "setRoute"
  | "addModule"
  | "moveModule"
  | "updateModule"
  | "removeModule"
  | "setModules";

export type SurveyModulesDispatch = {
  type: SurveyModulesAction;
  payload?: any; // string | SurveyModule | number | { to: number; from: number };
};

function reducer(state: SurveyModulesState, action: SurveyModulesDispatch) {
  switch (action.type) {
    case "closeModal":
      return { ...state, isOpen: false, route: "", existing: undefined };
    case "openModal":
      return {
        ...state,
        isOpen: true,
        route: action.payload?.route ?? "",
        existing: action.payload?.existing,
      };
    case "setRoute":
      return { ...state, route: action.payload };
    case "addModule": {
      const clonedQuestions = cloneDeep(state.questions);
      clonedQuestions.push(action.payload);
      return { ...state, questions: clonedQuestions };
    }
    case "moveModule": {
      const questionToMove = state.questions.splice(action.payload.from, 1)[0];
      const clonedQuestions = cloneDeep(state.questions);
      clonedQuestions.splice(action.payload.to, 0, questionToMove);
      return { ...state, questions: clonedQuestions };
    }
    case "updateModule": {
      const newModule = action.payload;
      const modIdx = state.questions.findIndex(
        (question) => question.id === newModule.id,
      );
      if (modIdx < 0) {
        throw new Error(
          `unable to find existing module to update: ${newModule.id}`,
        );
      }
      const clonedQuestions = cloneDeep(state.questions);
      clonedQuestions.splice(modIdx, 1, newModule);
      return { ...state, questions: clonedQuestions };
    }
    case "removeModule": {
      const clonedQuestions = cloneDeep(state.questions);
      clonedQuestions.splice(action.payload, 1);
      return { ...state, questions: clonedQuestions };
    }
    case "setModules": {
      const clonedQuestions = cloneDeep(
        action.payload as SurveyQuestionModule[],
      );
      return { ...state, questions: clonedQuestions };
    }
    default:
      throw new Error(
        `unexpected action in survey questions reducer: ${action.type}`,
      );
  }
}

export function useAddSurveyModules(initialState?: BuilderConfigModule[]) {
  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    surveyQuestionsSliceToReducer,
  );

  return { state, dispatch };
}

function surveyQuestionsSliceToReducer(
  initialState?: BuilderConfigModule[],
): SurveyModulesState {
  if (!initialState || !initialState.length) {
    return {
      route: "",
      isOpen: false,
      questions: [],
    };
  }

  const surveyQuestions = initialState.map((question) => {
    switch (question.type) {
      case "Autocomplete": {
        const qType = question as SelectBuilderConfigModule;
        return new AutocompleteModule({
          actionRequired: qType.actionRequired,
          required: qType.required,
          label: qType.label,
          id: question.id,
          options: qType.options,
          randomizeOptions: !!qType.randomizeOptions,
        });
      }
      case "Dropdown": {
        const qType = question as SelectBuilderConfigModule;
        return new DropdownModule({
          actionRequired: qType.actionRequired,
          required: qType.required,
          label: qType.label,
          id: qType.id,
          options: qType.options,
          randomizeOptions: !!qType.randomizeOptions,
        });
      }
      case "Html": {
        const qType = question as LabeledBuilderConfigModule;
        return new HTMLModule({
          actionRequired: qType.actionRequired,
          label: qType.label,
          id: question.id,
        });
      }
      case "ImageQuestion": {
        const qType = question as ImageQuestionBuilderConfigModule;
        return new ImageQuestionModule({
          actionRequired: qType.actionRequired,
          required: qType.required,
          label: qType.label,
          id: qType.id,
          showLabels: qType.showLabels,
          options: qType.options,
        });
      }
      case "image": {
        const qType = question as ImageBuilderConfigModule;
        return new ImageModule({
          actionRequired: qType.actionRequired,
          label: qType.label,
          id: question.id,
          src: qType.src,
        });
      }
      case "MultipleSelectQuestion": {
        const qType = question as MultiSelectBuilderConfigModule;
        return new MultiSelectQuestionModule({
          actionRequired: qType.actionRequired,
          label: qType.label,
          id: qType.id,
          requiredMin: qType.requiredMin,
          requiredMax: qType.requiredMax,
          options: qType.options,
        });
      }
      case "RankedQuestion": {
        const qType = question as RankedBuilderConfigModule;
        return new RankedQuestionModule({
          actionRequired: qType.actionRequired,
          required: qType.required,
          label: qType.label,
          id: qType.id,
          displayOrder: qType.displayOrder,
          rankLow: qType.rankLow,
          rankHigh: qType.rankHigh,
          rankRange: qType.rankRange,
        });
      }
      case "SingleSelectQuestion": {
        const qType = question as SingleSelectBuilderConfigModule;
        return new SingleSelectQuestionModule({
          actionRequired: qType.actionRequired,
          required: qType.required,
          label: qType.label,
          id: qType.id,
          hidden: qType.hidden,
          options: qType.options,
        });
      }
      case "TextQuestion": {
        const qType = question as TextQuestionBuilderConfigModule;
        return new TextQuestionModule({
          actionRequired: qType.actionRequired,
          required: qType.required,
          label: qType.label,
          id: qType.id,
          multiline: qType.multiline,
        });
      }
      case "text": {
        const qType = question as TextBuilderConfigModule;
        return new TextModule({
          actionRequired: qType.actionRequired,
          label: qType.label,
          id: qType.id,
          caption: qType.caption,
          variants: qType.variants,
        });
      }
      case "SongsQuestion": {
        const qType = question as SongsQuestionBuilderConfigModule;
        return new SongsQuestionModule({
          label: qType.label,
          id: qType.id,
          options: qType.options,
          required: qType.required,
          requiredMax: qType.requiredMax,
        });
      }
      default:
        throw new Error(
          `invalid survey module found when rehydrating state: ${question.type}`,
        );
    }
  });

  return {
    route: "",
    isOpen: false,
    questions: surveyQuestions,
  };
}
