import React, { useEffect } from "react";
import { Header, Layout } from "../../Components/Layout";
import { ArtistSelect } from "Routes/ArtistSelect";
import styled from "styled-components";

interface Props {
  allowedGroupIds?: string[];
}

export const Programs: React.FC<Props> = ({ allowedGroupIds }) => {
  const HeaderComponent = allowedGroupIds ? Header : StyledHeader;
  const content = (
    <>
      <HeaderComponent withDecorator={false}>
        SPONSORSHIP PROGRAMS
      </HeaderComponent>
      <ArtistSelect
        {...(allowedGroupIds && {
          allowedGroupIds,
        })}
      />
    </>
  );

  useEffect(() => {
    localStorage.removeItem("gtb_region_id");
  }, []);

  return allowedGroupIds ? content : <Layout>{content}</Layout>;
};

const StyledHeader = styled(Header)`
  margin: 10px 0 30px;
`;
