import * as React from "react";
import { SVGProps } from "react";
export const SvgAudience = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14,10.5 C16.209139,10.5 18,12.290861 18,14.5 L8,14.5 C8,12.290861 9.790861,10.5 12,10.5 L14,10.5 Z M8,10.5 C8.30078722,10.5 8.59382023,10.5331997 8.87563446,10.5961346 C7.73219274,11.5124478 7,12.9207175 7,14.5 L2,14.5 C2,12.290861 3.790861,10.5 6,10.5 L8,10.5 Z M14,11.5 L12,11.5 C10.6937812,11.5 9.58254212,12.3348076 9.17070571,13.5 L16.8292943,13.5 C16.4174579,12.3348076 15.3062188,11.5 14,11.5 Z M6.80260459,11.5 L6,11.5 C4.69378117,11.5 3.58254212,12.3348076 3.17070571,13.5 L6.08327084,13.5 C6.20274522,12.7893667 6.44817168,12.1133244 6.80260459,11.5 Z M7,5.5 C8.1045695,5.5 9,6.3954305 9,7.5 C9,8.6045695 8.1045695,9.5 7,9.5 C5.8954305,9.5 5,8.6045695 5,7.5 C5,6.3954305 5.8954305,5.5 7,5.5 Z M13,5.5 C14.1045695,5.5 15,6.3954305 15,7.5 C15,8.6045695 14.1045695,9.5 13,9.5 C11.8954305,9.5 11,8.6045695 11,7.5 C11,6.3954305 11.8954305,5.5 13,5.5 Z M7,6.5 C6.44771525,6.5 6,6.94771525 6,7.5 C6,8.05228475 6.44771525,8.5 7,8.5 C7.55228475,8.5 8,8.05228475 8,7.5 C8,6.94771525 7.55228475,6.5 7,6.5 Z M13,6.5 C12.4477153,6.5 12,6.94771525 12,7.5 C12,8.05228475 12.4477153,8.5 13,8.5 C13.5522847,8.5 14,8.05228475 14,7.5 C14,6.94771525 13.5522847,6.5 13,6.5 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
