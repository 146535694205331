import { TextInput } from "Components";
import { Field, FieldProps, Form, Formik } from "formik";
import { Button } from "melodies-source/Button";
import { useMemo } from "react";
import { Section, SectionFooter, SectionList } from "./components";
import { SONG_SCHEMA, Song } from "./types";

interface SongFormProps {
  song: Song;
  onCancel?: () => void;
  onSubmit?: (values: Song) => void;
}

export const SongForm = ({ song, onCancel, onSubmit }: SongFormProps) => {
  const initialValues: Song = useMemo(() => structuredClone(song), []);

  const handleSubmit = (values: Song) => {
    onSubmit?.(values);
  };

  return (
    <Formik<Song>
      initialValues={initialValues}
      validationSchema={SONG_SCHEMA}
      onSubmit={handleSubmit}
    >
      <Form>
        <SectionList>
          <Section>
            <Field name="name">
              {({ field, meta }: FieldProps) => (
                <TextInput
                  label="Display Name"
                  hasError={meta.touched && !!meta.error}
                  helperText={
                    meta.touched && meta.error
                      ? meta.error
                      : `Note: this track will still appear as “${song.name}” if a user saves this track to a playlist.`
                  }
                  asString={false}
                  {...field}
                />
              )}
            </Field>
          </Section>
          <SectionFooter>
            <Button variant="tertiary" type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" style={{ width: 140 }}>
              Save
            </Button>
          </SectionFooter>
        </SectionList>
      </Form>
    </Formik>
  );
};
