import styled from "styled-components";
import { BarChart, BarChartItemProps } from "./BarChart";
import { FlexColumn, FlexRow } from "Components/Flex";
import { useList } from "react-firebase-hooks/database";
import { getDatabase, orderByKey, query, ref } from "firebase/database";
import { DateTime } from "luxon";
import app from "firebase-internal";
import { Body2, H2, Subtitle1, H4 } from "melodies-source/Text";
import { memo, useEffect, useState } from "react";
import { Card } from "Components/Card";
import { Divider } from "Components/Divider";
import { useArtistEventContext } from "contexts/ArtistEventContext";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { formatNumber } from "Utils/format";

interface Bucket extends BarChartItemProps {
  bucket: number;
}

// This number must match what is used on the backend to create the aggregations
const WINDOW_MINUTES = 15;
const WINDOW_SECONDS = 60 * WINDOW_MINUTES;
const HOURS_BEFORE = 1;
const HOURS_AFTER = 5;

/**
 *
 * @param bucket The 15-minute bucket index
 * @returns
 */
const createBucket = (
  bucket: number,
  highlight: boolean,
  value = 0,
): Bucket => ({
  bucket: bucket,
  value: value,
  label: DateTime.fromSeconds(bucket * WINDOW_SECONDS).toFormat("h:mm"),
  highlight: highlight,
  remaining: 0,
});

const getWindow = (dt: DateTime) => Math.floor(dt.toSeconds() / WINDOW_SECONDS);
const pluralize = (n: number, str: string, suffix = "s") =>
  `${str}${n !== 1 ? suffix : ""}`;

const ActivityInternal = () => {
  const db = getDatabase(app);
  const {
    eventId,
    event: { startsAt },
    eventLoading,
  } = useArtistEventContext();

  const [buckets, setBuckets] = useState<Bucket[]>([]);
  const [outsideWindowCounts, setOutsideWindowCounts] = useState({
    before: 0,
    after: 0,
  });

  const [entries, loading] = useList(
    query(ref(db, `setlive_events/${eventId}/entries/times`), orderByKey()),
  );

  const { customApp } = useCustomAppContext();
  const eventAudienceSingular = customApp?.event.audience.singular || "Fan";

  useEffect(() => {
    if (!eventLoading) {
      setOutsideWindowCounts({ before: 0, after: 0 });

      setBuckets((buckets) => {
        buckets = [];

        const firstBucket = getWindow(startsAt.minus({ hour: HOURS_BEFORE }));
        const showBucket = getWindow(startsAt);
        const lastBucket = getWindow(startsAt.plus({ hour: HOURS_AFTER }));

        for (let bucket = firstBucket; bucket <= lastBucket; bucket++) {
          buckets.push(createBucket(bucket, bucket === showBucket));
        }

        return buckets;
      });
    }
  }, [startsAt, eventId, eventLoading]);

  useEffect(() => {
    if (!entries || buckets.length === 0) {
      return;
    }

    setBuckets((buckets) => {
      const firstBucket = buckets[0];
      const lastBucket = buckets[buckets.length - 1];

      if (!firstBucket || !lastBucket) {
        return buckets;
      }

      const outsideWindowCounts = {
        before: 0,
        after: 0,
      };

      entries.forEach((snap) => {
        const bucket = parseInt(snap.key);
        if (typeof bucket !== "number") {
          return;
        }

        const value = parseInt(snap.val()) || 0;
        if (typeof value !== "number") {
          return;
        }

        if (bucket < firstBucket.bucket) {
          outsideWindowCounts.before += value;
          return;
        } else if (bucket > lastBucket.bucket) {
          outsideWindowCounts.after += value;
          return;
        }

        const bucketIdx = buckets.findIndex((b) => b.bucket === bucket);
        if (bucketIdx > -1) {
          buckets[bucketIdx].value = value;
        }
      });

      // Calculate a running total of values from all previous buckets in reverse
      // order.  When it comes to rendering the chart, we can ignore any data
      // points after the first bucket with zero `remaining`.
      for (let i = buckets.length - 1; i >= 0; i--) {
        buckets[i].remaining =
          (buckets[i + 1]?.remaining || 0) + buckets[i].value;
      }

      setOutsideWindowCounts(outsideWindowCounts);
      return buckets;
    });
  }, [entries, buckets]);

  return (
    <StyledCard isElevated>
      <div>
        <H2>Entries by Time</H2>
        <Body2>
          {eventAudienceSingular} entries over time, immediately before, during
          and right after the show
        </Body2>
      </div>
      <StyledDivider />
      <BarChart data={buckets} loading={loading} />
      <Body2 style={{ marginTop: 5 }}>
        Chart displays users registering within each {WINDOW_MINUTES} minute
        block of time. Labels are in {DateTime.local().toFormat("ZZZZZ")}.
      </Body2>
      <StyledDivider />
      <div>
        <Subtitle1>Additional {eventAudienceSingular} Entries</Subtitle1>
        <FlexRow style={{ width: "100%" }} xSpaceAround>
          <Box xCenter yStart>
            <Body2>Early Entries</Body2>
            <H4>{formatNumber(outsideWindowCounts.before)}</H4>
            <Body2>
              &gt;{HOURS_BEFORE} {pluralize(HOURS_BEFORE, "hour")} before start
            </Body2>
          </Box>
          <Box xCenter yStart>
            <Body2>Late Entries</Body2>
            <H4>{formatNumber(outsideWindowCounts.after)}</H4>
            <Body2>
              &gt;{HOURS_AFTER} {pluralize(HOURS_AFTER, "hour")} after start
            </Body2>
          </Box>
        </FlexRow>
      </div>
    </StyledCard>
  );
};

export const Activity = memo(ActivityInternal);

export const StyledCard = styled(Card)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 32px;
  }

  ${H2} {
    color: var(--header-text-color);
    margin-bottom: 4px;
    text-align: center;
  }

  ${Body2} {
    font-size: 14px;
    text-align: center;
    color: var(--secondary-text-color);
  }

  ${Subtitle1} {
    color: var(--header-text-color);
    margin-top: 4px;
    margin-bottom: 12px;
    text-align: center;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
  }
`;

const Box = styled(FlexColumn)`
  flex: 1 1 auto;
  padding: 12px;

  & + & {
    margin-left: 24px;
  }

  ${Body2}:first-child {
    font-weight: 600;
    color: var(--header-text-color);
  }
  ${Body2}:not(:first-child) {
    color: var(--secondary-text-color);
    font-weight: 500;
  }
  ${H4} {
    color: var(--header-text-color);
    font-weight: 600;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 20px 0;
`;
