import styled from "styled-components";
import { SortableCard, SortableCardProps } from "./Sortable/SortableCard";
import { Body1, Body2, H3, Subtitle2, Td } from "melodies-source/Text";
import { AnimatedCaret, FlexRow } from "Routes/styled";
import {
  ModuleType,
  QuestionEditProps,
  SurveyConfigOption,
} from "@max/common/dist/setfan";
import { Button } from "melodies-source/Button";
import { DefaultAlbum } from "./SongsQuestion/SortableRow";
import { RatingScale } from "./RatingScale";
import { pluralize } from "Utils/pluralize";
import { Accordion } from "Components/Accordion";
import { useState } from "react";
import { Divider } from "Components";

const TOOLTIPS = {
  freeResponseQuestion: `A Free Response Question allows users to enter whatever they want in response to your question. Results will be displayed in a word cloud that sorts by popularity, with a full list of responses available to view.`,
  singleSelectQuestion: `A Single Select Question allows fans to vote for only one option from a list provided by you. Typically this is a "what is your favorite" or "which best describes you" type question. The answers will be displayed in a bar chart and you will be able to segment your results by the fans' answers.`,
  multipleSelectQuestion: `A Multiple Select Question allows fans to vote for multiple options from a list provided by you. Typically this is a "check all that you like" or "check all that you do" type question. The answers will be displayed in a bar chart and you will be able to segment your results by the fans' answers.`,
  imageQuestion: `An Image Question allows fans to vote for their favorite image. Typically this is a "which design do you like best" type question. The answers will be displayed in a bar chart and you will be able to segment your results by the fans' answers.`,
  rankedQuestion: `A Rating Question allows fans give a numeric rating to something. Typically this is a "on a scale from 1-5" type question. Both the average rating and the distribution of ratings will be displayed to you.`,
  text: `This is a text block that can be used for explanation in the middle of a SET.Fan survey. It is not question and does not provide fans the ability to react.`,
  image: `This is an image block that can be used to display a design or photo on a survey. It is not question and does not provide fans the ability to react. Typically it is followed up with questions about the creative displayed in the block.`,
  songsQuestion:
    "A Dream Setlist Question allows users to create their dream setlist out of a preselected list of albums and tracks.",
};

export interface QuestionTypeCardProps
  extends Omit<SortableCardProps, "title">,
    QuestionEditProps {
  type: ModuleType;
}

export const QuestionTypeCard = ({
  type,
  question,
  options,
  setlist,
  image,
  rank,
  text,
  ...props
}: QuestionTypeCardProps) => {
  let renderedContent: JSX.Element | undefined;
  let renderedTitle = "Free Response";
  let helperText = TOOLTIPS.freeResponseQuestion;
  let actionRequiredText = "";

  switch (type) {
    case "text":
      renderedTitle = "Text";
      renderedContent = <Body1>{text?.caption}</Body1>;
      helperText = TOOLTIPS.text;
      actionRequiredText = "Text";
      break;
    case "MultipleSelectQuestion":
    case "SingleSelectQuestion":
    case "Autocomplete":
    case "Dropdown":
      renderedTitle =
        type === "MultipleSelectQuestion" ? "Multiple Choice" : "Single Choice";
      helperText =
        type === "MultipleSelectQuestion"
          ? TOOLTIPS.multipleSelectQuestion
          : TOOLTIPS.singleSelectQuestion;
      actionRequiredText = "Options";
      renderedContent = (
        <Ul>
          {options.map((opt) =>
            opt.userDefined ? (
              <Li key={opt.id}>
                {opt.label} <Body2 as="span">(User defined option)</Body2>
              </Li>
            ) : (
              <Li key={opt.id}>{opt.label}</Li>
            ),
          )}
        </Ul>
      );
      break;
    case "RankedQuestion":
      renderedTitle = "Rating Question";
      helperText = TOOLTIPS.rankedQuestion;
      actionRequiredText = "Options";
      renderedContent = (
        <RatingScale labels={[rank.low, rank.high]} scale={rank.range} />
      );
      break;
    case "ImageQuestion":
      renderedTitle = "Image Question";
      helperText = TOOLTIPS.imageQuestion;
      actionRequiredText = "Images";
      renderedContent = !!options.length && (
        <Images>
          {options.map((opt) => (
            <ImageContainer key={opt.id}>
              <Image src={opt.src} />
              <Subtitle2>{opt.label}</Subtitle2>
            </ImageContainer>
          ))}
        </Images>
      );
      break;
    case "image":
      renderedTitle = "Image";
      helperText = TOOLTIPS.image;
      actionRequiredText = "an Image";
      renderedContent = (
        <>
          {image.src && (
            <Image style={{ width: 120, height: 120 }} src={image.src} />
          )}
          {image.label && <Subtitle2>{image.label}</Subtitle2>}
        </>
      );
      break;
    case "SongsQuestion": {
      const songCount = options.reduce(
        (songCount, album) => (songCount += album.options?.length),
        0,
      );
      // const overflow = options.length > 3 ? options.length - 2 : undefined;
      renderedTitle = "Dream Setlist Question";
      helperText = TOOLTIPS.songsQuestion;
      renderedContent = (
        <>
          <Ul>
            <Li>
              Showing {songCount} song voting option
              {songCount === 1 ? "" : "s"}
            </Li>
            <Li>
              {typeof setlist?.requiredMax === "number"
                ? `Fans can select ${pluralize(setlist?.requiredMax, "song")}`
                : "Fans can select any number of songs"}
            </Li>
          </Ul>
          <AlbumsAccordion options={options} />
        </>
      );
    }
  }

  return (
    <SortableCard {...props} title={renderedTitle} helperText={helperText}>
      <H3>{question}</H3>
      {renderedContent}
      {props.actionRequired && (
        <ButtonContainer>
          <Button onClick={props.onEdit}>
            Add {actionRequiredText} to Complete
          </Button>
        </ButtonContainer>
      )}
    </SortableCard>
  );
};

interface AlbumsAccordionProps {
  options?: SurveyConfigOption[];
}

const AlbumsAccordion = ({ options }: AlbumsAccordionProps) => {
  const [openIndex, setOpenIndex] = useState(-1);

  return (
    <>
      <Divider />
      <StyledAccordion
        sections={options?.map((option, index) => ({
          open: openIndex === index,
          header: (
            <FlexRow style={{ gap: 10, alignItems: "center" }}>
              {option.src ? (
                <img
                  width={34}
                  height={34}
                  style={{ objectFit: "cover" }}
                  src={option.src}
                  alt="album"
                />
              ) : (
                <StyledDefaultAlbum />
              )}
              <Td>{option.label}</Td>
            </FlexRow>
          ),
          body: (
            <Ul style={{ margin: 0 }}>
              {option.options.map((option, index) => (
                <Li key={index}>{option.label}</Li>
              ))}
            </Ul>
          ),
          withTransition: false,
          onOpenChange: () =>
            openIndex === index ? setOpenIndex(-1) : setOpenIndex(index),
        }))}
        icons={{
          open: <AnimatedCaret direction="up" $withTransition={false} />,
          closed: <AnimatedCaret direction="down" $withTransition={false} />,
        }}
        showDivider={true}
      />
      <Divider />
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  min-width: 0;
  margin-top: 24px;
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 24px;
  row-gap: 24px;
  margin-top: 32px;
  width: 100%;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  min-width: 0;
  ${Subtitle2} {
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
  }
`;

const Image = styled.img`
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
`;

const Ul = styled.ul`
  padding: 0 0 0 20px;
  color: #333333;
  list-style: disc;
  margin-top: 12px;
`;

const Li = styled(Body1).attrs({ as: "li" })``;

const StyledAccordion = styled(Accordion)`
  flex-direction: column;
`;

const StyledDefaultAlbum = styled(DefaultAlbum)`
  margin-left: 0 !important;
  width: 34px;
  height: 34px;

  & > img {
    width: 34px;
    height: 34px;
  }

  & > svg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
  }
`;
