import styled from "styled-components";

export type DMAMapData = {
  title: string;
  audience_size: number;
  lat: number;
  lon: number;
  value: number;
};

export type WorldMapData = {
  iso_a2: string;
  value: number;
};

export const LegendContainer = styled.div`
  position: relative;
`;

export const LegendLabel = styled.p`
  color: #666666;
  font-family: Poppins;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 16px;
`;

export const LegendBar = styled.div`
  margin: 32px 0 8px 0;
  height: 8px;
  width: 280px;
  border-radius: 10px;
  background: linear-gradient(90deg, #dae0e3, #493391);
`;

export const MapContainer = styled.div`
  width: 0;
  height: 0;
  position: relative;

  .tooltip {
    pointer-events: none;
    cursor: default;
    position: absolute;
    padding: 4px 6px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.6);
  }
`;
