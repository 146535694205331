import { CenterFlexRow } from "Routes/styled";
import { H2 } from "melodies-source/Text";

import { SurveyQuestions as SurveyQuestionsSlice } from "../../slices/SurveyQuestions";

export const SurveyQuestions = () => {
  return (
    <>
      <CenterFlexRow
        style={{
          marginTop: 28,
          marginBottom: -32,
        }}
      >
        <H2 style={{ color: "#333" }}>Survey Questions</H2>
      </CenterFlexRow>
      <SurveyQuestionsSlice />
    </>
  );
};
