import { H3 } from "melodies-source/Text";
import { useArtistContext } from "Components";
import { SettingsRow } from "../";
import { NameEdit } from "./NameEdit";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const ArtistName = () => {
  const { name } = useArtistContext();
  const { customApp, language } = useCustomAppContext();

  return (
    <SettingsRow
      title={`${language.event.owner.singular} Name`}
      description={`${language.event.owner.singular} name is used across ${
        customApp?.company.name || "SET"
      } services and may be seen by the general public when publishing content.`}
    >
      <div style={{ display: "flex" }}>
        <H3>{name}</H3>
        <NameEdit />
      </div>
    </SettingsRow>
  );
};
