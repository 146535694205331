const NoArtist = () => {
  return (
    <svg
      width="93"
      height="93"
      viewBox="0 0 93 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.5001 13.9502C54.2045 13.9502 60.4501 20.1958 60.4501 27.9002C60.4501 35.6046 54.2045 41.8502 46.5001 41.8502C38.7957 41.8502 32.5501 35.6046 32.5501 27.9002C32.5501 20.1958 38.7957 13.9502 46.5001 13.9502ZM74.4001 69.7502C74.4001 56.9096 63.9907 46.5002 51.1501 46.5002H41.8501C29.0095 46.5002 18.6001 56.9096 18.6001 69.7502V79.0502H74.4001V69.7502ZM51.1501 51.1502L52.0784 51.173L53.0243 51.2435C62.1036 52.1519 69.2589 59.5896 69.7258 68.7921L69.7501 69.7502V74.4002H23.2501V69.7502L23.2729 68.8219L23.3434 67.876C24.2518 58.7967 31.6895 51.6414 40.892 51.1744L41.8501 51.1502H51.1501ZM37.2001 27.9002C37.2001 22.7639 41.3638 18.6002 46.5001 18.6002C51.6363 18.6002 55.8001 22.7639 55.8001 27.9002C55.8001 33.0364 51.6363 37.2002 46.5001 37.2002C41.3638 37.2002 37.2001 33.0364 37.2001 27.9002Z"
        fill="var(--main-color)"
      />
      <circle
        cx="73.4167"
        cy="69.9997"
        r="14.1667"
        fill="var(--content-background-color)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.417 69.9993C56.417 78.6058 63.3939 85.5827 72.0003 85.5827C80.6068 85.5827 87.5837 78.6058 87.5837 69.9993C87.5837 61.3929 80.6068 54.416 72.0003 54.416C63.3939 54.416 56.417 61.3929 56.417 69.9993ZM84.7503 69.9993C84.7503 77.041 79.042 82.7493 72.0003 82.7493C64.9587 82.7493 59.2503 77.041 59.2503 69.9993C59.2503 62.9577 64.9587 57.2493 72.0003 57.2493C79.042 57.2493 84.7503 62.9577 84.7503 69.9993ZM72.0007 78.4969C72.7834 78.4969 73.4179 77.8626 73.4179 77.0802C73.4179 76.2978 72.7834 75.6636 72.0007 75.6636C71.2181 75.6636 70.5836 76.2978 70.5836 77.0802C70.5836 77.8626 71.2181 78.4969 72.0007 78.4969ZM73.422 61.4969H70.5878V72.8302H73.422V61.4969Z"
        fill="var(--secondary-color)"
      />
    </svg>
  );
};

export default NoArtist;
