import React from "react";
import { Spinner } from "melodies-source/Spinner";
import styled from "styled-components";
import { H2, H1, H3 } from "melodies-source/Text";
import { Item as DonutChartItem, DonutChart } from "Components/DonutChart";
import { NoData } from "Components/NoData";
import { RefreshIcon } from "Components/RefreshIcon";
import { Card } from "Components/Card";
import { Divider } from "Components/Divider";
import { useArtistEventContext } from "contexts/ArtistEventContext";
import { formatNumber } from "Utils/format";

export const Entries: React.FC = () => {
  const { event, eventLoading, totals, totalsLoading, totalsError } =
    useArtistEventContext();
  const eventIsVersion2 = event?.version === 2;

  const getPercentage = (val, total) => {
    if (!val || !total) return 0;
    return Math.ceil((100 * val) / total);
  };

  let data: DonutChartItem[] = totals
    ? [
        {
          id: "Outside Venue",
          label: "Location Outside Venue",
          value: getPercentage(
            totals.all - totals.inVenue - totals.noLocation,
            totals.all,
          ),
        },
        {
          id: "Not Shared",
          label: "Location Not Shared",
          value: getPercentage(totals.noLocation, totals.all),
        },
        {
          id: "Within Venue",
          label: "Confirmed Within Venue",
          value: getPercentage(totals.inVenue, totals.all),
        },
      ]
    : [];

  const total = data?.reduce((acc, { value }) => (acc += value), 0);

  if (total > 100) {
    const highestTotal = Math.max.apply(
      null,
      data?.map(({ value }) => value),
    );
    const updateIndex = data.findIndex(({ value }) => value === highestTotal);
    data[updateIndex].value = data[updateIndex].value - (total - 100);
  }

  return (
    <StyledCard isElevated eventIsVersion2={eventIsVersion2}>
      {eventLoading ? (
        <Spinner />
      ) : (
        <>
          {eventIsVersion2 ? (
            <H2>Entries by Location</H2>
          ) : (
            <>
              {totalsLoading ? (
                <StyledSpinner />
              ) : totalsError ? (
                <StyledRefreshIcon />
              ) : (
                <H1>{formatNumber(totals?.all)}</H1>
              )}
              <H3>Total Entries</H3>
            </>
          )}
          {eventIsVersion2 && totalsLoading ? (
            <LoadingCircle>
              <Spinner color="#A499C8" />
            </LoadingCircle>
          ) : eventIsVersion2 && totalsError ? (
            <StyledRefreshIcon />
          ) : totals?.all > 0 ? (
            <>
              {!eventIsVersion2 && <Divider />}
              <DonutChart data={data} />
            </>
          ) : (
            eventIsVersion2 && <NoData />
          )}
        </>
      )}
    </StyledCard>
  );
};

const LoadingCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92px;
  height: 92px;
  border: 20px solid #e8e5f1;
  border-radius: 100%;
`;

const StyledRefreshIcon = styled(RefreshIcon)`
  margin-bottom: 5px;
`;

const StyledSpinner = styled(Spinner)`
  margin: 20px 0 15px;
  transform: scale(0.7);
`;

interface ContainerProps {
  eventIsVersion2: boolean;
}

const StyledCard = styled(Card)<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${H2} {
    margin-bottom: 20px;
    text-align: center;
  }
  ${H1} {
    font-weight: 600;
    font-size: 38px;
    line-height: 54px;
  }
  ${H3} {
    color: #666666;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
  ${Divider} {
    margin-bottom: 24px;
  }
`;
