import styled, { useTheme } from "styled-components";
import ArtistShows from "./ArtistShows";
import ArtistSurveys from "./ArtistSurveys";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const AppCards = () => {
  const { customApp } = useCustomAppContext();

  return (
    <Container>
      <ArtistShows />
      {!customApp && <ArtistSurveys />}
    </Container>
  );
};

const Container = styled.div`
  ${({ theme }) => theme.media.desktop} {
    margin-left: 46px;
    margin-right: 46px;

    & > div:nth-last-child(n + 2) {
      margin-bottom: 33px;
    }
  }
`;
