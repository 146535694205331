import { useArtistContext } from "Components";
import styled from "styled-components";
import { UserCard } from "./UserCard";

export const Teammates = () => {
  const { artistGroup } = useArtistContext();

  return (
    <Container>
      {artistGroup?.members.length > 0 && <Header>Teammates</Header>}
      {artistGroup?.members.map((member) => (
        <UserCard key={member.uid} member={member} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding: 16px 0 16px 20px;
`;

const Header = styled.h1`
  color: var(--sidebar-section-header-color);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  padding: 0 0 12px 0;
`;
