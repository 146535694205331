import styled from "styled-components";
import { getBorderColor } from "./utils";

export const RadioContainer = styled.div<Props>`
  height: 16px;
  width: 16px;
  display: flex;
  margin: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px solid white;
  border-color: ${({ isSelected, disabled, theme }) =>
    getBorderColor(theme, isSelected, disabled)};
`;

export const RadioSelect = styled.div<Props>`
  height: 10px;
  width: 10px;
  border-radius: 100%;
  animation: grow 0.25s ease-in-out;
  background: ${({ theme, isSelected, disabled }) =>
    getBorderColor(theme, isSelected, disabled)};

  @keyframes grow {
    0% {
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -o-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
    }

    50% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
`;

interface Props {
  isSelected: boolean;
  disabled?: boolean;
}

export const RadioInput = ({ isSelected, disabled, ...props }: Props) => {
  return (
    <RadioContainer {...props} isSelected={isSelected} disabled={disabled}>
      {isSelected && (
        <RadioSelect isSelected={isSelected} disabled={disabled} />
      )}
    </RadioContainer>
  );
};
