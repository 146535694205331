import React, { createContext, useContext, useState, useEffect } from "react";
import { DateTime } from "luxon";
import firebase from "firebase/compat/app";

interface TimeProviderData {
  time: DateTime;
}

export const TimeContext = createContext(undefined);
export const TimeConsumer = TimeContext.Consumer;
export const TimeProvider = ({ children }) => {
  const [serverOffset, setServerOffset] = useState(0);
  const [localTime, setLocalTime] = useState(Date.now());
  const time = DateTime.fromMillis(localTime + serverOffset);

  useEffect(() => {
    firebase
      .database()
      .ref("/.info/serverTimeOffset")
      .once("value")
      .then(
        function stv(data) {
          setServerOffset(data.val());
        },
        function (err) {
          return err;
        },
      );
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setLocalTime(Date.now());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <TimeContext.Provider value={{ time } as TimeProviderData}>
      {children}
    </TimeContext.Provider>
  );
};

export const useTimeContext = (): TimeProviderData => useContext(TimeContext);
