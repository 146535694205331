const BoxSvg = ({
  selected,
  className,
}: {
  selected?: boolean;
  className?: string;
}) => {
  return selected ? (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17 4C17 3.44772 16.5523 3 16 3H4C3.44772 3 3 3.44772 3 4V16C3 16.5523 3.44772 17 4 17H16C16.5523 17 17 16.5523 17 16V4ZM8.27727 12.275L14.1879 6L15 6.86224L8.27727 14L5 10.5204L5.8121 9.65817L8.27727 12.275Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/CheckboxUnchecked">
        <path
          id="Shape"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.5587 3C17.1127 3 17.5618 3.44772 17.5618 4V16C17.5618 16.5523 17.1127 17 16.5587 17H4.52164C3.96765 17 3.51855 16.5523 3.51855 16V4C3.51855 3.44772 3.96765 3 4.52164 3H16.5587ZM16.0571 4H5.02318C4.77697 4 4.57219 4.17688 4.52972 4.41012L4.52164 4.5V15.5C4.52164 15.7455 4.69906 15.9496 4.93303 15.9919L5.02318 16H16.0571C16.3034 16 16.5081 15.8231 16.5506 15.5899L16.5587 15.5V4.5C16.5587 4.25454 16.3813 4.05039 16.1473 4.00806L16.0571 4Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default BoxSvg;
