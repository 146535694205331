import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Body1, Body2 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { Select } from "melodies-source/Select";
import { Button } from "melodies-source/Button";
import { useArtistContext } from "Components";
import { setDoc, DocumentData, DocumentReference } from "firebase/firestore";
import { Modal } from "melodies-source/Modal";
import { Field, useFormik } from "formik";
import { yupNumber, yupOptional } from "Utils/yup";
import * as yup from "yup";

const fieldKeys = ["maxArtistId", "spotifyArtistId", "postShowEmail"] as const;
type FieldKey = (typeof fieldKeys)[number];

interface Option {
  value: string;
  label: string;
}

interface BaseField {
  ref: DocumentReference<DocumentData>;
  label: string;
  placeholder?: string;
  key: FieldKey;
  value: string;
}

interface TextInput extends BaseField {
  type: "text-input";
}

interface Select extends BaseField {
  type: "select";
  options: Option[];
}

type Field = TextInput | Select;

interface Props {
  title: string;
  fields: Field[];
}

const spotifyIdRegex = /\w{22}/;

const INITIAL_VALUES = Object.fromEntries(
  fieldKeys.map((key) => [key, ""]),
) as Record<FieldKey, string>;

const VALIDATION_SCHEMA = yup.object().shape({
  maxArtistId: yupNumber.optional(),
  spotifyArtistId: yupOptional.matches(spotifyIdRegex, "Invalid ID"),
  postShowEmail: yupOptional,
});

export const Fields: React.FC<Props> = ({ title, fields }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isAdmin } = useArtistContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleSave,
  });

  const toggleModal = () => setModalIsOpen((open) => !open);

  async function handleSave() {
    setLoading(true);

    const data: Record<FieldKey, string | number> = {
      ...formik.values,
    };

    if (formik.values.maxArtistId) {
      data.maxArtistId = +formik.values.maxArtistId;
    }

    if (formik.values.spotifyArtistId) {
      data.spotifyArtistId = spotifyIdRegex.exec(
        formik.values.spotifyArtistId,
      )[0];
    }

    formik.setFieldValue("spotifyArtistId", data.spotifyArtistId);

    for (const field of fields) {
      await setDoc(
        field.ref,
        { [field.key]: data[field.key] },
        { merge: true },
      );
    }

    setLoading(false);
    toggleModal();
  }

  const inputProps = (fieldKey: string) => ({
    value: formik.values[fieldKey],
    onChange: formik.handleChange(fieldKey),
    ...(formik.errors[fieldKey] && {
      hasError: true,
      helperText: formik.errors[fieldKey],
    }),
  });

  useEffect(() => {
    for (const field of fields) {
      if (field.value) {
        formik.setFieldValue(field.key, field.value);
      }
    }
  }, [JSON.stringify([fields.map((field) => field.value)])]);

  return (
    <div>
      <TitleContainer>
        <Title>{title}</Title>
        {isAdmin && (
          <Button text onClick={toggleModal}>
            Edit
          </Button>
        )}
      </TitleContainer>
      <LinksContainer>
        {fields.map((field) => (
          <div key={field.key}>
            <p>{field.label}</p>
            <Body2>
              {(field.type === "select"
                ? field.options.find(
                    (opt) => opt.value === formik.values[field.key],
                  )?.label
                : formik.values[field.key]) || "(click “Edit” to update)"}
            </Body2>
          </div>
        ))}
      </LinksContainer>
      <Modal isOpen={modalIsOpen} onClose={toggleModal} header={title}>
        <Body1>If you don’t have an ID, you can leave the field blank.</Body1>
        <Form onSubmit={formik.handleSubmit}>
          {fields.map((field) => {
            switch (field.type) {
              case "text-input": {
                return (
                  <TextInput
                    key={field.key}
                    label={field.label}
                    name={field.label}
                    defaultValue={field.value}
                    placeholder={field.placeholder}
                    {...inputProps(field.key)}
                  />
                );
              }
              case "select": {
                return (
                  <Select
                    key={field.key}
                    label={field.label}
                    options={field.options}
                    {...inputProps(field.key)}
                  />
                );
              }
              default:
                return null;
            }
          })}
          <button ref={buttonRef} type="submit" hidden />
        </Form>
        <Buttons>
          <Button variant="tertiary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button onClick={() => buttonRef.current.click()} loading={loading}>
            Save
          </Button>
        </Buttons>
      </Modal>
    </div>
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  button {
    width: auto;
  }
`;

const Title = styled(Body1)`
  font-weight: 500;
  color: var(--header-text-color);
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${Body2} {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #999999;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;
  }
`;
