import React from "react";
import styled, { css } from "styled-components";

export const getScrollBarCss = (color = "#9ca4a8") => css`
  scrollbar-width: thin;
  scrollbar-color: ${color} transparent;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${color};
    border-radius: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    border-radius: 3px;
    background-color: ${color};
  }
`;

export const scrollBarDarkCss = css`
  scrollbar-width: thin;
  scrollbar-color: #ffffff29 transparent;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    opacity: 0.3;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #ffffff29;
    opacity: 0.3;
    border-radius: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    border-radius: 3px;
    background-color: #ffffff29;
  }
`;

export const scrollBarCss = css`
  scrollbar-width: thin;
  scrollbar-color: #7f7f7f transparent;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    opacity: 0.3;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: white;
    opacity: 0.3;
    border-radius: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #7f7f7f;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    border-radius: 3px;
    background-color: #efebe9;
  }
`;

export const paddingLeftCss = css`
  padding-left: 50px;
  ${(props) => props.theme.mediaQueries.mobile} {
    padding-left: 0%;
  }
`;

export const paddingRightCss = css`
  padding-right: 50px;
  ${(props) => props.theme.mediaQueries.mobile} {
    padding-right: 0%;
  }
`;

export const AppLayout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
`;

export const Container = styled.div`
  ${paddingRightCss}
  ${paddingLeftCss}
  width: 100%;
  max-width: 1224px;
`;

type Props = {
  children: JSX.Element;
  className?: string;
};
export const AppContainer = ({ children, className = "" }: Props) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center" }}
      className={className}
    >
      <Container>{children}</Container>
    </div>
  );
};
