import { useState } from "react";
import {
  REPORTING_PADDING_DESKTOP,
  REPORTING_PADDING_MOBILE,
} from "Routes/Reporting/styled";
import { AnimatedCaret, CenterFlexRow, Font20 } from "Routes/styled";
import styled, { css } from "styled-components";

const Collapse = ({
  label,
  endComponent,
  defaultOpen = true,
  children,
}: {
  label: string;
  endComponent?: JSX.Element;
  defaultOpen?: boolean;
  children: JSX.Element | JSX.Element[];
}) => {
  const [collapsed, setCollapsed] = useState(!defaultOpen);

  return (
    <>
      <Divider />
      <CollapseContainer collapsed={collapsed}>
        <CollapseHeader onClick={() => setCollapsed((collapsed) => !collapsed)}>
          <Font20
            fontWeight={600}
            color="var(--text-color)"
            style={{ marginRight: 10 }}
          >
            {label}
          </Font20>
          <AnimatedCaret direction={collapsed ? "down" : "up"} />
        </CollapseHeader>
        <EndComponentContainer collapsed={collapsed}>
          {endComponent}
        </EndComponentContainer>
      </CollapseContainer>
      <BodyContainer collapsed={collapsed}>
        <InnerContainer collapsed={collapsed}>{children}</InnerContainer>
      </BodyContainer>
    </>
  );
};

export default Collapse;

const BodyContainer = styled.div<{ collapsed: boolean }>`
  overflow-y: hidden;
  max-height: ${({ collapsed }) => (collapsed ? "0px" : "5000px")};
  transition: max-height 0.5s;
`;

interface CollapseProps {
  collapsed: boolean;
}

export const CollapseContainer = styled(CenterFlexRow)<CollapseProps>`
  transition: padding 500ms ease;
  ${({ theme }) => theme.media.desktop} {
    justify-content: space-between;
    padding: ${({ collapsed }) =>
      `${REPORTING_PADDING_DESKTOP}${collapsed ? "" : " 0px"}`};
  }

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: flex-start;
    padding: ${REPORTING_PADDING_MOBILE};
  }
`;

export const CollapseHeader = styled.div`
  height: 54px;
  align-self: flex-start;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.desktop} {
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid var(--border-color);
  height: 1px;
`;

const InnerContainer = styled.div<{ collapsed: boolean }>`
  ${({ theme }) => theme.media.desktop} {
    padding: 20px 40px 45px;
  }

  ${({ theme }) => theme.media.mobile} {
    padding: ${REPORTING_PADDING_MOBILE};
    ${({ collapsed }) =>
      !collapsed &&
      css`
        margin-bottom: 25px;
      `}
  }
`;

const EndComponentContainer = styled.div<CollapseProps>`
  ${({ collapsed }) =>
    collapsed
      ? css`
          display: none;
        `
      : css`
          display: block;
        `}

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;
