import { SvgHelp } from "melodies-source/Svgs/Help";
import { Tooltip } from "melodies-source/Tooltip";

export const HelpTooltip = ({ message }: { message: string }) => {
  return (
    <Tooltip parent={<SvgHelp style={{ display: "block" }} />}>
      {message}
    </Tooltip>
  );
};
