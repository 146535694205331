import { ReactComponent as IcEdit } from "assets/svg/ic_edit.svg";
import { Avatar } from "melodies-source/Avatar";
import { SvgIndividual } from "melodies-source/Svgs/Individual";
import { CSSProperties } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 145px;
  height: 145px;
  flex-shrink: 0;
  position: relative;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100px;
    height: 100px;
  }
`;

const StyledAvatar = styled(Avatar)`
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);

  svg {
    width: 50%;
    height: 50%;

    path {
      fill: ${({ theme }) => theme.colors.black20};
    }
  }
`;

const Edit = styled.div`
  background-color: ${(props) => props.theme.colors.black70};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  position: absolute;
  right: 3px;
  top: 3px;
  color: #ffffff;
  cursor: pointer;

  ${(props) => props.theme.mediaQueries.mobile} {
    top: -2px;
    right: -2px;
  }
`;

interface ProfileAvatarProps {
  url?: string;
  style?: CSSProperties;
  onEdit?: () => void;
}

export const ProfileAvatar = ({ url, style, onEdit }: ProfileAvatarProps) => {
  return (
    <Container style={style}>
      {onEdit && (
        <Edit onClick={onEdit}>
          <IcEdit />
        </Edit>
      )}
      <StyledAvatar imgUrl={url} icon={!url && <SvgIndividual />} />
    </Container>
  );
};
