import { ReactComponent as IconShirtQuestion } from "assets/svg/shirt-question.svg";
import { SurveyTemplate } from "../../types";
import { getContestDefaults } from "../helpers";

export const shirtColor: SurveyTemplate = {
  icon: <IconShirtQuestion />,
  title: "Shirt Color",
  description:
    "Ask fans to vote on what shirt color they'd like to see the newest design on.",
  templateId: "shirtColor",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: "Shirt Color",
        },
      },
      ...getContestDefaults(formState),
    },
    surveyQuestions: [
      {
        type: "image",
        actionRequired: true,
        showLabels: false,
        required: false,
        src: "",
      },
    ],
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-merch---sh-acb334"
        : "pp-merch---sh-4f5e9e",
    fields: [
      {
        name: "url",
        type: "text",
        label: "Paste the URL to your image here",
        placeholder: "https://...",
      },
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
