import { yupEmail } from "Utils/yupStrictPassword";
import { Form, Formik } from "formik";
import { useEmailLogin, useHistoryPush } from "hooks";
import { Button } from "melodies-source/Button";
import { SvgMail } from "melodies-source/Svgs/Mail";
import styled from "styled-components";
import * as yup from "yup";
import { Description, Title } from "../Layout";
import { SESSION_PATHS } from "../paths";
import { TextInput } from "melodies-source/TextInput";
import { useCustomAppContext } from "contexts/CustomAppContext";

const Container = styled.div`
  padding: 40px 48px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 32px 20px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 32px;
  }
`;

interface Values {
  email: string;
}

const validationsSchema = yup.object().shape({
  email: yupEmail,
});

export const ForgotPassword = () => {
  const historyPush = useHistoryPush();
  const { login, ui, isSendingEmail } = useEmailLogin();
  const handleSubmit = (values: Values) =>
    login(values.email, "/account/password");
  const { customApp } = useCustomAppContext();

  return (
    <Container>
      <Header>
        <Title style={{ textAlign: "center", marginBottom: 12 }}>
          Forgot Password
        </Title>
        <Description style={{ textAlign: "center" }}>
          Enter the email address associated with your{" "}
          {customApp?.company.name || "SET"} account and we'll email you
          instructions to reset your password.
        </Description>
      </Header>
      <Formik<Values>
        initialValues={{ email: "" }}
        validationSchema={validationsSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange }) => {
          return (
            <Form style={{ width: "100%" }}>
              <TextInput
                label="Email Address"
                leftIcon={<SvgMail />}
                name="email"
                placeholder="Email..."
                value={values.email}
                onChange={handleChange("email")}
                hasError={!!errors.email}
                helperText={errors.email}
              />
              {ui}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 30,
                  marginBottom: 10,
                }}
              >
                <Button
                  style={{ marginRight: 8 }}
                  variant="secondary"
                  onClick={() => historyPush(SESSION_PATHS.LOGIN_WITH_PASSWORD)}
                  disabled={isSendingEmail}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  style={{ marginLeft: 8 }}
                  disabled={isSendingEmail}
                  loading={isSendingEmail}
                  variant="primary"
                >
                  Reset Password
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
