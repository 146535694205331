import * as React from "react";
import { SVGProps } from "react";
export const SvgHide = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.0294848,4 L17.1636355,5.12005222 L15.109649,7.14833528 C16.1145707,7.89856035 17.0780211,8.8837825 18,10.1040017 C15.6063183,13.2719878 12.9330995,14.8559809 9.98034349,14.8559809 C9.18515799,14.8559809 8.41024565,14.7411033 7.65560645,14.5113483 L5.1341507,17 L4,15.8799478 L16.0294848,4 Z M14.0062718,8 L9,12.9226084 C9.25558776,12.9627447 9.51308073,12.9871833 9.77255197,12.9961253 L9.99805755,13 C12.0321025,13 13.9443621,12.0596216 15.7695568,10.0831714 L16,9.82680173 L15.9719997,9.79430615 C15.3280244,9.06728982 14.6732955,8.47060363 14.0062718,8 Z M9.86930769,5 C10.5968326,5 11.3070634,5.10481906 12,5.31445717 L10.671655,6.71737601 C10.4786291,6.69069745 10.2844866,6.67379047 10.0891948,6.66655809 L9.86930769,6.662498 C7.88594098,6.662498 6.0213253,7.64786166 4.24160528,9.71886022 L4.01611663,9.987494 L4.04420625,10.0215441 C4.6355346,10.7389337 5.23616179,11.3352153 5.8473428,11.8141059 L4.72537266,13 C3.77921677,12.2243191 2.87075922,11.2201504 2,9.987494 C4.34880604,6.662498 6.9719086,5 9.86930769,5 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
