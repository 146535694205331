import React from "react";
import styled from "styled-components";
import { Body1, H1, H2 } from "melodies-source/Text";
import { Button } from "melodies-source/Button";
import { Card } from "Components/Card";
import { useDesktopMediaQuery } from "hooks";
import { ReactComponent as WaitingIcon } from "assets/svg/waiting-circle.svg";

interface Props {
  image: string;
}

export const ArtistPendingCard: React.FC<Props> = ({ image }) => {
  const isDesktop = useDesktopMediaQuery();

  const Heading = isDesktop ? H1 : H2;

  return (
    <StyledCard isElevated>
      <Container>
        <ArtistImageContainer>
          <ArtistImage url={image} />
          <StyledWaitingIcon />
        </ArtistImageContainer>
        <Heading>Artist Pending</Heading>
        <Body1>
          We are reviewing your artist account. A MAX representative will reach
          out personally to ensure yor account is optimized and ready.
        </Body1>
        <StyledButton
          onClick={() => window.open("https://suite.set.live", "_self")}
        >
          Learn more about SET
        </StyledButton>
        <Button
          text
          onClick={() =>
            window.open(`${process.env.REACT_APP_SIGNUP_URL}/?start`, "_self")
          }
        >
          Add another artist
        </Button>
      </Container>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  z-index: 1;
  max-width: 450px;
  margin-top: 40px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  ${Body1} {
    text-align: center;
  }
`;

const ArtistImageContainer = styled.div`
  position: relative;
`;

interface ArtistImageProps {
  url: string;
}

const ArtistImage = styled.img<ArtistImageProps>`
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center;
  border: 2px solid #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
`;

const StyledWaitingIcon = styled(WaitingIcon)`
  position: absolute;
  top: -7px;
  right: -3px;
  width: 35px;
`;

const StyledButton = styled(Button)`
  margin: 30px 0 5px;
`;
