import styled, { css } from "styled-components";
import { useFanTableContext } from ".";
import { useDesktopMediaQuery } from "hooks";
import { Select } from "melodies-source/Select";
import { SvgSort } from "melodies-source/Svgs/Sort";
import { H4 } from "melodies-source/Text";

export const FanHeaderRow = () => {
  const isDesktop = useDesktopMediaQuery();
  return (
    <Container>
      {isDesktop ? (
        <>
          <Sortable sortkey={`name`}>Name</Sortable>
          <Sortable sortkey={`location`}>Location</Sortable>
          <Sortable sortkey={`age`}>Age</Sortable>
          <Sortable sortkey={`gender`}>Gender</Sortable>
          <Sortable sortkey={`createdAt`}>Added</Sortable>
          <Sortable sortkey={`phone`}>Phone</Sortable>
          <Sortable sortkey={`email`}>Email</Sortable>
        </>
      ) : null}
    </Container>
  );
};

const MobileSort = () => {
  const options = [
    { value: "name", label: "Name" },
    { value: "age", label: "Age" },
    { value: "added", label: "Added" },
    { value: "phone", label: "Phone" },
  ];
  const { sort, setSort } = useFanTableContext();
  return (
    <>
      <H4>Sort</H4>
      <SelectContainer>
        <SvgSort
          style={{
            padding: sort.direction === "asc" ? "0 4px 0 0" : "0 0 0 4px",
            transform: sort.direction === "asc" ? "rotate(180deg)" : "",
            alignSelf: "center",
            width: "38px",
          }}
          onClick={() => setSort(sort.field)}
        />
        <Select
          value={sort.field}
          onChange={(v) => setSort(v)}
          options={options}
        />
      </SelectContainer>
    </>
  );
};

const SelectContainer = styled.div`
  display: flex;
`;

const Container = styled.div(({ theme }) => {
  const { border } = theme.custom?.pages.contacts.table.row || {};

  return css`
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 -1px 0 0 ${border?.color || "#e6e9eb"};
    padding: 16px 12px;
    ${({ theme }) => theme.media.desktop} {
      flex-direction: row;
      justify-content: space-between;
    }
  `;
});

const Sortable = ({ sortkey = null, children }) => {
  const { sort, setSort } = useFanTableContext();
  const active = sort.field === sortkey;
  const handleSort = () => {
    if (sortkey) {
      setSort(sortkey);
    }
  };
  return (
    <Element onClick={handleSort} active={active} enabled={!!sortkey}>
      {children}
      {active && (
        <SvgSort
          style={{
            padding: sort.direction === "asc" ? "0 4px 0 0" : "0 0 0 4px",
            transform: sort.direction === "asc" ? "rotate(180deg)" : "",
          }}
        />
      )}
    </Element>
  );
};

const Element = styled.div<{ active: boolean; enabled: boolean }>`
  display: flex;
  align-items: center;
  color: inherit;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  ${(props) =>
    props.enabled &&
    `
  cursor: pointer;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
  `}
  ${(props) =>
    props.active &&
    `
    text-decoration: underline;
  `}
`;
