import React from "react";

interface Props {
  fill?: string;
}

const UMGLogo: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={411.113}
      height={155.126}
      viewBox="0 0 411.113 155.126"
      {...props}
    >
      <path
        d="M0 0c.078.117.314.039.48.035C.703.03.65-.3.959-.235c.308.066.312-.293.752-.28.442.014-.088-.154-.088-.154s-.273.132-.449.043c-.094-.047.034.027-.123.155-.242.198-.209-.037-.615.308C.148.081-.128-.192 0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 206.704 34.734)"
      />
      <path
        d="M0 0c-.261.422-.066.947.23 1.458.217.376.798.542 1.303.345.567.447.703-.034 1.205.183.66.286.291-.759.929-1.022.264.704-.601 1.233-.059 1.564.388.237.681.384.84.7.226.452.314.782.489.841.402.138.315.52.315.52s.429-.125.761.187c-.254.138-.626.255-.527.529.115.322.977-.333 1.232.196-.513.223-1.037.709-1.35.821-.214-.235-.349-.141-.626.078-.469.371-.508.019-.802.273-.233-.469.12-.432.227-.549.301-.331-.305.295-.246-.545.015-.218-.307-.259-.547.214-.125.247-1.121.196-1.272.431.138.274 1.311 1.251 3.11 1.016-2.615 1.028-6.147-.094-6.183-.484-.352-.221-.231-.474-.407-.532-.305-.102-.636-1.032-.636-1.032s.189-.126-.402-.788c-.487-.545-.631-1.371-.631-1.371s.322-.589.26-1.267c-.045-.518-.067-.134.158-.509.131-.22.274-.986.361-.687.166.57-.303 1.098-.225 1.665.018-.071.18.225.174-.043-.017-.7.862-2.05.862-2.05s-.328-.334.016-.334l-.088-.207s.255.015.419-.119c.165-.135.307-.301.457-.301h2.529s.18.767.327.898c.147.13-.294 0-.424.13-.131.131-.213-.114-.278-.244-.066-.131-.147-.36-.343-.425C.962-.525.49-.558.392-.558.294-.558 0 0 0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 192.072 36.742)"
      />
      <path
        d="M0 0c-.098.13.016.327.016.327h-7.131l.795-.694a.92.92 0 0 0 .495-.512c.238-.631-.116-.12-.116-1.074 0-.877-.247-.735-.247-.735s-.053-1.037-.59-1.674c-.341-.343-.076-.627-.076-.627s-.115-.065-.282-.817c-.055-.243.257-.619.527-.525.203.071-.492.138.262.455.29.123.053.354.053.354s.487.038.762.775c.486-.071.306.227 1.306.443.264.056.32.136.287.384-.033.249.326-.036 1.317.975.78.797.499.977 1.039 1.266.571.304.623-.026.814.221.436.568.283.531.464.893l.129.199S.097-.13 0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 244.373 72.99)"
      />
      <path
        d="m0 0-.159-.095-.032.158L0 0z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 222.374 39.404)"
      />
      <path
        d="M0 0c-.255-.055-.524 0-.761-.19l-.159.127h-.095c.222.182-.127.372.254.38C-.468.309-.255.104 0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 219.858 38.848)"
      />
      <path
        d="M0 0c1.647 5.094 5.546 9.028 12.062 9.028 6.055 0 10.504-3.988 12.118-9.139-1.684 4.801-6.469 8.264-12.109 8.264C6.473 8.153 1.718 4.743 0 0m24.153-7.792c-1.639-5.107-6.249-9.08-12.091-9.08-6.197 0-10.361 3.919-12.036 8.969 1.743-4.704 6.476-8.078 12.045-8.078 5.61 0 10.375 3.427 12.082 8.189"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 152.24 39.85)"
      />
      <path
        d="M0 0h-1.987l.297-.353v-2.61c0-.586-.388-.833-.969-.833-.579 0-.968.247-.968.833v2.61L-3.33 0h-1.987l.297-.353v-2.652c0-1.301.728-1.803 2.361-1.803 1.633 0 2.361.502 2.361 1.803v2.652L0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 109.274 45.354)"
      />
      <path
        d="M0 0h-1.676l.296-.353v-2.376L-3.783 0h-1.506l.297-.353v-4.009l-.297-.354h1.676l-.297.354v2.418l2.552-2.772h1.061v4.363L0 0z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 139.713 45.354)"
      />
      <path
        d="m0 0-.297.353v4.009L0 4.716h-2.001l.298-.354V.353L-2.001 0H0z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 156.755 66.17)"
      />
      <path
        d="M0 0h-1.683l.297-.353-1.188-2.595-1.145 2.595.297.353h-1.979l2.198-4.716h.983L0 0z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 185.879 45.354)"
      />
      <path
        d="M0 0v1.28L-.354.983h-2.411v.905h1.528l.346-.298v1.535l-.346-.297h-1.528v.905h2.411L0 3.436v1.28h-4.44l.296-.354V.353L-4.44 0H0z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 210.686 66.17)"
      />
      <path
        d="M0 0c0-.339-.325-.615-.778-.615h-.897V.544h1.067C-.297.544 0 .389 0 0m1.626-3.189L.347-1.329c.629.254 1.068.742 1.068 1.428 0 .785-.481 1.428-1.825 1.428h-2.941l.297-.354v-4.008l-.297-.354h1.972l-.296.354v1.336h.594l.904-1.336-.296-.354h2.099"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 233.343 52.094)"
      />
      <path
        d="M0 0c0 .749-.602 1.167-1.471 1.45-.566.176-1.365.303-1.365.678 0 .184.219.311.601.311.51 0 1.343-.212 1.994-.523v1.577l-.375-.361c-.396.163-1.082.333-1.555.333-1.605 0-2.121-.707-2.121-1.351 0-.877.58-1.259 1.492-1.577.678-.233 1.308-.254 1.308-.642 0-.334-.389-.376-.588-.376-.501 0-1.392.177-2.184.693v-1.746l.375.41c.368-.141 1.195-.382 1.873-.382C-.622-1.506 0-.891 0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 266.885 60.084)"
      />
      <path
        d="M0 0h-1.562l.778 1.689L0 0zm2.269-1.697-2.32 4.716h-1.59l.169-.354-2.099-4.362h1.731l-.296.354.233.495H.345l.228-.495-.298-.354h1.994z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 287.997 58.68)"
      />
      <path
        d="M0 0v1.28L-.354.983h-2.27v3.379l.297.354H-4.3l.298-.354V.353L-4.3 0H0z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 322.678 66.17)"
      />
      <path
        d="M0 0a.941.941 0 1 0 1.882-.002A.941.941 0 0 0 0 0m.089 0a.852.852 0 1 1 1.705-.001A.852.852 0 0 1 .089 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 318.286 49.856)"
      />
      <path
        d="M0 0a.492.492 0 0 1-.326.116.477.477 0 0 1-.483-.487c0-.274.202-.49.481-.49.125 0 .244.046.34.129v.123a.454.454 0 0 0-.345-.167c-.221 0-.384.192-.384.405 0 .213.165.401.386.401A.434.434 0 0 0 0-.122V0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 324.002 48.232)"
      />
      <path
        d="M0 0v-1.785c0-.876-.49-1.214-1.59-1.214-1.099 0-1.59.338-1.59 1.214V0l-.2.238h1.338l-.2-.238v-1.756c0-.395.262-.562.652-.562.391 0 .652.167.652.562V0l-.2.238H.2L0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 14.92 141.699)"
      />
      <path
        d="M0 0v-2.937h-.714l-1.718 1.866v-1.628l.2-.238h-1.129l.2.238V0l-.2.238h1.014l1.619-1.837V0l-.2.238H.2L0 0z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 37.568 141.699)"
      />
      <path
        d="m0 0 .2.238v2.699l-.2.238h1.347l-.2-.238V.238l.2-.238H0z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 46.118 154.663)"
      />
      <path
        d="M0 0h-.662l-1.48 3.175h1.333l-.2-.238.771-1.747.8 1.747-.2.238h1.133L0 0z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 67.296 154.663)"
      />
      <path
        d="m0 0 .2.238v2.699l-.2.238h2.989v-.862l-.238.2H1.128v-.609h1.028l.233.2V1.071l-.233.2H1.128V.662h1.623l.238.2V0H0z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 79.567 154.663)"
      />
      <path
        d="M0 0h-.719v-.78h.605c.304 0 .523.185.523.414C.409-.104.209 0 0 0m.09-2.513.2.238-.609.9h-.4v-.9l.2-.238h-1.328l.2.238V.424l-.2.238h1.98c.905 0 1.228-.433 1.228-.962 0-.461-.295-.79-.718-.961l.861-1.252H.09"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 108.373 143.57)"
      />
      <path
        d="M0 0c-.457 0-1.014.162-1.261.257l-.253-.276v1.176C-.981.809-.381.69-.043.69.09.69.352.719.352.942c0 .262-.423.277-.88.434-.614.214-1.005.471-1.005 1.061 0 .433.348.909 1.428.909.319 0 .781-.114 1.048-.224l.252.243V2.304c-.438.209-1 .352-1.343.352-.257 0-.404-.086-.404-.209 0-.253.538-.338.918-.457.586-.191.991-.471.991-.976C1.357.414.938 0 0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 128.92 155.042)"
      />
      <path
        d="m0 0-.523-1.138H.529L0 0zm.714-2.28.2.238-.152.333H-.752l-.157-.333.2-.238h-1.166L-.461.657l-.115.238H.495L2.057-2.28H.714z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 148.964 144.599)"
      />
      <path
        d="m0 0 .2.238v2.699l-.2.238h1.328l-.2-.238V.662h1.528l.238.2V0H0z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 164.03 154.663)"
      />
      <path
        d="m0 0 .2.238v1.523L-.643 0h-.442l-.953 1.79V.238l.2-.238h-1.099l.2.238v2.699l-.2.238h1.119l1.032-1.942.943 1.942h1.142l-.2-.238V.238l.2-.238H0z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 208.298 154.663)"
      />
      <path
        d="M0 0v-1.785c0-.876-.491-1.214-1.59-1.214-1.099 0-1.59.338-1.59 1.214V0l-.2.238h1.338l-.2-.238v-1.756c0-.395.262-.562.652-.562s.652.167.652.562V0l-.2.238H.2L0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 235.885 141.699)"
      />
      <path
        d="M0 0c-.457 0-1.014.162-1.261.257l-.253-.276v1.176C-.981.809-.381.69-.043.69.09.69.352.719.352.942c0 .262-.423.277-.88.434-.614.214-1.005.471-1.005 1.061 0 .433.348.909 1.429.909.318 0 .78-.114 1.046-.224l.253.243V2.304c-.438.209-1 .352-1.343.352-.257 0-.404-.086-.404-.209 0-.253.538-.338.919-.457.585-.191.99-.471.99-.976C1.357.414.938 0 0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 250.257 155.042)"
      />
      <path
        d="m0 0 .2.238v2.699l-.2.238h1.347l-.2-.238V.238l.2-.238H0z"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 263.702 154.663)"
      />
      <path
        d="M0 0c-.966 0-1.837.476-1.837 1.652 0 .928.666 1.647 1.875 1.647.367 0 .762-.086.985-.172l.219.21V2.28a2.123 2.123 0 0 1-1.151.333c-.672 0-.934-.447-.934-.961 0-.576.381-.967.962-.967.585 0 .938.229 1.128.348V.29C1.019.157.471 0 0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 284.691 154.937)"
      />
      <path
        d="M0 0h1.133v-1.271c-.281-.176-.905-.366-1.442-.366-.967 0-1.876.476-1.876 1.651 0 1.19.99 1.647 1.876 1.647.514 0 .847-.129 1.047-.2l.252.238V.609C.647.8.238.976-.262.976-.843.976-1.19.59-1.19.014s.347-.966.928-.966c.148 0 .314.024.467.052v.658L0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 318.75 147.71)"
      />
      <path
        d="M0 0h-.719v-.78h.605c.304 0 .523.185.523.414C.409-.104.209 0 0 0m.09-2.513.2.238-.609.9h-.4v-.9l.2-.238h-1.328l.2.238V.424l-.2.238h1.98c.905 0 1.228-.433 1.228-.962 0-.461-.295-.79-.718-.961l.861-1.252H.09"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 338.732 143.57)"
      />
      <path
        d="M0 0c-.595 0-.837-.395-.837-.981 0-.585.242-.985.837-.985s.833.4.833.985C.833-.395.595 0 0 0m0-2.651c-.895 0-1.837.466-1.837 1.67C-1.837.224-.895.685 0 .685S1.828.224 1.828-.981c0-1.204-.933-1.67-1.828-1.67"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 360.016 143.314)"
      />
      <path
        d="M0 0v-1.785c0-.876-.49-1.214-1.59-1.214-1.099 0-1.59.338-1.59 1.214V0l-.2.238h1.338l-.2-.238v-1.756c0-.395.262-.562.652-.562s.652.167.652.562V0l-.2.238H.2L0 0"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 389.136 141.699)"
      />
      <path
        d="M0 0h-.533v-.961h.447c.429 0 .667.109.667.514C.581-.095.338 0 0 0m.019-1.59h-.552v-.685l.2-.238h-1.328l.2.238V.424l-.2.238H.048C.676.662 1.547.486 1.547-.39c0-1-.766-1.2-1.528-1.2"
        style={{
          fill: "#231f20",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(4.41402 0 0 -4.41402 404.285 143.57)"
      />
    </svg>
  );
};

export default UMGLogo;
