import { MenuMode, useArtistContext, useMenuContext } from "Components";
import styled, { css } from "styled-components";
import { ReactComponent as ChevronsLeft } from "assets/svg/chevrons-left.svg";
import { ReactComponent as ChevronsRight } from "assets/svg/chevrons-right.svg";
import { useDesktopMediaQuery } from "hooks";

export const ArtistGroup = () => {
  const { assets, name } = useArtistContext();
  const { menuMode, setMenuMode } = useMenuContext();
  const longName = name?.length > 25;
  const isDesktop = useDesktopMediaQuery();

  return (
    <ArtistGroupContainer>
      {menuMode === "expanded" && (
        <>
          <ArtistGroupImage src={assets?.icon?.path} />
          <ArtistGroupName isLong={longName}>{name}</ArtistGroupName>
        </>
      )}
      {isDesktop && (
        <Chevrons
          menuMode={menuMode}
          onClick={() =>
            setMenuMode((menuMode) =>
              menuMode === "expanded" ? "collapsed" : "expanded",
            )
          }
        >
          {menuMode === "expanded" ? <ChevronsLeft /> : <ChevronsRight />}
        </Chevrons>
      )}
    </ArtistGroupContainer>
  );
};

const ArtistGroupContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 24px 0 20px 5px;
`;

const ArtistGroupImage = styled.img`
  margin: 8px;
  border-radius: 50%;
  height: 52.5px;
  width: 52.5px;
  object-fit: cover;
`;

const ArtistGroupName = styled.h1<{ isLong: boolean }>`
  color: var(--text-color);
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  max-width: 250px;

  ${({ isLong }) =>
    isLong && "font-size: 20px; line-height: 30px; word-wrap: break-word;"}
`;

interface ChevronsProps {
  menuMode: MenuMode;
}

const Chevrons = styled.div<ChevronsProps>(
  ({ menuMode }) => css`
    display: flex;
    padding: 4px;
    color: #1b0076;
    opacity: 0.5;
    border: 2px solid #1b0076;
    border-radius: 5px;
    transition: 300ms ease;
    margin: auto;

    ${menuMode === "expanded" &&
    css`
      margin-left: auto;
      margin-right: 10px;
    `}

    ${menuMode === "collapsed" &&
    css`
      margin: auto;
    `}

  svg {
      height: 25px;
      width: 25px;
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  `,
  ({ theme }) => {
    if (!theme.custom) return null;
    const { background } = theme.custom.layout.sideBar.active;
    return css`
      color: ${background};
      border-color: ${background};
    `;
  },
);
