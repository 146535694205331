import {
  ProxySearchParams,
  sanitizeTypesenseParams,
} from "contexts/TypesenseProxyContext";
import useUserIdToken from "./useUserIdToken";

export const useArtistGroupsSearch = () => {
  const token = useUserIdToken();

  const search = async (params: ProxySearchParams) => {
    if (!token || !params.q) {
      return;
    }

    const url = new URL(
      process.env.REACT_APP_ARTIST_GROUPS_SEARCH_PATH.replace(/\/$/, ""),
    );

    url.search = new URLSearchParams(
      sanitizeTypesenseParams(params),
    ).toString();

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return await response.json();
  };

  return { search };
};
