import { SetLiveEvent } from "models/event";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTypesenseProxyContext, ProxySearchParams } from "contexts/TypesenseProxyContext";
import { debounce } from "Utils";
import { useArtistContext } from "Components";

export const useSetLiveEvents = ({
  page = 1,
  query = "*",
  resultsPerPage = 10,
  showType,
  filter,
  filterBy,
  sort,
}: {
  page?: number;
  query?: string;
  resultsPerPage?: number;
  showType?: "upcoming" | "past";
  filter?: "newest" | "oldest" | "published" | "drafts";
  filterBy?: string;
  sort?: string;
}) => {
  const [setLiveEvents, setSetLiveEvents] = useState<SetLiveEvent[]>([]);
  const [totalEvents, setTotalEvents] = useState(0);
  const [loading, setLoading] = useState(true);

  const { proxySearch } = useTypesenseProxyContext();

  const { id } = useArtistContext();

  let sortBy = showType === "upcoming" ? "startsAt:asc" : "endedAt:desc";
  let filters = showType === "upcoming" ? "endedAt:0" : "endedAt:>0";

  switch (filter) {
    case "oldest": {
      sortBy = showType === "upcoming" ? "startsAt:desc" : "endedAt:asc";
      break;
    }
    case "published": {
      filters = `${filters} && status:=published`;
      break;
    }
    case "drafts": {
      filters = `${filters} && status:=unpublished`;
      break;
    }
  }

  const searchParameters: ProxySearchParams = useMemo(
    () => ({
      q: query,
      query_by: "venue",
      sort_by: sort || sortBy,
      filter_by: `${filterBy || `${filters} && deletedAt:0`} && (groupId:=${id} || additionalGroups:=[${id}])`,
      page,
      per_page: resultsPerPage,
    }),
    [query, page, resultsPerPage, filterBy, filters, sortBy, sort, id],
  );

  const getEvents = async (
    searchParameters: ProxySearchParams,
  ): Promise<SetLiveEvent[]> => 
    proxySearch(
      'setlive_events',
      searchParameters,
    )
    .then((resp) => {
      setTotalEvents(resp?.found || 0);
      const events = resp?.hits?.map((event) => event.document) || [];
      setSetLiveEvents(events);
      return events;
    })
    .catch((error) => {
      console.error(error);
      return [];
    })
    .finally(() => {
      setLoading(false);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedQuery = useCallback(debounce(getEvents), []);

  const reloadEvents = async (): Promise<SetLiveEvent[]> =>
    await getEvents(searchParameters);

  const updateEvent = (id: string, data: Partial<SetLiveEvent>) => {
    setSetLiveEvents((prev) =>
      prev.map((event) => (event.id === id ? { ...event, ...data } : event)),
    );
  };

  useEffect(() => {
    setLoading(true);
    if (proxySearch) {
      debouncedQuery(searchParameters);
    }
  }, [proxySearch, searchParameters, debouncedQuery]);

  return { setLiveEvents, totalEvents, loading, reloadEvents, updateEvent };
};
