import { ReactComponent as IconFavortiePoster } from "assets/svg/favorite-poster.svg";
import { SurveyTemplate } from "../../types";
import { getContestDefaults } from "../helpers";

export const releaseArtworkVibes: SurveyTemplate = {
  icon: <IconFavortiePoster />,
  title: "Release Artwork Vibes",
  description:
    "Share the artwork from an upcoming release and ask what vibes the fans get from it",
  templateId: "releaseArtworkVibes",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: formState.title,
        },
      },
      ...getContestDefaults(formState),
    },
    surveyQuestions: [
      {
        type: "image",
        actionRequired: true,
        showLabels: false,
        required: false,
        src: "",
      },
    ],
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-sweeps---r-0cde37"
        : "pp-release-ar-aaf6a6",
    fields: [
      {
        name: "format",
        type: "radio",
        label: "What format is this release?",
        options: ["Single", "EP", "Album"],
        getValidation: (fields) =>
          !!fields.format ? false : "You must select an option.",
      },
      {
        name: "title",
        type: "text",
        label: "What's the title of the release?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.title ? false : "This field cannot be left blank",
      },
      {
        name: "releaseDate",
        type: "text",
        label: "When will it be available (or is it already available)?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.releaseDate ? false : "This field cannot be left blank",
      },
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
