import * as React from "react";
import { SVGProps } from "react";
export const SvgBuilderLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11,17 L11,20 L3,20 L3,17 L11,17 Z M21,17 L21,20 L13,20 L13,17 L21,17 Z M6,12 L6,13 L8,13 L8,12 L10,12 L10,13 L11,13 L11,16 L3,16 L3,13 L4,13 L4,12 L6,12 Z M21,13 L21,16 L13,16 L13,13 L21,13 Z M21,9 L21,12 L13,12 L13,9 L21,9 Z M16,4 L16,5 L18,5 L18,4 L20,4 L20,5 L21,5 L21,8 L13,8 L13,5 L14,5 L14,4 L16,4 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
