import {
  arrayRemove,
  arrayUnion,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { Button } from "melodies-source/Button";
import { Modal } from "melodies-source/Modal";
import { Body1 } from "melodies-source/Text";
import { CenterFlexRow } from "Routes/styled";
import styled from "styled-components";
import { WinnerDetails } from "../hooks/useSweepsWinnersDetails";

export type ActionModalState = {
  confirmLabel: string;
  onConfirm: () => void;
  header: string;
  body: string;
  onClose: () => void;
};

export const ActionModal = ({
  header,
  body,
  confirmLabel,
  onConfirm,
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  confirmLabel: string;
  onConfirm: () => void;
  header: string;
  body: string;
  onClose: () => void;
}) => {
  return (
    <Modal header={header} isOpen={isOpen} onClose={onClose}>
      <Body1 style={{ color: "#000", marginBottom: 30 }}>{body}</Body1>
      <ButtonContainer>
        <StyledCancelButton variant="tertiary" onClick={() => onClose()}>
          Cancel
        </StyledCancelButton>
        <StyledConfirmButton
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          {confirmLabel}
        </StyledConfirmButton>
      </ButtonContainer>
    </Modal>
  );
};

export const buildModalState = ({
  action,
  winnerDetails = {} as WinnerDetails,
  onConfirm,
  onClose,
}: {
  action: "remove" | "replace" | "reset";
  winnerDetails?: WinnerDetails;
  onConfirm?: () => void;
  onClose?: () => void;
}): ActionModalState => {
  switch (action) {
    case "remove":
      return {
        header: "Remove Winner",
        body: `Are you sure you want to remove ${winnerDetails.name} as a winner? You will have the option to replace them.`,
        confirmLabel: "Remove",
        onConfirm,
        onClose,
      };
    case "replace":
      return {
        header: "Replace Winner",
        body: `Are you sure you want to replace ${winnerDetails.name} as a winner? Another random eligible contact will be chosen.`,
        confirmLabel: "Replace",
        onConfirm,
        onClose,
      };
    case "reset":
      return {
        header: "Reset Contest",
        body: "Are you sure you want to reset ignored and winning entries?",
        confirmLabel: "Confirm",
        onConfirm,
        onClose,
      };
  }
};

const StyledCancelButton = styled(Button)`
  ${({ theme }) => theme.mediaQueries.desktop} {
    margin-right: 13px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

const StyledConfirmButton = styled(Button)`
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

const ButtonContainer = styled(CenterFlexRow)`
  ${({ theme }) => theme.mediaQueries.desktop} {
    align-self: flex-end;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column-reverse;
    width: 100%;
  }
`;
