import * as React from "react";
import { SVGProps } from "react";
export const SvgIndividual = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11,10 C13.7614237,10 16,12.2385763 16,15 L16,17 L4,17 L4,15 C4,12.2385763 6.23857625,10 9,10 L11,10 Z M11,11 L9,11 L8.7939606,11.0052149 C6.81492754,11.1056305 5.21542471,12.6444064 5.02005514,14.5969395 L5.00489531,14.8003597 L5,15 L5,16 L15,16 L15,15 L14.9947851,14.7939606 C14.8943695,12.8149275 13.3555936,11.2154247 11.4030605,11.0200551 L11.1996403,11.0048953 L11,11 Z M10,3 C11.6568542,3 13,4.34314575 13,6 C13,7.65685425 11.6568542,9 10,9 C8.34314575,9 7,7.65685425 7,6 C7,4.34314575 8.34314575,3 10,3 Z M10,4 C8.8954305,4 8,4.8954305 8,6 C8,7.1045695 8.8954305,8 10,8 C11.1045695,8 12,7.1045695 12,6 C12,4.8954305 11.1045695,4 10,4 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
