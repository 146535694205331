import { LoadScriptProps, useLoadScript } from "@react-google-maps/api";
import { googleGeocoder, googlePrediction } from "Utils/maps";
import { debounce } from "lodash";
import { OptionProps } from "melodies-source/Autocomplete";
import { SvgLocation } from "melodies-source/Svgs/Location";
import { SetStateAction, useEffect, useMemo, useState } from "react";

interface PlacesAutocomplete {
  loading: boolean;
  isLoaded: boolean;
  setQuery: React.Dispatch<SetStateAction<string>>;
  query: string;
  results: OptionProps[];
  getAddressDetails: (v: OptionProps) => Promise<
    | {
        place: google.maps.GeocoderResult;
        placeName: string;
      }
    | undefined
  >;
}

const libraries = ["places"] as LoadScriptProps["libraries"];

export const usePlacesAutocomplete = (): PlacesAutocomplete => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<OptionProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_FIREBASE_APIKEY as string,
    libraries: libraries,
  });

  const handleSearch = useMemo(() => {
    return debounce(async (queryString) => {
      setLoading(true);
      const results = await googlePrediction(queryString);
      if (results) {
        const places = results
          ?.filter((r) => !!r.structured_formatting.secondary_text)
          ?.map((c) => ({
            icon: <SvgLocation />,
            label: c.structured_formatting.main_text,
            caption: c.structured_formatting.secondary_text,
            value: c.place_id,
          }));
        setResults(places);
        setLoading(false);
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (!!query) {
      handleSearch(query);
    } else {
      handleSearch.cancel();
      setResults([]);
    }
    return () => {
      handleSearch.cancel();
    };
  }, [query]);

  const getAddressDetails = async (selected: OptionProps) => {
    const placeId = selected.value;
    const placeName = selected.label;
    const places = await googleGeocoder(placeId);
    if (places.length) {
      return { place: places[0], placeName };
    }
  };

  return { loading, isLoaded, setQuery, query, results, getAddressDetails };
};
