import { pluralize } from "Utils/pluralize";
import { ReactComponent as AddAlbum } from "assets/svg/add-album.svg";
import { ReactComponent as AddTracks } from "assets/svg/add-tracks.svg";
import { ArrayHelpers, FieldArray, useFormikContext } from "formik";
import { Modal } from "melodies-source/Modal";
import { Checkbox } from "melodies-source/Selectable";
import { SvgRecord } from "melodies-source/Svgs/Record";
import { SvgShow } from "melodies-source/Svgs/Show";
import { Body1, Body2 } from "melodies-source/Text";
import { addToast } from "melodies-source/Toast";
import styled from "styled-components";
import { AddAlbumsForm } from "./AddAlbumsForm";
import { AlbumForm } from "./AlbumForm";
import { AlbumRow } from "./AlbumRow";

import { AlbumSelector } from "./AlbumSelector";
import { AllSongsForm } from "./AllSongsForm";
import { CreateAlbumForm } from "./CreateAlbumForm";
import { SortableList } from "./SortableList";
import {
  Action,
  ModalTitle,
  Section,
  SectionHeader,
  SectionTitle,
  SelectControls,
} from "./components";
import { Album, SongsQuestionValues } from "./types";
import { useState } from "react";
import { SortableRowLoader } from "./SortableRow";
import Skeleton from "react-loading-skeleton";
import { FlexColumn } from "Routes/styled";
import { Caption } from "Components";

const AddButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`;

export const AlbumsForm = ({ loadingAlbums }: { loadingAlbums: boolean }) => {
  const { values, setFieldValue, touched, errors } =
    useFormikContext<SongsQuestionValues>();
  const [albumSelectOpen, setAlbumSelectOpen] = useState(false);
  const [createAlbumOpen, setCreateAlbumOpen] = useState(false);
  const [addAlbumOpen, setAddAlbumOpen] = useState(false);
  const [allSongsOpen, setAllSongsOpen] = useState(false);
  const [addToAlbumIndex, setAddToAlbumIndex] = useState(-1);
  const selected = values.albums.filter(({ visible }) => visible).length;

  return (
    <Section>
      <SectionHeader $withBorder={true}>
        <SectionTitle style={{ marginBottom: 5 }}>
          <SvgRecord /> Song Voting Options
        </SectionTitle>
        <Body1>
          Edit the song choices for this survey by deselecting albums or tracks
          you want to hide from fans or adding additional tracks.
        </Body1>
      </SectionHeader>
      <FieldArray name="albums">
        {(helpers: ArrayHelpers<Album[]>) => (
          <>
            <Modal
              header="Where are you adding tracks?"
              headerColor="navy"
              isOpen={albumSelectOpen}
              onClose={() => setAlbumSelectOpen(false)}
            >
              <AlbumSelector
                onCancel={() => setAlbumSelectOpen(false)}
                onSubmit={(option) => {
                  if (option) {
                    setAddToAlbumIndex(
                      values.albums.findIndex((a) => a.name === option.value),
                    );
                  } else {
                    setCreateAlbumOpen(true);
                  }

                  setAlbumSelectOpen(false);
                }}
              />
            </Modal>
            <Modal
              variant="large"
              withCloseIcon={false}
              withBackdropClose={false}
              isOpen={!!values.albums[addToAlbumIndex]}
            >
              <AlbumForm
                album={values.albums[addToAlbumIndex]}
                isEditing={false}
                onCancel={() => setAddToAlbumIndex(-1)}
                onSubmit={(album) => {
                  helpers.replace(addToAlbumIndex, album);
                  setAddToAlbumIndex(-1);
                }}
              />
            </Modal>
            <Modal
              variant="large"
              withCloseIcon={false}
              withBackdropClose={false}
              isOpen={createAlbumOpen}
            >
              <CreateAlbumForm
                onCancel={() => setCreateAlbumOpen(false)}
                onSubmit={(album) => {
                  helpers.push(album);
                  setCreateAlbumOpen(false);
                }}
              />
            </Modal>
            <Modal
              header={
                <ModalTitle color="navy">
                  <AddAlbum /> Add Album
                </ModalTitle>
              }
              variant="large"
              withBackdropClose={false}
              isOpen={addAlbumOpen}
            >
              <AddAlbumsForm
                onCancel={() => setAddAlbumOpen(false)}
                onSubmit={(albums) => {
                  setFieldValue("albums", [...values.albums, ...albums]);
                  setAddAlbumOpen(false);
                  addToast(
                    albums.length === 1
                      ? `“${albums[0].name}” Added`
                      : `${albums.length} Albums Added`,
                    "success",
                  );
                }}
                exclusions={values.albums.map((album) =>
                  album.id.replace("spotify-", ""),
                )}
              />
            </Modal>
            <Modal
              header={
                <ModalTitle color="navy">
                  <SvgShow /> View All Songs
                </ModalTitle>
              }
              variant="large"
              withBackdropClose={false}
              isOpen={allSongsOpen}
            >
              <AllSongsForm
                albums={values.albums}
                onCancel={() => setAllSongsOpen(false)}
                onSubmit={(albums) => {
                  setFieldValue("albums", albums);
                  setAllSongsOpen(false);
                }}
              />
            </Modal>
            <AddButtons>
              <Action type="button" onClick={() => setAlbumSelectOpen(true)}>
                <AddTracks /> Add Tracks
              </Action>
              <Action type="button" onClick={() => setAddAlbumOpen(true)}>
                <AddAlbum /> Add Album
              </Action>
            </AddButtons>
            <SelectControls>
              {loadingAlbums ? (
                <SelectDeselectAllLoader />
              ) : (
                <Checkbox
                  value={selected === values.albums.length}
                  label="Select/Deselect All"
                  description={`${selected} of ${pluralize(
                    values.albums.length,
                    "Album",
                  )} Selected`}
                  onChange={(visible) => {
                    setFieldValue(
                      "albums",
                      values.albums.map((item) => ({
                        ...item,
                        visible,
                        songs: item.songs.map((song) => ({
                          ...song,
                          visible,
                        })),
                      })),
                    );
                  }}
                />
              )}
              <Action type="button" onClick={() => setAllSongsOpen(true)}>
                <SvgShow /> View All Songs
              </Action>
            </SelectControls>
            <SortableList id="albums" helpers={helpers}>
              {loadingAlbums ? (
                <>
                  <SortableRowLoader />
                  <SortableRowLoader />
                  <SortableRowLoader />
                  <SortableRowLoader />
                  <SortableRowLoader />
                </>
              ) : (
                values.albums.map((album, index) => (
                  <AlbumRow
                    key={index}
                    index={index}
                    album={album}
                    helpers={helpers}
                  />
                ))
              )}
            </SortableList>
            {touched.albums &&
              errors.albums &&
              typeof errors.albums === "string" && (
                <Caption style={{ marginTop: 20 }} error>
                  {errors.albums}
                </Caption>
              )}
          </>
        )}
      </FieldArray>
    </Section>
  );
};

const SelectDeselectAllLoader = () => {
  return (
    <FlexColumn>
      <div>
        <Skeleton width={16} height={16} style={{ marginRight: 16 }} inline />
        <Skeleton width={125} inline />
        <Body2>
          <Skeleton width={130} style={{ marginLeft: 32 }} />
        </Body2>
      </div>{" "}
    </FlexColumn>
  );
};
