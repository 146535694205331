import { DateTime } from "luxon";
import { getNumberSuffix } from "Routes/Dashboard/Components/AppCards/Calendar";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #e8e5f1;
  border-radius: 6px;
  width: 60px;
  color: #1b0076;
`;

const Header = styled.div`
  background: #1b0076;
  border-radius: 6px 6px 0 0;
  color: white;
  font-size: 7px;
  font-weight: bold;
  padding: 1px;
  text-align: center;
`;

const Body = styled.div`
  padding: 4px 12px;
  text-align: center;
`;

const Month = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #1b0076;
`;

const Day = styled.div`
  font-size: 12px;
  line-height: 18px;
`;

interface CalendarCardProps {
  date: DateTime;
}

export const CalendarCard = ({ date }: CalendarCardProps) => {
  return (
    <Container>
      <Header>{date.toFormat("EEEE")}</Header>
      <Body>
        <Month>{date.toFormat("MMM")}</Month>
        <Day>
          {date.day}
          {getNumberSuffix(date.day)}
        </Day>
      </Body>
    </Container>
  );
};
