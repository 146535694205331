import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { H1, H3, Body1, H4 } from "melodies-source/Text";
import { ReactComponent as WaveIcon } from "assets/svg/wave.svg";
import {
  useArtistContext,
  TwoColumns,
  LoadingScreen,
  LoadingScreenProps,
} from "Components";
import { useDesktopMediaQuery } from "hooks";
import { Layout } from "../../Layout";
import { StepProps } from "../..";
import { ReactComponent as UserIcon } from "assets/svg/user.svg";
import { ReactComponent as MessageIcon } from "assets/svg/message.svg";
import { ReactComponent as DesktopIcon } from "assets/svg/desktop.svg";
import { ReactComponent as CalendarIcon } from "assets/svg/calendar.svg";
import { ReactComponent as DashboardIcon } from "assets/svg/dashboard.svg";
import DashboardDesktopImage from "assets/images/dashboard-desktop.png";
import DashboardMobileImage from "assets/images/dashboard-mobile.png";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  query,
  collection,
  where,
  getFirestore,
  orderBy,
  limit,
} from "firebase/firestore";

const POPULATING_STEP_NAMES = [
  "artistInfo",
  "socialLinks",
  "websiteAndMerchStore",
  "upcomingTourDates",
  "dashboard",
] as const;
type PopulatingStep = (typeof POPULATING_STEP_NAMES)[number];

const POPULATING_STEPS: Record<PopulatingStep, LoadingScreenProps> = {
  artistInfo: { icon: <UserIcon />, message: "Analyzing Artist Info" },
  socialLinks: { icon: <MessageIcon />, message: "Gathering Social Links" },
  websiteAndMerchStore: {
    icon: <DesktopIcon />,
    message: "Finding Website and Merch Store",
  },
  upcomingTourDates: {
    icon: <CalendarIcon />,
    message: "Looking for Upcoming Tour Dates",
  },
  dashboard: { icon: <DashboardIcon />, message: "Building Your Dashboard" },
};

export const Dashboard: React.FC<StepProps> = ({ handleNext }) => {
  const artist = useArtistContext();
  const isDesktop = useDesktopMediaQuery();
  const [populatingStepKey, setPopulatingStepKey] = useState<PopulatingStep>(
    POPULATING_STEP_NAMES[0],
  );
  const [analysisDocs, analysisDocsLoading] = useCollectionData(
    query(
      collection(getFirestore(), `/artist_groups/${artist.id}/analysis`),
      orderBy("createdAt", "desc"),
      limit(1),
    ),
  );

  const Heading = isDesktop ? H1 : H3;
  const SubHeading = isDesktop ? H3 : H4;

  const DashnoardImage = isDesktop
    ? DashboardDesktopImage
    : DashboardMobileImage;

  const showPopulatingLoading = async () => {
    for (const stepKey of Object.keys(POPULATING_STEPS) as PopulatingStep[]) {
      let stop = false;
      setPopulatingStepKey((populatingStepKey) => {
        if (!populatingStepKey) {
          stop = true;
          return populatingStepKey;
        }
        return stepKey;
      });
      if (stop) return;
      await new Promise((r) => setTimeout(r, 1200));
    }
  };

  useEffect(() => {
    showPopulatingLoading();
  }, []);

  useEffect(() => {
    const [doc] = analysisDocs || [];
    if (!analysisDocsLoading && (!doc || doc.status !== "running")) {
      setPopulatingStepKey(null);
    }
  }, [analysisDocsLoading, analysisDocs?.[0]?.status]);

  return (
    <Layout
      step={1}
      buttons={{
        next: {
          text: `Your Social Links${populatingStepKey ? "..." : ""}`,
          onClick: handleNext,
          disabled: !!populatingStepKey,
        },
      }}
    >
      <Content>
        <Column>
          <HeadingContainer>
            <WaveIcon />
            {!isDesktop && <Heading>Hey, {artist.name}</Heading>}
          </HeadingContainer>
          {isDesktop && <Heading>Welcome!</Heading>}
          <SubHeading>
            {isDesktop ? `Hey, ${artist.name} - this is` : "Welcome to"} your
            artist dashboard.
          </SubHeading>
          <Body1>
            This is your home base for all things related to your fans and your
            shows. Your dashboard shows you important at-a-glance information,
            like upcoming shows, active surveys, and all of your teammates.
            <br />
            <br />
            Over the next few screens, we will be gathering info to ensure your
            SET experience is optimized.
          </Body1>
        </Column>
        <ImageContainer>
          {populatingStepKey && (
            <LoadingScreenContainer>
              <StyledLoadingScreen
                icon={POPULATING_STEPS[populatingStepKey].icon}
                message={POPULATING_STEPS[populatingStepKey].message}
              />
            </LoadingScreenContainer>
          )}
          <img src={DashnoardImage} alt="dashboard" />
        </ImageContainer>
      </Content>
    </Layout>
  );
};

const Content = styled(TwoColumns)`
  margin: 20px 0 5px;

  @media (max-width: 1050px) {
    gap: 0;
  }

  ${({ theme }) => theme.media.mobile} {
    margin: 10px 0 0 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  svg {
    width: 30px;
    height: 30px;
  }
  ${({ theme }) => theme.media.desktop} {
    ${Body1} {
      font-size: 14px;
      line-height: 130%;
    }
    svg {
      width: 60px;
      height: 60px;
    }
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.desktop} {
    align-items: flex-start;
    justify-content: flex-end;

    img {
      border-radius: 5px 0px 0px 5px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
      margin: 0 -40px 0 0;
      height: 50vh;
      object-fit: cover;
      object-position: top left;
    }
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    img {
      margin: 0 -30px 0 0;
    }
  }

  ${({ theme }) => theme.media.mobile} {
    margin: 25px 0 20px 0;

    img {
      width: 94%;
      margin: auto;
      border-radius: 5px;
      background: url(<path-to-image>),
        lightgray -0.08px 0px / 99.444% 198.641% no-repeat;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    }
  }
`;

const LoadingScreenContainer = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: calc(100% + 50px);

  ${({ theme }) => theme.media.desktop} {
    width: calc(100% + 50px);
    height: calc(100% + 120px);
    margin-right: -40px;
    margin-top: -20px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-right: -30px;
  }

  ${({ theme }) => theme.media.mobile} {
    margin: 0;
  }
`;

const StyledLoadingScreen = styled(LoadingScreen)`
  ${({ theme }) => theme.media.desktop} {
    margin-top: -90px !important;
  }

  ${({ theme }) => theme.media.mobile} {
    margin-top: -15px !important;
    scale: 0.9;
  }
`;
