import { Timestamp } from "firebase/firebase-firestore-compat";
import { DocumentData, DocumentReference } from "firebase/firestore";

export const enum AudienceIntegrationStatus {
  CONNECTING_MAILCHIMP = "connectingMailchimp",
  AUDIENCE_LINK = "audienceLink",
  SEARCHING_AUDIENCES = "searchingAudiences",
  NO_AUDIENCE_ERROR = "noAudienceError",
  MAILCHIMP_NOT_CONNECTED = "mailchimpNotConnected",
  ANALYZING_AUDIENCE = "analyzingAudience",
  CONNECTION_ERROR = "connectionError",
  CONNECTING_AUDIENCE = "connectingAudience",
  CONNECT_FIELDS = "connectFields",
  AUDIENCE_CONNECTED = "audienceConnected",
  CONTACTS_SYNCING = "contactsSyncing",
}

export const enum MailchimpIntegrationStatus {
  AUDIENCE_CONNECTED = "audienceConnected",
  MAILCHIMP_CONNECTED = "mailchimpConnected",
  CONTACTS_SYNCING = "contactsSyncing",
  NEEDS_ATTENTION = "needsAttention",
  NOT_CONNECTED = "notConnected",
}

export type MailchimpActionPayload = {
  action: "get_lists" | "get_list_merge_tags";
  artistGroupId: string;
  listId?: string;
};

export type MailchimpBackfillStatus = "in_progress" | "complete" | "error";

export type MailchimpDocument = {
  id: string;
  lastSynced?: Timestamp;
  listCreatedAt: Timestamp;
  listCreatedBy?: string;
  listId?: string;
  listName?: string;
  mergeTags: MailchimpMergeTagsMap;
  ref: DocumentReference<DocumentData>;
  secretPath?: string;
  serverPrefix?: string;
  syncStatus?: MailchimpBackfillStatus;
};

export type MailchimpLink = {
  rel: string;
  href: string;
  method: string;
  targetSchema: string;
  schema: string;
};

export type MailchimpList = {
  id: string;
  web_id: number;
  name: string;
  contact: {
    company: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    phone: string;
  };
  permission_reminder: string;
  use_archive_bar: boolean;
  campaign_defaults: {
    from_name: string;
    from_email: string;
    subject: string;
    language: string;
  };
  notify_on_subscribe: string;
  notify_on_unsubscribe: string;
  date_created: string;
  list_rating: number;
  email_type_option: boolean;
  subscribe_url_short: string;
  subscribe_url_long: string;
  beamer_address: string;
  visibility: string;
  double_optin: boolean;
  has_welcome: boolean;
  marketing_permissions: boolean;
  modules: string[];
  stats: {
    member_count: number;
    total_contacts: number;
    unsubscribe_count: number;
    cleaned_count: number;
    member_count_since_send: number;
    unsubscribe_count_since_send: number;
    cleaned_count_since_send: number;
    campaign_count: number;
    campaign_lasst_sent: string;
    merge_field_count: number;
    avg_sub_rate: number;
    avg_unsub_rate: number;
    target_sub_rate: number;
    open_rate: number;
    click_rate: number;
    last_sub_date: string;
    last_unsub_date: string;
    _links: MailchimpLink[];
  };
  _links: MailchimpLink[];
};

export type MailchimpMergeField =
  | "first_name"
  | "last_name"
  | "zip"
  | "phone_us"
  | "phone_international";

export type MailchimpMergeTags = Record<
  MailchimpMergeField,
  {
    name: string;
    tag: string;
    type: MailchimpMergeType;
    options?: Record<string, string>;
    selected?: boolean;
    included?: boolean;
  }
>;

type MailchimpMergeType =
  | "text"
  | "number"
  | "address"
  | "phone"
  | "date"
  | "url"
  | "imageurl"
  | "radio"
  | "dropdown"
  | "birthday"
  | "zip";

export type MailchimpMergeTagsMap = Partial<
  Record<
    MailchimpMergeField,
    Record<"merge_id" | "name" | "tag" | "type", string>
  >
>;

export const enum MailchimpRedirects {
  artistGroups = "artistGroups",
  artistGroupManagement = "artistGroupManagement",
  artistGroupMessages = "artistGroupMessages",
}
