import { useMediaQuery } from "react-responsive";
import { useTheme } from "styled-components";

export const useTabletMediaQuery = (min?: string) => {
  const theme = useTheme();
  const tabletSplit = theme.media.tablet.split(" and ");
  return useMediaQuery({
    query: [min ? `(min-width: ${min})` : tabletSplit[1], tabletSplit[2]].join(
      " and ",
    ),
  });
};
