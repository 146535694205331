import { DateTime } from "luxon";
import { useState } from "react";
import { DuplicateViews } from ".";
import { BulkForm } from "./BulkForm";
import { Controls } from "./Controls";
import { SingleForm } from "./SingleForm";
import { ConcertDetails, ListOption } from "./types";

interface CreateShowProps {
  view: DuplicateViews;
  upcomingShows: { date: DateTime }[];
  onCreate: (v: ConcertDetails<Date> | ConcertDetails<Date>[]) => void;
  onCancel: VoidFunction;
}

export const CreateShow = ({
  view,
  upcomingShows,
  onCreate,
  onCancel,
}: CreateShowProps) => {
  const [single, setIsSingle] = useState(true);
  const controls = <Controls single={single} onSingleChange={setIsSingle} />;

  return single ? (
    <SingleForm
      view={view}
      controls={controls}
      onCancel={onCancel}
      onCreate={onCreate}
    />
  ) : (
    <BulkForm
      upcoming={upcomingShows}
      controls={controls}
      onCancel={onCancel}
      onSubmit={onCreate}
    />
  );
};
