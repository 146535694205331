import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { doc, getFirestore } from "firebase/firestore";
import { useArtistContext } from "Components";
import IntegrationCard from "../IntegrationCard";
import layloHeader from "./laylo-header.svg";
import LayloLogo from "./LayloLogo";
import styled, { CSSProperties } from "styled-components";
import { DateTime } from "luxon";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Button } from "melodies-source/Button";
import { useDesktopMediaQuery } from "hooks";
import { LayloIntegrationStatus, LayloDocument } from "./types";
import { LayloIntegrationModal, LayloRemovalModal } from "./LayloModals";
import LastSyncIcon from "../LastSyncIcon";

const LayloIntegration = ({ sx }: { sx?: CSSProperties }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [integrationStatus, setIntegrationStatus] =
    useState<LayloIntegrationStatus>(LayloIntegrationStatus.NOT_CONNECTED);
  const [removalModalOpen, setRemovalModalOpen] = useState(false);

  const { id: artistGroupId } = useArtistContext();

  const [layloDoc] = useDocumentData<LayloDocument>(
    doc(
      getFirestore(),
      `artist_groups/${artistGroupId}/artist_group_private/laylo`,
    ),
  );

  const lastSynced: DateTime | undefined = layloDoc?.lastSynced
    ? DateTime.fromMillis(layloDoc.lastSynced.toMillis())
    : undefined;

  useEffect(() => {
    if (!!layloDoc?.secretPath) {
      setIntegrationStatus(LayloIntegrationStatus.CONNECTED);
    } else {
      setIntegrationStatus(LayloIntegrationStatus.NOT_CONNECTED);
    }
  }, [layloDoc]);

  return (
    <>
      <LayloIntegrationModal
        artistGroupId={artistGroupId}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <LayloRemovalModal
        artistGroupId={artistGroupId}
        onClose={() => {
          setRemovalModalOpen(false);
          setIntegrationStatus(LayloIntegrationStatus.NOT_CONNECTED);
        }}
        isOpen={removalModalOpen}
      />
      <LayloIntegrationCard
        integrationStatus={integrationStatus}
        lastSynced={lastSynced}
        onRemoveLaylo={() => setRemovalModalOpen(true)}
        setModalOpen={setModalOpen}
        sx={sx}
      />
    </>
  );
};

export default LayloIntegration;

const LayloIntegrationCard = ({
  integrationStatus,
  lastSynced,
  onRemoveLaylo,
  setModalOpen,
  sx,
}: {
  integrationStatus: LayloIntegrationStatus;
  lastSynced?: DateTime;
  onRemoveLaylo: () => void;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  sx?: CSSProperties;
}) => {
  const isDesktop = useDesktopMediaQuery();

  if (integrationStatus === LayloIntegrationStatus.CONNECTED) {
    return (
      <IntegrationCard
        header={<img src={layloHeader} alt="laylo logo" />}
        body={
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <LastSyncIcon />
              <StatusText isDesktop={isDesktop}>
                Last Sync Time:{" "}
                {lastSynced
                  ? lastSynced.toLocaleString(DateTime.TIME_SIMPLE)
                  : "Never Synced"}
              </StatusText>
            </div>
            <BodyText>You're connected to Laylo!</BodyText>
          </>
        }
        footer={
          <FooterText
            style={{ marginTop: 9, textAlign: "center" }}
            onClick={onRemoveLaylo}
          >
            Remove Laylo
          </FooterText>
        }
        sx={sx}
      />
    );
  }

  // laylo is not yet connected
  return (
    <IntegrationCard
      header={<img src={layloHeader} alt="laylo logo" />}
      footer={
        <Button onClick={() => setModalOpen(true)}>
          <span style={{ marginRight: "0.35rem" }}>Connect to</span>
          <LayloLogo fill="white" />
        </Button>
      }
      body="SET can automatically sync your contacts to your Laylo account. Click the button below to get started."
      sx={sx}
    />
  );
};

const BodyText = styled.div`
  font-size: 10px;
  line-height: 170%;
  font-weight: 400;
  text-align: center;
  color: #666;
`;

const FooterText = styled.div`
  color: #180171;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0 auto;
  padding-bottom: 5px;
  cursor: pointer;
  bottom: 0;
`;

const StatusText = styled.div<{ isDesktop: boolean }>`
  color: #635bff;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 12px)" : "13px")};
  line-height: 19.5px;
  margin-left: 0.25rem;
`;
