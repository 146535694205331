import { ArrayHelpers } from "formik";
import { PropsWithChildren } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface SortableListProps {
  id: string;
  helpers: ArrayHelpers;
}

export const SortableList = ({
  id,
  helpers,
  children,
}: PropsWithChildren<SortableListProps>) => {
  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        if (destination && destination.droppableId === source.droppableId) {
          helpers.move(source.index, destination.index);
        }
      }}
    >
      <Droppable droppableId={id}>
        {(provided) => (
          <Container ref={provided.innerRef} {...provided.droppableProps}>
            {children}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
};
