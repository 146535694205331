import { Dispatch, SetStateAction } from "react";
import {
  MailchimpMergeTags,
  AudienceIntegrationStatus,
  MailchimpList,
  MailchimpMergeField,
} from "./types";
import styled, { CSSProperties } from "styled-components";
import { Button } from "melodies-source/Button";
import { addMergeTagsToList } from "./mailchimpActions";
import CheckIcon from "./CheckIcon";
import { Checkbox } from "melodies-source/Selectable";

const fieldKeyToLabel = (fieldKey: MailchimpMergeField) => {
  switch (fieldKey) {
    case "first_name":
      return "First name";
    case "last_name":
      return "Last name";
    case "phone_international":
      return "Phone (International)";
    case "phone_us":
      return "Phone (US)";
    case "zip":
      return "Zipcode";
    default:
      return "";
  }
};

const MergeFieldsSelect = ({
  artistGroupId,
  mergeTags,
  selectedList,
  setMergeTags,
  setStatus,
}: {
  artistGroupId: string;
  mergeTags: MailchimpMergeTags;
  selectedList: MailchimpList;
  setMergeTags: Dispatch<SetStateAction<MailchimpMergeTags>>;
  setStatus: Dispatch<SetStateAction<AudienceIntegrationStatus>>;
}) => {
  // to display required merge tags already in artist audience, filter to show
  // tags with `included` property set to true (done in the mailchimp actions cloud function)
  const includedMergeTags = Object.entries(mergeTags)
    .filter(([, mergeTag]) => mergeTag.included)
    .map(([fieldKey, mergeTag]) => (
      <FieldRow key={`${mergeTag.tag}-content`}>
        <div style={{ marginRight: 8 }}>
          <CheckIcon />
        </div>
        <div>{fieldKeyToLabel(fieldKey as MailchimpMergeField)}</div>
      </FieldRow>
    ));

  // all merge tags not already included in artist audience. these are preselected for artist
  const mergeTagsToSelect = Object.entries(mergeTags)
    .filter(([, mergeTag]) => !mergeTag.included)
    .map(([fieldKey, mergeTag]) => (
      <FieldRow key={`${mergeTag.tag}-select`}>
        <Checkbox
          style={{ width: "auto" }}
          label={mergeTag.name}
          value={mergeTag.selected}
          onChange={(selected) =>
            setMergeTags((mergeTags) => ({
              ...mergeTags,
              [fieldKey]: {
                ...mergeTags[fieldKey],
                selected,
              },
            }))
          }
        />
      </FieldRow>
    ));

  const handleMergeTagsSubmission = async () => {
    setStatus(AudienceIntegrationStatus.CONNECTING_AUDIENCE);

    try {
      await addMergeTagsToList(
        selectedList.id,
        selectedList.name,
        mergeTags,
        artistGroupId,
      );

      setStatus(AudienceIntegrationStatus.AUDIENCE_CONNECTED);
    } catch (error) {
      console.error(error);

      setStatus(AudienceIntegrationStatus.CONNECTION_ERROR);
    }
  };

  return (
    <>
      {!!includedMergeTags.length && (
        <>
          <BodyText style={{ marginBottom: 17 }}>
            We've found the following fields to send your data to
          </BodyText>
          <FieldsBackground>
            <FieldsContainer>{includedMergeTags}</FieldsContainer>
          </FieldsBackground>
        </>
      )}
      {!!mergeTagsToSelect.length && (
        <>
          <BodyContent
            includedMergeTags={includedMergeTags}
            mergeTags={mergeTags}
            sx={{ marginBottom: 20 }}
          />
          <FieldsSelectBackground>
            <FieldsContainer>{mergeTagsToSelect}</FieldsContainer>
          </FieldsSelectBackground>
        </>
      )}
      <Button
        style={{ maxWidth: 385 }}
        onClick={() => handleMergeTagsSubmission()}
      >
        Next
      </Button>
    </>
  );
};

export default MergeFieldsSelect;

const BodyContent = ({
  includedMergeTags,
  mergeTags,
  sx,
}: {
  includedMergeTags: JSX.Element[];
  mergeTags: MailchimpMergeTags;
  sx: CSSProperties;
}) => {
  let missingTags = Object.entries(mergeTags)
    .filter(([, mergeTag]) => !mergeTag.included)
    .map(([fieldKey]) => {
      switch (fieldKey) {
        case "first_name":
        case "last_name":
          return "name";
        case "address":
          return "address";
        case "zip":
          return "zip code";
        case "phone_us":
        case "phone_international":
          return "phone number";
        default:
          return "";
      }
    });

  // dedupe
  missingTags = missingTags.filter(
    (field, idx) => missingTags.indexOf(field) === idx,
  );

  if (!missingTags.length) {
    return null;
  }

  // if international phone number is one of the fields not included, display text is slightly different.
  // if that changes we can make this a little cleaner.
  if (missingTags.length === 1) {
    return (
      <BodyText style={sx}>
        We can also create the following field in your audience to send{" "}
        {missingTags[0]} data
        {mergeTags.phone_international.included
          ? ""
          : " for fans outside the US"}
        . If you would <b>not</b> like to create this field please uncheck the
        boxes below:
      </BodyText>
    );
  }

  const joinedTags = missingTags.reduce((acc, curr, idx) => {
    if (idx === missingTags.length - 1) {
      acc += curr;
    } else if (idx === missingTags.length - 2) {
      acc += curr + " and ";
    } else {
      acc += curr + ", ";
    }
    return acc;
  }, "");

  return (
    <BodyText style={sx}>
      We can {includedMergeTags?.length ? "also" : ""} create the following
      fields in your audience to send {joinedTags} data. If you would <b>not</b>{" "}
      like to create these fields please uncheck the boxes below:
    </BodyText>
  );
};

const BodyText = styled.div`
  font-size: 17px;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldsBackground = styled.div`
  margin-bottom: 33px;
  background-color: rgba(255, 255, 255, 0.83);
  border-radius: 8px;
  padding-top: 13px;
  padding-bottom: 13px;
  width: 378px;
  max-width: 90%;
  display: flex;
  justify-content: center;
`;

const FieldsSelectBackground = styled(FieldsBackground)`
  justify-content: flex-start;
  padding-left: 44px;
`;

const FieldRow = styled.div`
  display: flex;
  padding: 2px 0;
`;
