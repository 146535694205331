import React from "react";
import styled from "styled-components";
import { Portal } from "../common/Portal";
import { useOverlay } from "../utils/hooks";
import { SvgLeft } from "../Svgs/Left";

const BackIcon = styled(SvgLeft)`
  color: #666666;
`;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  background: #ffffff;
  padding: 16px 18px;
  z-index: 1000;
`;

const Body = styled.div`
  height: calc(100vh - 78px);
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element;
  style?: any;
};
export const FullscreenModal = ({
  isOpen,
  onClose,
  children,
  ...props
}: Props) => {
  useOverlay(isOpen);
  Math.random().toString(36).substring(2);
  return (
    <>
      {isOpen && (
        <Portal>
          <Container {...props}>
            <div
              style={{
                cursor: "pointer",
                height: "30px",
                marginBottom: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <BackIcon />
            </div>
            <Body>{children}</Body>
          </Container>
        </Portal>
      )}
    </>
  );
};
