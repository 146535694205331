export const debounce = (
  func: (...args: unknown[]) => unknown,
  timeout = 300,
) => {
  let timer: ReturnType<typeof setTimeout>;
  return function (...args: unknown[]) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, timeout);
  };
};
