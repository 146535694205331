import { Caption, useArtistContext } from "Components";
import { RefreshIcon } from "Components/RefreshIcon";
import { REQUIRED_MSG } from "Utils/yupStrictPassword";
import { ImageUpload } from "melodies-source/ImageUpload";
import { ImageUploadModal } from "melodies-source/ImageUploadModal";
import { Td } from "melodies-source/Text";
import styled from "styled-components";

const Container = styled.div`
  flex-shrink: 0;
`;

const Cover = styled.img`
  width: 186px;
  height: 186px;
  margin-bottom: 4px;
  object-fit: cover;
`;

const StyledImageUpload = styled(ImageUpload)`
  width: 356px;
  border-radius: 9px;
  background: #fff;
  padding: 10px;
`;

interface CoverFieldProps {
  src?: string;
  error?: string;
  onComplete?: (url: string) => void;
}

export const CoverField = ({ src, error, onComplete }: CoverFieldProps) => {
  const { id: artistId } = useArtistContext();

  return (
    <Container>
      <Td>Album Image</Td>
      {src ? (
        <>
          <Cover src={src} />
          <ImageUploadModal
            icon={src && <RefreshIcon />}
            label={src && "Replace"}
            uploadPath={`surveys/${artistId}/album-covers`}
            uploadWithFileExt={false}
            withBackdropClose={false}
            onComplete={(url) => onComplete?.(url)}
          />
        </>
      ) : (
        <StyledImageUpload
          uploadPath={`surveys/${artistId}/album-covers`}
          uploadWithFileExt={false}
          withBackground={false}
          onComplete={(url) => onComplete?.(url)}
        />
      )}
      {error && <Caption error>{REQUIRED_MSG}</Caption>}
    </Container>
  );
};
