import { useEffect, useState } from "react";

const useIsMounted = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setTimeout(() => setMounted(true), 20);
    }
  }, [mounted]);

  return { mounted };
};

export default useIsMounted;
