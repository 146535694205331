import * as React from "react";
import { SVGProps } from "react";
export const SvgShow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10,5 C12.9455187,5 15.6121853,6.62962963 18,9.88888889 C15.6121853,13.1481481 12.9455187,14.7777778 10,14.7777778 C7.05448133,14.7777778 4.38781467,13.1481481 2,9.88888889 C4.38781467,6.62962963 7.05448133,5 10,5 Z M10.2341845,6.7815767 L10.1674377,6.78158524 C11.9380133,6.86249421 13.3468235,8.22273692 13.3468235,9.88888889 C13.3468235,11.6071081 11.8485869,13 10.0004183,13 C8.15224973,13 6.65401307,11.6071081 6.65401307,9.88888889 C6.65401307,8.22273692 8.0628233,6.86249421 9.83339893,6.78158524 L10.0004183,6.77777778 C7.89186282,6.77777778 5.90955386,7.69975659 4.01749911,9.63753535 L3.77777778,9.88888889 L3.8076404,9.92074866 C5.69305902,11.9339006 7.6673988,12.9277777 9.76665206,12.9962011 L10.0004183,13 C12.1089738,13 14.0912827,12.0780212 15.9833375,10.1402424 L16.2222222,9.88888889 L16.1931962,9.85702912 C14.3077776,7.84387721 12.3334378,6.85000012 10.2341845,6.7815767 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
