import * as React from "react";
import { SVGProps } from "react";
export const SvgShirt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="m17.5 7.5-1.926 2.473L14 8.75V16H6V8.75L4.43 9.97 2.5 7.5 7 4c.08.23.264.54.539.848C8.059 5.429 8.908 6 10 6c.546 0 1.739-.246 2.497-1.125.208-.241.383-.53.503-.875l4.5 3.5Zm-1.404.175-.697.895L13 6.707V15H7V6.707L4.604 8.568l-.699-.894 2.843-2.211c.171.197.367.388.582.563C7.979 6.554 8.887 7 10 7c.495 0 1.262-.133 2.003-.508.445-.225.903-.55 1.284-1.002l2.81 2.185Z"
      fill="currentColor"
    />
  </svg>
);
