import React from "react";
import * as DonutChart from "Components/DonutChart";
import * as OverallScore from "./OverallScore";
import * as RatingRanges from "./RatingRanges";
import * as RatingBars from "./RatingBars";

export interface Item {
  score: number;
  label: string;
  count: number;
}

export interface Props {
  data: Item[];
  total?: number;
}
export const RatingChart: React.FC<Props> = ({ data, total }) => {
  const donutChartData: DonutChart.Props["data"] = data.map(
    ({ score, label, count }) => ({ id: `${score}`, label, value: count }),
  );

  const votesCount = total ?? data.reduce((sum, { count }) => sum + count, 0);

  const overallScore =
    votesCount > 0
      ? data.reduce((sum, { score, count }) => sum + +score * count, 0) /
        votesCount
      : 0;

  const overallScoreData: OverallScore.Props["data"] = {
    score: overallScore,
    maxScore: data.length,
  };

  const getItemPercentage = (scoreCount, votesCount) =>
    Math.round((scoreCount / votesCount) * 100);

  const ratingRangeData: RatingRanges.Props["data"] = data.map(
    ({ score, label, count }) => ({
      score: +score,
      label,
      percentage: getItemPercentage(count, votesCount),
    }),
  );

  const ratingBarsData: RatingBars.Props["data"] = data;

  return (
    <div>
      {data.length === 3 && <DonutChart.Component data={donutChartData} />}
      {data.length === 5 && (
        <>
          <OverallScore.Component data={overallScoreData} />
          <RatingRanges.Component data={ratingRangeData} />
        </>
      )}
      {data.length === 10 && (
        <>
          <OverallScore.Component data={overallScoreData} />
          <RatingBars.Component data={ratingBarsData} />
        </>
      )}
    </div>
  );
};

export const Component = RatingChart;
