import toast, { useToaster } from "react-hot-toast";
import { Toast as ToastBase, ToastVariant } from "./Toast";
import styled, { keyframes } from "styled-components";

export const Toaster = () => {
  const { toasts, handlers } = useToaster({ duration: 7000 });
  const { startPause, endPause, updateHeight, calculateOffset } = handlers;

  return (
    <Container onMouseEnter={startPause} onMouseLeave={endPause}>
      {toasts.map((noti) => {
        const offset = calculateOffset(noti, {
          reverseOrder: false,
          gutter: 8,
        });

        const ref = (el) => {
          if (el && typeof noti.height !== "number") {
            const height = el.getBoundingClientRect().height;
            updateHeight(noti.id, height);
          }
        };
        return (
          <Toast
            key={noti.id}
            ref={ref}
            label={noti.message}
            onAction={() => toast.dismiss(noti.id)}
            variant={
              ["success", "error"].includes(noti.type)
                ? (noti.type as ToastVariant)
                : "default"
            }
            style={{
              position: "absolute",
              opacity: noti.visible ? 1 : 0,
              transform: `translateY(${offset}px)`,
            }}
            {...noti.ariaProps}
          />
        );
      })}
    </Container>
  );
};

const enterAnimation = keyframes`
 0% { transform: translateX(105%) }
 100% { transform: translateX(0) }
`;

const Toast = styled(ToastBase)`
  color: #ffffff;
  animation-name: ${enterAnimation};
  animation-duration: 300ms;
  transition: all 0.5s ease-out;
`;

const Container = styled.div`
  position: fixed;
  top: 8px;
  left: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
`;
