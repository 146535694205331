type QueryParams = {
  [key: string]: string;
};

export type GetUrlParams = {
  domain?: string;
  path?: string;
  params?: QueryParams;
};

export const getUrl = ({
  domain = window.location.origin,
  path = "/",
  params = {},
}: GetUrlParams) => {
  const url = new URL(
    path,
    domain?.match(/^https?:\/\//i) ? domain : `https://${domain}`,
  );
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });
  return url.toString();
};
