import React from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as BackIcon } from "assets/svg/back.svg";

interface Props {
  path: string;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}
export const BackLink: React.FC<Props> = ({
  path,
  className,
  children,
  onClick,
}) => {
  const { artistId } = useParams<{
    artistId: string;
  }>();
  return (
    <StyledLink
      className={className}
      to={artistId ? `/${artistId}${path}` : path}
      {...(onClick && { onClick })}
    >
      <BackIcon /> {children}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: var(--main-color);
  font-weight: 500;

  svg {
    margin-right: 5px;
  }
`;
