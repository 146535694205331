import React from "react";
import styled, { css } from "styled-components";
import { Body2, H4 } from "melodies-source/Text";
import { ReactComponent as DeleteIcon } from "assets/svg/trash.svg";

interface Data {
  id: string;
  name: string;
  image: string;
}

export type ProgramCardData = Data;

interface Props {
  data: Data;
  isOption?: boolean;
  onSelect?: (id: string) => void;
  onDelete?: () => void;
}

export const ProgramCard: React.FC<Props> = ({
  data: { id, name, image },
  isOption,
  onSelect,
  onDelete,
}) => {
  return (
    <Container
      isOption={isOption}
      {...(onSelect && { onClick: () => onSelect(id) })}
    >
      <img src={image} alt={name} />
      <div>
        <H4>{name}</H4>
      </div>
      {onDelete && <StyledDeleteIcon onClick={onDelete} />}
    </Container>
  );
};

type ContainerProps = Pick<Props, "isOption">;

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  border-radius: 6px;
  color: var(--header-text-color);
  border: 1px solid var(--text-color);

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  ${Body2} {
    text-transform: capitalize;
  }

  ${({ isOption }) =>
    isOption &&
    css`
      border: none;
      padding: 5px 10px;

      &:hover {
        background: var(--hover-color);
      }
    `}
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  margin-left: auto;

  &:hover {
    cursor: pointer;
  }
`;
