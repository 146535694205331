import { VideoModal } from "Components/VideoModal";
import React, { useState } from "react";
import { Video as VideoType } from "Routes/HelpCenter";
import styled from "styled-components";
import { ReactComponent as PlayButton } from "assets/svg/play.svg";

interface Props {
  video: VideoType;
}
export const Video: React.FC<Props> = ({ video }) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Container onClick={() => setModalOpen((open) => !open)}>
        <Poster src={video.thumbnail} alt={video.thumbnail} />
        <StyledPlayButton />
      </Container>
      <VideoModal open={modalOpen} setOpen={setModalOpen} video={video} />
    </>
  );
};

const Container = styled.span`
  position: relative;
  width: 250px;
  height: 150px;
  margin: 0 0 20px 20px;
  float: right;
  cursor: pointer;

  ${({ theme }) => theme.media.mobile} {
    margin: auto;
    margin-bottom: 20px;
    width: 100%;
    height: 40vw;
  }
`;

const Poster = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

const StyledPlayButton = styled(PlayButton)`
  position: absolute;
  width: 100px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;
