import styled, { css } from "styled-components";
import { Font15 } from "Routes/styled";
import BoxSvg from "./BoxSvg";

const HoverIndicator = styled.div`
  background: #f2f5f733;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  display: none;
  bottom: -3px;
`;

const SelectableContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 100%;
  :hover {
    cursor: pointer;
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: default;
      `}
    ${HoverIndicator} {
      display: block;
    }
  }
`;

export type SelectableProps = {
  label?: string | JSX.Element;
  value: boolean;
  description?: string;
  disabled?: boolean;
  style?: any;
  onChange?: (v: boolean) => void;
};
export const Selectable = ({
  label,
  value,
  onChange,
  disabled = false,
  description,
  ...props
}: SelectableProps) => {
  const toggle = () => onChange?.(!value);

  const textUI = (
    <Label onClick={() => toggle()} disabled={disabled}>
      <Font15>{label}</Font15>
      {description && <Body2>{description}</Body2>}
    </Label>
  );

  return (
    <Container {...props}>
      <SelectableContainer
        disabled={disabled}
        onClick={() => {
          !disabled && toggle();
        }}
      >
        <div
          style={{ zIndex: 1, height: 20 }}
          tabIndex={0}
          onKeyDown={({ code }) => {
            if (code === "Space" || code === "Enter") toggle();
          }}
        >
          <BoxSvg selected={value} />
        </div>
        <HoverIndicator style={{ position: "absolute" }} />
      </SelectableContainer>
      {label && textUI}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;

  ${({ theme }) => theme.media.mobile} {
    margin-bottom: 20px;
  }
`;

const Label = styled.label<{ disabled?: boolean }>`
  width: 100%;
  cursor: pointer;
  margin-left: 10px;
  line-height: 15px;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: #333333;
      ${Body1} {
        color: #999999;
      }
    `}
`;

export const Checkbox = ({ ...props }: SelectableProps) => (
  <Selectable {...props} />
);

export const Body1 = styled.p`
  font-family: var(--max-font-primary);
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
`;

export const Body2 = styled.p`
  font-family: var(--max-font-primary);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;
