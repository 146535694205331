import React from "react";

interface Props {
  fill?: string;
}

const CommunityLogo: React.FC<Props> = (props) => {
  const fill = props.fill || "black";
  return (
    <svg
      width="198"
      height="32"
      viewBox="0 0 198 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.2823 0C40.5394 0 36.2963 5.12796 36.2963 11.4099C36.2963 17.7579 40.5394 23.0047 47.2823 23.0047C52.7421 23.0047 56.6503 19.9554 57.9002 15.1329L53.9341 15.1032C53.3219 17.9709 50.3349 19.4337 47.1916 19.4337C42.7668 19.4337 40.2026 15.9864 40.2026 11.4099C40.2026 6.83339 42.8279 3.53805 47.1916 3.53805C50.6601 3.53805 52.9603 5.79824 53.3365 8.44804H57.139C56.9839 3.32342 52.866 0 47.2823 0Z"
        fill={fill}
      />
      <path
        d="M67.4346 6.15991C63.1041 6.15991 59.6567 9.64018 59.6567 14.3719C59.6567 19.3727 63.1041 22.6383 67.4346 22.6383C71.6748 22.6383 75.1254 19.3727 75.1254 14.3719C75.1254 9.64018 71.6748 6.15991 67.4346 6.15991ZM67.4346 19.4354C64.9318 19.4354 63.0709 17.3932 63.0709 14.3736C63.0709 11.4398 64.9318 9.36614 67.4346 9.36614C69.847 9.36614 71.7075 11.4414 71.7075 14.3736C71.7075 17.3932 69.8454 19.4354 67.4346 19.4354Z"
        fill={fill}
      />
      <path
        d="M95.3024 5.85767C92.3834 5.85767 90.3856 7.82398 89.8705 9.94218H89.415C89.6393 7.93622 87.5363 5.85767 84.8995 5.85767C82.9875 5.85767 81.8368 6.64023 81.0326 7.92136H80.3014C80.3657 7.36004 80.3625 6.81357 80.2896 6.46523H76.8422V22.3328H80.2896V14.8885C80.2896 10.8337 81.9704 9.12001 84.5626 9.12001C86.9418 9.12001 87.3395 10.6769 87.3395 15.3475V22.3328H90.7573V15.2253C90.7573 10.6158 92.6178 9.12001 95.121 9.12001C97.348 9.12001 97.989 10.6769 97.989 15.1642V22.3344H101.406V13.7592C101.408 7.99404 99.5754 5.85767 95.3024 5.85767Z"
        fill={fill}
      />
      <path
        d="M122.497 5.85767C119.574 5.85767 117.58 7.82398 117.061 9.94218H116.609C116.834 7.93622 114.727 5.85767 112.09 5.85767C110.178 5.85767 109.031 6.64023 108.227 7.92136H107.495C107.556 7.36004 107.553 6.81357 107.484 6.46523H104.037V22.3328H107.484V14.8885C107.484 10.8337 109.16 9.12001 111.757 9.12001C114.136 9.12001 114.534 10.6769 114.534 15.3475V22.3328H117.948V15.2253C117.948 10.6158 119.809 9.12001 122.312 9.12001C124.539 9.12001 125.18 10.6769 125.18 15.1642V22.3344H128.597V13.7592C128.599 7.99404 126.769 5.85767 122.497 5.85767Z"
        fill={fill}
      />
      <path
        d="M145.043 6.46851H141.625V14.6442C141.625 18.552 140.039 19.8332 137.352 19.8332C135.068 19.8332 134.67 18.5223 134.67 14.3734V6.46851H131.222V15.6513C131.222 21.0533 133.235 22.9436 136.715 22.9436C138.985 22.9436 140.488 21.7665 141.282 20.3764H141.691C141.649 20.6372 141.627 20.8716 141.627 21.0252C141.627 21.6955 141.657 22.1512 141.779 22.3361H145.26V22.0901C145.169 21.8474 145.169 21.3917 145.169 20.7792L145.043 6.46851Z"
        fill={fill}
      />
      <path
        d="M156.974 5.85767C154.74 5.85767 153.411 6.57747 152.524 7.92136H151.793C151.844 7.43268 151.829 6.95391 151.756 6.46523H148.342V22.3328H151.756V14.6128C151.756 10.553 154.291 9.12001 156.181 9.12001C158.778 9.12001 159.353 10.5861 159.353 15.3772V22.3328H162.77V13.7592C162.77 8.20698 161.155 5.85767 156.974 5.85767Z"
        fill={fill}
      />
      <path d="M169.244 6.4668H165.826V22.3343H169.244V6.4668Z" fill={fill} />
      <path
        d="M181.577 21.9976L180.816 19.1596C180.018 19.5575 179.441 19.8001 178.798 19.8001C177.882 19.8001 177.701 19.2207 177.701 17.7579V9.48813H181.636V6.46849H177.255C178.123 5.38544 178.305 2.85779 177.791 1.88867H174.344V6.46849H171.872V9.48813H174.346V18.552C174.346 21.083 175.475 22.7917 178.585 22.7917C179.805 22.7917 180.998 22.3922 181.577 21.9976Z"
        fill={fill}
      />
      <path
        d="M167.535 0.48877C166.437 0.48877 165.551 1.37534 165.551 2.46994C165.551 3.56784 166.437 4.45443 167.535 4.45443C168.628 4.45443 169.516 3.56784 169.516 2.46994C169.516 1.37534 168.63 0.48877 167.535 0.48877Z"
        fill={fill}
      />
      <path
        d="M193.999 26.0424C193.399 26.7441 192.476 27.5778 191.165 27.5778C189.936 27.5778 188.325 26.7953 188.087 24.7679H184.77C184.792 25.4299 184.858 26.1151 185.132 26.7986C185.444 27.6488 186.018 28.4644 186.768 29.0356C188.307 30.1798 190.063 30.4159 191.718 30.3614C193.457 30.2739 195.305 29.5062 196.534 27.9972C197.711 26.5592 198 24.8075 198 23.2638L197.997 15.2896V6.4668H194.658V14.9248C194.576 18.5965 193.014 19.8314 190.4 19.8314C188.112 19.8314 187.717 18.5206 187.717 14.3717V6.4668H184.267V15.6495C184.267 21.0515 186.284 22.9452 189.76 22.9452C192.291 22.9452 193.881 21.4791 194.582 19.8925L194.676 19.5987V22.5143C194.676 24.4079 194.582 25.3573 193.999 26.0424Z"
        fill={fill}
      />
      <path
        d="M0.790846 26.6238C3.69171 21.9235 6.92441 19.7227 11.998 21.0402C15.0838 21.8442 18.1069 22.9884 21.8332 22.9884C26.291 22.9884 28.3911 22.0836 28.9492 21.7897C29.0499 21.7386 29.1094 21.6411 29.1094 21.5256V13.3053C29.1094 13.1601 28.9574 13.0627 28.8303 13.1237C28.1171 13.4671 26.0137 14.2613 21.8349 14.2613C16.2478 14.2613 12.254 11.6362 7.22327 11.6362C2.96525 11.6362 0.741314 12.6615 0.161801 12.9685C0.0594372 13.023 0 13.1205 0 13.2327V26.5974C0 26.9061 0.52998 27.0431 0.790846 26.6238Z"
        fill={fill}
      />
      <path
        d="M29.1027 9.94991C29.1027 10.0589 29.0417 10.1596 28.9476 10.2107C28.3829 10.4997 26.2861 11.4061 21.8283 11.4061C16.2445 11.4061 12.2474 8.7843 7.21667 8.7843C3.22942 8.7843 1.02529 9.67915 0.285629 10.0473C0.151895 10.1134 0 10.0225 0 9.87395V1.65044C0 1.54147 0.057786 1.44407 0.155197 1.39288C0.73471 1.07755 2.95865 0.0605469 7.21667 0.0605469C12.2457 0.0605469 16.2445 2.6823 21.8283 2.6823C26.007 2.6823 28.1138 1.88488 28.8204 1.54973C28.9508 1.48864 29.1027 1.58275 29.1027 1.72638V9.94991Z"
        fill={fill}
      />
    </svg>
  );
};

export default CommunityLogo;
