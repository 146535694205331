import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

function useSearchParams() {
  const { search } = useLocation();
  const history = useHistory();
  return {
    searchParams: useMemo(
      () => new URLSearchParams(search),
      [search],
    ) as URLSearchParams,
    history,
  };
}

export { useSearchParams };
