import { pluralize } from "Utils/pluralize";
import { Checkbox } from "melodies-source/Selectable";
import { SvgDelete } from "melodies-source/Svgs/Delete";
import { SvgDragLarge } from "melodies-source/Svgs/DragLarge";
import { Body2, H4 } from "melodies-source/Text";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { Action } from "./components";
import { Album, Song } from "./types";
import Skeleton from "react-loading-skeleton";
import albumBg from "assets/images/default-spotify-album-bg.png";
import { ReactComponent as SvgNote } from "assets/svg/music-note.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const Handle = styled.div`
  padding-left: 10px;
`;

const Cover = styled.img`
  width: 68px;
  height: 68px;
  object-fit: cover;
  user-select: none;
  margin-left: 12px;
`;

const DisabledCover = styled.div`
  width: 68px;
  height: 68px;
  background: #d5dadd;
  margin-left: 12px;
`;

const Main = styled.div`
  flex: 1;
  padding: 0 20px;
`;

interface SortableRowProps {
  index: number;
  row: Album | Song;
  editable?: boolean;
  draggable?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const SortableRow = ({
  index,
  row,
  editable = true,
  draggable = true,
  onVisibleChange,
  onEdit,
  onDelete,
}: SortableRowProps) => {
  const color = row.visible ? undefined : "#999";
  const CoverImage = ({ visible, src }: { visible: boolean; src?: string }) => {
    if (visible && src) {
      return <Cover src={src} />;
    }
    if (visible) {
      return <DefaultAlbum />;
    }
    return <DisabledCover />;
  };

  return (
    <Draggable
      isDragDisabled={!draggable}
      draggableId={index.toString()}
      index={index}
    >
      {(provided) => (
        <Container ref={provided.innerRef} {...provided.draggableProps}>
          <Checkbox
            value={row.visible}
            onChange={(v) => onVisibleChange?.(v)}
          />
          {draggable && (
            <Handle {...provided.dragHandleProps}>
              <SvgDragLarge />
            </Handle>
          )}
          <CoverImage visible={row.visible} src={row.src} />
          <Main>
            <H4 style={{ color }}>{row.name}</H4>
            <Body2 style={{ color }}>{row.artist}</Body2>
            <Body2 style={{ color }}>
              {"songs" in row
                ? pluralize(row.songs.filter((s) => s.visible).length, "Track")
                : row.album}
            </Body2>
          </Main>
          {editable && (
            <Action
              type="button"
              style={{ color }}
              disabled={!row.visible}
              onClick={onEdit}
            >
              Edit
            </Action>
          )}
          {row.deletable && (
            <Action
              type="button"
              style={{ color, marginLeft: 20 }}
              onClick={onDelete}
            >
              <SvgDelete />
            </Action>
          )}
        </Container>
      )}
    </Draggable>
  );
};

export const SortableRowLoader = () => {
  return (
    <Container>
      <Skeleton
        width={14}
        height={14}
        style={{ marginLeft: 1, marginRight: 16 }}
      />
      <SvgDragLarge style={{ marginRight: 9 }} />
      <Skeleton width={68} height={68} />
      <Main>
        <H4>
          <Skeleton width={100} />
        </H4>
        <Body2>
          <Skeleton width={75} />
        </Body2>
        <Body2>
          <Skeleton width={20} inline style={{ marginRight: 6 }} />
          <Skeleton width={60} inline />
        </Body2>
      </Main>
      <Skeleton width={33} style={{ left: -28 }} />
      <Skeleton width={11} height={13} style={{ left: -5 }} />
    </Container>
  );
};

export const DefaultAlbum = ({ className }: { className?: string }) => {
  return (
    <div style={{ position: "relative", marginLeft: 12 }} className={className}>
      <img src={albumBg} alt="default album cover" />
      <StyledMusicNote />
    </div>
  );
};

const StyledMusicNote = styled(SvgNote)`
  position: absolute;
  top: 18px;
  left: 17px;
  & > path {
    fill: white;
  }
`;
