import { yupEmail } from "Utils/yupStrictPassword";
import { Form, Formik } from "formik";
import { useEmailLogin, useHistoryPush } from "hooks";
import { Button } from "melodies-source/Button";
import { SvgMail } from "melodies-source/Svgs/Mail";
import styled from "styled-components";
import * as yup from "yup";
import { Description, Link } from "../Layout";
import { SESSION_PATHS } from "../paths";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { TextInput } from "melodies-source/TextInput";
import Logo from "svg/Logo";

const Container = styled.div`
  padding: 40px 48px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 32px 20px;
  }
`;

const Header = styled.div`
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 32px;
  }
`;

interface Values {
  email: string;
}

const validationsSchema = yup.object().shape({
  email: yupEmail,
});

export const LoginWithSSO = () => {
  const historyPush = useHistoryPush();
  const { login, ui, isSendingEmail } = useEmailLogin();
  const handleSubmit = (values: Values) => login(values.email);
  const { customApp } = useCustomAppContext();

  return (
    <Container>
      <Header
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <StyledLogo />
        <Description style={{ textAlign: "center" }}>
          Enter the email address associated with your{" "}
          {customApp?.company.name || "SET"} account and you’ll receive a
          one-time login link.
        </Description>
      </Header>
      <Formik<Values>
        initialValues={{ email: "" }}
        validationSchema={validationsSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange }) => {
          return (
            <Form style={{ width: "100%" }}>
              <TextInput
                label="Email Address"
                leftIcon={<SvgMail />}
                name="email"
                placeholder="Email..."
                value={values.email}
                onChange={handleChange("email")}
                hasError={!!errors.email}
                helperText={errors.email}
              />
              {ui}
              <Button
                type="submit"
                style={{ width: "100%", marginTop: 30, marginBottom: 10 }}
                disabled={isSendingEmail}
                loading={isSendingEmail}
              >
                Log In
              </Button>
            </Form>
          );
        }}
      </Formik>
      <div
        style={{
          marginTop: 24,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div>
          <Link onClick={() => historyPush(SESSION_PATHS.LOGIN_WITH_PASSWORD)}>
            Log In with Password
          </Link>
        </div>
      </div>
    </Container>
  );
};

const StyledLogo = styled(Logo)`
  height: 65px;
  margin-bottom: 15px;
`;
