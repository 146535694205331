import { useArtistContext } from "Components";
import { ReactElement } from "react";
export const withAdminRequired = (WrappedComponent) => {
  return (props): ReactElement => {
    const { isAdmin } = useArtistContext();
    if (!isAdmin) {
      return null;
    }
    return <WrappedComponent {...props} />;
  };
};
