import React, { useState } from "react";
import * as WordCloudChart from "../WordCloudChart";
import styled from "styled-components";
import { Card } from "Components/Card";
import { H2, H3 } from "melodies-source/Text";
import * as HorizontalBarChart from "../HorizontalBarChart";
import * as DonutChart from "Components/DonutChart";
import * as InfoBars from "Components/InfoBars";
import * as RatingChart from "../RatingChart";
import { Divider } from "Components/Divider";
import { FlexColumn } from "Components/Flex";
import { ReactComponent as OpenIcon } from "assets/svg/open.svg";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { Spinner } from "melodies-source/Spinner";
import * as DivergingBarChart from "Components/DivergingChart";

interface WordCloud {
  type: "word-cloud";
  data: WordCloudChart.Props["data"];
}

interface HorizontalBar {
  type: "horizontal-bar";
  data: HorizontalBarChart.Props["data"];
  total?: number;
}

interface Donut {
  type: "donut";
  data: DonutChart.Props["data"];
}

interface InfoBar {
  type: "info-bar";
  data: InfoBars.Props["data"];
  total?: number;
}

interface Rating {
  type: "rating";
  data: RatingChart.Props["data"];
  total?: number;
}

interface DivergingBar {
  type: "diverging-bar";
  data: DivergingBarChart.Props["data"];
}

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  [key: string]: any;
}

interface Modal {
  buttonText: string;
  component: React.FC<ModalProps>;
  props: Record<string, any>;
}

export interface Props {
  title: string;
  chart: WordCloud | HorizontalBar | Donut | InfoBar | Rating | DivergingBar;
  loading?: boolean;
  modal?: Modal;
}

export const ChartCard: React.FC<Props> = ({
  title,
  chart,
  loading = false,
  modal,
}) => {
  const isMobile = useMobileMediaQuery();
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModalOpen = () => setModalOpen((open) => !open);

  let ChartComponent = null;
  let props = {};
  switch (chart.type) {
    case "word-cloud":
      ChartComponent = WordCloudChart.Component;
      break;
    case "horizontal-bar":
      ChartComponent = HorizontalBarChart.Component;
      if (chart.total !== undefined) {
        props = { total: chart.total };
      }
      break;
    case "donut":
      ChartComponent = DonutChart.Component;
      break;
    case "info-bar":
      ChartComponent = InfoBars.Component;
      props = { mode: "percentage", twoCols: true, twoRows: true };
      break;
    case "rating":
      ChartComponent = RatingChart.Component;
      break;
    case "diverging-bar":
      ChartComponent = DivergingBarChart.Component;
      break;
  }

  const Heading = isMobile ? H3 : H2;

  const Modal = modal?.component;

  return (
    <StyledCard isElevated>
      <CardContent>
        <Heading>{title}</Heading>
        <ChartContainer>
          {loading ? (
            <Spinner />
          ) : (
            <ChartComponent data={chart.data} {...props} />
          )}
        </ChartContainer>
      </CardContent>
      {modal && (
        <>
          <FlexColumn xCenter yCenter>
            <Divider />
            <SeeDetailsContainer onClick={toggleModalOpen}>
              <span>{modal.buttonText}</span>
              <OpenIcon />
            </SeeDetailsContainer>
            <Modal
              key={`chart-modal-${modalOpen}`}
              open={modalOpen}
              onClose={toggleModalOpen}
              {...modal.props}
            />
          </FlexColumn>
        </>
      )}
    </StyledCard>
  );
};

const SeeDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 3px 0 18px;
  span {
    font-size: 16px;
    color: inherit;
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;
    & * {
      color: var(--main-color);
    }
  }
`;

const ChartContainer = styled.div`
  margin: 40px 0 10px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    margin: 20px 0 10px;
  }
`;

const CardContent = styled.div`
  padding: 35px 30px;

  ${({ theme }) => theme.media.tablet} {
    padding: 25px 30px;
  }

  ${({ theme }) => theme.media.mobile} {
    padding: 20px;
  }
`;

const StyledCard = styled(Card)`
  padding: 0;
  flex: 1;

  ${H2}, ${H3} {
    max-width: 700px;
    margin: auto;
    text-align: center;
  }
`;
