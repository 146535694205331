import { ReactComponent as IconDreamSetlist } from "assets/svg/playlistsInfluencers.svg";
import { SurveyTemplate } from "../../types";
import { getContestDefaults } from "../helpers";

export const playlistsInfluencers: SurveyTemplate = {
  icon: <IconDreamSetlist />,
  title: "Playlists & Influencers",
  description:
    "Discover which playlists and influencers you should be reaching out to.",
  templateId: "playlistsInfluencers",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: "Playlists & Influencers",
        },
      },
      ...getContestDefaults(formState),
    },
    surveyQuestions: [
      {
        type: "TextQuestion",
        id: "question_1",
        multiline: true,
      },
    ],
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes" ? "pp-marketing-2d732a" : "pp-marketing-8412d1",
    fields: [
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
