import { Fan } from "@musicaudienceexchange/ap-interface/dist/esm/firestore";
import { Button } from "melodies-source/Button";
import { useArtistContext } from "Components";
import { DateTime } from "luxon";
import { useState } from "react";
import { formatPhoneNumber } from "Utils";
import { downloadCSV } from "Utils/csv";
import { useFanTableContext } from "./";
import { useTypesenseProxyContext } from "contexts/TypesenseProxyContext";
import { SvgDownload } from "melodies-source/Svgs/Download";

export const Download = () => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  const { proxySearch } = useTypesenseProxyContext();
  const { searchParameters, found } = useFanTableContext();
  const { name: artistName } = useArtistContext();

  const onClickDownload = async () => {
    setDownloadLoading(true);

    let pagecount = found / 250;
    let requests = [];
    for (let page = 0; page < pagecount; page++) {
      let req = await new Promise<any[]>(async (resolve, reject) => {
        proxySearch('contacts', { ...searchParameters, page: page + 1, per_page: 250 })
        .then((res) => {
          let docs = res.hits.map(({ document }) => document);
          resolve(docs);
        });
      });
      requests.push(req);
    }

    let csvData = [];

    try {
      
      for (const req of requests) {

        const results = await req;
    
        //  const results = await Promise.all(requests);

        csvData = csvData.concat(results
        //.flat()
        .map((doc: Omit<Fan, "createdAt"> & { createdAt: number }) => {
          const {
            name,
            location,
            age,
            gender,
            createdAt,
            phone,
            email,
            city,
            state,
            zip,
            country,
          } = doc;

          return [
            name || "",
            location || "",
            age || "",
            gender || "",
            (
              DateTime.fromMillis(createdAt).toLocaleString(
                DateTime.DATETIME_SHORT,
              ) || ""
            ).replace(",", ""),
            formatPhoneNumber(phone) || "",
            email || "",
            city || "",
            state || "",
            zip || "",
            (country || "").toUpperCase(),
          ];
        }))
      }

      downloadCSV(
        FANS_HEADERS,
        csvData as string[][],
        `${artistName}-${DateTime.now().toLocaleString(
          DateTime.DATE_SHORT,
        )}-fans.csv`,
      );
    } catch (error) {
      console.error(error);
    }

    setDownloadLoading(false);
  };

  return (
    <Button
      onClick={onClickDownload}
      disabled={downloadLoading}
      style={{ width: "180px", minWidth: "180px" }}
    >
      {downloadLoading ? (
        "Loading"
      ) : (
        <>
          <SvgDownload style={{ marginRight: 10, marginLeft: "-10px" }} />{" "}
          Download
        </>
      )}
    </Button>
  );
};

const FANS_HEADERS = [
  "Name",
  "Location",
  "Age",
  "Gender",
  "Added",
  "Phone",
  "Email",
  "City",
  "State",
  "Zip",
  "Country",
];
