import { Spinner } from "melodies-source/Spinner";
import {
  GoogleMap as GoogleMapBase,
  useLoadScript,
  Circle,
  LoadScriptProps,
} from "@react-google-maps/api";

interface CoordProps {
  lat: number;
  lng: number;
}

interface MapPoint {
  center: CoordProps;
  radius: number;
}

interface MapProps {
  center: CoordProps;
  points?: MapPoint[];
}

const libraries = ["places"] as LoadScriptProps["libraries"];

export const GoogleMap = ({ center, points }: MapProps) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    libraries: libraries,
  });

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  if (!isLoaded) {
    return <Spinner />;
  }

  const checkedPoints = points?.filter(
    ({ center }) =>
      typeof center.lat === "number" && typeof center.lng === "number",
  );

  const circles = checkedPoints?.map((point, index) => {
    const options = {
      ...point,
      options: { strokeWeight: 0, fillColor: "#1B0076", fillOpacity: 0.4 },
    };
    return <Circle {...options} key={index} />;
  });

  return (
    <GoogleMapBase
      zoom={9}
      center={center}
      mapContainerStyle={mapContainerStyle}
      options={{
        disableDoubleClickZoom: true,
        disableDefaultUI: true,
        scrollwheel: false,
        keyboardShortcuts: false,
        gestureHandling: "none",
        mapId: "607d9587a569e5a0",
      }}
    >
      {circles}
    </GoogleMapBase>
  );
};
