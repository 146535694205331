import { SurveyTemplate } from "Routes/SetFan/dashboard/Components/Templates/types";
import { ReactComponent as IconMegaphone } from "assets/svg/megaphone.svg";
import { getContestDefaults } from "../helpers";

export const tourReminder: SurveyTemplate<{ artist?: string }> = {
  icon: <IconMegaphone />,
  title: "Tour Announcement Sign Up",
  description:
    "Invite fans to the be among to the first people to hear about new tour dates.",
  templateId: "tourReminder",
  template: ({ artist }, formState) => ({
    fields: {
      description: {
        content: {
          en: "Tour Announcement Signup",
        },
      },
      header: {
        content: {
          en: "Be The First to Hear",
        },
      },
      body: {
        content: {
          en: `If you want to be among the first to find out about upcoming ${artist} tour dates, sign up here, and we'll be sure to let you know when you can get your tickets.`,
        },
      },
      numberOfCategories: {
        content: "1",
      },
      displayedCategories: {
        content: [
          "demographics",
          "fooddrink",
          "alcohol",
          "clothing",
          "vehicle",
          "entertainment",
        ],
      },
      thankYouHeader: {
        content: {
          en: "Thanks!",
        },
      },
      thankYouBody: {
        content: {
          en: "We'll be announcing new tour dates soon!",
        },
      },
      ...getContestDefaults(formState),
    },
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-tour---tou-b54164"
        : "pp-tour---tou-50c563",
    fields: [
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
