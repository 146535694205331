import React from "react";
import styled from "styled-components";
import { Bar } from "./Bar";
import { NoData } from "Components/NoData";

export interface Item {
  label: string;
  value: number;
  actual?: number;
}

export interface Props {
  data: Item[];
  total?: number;
  loading?: boolean;
}

const HorizontalBarChart: React.FC<Props> = ({ data, total: propsTotal }) => {
  const total =
    propsTotal ?? data.reduce((total, item) => total + item.value, 0);

  return (
    <Container>
      {data.length === 0 ? (
        <NoData />
      ) : (
        data.map((item) => <Bar key={item.label} total={total} item={item} />)
      )}
    </Container>
  );
};

export default HorizontalBarChart;

export const Component = HorizontalBarChart;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
