const SignOut = ({ fillColor }: { fillColor?: string }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.41797 1L8.35645 1.02051L3.10645 2.25098L2.59375 2.37402V15.6631L3.12695 15.7451L8.37695 16.7295L8.43848 16.75H9.8125V1H8.41797ZM11.125 2.3125V3.625H13.0938V14.125H11.125V15.4375H14.4062V2.3125H11.125ZM8.5 2.33301V15.4375L3.90625 14.5762V3.39941L8.5 2.33301ZM7.41309 8.21875C7.17212 8.21875 6.96191 8.51355 6.96191 8.875C6.96191 9.23645 7.17212 9.53125 7.41309 9.53125C7.65405 9.53125 7.84375 9.23645 7.84375 8.875C7.84375 8.51355 7.65405 8.21875 7.41309 8.21875Z"
        fill={fillColor ?? "var(--main-color)"}
      />
    </svg>
  );
};

export default SignOut;
