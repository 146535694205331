import React, { useEffect } from "react";
import styled from "styled-components";
import { TopPrograms } from "./TopPrograms";
import { UpcomingEvents } from "./UpcomingEvents";
import { ReactComponent as UsersIcon } from "assets/svg/users.svg";
import { ReactComponent as PlusIcon } from "assets/svg/plus.svg";
import { Stats } from "./Stats";
import { Layout } from "../../Components/Layout";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { DocumentReference, doc, getFirestore } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { GtbRegion } from "@max/common/src/thirdparty/gtb";
import { Spinner } from "melodies-source/Spinner";
import { Programs } from "../Programs";
import { useUser } from "auth";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { GtbCustomApp, GtbScProps } from "../../types";

interface Props {
  withBack?: boolean;
}

export const Region: React.FC<Props> = ({ withBack }) => {
  const { regionId } = useParams<{ regionId: string }>();
  const { customApp } = useCustomAppContext<GtbCustomApp>();
  const [region, regionLoading] = useDocumentData<GtbRegion>(
    doc(
      getFirestore(),
      `${customApp?.regionsCollection}/${regionId}`,
    ) as DocumentReference<GtbRegion>,
  );
  const { user, claims } = useUser();
  const history = useHistory();

  if (
    region &&
    user &&
    !claims.admin &&
    !Object.keys(region.members || {}).includes(user.uid)
  ) {
    history.push("/");
  }

  const contactsTotal =
    region &&
    Object.values(region.programs || {}).reduce(
      (total, program) => total + program.contactsTotal,
      0,
    );

  const contactsLast30Days =
    region &&
    Object.values(region.programs || {}).reduce(
      (total, program) => total + program.contacts30day,
      0,
    );

  useEffect(() => {
    localStorage.setItem("gtb_region_id", regionId);
  }, []);

  return (
    <Layout
      header={`${region?.name?.toUpperCase() || ""}`}
      {...(withBack && { back: { path: "/", text: "Back to Areas" } })}
    >
      {regionLoading || !user ? (
        <Spinner />
      ) : !region ? (
        <Redirect to="/" />
      ) : (
        <>
          <Cards>
            <StatsCards>
              <ContactsTotal
                icon={<UsersIcon />}
                total={contactsTotal}
                title="Total Contacts"
              />
              <ContactsLast30Days
                icon={<PlusIcon />}
                total={contactsLast30Days}
                title="Added in Last 30 Days"
              />
            </StatsCards>
            <TopPrograms programs={region.programs || {}} />
            <UpcomingEvents programIds={Object.keys(region.programs || {})} />
          </Cards>
          <ProgramsSection>
            <Programs
              {...(region && {
                allowedGroupIds: Object.keys(region.programs || {}),
              })}
            />
          </ProgramsSection>
        </>
      )}
    </Layout>
  );
};

const Cards = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  gap: 30px;
  width: 100%;

  ${({ theme }) => theme.media.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  ${({ theme }) => theme.media.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
  }
`;

const StatsCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.media.tablet} {
    flex-direction: row;
  }
`;

const ContactsTotal = styled(Stats)<GtbScProps>`
  background: ${({ theme }) =>
    theme.custom.pages.region.cards.contactsTotal.background};
  color: ${({ theme }) => theme.custom.pages.region.cards.contactsTotal.color};

  svg {
    height: 40px;
  }
`;

const ContactsLast30Days = styled(Stats)<GtbScProps>`
  background: ${({ theme }) =>
    theme.custom.pages.region.cards.contactsLast30Days.background};
  color: ${({ theme }) =>
    theme.custom.pages.region.cards.contactsLast30Days.color};

  svg {
    height: 40px;
    width: 40px;
  }
`;

const ProgramsSection = styled.div`
  margin-top: 80px;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 60px;
  }
`;
