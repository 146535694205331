import React, { useState } from "react";
import styled from "styled-components";
import { H1, H3, Body1, H4 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { TwoColumns, useArtistContext } from "Components";
import { useDesktopMediaQuery } from "hooks";
import { Layout } from "../../Layout";
import { StepProps } from "../..";
import { setDoc } from "firebase/firestore";
import { ReactComponent as ChatIcon } from "assets/svg/chat.svg";

export const Social: React.FC<StepProps> = ({ handleBack, handleNext }) => {
  const { artistGroupDoc } = useArtistContext();
  const artistGroupData = artistGroupDoc?.data();
  const [instagram, setInstagram] = useState(artistGroupData?.instagram || "");
  const [facebook, setFacebook] = useState(artistGroupData?.facebook || "");
  const [tiktok, setTiktok] = useState(artistGroupData?.tiktok || "");
  const [youtube, setYoutube] = useState(artistGroupData?.youtube || "");
  const [twitter, setTwitter] = useState(artistGroupData?.twitter || "");
  const [loading, setLoading] = useState(false);

  const isDesktop = useDesktopMediaQuery();

  const Heading = isDesktop ? H1 : H3;
  const SubHeading = isDesktop ? H3 : H4;

  const handleNextButtonClick = async () => {
    setLoading(true);

    const data = {
      instagram,
      facebook,
      tiktok,
      youtube,
      twitter,
    };

    await setDoc(artistGroupDoc.ref, data, { merge: true });

    setLoading(false);
    handleNext();
  };

  return (
    <Layout
      step={2}
      buttons={{
        back: { onClick: handleBack },
        next: {
          text: "Your Websites",
          onClick: handleNextButtonClick,
          loading,
        },
      }}
    >
      {isDesktop && <ChatIcon />}
      <Content>
        <Column>
          <HeadingContainer>
            {!isDesktop && <ChatIcon />}
            <Heading>Social Accounts</Heading>
          </HeadingContainer>
          <SubHeading>Help your fans connect with you!</SubHeading>
          <Body1>
            To give you a head start setting up your account, MAX has found the
            following links for your social accounts.
            <br />
            <br />
            Please confirm and enter any missing social links. These are used in
            our system to connect you to your fans, and to intelligently
            populate in live show experiences.
          </Body1>
        </Column>
        <Column>
          <TextInput
            label="Instagram"
            value={instagram}
            onChange={setInstagram}
          />
          <TextInput label="Facebook" value={facebook} onChange={setFacebook} />
          <TextInput label="Tiktok" value={tiktok} onChange={setTiktok} />
          <TextInput label="Youtube" value={youtube} onChange={setYoutube} />
          <TextInput label="Twitter" value={twitter} onChange={setTwitter} />
        </Column>
      </Content>
    </Layout>
  );
};

const Content = styled(TwoColumns)`
  margin: 20px 0 30px;

  ${({ theme }) => theme.media.mobile} {
    margin: 10px 0 0 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  svg {
    width: 30px;
    height: 30px;
  }
`;
