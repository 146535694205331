import React from "react";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { Profile } from "@max/common/src/user";
import styled from "styled-components";
import { Body2, H3, Subtitle1 } from "melodies-source/Text";
import { ReactComponent as EditIcon } from "assets/svg/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/svg/trash.svg";
import { IconButton } from "Components";

interface Props {
  member: QueryDocumentSnapshot<Profile>;
  role: string;
  onEdit: (member: QueryDocumentSnapshot<Profile>) => void;
  onDelete: (member: QueryDocumentSnapshot<Profile>) => void;
}

export const MemberCard: React.FC<Props> = ({
  member,
  role,
  onEdit,
  onDelete,
}) => {
  const { name, verifiedEmail } = member.data();
  const fullName = `${name.firstName} ${name.lastName}`;
  return (
    <Container>
      <Detail>
        <H3>{fullName}</H3>
        <Subtitle1>{role}</Subtitle1>
        <Body2>{verifiedEmail}</Body2>
      </Detail>
      <Actions>
        <IconButton onClick={() => onEdit(member)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => onDelete(member)}>
          <DeleteIcon />
        </IconButton>
      </Actions>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid var(--border-color);
`;

const Detail = styled.div`
  ${H3} {
    font-size: 18px;
  }
  ${Body2} {
    font-size: 14px;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
