import { SvgHappyCloud } from "melodies-source/Svgs/HappyCloud";
import { SvgImage } from "melodies-source/Svgs/Image";
import { SvgSadCloud } from "melodies-source/Svgs/SadCloud";
import { Label } from "melodies-source/Text";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

const CONTENT = {
  inactive: {
    message: "Drop your image here",
    icon: <SvgImage />,
  },
  active: {
    valid: {
      message: "Drop It!",
      icon: <SvgHappyCloud />,
    },
    invalid: {
      message: "Are you sure this is an image?",
      icon: <SvgSadCloud />,
    },
  },
};

const Errors = styled.ul`
  margin-top: 20px;
  list-style: unset;
  padding-left: 20px;

  li {
    color: #ef5350;
    font-size: 14px;
  }
`;

interface ImageSelectProps {
  dropzoneDescription?: string;
  withBackground?: boolean;
  onSelect: (url: string, type: string) => void;
}

export const ImageSelect = ({
  dropzoneDescription = "Supports JPEG and PNG",
  withBackground = true,
  onSelect,
}: ImageSelectProps) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    fileRejections,
  } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: ([file]) => {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => onSelect(reader.result as string, file.type);
      }
    },
  });

  const content = !isDragActive
    ? CONTENT.inactive
    : CONTENT.active[isDragAccept ? "valid" : "invalid"];

  const contentUI = (
    <div
      style={{
        marginTop: 10,
        padding: "40px 20px",
        borderRadius: 6,
        background: withBackground
          ? "var(--content-background-color)"
          : undefined,
      }}
    >
      <div style={{ margin: "-10px auto 0 auto" }}>{content.icon}</div>
      <div style={{ marginTop: 10, fontWeight: "bold" }}>
        <span>{content.message}</span>
        {!isDragActive && (
          <span>
            , or{" "}
            <span
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Browse
            </span>
          </span>
        )}
        <div style={{ margin: "10px auto 0" }}>
          <Label>{dropzoneDescription}</Label>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div style={{ textAlign: "center" }}>{contentUI}</div>
      </div>
      <Errors style={{ marginTop: 20, color: "red" }}>
        {fileRejections.map(({ errors, file }) =>
          errors.map((error) => <li>{error.message}</li>),
        )}
      </Errors>
    </div>
  );
};
