import * as React from "react";
import { SVGProps } from "react";
export const SvgMenuOpenLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9,16 L9,18 L3,18 L3,16 L9,16 Z M15,11 L15,13 L3,13 L3,11 L15,11 Z M12,6 L12,8 L3,8 L3,6 L12,6 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
