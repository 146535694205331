import { FC, PropsWithChildren, useEffect, useLayoutEffect } from "react";
import { useUser } from "auth";
import { useHistory, useLocation } from "react-router-dom";
import { SESSION_PATHS } from "Routes/Session/paths";

export const AuthHandler: FC<PropsWithChildren<{ children: any }>> = ({
  children,
}) => {
  const { isLoggedIn, user, clearSession } = useUser();
  const history = useHistory();
  const location = useLocation();
  const hasLoggedInUser = isLoggedIn && !user.isAnonymous;
  const loading = user === undefined;

  useEffect(() => {
    if (!loading && !hasLoggedInUser) {
      (async () => {
        await clearSession();
        const params = new URLSearchParams(location.search);
        history.replace(
          `${SESSION_PATHS.ROOT}?redirect=${
            params.get("redirect") || location.pathname
          }`,
        );
      })();
    }
  }, [hasLoggedInUser, clearSession, history, location, loading]);

  useLayoutEffect(() => {
    if (!loading) {
      document.getElementById("loader")?.remove();
    }
  }, [loading]);

  return !loading && hasLoggedInUser ? children : null;
};
