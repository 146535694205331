const CheckIcon = () => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2821 0.303855C12.6666 -0.0926305 13.2997 -0.10237 13.6961 0.2821C14.0621 0.636996 14.0986 1.20373 13.7996 1.60067L13.7179 1.69615L4.98921 10.6976L0.271803 5.68537C-0.106714 5.28319 -0.0875356 4.65032 0.314638 4.2718C0.685875 3.9224 1.25369 3.91186 1.63656 4.22863L1.7282 4.31464L5.011 7.802L12.2821 0.303855Z"
        fill="#00BD68"
      />
    </svg>
  );
};

export default CheckIcon;
