import styled from "styled-components";
import { useDesktopMediaQuery } from "hooks";
import IntegrationModalBackground from "./IntegrationModalBackground";
import mailchimpHeader from "../mailchimp/mailchimp-header.png";
import klaviyoHeader from "../klaviyo/klaviyo-header.png";
import communityHeader from "../community/community-button.svg";
import stripeHeader from "../stripe/stripe-header.png";
import layloHeader from "../laylo/laylo-header.svg";
import umgHeader from "../umg/umg.svg";
import { Modal as MelodiesModal } from "melodies-source/Modal";
import XIcon from "./XIcon";

const Modal = ({
  isOpen,
  onClose,
  service,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  service: "mailchimp" | "klaviyo" | "stripe" | "community" | "umg" | "laylo";
  children: JSX.Element | JSX.Element[];
}) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <StyledModal
      isOpen={isOpen}
      variant="large"
      onClose={onClose}
      withCloseIcon={false}
    >
      <BGContainer>
        <IntegrationModalBackground />
      </BGContainer>
      <ModalContent>
        <CloseBG onClick={onClose}>
          <XIcon />
        </CloseBG>
        <ModalHeader>
          <img
            src={
              service === "mailchimp"
                ? mailchimpHeader
                : service === "stripe"
                ? stripeHeader
                : service === "klaviyo"
                ? klaviyoHeader
                : service === "community"
                ? communityHeader
                : service === "umg"
                ? umgHeader
                : service === "laylo"
                ? layloHeader
                : null
            }
            height={isDesktop ? 43 : 31}
            alt={
              service === "mailchimp"
                ? "mailchimp logo"
                : service === "stripe"
                ? "stripe logo"
                : service === "klaviyo"
                ? "klaviyo logo"
                : service === "community"
                ? "community logo"
                : service === "umg"
                ? "umg logo"
                : "logo"
            }
          />
        </ModalHeader>
        <BodyContainer>{children}</BodyContainer>
      </ModalContent>
    </StyledModal>
  );
};

export default Modal;

const CloseBG = styled.div`
  cursor: pointer;
  background-color: rgba(154, 154, 154, 0.42);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
`;

const StyledModal = styled(MelodiesModal)`
  & > div > div {
    background-color: rgb(247, 251, 254);
  }
`;

const BGContainer = styled.div`
  left: 0;
  bottom: 0;
  position: absolute;
`;

// z-index 1 to place over background svg
const BodyContainer = styled.div`
  z-index: 1;
  max-width: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background-color: rgb(247, 251, 254);
`;

const ModalHeader = styled.div`
  margin-bottom: 40px;
  margin-top: 10px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 25px;
  }
`;
