import {
  SongsQuestionBuilderConfigModule,
  SongsQuestionModule
} from "@max/common/dist/setfan";
import { ReactComponent as MusicNoteIcon } from "assets/svg/music-note.svg";
import { useArtistContext } from "Components";
import { Prompt } from "contexts/PromptContext";
import { Form, Formik } from "formik";
import { cloneDeep } from "lodash";
import { Button } from "melodies-source/Button";
import { SvgBack } from "melodies-source/Svgs/Back";
import { Body1, H2 } from "melodies-source/Text";
import { useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useArtistInfo } from "Routes/SetFan/hooks/useArtistInfo";
import { autoId } from "Routes/SetFan/util";
import styled from "styled-components";
import { useBuilderContext } from "../BuilderContext";
import { AlbumsForm } from "./AlbumsForm";
import { Action, SectionFooter } from "./components";
import { HeadlineForm } from "./HeadlineForm";
import { Album, SongsQuestionValues, SONGS_QUESTION_SCHEMA } from "./types";

const Container = styled.div`
  padding: 40px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
`;

const Topline = styled.div`
  margin-bottom: 30px;
`;

const Header = styled.div`
  margin-bottom: 20px;
`;

const Title = styled(H2)`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(p) => p.theme.colors.navy};
  margin-bottom: 4px;

  svg {
    width: 31px;
    height: 31px;
  }
`;

const Main = styled.div`
  display: grid;
  gap: 22px;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  margin-bottom: 30px;
`;

interface SongsQuestionProps {
  backPath: string;
}

function extractAlbumsFromBuilder(
  artistName: string,
  state?: SongsQuestionBuilderConfigModule,
): Album[] {
  if (!state) {
    return undefined;
  }

  return state.options?.map((album) => ({
    id: album.id,
    artist: artistName,
    src: album.src.en,
    name: album.label.en,
    visible: true,
    deletable: !album.id?.startsWith("spotify-"),
    songs: album.options?.map((song) => ({
      id: song.id,
      album: album.label.en,
      artist: artistName,
      src: song.src.en,
      name: song.label.en,
      visible: true,
      deletable: !song.id?.startsWith("spotify-"),
    })),
  }));
}

export const SongsQuestion = ({ backPath }: SongsQuestionProps) => {
  const history = useHistory();
  const { state } = useLocation<SongsQuestionBuilderConfigModule>();
  const { id, name } = useArtistContext();
  const { artistInfo, loading } = useArtistInfo({
    artistGroupId: id,
    artistName: name,
  });
  const { setData, data } = useBuilderContext();

  const params = useParams<{ setlistId?: string; surveyId: string }>();

  // logical order for loading selections
  // 1. we have state in firestore for the question, pull data with setlistId parameter
  // 2. we have state passed from the router on edit
  // 3. we load all available albums for the artist if starting fresh
  const existingState = useMemo(
    () =>
      data.surveyQuestions.find((sq) => sq.id === params.setlistId) ?? state,
    [data.surveyQuestions, state, params.setlistId],
  );

  const existingAlbums = useMemo(
    () =>
      extractAlbumsFromBuilder(
        name,
        existingState as SongsQuestionBuilderConfigModule,
      ),
    [existingState, name],
  );

  // existing setlist question (on edit) takes precedence, otherwise show all artist albums after load
  const initialValues: SongsQuestionValues = useMemo(
    () => ({
      headline: state?.label.en ?? "",
      max: String(state?.requiredMax || "10"),
      albums: existingAlbums ?? artistInfo?.albums ?? [],
    }),
    [artistInfo, existingAlbums, state],
  );

  const goBack = () => {
    history.push(backPath);
  };

  const handleSubmit = (values: SongsQuestionValues) => {
    const songsQuestion = new SongsQuestionModule({
      id: params.setlistId ?? autoId(),
      label: { en: values.headline },
      options: values.albums
        .filter((album) => album.visible)
        .map((album) => ({
          id: album.id,
          label: { en: album.name },
          src: { en: album.src },
          options: album.songs
            .filter((song) => song.visible)
            .map((song) => ({
              id: song.id,
              label: { en: song.name },
              src: { en: song.src },
            })),
        })),
      requiredMax: values.max === "no-limit" ? undefined : parseInt(values.max),
    });
    setData((data) => {
      const clonedSurveyQuestions = cloneDeep(data.surveyQuestions);
      // if an existing question, replace. otherwise, append to end of survey questions
      if (params.setlistId) {
        const setlistIdx = clonedSurveyQuestions.findIndex(
          (sq) => sq.id === params.setlistId,
        );
        if (setlistIdx === -1) {
          clonedSurveyQuestions.push(songsQuestion.toBuilderConfig());
        } else {
          clonedSurveyQuestions.splice(
            setlistIdx,
            1,
            songsQuestion.toBuilderConfig(),
          );
        }
      } else {
        clonedSurveyQuestions.push(songsQuestion.toBuilderConfig());
      }
      return { ...data, surveyQuestions: clonedSurveyQuestions };
    });
    history.push(backPath);
  };

  return (
    <>
      <Container>
        <Formik<SongsQuestionValues>
          enableReinitialize
          initialValues={initialValues}
          validationSchema={SONGS_QUESTION_SCHEMA}
          onSubmit={handleSubmit}
        >
          {({ values }) => {
            return (
              <Form>
                <Prompt
                  // when={JSON.stringify(initialValues) !== JSON.stringify(values)}
                  when={false}
                  message="Cancel editing Dream Setlist section?"
                />
                <Topline>
                  <Action type="button" onClick={goBack}>
                    <SvgBack /> Survey Editor
                  </Action>
                </Topline>
                <Header>
                  <Title>
                    <MusicNoteIcon /> Dream Setlist
                  </Title>
                  <Body1>
                    Ask participants to build a custom playlist from songs in
                    your library
                  </Body1>
                </Header>
                <Main>
                  <HeadlineForm />
                  <AlbumsForm loadingAlbums={!existingAlbums && loading} />
                </Main>
                <SectionFooter>
                  <Button variant="tertiary" type="button" onClick={goBack}>
                    Cancel
                  </Button>
                  <Button type="submit" style={{ width: 140 }}>
                    {params.setlistId ? "Save" : "Add"}
                  </Button>
                </SectionFooter>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
};
