import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DefaultTheme, StyledComponent } from "styled-components";
import {
  query,
  collection,
  where,
  getFirestore,
  getCountFromServer,
} from "firebase/firestore";
import SwitchIcon from "svg/Switch";
import { useUser } from "auth";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { GtbCustomApp } from "../../types";

interface Props {
  Option: StyledComponent<"a", DefaultTheme, {}, never>;
}

export const UserMenuOptions: React.FC<Props> = ({ Option }) => {
  const { user, claims } = useUser();
  const [regionCount, setRegionCount] = useState(0);
  const [programCount, setProgramCount] = useState(0);
  const { customApp } = useCustomAppContext<GtbCustomApp>();
  const location = useLocation();

  const getRegionCount = async () => {
    const count = await getCountFromServer(
      query(
        collection(getFirestore(), customApp.regionsCollection),
        where(`members.${user?.uid}`, "!=", null),
      ),
    );
    setRegionCount(count.data().count);
  };

  const getProgramCount = async () => {
    const count = await getCountFromServer(
      query(
        collection(getFirestore(), "artist_groups"),
        where(`members.${user?.uid}`, "array-contains-any", [
          "artist",
          "manager",
          "admin",
        ]),
      ),
    );
    setProgramCount(count.data().count);
  };

  useEffect(() => {
    getRegionCount();
    getProgramCount();
  }, []);

  const regionId = localStorage.getItem("gtb_region_id");

  return (
    <>
      {(claims.admin || regionCount > 1) && location.pathname !== "/" && (
        <Option as={Link} to="/">
          <SwitchIcon /> Switch Region
        </Option>
      )}
      {programCount > 1 &&
        location.pathname !== "/" &&
        !location.pathname.startsWith("/regions/") && (
          <Option as={Link} to={regionId ? `/regions/${regionId}` : "/"}>
            <SwitchIcon /> Switch Program
          </Option>
        )}
    </>
  );
};
