import { Redirect, Route } from "react-router-dom";
import { Regions } from "./Regions";
import { Region } from "./Region";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { GtbRegion } from "@max/common/src/thirdparty/gtb";
import {
  DocumentReference,
  Query,
  collection,
  doc,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { useUser } from "auth";
import { Programs } from "./Programs";
import { AppLoading } from "melodies-source/AppLoading";
import { TypesenseProxyProvider } from "contexts/TypesenseProxyContext";
import { Profile } from "@max/common/src/user";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { GtbCustomApp } from "../types";

export const Routes: React.FC = () => {
  const { user, claims } = useUser();
  const { customApp } = useCustomAppContext<GtbCustomApp>();
  const [regions, regionsLoading] = useCollection<GtbRegion>(
    query(
      collection(getFirestore(), customApp.regionsCollection),
    ) as Query<GtbRegion>,
  );

  const [regionsAsAdmin, regionsAsAdminLoading] = useCollection<GtbRegion>(
    query(
      collection(getFirestore(), customApp.regionsCollection),
      where(`members.${user.uid}`, "!=", null),
    ) as Query<GtbRegion>,
  );

  const [profile, profileLoading] = useDocumentData(
    doc(getFirestore(), `profiles/${user.uid}`) as DocumentReference<Profile>,
  );

  const isSystemAdmin = claims.admin;
  const regionsWithAccess = isSystemAdmin ? regions : regionsAsAdmin;
  const loading = regionsLoading || regionsAsAdminLoading || profileLoading;

  return loading ? (
    <AppLoading />
  ) : (
    <TypesenseProxyProvider>
      {profile?.shouldOnboard ? (
        <>
          <Redirect to="/signup" />
        </>
      ) : (
        <>
          <Route
            path="/"
            exact
            render={() =>
              isSystemAdmin || regionsAsAdmin?.size > 1 ? (
                <Regions regions={regionsWithAccess} regionsLoading={loading} />
              ) : !isSystemAdmin && regionsAsAdmin?.size === 1 ? (
                <Redirect to={`/regions/${regionsAsAdmin.docs[0].id}`} />
              ) : (
                <Programs />
              )
            }
          />
          <Route
            path={"/regions/:regionId"}
            exact
            render={() => (
              <Region withBack={isSystemAdmin || regionsAsAdmin?.size > 1} />
            )}
          />
        </>
      )}
    </TypesenseProxyProvider>
  );
};
