import { ImageModule } from "@max/common/dist/setfan";
import { TextInput, useArtistContext } from "Components";
import { CenterFlexRow } from "Routes/styled";
import { REQUIRED_MSG } from "Utils/yupStrictPassword";
import { Field, FieldProps, Form, Formik } from "formik";
import { Button } from "melodies-source/Button";
import { ImageUpload } from "melodies-source/ImageUpload";
import { useMemo } from "react";
import * as yup from "yup";
import { QuestionModalProps, QuestionTypeMeta } from "./BaseQuestionModal";
import { PreviewImage } from "./PreviewImage";
import { ReplaceImageButton } from "./ReplaceImageButton";

interface Values {
  src: string;
  label: string;
}

const VALIDATION_SCHEMA = yup.object().shape({
  src: yup.string().required(REQUIRED_MSG),
  label: yup.string(),
});

const AddImageModal = ({
  dispatch,
  existing,
  onClose,
}: Omit<QuestionModalProps<ImageModule>, "state">) => {
  const { artistGroupDoc } = useArtistContext();

  const initialValues: Values = useMemo(
    () => ({
      src: existing?.src.en ?? "",
      label: existing?.label.en ?? "",
    }),
    [existing],
  );

  const onSubmit = ({ src, label }: Values) => {
    const imageModule = new ImageModule({
      id: existing?.id,
      src: { en: src },
      label: { en: label },
      style: { paddingTop: 20, paddingBottom: 20 },
    });
    dispatch({
      type: existing ? "updateModule" : "addModule",
      payload: imageModule,
    });
    onClose();
  };

  return (
    <Formik<Values>
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            {values.src ? (
              <>
                <PreviewImage src={values.src} />
                <ReplaceImageButton onClick={() => setFieldValue("src", "")} />
              </>
            ) : (
              <ImageUpload
                uploadPath={`surveys/${artistGroupDoc.id}/images`}
                uploadWithFileExt={false}
                withAspectRatio={false}
                onComplete={(url) => setFieldValue("src", url)}
              />
            )}
            {values.src && (
              <>
                <div style={{ marginTop: 26, marginBottom: 26 }}>
                  <Field name="label">
                    {({ field, meta }: FieldProps) => (
                      <TextInput
                        label="Caption (Optional)"
                        placeholder="Caption your image"
                        hasError={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error}
                        asString={false}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
                <CenterFlexRow style={{ justifyContent: "flex-end" }}>
                  <Button type="button" variant="tertiary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button type="submit">{existing ? "Update" : "Add"}</Button>
                </CenterFlexRow>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export const image: QuestionTypeMeta = {
  icon: null,
  key: "image",
  header: "Image",
  subHeader: "",
  body: AddImageModal,
  variant: "small",
};
