import { useArtistGroupsByIdsQuery } from "hooks";
import { Button } from "melodies-source/Button";
import { Body1, H4 } from "melodies-source/Text";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ListItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ListImage = styled.img`
  width: 46px;
  height: 46px;
  border-radius: 46px;
`;

const Footer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: flex-end;
`;

interface SharedArtistsListProps {
  ids: string[];
  onCancel?: () => void;
  onConfirm?: () => void;
}

export const SharedArtistsList = ({
  ids,
  onCancel,
  onConfirm,
}: SharedArtistsListProps) => {
  const { artistGroups } = useArtistGroupsByIdsQuery({ ids });

  return (
    <>
      <Container>
        <Body1>
          All data (including contacts) from this event is being shared with the
          following accounts:
        </Body1>
        <List>
          {artistGroups?.map((artistGroup, index) => (
            <ListItem key={index}>
              <ListImage src={artistGroup.assets?.icon?.path} />
              <H4>{artistGroup.name}</H4>
            </ListItem>
          ))}
        </List>
        <Body1>
          If you duplicate this event, any data collected from your new event
          will also be shared with these accounts.
        </Body1>
      </Container>
      <Footer>
        <Button variant="tertiary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Duplicate</Button>
      </Footer>
    </>
  );
};
