const CheckCircleIcon = () => {
  return (
    <svg
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4998 6.2998C45.4174 6.2998 56.6998 17.5822 56.6998 31.4998C56.6998 45.4174 45.4174 56.6998 31.4998 56.6998C17.5822 56.6998 6.2998 45.4174 6.2998 31.4998C6.2998 17.5822 17.5822 6.2998 31.4998 6.2998ZM47.8563 18.6592C46.6978 17.5471 44.9096 17.4962 43.6938 18.482L43.4025 18.7501L26.8189 36.0232L19.6395 28.245L19.3534 27.9713C18.1571 26.9618 16.3682 26.9777 15.1882 28.0669C14.0082 29.1562 13.8495 30.938 14.7602 32.2112L15.0102 32.5181L26.7298 45.2144L47.9472 23.113L48.2031 22.811C49.1385 21.5559 49.0148 19.7713 47.8563 18.6592Z"
        fill="#6979F8"
      />
    </svg>
  );
};

export default CheckCircleIcon;
