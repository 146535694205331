import React from "react";
import styled from "styled-components";
import { Divider } from "Components";
import { Affiliation, AffiliationMap } from "models/artistSignup";
import { ReactComponent as CloseIcon } from "assets/svg/close-filled.svg";
import { Body1, Body2 } from "melodies-source/Text";
import { useCustomAppContext } from "contexts/CustomAppContext";

export interface Teammate {
  email: string;
  isAdmin: boolean;
  role: Affiliation;
}

interface Props {
  teammate: Teammate;
  onRemove: (email: string) => void;
}

export const Teammate: React.FC<Props> = ({ teammate, onRemove }) => {
  const { customApp } = useCustomAppContext();
  return (
    <>
      <Container>
        <Body1>{teammate.email}</Body1>
        <Container>
          <Body2>
            {(customApp?.userRoles || AffiliationMap)[teammate.role]}{" "}
            {teammate.isAdmin && "• Admin"}
          </Body2>
          <CloseIcon onClick={() => onRemove(teammate.email)} />
        </Container>
      </Container>
      <Divider />
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  ${Body1} {
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    cursor: pointer;
    margin-left: 5px;
    flex-shrink: 0;
  }
`;
