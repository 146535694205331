import * as React from "react";
import { SVGProps } from "react";
export const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17,15.9993189 L17,16.9993189 L3,16.9993189 L3,15.9993189 L17,15.9993189 Z M3.74753353,13.7567183 L5.241,15.2513189 L3,15.9993189 L3.74753353,13.7567183 Z M12.717,4.7863189 L14.212,6.2813189 L5.991,14.5033189 L4.496,13.0083189 L12.717,4.7863189 Z M15.064912,3.14644661 L15.8528723,3.93440689 C16.0481344,4.12966903 16.0481344,4.44625152 15.8528723,4.64151367 L14.96,5.5333189 L13.465,4.0383189 L14.3578052,3.14644661 C14.5530674,2.95118446 14.8696499,2.95118446 15.064912,3.14644661 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
