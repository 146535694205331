import { FC } from "react";
import styled from "styled-components";
import { SvgRight } from "melodies-source/Svgs/Right";

export const ActionLink: FC = ({ children, ...props }) => {
  return (
    <ActionLinkContainer {...props}>
      {children}
      {children && (
        <IconContainer>
          <SvgRight width={20} height={20} />
        </IconContainer>
      )}
    </ActionLinkContainer>
  );
};

const IconContainer = styled.div`
  margin-left: 15px;
  margin-top: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionLinkContainer = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  display: flex;
  align-items: center;
  margin-top: 12px;
`;
