import React, { useState } from "react";
import { Card } from "melodies-source/Card";
import styled from "styled-components";
import { Button } from "melodies-source/Button";
import { H2, H3 } from "melodies-source/Text";
import { useHistory } from "react-router-dom";
import MapPinIcon from "svg/MapPinIcon";
import { GtbRegion } from "@max/common/src/thirdparty/gtb";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { ProgramsModal } from "./ProgramsModal";
import { MembersModal } from "./MembersModal";
import { useUser } from "auth";
import { ReactComponent as PinIcon } from "assets/svg/map-pin-outlined.svg";
import { ReactComponent as UserIcon } from "assets/svg/user-outlined-rounded.svg";
import { formatNumber } from "Utils/format";
import { MenuButton } from "Components/MenuButton";
import { ReactComponent as KebabMenuIcon } from "assets/svg/kebab-menu.svg";
import { DeafultQuestionsModal } from "./DefaultQuestionsModal";
import { ReactComponent as QuestionIcon } from "assets/svg/question-mark-outlined-2.svg";
import { useCustomAppContext } from "contexts/CustomAppContext";

interface Props {
  region: QueryDocumentSnapshot<GtbRegion>;
}

export const RegionCard: React.FC<Props> = ({ region }) => {
  const [programsModalIsOpen, setProgramsModalIsOpen] = useState(false);
  const [membersModalIsOpen, setMembersModalIsOpen] = useState(false);
  const [questionsModalIsOpen, setQuestionsModalIsOpen] = useState(false);
  const history = useHistory();
  const { claims } = useUser();
  const { customApp } = useCustomAppContext();

  const isSystemAdmin = claims.admin;

  const { name, programs = {} } = region.data();
  const programsTotal = Object.keys(programs).length;
  const contactsTotal = Object.values(programs).reduce(
    (total, program) => total + program.contactsTotal,
    0,
  );

  const isGtb = customApp.company.name.toLowerCase() === "gtb";

  const menu = {
    position: "right" as const,
    width: "220px",
    items: [
      {
        icon: {
          position: "left" as const,
          element: <PinIcon />,
        },
        label: "Edit Programs",
        onClick: () => setProgramsModalIsOpen(true),
      },
      {
        icon: {
          position: "left" as const,
          element: <UserIcon />,
        },
        label: "Edit Members",
        onClick: () => setMembersModalIsOpen(true),
      },
      ...(isGtb
        ? [
            {
              icon: {
                position: "left" as const,
                element: <QuestionIcon />,
              },
              label: "Edit Default Questions",
              onClick: () => setQuestionsModalIsOpen(true),
            },
          ]
        : []),
    ],
  };

  return (
    <>
      <StyledCard isElevated>
        <MapPinIcon />
        <Info>
          <H2>{name}</H2>
          <H3>
            {formatNumber(programsTotal)}{" "}
            {programsTotal === 1 ? "Program" : "Programs"}
          </H3>
          <H3>
            {formatNumber(contactsTotal)}{" "}
            {contactsTotal === 1 ? "Contact" : "Contacts"}
          </H3>
        </Info>
        <Actions>
          <Button onClick={() => history.push(`/regions/${region.id}`)}>
            View Details
          </Button>
          {isSystemAdmin && (
            <MenuContainer>
              <StyledMenuButton menu={menu} textButton>
                <KebabMenuIcon color="var(--text-color)" />
              </StyledMenuButton>
            </MenuContainer>
          )}
        </Actions>
      </StyledCard>
      <ProgramsModal
        region={region}
        isOpen={programsModalIsOpen}
        onClose={() => setProgramsModalIsOpen(false)}
      />
      <MembersModal
        region={region}
        isOpen={membersModalIsOpen}
        onClose={() => setMembersModalIsOpen(false)}
      />
      {isGtb && (
        <DeafultQuestionsModal
          isOpen={questionsModalIsOpen}
          onClose={() => setQuestionsModalIsOpen(false)}
          regionId={region.id}
        />
      )}
    </>
  );
};

const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    padding-bottom: 25px;
    & > div,
    span {
      text-align: center;
    }
  }
`;

const Info = styled.div`
  flex: 1;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 13px;
`;

const StyledMenuButton = styled(MenuButton)`
  padding: 5px;
`;
