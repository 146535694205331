import { Card } from "melodies-source/Card";
import styled, { CSSProperties } from "styled-components";
import { useDesktopMediaQuery } from "hooks";

const IntegrationCard = ({
  header,
  body,
  footer,
  sx,
}: {
  header: JSX.Element;
  body?: string | JSX.Element;
  footer?: JSX.Element | string;
  sx?: CSSProperties;
}) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <StyledCard style={sx} isElevated>
      <>
        <HeaderContainer>{header}</HeaderContainer>
        {!!body && <BodyContainer>{body}</BodyContainer>}
        {!!footer && (
          <FooterContainer isDesktop={isDesktop}>{footer}</FooterContainer>
        )}
      </>
    </StyledCard>
  );
};

export default IntegrationCard;

const BodyContainer = styled.div`
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  color: var(--secondary-text-color);
  margin-bottom: auto;
  max-width: 330px;
`;

const FooterContainer = styled.footer<{ isDesktop: boolean }>`
  display: flex;
  justify-self: flex-end;
  justify-content: center;
  width: 100%;
  & button {
    max-width: 280px;
  }
  ${({ isDesktop }) =>
    isDesktop && "button > * { font-size: min(1.4vw, 16px); }"}
  img,
  svg {
    height: 20px;
    max-width: 50%;
  }
`;

const HeaderContainer = styled.header`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  img,
  svg {
    height: 36px;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  padding: 20px 36px 29px 36px;
  min-height: 220px;
  width: 350px;

  @media (max-width: 1050px) {
    width: 300px;
  }

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }

  & > * {
    font-family: Poppins;
  }
`;
