import { ConcertData } from "hooks/useUpcomingConcerts";
import { ConcertDetails } from "Routes/SetLive/Duplicate/types";
import { BuilderEventData } from "./hooks/useSetLiveBuilderEvents";

export const getEvent = (
  concert?: ConcertDetails<Date> | ConcertData,
  events?: BuilderEventData[],
) => {
  return concert
    ? events?.find(({ id }) => id === concert?.setliveEventId)
    : undefined;
};
