const BarChart = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3H21C22.1046 3 23 3.89543 23 5V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5C1 3.89543 1.89543 3 3 3ZM3 5V19H21V5H3ZM7 17H9V11H7V17ZM13 17H11V7H13V17ZM15 17H17V10H15V17Z"
        fill="var(--secondary-color)"
      />
    </svg>
  );
};

export default BarChart;
