import React, { useState } from "react";
import { ModalProps } from "Routes/Reporting/Components/ChartCard";
import { AudienceAttribute, ProfileSection } from "models/fanProfile";
import { textWithoutParentheses } from "Utils";
import HorizontalBarChart from "Routes/Reporting/Components/HorizontalBarChart";
import styled from "styled-components";
import { getDivergingChartData, getHorizontalBarChartData } from "../Section";
import { ChartSelect, DataType } from "../Section/ChartSelect";
import { DivergingChart } from "Components/DivergingChart";
import { Modal } from "melodies-source/Modal";

interface Props extends ModalProps {
  question: ProfileSection<AudienceAttribute>;
}

export const QuestionModal: React.FC<Props> = ({ open, onClose, question }) => {
  const [dataType, setDataType] = useState<DataType>("index");
  const hasIndexValues = !!question.data?.[0]?.index;
  const chart =
    dataType === "index" && hasIndexValues ? (
      <DivergingChart data={getDivergingChartData(question.data)} />
    ) : (
      <HorizontalBarChart data={getHorizontalBarChartData(question.data)} />
    );

  return (
    <Modal
      isOpen={open}
      header={textWithoutParentheses(question.question)}
      onClose={onClose}
      variant="large"
    >
      <Container>
        {hasIndexValues && (
          <StyledChartSelect value={dataType} setValue={setDataType} />
        )}
        <ChartContainer>{chart}</ChartContainer>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const ChartContainer = styled.div`
  overflow: auto;
  padding: 5px 0;
`;

const StyledChartSelect = styled(ChartSelect)`
  margin-bottom: 30px;

  ${({ theme }) => theme.media.mobile} {
    margin-bottom: 20px;
  }
`;
