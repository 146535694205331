import { getFunctions, httpsCallable } from "firebase/functions";
import { AlbumT, ArtistInfo } from "../Components/SongsQuestion/types";

interface GetArtistInfoOptions {
  artistGroupId: string;
  artistName: string;
}

export const getArtistInfo = async ({
  artistGroupId,
  artistName,
}: GetArtistInfoOptions): Promise<ArtistInfo> => {
  const response = await httpsCallable<
    { id: string },
    Omit<ArtistInfo, "albums"> & { albums: AlbumT[] }
  >(
    getFunctions(),
    "setfan-artistinfo",
  )({ id: artistGroupId });

  return {
    albums: response.data.albums
      .filter((albumT) => albumT.tracks.length)
      .map((albumT) => ({
        id: `spotify-${albumT.id}`,
        name: albumT.name,
        artist: artistName,
        src: albumT.image,
        visible: true,
        deletable: false,
        songs: albumT.tracks.map((trackT) => ({
          id: `spotify-${trackT.id}`,
          name: trackT.name,
          album: albumT.name,
          artist: artistName,
          src: trackT.image,
          visible: true,
          deletable: false,
        })),
      })),
  };
};
