import { Caption } from "Components";
import { Select, SelectOption } from "Components/Select";
import { REQUIRED_MSG } from "Utils/yupStrictPassword";
import { Form, Formik, useFormikContext } from "formik";
import { Button } from "melodies-source/Button";
import { Radio } from "melodies-source/Selectable";
import { useMemo } from "react";
import * as yup from "yup";
import { SectionFooter, SectionList } from "./components";
import { SongsQuestionValues } from "./types";

const ALBUM_SELECTOR_SCHEMA = yup.object().shape({
  existing: yup.boolean().required(REQUIRED_MSG),
  albumId: yup.string().when("existing", {
    is: true,
    then: (schema) => schema.required(REQUIRED_MSG),
  }),
});

interface AlbumSelectorValues {
  existing: boolean;
  albumId?: string;
}

interface AlbumSelectorProps {
  onCancel?: () => void;
  onSubmit?: (option?: SelectOption) => void;
}

export const AlbumSelector = ({ onCancel, onSubmit }: AlbumSelectorProps) => {
  const { values } = useFormikContext<SongsQuestionValues>();

  const OPTIONS = useMemo<SelectOption[]>(
    () =>
      values.albums.map((a) => ({
        label: a.name,
        value: a.name,
      })),
    [values],
  );

  const handleSubmit = (values: AlbumSelectorValues) => {
    if (values.existing) {
      const album = OPTIONS.find(({ value }) => value === values.albumId);
      onSubmit?.(album);
    } else {
      onSubmit?.();
    }
  };

  return (
    <Formik<AlbumSelectorValues>
      initialValues={{ existing: undefined, albumId: undefined }}
      validationSchema={ALBUM_SELECTOR_SCHEMA}
      onSubmit={handleSubmit}
    >
      {({ values, touched, errors, setFieldValue }) => (
        <Form>
          <SectionList>
            <div>
              <Radio
                label="An existing album in my library"
                value={values.existing != null && values.existing}
                onChange={() => setFieldValue("existing", true)}
              />
              {values.existing && (
                <div style={{ paddingTop: 8, paddingLeft: 30 }}>
                  <Select
                    label="Album Select"
                    options={OPTIONS}
                    onChange={(v) => setFieldValue("albumId", v)}
                    value={values.albumId}
                  />
                  {touched.albumId && errors.albumId && (
                    <Caption error>{errors.albumId}</Caption>
                  )}
                </div>
              )}
              <Radio
                label="A new custom album"
                value={values.existing != null && !values.existing}
                onChange={() => setFieldValue("existing", false)}
                style={{ marginTop: 20 }}
              />
              {touched.existing && errors.existing && (
                <Caption error>{errors.existing}</Caption>
              )}
            </div>
            <SectionFooter>
              <Button variant="tertiary" type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" style={{ width: 140 }}>
                Next
              </Button>
            </SectionFooter>
          </SectionList>
        </Form>
      )}
    </Formik>
  );
};
