import { LayoutProps } from "./types";

export const BoxyLayout = ({
  image,
  backgroundColor,
  buttonColor,
  width,
  height,
  onClick,
}: LayoutProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width ?? 163}
    height={height ?? 352}
    viewBox="0 0 163 352"
    fill="none"
    onClick={onClick}
  >
    <g clipPath="url(#boxy-layout_svg__a)">
      <rect
        width={162.666}
        height={351.608}
        fill={backgroundColor}
        rx={8.342}
      />
      <rect
        width={144.314}
        height={64.232}
        x={9.175}
        y={64.217}
        fill="#FAFBFB"
        stroke="#CDCECE"
        strokeWidth={0.834}
        rx={3.754}
      />
      <rect
        width={144.314}
        height={205.209}
        x={8.759}
        y={136.389}
        fill="#FAFBFB"
        stroke="#CDCECE"
        strokeWidth={0.834}
        rx={3.754}
      />
      <g filter="url(#boxy-layout_svg__b)">
        <rect
          width={119.288}
          height={18.352}
          x={21.689}
          y={309.883}
          fill={buttonColor}
          rx={2.503}
        />
      </g>
      <rect width={120} height={6} x={22} y={150} fill="#D9D9D9" rx={1.5} />
      <rect width={45} height={6} x={22} y={159} fill="#D9D9D9" rx={1.5} />
      <rect
        width={118.871}
        height={17.935}
        x={22.209}
        y={173.209}
        stroke="#CCC"
        strokeWidth={0.417}
        rx={2.294}
      />
      <rect
        width={27}
        height={5}
        x={27.729}
        y={179.832}
        fill="#D9D9D9"
        rx={2.5}
      />
      <rect
        width={68.82}
        height={70.488}
        x={46.714}
        y={9.16}
        fill="url(#boxy-layout_svg__c)"
        rx={4.171}
      />
      <rect width={120} height={6} x={22.24} y={206} fill="#D9D9D9" rx={1.5} />
      <rect width={45} height={6} x={22.24} y={215} fill="#D9D9D9" rx={1.5} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M26.475 237.674c1.75 0 3.17-1.494 3.17-3.337S28.225 231 26.475 231s-3.17 1.494-3.17 3.337 1.419 3.337 3.17 3.337m0-.417c1.531 0 2.773-1.307 2.773-2.92s-1.242-2.919-2.774-2.919c-1.531 0-2.773 1.307-2.773 2.919s1.242 2.92 2.773 2.92M26.57 256.443c1.793 0 3.247-1.493 3.247-3.336s-1.454-3.337-3.247-3.337-3.247 1.494-3.247 3.337 1.454 3.336 3.247 3.336m0-.417c1.57 0 2.841-1.307 2.841-2.919s-1.272-2.92-2.84-2.92c-1.57 0-2.842 1.307-2.842 2.92s1.272 2.919 2.841 2.919"
        clipRule="evenodd"
      />
      <ellipse cx={26.57} cy={253.107} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M26.57 275.212c1.793 0 3.247-1.494 3.247-3.337s-1.454-3.336-3.247-3.336-3.247 1.493-3.247 3.336 1.454 3.337 3.247 3.337m0-.417c1.568 0 2.84-1.307 2.84-2.92s-1.272-2.919-2.84-2.919c-1.57 0-2.842 1.307-2.842 2.919s1.272 2.92 2.841 2.92"
        clipRule="evenodd"
      />
      <ellipse cx={26.57} cy={271.876} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M26.57 293.981c1.793 0 3.247-1.494 3.247-3.337s-1.454-3.337-3.247-3.337-3.247 1.494-3.247 3.337 1.454 3.337 3.247 3.337m0-.417c1.57 0 2.841-1.308 2.841-2.92 0-1.613-1.272-2.92-2.84-2.92-1.57 0-2.842 1.307-2.842 2.92s1.272 2.92 2.841 2.92"
        clipRule="evenodd"
      />
      <ellipse cx={26.57} cy={290.644} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <rect
        width={27}
        height={5}
        x={34.24}
        y={231.364}
        fill="#D9D9D9"
        rx={2.5}
      />
      <rect
        width={27}
        height={5}
        x={34.24}
        y={250.364}
        fill="#D9D9D9"
        rx={2.5}
      />
      <rect
        width={27}
        height={5}
        x={34.24}
        y={269.364}
        fill="#D9D9D9"
        rx={2.5}
      />
      <rect
        width={27}
        height={5}
        x={34.24}
        y={288.364}
        fill="#D9D9D9"
        rx={2.5}
      />
      <rect width={30} height={6} x={68} y={316} fill="#fff" rx={3} />
      <rect width={55} height={7} x={22} y={89} fill="#D9D9D9" rx={1.5} />
      <rect width={42} height={5} x={22} y={110} fill="#D9D9D9" rx={2.5} />
      <rect width={121} height={5} x={22} y={103} fill="#D9D9D9" rx={2.5} />
    </g>
    <rect
      width={161.666}
      height={350.608}
      x={0.5}
      y={0.5}
      stroke="#CDCDCD"
      rx={7.842}
    />
    <defs>
      <clipPath id="boxy-layout_svg__a">
        <rect width={162.666} height={351.608} fill="#fff" rx={8.342} />
      </clipPath>
      <pattern
        id="boxy-layout_svg__c"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use
          xlinkHref="#boxy-layout_svg__d"
          transform="matrix(.00382 0 0 .00373 -.25 0)"
        />
      </pattern>
      <filter
        id="boxy-layout_svg__b"
        width={125.962}
        height={25.026}
        x={18.353}
        y={306.547}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1.668} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2349_173582"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2349_173582"
          result="shape"
        />
      </filter>
      <image href={image} id="boxy-layout_svg__d" width={392} height={268} />
    </defs>
  </svg>
);
