import * as React from "react";
import { SVGProps } from "react";
export const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15,6 L15,16 C15,16.5522847 14.5522847,17 14,17 L6,17 C5.44771525,17 5,16.5522847 5,16 L5,6 L15,6 Z M13,8 L7,8 L7,15 L13,15 L13,8 Z M7,3 L13,3.00416306 L14,4 L16,4 L16,5 L4,5 L4,4 L6,4 L7,3 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
