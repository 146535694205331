import { SurveyTemplate } from "Routes/SetFan/dashboard/Components/Templates/types";
import { ReactComponent as IconMemory } from "assets/svg/memory.svg";
import { getContestDefaults } from "../helpers";

export const showMemory: SurveyTemplate<{ artist?: string }> = {
  icon: <IconMemory />,
  title: "Favorite Show Memory",
  description:
    "Hear about fans' favorite concert experiences and what made those shows memorable.",
  complexity: "low",
  contestSuggestion: "Sweepstakes",
  templateId: "showMemory",
  template: ({ artist }, formState) => ({
    fields: {
      description: {
        content: {
          en: "Favorite Show Memory",
        },
      },
      header: {
        content: {
          en: "Share Your Favorite Concert Moments!",
        },
      },
      body: {
        content: {
          en: `With ${artist} preparing to go on tour, we would love hear about your favorite live music experiences!`,
        },
      },
      numberOfCategories: {
        content: "2",
      },
      displayedCategories: {
        content: [
          "demographics",
          "fooddrink",
          "alcohol",
          "clothing",
          "vehicle",
          "entertainment",
        ],
      },
      thankYouHeader: {
        content: {
          en: "Thanks for Sharing Your Experience!",
        },
      },
      thankYouBody: {
        content: {
          en: `Be sure to make new memories and check out ${artist}'s upcoming show dates!`,
        },
      },
      ...getContestDefaults(formState),
    },
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
    surveyQuestions: [
      {
        type: "SingleSelectQuestion",
        required: true,
        label: {
          en: `How many times have you seen ${artist} in concert?`,
        },
        actionRequired: false,
        options: [
          {
            label: {
              en: "1",
            },
          },
          {
            label: {
              en: "2",
            },
          },
          {
            label: {
              en: "3-5",
            },
          },
          {
            label: {
              en: "6-10",
            },
          },
          {
            label: {
              en: "More than 10",
            },
          },
          {
            label: {
              en: "Never",
            },
          },
        ],
      },
      {
        actionRequired: false,
        type: "TextQuestion",
        noBorder: false,
        multiline: true,
        label: {
          en: `What's your favorite memory from a live show (whether it's a ${artist} concert or another artist's)?`,
        },
        required: true,
        variants: { mobile: "Headline4", desktop: "Headline3" },
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-tour---fav-251bbd"
        : "pp-tour---fav-0f9c2c",
    fields: [
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
