import styled from "styled-components";

export const Filter = styled.div`
  display: flex;
  width: 100%;
  color: #000000;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  padding: 10px 0 20px 0;
  margin-bottom: 20px;
  box-shadow: inset 0 -1px 0 0 var(--border-color);
  & * {
    font-family: Poppins;
  }
`;
