import { ReactComponent as RefreshIcon } from "assets/svg/refresh.svg";
import { ColorPicker } from "Components/ColorPicker";
import { useConfigSlice } from "Routes/SetFan/Components/BuilderContext";
import { AnimatedCaret, FlexColumn, FlexRow } from "Routes/styled";
import { ImageUploadModal } from "melodies-source/ImageUploadModal";
import { Body2, Subtitle1, Subtitle2, Td } from "melodies-source/Text";
import styled from "styled-components";
import { Divider, useArtistContext } from "Components";
import {
  AngularLayout,
  BoxyLayout,
  DefaultLayout,
  LayoutProps,
  WaveLayout,
} from "../Components/Layouts";
import { SvgCheck } from "melodies-source/Svgs/Check";
import { LayoutContent, TextureContent } from "@max/common";
import { FC, useState } from "react";
import LightsSM from "assets/images/textures/LightsSM.jpg";
import WatercolorSM from "assets/images/textures/WatercolorSM.jpg";
import WavesSM from "assets/images/textures/WavesSM.jpg";
import PaperSM from "assets/images/textures/PaperSM.jpg";
import WaterSM from "assets/images/textures/WaterSM.jpg";
import GeometricSM from "assets/images/textures/GeometricSM.jpg";
import DistressedSM from "assets/images/textures/DistressedSM.jpg";
import { StyledCheck } from "../Components/StyledCheck";

const LayoutContainer = styled.div`
  margin-bottom: 50px;
`;

const LayoutList = styled.div`
  display: flex;
  gap: 20px;
`;

const LayoutItem = styled.div<{ $selected?: boolean }>`
  position: relative;
  cursor: pointer;
  flex: 1;

  &::after {
    content: "";
    display: ${(p) => (p.$selected ? "block" : "none")};
    position: absolute;
    inset: 0;
    border-radius: 9px;
    background: rgba(255, 255, 255, 0.69);
  }
`;

const CheckIcon = styled(SvgCheck)`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Textures = styled.div`
  padding: 24px 0 0 0;
`;

const TextureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: auto;
  gap: 20px 38px;
  margin-top: 24px;
`;

const TextureItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TexturePreview = styled.div<{ $selected?: boolean }>`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  background-blend-mode: multiply;
  border-radius: 8px;
  cursor: pointer;
  background-size: 100%;
  background-size: 100% 100%;
  background-position: center center;
  box-shadow: ${(p) => p.$selected && `0 0 0 2px ${p.theme.colors.main}`};

  svg {
    width: 22px;
    height: 22px;
  }
`;

const LAYOUTS: { id: LayoutContent; component: FC<LayoutProps> }[] = [
  {
    id: "default",
    component: DefaultLayout,
  },
  {
    id: "angular",
    component: AngularLayout,
  },
  {
    id: "wave",
    component: WaveLayout,
  },
  {
    id: "boxy",
    component: BoxyLayout,
  },
];

const TEXTURES: {
  id: TextureContent;
  name: string;
  url?: string;
}[] = [
  {
    id: "none",
    name: "None",
  },
  {
    id: "lights",
    url: LightsSM,
    name: "Lights",
  },
  {
    id: "watercolor",
    url: WatercolorSM,
    name: "Watercolor",
  },
  {
    id: "waves",
    url: WavesSM,
    name: "Waves",
  },
  {
    id: "paper",
    url: PaperSM,
    name: "Paper",
  },
  {
    id: "water",
    url: WaterSM,
    name: "Water",
  },
  {
    id: "geometric",
    url: GeometricSM,
    name: "Geometric",
  },
  {
    id: "distressed",
    url: DistressedSM,
    name: "Distressed",
  },
];

export const Appearance = () => {
  const { artistGroupDoc } = useArtistContext();
  const [showTextures, setShowTextures] = useState(false);

  const { fields, setField } = useConfigSlice([
    "image",
    "backgroundColor",
    "buttonColor",
    "layout",
    "texture",
  ]);

  return (
    <>
      <LayoutContainer>
        <Label style={{ marginBottom: 8 }}>Layout</Label>
        <LayoutList>
          {LAYOUTS.map(({ id, component: Component }) => (
            <LayoutItem key={id} $selected={fields.layout.content === id}>
              {fields.layout.content === id && (
                <CheckIcon width={54} height={54} />
              )}
              <Component
                image={fields.image.content.en}
                backgroundColor={fields.backgroundColor.content}
                buttonColor={fields.buttonColor.content}
                width="100%"
                height="100%"
                onClick={() => setField("layout", id)}
              />
            </LayoutItem>
          ))}
        </LayoutList>
      </LayoutContainer>
      <Container>
        <FlexColumn style={{ width: 344 }}>
          <Label style={{ marginBottom: 8 }}>Image</Label>
          {fields.image.content.en && (
            <ImagePreview src={fields.image.content.en} />
          )}
          <ImageUploadModal
            icon={fields.image.content.en && <RefreshIcon />}
            label={fields.image.content.en && "Replace"}
            uploadPath={`surveys/${artistGroupDoc.id}`}
            uploadFileName="image"
            uploadWithFileExt={false}
            withBackdropClose={false}
            onComplete={(url) => setField("image", { en: url })}
          />
        </FlexColumn>
        <FlexColumn>
          <Label>Page Colors</Label>
          <Body2 style={{ color: "#999", marginBottom: 14 }}>
            Colors are generated from your image
          </Body2>
          <FlexRow>
            <ColorPicker
              label="Background"
              value={fields.backgroundColor.content}
              onSelect={(v) => setField("backgroundColor", v)}
              style={{ marginRight: 16 }}
            />
            <ColorPicker
              label="Button"
              value={fields.buttonColor.content}
              onSelect={(v) => setField("buttonColor", v)}
            />
          </FlexRow>
        </FlexColumn>
      </Container>
      <Divider style={{ margin: "24px 0 0 0" }} />
      <Textures>
        <FlexRow
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            gap: 10,
            cursor: "pointer",
          }}
          onClick={() => setShowTextures((p) => !p)}
        >
          <Subtitle1>Background Texture</Subtitle1>
          <AnimatedCaret
            width={16}
            height={16}
            direction={showTextures ? "up" : "down"}
            $withTransition={false}
          />
        </FlexRow>
        {showTextures && (
          <TextureGrid>
            {TEXTURES.map((texture) => (
              <TextureItem key={texture.id}>
                <TexturePreview
                  $selected={fields.texture.content === texture.id}
                  style={{
                    backgroundColor: fields.backgroundColor.content,
                    backgroundImage: `url(${texture.url})`,
                  }}
                  onClick={() => setField("texture", texture.id)}
                >
                  {fields.texture.content === texture.id && <StyledCheck />}
                </TexturePreview>
                <Subtitle2>{texture.name}</Subtitle2>
              </TextureItem>
            ))}
          </TextureGrid>
        )}
      </Textures>
    </>
  );
};

const Label = styled(Td)`
  color: #333;
`;

const Container = styled(FlexRow)`
  & > div {
    flex: 1;
  }
`;

const ImagePreview = styled.img`
  width: 259px;
  height: auto;
  object-fit: contain;
  margin-bottom: 10px;
`;
