import { Body2 } from "melodies-source/Text";
import styled from "styled-components";

export const SurveyInputList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SurveyInputListItem = styled.div`
  display: grid;
  grid-template-columns: min(60%, 400px) 1fr;
  gap: 30px;
`;

export const SurveyInputHelpText = styled(Body2)`
  padding-top: 20px;
  color: #999999;
`;
