import { createContext, useCallback, useContext } from "react";

export const ScrollLockContext = createContext<{
  lockScroll: () => void;
  unlockScroll: () => void;
}>(null);

export const ScrollLockProvider = ({ children }) => {
  const lockScroll = useCallback(() => {
    const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${scrollBarCompensation}px`;
    document.body.dataset.scrollLock = "true";
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.style.overflow = "";
    document.body.style.paddingRight = "0px";
    delete document.body.dataset.scrollLock;
  }, []);

  return (
    <ScrollLockContext.Provider value={{ lockScroll, unlockScroll }}>
      {children}
    </ScrollLockContext.Provider>
  );
};

export const useScrollLockContext = () => useContext(ScrollLockContext);
