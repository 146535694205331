import { useState } from "react";
import styled from "styled-components";
import { TextInput } from "melodies-source/TextInput";
import { Textarea } from "melodies-source/Textarea";
import { RadioGroup } from "Components";
import { Select as SelectBase, SelectProps } from "melodies-source/Select";

interface SimplifiedOptionSelect extends Omit<SelectProps, "options"> {
  options?: string[];
}

const Select = (props: SimplifiedOptionSelect) => {
  return (
    <SelectBase
      {...props}
      options={props.options.map((o) => ({ label: o, value: o })) ?? []}
    />
  );
};

const GetFieldTypes = ["text", "textarea", "radio", "select"] as const;
export type GetFieldType = (typeof GetFieldTypes)[number];

export type GetFieldProps = {
  type: GetFieldType;
};

export const GetField = ({ type }: GetFieldProps) => {
  switch (type) {
    case "radio":
      return RadioGroup;
    case "select":
      return Select;
    case "textarea":
      return Textarea;
    default:
      return TextInput;
  }
};

export interface FormState {
  [key: string]: string;
}

export interface FieldProps extends GetFieldProps {
  name: string;
  label: string;
  defaultValue?: string;
  placeholder?: string;
  options?: string[];
  required?: boolean;
  isVisible?: (formState: FormState) => boolean;
  getValidation?: (formState: FormState) => string | false;
}

export interface FormProps
  extends Omit<React.HTMLAttributes<HTMLFormElement>, "onSubmit"> {
  fields: FieldProps[];
  onSubmit: (v: unknown) => void;
  children?: React.ReactNode;
}

export const Form = ({ children, fields, onSubmit, ...props }: FormProps) => {
  const defaultValues = fields.reduce(
    (acc, curr) => ({ ...acc, [curr.name]: curr.defaultValue }),
    {},
  );
  const [formState, setFormState] = useState<FormState>(defaultValues);
  const [errors, setErrors] = useState<any>({});

  const handleChange = (name: string, value?: string) => {
    if (errors[name]) {
      const err = { ...errors };
      delete err[name];
      setErrors(err);
    }
    setFormState((state) => ({ ...state, [name]: value }));
  };

  const visibleFields = fields.filter(
    (f) => !f.isVisible || f.isVisible(formState),
  );

  const isValid = () => {
    const error = {};
    for (const field of fields) {
      const errorMessage = field.getValidation?.(formState);
      if (errorMessage) {
        error[field.name] = errorMessage;
      }
    }
    return error;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = isValid();
    if (!Object.keys(errors).length) {
      onSubmit(formState);
    } else {
      setErrors(errors);
    }
  };

  return (
    <Container onSubmit={handleSubmit} {...props}>
      {visibleFields.map(({ name, type, ...rest }) => {
        const RenderField = GetField({ type });
        return (
          <RenderField
            {...rest}
            key={name}
            onChange={(v) => handleChange(name, v)}
            value={formState[name]}
            hasError={errors[name]}
            {...(errors[name] && { helperText: errors[name] })}
          />
        );
      })}
      {children}
    </Container>
  );
};

const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
