import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { FullscreenModal } from "../FullscreenModal";
import { ListItem as ListItemBase } from "../ListItem";
import { SelectMenu, SelectMenuDiv } from "../SelectMenu";
import { Button } from "../Button";
import { Spinner as StyledSpinner, Bounce } from "../Spinner";
import { Caption, H2, Body1, Body2, Label } from "../Text";
import { TextInput } from "../TextInput";
import { SvgCloseLarge } from "../Svgs/CloseLarge";
import { SvgSearchAlt } from "../Svgs/SearchAlt";
import { colors } from "../Theme";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { scrollBarDarkCss } from "melodies-source/common/layout";
import { useClickOutside } from "melodies-source/utils/hooks";
import { Link } from "react-router-dom";

const ListItem = styled(ListItemBase)`
  padding: 8px 12px;
  &:hover {
    cursor: pointer;
    background: #4c4c4c8e;
  }
`;

type ContainerProps = {
  isFullscreen?: boolean;
  hasButton?: boolean;
};

const SpotifySpinner = styled(StyledSpinner)`
  & > div > ${Bounce} {
    width: 8px;
    height: 8px;
  }
`;
const Container = styled.div<ContainerProps>(
  ({ isFullscreen, hasButton }) => css`
    ${SelectMenuDiv} {
      padding: 0;
      overflow: initial;
    }
    ${hasButton &&
    css`
      ${SelectMenuDiv} {
        max-height: 295px;
      }
    `}
    ${isFullscreen &&
    css`
      height: 100%;
      display: grid;
      width: 100%;
      grid-template-rows: auto auto 1fr;
    `}
  `,
);

const CloseIcon = styled(SvgCloseLarge)`
  cursor: pointer;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 250px;
  padding: 8px 0;
  background: var(--component-background-color);
  ${scrollBarDarkCss};
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-top: 1px solid #999999;
  button {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    svg {
      margin-right: 6px;
    }
  }
`;

export type Option = {
  label: string;
  value: string;
  avatarSrc?: string;
  icon?: any;
  caption?: string;
  caption2?: string;
  data?: any;
  style?: any;
  defaultOption?: React.ReactNode;
};

export type OptionProps = {
  isSelected?: boolean;
} & Option;
const OptionDefault = ({
  label,
  avatarSrc,
  caption,
  caption2,
  isSelected,
  icon,
  ...props
}: OptionProps) => {
  return (
    <ListItem
      {...props}
      leftIcon={icon}
      isSelected={isSelected}
      // avatar={<img src={avatarSrc} />}
      caption={caption}
      caption2={caption2}
      {...(icon && { style: { paddingLeft: 0 } })}
    >
      {label}
    </ListItem>
  );
};

type BaseProps = {
  label?: string;
  fullscreenConfig?: {
    isOpen: boolean;
    onClose: () => void;
    headerUI?: JSX.Element;
  };
  placeholder?: string;
  customOption?: React.FC<OptionProps> | any;
  defaultOption?: React.ReactNode;
  value?: Option;
  helperText?: string;
  buttonText?: string;
  hasError?: boolean;
  style?: any;
  onClear?: () => void;
  headerUI?: JSX.Element;
  onChange?: (o: Option) => void;
  secondary?: boolean;
  hideDropdown?: boolean;
  setCustom?: VoidFunction;
  noResult?: React.ReactNode;
  spinnerColor?: string;
  clearOnSelect?: boolean;
};
type AutocompleteProps = BaseProps & {
  text: string;
  setText: Function;
  options: Option[];
  buttonText?: string;
  isLoading?: boolean;
  customOption?: any;
};
export const AutocompleteBase = ({
  label,
  fullscreenConfig,
  placeholder = "Select Option",
  value,
  text,
  hasError,
  isLoading,
  customOption: CustomOption,
  setText,
  buttonText,
  hideDropdown = false,
  onChange,
  onClear,
  setCustom,
  options,
  helperText,
  noResult,
  secondary,
  defaultOption,
  spinnerColor,
  clearOnSelect,
  ...props
}: AutocompleteProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const OptionComponent = CustomOption || OptionDefault;
  const isFullscreen = !!fullscreenConfig;
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  useClickOutside(referenceElement, () => setIsMenuOpen(false));

  const onSelect = (o: Option) => {
    if (clearOnSelect) {
      setText("");
    } else {
      setText(o.label);
    }

    setIsMenuOpen(false);
    onChange?.(o);
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
    setText("");
  };

  const optionsUI = isLoading ? (
    <StyledSpinner color={spinnerColor} style={{ margin: "12px 0" }} />
  ) : (
    <>
      {!options?.length ? (
        value || text ? (
          !defaultOption &&
          (noResult || (
            <Label
              style={{
                margin: "12px 16px",
                fontWeight: 400,
                color: "var(--text-color)",
              }}
            >
              No results were found. Please check your spelling or try a new
              search...
            </Label>
          ))
        ) : (
          <Label
            style={{
              margin: "12px 16px",
              fontWeight: 400,
              color: "var(--text-color)",
            }}
          >
            Start typing to begin your search...
          </Label>
        )
      ) : (
        options?.map((o, index) => (
          <OptionComponent
            {...o}
            key={index}
            onClick={() => onSelect(o)}
            data={o}
            isSelected={value?.value === o.value}
          />
        ))
      )}
      {text && defaultOption && (
        <ListItem style={{ background: "none" }}>{defaultOption}</ListItem>
      )}
    </>
  );

  const mainUI = (
    <Container
      isFullscreen={isFullscreen}
      hasButton={!!setCustom}
      {...props}
      ref={setReferenceElement}
    >
      {isFullscreen && (
        <div>
          <H2 style={{ marginBottom: "5px" }}>{label}</H2>
          {fullscreenConfig?.headerUI}
        </div>
      )}
      <div
        style={{
          marginTop: isFullscreen && label ? "4px" : undefined,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          position: "relative",
        }}
      >
        <TextInput
          label={!isFullscreen ? label : ""}
          hasError={hasError}
          secondary={secondary}
          leftIcon={<SvgSearchAlt />}
          rightIcon={
            !!value && !buttonText ? (
              <CloseIcon onClick={handleClear} />
            ) : undefined
          }
          placeholder={placeholder}
          value={text}
          onChange={(v) => setText(v)}
          onFocus={() => setIsMenuOpen(true)}
        />
        {isLoading && hideDropdown && (
          <SpotifySpinner
            style={{
              marginLeft: "auto",
              position: "absolute",
              right: 0,
              bottom: 10,
              left: "100%",
              width: "fit-content",
            }}
          />
        )}
      </div>
      {isFullscreen ? (
        <div style={{ overflowY: "scroll", marginTop: "10px" }}>
          {optionsUI}
        </div>
      ) : (
        <>
          {!hideDropdown && (
            <SelectMenu
              isOpen={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
              onClickOutside={() => {}} //we handle this ourselves
            >
              <OptionsContainer>{optionsUI}</OptionsContainer>
              {setCustom && (
                <ButtonWrap>
                  <Button
                    text
                    onClick={() => {
                      setIsMenuOpen(false);
                      setCustom();
                    }}
                    leftIcon={<SvgAddAlt />}
                  >
                    {buttonText || "Add a custom option"}
                  </Button>
                </ButtonWrap>
              )}
            </SelectMenu>
          )}
          {helperText && (
            <Caption hasError={hasError} style={{ marginTop: "4px" }}>
              {helperText}
            </Caption>
          )}
        </>
      )}
    </Container>
  );

  return (
    <>
      {fullscreenConfig ? (
        <FullscreenModal
          isOpen={fullscreenConfig.isOpen}
          onClose={fullscreenConfig.onClose}
        >
          {mainUI}
        </FullscreenModal>
      ) : (
        mainUI
      )}
    </>
  );
};

export const AutocompleteAsync = ({
  getOptions,
  text,
  setText,
  buttonText,
  hideDropdown,
  ...props
}: BaseProps & {
  getOptions: (text: string) => any;
  // getOptions: (text: string) => Promise<Option[]>;
  text: string;
  setText: Function;
  hideDropdown?: boolean;
  style?: any;
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let cancel = false;
    (async () => {
      setIsLoading(true);
      const _options = await getOptions(text);
      if (!cancel) {
        setOptions(_options);
        setIsLoading(false);
      }
    })();
    return () => {
      cancel = true;
    };
  }, [text]);

  return (
    <AutocompleteBase
      {...{
        isLoading,
        text,
        setText,
        hideDropdown,
        options,
        buttonText,
        ...props,
      }}
    />
  );
};

export const Autocomplete = ({
  options,
  buttonText,
  ...props
}: BaseProps & { options: Option[] }) => {
  const [text, setText] = useState("");

  const filteredOptions = options?.filter((o) =>
    o.label.toLowerCase().includes(text.toLowerCase()),
  );

  return (
    <AutocompleteBase
      {...{ text, setText, buttonText, options: filteredOptions, ...props }}
    />
  );
};
