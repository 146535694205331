import styled from "styled-components";
import { Font16 } from "../../../styled";

export const AppCardButton = ({
  children,
  onClick,
  className,
}: {
  children: string;
  onClick?: () => void;
  className?: string;
}) => {
  return (
    <StyledButton className={className} onClick={onClick}>
      <Font16 fontWeight={600}>{children}</Font16>
    </StyledButton>
  );
};

const StyledButton = styled.div`
  width: 292px;
  height: 42px;
  border: 1px solid #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ theme }) => theme.media.mobile} {
    width: 343px;
    height: 41px;
  }
`;
