import { Body2, H3 } from "melodies-source/Text";
import { PropsWithChildren, ReactElement } from "react";
import styled from "styled-components";

const Container = styled.div`
  border-radius: 12px;
  background: #f2f5f7;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(p) => p.theme.colors.navy};
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Title = styled(H3)`
  color: ${(p) => p.theme.colors.navy};
`;

const HelpText = styled(Body2)<{ error?: boolean }>`
  margin-left: auto;
  color: ${(p) => p.error && p.theme.colors.error};
`;

const Content = styled.div<{ padded?: boolean }>`
  padding-left: ${(p) => p.padded && "calc(30px + 12px)"};
`;

interface QuestionCardProps {
  title: string;
  icon: ReactElement;
  helpText?: string;
  hasError?: boolean;
  hasPadding?: boolean;
}

export const QuestionCard = ({
  title,
  icon,
  helpText,
  hasError,
  hasPadding = true,
  children,
}: PropsWithChildren<QuestionCardProps>) => {
  return (
    <Container>
      <Header>
        <Icon>{icon}</Icon>
        <Title>{title}</Title>
        {helpText && <HelpText error={hasError}>{helpText}</HelpText>}
      </Header>
      <Content padded={hasPadding}>{children}</Content>
    </Container>
  );
};
