import { useArtistContext } from "Components";
import { Datepicker } from "Components/DatePicker";
import { FlexColumn } from "Components/Flex";
import {
  PlacesAutocomplete,
  PlacesAutocompleteResult,
} from "Components/PlacesAutocomplete";
import { collection, doc, getFirestore } from "firebase/firestore";
import { DateTime } from "luxon";
import { Button } from "melodies-source/Button";
import { Label } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { nanoid } from "nanoid/non-secure";
import { ReactElement, useState } from "react";
import styled from "styled-components";
import { DuplicateViews } from ".";
import { ListItem } from "./ListItem";
import { ConcertDetails } from "./types";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 320px minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  column-gap: 30px;
  flex-grow: 1;
  min-height: 0;

  ${(p) => p.theme.mediaQueries.mobile} {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: min-content minmax(0, 1fr);
    row-gap: 20px;
  }
`;

const Footer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: end;
  margin-top: 30px;

  ${(p) => p.theme.mediaQueries.mobile} {
    flex-direction: column-reverse;
  }
`;

const Column = styled(FlexColumn)`
  min-height: 0;
`;

interface CreateShowProps {
  controls: ReactElement;
  onCreate: (v: ConcertDetails<Date>) => void;
  onCancel: VoidFunction;
  view?: DuplicateViews;
}

export const SingleForm = ({
  controls,
  onCreate,
  onCancel,
  view,
}: CreateShowProps) => {
  const [details, setDetails] = useState<ConcertDetails | null>(null);

  const handleVenueSelect = (selected: PlacesAutocompleteResult) => {
    const { result, placeName, timeZone, addressType } = selected;
    const { lat, lng } = result.geometry.location;
    const date =
      details?.date ||
      DateTime.now().plus({ days: 1 }).set({ hour: 19, minute: 30 });
    setDetails({
      id: doc(collection(getFirestore(), "ids")).id,
      address: result.formatted_address,
      addressType,
      coords: [lat(), lng()],
      geocode: JSON.parse(JSON.stringify(result)),
      timeZone,
      venue: addressType === "establishment" ? placeName : "",
      source: "manual",
      locationAddedManually: true,
      date: date.setZone(timeZone),
      deletedAt: null,
    });
  };

  const handleDate = (date: DateTime) => {
    setDetails((d) => ({ ...d, date }));
  };

  const handleCreate = () => {
    const result = {
      ...details,
      date: details?.date?.toJSDate(),
    };
    if (details && details.date.isValid) {
      onCreate(result);
    }
  };

  return (
    <>
      <Grid>
        <Column>{controls}</Column>
        <Column>
          <FlexColumn gap="16px">
            {details?.addressType ? (
              <FlexColumn>
                <Label style={{ marginBottom: 4 }}>Location</Label>
                <ListItem
                  label={
                    details.addressType === "establishment"
                      ? details.venue
                      : details.address
                  }
                  caption={
                    details.addressType === "establishment"
                      ? details.address
                      : undefined
                  }
                  onRemove={() => {
                    if (details.date) {
                      setDetails({
                        date: details.date,
                      } as ConcertDetails<DateTime>);
                    } else {
                      setDetails(null);
                    }
                  }}
                />
              </FlexColumn>
            ) : (
              <PlacesAutocomplete
                onChange={handleVenueSelect}
                label="Location"
                placeholder="Type a venue or address..."
              />
            )}
            <Datepicker
              label="Date and Time"
              onChange={handleDate}
              value={details?.date}
              placeholder="Pick a date..."
              minDate={DateTime.now()}
              timeZone={details?.timeZone}
            />
            {details?.address && details?.addressType === "address" && (
              <TextInput
                label="Venue Name"
                placeholder="Enter venue name..."
                value={details.venue}
                onChange={(v) => setDetails((d) => ({ ...d, venue: v }))}
              />
            )}
          </FlexColumn>
        </Column>
      </Grid>
      <Footer>
        <Button onClick={onCancel} variant="tertiary">
          Back
        </Button>
        <Button
          onClick={handleCreate}
          disabled={!details?.venue || !details?.address || !details?.date}
        >
          {view === "noConcerts" ? "Duplicate" : "Add"}
        </Button>
      </Footer>
    </>
  );
};
