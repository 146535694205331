import * as React from "react";
import { SVGProps } from "react";
export const SvgAccordionClosed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.64644661,3.64644661 C6.82001296,3.47288026 7.08943736,3.45359511 7.2843055,3.58859116 L7.35355339,3.64644661 L13.7071068,10 L7.35355339,16.3535534 C7.15829124,16.5488155 6.84170876,16.5488155 6.64644661,16.3535534 C6.47288026,16.179987 6.45359511,15.9105626 6.58859116,15.7156945 L6.64644661,15.6464466 L12.293,10 L6.64644661,4.35355339 C6.47288026,4.17998704 6.45359511,3.91056264 6.58859116,3.7156945 L6.64644661,3.64644661 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
