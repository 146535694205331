import { useUser } from "auth";
import { useQueryParams } from "hooks";
import { Redirect, Route, Switch } from "react-router-dom";
import { SessionLayout } from "./Layout";
import { LoginWithPassword } from "./LoginWithPassword";
import { LoginWithSSO } from "./LoginWithSSO";
import { SESSION_PATHS } from "./paths";
import { ForgotPassword } from "./ForgotPassword";
import { useEffect, useLayoutEffect } from "react";
import { sessionApi } from "@musicaudienceexchange/firebase-auth-ui";
import { AppLoading } from "melodies-source/AppLoading";

export const SessionRouter = () => {
  const { isLoggedIn } = useUser();
  const { redirect } = useQueryParams(["redirect"]);
  const params = new URLSearchParams();

  if (redirect) {
    params.append("redirect", redirect);
  }

  useEffect(() => {
    if (isLoggedIn) {
      const redirect = params.get("redirect")?.match(/^https?/i)
        ? sessionApi.go(decodeURIComponent(params.get("redirect")))
        : params.get("redirect") ?? "/";

      window.location.href = redirect;
    }
  }, [isLoggedIn, params]);

  useLayoutEffect(() => {
    document.getElementById("loader")?.remove();
  }, []);

  if (isLoggedIn) {
    return null;
  }

  const cssVariablesLoaded = getComputedStyle(
    document.getElementById("root"),
  ).getPropertyValue("--variables-loaded");

  if (!cssVariablesLoaded) {
    return <AppLoading />;
  }

  return (
    <SessionLayout size={476} light={false} header={false}>
      <Switch>
        <Route
          path={SESSION_PATHS.LOGIN_WITH_PASSWORD}
          component={LoginWithPassword}
        />
        <Route path={SESSION_PATHS.LOGIN_WITH_SSO} component={LoginWithSSO} />
        <Route
          path={SESSION_PATHS.FORGOT_PASSWORD}
          component={ForgotPassword}
        />
        <Redirect
          to={{
            pathname: SESSION_PATHS.LOGIN_WITH_PASSWORD,
            search: `?${params}`,
          }}
        />
      </Switch>
    </SessionLayout>
  );
};
