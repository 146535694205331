import * as React from "react";
import { SVGProps } from "react";
export const SvgVideoChat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16,3 C16.5522847,3 17,3.44771525 17,4 L17,13 C17,13.5522847 16.5522847,14 16,14 L15,14 L15,17 L12,14 L4,14 C3.44771525,14 3,13.5522847 3,13 L3,4 C3,3.44771525 3.44771525,3 4,3 L16,3 Z M16,4 L4,4 L4,13 L12.4142136,13 L14,14.585 L14,13 L16,13 L16,4 Z M11,6 C11.5522847,6 12,6.44771525 12,7 L12,7.25 L14,6 L14,11 L12,9.75 L12,10 C12,10.5522847 11.5522847,11 11,11 L7,11 C6.44771525,11 6,10.5522847 6,10 L6,7 C6,6.44771525 6.44771525,6 7,6 L11,6 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
