import { callable } from "firebase-internal";
import { BuilderEventData } from "./useSetLiveBuilderEvents";

export const publishBuilderEvent = async (
  event: BuilderEventData,
  status?: "published" | "deleted",
) => {
  return await callable("setlivev3-event-publish", {
    id: event.id,
    status,
  });
};
