import styled, { css } from "styled-components";
import { NavLink as RouterLink, NavLinkProps } from "react-router-dom";
import { RefAttributes } from "react";

interface NavLinkInterface
  extends NavLinkProps,
    RefAttributes<HTMLAnchorElement> {}

export const NavLink = ({ children, ...props }: NavLinkInterface) => {
  return (
    <Link {...props} activeClassName="active">
      {children}
    </Link>
  );
};

const Link = styled(RouterLink)(
  () => css`
    display: flex;
    align-items: center;
    padding: 0 0 0 30px;
    color: inherit;
    font-weight: 500;
    line-height: 25px;
    height: 44px;
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    text-decoration: none;
    &.active {
      color: #1b0076;
      background-color: #e8e5f1;
      border-radius: 0 24px 24px 0;
      font-weight: 600;
    }
  `,
  ({ theme }) => {
    if (!theme.custom) return null;
    const { background, color } = theme.custom.layout.sideBar.active;
    return css`
      &.active {
        color: ${color};
        background-color: ${background};
      }
    `;
  },
);
