import styled, { css } from "styled-components";

interface Props {
  children: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  hasBorder: boolean;
}
export const SettingsRow = ({
  children,
  title,
  description,
  hasBorder = true,
  ...props
}) => {
  return (
    <Container hasBorder={hasBorder} {...props}>
      <LeftContent>
        <SettingsRowHeader>{title}</SettingsRowHeader>
        <Description>{description}</Description>
      </LeftContent>
      <RightContent>{children}</RightContent>
    </Container>
  );
};

type ContainerProps = Pick<Props, "hasBorder">;

const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 16px 0;
  ${(props) =>
    props.hasBorder &&
    css`
      box-shadow: inset 0 -1px 0 0 var(--border-color);
    `};
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
  }
`;

const LeftContent = styled.div`
  ${({ theme }) => theme.media.desktop} {
    width: 40%;
    padding: 0 120px 0 0;
  }
  padding: 0 0 24px 0;
`;

const RightContent = styled.div`
  flex-grow: 1;
  ${({ theme }) => theme.media.desktop} {
    width: 60%;
  }
`;

export const SettingsRowHeader = styled.h1`
  color: var(--text-color);
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
`;

const Description = styled.p`
  color: var(--secondary-text-color);
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
`;
