import React, { ComponentType, useEffect } from "react";
import { Question } from "models/survey";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { H3, H4 } from "melodies-source/Text";
import styled from "styled-components";
import { Select } from "Components/StyledSelect";

interface SelectOption {
  label: string;
  value: string;
}

interface Props {
  surveyQuestions: Question[];
  selectedQuestion: string;
  setSelectedQuestion: (value: string) => void;
  selectedOption: string;
  setSelectedOption: (value: string) => void;
  Heading?: ComponentType;
  className?: string;
}
export const Filter: React.FC<Props> = ({
  surveyQuestions,
  selectedQuestion,
  setSelectedQuestion,
  selectedOption,
  setSelectedOption,
  Heading,
  className,
}) => {
  const isMobile = useMobileMediaQuery();

  const clearFilters = () => {
    setSelectedOption("all");
    setSelectedQuestion("all");
  };

  const questions: SelectOption[] = [
    {
      label: "All Respondents",
      value: "all",
    },
    ...(surveyQuestions
      ? surveyQuestions
          .filter(
            (question) => question.header && question.type !== "TextQuestion",
          )
          .map((question) => ({
            label: question.header,
            value: question.id,
          }))
      : []),
  ];

  const question =
    selectedQuestion !== "all" &&
    surveyQuestions &&
    surveyQuestions.find((question) => question.id === selectedQuestion);

  const options: SelectOption[] = [
    {
      label: "All Options",
      value: "all",
    },
    ...(question
      ? Object.entries(question.options)
          .filter(
            ([id, option]) =>
              id !== "no-reponse" &&
              (option.label || question.type === "RankedQuestion"),
          )
          .map(([id, option]) => ({
            label: `${option.label ?? id}${
              selectedQuestion === "age" ? " years old" : ""
            } (${option.count})`,
            value: id,
          }))
          .sort((a, b) => a.value.localeCompare(b.value))
      : []),
  ];

  const FilterTitle = Heading ? Heading : isMobile ? H4 : H3;

  useEffect(() => {
    setSelectedOption("all");
  }, [selectedQuestion]);

  return (
    <Container className={className}>
      <FilterTitle>Filter by</FilterTitle>
      <FilterContainer>
        <Select
          options={questions}
          value={selectedQuestion}
          onChange={setSelectedQuestion}
        />
        {selectedQuestion !== "all" && (
          <>
            <Select
              options={options}
              value={selectedOption}
              onChange={setSelectedOption}
            />
            <ClearFilters onClick={clearFilters}>Clear Filters</ClearFilters>
          </>
        )}
      </FilterContainer>
    </Container>
  );
};

const ClearFilters = styled.span`
  color: var(--main-color);
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${({ theme }) => theme.media.mobile}, ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
`;

const Container = styled.div`
  width: 100%;
`;
