import { AppLoading } from "melodies-source/AppLoading";
import React, { Suspense } from "react";
const STSReportContent = React.lazy(() => import("./STSReportContent"));

const STSReport = () => {
  return (
    <Suspense fallback={<AppLoading />}>
      <STSReportContent />
    </Suspense>
  );
};

export default STSReport;
