import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Body1, Body2 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { Button } from "melodies-source/Button";
import { useArtistContext } from "Components";
import { setDoc } from "firebase/firestore";
import { Modal } from "melodies-source/Modal";

interface Link {
  label: string;
  key: string;
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
}
interface Props {
  title: string;
  links: Link[];
}

export const Links: React.FC<Props> = ({ title, links }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { artistGroupDoc, isAdmin } = useArtistContext();
  const formRef = useRef<HTMLFormElement>(null);

  const toggleModal = () => setModalIsOpen((open) => !open);

  const handleSave = async () => {
    setLoading(true);
    const data = {};

    for (const link of links) {
      const value = formRef.current.elements[link.label].value;
      data[link.key] = value;
      link.setUrl(value);
    }

    await setDoc(artistGroupDoc.ref, data, { merge: true });

    setLoading(false);
    toggleModal();
  };

  return (
    <div>
      <TitleContainer>
        <Title>{title}</Title>
        {isAdmin && (
          <Button text onClick={toggleModal}>
            Edit
          </Button>
        )}
      </TitleContainer>
      <LinksContainer>
        {links.map((link) => (
          <div key={link.key}>
            <p>{link.label}</p>
            <Body2>{link.url || "(click “Edit” to add link)"}</Body2>
          </div>
        ))}
      </LinksContainer>
      <Modal isOpen={modalIsOpen} onClose={toggleModal} header={title}>
        <Body1>If you don’t have a link, you can leave the field blank.</Body1>
        <Form ref={formRef}>
          {links.map((link) => (
            <TextInput
              key={link.key}
              label={link.label}
              name={link.label}
              defaultValue={link.url}
              placeholder="Paste link here"
            />
          ))}
        </Form>
        <Buttons>
          <Button variant="tertiary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button onClick={handleSave} loading={loading}>
            Save
          </Button>
        </Buttons>
      </Modal>
    </div>
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  button {
    width: auto;
  }
`;

const Title = styled(Body1)`
  font-weight: 500;
  color: var(--header-text-color);
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${Body2} {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #999999;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;
  }
`;
