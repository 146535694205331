import React, { useState } from "react";
import { DefaultLayout } from "Components";
import DashboardBG from "svg/DashboardHeaderBG";
import { GlobalStyles } from "styled";
import { H1, H2 } from "melodies-source/Text";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { SvgCopy } from "melodies-source/Svgs/Copy";
import styled from "styled-components";
import { Tabs } from "Components/Tabs";
import { Shows } from "./Shows";
import { svg } from "svg";
import { MenuButton } from "Components/MenuButton";
import { ReactComponent as VerticalDividerIcon } from "assets/svg/vertical-divider.svg";
import { ReactComponent as ExpandMoreIcon } from "assets/svg/chevron-bottom.svg";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { useParams } from "react-router-dom";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import { Duplicate } from "./Duplicate";
import {
  SetLiveEventsProvider,
  useSetLiveEventsContext,
} from "contexts/SetLiveEventsContext";
import { useSetLiveEvents } from "hooks";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const SetLive: React.FC = () => {
  return (
    <SetLiveEventsProvider>
      <SetLiveInternal />
    </SetLiveEventsProvider>
  );
};

export const SetLiveInternal: React.FC = () => {
  const isMobile = useMobileMediaQuery();
  const { artistId } = useParams<{ artistId: string }>();
  const [duplicateOpen, setDuplicateOpen] = useState(false);
  const { reloadEvents } = useSetLiveEventsContext();
  const { customApp, language } = useCustomAppContext();

  const createEvent = async () => {
    try {
      const ref = await addDoc(
        collection(getFirestore(), `set_fresh_builder_events`),
        { artistGroupId: artistId },
      );
      const url = new URL(
        `${process.env.REACT_APP_BUILDER_URL?.replace("{id}", ref.id)}theme`,
      );
      url.search = "ref=ap";
      window.location.href = url.toString();
    } catch (err: any) {
      alert("Error creating new event: " + err.message);
    }
  };

  const { totalEvents } = useSetLiveEvents({
    filterBy: "deletedAt:0 && status:=published && version:=2",
    page: 1,
    resultsPerPage: 1,
  });

  const tabs = [
    {
      param: "upcoming-shows",
      label: `Upcoming ${language.event.type.plural}`,
      content: <Shows key="upcoming-shows" />,
      icon: <svg.Microphone />,
    },
    {
      param: "past-shows",
      label: `Past ${language.event.type.plural}`,
      content: <Shows key="past-shows" />,
      icon: <svg.Calendar />,
    },
  ];

  const headerButtonMenu = {
    position: "right" as const,
    width: "200px",
    items: [],
  };

  if (totalEvents > 0) {
    headerButtonMenu.items.push({
      label: `Duplicate ${language.event.type.singular}`,
      icon: {
        position: "left" as const,
        element: <SvgCopy />,
      },
      onClick: () => setDuplicateOpen(true),
    });
  }

  if (!isMobile) {
    headerButtonMenu.items.unshift({
      label: `New ${language.event.type.singular}`,
      icon: {
        position: "left" as const,
        element: <SvgAddAlt />,
      },
      onClick: createEvent,
    });
  }

  const Heading = isMobile ? H2 : H1;
  return (
    <>
      <DefaultLayout>
        <GlobalStyles grayBG />
        <DashboardBG />
        <Container>
          <Header>
            <Heading>{customApp?.company.name || "SET"}.Live</Heading>
            {!!headerButtonMenu.items.length && !customApp && (
              <HeaderButton variant="primary" menu={headerButtonMenu}>
                <SvgAddAlt />
                Add {language.event.type.singularWithArticle}
                <StyledVerticalDividerIcon />
                <StyledExpandMoreIcon />
              </HeaderButton>
            )}
          </Header>
          <Tabs tabs={tabs} basePath={`/${artistId}/set-live`} />
        </Container>
      </DefaultLayout>
      {duplicateOpen && (
        <Duplicate
          onComplete={(v) => {
            setDuplicateOpen(false);
            if (v) reloadEvents();
          }}
        />
      )}
    </>
  );
};

const StyledVerticalDividerIcon = styled(VerticalDividerIcon)`
  margin: 0 10px;
`;
const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  height: 25px;
  width: 25px;
  margin-right: 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;

  h1,
  h2 {
    color: white;
  }

  ${({ theme }) => theme.media.mobile} {
    margin-bottom: 22px;
  }

  & div div p,
  & div div svg {
    color: var(--main-color);
  }
`;

const HeaderButton = styled(MenuButton)`
  width: auto;
  padding: 0 10px;

  svg:first-child {
    margin: 0 5px;
  }
`;

const Container = styled.div`
  position: relative;
  padding: 37px 46px;
  font-family: "Poppins";

  ${({ theme }) => theme.media.mobile} {
    padding: 20px 0;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;
