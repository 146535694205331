import * as React from "react";
import { SVGProps } from "react";
export const SvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16,3 C16.5522847,3 17,3.44771525 17,4 L17,16 C17,16.5522847 16.5522847,17 16,17 L4,17 C3.44771525,17 3,16.5522847 3,16 L3,4 C3,3.44771525 3.44771525,3 4,3 L16,3 Z M16,14 L4,14 L4,16 L16,16 L16,14 Z M16,4 L4,4 L4,13 L16,13 L16,4 Z M12,6 L15,12 L5,12 L8,8 L10,9 L12,6 Z M11.8762022,7.98847235 L10.3423708,10.2892194 L8.29925419,9.26766108 L7,11 L13.381966,11 L11.8762022,7.98847235 Z M6,5 C6.55228475,5 7,5.44771525 7,6 C7,6.55228475 6.55228475,7 6,7 C5.44771525,7 5,6.55228475 5,6 C5,5.44771525 5.44771525,5 6,5 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
