const FanSizeIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="21.7896" cy="22.6841" r="21.7896" fill="#E8E5F1" />
      <circle
        cx="21.7891"
        cy="22.6839"
        r="15.2527"
        fill="var(--main-color)"
        opacity={0.4}
      />
      <circle cx="21.7896" cy="22.684" r="7.62635" fill="var(--main-color)" />
    </svg>
  );
};

export default FanSizeIcon;
