import { FlexColumn, FlexRow } from "Components/Flex";
import { ConcertData } from "hooks/useUpcomingConcerts";
import { Button } from "melodies-source/Button";
import { Body1 } from "melodies-source/Text";
import { ConcertCard } from "./ConcertCard";
import { BuilderEventData } from "./hooks/useSetLiveBuilderEvents";
import { getEvent } from "./utils";

interface ListConcertsProps {
  concerts?: ConcertData[];
  events?: BuilderEventData[];
  onCreate?: () => void;
  onEdit?: (concert: ConcertData) => void;
  onClose?: () => void;
}

export const ListConcerts = ({
  concerts = [],
  events = [],
  onCreate,
  onEdit,
  onClose,
}: ListConcertsProps) => {
  const withSetLive = concerts.filter((concert) => {
    const event = getEvent(concert, events);
    return concert.setliveEventId && !!event?.publishedId;
  });

  return (
    <FlexColumn gap="20px" style={{ minHeight: 0 }}>
      <Body1>
        We're tracking {concerts.length} upcoming shows in our system, including{" "}
        {withSetLive.length} with a SET.Live experience.
      </Body1>
      <FlexColumn flex="1" gap="10px" style={{ overflow: "auto" }}>
        {concerts.map((concert) => (
          <ConcertCard
            key={concert.id}
            concert={concert}
            event={events.find(({ id }) => id === concert.setliveEventId)}
            onEdit={() => onEdit?.(concert)}
          />
        ))}
      </FlexColumn>
      <FlexRow xSpaceBetween>
        <Button variant="tertiary" onClick={onCreate}>
          Add Show
        </Button>
        <Button onClick={onClose}>Close</Button>
      </FlexRow>
    </FlexColumn>
  );
};
