import { Button, DefaultLayout, useArtistContext } from "Components";
import { FlexColumn, FlexRow } from "Components/Flex";
import { H1, H2, H3, H4, LinkText } from "melodies-source/Text";
import { SvgClipboard } from "melodies-source/Svgs/Clipboard";
import { useIsMobile } from "melodies-source/utils";
import { GlobalStyles } from "styled";
import styled from "styled-components";
import DashboardBG from "svg/DashboardHeaderBG";
import { Card } from "melodies-source/Card";
import { TextInput } from "melodies-source/TextInput";
import { SvgSearch } from "melodies-source/Svgs/Search";
import { useState } from "react";
import DropdownMenu from "Components/DropdownMenu";
import { SvgDropdown } from "melodies-source/Svgs/Dropdown";
import { useArtistSurveys } from "hooks/useArtistSurveys";
import { SvgLink } from "melodies-source/Svgs/Link";
import { SvgMoreLarge } from "melodies-source/Svgs/MoreLarge";
import { ReactComponent as SvgCircleChecked } from "assets/svg/circle-checked.svg";
import { ReactComponent as SvgCircleBlock } from "assets/svg/circle-block.svg";
import { ReactComponent as SvgClipboardEdit } from "assets/svg/clipboard-edit.svg";
import { ReactComponent as SvgRefresh } from "assets/svg/refresh.svg";
import { ReactComponent as SvgFileText } from "assets/svg/file-text.svg";
import { SvgIndividual } from "melodies-source/Svgs/Individual";
import { DateTime } from "luxon";
import { SurveyStatus } from "@max/common/dist/setfan";
import { SurveyTypesenseDocument } from "models/survey";
import { SvgGear } from "melodies-source/Svgs/Gear";
import { SvgDelete } from "melodies-source/Svgs/Delete";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router-dom";
import { useScrollLockContext } from "contexts/ScrollLockContext";
import {
  ConfirmFeatureModal,
  GetFeaturedLinkModal,
  GetLinkModal,
} from "./Components/GetLinkModal";
import { ReactComponent as SvgStar } from "./Components/SvgStar.svg";
import { ReactComponent as SvgFeatured } from "./Components/SvgFeatured.svg";
import { getPublishFunction } from "@max/common/dist/functions/setfan/publish";
import { getFunctions } from "firebase/functions";
import { addToast } from "melodies-source/Toast";
import { Link } from "melodies-source/Link";
import {
  query,
  collection,
  getFirestore,
  where,
  updateDoc,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { TemplateProvider, useTemplates } from "./Components/Templates";
import { formatNumber } from "Utils/format";

const publish = getPublishFunction(getFunctions());

const SortByOptions = [
  "Newest",
  "Oldest",
  "Published",
  "Draft",
  "Ended",
] as const;
type SortBy = (typeof SortByOptions)[number];

const SurveyMenuOptions = [
  "fanView",
  "editSurvey",
  "setFeatured",
  "endSurvey",
  "resumeSurvey",
  "deleteSurvey",
];
type SurveyMenuOption = (typeof SurveyMenuOptions)[number];

export const Dashboard = () => {
  return (
    <TemplateProvider>
      <DashboardInternal />
    </TemplateProvider>
  );
};

const DashboardInternal = () => {
  const isMobile = useIsMobile();
  const history = useHistory();

  const { id } = useArtistContext();
  const { setModal } = useTemplates();

  const { lockScroll } = useScrollLockContext();

  const BGHeader = isMobile ? H2 : H1;
  const CardHeader = isMobile ? H3 : H2;

  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<SortBy>(SortByOptions[0]);
  const [surveyUpdating, setSurveyUpdating] = useState("");
  const [featuredLinkOpen, setFeaturedLinkOpen] = useState(false);
  const [confirmFeaturedOpen, setConfirmFeaturedOpen] = useState(null);
  const [subdomains] = useCollection(
    query(
      collection(getFirestore(), `artist_subdomain`),
      where("artistGroupId", "==", id),
    ),
  );

  const featuredDoc = subdomains?.docs?.[0];

  const hasFeaturedSupport = featuredDoc?.exists();

  const featuredId = featuredDoc?.data()?.surveyId;

  const surveyFilterBy = () => {
    switch (sortBy) {
      case "Draft":
        return "status:=[draft,unpublished]";
      case "Ended":
        return "status:=[completed,cancelled]";
      case "Published":
        return "status:=[published,live]";
      // sort by handled below
      case "Newest":
      case "Oldest":
        break;
      default:
        throw new Error(`invalid sortBy selection: ${sortBy}`);
    }
  };

  const filterBy = [
    surveyFilterBy(),
    "surveyType:=1",
    `artistGroupId:${id}`,
    `status:!=deleted`,
  ].filter((v) => !!v);

  const {
    documents: surveys,
    loading: loadingSurveys,
    forceReload,
  } = useArtistSurveys({
    sortBy: sortBy === "Oldest" ? "createdAt:asc" : "createdAt:desc",
    filterBy: filterBy.join(" && "),
    query: search,
    queryBy: "title,status,description",
  });

  const updateStatus = async (
    surveyId: string,
    status: Exclude<SurveyStatus, "unpublished" | "published" | "cancelled">,
  ) => {
    setSurveyUpdating(surveyId);
    try {
      const {
        data: { success },
      } = await publish({ surveyId, status });

      if (!success) {
        throw new Error("publish returned unsuccessfully");
      } else {
        forceReload();
      }
    } catch (err) {
      console.error(err);
      addToast(
        `Unable to ${
          status === "live"
            ? "resume"
            : status === "completed"
            ? "end"
            : "delete"
        } survey. Please try again.`,
        "error",
      );
    } finally {
      setSurveyUpdating("");
    }
  };

  const currentTitle = surveys.find(
    (tSurvey) => tSurvey.surveyId === featuredId,
  )?.title;

  const updateFeatured = () => {
    updateDoc(featuredDoc.ref, {
      surveyId: confirmFeaturedOpen,
    })
      .then(() => {
        addToast(
          `${featuredDoc.id}.set.fan now points to ${currentTitle}`,
          "success",
        );
      })
      .catch(() => {
        addToast(`Updating featured event failed.`, "error");
      });
    setConfirmFeaturedOpen(null);
  };

  const onSurveyMenuSelect = async (
    menuItem: SurveyMenuOption,
    surveyId: string,
  ) => {
    switch (menuItem) {
      case "fanView":
        window.open(`${process.env.REACT_APP_SET_FAN_URL}/${surveyId}`);
        break;
      case "editSurvey":
        history.push(`surveys/${surveyId}`);
        break;
      case "endSurvey":
        await updateStatus(surveyId, "completed");
        break;
      case "resumeSurvey":
        await updateStatus(surveyId, "live");
        break;
      case "deleteSurvey":
        await updateStatus(surveyId, "deleted");
        break;
      case "setFeatured":
        setConfirmFeaturedOpen(surveyId);
        break;
      default:
        throw new Error(`invalid menu selection for survey: ${menuItem}`);
    }
  };

  return (
    <DefaultLayout>
      <GlobalStyles grayBG />
      <DashboardBG />
      <Frame>
        <FlexRow
          style={{ justifyContent: "space-between", alignItems: "baseline" }}
        >
          <BGHeader style={{ color: "white" }}>SET.Fan</BGHeader>
          <Button
            variant="primary"
            leftIcon={<SvgClipboard />}
            onClick={() => {
              lockScroll();
              setModal({ selectCategory: true });
            }}
          >
            Create Survey
          </Button>
        </FlexRow>
        <SurveysCard isElevated>
          <CardHeader style={{ color: "#1B0076", marginBottom: 28 }}>
            Surveys
          </CardHeader>
          <FlexRow
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 31,
            }}
          >
            <TextInput
              leftIcon={<SvgSearch />}
              value={search}
              placeholder="Search for a survey"
              onChange={(text) => setSearch(text)}
              style={{ width: 380 }}
            />
            <div style={{ display: "flex" }}>
              {featuredId && (
                <Link
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                  onClick={() => setFeaturedLinkOpen(true)}
                >
                  <SvgStar style={{ marginRight: "5px" }} />
                  Get Featured Link
                </Link>
              )}
              <DropdownMenu
                labelAs={H4}
                options={SortByOptions.map((opt) => ({
                  label: opt,
                  value: opt,
                }))}
                icon={<SvgDropdown />}
                onClick={(val) => setSortBy(val)}
                value={sortBy}
                forceSide="left"
                styles={{
                  label: [
                    "font-size: 16px",
                    "font-family: Poppins",
                    "line-height: 24px",
                  ],
                }}
              />
            </div>
          </FlexRow>
          <SurveyList>
            {loadingSurveys ? (
              <SurveyCardLoader />
            ) : surveys.length >= 1 ? (
              surveys.map((survey) =>
                surveyUpdating === survey.id ? (
                  <SurveyCardLoader key={survey.id} />
                ) : (
                  <SurveyCard
                    key={survey.id}
                    survey={survey}
                    onSurveyMenuSelect={onSurveyMenuSelect}
                    hasFeaturedSupport={hasFeaturedSupport}
                    featuredId={featuredId}
                    featuredUrl={`https://${featuredDoc?.id}.set.fan`}
                  />
                ),
              )
            ) : (
              <NoSurveyContainer>
                <SvgClipboard />
                <H3>There are no surveys available for this artist.</H3>
              </NoSurveyContainer>
            )}
          </SurveyList>
        </SurveysCard>
      </Frame>
      <GetFeaturedLinkModal
        isOpen={featuredLinkOpen}
        onClose={() => setFeaturedLinkOpen(false)}
        title={currentTitle}
        link={`https://${subdomains?.docs?.[0]?.id}.set.fan`}
      />
      <ConfirmFeatureModal
        isOpen={confirmFeaturedOpen}
        onClose={() => setConfirmFeaturedOpen(false)}
        onConfirm={updateFeatured}
        currentTitle={currentTitle}
        link={`${subdomains?.docs?.[0]?.id}.set.fan`}
      />
    </DefaultLayout>
  );
};

const SurveyCard = ({
  survey,
  onSurveyMenuSelect,
  hasFeaturedSupport,
  featuredId,
  featuredUrl,
}: {
  survey: SurveyTypesenseDocument;
  onSurveyMenuSelect: (val: SurveyMenuOption, surveyId: string) => void;
  hasFeaturedSupport: boolean;
  featuredId: string;
  featuredUrl?: string;
}) => {
  const { id: artistGroupId } = useArtistContext();
  const [getLinkModalOpen, setGetLinkModalOpen] = useState(false);
  const isActive = survey.status === "published" || survey.status === "live";
  const color = isActive ? "#1B0076" : "#999";
  const hasResults = survey.responses > 0;
  const isFeatured = survey.id === featuredId;

  const history = useHistory();

  return (
    <StyledSurveyCard isElevated>
      <SurveyDropdownMenu
        disableAnimation
        options={SurveyMenu(survey, hasFeaturedSupport, featuredId)}
        icon={<SvgMoreLarge />}
        onClick={(selection) => onSurveyMenuSelect(selection, survey.id)}
        forceSide="left"
      />
      <SurveyIconContainer>
        <SvgClipboardEdit />
      </SurveyIconContainer>
      <FlexColumn style={{ marginRight: "auto" }}>
        <H2 style={{ marginRight: 10 }}>
          {survey.description || survey.title}
        </H2>
        <FlexRow style={{ marginBottom: 5 }}>
          <H3>
            {formatNumber(survey.responses)} Response
            {survey.responses === 1 ? "" : "s"} • Last Response:{" "}
            {survey.responses === 0
              ? "Awaiting Responses"
              : DateTime.fromMillis(survey.lastResponse).toRelative()}
          </H3>
        </FlexRow>
        <FlexRow style={{ alignItems: "center" }}>
          <SurveyStatusComp status={survey.status ?? "unpublished"} />
          {isFeatured && <SvgFeatured style={{ marginLeft: "10px" }} />}
        </FlexRow>
      </FlexColumn>
      <FlexColumn style={{ alignItems: "center" }}>
        <Button
          onClick={() => {
            if (isActive || survey.status === "completed") {
              history.push(
                `/${artistGroupId}/reporting/set-fan/surveys/${survey.surveyId}`,
              );
            }
          }}
          variant="primary"
          style={{ marginBottom: 10, width: 156 }}
          disabled={!hasResults}
        >
          {isActive || survey.status === "completed"
            ? "View Results"
            : "Publish"}
        </Button>
        <FlexRow
          style={{
            alignItems: "center",
            cursor: isActive ? "pointer" : "not-allowed",
          }}
        >
          <SvgLink
            style={{
              height: 16,
              width: 16,
              transform: "rotate(-45deg)",
              color,
              marginRight: 2,
            }}
          />
          <LinkText
            as="a"
            style={{
              color,
              textDecoration: "none",
              pointerEvents: !isActive ? "none" : "auto",
            }}
            onClick={() => setGetLinkModalOpen(true)}
          >
            Get Link
          </LinkText>
          <GetLinkModal
            isOpen={getLinkModalOpen}
            onClose={() => setGetLinkModalOpen(false)}
            surveyId={survey.id}
            featuredUrl={isFeatured ? featuredUrl : null}
          />
        </FlexRow>
      </FlexColumn>
    </StyledSurveyCard>
  );
};

const SurveyCardLoader = () => (
  <StyledSurveyCard isElevated>
    <Skeleton circle width={88} height={88} style={{ marginRight: 30 }} />
    <FlexColumn style={{ marginRight: "auto" }}>
      <Skeleton
        width={300}
        count={3}
        wrapper={(props) => (
          <div style={{ lineHeight: "1.5rem" }}>{props.children}</div>
        )}
      />
    </FlexColumn>
    <Skeleton height={78} width={150} />
  </StyledSurveyCard>
);

const NoSurveyContainer = styled(FlexColumn)`
  align-items: center;
  padding: 52px 53px 60px 53px;
  max-width: 372px;
  margin: 80px auto 80px auto;

  svg {
    width: 118px;
    height: 118px;
    color: #e8e5f1;
    margin-bottom: 16px;
  }

  ${H3} {
    color: #333333;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }
`;

const SurveyDropdownMenu = styled(DropdownMenu)`
  position: absolute;
  top: 12px;
  right: 11px;
`;

const fanView = {
  icon: <SvgIndividual style={{ marginRight: 12 }} />,
  label: "Fan View",
  value: "fanView",
};
const editSurvey = {
  icon: <SvgGear style={{ marginRight: 12 }} />,
  label: "Edit",
  value: "editSurvey",
};
const endSurvey = {
  icon: <SvgCircleBlock style={{ marginRight: 12 }} />,
  label: "End Survey",
  value: "endSurvey",
};
const resumeSurvey = {
  icon: <SvgRefresh style={{ marginRight: 12 }} />,
  label: "Resume Survey",
  value: "resumeSurvey",
};
const deleteSurvey = {
  icon: <SvgDelete style={{ marginRight: 12, color: "#E45C52" }} />,
  label: "Delete",
  value: "deleteSurvey",
  labelStyles: ["color: #E45C52"],
};
const setFeatured = {
  icon: <SvgStar style={{ marginRight: 12 }} />,
  label: "Feature This Survey",
  value: "setFeatured",
};

const SurveyMenu = (
  survey: SurveyTypesenseDocument,
  hasFeaturedSupport: boolean,
  featuredId: string,
) => {
  let extras = [];
  if (hasFeaturedSupport && featuredId !== survey.id) {
    extras.push(setFeatured);
  }
  if (survey.status === "draft" || survey.status === "unpublished") {
    return [editSurvey, deleteSurvey];
  }

  if (survey.status === "completed" || survey.status === "cancelled") {
    return [fanView, editSurvey, resumeSurvey, deleteSurvey];
  }

  return [fanView, editSurvey, endSurvey, ...extras, deleteSurvey];
};

// when we have templates ready
// const ShirtEllipse = styled.div`
//   width 12px;
//   height: 12px;
//   border-radius: 50%;
//   background-color: #1B0076;
//   position: absolute;
// `;
// const SurveyIcon = ({ type }: { type: "merch" | "tour" | "setlist" }) => {
//   switch (type) {
//     case "merch":
//       return (
//         <CenterFlexRow
//           style={{ position: "relative", justifyContent: "center" }}
//         >
//           <SvgShirt style={{ width: 72, height: 72, color: "#1B0076" }} />
//           <ShirtEllipse />
//         </CenterFlexRow>
//       );
//     case "tour":
//       return (
//         <SvgMapMarkedAlt style={{ width: 60, height: 60, color: "#1B0076" }} />
//       );
//     case "setlist":
//       return (
//         <CenterFlexRow
//           style={{ position: "relative", justifyContent: "center" }}
//         >
//           <SvgHeart style={{ position: "absolute", top: 10, left: 0 }} />
//           <SvgSetList style={{ width: 60, height: 60, color: "#1B0076" }} />
//         </CenterFlexRow>
//       );
//   }
// };

const SurveyStatusComp = ({ status }: { status: SurveyStatus }) => {
  switch (status) {
    case "draft":
    case "unpublished":
      return (
        <>
          <SvgFileText style={{ marginRight: 3 }} />
          <H4 style={{ color: "#FC6716" }}>DRAFT</H4>
        </>
      );
    case "live":
    case "published":
      return (
        <>
          <SvgCircleChecked style={{ color: "#1DAB98", marginRight: 3 }} />
          <H4 style={{ color: "#1DAB98" }}>PUBLISHED</H4>
        </>
      );
    case "completed":
    case "cancelled":
      return (
        <>
          <SvgCircleBlock style={{ marginRight: 3 }} />
          <H4 style={{ color: "#999" }}>SURVEY ENDED</H4>
        </>
      );
  }
};

const SurveyIconContainer = styled(FlexRow)`
  width: 88px;
  height: 88px;
  background: #f6f9fb;
  border-radius: 50%;
  margin-right: 30px;

  justify-content: center;
  align-items: center;
`;

const SurveyList = styled(FlexColumn)`
  & > div:nth-last-child(n + 2) {
    margin-bottom: 20px;
  }
`;

const Frame = styled.div`
  position: relative;
  padding: 42px 45px;
  width: 100%;

  ${({ theme }) => theme.media.mobile} {
    padding: 20px 0;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledSurveyCard = styled(Card)`
  padding: 33px 45px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
  position: relative;
`;

const SurveysCard = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  padding: 29px 30px;
  margin-top: 32px;
  border: none;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  }
`;
