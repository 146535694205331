import { Button, ButtonType } from "melodies-source/Button";
import { Body1 } from "melodies-source/Text";
import { PropsWithChildren, isValidElement } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

export interface ConfirmationProps {
  cancelLabel?: string;
  cancelVariant?: ButtonType;
  confirmLabel?: string;
  confirmVariant?: ButtonType;
  loading?: boolean;
  disabled?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

export const Confirmation = ({
  children,
  cancelLabel = "Cancel",
  cancelVariant,
  confirmLabel = "confirm",
  confirmVariant,
  loading,
  disabled,
  onCancel,
  onConfirm,
}: PropsWithChildren<ConfirmationProps>) => {
  return (
    <Container>
      <Body1 as="div">{children}</Body1>
      <Footer>
        <Button
          text
          disabled={loading}
          variant={cancelVariant}
          style={{ minWidth: 120 }}
          onClick={onCancel}
        >
          {cancelLabel}
        </Button>
        <Button
          disabled={disabled}
          loading={loading}
          variant={confirmVariant}
          style={{ minWidth: 120 }}
          onClick={onConfirm}
        >
          {confirmLabel}
        </Button>
      </Footer>
    </Container>
  );
};
