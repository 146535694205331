import { forwardRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
`;

const Item = styled.div`
  border: 1px solid white;
  border-radius: 5000px;
  overflow: hidden;
  background-color: #d9d9d9;

  &:first-child {
    margin-left: 0 !important;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

interface AvatarsStackProps {
  urls: string[];
  max?: number;
  size?: number;
}

export const AvatarsStack = forwardRef<HTMLDivElement, AvatarsStackProps>(
  ({ urls, max = 4, size = 35 }, ref) => {
    const slicedUrls = urls.slice(0, Math.min(urls.length, max));

    return (
      <Container ref={ref}>
        {slicedUrls.map((url, index) => (
          <Item
            key={url}
            style={{
              width: size,
              height: size,
              borderWidth: 0.06 * size,
              marginLeft: urls.length >= max && index === 0 ? 0 : -0.4 * size,
              marginRight: urls.length >= max && index === 0 ? -0.3 * size : 0,
            }}
          >
            {urls.length >= max && index === 0 ? null : <Image src={url} />}
          </Item>
        ))}
      </Container>
    );
  },
);
