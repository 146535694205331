import { useEffect, useRef } from "react";

export const useDidUpdate = (
  ...[effect, dependencies]: Parameters<typeof useEffect>
) => {
  const mounted = useRef<boolean>();
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      effect();
    }
  }, dependencies);
};
