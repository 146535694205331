import { CSSProperties } from "styled-components";

const IntegrationModalBackground = ({
  width,
  height,
  sx,
}: {
  width?: number;
  height?: number;
  sx?: CSSProperties;
}) => {
  return (
    <svg
      width={width ?? "338"}
      height={height ?? "309"}
      viewBox={`0 0 ${width ?? 338} ${height ?? 309}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "block", ...sx }}
    >
      <path
        d="M337.994 308.083L0 77.7686V308.083H337.994Z"
        fill="url(#paint0_linear_103_1359)"
        fillOpacity="0.4"
      />
      <path
        d="M56.4242 165.163L0 0V308.083H186.445L56.4242 165.163Z"
        fill="url(#paint1_linear_103_1359)"
      />
      <path
        d="M213.365 307.086L0 160.522V307.086H213.365Z"
        fill="url(#paint2_linear_103_1359)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_103_1359"
          x1="12.9043"
          y1="109.585"
          x2="175.63"
          y2="268.719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#180171" />
          <stop offset="1" stopColor="#8B7FB4" stopOpacity="0.06" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_103_1359"
          x1="20.4392"
          y1="-13.4599"
          x2="118.17"
          y2="250.661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F6F9" />
          <stop offset="1" stopColor="#180171" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_103_1359"
          x1="114.16"
          y1="168.997"
          x2="106.682"
          y2="307.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4C7DB" stopOpacity="0.15" />
          <stop offset="1" stopColor="#C4C7DB" stopOpacity="0.29" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IntegrationModalBackground;
