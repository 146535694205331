import { Button } from "melodies-source/Button";
import { Body1, Selected } from "melodies-source/Text";
import styled from "styled-components";
import { useFanTableContext } from "./";
import { useDesktopMediaQuery } from "hooks";
import { formatNumber } from "Utils/format";

export const ShowMore = () => {
  const { found, getNextPage, hasMore, pageLoading, loading, fans } =
    useFanTableContext();
  const isDesktop = useDesktopMediaQuery();
  const handleClick = () => {
    getNextPage();
  };

  if (!fans) {
    return null;
  }

  if (loading) {
    return null;
  }

  return (
    <Container>
      {hasMore &&
        (pageLoading ? (
          <Button
            variant="secondary"
            disabled
            style={{ width: isDesktop ? "200px" : "100%" }}
          >
            Loading
          </Button>
        ) : (
          <Button
            variant="secondary"
            onClick={handleClick}
            style={{ width: isDesktop ? "200px" : "100%" }}
          >
            Show More
          </Button>
        ))}
      <Body1 style={{ margin: "10px 0" }}>
        Showing{" "}
        <Selected style={{ display: "inline" }}>
          {!hasMore
            ? `all ${formatNumber(fans.length)}`
            : `${formatNumber(fans.length)} of ${formatNumber(found)}`}
        </Selected>{" "}
        contacts
      </Body1>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
  p {
    color: var(--secondary-text-color);
    font-size: 12px;
  }
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;
  }
`;
