import * as React from "react";
import { SVGProps } from "react";
export const SvgClearCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10,2 C14.418278,2 18,5.581722 18,10 C18,14.418278 14.418278,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 Z M7.61289944,6.20970461 C7.22060824,5.90467972 6.65337718,5.93240926 6.29289322,6.29289322 C5.93240926,6.65337718 5.90467972,7.22060824 6.20970461,7.61289944 L6.29289322,7.70710678 L8.585,10 L6.29289322,12.2928932 L6.20970461,12.3871006 C5.90467972,12.7793918 5.93240926,13.3466228 6.29289322,13.7071068 C6.62333685,14.0375504 7.12750547,14.0883879 7.51140295,13.8596192 L7.61289944,13.7902954 L7.70710678,13.7071068 L10,11.415 L12.2928932,13.7071068 L12.3871006,13.7902954 C12.7793918,14.0953203 13.3466228,14.0675907 13.7071068,13.7071068 C14.0675907,13.3466228 14.0953203,12.7793918 13.7902954,12.3871006 L13.7071068,12.2928932 L11.415,10 L13.7071068,7.70710678 L13.7902954,7.61289944 C14.0953203,7.22060824 14.0675907,6.65337718 13.7071068,6.29289322 C13.3766631,5.96244959 12.8724945,5.91161211 12.4885971,6.14038077 L12.3871006,6.20970461 L12.2928932,6.29289322 L10,8.585 L7.70710678,6.29289322 L7.61289944,6.20970461 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
