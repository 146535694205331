import { useArtistContext } from "Components";
import styled from "styled-components";
import { SvgCheck } from "melodies-source/Svgs/Check";
import { SvgDropdown } from "melodies-source/Svgs/Dropdown";
import { useEffect, useState } from "react";
import { useClickOutside } from "react-click-outside-hook";
import { toast } from "react-hot-toast";
import { useDesktopMediaQuery } from "hooks";
import { setDoc } from "firebase/firestore";
import { Member } from ".";
import { Toggle } from "Components/Toggle";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { AffiliationMap } from "@max/common/dist/signup";

interface Props {
  member: Member;
  adminCount: number;
}

export const TeammateRoleSelect: React.FC<Props> = ({ member, adminCount }) => {
  const { uid, roles } = member;
  const isDesktop = useDesktopMediaQuery(1200);

  const [open, setOpen] = useState(false);
  const { artistGroupDoc, isAdmin, getArtistGroup } = useArtistContext();
  const { roles: agRoles = {} } = artistGroupDoc.data();
  const [ref, hasClickedOutside] = useClickOutside();
  const { customApp } = useCustomAppContext();

  const roleOptions = customApp?.userRoles || AffiliationMap;

  const roleText =
    (agRoles[uid] || "") +
    (roles.includes("admin") ? (agRoles[uid] ? " • Admin" : " Admin") : "");
  const toggleOpen = () => {
    setOpen(!open);
  };

  const updateRoles = async (roles, roleText = agRoles[uid]) => {
    await setDoc(
      artistGroupDoc.ref,
      {
        members: {
          [uid]: roles,
        },
        roles: {
          [uid]: roleText,
        },
      },
      { merge: true },
    );
    getArtistGroup();
  };

  const handleRoleSelect = (role) => {
    const newRoles = [role === "Artist" ? "artist" : "manager"];
    if (roles.includes("admin")) {
      newRoles.push("admin");
    }
    updateRoles(newRoles, role);
  };

  const handleAdminSelect = () => {
    let newRoles = [...roles];
    if (newRoles.includes("admin")) {
      if (adminCount === 1) {
        toast("You're the only admin left. Make another one first");
        return;
      }
      newRoles = newRoles.filter((r) => r !== "admin");
    } else {
      newRoles.push("admin");
    }
    updateRoles(newRoles);
  };

  useEffect(() => {
    if (hasClickedOutside) {
      setOpen(false);
    }
  }, [hasClickedOutside]);

  if (!isAdmin) {
    return <Role>{roleText}</Role>;
  }

  return (
    <Container ref={ref}>
      <RoleButton onClick={toggleOpen}>
        {!isDesktop && <Dropdown style={{ margin: "0 8px 0 -25px" }} />}
        {roleText || (!isDesktop && <SelectText>Select a role</SelectText>)}
        {isDesktop && <Dropdown style={{ margin: "0 5px" }} />}
      </RoleButton>
      {open && (
        <Overlay isDesktop={isDesktop}>
          {Object.entries(roleOptions)
            .filter(([_, label]) => label.toLowerCase() !== "unknown")
            .map(([value, label]) => (
              <RoleOptions
                onClick={() => {
                  handleRoleSelect(label);
                }}
                isSelected={agRoles[uid] === label}
                key={`teammate-role-option-${value}`}
              >
                {label}
              </RoleOptions>
            ))}
          <AdminContainer>
            <MakeAdmin>Make Admin</MakeAdmin>
            <Toggle
              checked={roles.includes("admin")}
              onChange={handleAdminSelect}
              withSwitchLabel
            />
          </AdminContainer>
        </Overlay>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Role = styled.div`
  color: var(--text-color);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
  padding: 0;
  display: flex;
  align-items: center;
`;

const RoleButton = styled(Role)`
  cursor: pointer;
  &:hover {
    background: var(--hover-background-color);
  }
`;
const SelectText = styled.div`
  color: #a4a4a4;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 200;
`;
const Dropdown = styled(SvgDropdown)`
  & path {
    fill: var(--text-color);
  }
`;

const Overlay = styled.div<{ isDesktop?: boolean }>`
  position: absolute;
  top: 20px;
  ${({ isDesktop }) => (!isDesktop ? "left: 0;" : "right: 0")};
  background: var(--component-background-color);
  z-index: 5;
  box-shadow: 0 4px 12px 0 var(--box-shadow-color);
  border-radius: 6px;
  overflow: hidden;
`;

const MakeAdmin = styled.div`
  color: var(--text-color);
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
`;

const RoleOptions = ({ isSelected, children, ...props }) => {
  return (
    <RoleOptionsContainer {...props}>
      {isSelected && (
        <SvgCheck
          style={{
            color: "var(--secondary-color)",
            position: "absolute",
            top: "16px",
            left: "12px",
          }}
        />
      )}
      {children}
    </RoleOptionsContainer>
  );
};

const RoleOptionsContainer = styled.div`
  cursor: pointer;
  position: relative;
  color: var(--text-color);
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  width: 280px;
  padding: 12px 12px 12px 48px;
  &:hover {
    background: var(--hover-background-color);
  }
`;

const AdminContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: inset 0 1px 0 0 var(--border-color);
  padding: 14px 12px;
`;
