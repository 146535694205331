import { FlexRow, Font13, Font16 } from "Routes/styled";
import { truncateString } from "Utils";
import { useDesktopMediaQuery } from "hooks";
import useIsMounted from "hooks/useIsMounted";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { useTabletMediaQuery } from "hooks/useTabletMediaQuery";
import { Aggregation } from "models/survey";
import styled from "styled-components";

const QuestionShowcase = ({
  showcasedQuestion,
  totalResponses,
  compact,
}: {
  showcasedQuestion: Omit<Aggregation, "options"> & {
    options: { label: string; id: string; count: number }[];
  };
  totalResponses: number;
  compact?: boolean;
}) => {
  const isDesktop = useDesktopMediaQuery();
  const isTablet = useTabletMediaQuery();
  const isMobile = useMobileMediaQuery();
  const { mounted } = useIsMounted();

  return (
    <ShowcaseChart style={{ marginBottom: isDesktop ? 20 : 22 }}>
      {!compact && !isTablet && !isMobile && (
        <Font16
          fontWeight={700}
          style={{ marginTop: isDesktop ? 27 : 0, marginBottom: 13 }}
        >
          {showcasedQuestion.header}
        </Font16>
      )}
      {isDesktop && (
        <FlexRow style={{ width: "100%" }}>
          <BarLabels>
            {showcasedQuestion?.options.map(({ label, id }) => {
              return (
                <BarLabel key={`bar-label-${id ?? label}`}>
                  {truncateString(label)}
                </BarLabel>
              );
            })}
          </BarLabels>
          <BarDataContainer>
            {showcasedQuestion?.options.map(({ count, label, id }) => {
              return (
                <BarDataRow key={`bar-${id ?? label}`}>
                  <Bar
                    width={!mounted ? 0 : (count / totalResponses) * 100}
                    opacity={mounted ? 1 : 0}
                  />
                  <Font13 style={{ lineHeight: "15px" }} fontWeight={400}>
                    {count}
                  </Font13>
                </BarDataRow>
              );
            })}
          </BarDataContainer>
        </FlexRow>
      )}
    </ShowcaseChart>
  );
};

export default QuestionShowcase;

const Bar = styled.div<{ width: number; opacity: number }>`
  background: white;
  width: ${({ width }) => `${width}%`};
  height: 14px;
  opacity: ${({ opacity }) => opacity};
  margin-right: ${({ width }) => (width ? "7px" : 0)};
  border-radius: 0px 20px 20px 0px;

  transition:
    width 1.5s,
    opacity 2s;
`;

const BarDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 8px;
`;

const BarLabels = styled.div`
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 1px;
`;

const BarDataRow = styled.div`
  display: flex;
  width: 100%;
  height: 27px;
`;

const BarLabel = styled.div`
  font-family: Poppins;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  color: white;
  text-align: right;
  white-space: nowrap;
`;

const ShowcaseChart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
