import { REQUIRED_MSG } from "Utils/yupStrictPassword";
import * as yup from "yup";

export interface CustomSong {
  name: string;
  album: string;
  artist: string;
}

export const CUSTOM_SONG_SCHEMA = yup.object().shape({
  name: yup.string().required(REQUIRED_MSG),
  album: yup.string().required(REQUIRED_MSG),
  artist: yup.string().required(REQUIRED_MSG),
});

export interface Song {
  id: string;
  editedName?: string;
  name: string;
  album: string;
  artist: string;
  src: string;
  visible: boolean;
  deletable: boolean;
}

export const SONG_SCHEMA = yup.object().shape({
  name: yup.string().required(REQUIRED_MSG),
  album: yup.string().required(REQUIRED_MSG),
  artist: yup.string().required(REQUIRED_MSG),
  src: yup.string().nullable(true),
  visible: yup.boolean().required(REQUIRED_MSG),
  deletable: yup.boolean().required(REQUIRED_MSG),
});

export interface Album {
  id: string;
  editedName?: string;
  name: string;
  artist: string;
  src: string;
  visible: boolean;
  deletable: boolean;
  songs: Song[];
}

export const ALBUM_SCHEMA = yup.object().shape({
  name: yup.string().required(REQUIRED_MSG),
  artist: yup.string().required(REQUIRED_MSG),
  src: yup.string().nullable(true),
  visible: yup.boolean().required(REQUIRED_MSG),
  deletable: yup.boolean().required(REQUIRED_MSG),
  songs: yup
    .array()
    .of(SONG_SCHEMA)
    .compact((v) => !v.visible)
    .min(1, "Please select at least 1 song"),
});

export interface SongsQuestionValues {
  headline: string;
  max: string;
  albums: Album[];
}

export const SONGS_QUESTION_SCHEMA = yup.object().shape({
  headline: yup.string().required(REQUIRED_MSG),
  max: yup.string(),
  albums: yup
    .array()
    .of(ALBUM_SCHEMA)
    .compact((v) => !v.visible)
    .min(1, "Please select at least 1 song for a dream setlist"),
});

// subject to change
export type ArtistInfo = {
  albums: Album[];
};

export type AlbumT = {
  album_type: string;
  editedName?: string;
  id: string;
  image: string;
  name: string;
  tracks: Track[];
  visible?: boolean;
};

type Track = {
  disc_number: number;
  duration_ms: number;
  editedName?: string;
  id: string;
  image: string;
  name: string;
  track_number: number;
};
