import { DefaultLayout } from "Components";
import DashboardBG from "svg/DashboardHeaderBG";
import { Font30 } from "Routes/styled";
import styled from "styled-components";
import { Tabs } from "Components/Tabs";
import SetFan from "./SetFan";
import SetLive from "./SetLive";
import { GlobalStyles } from "styled";
import { useParams } from "react-router-dom";
import MicrophoneIcon from "svg/Microphone";
import { ReactComponent as ClipboardIcon } from "assets/svg/clipboard.svg";
import FanProfile from "./FanProfile";
import { ReactComponent as UsersIcon } from "assets/svg/users.svg";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { ReactComponent as ClipboardFillIcon } from "assets/svg/clipboard-fill.svg";

export const Reporting = () => {
  const { artistId } = useParams<{ artistId: string }>();
  const { customApp } = useCustomAppContext();

  const tabs = [
    ...(customApp
      ? []
      : [
          {
            param: "fan-profile",
            label: "Fan Profile",
            content: <FanProfile />,
            icon: <UsersIcon />,
            hasHorizontalPadding: false,
          },
        ]),
    {
      param: "set-live",
      label: `${customApp?.company.name || "SET"}.Live`,
      content: (
        <SetLive />
      ),
      icon: customApp ? (
        <ClipboardFillIcon width={24} height={24} />
      ) : (
        <MicrophoneIcon />
      ),
    },
    ...(customApp
      ? []
      : [
          {
            param: "set-fan",
            label: `${customApp?.company.name || "SET"}.Fan`,
            content: <SetFan />,
            icon: <ClipboardIcon />,
          },
        ]),
  ];

  return (
    <DefaultLayout>
      <GlobalStyles grayBG />
      <DashboardBG />
      <ReportingContainer>
        <Font30 as="div" fontWeight={700} style={{ marginBottom: 30 }}>
          Reporting
        </Font30>
        <Tabs tabs={tabs} basePath={`/${artistId}/reporting`} />
      </ReportingContainer>
    </DefaultLayout>
  );
};

const ReportingContainer = styled.div`
  position: relative;
  padding: 37px 46px;

  ${({ theme }) => theme.media.mobile} {
    padding: 20px 0;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
`;
