import * as React from "react";
import { SVGProps } from "react";
export const SvgOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8,3 L8,5 L5,5 L5,15 L15,15 L15,12 L17,12 L17,16 C17,16.5522847 16.5522847,17 16,17 L4,17 C3.44771525,17 3,16.5522847 3,16 L3,4 C3,3.44771525 3.44771525,3 4,3 L8,3 Z M17,3 L17,9 L15,9 L14.9998932,6.414 L10.7071068,10.7071068 L9.29289322,9.29289322 L13.5848932,5 L11,5 L11,3 L17,3 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
