import styled from "styled-components";
import { DateTime } from "luxon";
import { useDesktopMediaQuery } from "hooks";

const CalendarIcon = ({ height }: { height: number }) => {
  return (
    <svg
      width={height}
      height={height}
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 5.875C0.5 3.38972 2.51472 1.375 5 1.375H53.125C55.6103 1.375 57.625 3.38972 57.625 5.875V14.5833H0.5V5.875Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M1 14.7917H57.125V54C57.125 56.2091 55.3341 58 53.125 58H5C2.79086 58 1 56.2091 1 54V14.7917Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

const Calendar = ({ date }: { date: DateTime }) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <CalendarContainer>
      <DayOfWeekText>{date.weekdayLong}</DayOfWeekText>
      <MonthText>{date.monthShort}</MonthText>
      <DayNumber>{`${date.day}${getNumberSuffix(date.day)}`}</DayNumber>
      <CalendarIcon height={isDesktop ? 59 : 35} />
    </CalendarContainer>
  );
};

export default Calendar;

const CalendarContainer = styled.div`
  margin-right: 16px;
  height: 59px;

  ${({ theme }) => theme.media.mobile} {
    margin-right: 13.6px;
    height: 35px;
  }
`;

const MonthText = styled.div`
  position: absolute;
  width: 59px;
  top: 30px;
  text-align: center;
  color: white;
  font-family: Lato;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;

  ${({ theme }) => theme.media.mobile} {
    width: 35px;
    top: 18px;
    font-size: 9.2px;
    line-height: 14px;
  }
`;

const DayNumber = styled(MonthText)`
  top: 45px;

  ${({ theme }) => theme.media.mobile} {
    top: 27px;
  }
`;

const DayOfWeekText = styled.div`
  font-family: Lato;
  font-weight: 800;
  font-size: 6.5px;
  line-height: 17px;
  width: 59px;
  color: black;
  position: absolute;
  text-align: center;

  ${({ theme }) => theme.media.mobile} {
    width: 35px;
    font-size: 5.44px;
    line-height: 14px;
    top: 6px;
  }
`;

export function getNumberSuffix(num: number): "th" | "st" | "nd" | "rd" {
  if (num === 11 || num === 12 || num === 13) return "th";

  let lastDigit = num.toString().slice(-1);

  switch (lastDigit) {
    case "1":
      return "st";
    case "2":
      return "nd";
    case "3":
      return "rd";
    default:
      return "th";
  }
}
