import { FlexColumn, FlexRow } from "Routes/styled";
import styled from "styled-components";

const BarChart = ({ dataSeries }: { dataSeries: number[] }) => {
  const maxValue = Math.max(...dataSeries);
  return (
    <GraphContent>
      {dataSeries.map((val, idx) => (
        <GraphBarContainer key={`graph-bar-${idx}-${val}`}>
          <GraphBar ratio={val / maxValue} />
        </GraphBarContainer>
      ))}
    </GraphContent>
  );
};

export default BarChart;

const GraphBarContainer = styled(FlexColumn)`
  position: relative;
  width: 6.66667%;
  height: 73px;
  align-items: center;
  justify-content: flex-end;
`;

const GraphBar = styled.div<{
  ratio: number;
}>`
  background: var(--main-color);
  opacity: ${({ ratio }) => Math.max(0.2, ratio)};
  border-radius: 2px;
  width: 72%;
  height: ${({ ratio }) => `${ratio * 100}%`};
`;

const GraphContent = styled(FlexRow)`
  margin-bottom: 10px;
`;
