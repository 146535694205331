import { Datepicker } from "Components/DatePicker";
import { FlexRow } from "Routes/styled";
import { DateTime } from "luxon";
import { SvgRightLarge } from "melodies-source/Svgs/RightLarge";
import styled, { css } from "styled-components";
import { Filter } from "./Filter";

interface DateFilterProps {
  start?: DateTime;
  end?: DateTime;
  onChange: (v: [DateTime, DateTime]) => void;
  last?: boolean;
}

export const DateRange = ({ last, start, end, onChange }: DateFilterProps) => {
  return (
    <CustomFilter last={last}>
      <DateContainer>
        <Datepicker
          type="dateOnly"
          value={start}
          onChange={(v) => onChange([v, end?.endOf("day")])}
        />
        <SvgRightLarge />
        <Datepicker
          type="dateOnly"
          value={end}
          onChange={(v) => onChange([start, v?.endOf("day")])}
        />
      </DateContainer>
    </CustomFilter>
  );
};

const CustomFilter = styled(Filter)<{ last?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ last }) =>
    last &&
    css`
      box-shadow: none;
      margin-bottom: -5px;
    `}
`;

const DateContainer = styled(FlexRow)`
  width: 100%;
  margin: 10px 0 20px;
  & > svg {
    width: 55px;
    margin: 10px 3vw -10px;
    color: #dedede;
    ${({ theme }) => theme.mediaQueries.mobile} {
      margin: 10px auto;
    }
  }
  & > div {
    width: 100%;
    font-size: 12px !important;
  }
  & > div > div {
    width: 100%;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
  }
`;
