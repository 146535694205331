import styled, { css } from "styled-components";
import { useArtistContext } from "../../../../Components";
import {
  DMAMapData,
  WorldMapData,
} from "../../../../Components/FanLocationMaps/common";
import { useState } from "react";
import useMeasure from "react-use-measure";
import { WorldMap } from "Components/FanLocationMaps/WorldMap";
import { StateMap } from "Components/FanLocationMaps/StateMap";
import { Select } from "melodies-source/Select";
import FanLocationsKeyCard from "./FanLocationsKeyCard";
import { Card } from "Components/Card";
import { H2, H3 } from "melodies-source/Text";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";

export const options = [
  {
    value: "1",
    label: "By City (US Only)",
  },
  {
    value: "2",
    label: "By Country",
  },
];

interface Props {
  dmaData?: DMAMapData[];
  worldData?: WorldMapData[];
}

const FanLocations: React.FC<Props> = ({ dmaData, worldData }) => {
  const [selectedMap, setSelectedMap] = useState(options[0].value);
  const isMobile = useMobileMediaQuery();
  const [ref, { width }] = useMeasure();

  const { privateData } = useArtistContext();
  const worldMapData =
    (worldData ?? (privateData?.data()?.world as WorldMapData[])) || [];
  const dmaMapData =
    (dmaData ?? (privateData?.data()?.dma as DMAMapData[])) || [];

  const isWorldMapSelected = selectedMap === "2";

  const Heading = isMobile ? H3 : H2;
  return (
    <>
      <StyledCard isElevated>
        <HeaderContainer>
          <Heading>Markets</Heading>
          <MarketSelect
            data-html2canvas-ignore
            options={options}
            onChange={(v) => setSelectedMap(v)}
            value={selectedMap}
          />
        </HeaderContainer>
        <Content>
          <Left>
            {isWorldMapSelected ? (
              <WorldMap width={width} data={worldMapData} />
            ) : (
              <StateMap width={width} data={dmaMapData} />
            )}
            <Sizer isWorld={isWorldMapSelected} ref={ref} />
          </Left>
        </Content>
      </StyledCard>
      <FanLocationsKeyCard />
    </>
  );
};

export default FanLocations;

const StyledCard = styled(Card)`
  padding: 32px 48px;
  overflow: hidden;

  ${({ theme }) => theme.media.mobile} {
    padding: 20px 10px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
`;

const MarketSelect = styled(Select)`
  width: 198px;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 10px;
  }
`;

const Sizer = styled.div<{ isWorld: boolean }>`
  height: 0;
  padding-top: 62.5%;
  ${({ isWorld }) =>
    isWorld &&
    css`
      padding-top: 50%;
    `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
  }

  ${({ theme }) => theme.media.mobile} {
    margin-top: 20px;
  }
`;
const Left = styled.div`
  flex-grow: 1;
`;
