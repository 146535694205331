import React from "react";
import styled from "styled-components";
import { SvgRefreshLarge as MelodiesRefreshIcon } from "melodies-source/Svgs/RefreshLarge";
import { FlexRow } from "Components/Flex";

export const RefreshIcon: React.FC = (props) => {
  return (
    <FlexRow xCenter yCenter>
      <StyledIcon {...props} onClick={() => window.location.reload()} />
    </FlexRow>
  );
};

const StyledIcon = styled(MelodiesRefreshIcon)`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;
