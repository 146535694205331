import React from "react";
import styled from "styled-components";
import { Spinner } from "../Spinner";

const LoadingBackdrop = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: var(--background-color);
`;

const ModalContainer = styled.div`
  top: 0px;
  left: 0px;
  margin: 0px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  margin-top: 115px;
  pointer-events: none;
  div {
    pointer-events: auto;
  }
`;

const LoaderContainer = styled(ModalContainer)`
  pointer-events: none;
`;
type SpinnerProps = {
  color?: string;
  style?: any;
};
export const AppLoading = ({ color, ...props }: SpinnerProps) => (
  <>
    <LoadingBackdrop />
    <LoaderContainer {...props}>
      <div style={{ width: 300, marginTop: 100 }}>
        <div style={{ position: "relative" }}>
          <Spinner color={color} />
        </div>
      </div>
    </LoaderContainer>
  </>
);
