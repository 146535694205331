import { Modal } from "melodies-source/Modal";
import styled, { useTheme } from "styled-components";
import { FlexColumn, FlexRow } from "Routes/styled";
import { Button } from "melodies-source/Button";
import { Body1, Body2, H2, H4 } from "melodies-source/Text";
import { SvgBuilderLarge } from "melodies-source/Svgs/BuilderLarge";
import { useTemplates } from "./TemplateContext";
import { surveyTemplates } from "./defaults";
import {
  SelectionCard,
  IconContainer,
} from "Routes/SetFan/Components/SelectionCard";

interface SelectTemplateProps {
  onBack: VoidFunction;
}

export const SelectTemplateModal = ({ onBack }: SelectTemplateProps) => {
  const {
    isLoading,
    handleCreate,
    templateCategory,
    setSelectedTemplate,
    selectedTemplate,
    setModal,
    modal,
  } = useTemplates();
  const templateByCategory = surveyTemplates[templateCategory];
  const { colors } = useTheme();
  const templates = Object.values(templateByCategory?.templates || {});

  return (
    <Modal
      header={
        <div>
          <H2>Select Your Template</H2>
          <Body1 style={{ color: "#666", marginBottom: 12 }}>
            {templateByCategory?.modalHeader}
          </Body1>
        </div>
      }
      isOpen={modal.selectTemplate}
      variant="large"
    >
      {!templates?.length ? (
        <NoTemplates style={{ color: "#666666" }}>
          <SvgBuilderLarge />
          <Body1>
            There are currently no templates available for this category.
          </Body1>
        </NoTemplates>
      ) : (
        <CardContainer>
          {templates.map((tmpl) => (
            <StyledSelectionCard
              icon={tmpl.icon}
              header={tmpl.title}
              subHeader={tmpl.description}
              onClick={() => {
                if (!isLoading) {
                  setSelectedTemplate(tmpl);
                }
              }}
              selected={tmpl.templateId === selectedTemplate?.templateId}
              key={`template-${tmpl.templateId}`}
            />
          ))}
        </CardContainer>
      )}
      <FlexRow style={{ justifyContent: "flex-end", marginTop: 32 }}>
        <Button
          variant="tertiary"
          onClick={() => onBack()}
          style={{ marginRight: 24, color: colors.main }}
          disabled={isLoading}
        >
          Back
        </Button>
        <Button
          variant="primary"
          loading={isLoading}
          onClick={() => {
            if (selectedTemplate.prompt) {
              setModal({ selectTemplate: false, aiPrompt: true });
              return;
            } else {
              handleCreate();
            }
          }}
          disabled={selectedTemplate === undefined}
        >
          {!selectedTemplate?.prompt ? "Create" : "Next"}
        </Button>
      </FlexRow>
    </Modal>
  );
};

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  column-gap: 20px;

  ${({ theme }) => theme.mediaQueries.desktop} {
    grid-template-columns: 1fr 1fr;
  }
`;

const NoTemplates = styled(FlexColumn)`
  color: #666;
  align-items: center;
  padding: 52px 53px 60px 53px;
  max-width: 372px;
  margin: 80px auto 80px auto;

  svg {
    width: 118px;
    height: 118px;
    color: #e8e5f1;
    margin-bottom: 16px;
  }

  ${Body1} {
    color: #333333;
    text-align: center;
  }
`;

const StyledSelectionCard = styled(SelectionCard)`
  padding: 12px 20px 12px 16px;
  ${H4} {
    margin-top: 3px;
  }
  ${Body2} {
    margin-top: 2px;
  }
  ${IconContainer} {
    align-self: flex-start;
    width: 32px;
    margin-right: 12px;
    & > svg {
      width: 32px;
      height: 32px;
    }
  }
`;
