import { SurveyTemplate } from "Routes/SetFan/dashboard/Components/Templates/types";
import { ReactComponent as IconTour } from "assets/svg/tour.svg";
import { getContestDefaults } from "../helpers";

export const tourWith: SurveyTemplate = {
  icon: <IconTour />,
  title: "Who Should We Tour With?",
  description:
    "Ask fans who they think would be a good fit to tour with and learn more about their taste.",
  complexity: "med",
  contestSuggestion: "Sweepstakes",
  templateId: "tourWith",
  template: (v, formState) => ({
    fields: {
      alcoholIntroText: {
        content: {
          en: "Please share a bit about what you like to drink. Your feedback could help us find a sponsor for the tour.",
        },
      },
      body: {
        content: {
          en: "We want to know about your dream bill. If you could choose any artist for us to tour with, who would it be?",
        },
      },
      clothingIntroText: {
        content: {
          en: "Working with a fashion brand as a sponsor could help make our tour even better, but we want to find one that would be a good fit with our fans.",
        },
      },
      demographicsIntroText: {
        content: {
          en: "We'd love to know a bit more about you to help plan how to get the word out about shows in your area",
        },
      },
      description: {
        content: {
          en: "Who Should We Tour With",
        },
      },
      displayedCategories: {
        content: [
          "demographics",
          "fooddrink",
          "alcohol",
          "clothing",
          "vehicle",
          "entertainment",
        ],
      },
      entertainmentIntroText: {
        content: {
          en: "Please take a minute tell us what you like in your free time. Your insights can help us collaborate with tech and entertainment brands that you love, adding an extra spark to our shows.",
        },
      },
      fooddrinkIntroText: {
        content: {
          en: "A food or drink sponsor could help us make the tour even better. Help us find one that would be a good fit for this tour.",
        },
      },
      header: {
        content: {
          en: "Who Should We Tour With?",
        },
      },
      numberOfCategories: {
        content: "2",
      },
      thankYouBody: {
        content: {
          en: "We love hearing about who you think would be a great tour bill and will let you know about when we hit the road next!",
        },
      },
      thankYouHeader: {
        content: {
          en: "Thank you for letting us know your dream tour! ",
        },
      },
      vehicleIntroText: {
        content: {
          en: "Your feedback about what you'd like to drive can help us work with a vehicle brand to make the next tour even better.",
        },
      },
      ...getContestDefaults(formState),
    },
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
    surveyQuestions: [
      {
        type: "TextQuestion",
        label: { en: "Who should we tour with?" },
        required: true,
        multiline: false,
        variants: { mobile: "Headline4", desktop: "Headline3" },
        noBorder: false,
      },
      {
        type: "TextQuestion",
        label: { en: "Why would they be a good fit?" },
        required: true,
        multiline: true,
        variants: { mobile: "Headline4", desktop: "Headline3" },
        noBorder: false,
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-tour---who-efcc15"
        : "pp-tour---who-6589d2",
    fields: [
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
