import { Route, Switch } from "react-router-dom";
import { Dashboard } from "./dashboard";
import { Wizard } from "./wizard";
import { Editor } from "./editor";

export const SetFan = () => {
  return (
    <Switch>
      <Route path="/:artistId/set-fan/surveys" component={Dashboard} exact />
      <Route
        path="/:artistId/set-fan/surveys/:surveyId/wizard"
        component={Wizard}
      />
      <Route path="/:artistId/set-fan/surveys/:surveyId" component={Editor} />
    </Switch>
  );
};
