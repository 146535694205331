import React, { useEffect, useState } from "react";
import { Body1, Body2 } from "melodies-source/Text";
import { Divider } from "Components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import {
  DocumentReference,
  FieldValue,
  deleteField,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { BrandCustomizations, Question, Questions } from "./types";
import { Checkbox } from "melodies-source/Selectable";
import styled, { css } from "styled-components";
import { Toggle } from "Components/Toggle";
import { Modal } from "../../Components/Modal";
import toast from "react-hot-toast";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  regionId: string;
}

export const DeafultQuestionsModal: React.FC<Props> = ({
  isOpen,
  onClose,
  regionId,
}) => {
  const [brandCustomizations] = useDocumentData(
    doc(
      getFirestore(),
      "brand_customizations/gtb",
    ) as DocumentReference<BrandCustomizations>,
  );
  const gtbQuestionsPath = `gtb_regions/${regionId}/private/questions`;
  const [questions, questionsLoading] = useDocumentData(
    doc(getFirestore(), gtbQuestionsPath) as DocumentReference<Questions>,
  );
  const [questionsDraft, setQuestionsDraft] = useState<Questions>({});
  const [saveLoading, setSaveLoading] = useState(false);

  const updateQuestion = (questionId: string, data: Partial<Question>) => {
    setQuestionsDraft((questions) => ({
      ...questions,
      [questionId]: { ...questions[questionId], ...data },
    }));
  };

  const addQuestion = (questionId: string) => {
    updateQuestion(questionId, { required: false });
  };

  const removeQuestion = (questionId: string) => {
    const { [questionId]: questionDeleted, ...remainingQuestions } =
      questionsDraft;
    setQuestionsDraft(remainingQuestions);
  };

  const updateQuestionsCollection = () => {
    setSaveLoading(true);
    setDoc(doc(getFirestore(), gtbQuestionsPath), questionsDraft);
    setSaveLoading(false);
    toast.success("Questions updated successfully!");
    onClose();
  };

  useEffect(() => {
    if (!questionsLoading && questions) {
      setQuestionsDraft(questions);
    }
  }, [questionsLoading]);

  return (
    <Modal
      header="Edit Default Questions"
      isOpen={isOpen}
      onClose={onClose}
      actions={{
        secondary: {
          text: "Cancel",
          onClick: onClose,
        },
        main: {
          text: "Save",
          onClick: updateQuestionsCollection,
          loading: saveLoading,
        },
      }}
      withCloseIcon
    >
      <Body1>
        Please select which questions you would like to appear on check-in forms
        for users.
      </Body1>
      <br />
      <Body2>
        These questions will appear for any current and future programs in this
        region.
      </Body2>
      <Divider />
      <QuestionsContainer>
        {Object.entries(brandCustomizations?.questions || {})
          .sort(([aId, a], [bId, b]) => a.defaultSortOrder - b.defaultSortOrder)
          .map(([questionId, question]) => (
            <React.Fragment key={questionId}>
              <StyledCheckbox
                label={question.internalLabel}
                value={!!questionsDraft?.[questionId]}
                onChange={() =>
                  !questionsDraft?.[questionId]
                    ? addQuestion(questionId)
                    : removeQuestion(questionId)
                }
              />
              {!!questionsDraft?.[questionId] && (
                <QuestionConfig>
                  <Toggle
                    label="Required"
                    checked={questionsDraft?.[questionId].required}
                    onChange={() =>
                      updateQuestion(questionId, {
                        required: !questionsDraft?.[questionId].required,
                      })
                    }
                  />
                </QuestionConfig>
              )}
            </React.Fragment>
          ))}
      </QuestionsContainer>
    </Modal>
  );
};

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const QuestionConfig = styled.div`
  margin-left: 32px;
`;

const StyledCheckbox = styled(Checkbox)`
  ${(props) =>
    !props.value &&
    css`
      * {
        color: #666666;
        border-color: #666666;
      }
    `}
`;
