import React from "react";
import { useState } from "react";
import { svg } from "svg";
import { ReportingCardHeader } from "Routes/Reporting/styled";
import { CenterFlexRow, Font14, Font16 } from "Routes/styled";
import styled from "styled-components";
import Collapse from "../../Components/CollapseContainer";
import {
  ProfileSection,
  AudienceAttribute,
  WordCloudQuestion,
} from "models/fanProfile";
import { ChartCard, Props as ChartCardProps } from "../../Components/ChartCard";
import { QuestionModal } from "../QuestionModal";
import { textWithoutParentheses } from "Utils";
import { ChartSelect, DataType } from "./ChartSelect";
import { useIsMobile } from "melodies-source/utils";
import { useTabletMediaQuery } from "hooks/useTabletMediaQuery";
import { Item } from "Routes/Reporting/Components/WordCloudChart";
import { useCustomAppContext } from "contexts/CustomAppContext";

interface Props {
  label: string;
  data?: {
    profile: Record<string, ProfileSection<AudienceAttribute>>;
    wordCloud: Record<string, WordCloudQuestion>;
  };
  loading?: boolean;
  defaultOpen?: boolean;
}

const MAX_ITEMS = {
  "diverging-bar": 7,
  "horizontal-bar": 9,
};

export const getDivergingChartData = (data) =>
  data.filter((elem) => elem.index !== null).sort((a, b) => b.index - a.index);

export const getHorizontalBarChartData = (data) =>
  data
    .map((elem) => ({
      key: elem.id,
      label:
        elem.category !== "demographics"
          ? textWithoutParentheses(elem.title)
          : elem.title,
      value: elem.count,
      // this is an escape hatch to handle the different way we store data for fan profiles and other aggregations.
      // fan profiles are stored as a decimal value that translates directly to a percentage to be displayed in the ui.
      // aggregations are stored as a count so we need to calculate the count / total count for a question.
      actual: elem.actual ?? 0,
    }))
    .sort((a, b) => b.value - a.value);

const Section: React.FC<Props> = ({ label, data, loading, defaultOpen }) => {
  const isMobile = useIsMobile();
  const isTablet = useTabletMediaQuery();
  const [dataType, setDataType] = useState<DataType>("index");
  const { customApp } = useCustomAppContext();

  const hasIndexQuestion = Object.values(data.profile || {}).some(
    (question) => !!question.data?.[0]?.index,
  );

  const hasQuestionWithData = Object.values(data.profile || {}).some(
    (question) => !!question.data?.length,
  );

  return (
    <Collapse
      label={label}
      endComponent={
        hasIndexQuestion && (
          <ChartSelect value={dataType} setValue={setDataType} />
        )
      }
      defaultOpen={defaultOpen}
    >
      {loading ? (
        <HeaderText>Loading...</HeaderText>
      ) : (
        <>
          <BodyContainer>
            {Object.values(data.profile || {})
              .filter((question) => question.data.length > 0)
              .map((question) => {
                let chart: ChartCardProps["chart"];
                const hasIndexValues = !!question.data?.[0]?.index;

                if (dataType === "index" && hasIndexValues) {
                  chart = {
                    type: "diverging-bar",
                    data: getDivergingChartData(question.data),
                  };
                } else {
                  chart = {
                    type: "horizontal-bar",
                    data: getHorizontalBarChartData(question.data),
                  };
                }

                const totalItems = chart.data.length;

                if (chart.data.length > MAX_ITEMS[chart.type]) {
                  chart.data = chart.data.slice(0, MAX_ITEMS[chart.type]);
                }

                return (
                  <ChartContainer>
                    <ChartCard
                      key={question.question}
                      title={textWithoutParentheses(question.question)}
                      chart={chart}
                      {...(totalItems > MAX_ITEMS[chart.type] && {
                        modal: {
                          buttonText: "Show All",
                          component: QuestionModal,
                          props: {
                            question,
                          },
                        },
                      })}
                    />
                  </ChartContainer>
                );
              })}
            {Object.values(data?.wordCloud ?? {})
              .filter((wc) => Object.keys(wc.data).length)
              .map((wc) => {
                const wordCloudData = getWordCloudData(
                  wc.data,
                  isMobile,
                  isTablet,
                );
                return !wordCloudData.data.length ? null : (
                  <ChartContainer>
                    <ChartCard
                      title={wc.question}
                      chart={wordCloudData}
                      loading={false}
                    />
                  </ChartContainer>
                );
              })}
          </BodyContainer>
          {hasQuestionWithData ? (
            <FooterText>
              * Index values derived from a sample of 17,000 music fans across
              all genres.
            </FooterText>
          ) : (
            <NoData>
              <StyledHelpIcon />
              <NoDataText>
                Insufficient data - this section will populate as more fans
                complete{" "}
                <NoDataText
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open("set-fan")}
                >
                  {customApp?.company.name || "SET"}.Fan
                </NoDataText>{" "}
                surveys
              </NoDataText>
            </NoData>
          )}
        </>
      )}
    </Collapse>
  );
};

export default Section;

const getWordCloudData = (
  wordData: Record<string, number>,
  isMobile: boolean,
  isTablet: boolean,
): { type: "word-cloud"; data: Item[] } => {
  let sortedData = Object.entries(wordData ?? {})
    .map(([word, count]) => ({ text: word, value: count }))
    .sort((a, b) => b.value - a.value);

  sortedData = sortedData.slice(
    0,
    Math.min(sortedData.length, isMobile ? 20 : isTablet ? 30 : 40),
  );
  return {
    type: "word-cloud",
    data: sortedData,
  };
};

const NoData = styled(CenterFlexRow)`
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media.mobile} {
    align-items: flex-start;
    margin: 0 20px 20px 20px;
  }
`;

const NoDataText = styled(Font16)`
  color: var(--secondary-text-color);
`;

const StyledHelpIcon = styled(svg.HelpIcon)`
  margin-right: 5px;
`;

const BodyContainer = styled.div`
  display: flex;
  gap: 2%;

  ${({ theme }) => theme.media.desktop} {
    flex-wrap: wrap;
  }

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const HeaderText = styled(ReportingCardHeader)`
  margin-bottom: 42px;
`;

const FooterText = styled(Font14)`
  display: block;
  margin-top: 20px;
  width: 100%;
  text-align: center;
  color: var(--secondary-text-color);
`;

const ChartContainer = styled.div`
  flex: 48%;
  margin-bottom: 20px;

  ${({ theme }) => theme.media.tablet} {
    margin-bottom: 20px;
    width: 100%;
    flex: 100%;
  }

  ${({ theme }) => theme.media.mobile} {
    margin-bottom: 10px;
    width: 100%;
    flex: 100%;
  }
`;
