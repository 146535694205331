import { SweepsDocument } from "@max/common";
import { SurveySubmission } from "@max/common/src/functions/setfan";
import {
  CollectionReference,
  DocumentReference,
  Query,
  QueryFieldFilterConstraint,
  QueryLimitConstraint,
  QueryOrderByConstraint,
  QueryStartAtConstraint,
  addDoc,
  arrayUnion,
  arrayRemove,
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { addToast } from "melodies-source/Toast";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";

export const useSetfanSweeps = ({ surveyId }: { surveyId?: string }) => {
  const [activeSweeps, setActiveSweeps] = useState<
    | (SweepsDocument & { id: string; ref: DocumentReference<SweepsDocument> })
    | null
  >(null);
  const [runningSweeps, setRunningSweeps] = useState(true);
  const [winnerBeingReplaced, setWinnerBeingReplaced] = useState<string>();
  const [sweepsCollection, loadingSweepsCollection] = useCollection(
    query(
      collection(
        getFirestore(),
        `sts3_surveys/${surveyId}/sweeps`,
      ) as CollectionReference<SweepsDocument>,
      orderBy("createdAt", "desc"),
      limit(1),
    ),
  );

  useEffect(() => {
    if (
      !loadingSweepsCollection &&
      sweepsCollection?.docs?.length &&
      !["cancelled", "error"].includes(sweepsCollection.docs[0].data().status)
    ) {
      setActiveSweeps({
        ...sweepsCollection.docs[0].data(),
        id: sweepsCollection.docs[0].id,
        ref: sweepsCollection.docs[0].ref,
      });
    } else {
      setActiveSweeps(null);
    }

    setRunningSweeps(false);
  }, [sweepsCollection, loadingSweepsCollection]);

  const sweepsWinnersQuery = ({
    seed,
    numberOfWinners,
    limitToUS,
    dir,
    exclusionList,
  }: {
    seed: number;
    numberOfWinners: number;
    limitToUS: boolean;
    dir: "asc" | "desc";
    exclusionList?: string[];
  }) => {
    const numToQuery = numberOfWinners + (exclusionList?.length ?? 0);
    const predicates: (
      | QueryOrderByConstraint
      | QueryLimitConstraint
      | QueryStartAtConstraint
      | QueryFieldFilterConstraint
    )[] = [orderBy("seed", dir), startAfter(seed), limit(numToQuery)];

    if (limitToUS) {
      predicates.unshift(where("responses.countryCode.value", "==", "US"));
    }

    return query(
      collection(getFirestore(), `sts3_surveys/${surveyId}/submissions_unique`),
      ...predicates,
    ) as Query<SurveySubmission>;
  };

  const generateWinners = async (props: {
    numberOfWinners: number;
    limitToUS: boolean;
    exclusionList?: string[];
  }) => {
    const seed = Math.random();
    try {
      const rawWinners = await Promise.all([
        getDocs(sweepsWinnersQuery({ ...props, seed, dir: "asc" })),
        getDocs(sweepsWinnersQuery({ ...props, seed, dir: "desc" })),
      ]);

      // combine queries and dedupe
      const combinedWinners = rawWinners.reduce((acc, docsRes) => {
        docsRes.docs.forEach((doc) => {
          if (!acc.some((win) => win === doc.id)) {
            // id is hashed email
            acc.push(doc.id);
          }
        });
        return acc;
      }, [] as string[]);

      // eliminate ids in the exclusion list as well as existing winners
      return combinedWinners
        .filter((win) => !props.exclusionList?.includes(win))
        .filter((win) => !activeSweeps?.winners.includes(win))
        .slice(0, props.numberOfWinners);
    } catch (err) {
      console.error(
        `there was an issue picking generating winners: ${
          (err as Error).message
        }`,
      );
      return [];
    }
  };

  const runNewSweeps = async (props: {
    numberOfWinners: number;
    limitToUS: boolean;
  }) => {
    setRunningSweeps(true);
    try {
      const winners = await generateWinners(props);

      // if not enough candidates remaining, alert user and cancel sweeps
      if (winners.length < props.numberOfWinners) {
        addToast(
          "Unable to find enough winners for sweepstakes. Cancelling.",
          "error",
        );
        return;
      }

      await addDoc(
        collection(getFirestore(), `sts3_surveys/${surveyId}/sweeps`),
        {
          surveyId,
          winners,
          numberOfWinners: props.numberOfWinners,
          limitToUS: props.limitToUS,
          createdAt: serverTimestamp(),
          status: "sampled",
        },
      );
    } catch (err) {
      console.error(`unable to run new sweeps: ${(err as Error).message}`);
    } finally {
      setRunningSweeps(false);
    }
  };

  const replaceWinner = async (replaceId: string) => {
    setWinnerBeingReplaced(replaceId);
    try {
      const existingWinners = activeSweeps.winners;
      const exclusionList = activeSweeps.fanExclusions ?? [];
      exclusionList.push(replaceId);

      const newWinners = await generateWinners({
        numberOfWinners: 1,
        limitToUS: activeSweeps.limitToUS,
        exclusionList,
      });

      if (!newWinners.length) {
        addToast(
          "unable to find a replacement for winner. Cancelling.",
          "error",
        );
        await cancelCurrentSweeps();
        return;
      }

      const replaceIdx = existingWinners.findIndex((ew) => ew === replaceId);
      if (replaceIdx === -1) {
        console.error("there was a problem finding winner to replace");
        return;
      }

      existingWinners[replaceIdx] = newWinners[0];
      await updateDoc(activeSweeps.ref, {
        winners: existingWinners,
        fanExclusions: exclusionList,
        // if the fan being replaced was currently "removed", remove them from the list
        removedFans: arrayRemove(replaceId),
      });
    } catch (err) {
      console.error(
        `there was a problem replacing winner ${replaceId}: ${
          (err as Error).message
        }`,
      );
    } finally {
      setWinnerBeingReplaced("");
    }
  };

  const removeWinner = async (removeId: string) => {
    setWinnerBeingReplaced(removeId);
    try {
      await updateDoc(activeSweeps.ref, {
        removedFans: arrayUnion(removeId),
      });
    } catch (err) {
      console.error(`there was an error removing fan ${removeId}`);
    } finally {
      setWinnerBeingReplaced("");
    }
  };

  const cancelCurrentSweeps = async () => {
    try {
      await updateDoc(
        doc(
          getFirestore(),
          `sts3_surveys/${surveyId}/sweeps/${activeSweeps.id}`,
        ),
        { status: "cancelled", cancelledAt: serverTimestamp() },
      );
    } catch (err) {
      console.error(
        `Unable to cancel current sweeps: ${(err as Error).message}`,
      );
    }
  };

  return {
    cancelCurrentSweeps,
    runNewSweeps,
    activeSweeps,
    removeWinner,
    replaceWinner,
    winnerBeingReplaced,
    loading: runningSweeps || loadingSweepsCollection,
    sweepsCollection,
  };
};
