import styled, { CSSProperties } from "styled-components";

const AlertIcon = ({ height }: { height?: number }) => {
  const svgHeight = height ?? 41;

  return (
    <svg
      width={svgHeight}
      height={svgHeight}
      viewBox={`0 0 41 41`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2 1.4717L23.3734 1.77632L40.6609 36.3514C40.8839 36.7974 41 37.2892 41 37.7879C41 39.4436 39.7472 40.8068 38.1379 40.9812L37.7879 41H3.21282C2.71416 41 2.22234 40.8839 1.77632 40.6609C0.295383 39.9204 -0.363597 38.1903 0.200152 36.6728L0.339816 36.3514L17.6273 1.77632C17.9382 1.15469 18.4422 0.650633 19.0638 0.339816C20.5448 -0.400652 22.3243 0.110234 23.2 1.4717ZM20.2071 31.042C18.7515 31.042 17.5714 32.222 17.5714 33.6777C17.5714 35.1334 18.7515 36.3134 20.2071 36.3134C21.6628 36.3134 22.8429 35.1334 22.8429 33.6777C22.8429 32.222 21.6628 31.042 20.2071 31.042ZM18.4501 11.7142H21.9644V27.5285H18.4501V11.7142Z"
        fill="#E45C52"
      />
    </svg>
  );
};

export default AlertIcon;
