import { SelectOption } from "Components/Select";

export const customPrizeOption: SelectOption = {
  label: "a custom prize",
  value: "a custom prize",
};

export const prizes: SelectOption[] = [
  {
    label: "Merch",
    options: [
      {
        label: "a tour poster",
        value: "a tour poster",
      },
      {
        label: "a t-shirt",
        value: "a t-shirt",
      },
      {
        label: "a merch bundle",
        value: "a merch bundle",
      },
      {
        label: "VIP pass in future",
        value: "VIP pass in future",
      },
    ],
  },
  {
    label: "Signed",
    options: [
      {
        label: "a signed poster",
        value: "a signed poster",
      },
      {
        label: "a signed setlist",
        value: "a signed setlist",
      },
    ],
  },
  {
    label: "Experiential",
    options: [
      {
        label: "an onstage shoutout",
        value: "an onstage shoutout",
      },
      {
        label: "a chance to dedicate a song",
        value: "a chance to dedicate a song",
      },
      {
        label: "a chance to watch the show from backstage",
        value: "a chance to watch the show from backstage",
      },
    ],
  },
  {
    label: "Meet & Greet",
    options: [
      {
        label: "a backstage meet & greet",
        value: "a backstage meet & greet",
      },
      {
        label: "a virtual meet & greet",
        value: "a virtual meet & greet",
      },
    ],
  },
  {
    label: "Other",
    options: [customPrizeOption],
  },
];
