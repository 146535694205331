import * as React from "react";
import { SVGProps } from "react";
export const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10,2 C14.418278,2 18,5.581722 18,10 C18,14.418278 14.418278,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 Z M10,3 C6.13400675,3 3,6.13400675 3,10 C3,13.8659932 6.13400675,17 10,17 C13.8659932,17 17,13.8659932 17,10 C17,6.13400675 13.8659932,3 10,3 Z M10.653277,13.6250838 L10.653277,15 L9.35306554,15 L9.35306554,13.6250838 L10.653277,13.6250838 Z M10.012685,5 C10.9217759,5 11.6469345,5.26156942 12.1881607,5.78470825 C12.7293869,6.30784708 13,6.94053208 13,7.68276325 C13,8.11200537 12.9048626,8.50771294 12.7145877,8.86988598 C12.5454545,9.19181757 12.2326625,9.57557416 11.7762117,10.0211557 L11.464819,10.317635 L11.464819,10.317635 L11.2341107,10.5448315 C11.0620045,10.7198399 10.9402748,10.8590431 10.8689218,10.9624413 C10.7547569,11.1278784 10.6701903,11.3179074 10.615222,11.5325285 C10.5681063,11.7164894 10.5380765,11.9990008 10.5251327,12.3800627 L10.5200846,12.5788062 L9.42283298,12.5788062 L9.41649049,12.2166331 L9.41649049,12.2166331 C9.41649049,11.7426783 9.47991543,11.3335569 9.60676533,10.9892689 C9.69978858,10.7299352 9.84989429,10.4683658 10.0570825,10.2045607 L10.1297623,10.116755 L10.1297623,10.116755 L10.3197782,9.90870392 L10.3197782,9.90870392 L10.569336,9.65740018 L10.569336,9.65740018 L10.8784355,9.36284373 C11.2737844,8.99396378 11.5306554,8.69997764 11.6490486,8.48088531 C11.7674419,8.26179298 11.8266385,8.02257992 11.8266385,7.76324614 C11.8266385,7.29376258 11.653277,6.88128773 11.3065539,6.5258216 C10.9598309,6.17035547 10.5348837,5.9926224 10.0317125,5.9926224 C9.54545455,5.9926224 9.13953488,6.1535882 8.81395349,6.47551979 C8.5245478,6.7616812 8.32367604,7.19092332 8.21133819,7.76324614 L8.17336152,7.98457411 L7,7.83702213 L7.03399263,7.59235856 C7.16451173,6.79455059 7.46713435,6.1741154 7.94186047,5.73105298 C8.4640592,5.24368433 9.15433404,5 10.012685,5 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
