import React, { useEffect } from "react";
import { useUser } from "auth";

export const Signout: React.FC = () => {
  const { clearSession } = useUser();

  useEffect(() => {
    clearSession();
  }, []);

  return null;
};
