import { collection, getFirestore, query, where } from "firebase/firestore";
import { useArtistContext } from "Components";
import { useCollection } from "react-firebase-hooks/firestore";
import { useEffect, useState } from "react";
import { useUser } from "auth";

type SweepsConversation = {
  unread: number;
  name: string;
};

/**
 * this hook returns a map of conversation participant uids to conversation metadata
 * @returns
 */
const useSweepsConversations = (sweepsId: string) => {
  const [sweepsConversations, setSweepsConversations] = useState<
    Record<string, SweepsConversation>
  >({});
  const { id: artistGroupId } = useArtistContext();
  const {
    user: { uid },
  } = useUser();

  const conversationsQuery = query(
    collection(getFirestore(), `conversations`),
    where("artistGroupId", "==", artistGroupId),
    where("metadata.sweepsId", "==", sweepsId),
    where("metadata.memberUids", "array-contains", uid),
  );
  const [conversations, loading, error] = useCollection(conversationsQuery);

  useEffect(() => {
    if (conversations) {
      setSweepsConversations(
        conversations.docs.reduce((acc, conversation) => {
          let unread = 0;
          if (
            typeof conversation.data().messages === "number" &&
            typeof conversation.data().readCount === "number"
          ) {
            unread =
              conversation.data().messages - conversation.data().readCount;
          }
          acc[conversation.id] = {
            unread,
            name: conversation.data().user.displayName,
          };
          return acc;
        }, {}),
      );
    }
  }, [conversations]);

  if (error) {
    console.log(error);
  }

  return { conversations: sweepsConversations, loading };
};

export default useSweepsConversations;
