import { Dispatch, PropsWithChildren } from "react";
import {
  SurveyModulesDispatch,
  SurveyModulesState,
} from "../../../hooks/useSurveyModules";
import { Modal, ModalType } from "melodies-source/Modal";
import { useScrollLockContext } from "contexts/ScrollLockContext";
import { CenterFlexRow } from "Routes/styled";
import { Body1, H2 } from "melodies-source/Text";
import styled from "styled-components";
import { SurveyQuestionModule } from "@max/common/dist/setfan";

export type QuestionType =
  | "freeResponseQuestion"
  | "imageQuestion"
  | "multipleChoiceQuestion"
  | "ratingQuestion"
  | "image"
  | "text";

export type QuestionModalProps<T = SurveyQuestionModule> = {
  isOpen: boolean;
  onClose: () => void;
  dispatch: Dispatch<SurveyModulesDispatch>;
  state: SurveyModulesState;
  existing?: T;
};

export type QuestionTypeMeta = {
  icon: JSX.Element;
  key: QuestionType;
  header: string;
  subHeader: string;
  body: (props: Omit<QuestionModalProps, "state">) => JSX.Element;
  variant?: ModalType;
};

export const BaseQuestionModal = (
  props: PropsWithChildren<QuestionModalProps & QuestionTypeMeta>,
) => {
  const { unlockScroll } = useScrollLockContext();
  const BodyComponent = props.body;

  return (
    <Modal
      isOpen={props.state.isOpen}
      onClose={() => {
        unlockScroll();
        props.dispatch({ type: "closeModal" });
      }}
      variant={props.variant ?? "large"}
      withBackdropClose={false}
      header={
        <div style={{ marginBottom: 17 }}>
          <CenterFlexRow style={{ marginBottom: 8 }}>
            {props.icon && <IconContainer>{props.icon}</IconContainer>}
            <H2 style={{ color: "#1B0076" }}>{props.header}</H2>
          </CenterFlexRow>
          {props.subHeader && (
            <Body1 style={{ color: "#333" }}>{props.subHeader}</Body1>
          )}
        </div>
      }
    >
      <BodyComponent {...props} />
    </Modal>
  );
};

const IconContainer = styled.div`
  margin-right: 8px;

  & > svg {
    display: block;
  }
`;
