import styled, { css } from "styled-components";
import { useParams } from "react-router-dom";
import { LinkItem, Link } from "./Components/Link";
import { SvgGear } from "melodies-source/Svgs/Gear";
import { SvgHelp } from "melodies-source/Svgs/Help";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const AppSecondaryLinks = () => {
  const { artistId } = useParams<{ artistId: string }>();
  const { customApp } = useCustomAppContext();

  const links: LinkItem[] = [
    {
      icon: <SvgGear />,
      label: "Settings",
      to: `/${artistId}/settings`,
    },
    ...(!customApp
      ? [
          {
            icon: <SvgHelp />,
            label: "Help Center",
            to: `/${artistId}/help-center`,
          },
        ]
      : []),
  ];

  return (
    <Container>
      {links.map((link) => (
        <Link key={link.label} {...link} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  box-shadow: inset 0 -1px 0 0 var(--box-shadow-color);
  padding: 14px 20px 14px 0;
`;
