import React, { PropsWithChildren, useState } from "react";
import styled, { DefaultTheme } from "styled-components";
import { SvgCloseLarge } from "../Svgs/CloseLarge";
import { H2 } from "../Text";
import { Portal } from "../common/Portal";
import { useClickOutside, useOverlay } from "../utils/hooks";

const Divider = styled.div`
  background: #e6e9eb;
  height: 1px;
  width: 100%;
  margin: 12px 0;
`;

export const ModalWrapper = styled.div<{ variant?: ModalType }>`
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: ${(p) => (p.variant === "fullscreen" ? "" : "0 30px")};
  position: fixed;
  display: block;
  z-index: 500;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 500;
  background: black;
  opacity: 0.8;
  pointer-events: none;
`;

export const ModalContainer = styled.div<{
  variant?: ModalType;
  $withViewportMaxHeight?: boolean;
}>`
  display: flex;
  flex-direction: column;
  background: var(--component-background-color);
  padding: 34px 40px;
  margin: ${(p) => (p.variant === "fullscreen" ? "" : "max(44px, 8vh) auto")};
  flex-shrink: 0;
  border-radius: ${(p) => (p.variant === "fullscreen" ? "0px" : "12px")};
  min-height: ${(p) => (p.variant === "fullscreen" ? "100dvh" : "")};
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  position: relative;
  max-height: ${(p) =>
    p.$withViewportMaxHeight &&
    p.variant !== "fullscreen" &&
    "calc(100vh - calc(max(44px, 8vh)*2))"};

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30px;
    margin: ${(p) => (p.variant === "fullscreen" ? "" : "30px auto")};
    max-height: ${(p) =>
      p.$withViewportMaxHeight &&
      p.variant !== "fullscreen" &&
      "calc(100vh - calc(30px*2))"};
  }
`;

export const ModalCloseIcon = styled.div`
  height: 44px;
  width: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 150ms cubic-bezier(0, 0, 0.3, 1);
  cursor: pointer;
  margin: -4px 0 -4px auto;
  color: var(--text-color);
  flex-shrink: 0;

  &:hover {
    background-color: var(--hover-background-color);
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    height: 36px;
    width: 36px;
  }
`;

export const ModalHeaderContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 20px;

  ${H2} {
    color: var(--header-text-color);
  }
`;

export const ModalHeaderTitle = styled(H2)<{
  color?: keyof DefaultTheme["colors"];
}>`
  color: ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.black)};

  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 1;
`;

export const ModalDivider = styled(Divider)`
  width: auto;
  margin: 0 -40px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 0 -30px;
  }
`;

export const ModalHeaderClose = ({ onClose }: Pick<ModalProps, "onClose">) => {
  return (
    <ModalCloseIcon onClick={onClose}>
      <SvgCloseLarge />
    </ModalCloseIcon>
  );
};

const ModalTypes = ["small", "medium", "large", "fullscreen"] as const;
export type ModalType = (typeof ModalTypes)[number];

type ModalWidths = {
  [K in ModalType]?: any;
};

const modalWidths: ModalWidths = {
  small: 476,
  medium: 676,
  large: 876,
  fullscreen: "100%",
};

export type ModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  header?: React.ReactElement | string;
  headerColor?: keyof DefaultTheme["colors"];
  variant?: ModalType;
  className?: string;
  style?: React.CSSProperties;
  withViewportMaxHeight?: boolean;
  withCloseIcon?: boolean;
  withBackdropClose?: boolean;
};

export const Modal = ({
  isOpen,
  onClose,
  header,
  headerColor,
  variant = "small",
  children,
  className,
  style,
  withViewportMaxHeight,
  withCloseIcon = true,
  withBackdropClose = true,
}: PropsWithChildren<ModalProps>) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  useOverlay(!!isOpen);
  useClickOutside(ref, onClose && withBackdropClose ? onClose : () => {});

  return (
    <>
      {isOpen && (
        <Portal>
          <div className={className}>
            <ModalBackdrop />
            <ModalWrapper variant={variant}>
              <ModalContainer
                ref={setRef}
                variant={variant}
                $withViewportMaxHeight={withViewportMaxHeight}
                style={{ maxWidth: modalWidths[variant], ...style }}
              >
                {(header || (onClose && withCloseIcon)) && (
                  <ModalHeaderContainer>
                    {header && (
                      <div>
                        {typeof header === "string" ? (
                          <ModalHeaderTitle color={headerColor}>
                            {header}
                          </ModalHeaderTitle>
                        ) : (
                          header
                        )}
                      </div>
                    )}
                    {onClose && withCloseIcon && (
                      <ModalHeaderClose onClose={onClose} />
                    )}
                  </ModalHeaderContainer>
                )}
                {children}
              </ModalContainer>
            </ModalWrapper>
          </div>
        </Portal>
      )}
    </>
  );
};
