import * as React from "react";
import { SVGProps } from "react";
export const SvgRefreshLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12,11 L7,6.5 L12,2 L12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 C7.66508574,21 4.13545713,17.5521622 4.00380682,13.2491793 L4,13 L7,13 C7,15.7614237 9.23857625,18 12,18 C14.7614237,18 17,15.7614237 17,13 C17,10.2385763 14.7614237,8 12,8 L12,11 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
