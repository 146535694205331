import { EntityCountry } from "./types";

export const countries: EntityCountry[] = [
  { id: "US", label: "United States" },
  { id: "CA", label: "Canada" },
  { id: "AR", label: "Argentina" },
  { id: "AU", label: "Australia" },
  { id: "AT", label: "Austria" },
  { id: "BE", label: "Belgium" },
  { id: "BR", label: "Brazil" },
  { id: "CL", label: "Chile" },
  { id: "CN", label: "China" },
  { id: "CO", label: "Colombia" },
  { id: "CR", label: "Costa Rica" },
  { id: "HR", label: "Croatia" },
  { id: "CZ", label: "Czech Republic" },
  { id: "DK", label: "Denmark" },
  { id: "EC", label: "Ecuador" },
  { id: "FI", label: "Finland" },
  { id: "FR", label: "France" },
  { id: "DE", label: "Germany" },
  { id: "GH", label: "Ghana" },
  { id: "GR", label: "Greece" },
  { id: "HK", label: "Hong Kong" },
  { id: "HU", label: "Hungary" },
  { id: "IN", label: "India" },
  { id: "ID", label: "Indonesia" },
  { id: "IT", label: "Italy" },
  { id: "JP", label: "Japan" },
  { id: "KE", label: "Kenya" },
  { id: "KR", label: "Korea (Republic of)" },
  { id: "MO", label: "Macao" },
  { id: "MY", label: "Malaysia" },
  { id: "MX", label: "Mexico" },
  { id: "NL", label: "Netherlands" },
  { id: "NZ", label: "New Zealand" },
  { id: "NG", label: "Nigeria" },
  { id: "NO", label: "Norway" },
  { id: "PE", label: "Peru" },
  { id: "PH", label: "Philippines" },
  { id: "PL", label: "Poland" },
  { id: "PT", label: "Portugal" },
  { id: "RO", label: "Romania" },
  { id: "RU", label: "Russian Federation" },
  { id: "RS", label: "Serbia" },
  { id: "SG", label: "Singapore" },
  { id: "SK", label: "Slovakia" },
  { id: "SI", label: "Slovenia" },
  { id: "ZA", label: "South Africa" },
  { id: "ES", label: "Spain" },
  { id: "SE", label: "Sweden" },
  { id: "CH", label: "Switzerland" },
  { id: "TW", label: "Taiwan" },
  { id: "TH", label: "Thailand" },
  { id: "TR", label: "Turkey" },
  { id: "AE", label: "United Arab Emirates" },
  { id: "GB", label: "United Kingdom" },
  { id: "VE", label: "Venezuela" },
  { id: "VN", label: "Vietnam" },
];
