const XIcon = () => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.98258 0.221122L2.04967 0.280366L5.81909 4.04923L9.58851 0.280366L9.6556 0.221122C9.93497 0.00389346 10.3389 0.0236415 10.5957 0.280366C10.8524 0.537091 10.8721 0.941053 10.6549 1.22043L10.5957 1.28752L6.8268 5.05694L10.5957 8.82636C10.8738 9.10448 10.8738 9.5554 10.5957 9.83352C10.3389 10.0902 9.93497 10.11 9.6556 9.89276L9.58851 9.83352L5.81909 6.06466L2.04967 9.83352L1.98258 9.89276C1.7032 10.11 1.29924 10.0902 1.04251 9.83352C0.785787 9.57679 0.766039 9.17283 0.983268 8.89345L1.04251 8.82636L4.81137 5.05694L1.04251 1.28752C0.764394 1.0094 0.764394 0.558484 1.04251 0.280366C1.29924 0.0236415 1.7032 0.00389346 1.98258 0.221122Z"
        fill="white"
      />
    </svg>
  );
};

export default XIcon;
