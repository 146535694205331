import { SetLiveEvent } from "models/event";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ProxySearchParams, useTypesenseProxyContext } from "contexts/TypesenseProxyContext";
import { useArtistContext } from 'Components';

export const useNextShow = () => {

  const [nextShow, setNextShow] = useState<SetLiveEvent>(null);
  const [loading, setLoading] = useState(true);

  const { id } = useArtistContext();

  const { proxySearch } = useTypesenseProxyContext();

  const searchParameters: ProxySearchParams = useMemo(
    () => ({
      q: "*",
      query_by: "",
      sort_by: "startsAt:asc",
      limit_hits: '1',
      filter_by: `(groupId:="${id}" || additionalGroups:=["${id}"]) && endedAt:0 && status:published && deletedAt:0`,
      page: 1,
      per_page: 1,
    }),
    [id],
  );

  const getNextShow = useCallback(
    async () => {
      if (!proxySearch || !id) return;
      const { hits } = await proxySearch(
        'setlive_events',
        searchParameters,
      ) || {};

      if (hits?.length) {
        setNextShow(hits[0].document);
      }

      setLoading(false);
    },
    [proxySearch, searchParameters, id],
  );

  useEffect(() => {
    setLoading(true);
    if (proxySearch) {
      getNextShow();
    }
  }, [proxySearch, getNextShow]);

  return { nextShow, loading };
};
