export const MusicIcon = (props) => {
  return (
    <svg
      width="28"
      height="27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.35.3v18.857c-.016 1.161-1.378 2.498-3.45 3.247-2.508.906-5.107.61-5.805-.663-.698-1.272.77-3.038 3.277-3.945 1.467-.53 2.965-.648 4.095-.4l-.003-9.365-15.085 1.51v13.388c-.017 1.16-1.378 2.497-3.451 3.246-2.508.906-5.106.61-5.804-.663-.698-1.272.77-3.038 3.277-3.944 1.467-.53 2.964-.649 4.095-.401L8.493 2.186 27.35.3Zm-1.886 2.074L10.38 3.883v3.771l15.085-1.508V2.374Z"
        fill="#fff"
      />
    </svg>
  );
};
