import { Font14 } from "Routes/styled";
import React from "react";
import styled from "styled-components";

export type DataType = "index" | "actual";

interface Props {
  value: DataType;
  setValue: React.Dispatch<React.SetStateAction<DataType>>;
  className?: string;
}

export const ChartSelect: React.FC<Props> = ({
  className,
  value,
  setValue,
}) => {
  return (
    <TabsContainer className={className}>
      <LeftTab onClick={() => setValue("index")} selected={value === "index"}>
        <TabFont selected={value === "index"}>Index value</TabFont>
      </LeftTab>
      <RightTab
        onClick={() => setValue("actual")}
        selected={value === "actual"}
      >
        <TabFont selected={value === "actual"}>Actual value</TabFont>
      </RightTab>
    </TabsContainer>
  );
};

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TabBase = styled.div<{ selected: boolean }>`
  background: ${({ selected }) =>
    selected ? "var(--main-color)" : "var(--component-background--color)"};
  border: ${({ selected }) =>
    selected
      ? "1px solid var(--main-color)"
      : "1px solid var(--secondary-text-color)"};

  height: 31px;
  width: 123px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: ${({ selected }) => (selected ? "default" : "pointer")};

  ${({ theme }) => theme.media.mobile} {
    width: 50%;
  }
`;

const LeftTab = styled(TabBase)`
  border-radius: 30px 0px 0px 30px;
`;

const RightTab = styled(TabBase)`
  border-radius: 0 30px 30px 0;
`;

const TabFont = styled(Font14)<{ selected: boolean }>`
  color: ${({ selected }) =>
    selected
      ? "var(--primary-button-text-color)"
      : "var(--secondary-text-color)"};
`;
