const Calendar = ({ fillColor }: { fillColor?: string }) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.575 4.05005C19.9478 4.05005 20.25 4.35226 20.25 4.72505V5.40005H21.6C22.2923 5.40005 22.8629 5.9212 22.9409 6.59261L22.95 6.75005V21.6C22.95 22.3456 22.3456 22.9501 21.6 22.9501H5.4C4.65442 22.9501 4.05 22.3456 4.05 21.6V6.75005C4.05 6.00446 4.65442 5.40005 5.4 5.40005H6.75V4.72505C6.75 4.35226 7.05221 4.05005 7.425 4.05005C7.7978 4.05005 8.1 4.35226 8.1 4.72505V5.40005H18.9V4.72505C18.9 4.35226 19.2022 4.05005 19.575 4.05005ZM21.6 10.8H5.4V21.6H21.6V10.8ZM20.25 17.55V20.25H17.55V17.55H20.25Z"
        fill={fillColor ?? "var(--secondary-color)"}
      />
    </svg>
  );
};

export default Calendar;
