import { SurveyConfig, SurveyConfigModule } from "@max/common";
import {
  QuestionOptions,
  Question,
} from "Routes/Reporting/SetFan/Components/Sweeps/model";
import { DocumentReference, doc, getFirestore } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";

/**
 * consumes a survey config for a corresponding survey id, pulls out questions
 * for types we want to display (TextQuestion, SingleSelect, etc) and creates a map
 * by question id
 * @returns
 */
const useSurveyQuestions = (surveyId: string) => {
  const [surveyQuestions, setSurveyQuestions] =
    useState<Record<string, Question>>();

  const [surveyConfig] = useDocumentData(
    doc(
      getFirestore(),
      `sts3_surveys/${surveyId}/versions/prod`,
    ) as DocumentReference<SurveyConfig>,
  );

  useEffect(() => {
    if (surveyConfig) {
      const questions = getQuestions(surveyConfig);
      setSurveyQuestions(questions);
    }
  }, [surveyConfig]);

  return { surveyQuestions };
};

export default useSurveyQuestions;

const getQuestions = (survey?: SurveyConfig): Record<string, Question> => {
  const questions: Record<string, Question> = {};

  if (survey) {
    Object.entries(flattenModules(survey.pages)).forEach(([id, data]) => {
      if (data.type === "TextQuestion") {
        questions[id] = {
          type: data.type,
          label: data.header?.children || "",
          required: !!data.validation,
        };
      }
      if (data.type === "SingleSelectQuestion") {
        questions[id] = {
          type: data.type,
          label: data.header?.children || "",
          required: !!data.validation,
          options: data.options?.reduce((acc, opt) => {
            acc[opt.id] = {
              label: opt.label || "",
              userDefined: opt.userDefined,
            };
            return acc;
          }, {} as QuestionOptions),
        };
        if (!data.validation) {
          (questions[id].options as QuestionOptions)["no-response"] = {
            label: "No response",
          };
        }
      }
      if (data.type === "MultipleSelectQuestion" && data.options) {
        questions[id] = {
          type: data.type,
          label: data.header?.children || "",
          required: !!data.validation,
          options: data.options?.reduce((acc, opt) => {
            acc[opt.id] = {
              label: opt.label || "",
              userDefined: opt.userDefined,
            };
            return acc;
          }, {} as QuestionOptions),
          multi: true,
        };
        if (!data.validation) {
          (questions[id].options as QuestionOptions)["no-response"] = {
            label: "No response",
          };
        }
      }
      if (data.type === "SongsQuestion" && data.options) {
        questions[id] = {
          type: data.type,
          label: data.header?.children || "",
          required: !!data.validation,
          options: data.options?.reduce((acc, album) => {
            album.options?.forEach((song) => {
              acc[song.id] = {
                label: song.label || "",
                src: song.src || null,
              };
            });
            return acc;
          }, {} as QuestionOptions),
          multi: true,
        };
        if (!data.validation) {
          (questions[id].options as QuestionOptions)["no-response"] = {
            label: "No response",
          };
        }
      }
      if (data.type === "RankedQuestion") {
        questions[id] = {
          type: data.type,
          label: data.header?.children || "",
          required: !!data.validation,
          options: Array.from(
            { length: Number(data.rankRange) },
            (_, i) => i + 1,
          ).reduce((acc, opt) => {
            let label = `${opt}`;

            if (opt === 1) {
              label = `${opt} (${data.rankLow})`;
            }
            if (opt === data.rankRange) {
              label = `${opt} (${data.rankHigh})`;
            }

            acc[opt.toString()] = {
              label,
            };
            return acc;
          }, {} as QuestionOptions),
        };
        if (!data.validation) {
          (questions[id].options as QuestionOptions)["no-response"] = {
            label: "No response",
          };
        }
      }
      if (data.type === "Autocomplete") {
        questions[id] = {
          type: data.type,
          label: data.header?.children || "",
          required: !!data.validation,
          options: data.options?.reduce((acc, opt) => {
            acc[opt.id] = {
              label: opt.label || "",
              userDefined: opt.userDefined,
            };
            return acc;
          }, {} as QuestionOptions),
        };
        if (!data.validation) {
          (questions[id].options as QuestionOptions)["no-response"] = {
            label: "No response",
          };
        }
      }
      if (data.type === "ImageQuestion") {
        questions[id] = {
          type: data.type,
          label: data.header?.children || "",
          required: !!data.validation,
          options: data.options?.reduce((acc, opt) => {
            acc[opt.id] = {
              label: opt.label || "",
              src: opt.src || "",
              userDefined: opt.userDefined,
            };
            return acc;
          }, {} as QuestionOptions),
        };
        if (!data.validation) {
          (questions[id].options as QuestionOptions)["no-response"] = {
            label: "No response",
          };
        }
      }
    });
  }

  return questions;
};

const flattenModules = (
  modules: SurveyConfigModule[],
  flattened: [string, Omit<SurveyConfigModule, "id" | "modules">][] = [],
): Record<string, Omit<SurveyConfigModule, "id" | "modules">> => {
  modules.forEach((m) => {
    const { id, modules, ...mod } = m;
    id && flattened.push([id, mod]);
    flattenModules(modules || [], flattened);
  });
  return Object.fromEntries(flattened);
};
