import { useState } from "react";
import { Appearance } from "../../slices/Appearance";
import { Contest } from "../../slices/Contest";
import { SurveyInfoContent } from "../../slices/SurveyInfoContent";
import styled from "styled-components";
import { SectionContainer } from "Routes/SetFan/editor/Components/SectionContainer";
import { ReactComponent as IconPalette } from "assets/svg/palette.svg";
import { ReactComponent as IconTrophy } from "assets/svg/trophy-large.svg";
import { ContestTitleAddon } from "Routes/SetFan/slices/ContestTitleAddon";
import { useConfigFlags } from "Routes/SetFan/Components/BuilderContext";

export const SurveyInfo = () => {
  const { flags } = useConfigFlags();
  const [showAppearance, setShowAppearance] = useState(false);
  const [showContest, setShowContest] = useState(
    flags.showContestConfirmationMessage ?? false,
  );

  return (
    <>
      <SurveyInfoContent isWizard />
      <Sections>
        <SectionContainer
          icon={<IconPalette />}
          title="Appearance"
          slice={Appearance}
          active={showAppearance}
          lastIndex={true}
          onClick={() => setShowAppearance((p) => !p)}
        />
        <SectionContainer
          icon={<IconTrophy />}
          title="Sweepstakes"
          titleAddon={ContestTitleAddon}
          slice={Contest}
          active={showContest}
          lastIndex={true}
          onClick={() => setShowContest((p) => !p)}
        />
      </Sections>
    </>
  );
};

const Sections = styled.div`
  border-top: 1px solid #e6e9eb;
  margin: 40px -45px -28px -45px;
`;
