import React, { useState } from "react";
import { TextInput } from "melodies-source/TextInput";
import { Body2, H2 } from "melodies-source/Text";
import { ReactComponent as KeyIcon } from "assets/svg/key.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";
import styled from "styled-components";
import { Button } from "melodies-source/Button";
import { Card } from "melodies-source/Card";
import * as yup from "yup";
import { yupPasswordConfirmation, yupStrictPassword } from "Utils/yup";
import { FormikConfig, useFormik } from "formik";
import { StepProps } from "Routes/Dashboard/Components";
import { useUser } from "auth";

const INITIAL_VALUES = {
  password: "",
  passwordConfirm: "",
};

const VALIDATION_SCHEMA = yup.object().shape({
  password: yupStrictPassword,
  passwordConfirm: yupPasswordConfirmation,
});

export const Password: React.FC<StepProps> = ({ handleNext, handleBack }) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: hanldePasswordSet,
  });
  const { updatePassword } = useUser();

  async function hanldePasswordSet(
    ...params: Parameters<FormikConfig<typeof INITIAL_VALUES>["onSubmit"]>
  ) {
    const [{ password }] = params;

    setLoading(true);
    try {
      updatePassword(password);
      handleNext();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <StyledCard isElevated>
      <Container>
        <H2>Please create a password for your account.</H2>
        <Form onSubmit={formik.handleSubmit}>
          <TextInput
            label="Password"
            value={formik.values.password}
            onChange={formik.handleChange("password")}
            type="password"
            leftIcon={<KeyIcon />}
            {...(formik.errors.password && {
              hasError: true,
              helperText: formik.errors.password,
            })}
          />
          <TextInput
            label="Confirm Password"
            value={formik.values.passwordConfirm}
            onChange={formik.handleChange("passwordConfirm")}
            type="password"
            leftIcon={<KeyIcon />}
            {...(formik.errors.passwordConfirm && {
              hasError: true,
              helperText: formik.errors.passwordConfirm,
            })}
          />
          <Body2>
            Password must be at least 8 characters, contain at least one special
            character, one letter, and one number
          </Body2>
          <Actions>
            <Button variant="secondary" onClick={handleBack}>
              Back
            </Button>
            <Button
              type="submit"
              rightIcon={<ArrowRightIcon />}
              loading={loading}
            >
              Next
            </Button>
          </Actions>
        </Form>
      </Container>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: min(500px, 90vw);
`;

const Container = styled.div`
  gap: 20px;

  ${H2} {
    margin-bottom: 20px;
  }

  ${H2},${Body2} {
    text-align: center;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  gap: 15px;
`;
