import React from "react";
import { H1, H2, H3, H4 } from "melodies-source/Text";
import styled from "styled-components";
import { InfoBars } from "Components/InfoBars";
import { Item as DonutChartItem, DonutChart } from "Components/DonutChart";
import { RefreshIcon } from "Components/RefreshIcon";
import { Spinner } from "melodies-source/Spinner";
import { Card } from "Components/Card";
import { useArtistEventContext } from "contexts/ArtistEventContext";
import { TwoColumns } from "Components";
import { formatNumber } from "Utils/format";

export const Clicks: React.FC = () => {
  const { clicks, clicksLoading, clicksError } = useArtistEventContext();

  const categoriesTotal = clicks?.reduce(
    (total, item) => total + item.total,
    0,
  );

  const data: DonutChartItem[] = clicks?.map(({ name, total }) => ({
    id: name,
    label: name,
    value: Math.round((total / categoriesTotal) * 100),
  }));

  const categories = clicks?.map(({ name, detail }) => (
    <Category>
      <CategoryName>Clicks: {name}</CategoryName>
      <InfoBars
        data={detail.map(({ name, count }) => ({
          label: name,
          value: count,
        }))}
      />
    </Category>
  ));

  return (
    <StyledCard isElevated>
      <H3>Total Clicks</H3>
      {clicksLoading ? (
        <StyledSpinner />
      ) : clicksError ? (
        <StyledRefreshIcon />
      ) : (
        <>
          <Total hasMarginBottom={categoriesTotal > 0}>
            {formatNumber(categoriesTotal)}
          </Total>
          {categoriesTotal > 0 && (
            <>
              <DonutChart data={data} />
              <H2>Click Details</H2>
              <TwoColumns gap="30px">{categories}</TwoColumns>
            </>
          )}
        </>
      )}
    </StyledCard>
  );
};

const StyledRefreshIcon = styled(RefreshIcon)`
  margin-top: 5px;
`;

const StyledSpinner = styled(Spinner)`
  margin: 20px 0 15px;
  transform: scale(0.7);
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  ${H3} {
    color: var(--secondary-text-color);
  }
  ${H2} {
    margin: 40px 0 20px;
    text-align: center;
  }
`;

interface TotalProps {
  hasMarginBottom: boolean;
}

const Total = styled(H1)<TotalProps>`
  ${(props) => props.hasMarginBottom && "margin-bottom: 20px;"};
`;

const CategoryName = styled(H4)`
  color: var(--header-text-color);
`;

const Category = styled.div`
  text-align: left;
  width: 100%;
`;
