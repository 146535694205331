import { DefaultLayout, useArtistContext } from "Components";
import { GlobalStyles } from "styled";
import { StatusCards, AppCards, ServiceCards, Welcome } from "./Components";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { Modal } from "melodies-source/Modal";

export const Dashboard = () => {
  const { showWelcome, hideWelcome } = useArtistContext();
  const { customApp } = useCustomAppContext();

  return (
    <DefaultLayout>
      <GlobalStyles grayBG />
      <StatusCards />
      <AppCards />
      {!customApp && (
        <>
          <ServiceCards />
          <Modal
            isOpen={showWelcome}
            onClose={() => hideWelcome()}
            withCloseIcon={false}
            variant="large"
          >
            <Welcome />
          </Modal>
        </>
      )}
    </DefaultLayout>
  );
};
