import { SVGProps } from "react";
const ContactsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="#1C0D6A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.7,33.7c9.5,0,17.2,7.7,17.2,17.2H25.9c0-9.5,7.7-17.2,17.2-17.2H51.7z M25.9,33.7c1.3,0,2.6,0.1,3.8,0.4
        c-4.9,4-8.1,10-8.1,16.8H0c0-9.5,7.7-17.2,17.2-17.2H25.9z M51.7,38h-8.6c-5.6,0-10.4,3.6-12.2,8.6h33C62.2,41.6,57.4,38,51.7,38z
         M20.7,38h-3.5C11.6,38,6.8,41.6,5,46.6h12.6C18.1,43.5,19.2,40.6,20.7,38z M21.6,12.1c4.8,0,8.6,3.9,8.6,8.6s-3.9,8.6-8.6,8.6
        s-8.6-3.9-8.6-8.6S16.8,12.1,21.6,12.1z M47.4,12.1c4.8,0,8.6,3.9,8.6,8.6s-3.9,8.6-8.6,8.6s-8.6-3.9-8.6-8.6S42.7,12.1,47.4,12.1z
         M21.6,16.4c-2.4,0-4.3,1.9-4.3,4.3s1.9,4.3,4.3,4.3s4.3-1.9,4.3-4.3S23.9,16.4,21.6,16.4z M47.4,16.4c-2.4,0-4.3,1.9-4.3,4.3
        s1.9,4.3,4.3,4.3c2.4,0,4.3-1.9,4.3-4.3S49.8,16.4,47.4,16.4z"
      />
    </svg>
  );
};

export default ContactsIcon;
