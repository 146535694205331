import * as d3 from "d3";
import { useEffect, useRef } from "react";
import {
  DMAMapData,
  LegendBar,
  LegendContainer,
  LegendLabel,
  MapContainer,
} from "../common";
import { features as usFeatures } from "./us.json";
import { useCustomAppContext } from "contexts/CustomAppContext";

type Props = {
  width: number;
  data: DMAMapData[];
};
export const StateMap = ({ width, data: dma }: Props) => {
  const container = useRef();
  const { customApp } = useCustomAppContext();

  useEffect(() => {
    const height = (width * 5) / 8;

    ///@ts-ignore-next-line
    container.current.replaceChildren();
    const projection = d3
      .geoAlbersUsa()
      .translate([width / 2, height / 2])
      .scale(120 + width);

    const path = d3.geoPath().projection(projection);

    const paint = d3
      .scaleLinear()
      ///@ts-ignore-next-line
      .range([
        "rgba(60,0,255,0)",
        customApp?.theme.colors.main || "rgba(60,0,255,1)",
      ])
      .domain([0, 4]);

    const svg = d3
      .select(container.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const tooltipDiv = d3
      .select(container.current)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    svg
      .selectAll("path")
      .data(usFeatures)
      .enter()
      .append("path")
      ///@ts-ignore-next-line
      .attr("d", path)
      .style("stroke", "#fff")
      .style("stroke-width", "1")
      .style("fill", "#DAE0E3");

    svg
      .selectAll("circle")
      .data(dma)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return projection([d.lon, d.lat])[0];
      })
      .attr("cy", function (d) {
        return projection([d.lon, d.lat])[1];
      })
      .attr("r", function (d) {
        return (d.audience_size + 1) ** 1.9 + 1;
      })
      .style("fill", (d) => paint(d.value))
      .style("opacity", 0.85)
      .on("mouseover", function () {
        tooltipDiv.transition().duration(200).style("opacity", 0.9);
        const bbox = this.getBBox();
        tooltipDiv
          ///@ts-ignore-next-line
          .text(this.__data__.title)
          .style("left", bbox.x + bbox.width / 2 + "px")
          .style("top", bbox.y + 25 + "px");
      })
      .on("mouseout", function () {
        tooltipDiv.transition().duration(500).style("opacity", 0);
      });
  }, [dma, width]);

  return <MapContainer ref={container} />;
};
export const StateLegend = () => {
  return (
    <LegendContainer>
      <LegendBar />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "280px",
        }}
      >
        <LegendLabel>Fewer Fans</LegendLabel>
        <LegendLabel>More Fans</LegendLabel>
      </div>
    </LegendContainer>
  );
};
