import styled, { CSSProperties } from "styled-components";
import { useDesktopMediaQuery } from "hooks";
import { AppCardButton } from "./AppCardButton";
import { Font15, Font20 } from "Routes/styled";
import { desktopImages } from "../desktopImages";
import { mobileImages } from "../mobileImages";
import { svg } from "svg";
import { useState } from "react";
import SetFanInterestModal from "../SetFanInterestModal";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, getFirestore } from "firebase/firestore";
import { useArtistContext } from "Components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useScrollLockContext } from "contexts/ScrollLockContext";
import { useCustomAppContext } from "contexts/CustomAppContext";

const AppCardNoData = ({
  bgImg,
  overlayImg,
  headerIcon,
  bodyText,
  button,
  onButtonClick,
  className,
  loading,
  sx,
}: {
  bgImg?: JSX.Element;
  overlayImg?: JSX.Element;
  headerIcon: JSX.Element;
  bodyText: String;
  button: string | JSX.Element;
  onButtonClick?: () => void;
  className?: string;
  loading?: boolean;
  sx?: CSSProperties;
}) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <SkeletonTheme baseColor="transparent">
      <AppCardContainer style={sx} className={className}>
        {bgImg}
        {isDesktop && overlayImg}
        <AppCardBody>
          <HeaderIcon>
            {loading ? <Skeleton width={160} height={52} /> : headerIcon}
          </HeaderIcon>
          <AppCardBodyText as="div">
            {loading ? <Skeleton count={3} /> : bodyText}
          </AppCardBodyText>
          {loading ? (
            <Skeleton height={42} />
          ) : typeof button === "string" ? (
            <AppCardButton onClick={() => onButtonClick?.()}>
              {button}
            </AppCardButton>
          ) : (
            button
          )}
          {!isDesktop && overlayImg}
        </AppCardBody>
      </AppCardContainer>
    </SkeletonTheme>
  );
};

const StyledSetFanCard = styled(AppCardNoData)<{ loading?: boolean }>`
  background: linear-gradient(80.58deg, #028dea 1.24%, #02d375 98.32%);

  ${({ theme }) => theme.media.mobile} {
    background: linear-gradient(44.42deg, #018ce9 3.08%, #01d374 97.4%);
  }
`;

export const SetFanAppNoData = ({ loading }: { loading?: boolean }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const isDesktop = useDesktopMediaQuery();
  const { id: artistGroupId } = useArtistContext();
  const { lockScroll, unlockScroll } = useScrollLockContext();

  const [data, setFanInterestLoading] = useDocumentData(
    doc(getFirestore(), `artist_interest_setfan/${artistGroupId}`),
  );

  return (
    <>
      <StyledSetFanCard
        bgImg={
          <BackgroundImage
            src={isDesktop ? desktopImages.SetFanBG : mobileImages.SetFanBG}
          />
        }
        overlayImg={
          <OverlayImage src={desktopImages.SetFanOverlay} height={360} />
        }
        headerIcon={<svg.SetFanKnockout />}
        bodyText="The ultimate digital survey tool that lets you better understand your fans."
        button={
          !setFanInterestLoading && data ? (
            <Font15>
              Thank you for your responses. A SET representative will be
              reaching out.
            </Font15>
          ) : (
            "Get Started"
          )
        }
        loading={loading}
        onButtonClick={() => {
          lockScroll();
          setModalOpen(true);
        }}
      />
      <SetFanInterestModal
        isOpen={modalOpen}
        onClose={() => {
          unlockScroll();
          setModalOpen(false);
        }}
      />
    </>
  );
};

const StyledSetLiveCard = styled(AppCardNoData)<{ loading?: boolean }>`
  background: ${({ theme }) =>
    theme.custom
      ? theme.custom.pages.dashboard.cards.shows.background
      : "linear-gradient(80.9deg, #581b81 4.52%, #b64f39 98.8%)"};

  ${({ theme }) => theme.media.mobile} {
    background: linear-gradient(44.42deg, #571a80 3.08%, #b54e38 97.4%);
  }
`;

export const SetLiveAppNoData = ({ loading }: { loading?: boolean }) => {
  const isDesktop = useDesktopMediaQuery();
  const { customApp } = useCustomAppContext();

  return (
    <StyledSetLiveCard
      {...(!customApp && {
        bgImg: (
          <BackgroundImage
            src={isDesktop ? desktopImages.SetLiveBG : mobileImages.SetLiveBG}
          />
        ),
        overlayImg: <OverlayImage src={desktopImages.SetLiveOverlay} />,
      })}
      headerIcon={<svg.SetLiveKnockout />}
      bodyText="Create real-time engagement with the audience at your live show. NO
    app to download."
      button="Learn More"
      onButtonClick={() => window.open(process.env.REACT_APP_ABOUT_URL)}
      loading={loading}
    />
  );
};

const AppCardBody = styled.div`
  position: absolute;
  height: 100%;
  left: 78px;
  width: 90%;
  display: block;
  ${({ theme }) => theme.media.desktop} {
    top: 54px;
    max-width: 324px;
  }

  ${({ theme }) => theme.media.mobile} {
    padding-top: 38px;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

const AppCardContainer = styled.div`
  position: relative;
  display: flex;
  height: 385px;
  border-radius: 10px;
  overflow: hidden;
  max-width: 1300px;

  ${({ theme }) => theme.media.tablet} {
    height: 587px;
  }
  ${({ theme }) => theme.media.mobile} {
    height: 587px;
    border-radius: 0px;
  }
`;

const AppCardBodyText = styled(Font20)`
  font-weight: 500;
  margin-bottom: 41px;

  ${({ theme }) => theme.media.mobile} {
    margin-bottom: 27px;
  }
`;

const BackgroundImage = styled.img`
  position: relative;
  left: 0;
  top: 0;
  height: 100%;
`;

const HeaderIcon = styled.div`
  margin-bottom: 41px;

  ${({ theme }) => theme.media.mobile} {
    margin-bottom: 31px;
  }
`;

const OverlayImage = styled.img`
  position: absolute;
  bottom: 0;
  height: 360px;

  ${({ theme }) => theme.media.desktop} {
    right: 3%;
    max-width: 40%;
    max-height: 90%;
    height: auto;
  }

  ${({ theme }) => theme.media.tablet} {
    max-width: 60%;
    max-height: 40%;
  }

  ${({ theme }) => theme.media.mobile} {
    height: 300px;
  }
`;
