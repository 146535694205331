import { useArtistContext } from "Components";
import { useEffect, useState } from "react";
import IntegrationCard from "../IntegrationCard";
import styled, { CSSProperties } from "styled-components";
import { doc, getFirestore } from "firebase/firestore";
import { DateTime } from "luxon";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Button } from "melodies-source/Button";
import { useDesktopMediaQuery } from "hooks";
import {
  CommunityIntegrationStatus,
  CommunityDocument,
  CommunityIntegrationCardProps,
} from "./types";
import {
  CommunityIntegrationModal,
  CommunityRemovalModal,
} from "./CommunityModals";
import LastSyncIcon from "../LastSyncIcon";
import CommunityLogo from "./CommunityLogo";

const CommunityIntegration = ({ sx }: { sx?: CSSProperties }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [integrationStatus, setIntegrationStatus] =
    useState<CommunityIntegrationStatus>(
      CommunityIntegrationStatus.NOT_CONNECTED,
    );
  const [removalModalOpen, setRemovalModalOpen] = useState(false);

  const { id: artistGroupId } = useArtistContext();

  const [_communityDoc] = useDocumentData(
    doc(
      getFirestore(),
      `artist_groups/${artistGroupId}/artist_group_private/community`,
    ),
  );

  const communityDoc = _communityDoc as CommunityDocument | undefined;

  const lastSynced: DateTime | undefined = communityDoc?.lastSynced
    ? DateTime.fromMillis(communityDoc.lastSynced.toMillis())
    : undefined;

  useEffect(() => {
    if (!!communityDoc?.secretPath) {
      setIntegrationStatus(CommunityIntegrationStatus.CONNECTED);
    } else {
      setIntegrationStatus(CommunityIntegrationStatus.NOT_CONNECTED);
    }
  }, [communityDoc]);

  return (
    <>
      {modalOpen && (
        <CommunityIntegrationModal
          artistGroupId={artistGroupId}
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          communityDoc={communityDoc}
        />
      )}
      <CommunityRemovalModal
        artistGroupId={artistGroupId}
        onClose={() => {
          setRemovalModalOpen(false);
        }}
        isOpen={removalModalOpen}
      />
      <CommunityIntegrationCard
        integrationStatus={integrationStatus}
        lastSynced={lastSynced}
        onRemoveCommunity={() => setRemovalModalOpen(true)}
        setModalOpen={setModalOpen}
        sx={sx}
      />
    </>
  );
};

export default CommunityIntegration;

const CommunityIntegrationCard: React.FC<CommunityIntegrationCardProps> = ({
  integrationStatus,
  lastSynced,
  onRemoveCommunity,
  setModalOpen,
  sx,
}) => {
  const isDesktop = useDesktopMediaQuery();

  if (integrationStatus === CommunityIntegrationStatus.CONNECTED) {
    return (
      <IntegrationCard
        header={<CommunityLogo />}
        body={
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <LastSyncIcon />
              <StatusText isDesktop={isDesktop}>
                Last Sync Time:{" "}
                {lastSynced
                  ? lastSynced.toLocaleString(DateTime.TIME_SIMPLE)
                  : "Never Synced"}
              </StatusText>
            </div>
            <BodyText>You're connected to Community!</BodyText>
          </>
        }
        footer={
          <FooterText
            style={{ marginTop: 9, textAlign: "center" }}
            onClick={onRemoveCommunity}
          >
            Remove Community
          </FooterText>
        }
        sx={sx}
      />
    );
  }

  // Community is not yet connected
  return (
    <IntegrationCard
      header={<CommunityLogo />}
      footer={
        <Button onClick={() => setModalOpen(true)}>
          <span style={{ marginRight: "0.35rem" }}>Connect to</span>
          <CommunityLogo fill="white" />
        </Button>
      }
      body="SET can automatically sync your contacts to your Community account. Click the button below to get started."
      sx={sx}
    />
  );
};

const BodyText = styled.div`
  font-size: 10px;
  line-height: 170%;
  font-weight: 400;
  text-align: center;
  color: #666;
`;

const FooterText = styled.div`
  color: #180171;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0 auto;
  padding-bottom: 5px;
  cursor: pointer;
  bottom: 0;
`;

const StatusText = styled.div<{ isDesktop: boolean }>`
  color: #635bff;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 12px)" : "13px")};
  line-height: 19.5px;
  margin-left: 0.25rem;
`;
