import { Dispatch, SetStateAction, useEffect } from "react";
import { FlexRow, Font16 } from "Routes/styled";
import styled from "styled-components";
import { SvgBack } from "melodies-source/Svgs/Back";
import { SvgForward } from "melodies-source/Svgs/Forward";

const arrayFromNum = (num: number, start = 0) =>
  [...Array(num)].map((_, idx) => idx + 1 + start);

const Pagination = ({
  selectedPage,
  resultsPerPage,
  totalResults,
  onSetPage,
  displayPages = 5,
}: {
  selectedPage: number;
  resultsPerPage: number;
  totalResults: number;
  onSetPage: Dispatch<SetStateAction<number>>;
  displayPages?: number;
}) => {
  const numPages = Math.ceil(totalResults / resultsPerPage);

  const middle = () => {
    if (numPages <= displayPages) {
      return (
        <NumbersRow
          numbers={arrayFromNum(numPages)}
          currentPage={selectedPage}
          onSetPage={onSetPage}
        />
      );
    }

    const pagesBetween = displayPages - 2;
    const startBoundary = selectedPage - Math.floor(pagesBetween / 2);
    const endBoundary = selectedPage + Math.floor(pagesBetween / 2);

    if (startBoundary <= 1) {
      return (
        <>
          <NumbersRow
            numbers={arrayFromNum(pagesBetween + 1)}
            currentPage={selectedPage}
            onSetPage={onSetPage}
          />
          <Ellipse />
          <NumbersRow
            numbers={[numPages]}
            currentPage={selectedPage}
            onSetPage={onSetPage}
          />
        </>
      );
    }

    if (startBoundary <= Math.ceil(pagesBetween / 2)) {
      return (
        <>
          <NumbersRow
            numbers={arrayFromNum(pagesBetween + 1)}
            currentPage={selectedPage}
            onSetPage={onSetPage}
          />
          <Ellipse />
          <NumbersRow
            numbers={[numPages]}
            currentPage={selectedPage}
            onSetPage={onSetPage}
          />
        </>
      );
    }

    if (
      startBoundary < selectedPage &&
      endBoundary > selectedPage &&
      endBoundary < numPages - 1
    ) {
      return (
        <>
          <NumbersRow
            numbers={[1]}
            currentPage={selectedPage}
            onSetPage={onSetPage}
          />
          <Ellipse />
          <NumbersRow
            numbers={arrayFromNum(
              pagesBetween,
              startBoundary - Math.floor(pagesBetween / 2),
            )}
            currentPage={selectedPage}
            onSetPage={onSetPage}
          />
          <Ellipse />
          <NumbersRow
            numbers={[numPages]}
            currentPage={selectedPage}
            onSetPage={onSetPage}
          />
        </>
      );
    }

    return (
      <>
        <NumbersRow
          numbers={[1]}
          currentPage={selectedPage}
          onSetPage={onSetPage}
        />
        <Ellipse />
        <NumbersRow
          numbers={arrayFromNum(pagesBetween + 1, numPages - pagesBetween - 1)}
          currentPage={selectedPage}
          onSetPage={onSetPage}
        />
      </>
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedPage]);

  return (
    <PaginationContainer>
      <NavigationIcon
        as={SvgBack}
        onClick={() => {
          if (selectedPage === 1) return;
          onSetPage((page) => page - 1);
        }}
        disabled={selectedPage === 1}
      />
      {middle()}
      <NavigationIcon
        as={SvgForward}
        onClick={() => {
          if (selectedPage === numPages) return;
          onSetPage((page) => page + 1);
        }}
        disabled={selectedPage === numPages}
      />
    </PaginationContainer>
  );
};

export default Pagination;

const PaginationContainer = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
`;

const NavigationIcon = styled.svg<{ disabled: boolean }>`
  height: 20px;
  color: var(--main-color);
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const PageNumberText = styled(Font16)<{ selected: boolean }>`
  color: var(--main-color);
  opacity: ${({ selected }) => (selected ? 1 : 0.6)};
  margin-right: 8px;
  margin-left: 8px;
  cursor: ${({ selected }) => (selected ? "default" : "pointer")};
`;

const EllipseFont = styled(Font16)`
  color: #1b007633;
`;

const Ellipse = () => {
  return <EllipseFont>...</EllipseFont>;
};

const NumbersRow = ({
  numbers,
  currentPage,
  onSetPage,
}: {
  numbers: number[];
  currentPage: number;
  onSetPage: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <>
      {numbers.map((num) => (
        <PageNumberText
          key={`pagination-${num}`}
          onClick={() => onSetPage(num)}
          selected={num === currentPage}
        >
          {num}
        </PageNumberText>
      ))}
    </>
  );
};
