import useUserIdToken from "./useUserIdToken";

export const useSpotifySearch = () => {
  const spotifyToken = useUserIdToken();

  const search = async (term: string, type: "track" | "album" = "track") => {
    if (!spotifyToken || !term) {
      return [];
    }

    const url = new URL(
      "/v1/search",
      process.env.REACT_APP_SPOTIFY_SEARCH_URL.replace(/\/$/, ""),
    );

    url.searchParams.set("q", term);
    url.searchParams.set("type", type);

    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${spotifyToken}`,
      },
    });

    return await response.json();
  };

  const fetchAlbumTracks = async (id: string) => {
    if (!spotifyToken) {
      return [];
    }

    const url = new URL(
      `/v1/albums/${id}/tracks`,
      process.env.REACT_APP_SPOTIFY_SEARCH_URL.replace(/\/$/, ""),
    );

    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${spotifyToken}`,
      },
    });

    return await response.json();
  };

  return { search, fetchAlbumTracks };
};
