import { ReactComponent as EditIcon } from "assets/svg/edit.svg";
import { FlexColumn, FlexRow } from "Components/Flex";
import { useMobileMediaQuery } from "hooks";
import { ConcertData } from "hooks/useUpcomingConcerts";
import { SvgCheck } from "melodies-source/Svgs/Check";
import { Body1, Body2, H3, H4, Subtitle2 } from "melodies-source/Text";
import { CalendarCard } from "Routes/SetLive/Duplicate/CalendarCard";
import styled from "styled-components";
import { getDateTime } from "Utils/date";
import { SetLiveBuilderEvent } from "./hooks/useSetLiveBuilderEvents";

const Container = styled(FlexRow)<{ $setLive?: boolean }>`
  background: ${(p) =>
    p.$setLive
      ? "linear-gradient(to top right, #1B0076 0%, #E71E3D 100%), #1B0076"
      : "#f2f5f7"};
  border-radius: 10px;
  padding: 10px 20px 10px 10px;
  color: ${(p) => p.$setLive && "white"};
`;

export const truncate: React.CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
};

interface ConcertCardProps {
  concert: ConcertData;
  event?: SetLiveBuilderEvent;
  onEdit?: () => void;
}

export const ConcertCard = ({ concert, event, onEdit }: ConcertCardProps) => {
  const isMobile = useMobileMediaQuery();
  const Title = isMobile ? H4 : H3;
  const Text = isMobile ? Body2 : Body1;
  const date = getDateTime(concert.date);
  const setLive = concert.setliveEventId && !!event?.publishedId;

  return (
    <Container $setLive={setLive} gap="14px" yCenter>
      <CalendarCard date={date} />
      <FlexColumn flex="1" style={{ minWidth: 0 }}>
        <Title style={truncate}>{concert.venue}</Title>
        <Text style={truncate}>
          {getDisplayAddress(concert)} • {date.toFormat("h:mm a (ZZZZ)")}
        </Text>
        {setLive && (
          <FlexRow gap="2px" yCenter>
            <SvgCheck width={12} height={12} />
            <Subtitle2>Has SET.Live Experience</Subtitle2>
          </FlexRow>
        )}
      </FlexColumn>
      {onEdit && <EditIcon onClick={() => onEdit?.()} />}
    </Container>
  );
};

export const getDisplayAddress = (concert: {
  state?: string;
  city?: string;
  address?: string;
}) => {
  return (
    [concert.city, concert.state].filter(Boolean).join(", ") || concert.address
  );
};
