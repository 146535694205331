import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";

export const getDateTime = (date: unknown) => {
  if (date instanceof DateTime) {
    return date;
  }

  if (date instanceof Timestamp) {
    return DateTime.fromJSDate(date.toDate());
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  if (typeof date === "number") {
    return DateTime.fromMillis(date);
  }

  throw new Error("#getDateTime: Invalid input");
};

export const isSameISODate = (first: unknown, second: unknown) => {
  return getDateTime(first).toISODate() === getDateTime(second).toISODate();
};
