import styled from "styled-components";

export const ModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
`;
