import { createContext, useContext, useCallback } from "react";
import { getIdToken } from "firebase/auth";
import { useUser } from "auth";

export type ProxyCollection =
  | "lead_sources"
  | "contacts"
  | "setlive_events"
  | "surveys"
  | "submissions"
  | "artist_groups";

// Sub-set of Typesense search params
export interface ProxySearchParams {
  q: string;
  query_by: string | string[];
  filter_by?: string;
  sort_by?: string | string[];
  facet_by?: string | string[];
  max_facet_values?: number;
  page?: number;
  per_page?: number;
  group_by?: string | string[];
  offset?: number;
  limit?: number;
}

interface TypsenseProxyProps {
  proxySearch: (
    collection: ProxyCollection,
    params: ProxySearchParams,
  ) => Promise<Record<string, any>>;
}

export const sanitizeTypesenseParams = (
  params: ProxySearchParams,
): Record<string, string> => {
  const p: Record<string, string> = {};
  for (const key of Object.keys(params)) {
    if (typeof params[key] === "number") p[key] = `${params[key]}`;
    else p[key] = params[key];
  }
  return p;
};

export const TypesenseProxyContext = createContext<TypsenseProxyProps>(null);

export const TypesenseProxyProvider = ({ children }) => {
  const { user } = useUser();

  const proxySearch = useCallback(
    async (
      collection: ProxyCollection,
      params: ProxySearchParams,
    ): Promise<Record<string, any>> => {
      let host: string = "";

      let server =
        process.env.REACT_APP_ENV === "production" ? "set" : "dev-set";

      if (!user) {
        console.log("No user user for proxy");
        return;
      }

      const token = await getIdToken(user);

      //* TODO * Define a server for each collection as dev has different organization of servers/collections
      switch (collection) {
        case "surveys":
        case "submissions":
          host = "surveys";
          break;
        case "setlive_events":
          host = "events";
          break;
        case "lead_sources":
        case "contacts":
          // lead_sources, contacts
          host = "fans";
          break;
        case "artist_groups":
          host = "groups";
      }

      if (!host) {
        console.log("Could not find proxy for", collection);
        return;
      }

      const url = new URL(
        `/collections/${collection}/documents/search`,
        `https://${host}.search.${server}.rocks`,
      );

      url.search = new URLSearchParams(
        sanitizeTypesenseParams(params),
      ).toString();

      return await fetch(url.toString(), {
        headers: {
          authorization: `bearer ${token}`,
        },
      })
        .then(async (res) => {
          const r = await res.json();
          return r;
        })
        .catch((err) => {
          console.log(err);
          return {};
        });
    },
    [user],
  );

  return (
    <TypesenseProxyContext.Provider
      value={{
        proxySearch,
      }}
    >
      {children}
    </TypesenseProxyContext.Provider>
  );
};

export const useTypesenseProxyContext = () => useContext(TypesenseProxyContext);
