import React from "react";
import { Body2, H2, Subtitle2 } from "melodies-source/Text";
import styled from "styled-components";
import { Survey } from "models/survey";
import CustomBarChart from "Components/VerticalBarChart";
import Skeleton from "react-loading-skeleton";

interface Props {
  survey: Survey;
}

export const BarChart: React.FC<Props> = ({ survey }) => {
  return !!survey.series.length ? (
    <ChartContainer>
      <CustomBarChart dataSeries={survey.series} />
      <ChartDateRange>
        <Subtitle2>
          {`${survey.startDate.monthShort} ${survey.startDate.day}`}
        </Subtitle2>
        <Body2>Responses over time</Body2>
        <Subtitle2>
          {`${survey.endDate.monthShort} ${survey.endDate.day}`}
        </Subtitle2>
      </ChartDateRange>
    </ChartContainer>
  ) : (
    <div style={{ marginBottom: 20, textAlign: "center" }}>
      Awaiting first response
    </div>
  );
};

export const BarChartLoader = () => (
  <ChartContainer>
    <Skeleton height={73} />
    <ChartDateRange>
      <Subtitle2>
        <Skeleton />
      </Subtitle2>
      <Body2>
        <Skeleton />
      </Body2>
      <Subtitle2>
        <Skeleton />
      </Subtitle2>
    </ChartDateRange>
  </ChartContainer>
);

const ChartDateRange = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${H2} {
    color: var(--text-color);
  }

  ${Body2} {
    color: var(--secondary-text-color);
  }
`;

const ChartContainer = styled.div`
  width: 400px;
  margin: 20px 0;

  ${({ theme }) => theme.media.tablet} {
    width: 30vw;
    margin: 20px auto;
  }

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;
