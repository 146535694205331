import { useArtistContext } from "Components";
import { updateDoc } from "firebase/firestore";
import { Body1 } from "melodies-source/Text";
import { SettingsRow } from "../";
import { ProfileAvatar } from "Components/ProfileAvatar";
import { ImageUploadModal } from "melodies-source/ImageUploadModal";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const ArtistImage = () => {
  const { assets, artistGroupDoc, id } = useArtistContext();
  const { customApp, language } = useCustomAppContext();

  const onUploadComplete = (url: string) => {
    updateDoc(artistGroupDoc.ref, {
      "assets.icon.path": url,
      "assets.headerSmall.path": url,
      "assets.headerLarge.path": url,
    });
  };

  return (
    <SettingsRow
      title={`${language.event.owner.singular} Image`}
      description={`${language.event.owner.singular} image is visible across ${
        customApp?.company.name || "SET"
      } services and may be seen by the general public when publishing content.`}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <ImageUploadModal
          header={`${language.event.owner.singular} Image`}
          dropzoneDescription="High-resolution images of 1000 x 1000 pixels or with an aspect ratio of 1:1 are recommended."
          uploadPath="/ap-images"
          uploadFileName={id}
          onComplete={onUploadComplete}
        >
          {({ toggle }) => (
            <ProfileAvatar url={assets?.icon?.path} onEdit={toggle} />
          )}
        </ImageUploadModal>
        <Body1 style={{ marginLeft: 26 }}>
          High-resolution images of 1000 x 1000 pixels or with an aspect ratio
          of 1:1 are recommended.
        </Body1>
      </div>
    </SettingsRow>
  );
};
