import * as React from "react";
import { SVGProps } from "react";
export const SvgRightLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.8786797,4.29289322 C10.5181957,4.65337718 10.4904662,5.22060824 10.795491,5.61289944 L10.8786797,5.70710678 L16.171,11 L4,11 L4,13 L16.171,13 L10.8786797,18.2928932 C10.5181957,18.6533772 10.4904662,19.2206082 10.795491,19.6128994 L10.8786797,19.7071068 C11.2391636,20.0675907 11.8063947,20.0953203 12.1986859,19.7902954 L12.2928932,19.7071068 L20,12 L12.2928932,4.29289322 C11.9023689,3.90236893 11.2692039,3.90236893 10.8786797,4.29289322 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
