import { SvgCheckCircle } from "melodies-source/Svgs/CheckCircle";
import { SvgErrorAlt } from "melodies-source/Svgs/ErrorAlt";
import { SvgInformationAlt } from "melodies-source/Svgs/InformationAlt";
import { SvgCloseLarge } from "melodies-source/Svgs/CloseLarge";
import { Body1 } from "melodies-source/Text";
import styled from "styled-components";
import { forwardRef } from "react";

const ToastVariants = [
  "default",
  "success",
  "info",
  "warning",
  "error",
] as const;
export type ToastVariant = (typeof ToastVariants)[number];

interface ToastProps {
  label: React.ReactNode;
  onAction?: VoidFunction;
  variant?: ToastVariant;
  style?: React.CSSProperties;
}

export const Toast = forwardRef<HTMLDivElement, ToastProps>(
  ({ label, onAction, variant = "default", ...props }, ref) => {
    const presets = {
      default: { color: "black80", icon: undefined },
      success: { color: "green", icon: <SvgCheckCircle /> },
      info: { color: "blue", icon: <SvgInformationAlt /> },
      warning: {
        color: "yellow",
        icon: <SvgInformationAlt style={{ transform: "rotate(180deg)" }} />,
      },
      error: { color: "red", icon: <SvgErrorAlt /> },
    };

    presets.default = presets.info;

    const color = presets[variant].color;
    const icon = presets[variant].icon;
    return (
      <Container ref={ref} color={color} {...props}>
        {icon && (
          <IconContainer style={{ marginRight: 12 }}>{icon}</IconContainer>
        )}
        <Body1>{label}</Body1>
        {onAction && (
          <IconContainer style={{ marginLeft: 12 }}>
            <IconButton onClick={onAction}>
              <SvgCloseLarge style={{ width: 20, height: 20 }} />
            </IconButton>
          </IconContainer>
        )}
      </Container>
    );
  },
);

const Container = styled.div<{ color: string }>`
  background-color: ${({ theme, color }) => theme.colors[color]};
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding: 6px 12px;
  color: rgb(255, 255, 255);
  border-radius: 6px;
  box-shadow:
    rgba(145, 158, 171, 0.2) 0px 3px 5px -1px,
    rgba(145, 158, 171, 0.14) 0px 6px 10px 0px,
    rgba(145, 158, 171, 0.12) 0px 1px 18px 0px;

  ${Body1} {
    display: flex;
    font-weight: 400;
    line-height: 22px;
    padding: 6px 0;
    color: inherit;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    max-width: 100%;
  }
`;

const IconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.black70}12;
  }
`;

const IconContainer = styled.div`
  display: flex;
  width: 20px;
  flex-shrink: 0;
  justify-content: center;
`;
