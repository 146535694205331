import { DefaultLayout } from "Components";
import { Card } from "melodies-source/Card";
import { GlobalStyles } from "styled";
import styled from "styled-components";
import { Preview } from "../Components/Preview";
import { Dispatch, SetStateAction, useState } from "react";
import { SectionContainer } from "Routes/SetFan/editor/Components/SectionContainer";
import { SvgClipboard } from "melodies-source/Svgs/Clipboard";
import { SvgChecklist } from "melodies-source/Svgs/Checklist";
import { ReactComponent as IconPalette } from "assets/svg/palette.svg";
import { ReactComponent as IconTrophy } from "assets/svg/trophy-large.svg";
import { ReactComponent as IconUserQuestion } from "assets/svg/user-question.svg";
import { ReactComponent as IconMessageCheck } from "assets/svg/message-check.svg";
import { SurveyInfoContent } from "../slices/SurveyInfoContent";
import { Appearance } from "../slices/Appearance";
import { Contest } from "../slices/Contest";
import { SurveyQuestions } from "../slices/SurveyQuestions";
import { ProfilingQuestions } from "../slices/ProfilingQuestions";
import { ThankYouMessage } from "../slices/ThankYouMessage";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { useSurveyBuilder } from "../hooks/useSurveyBuilder";
import {
  BuilderProvider,
  useBuilderContext,
} from "../Components/BuilderContext";
import { H2 } from "melodies-source/Text";
import { Button } from "melodies-source/Button";
import { CenterFlexRow, FlexColumn } from "Routes/styled";
import { SongsQuestion } from "../Components/SongsQuestion";
import { ContestTitleAddon } from "../slices/ContestTitleAddon";

const slices = [
  { icon: <SvgClipboard />, title: "Survey Info", slice: SurveyInfoContent },
  { icon: <IconPalette />, title: "Appearance", slice: Appearance },
  {
    icon: <IconTrophy />,
    title: "Sweepstakes",
    slice: Contest,
    titleAddon: ContestTitleAddon,
  },
  {
    icon: <SvgChecklist />,
    title: "Survey Questions",
    slice: SurveyQuestions,
  },
  {
    icon: <IconUserQuestion />,
    title: "Profiling Questions",
    slice: ProfilingQuestions,
  },
  {
    icon: <IconMessageCheck />,
    title: "Thank You Message",
    slice: ThankYouMessage,
  },
];

export const Editor = ({
  match,
}: RouteComponentProps<{ surveyId: string }>) => {
  const [page, setPage] = useState<number | null>(0);
  const { survey, version } = useSurveyBuilder(match.params.surveyId);

  return (
    <DefaultLayout>
      <GlobalStyles grayBG />
      {survey && version && (
        <BuilderProvider survey={survey} version={version}>
          <Switch>
            <Route
              path={match.path}
              exact={true}
              render={() => <InnerEditor page={page} setPage={setPage} />}
            />
            <Route
              path={`${match.path}/setlist/:setlistId?`}
              render={() => <InnerSetlist backPath={match.url} />}
            />
          </Switch>
        </BuilderProvider>
      )}
    </DefaultLayout>
  );
};

interface InnerSetlistProps {
  backPath: string;
}

const InnerSetlist = ({ backPath }: InnerSetlistProps) => {
  return (
    <div style={{ padding: 40 }}>
      <SongsQuestion backPath={backPath} />
    </div>
  );
};

interface InnerEditorProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const InnerEditor = ({ page, setPage }: InnerEditorProps) => {
  const { onPublish, publishingStatus, status } = useBuilderContext();

  return (
    <>
      <Header>
        <H2 style={{ color: "#1B0076" }}>Edit Survey</H2>
        <CenterFlexRow>
          {status === "draft" && (
            <Button
              loading={publishingStatus === "draft"}
              disabled={publishingStatus === "live"}
              variant="tertiary"
              onClick={() => onPublish({ status: "draft" })}
              style={{ marginRight: 12, minWidth: 156 }}
            >
              Save as Draft
            </Button>
          )}
          <Button
            style={{ minWidth: 162 }}
            onClick={() => onPublish({ status: "live" })}
            loading={publishingStatus === "live"}
            disabled={publishingStatus === "draft"}
          >
            {status === "draft" ? "Publish" : "Update"}
          </Button>
        </CenterFlexRow>
      </Header>
      <Frame>
        <CreatorCard isElevated>
          {slices.map((section, index) => (
            <SectionContainer
              {...section}
              active={index === page}
              onClick={() => setPage((curr) => (curr === index ? null : index))}
              lastIndex={index === slices.length - 1}
              key={`survey-section-${index}`}
            />
          ))}
        </CreatorCard>
        <PreviewContainer>
          <Preview />
        </PreviewContainer>
      </Frame>
    </>
  );
};

const PreviewContainer = styled(FlexColumn)`
  position: sticky;
  top: 40px;
  width: 400px;
  flex-shrink: 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 44px 0;
  width: 100%;
`;

const Frame = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 32px 44px 44px;

  ${({ theme }) => theme.media.mobile} {
    padding: 20px 0;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const CreatorCard = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  padding: 0;
  border: none;
  margin-right: 44px;
  flex-grow: 1;
  overflow: hidden;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  }
`;
