const ClipboardLogo = ({ className }: { className?: string }) => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g style={{ mixBlendMode: "soft-light" }}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7401 4.83332C23.5581 3.29385 26.1946 2.41666 29 2.41666C31.8054 2.41666 34.4419 3.29385 36.2599 4.83332H38.6667C41.336 4.83332 43.5 6.99728 43.5 9.66666H45.9167C48.586 9.66666 50.75 11.8306 50.75 14.5V50.75C50.75 53.4194 48.586 55.5833 45.9167 55.5833H12.0833C9.41396 55.5833 7.25 53.4194 7.25 50.75V14.5C7.25 11.8306 9.41396 9.66666 12.0833 9.66666H14.5C14.5 6.99728 16.664 4.83332 19.3333 4.83332H21.7401ZM38.6667 16.9167C40.4557 16.9167 42.0177 15.9447 42.8534 14.5H45.9167V50.75H12.0833V14.5H15.1466C15.9823 15.9447 17.5443 16.9167 19.3333 16.9167H38.6667ZM19.3333 38.6667V33.8333H33.8333V38.6667H19.3333ZM19.3333 24.1667V29H38.6667V24.1667H19.3333ZM23.8226 9.66666L24.5452 8.83382C25.3392 7.91878 27.0411 7.24999 29 7.24999C30.9589 7.24999 32.6608 7.91878 33.4548 8.83382L34.1774 9.66666H38.6667V12.0833H19.3333V9.66666H22.7199H23.8226Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default ClipboardLogo;
