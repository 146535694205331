import { Colors } from "custom/types";
import { UserMenuOptions } from "../gtb/Components/UserMenuOptions";
import { GtbAffiliationMap } from "@max/common/dist/thirdparty/gtb";
import { GtbCustomApp } from "../gtb/types";
import { gtb } from "../gtb";

const colors: Colors = {
  main: "#006610",
  secondary: "#00BD1D",
  tertiary: "#006610",
  background: "white",
  text: "#333333",
  headerText: "#343a40",
  secondaryText: "#999999",
  primaryButtonText: "white",
  primaryButtonHover: "#288b39",
  componentBackground: "#FFFFFF",
  border: "#ced4da",
  inputBorder: "#ced4da",
  hoverBackground: "#b2f2bb",
  boxShadow: "rgba(0,0,0,0.1)",
  selected: "#51cf66",
  contentBackground: "#E7F3E6",
  disabled: "#565656",
  spinner: "#2f9e44",
  liveEventCardBackground: "#d3f9d8",
};

const isDev = process.env.NODE_ENV === "development";

export const towne: GtbCustomApp = {
  app: { name: "SPONSORSHIP PROGRAMS" },
  company: {
    logo: {
      url: "https://storage.googleapis.com/set-live-stage.appspot.com/Towne_logo.svg",
      description: "towne",
    },
    name: "Towne",
  },
  event: {
    owner: {
      singular: "Program",
      plural: "Programs",
      singularWithArticle: "a Program",
    },
    type: {
      singular: "Event",
      plural: "Events",
      singularWithArticle: "an Event",
    },
    audience: {
      singular: "Attendee",
      plural: "Attendees",
      singularWithArticle: "an Attendee",
    },
  },
  routes: gtb.routes,
  UserMenuOptions,
  userRoles: JSON.parse(
    JSON.stringify(GtbAffiliationMap).replaceAll("GTB", "Towne"),
  ),
  footerLinks: [
    {
      text: "Towne Privacy Policy",
      url: "https://www.max.live/privacy-policy",
    },
    { text: "MAX Privacy Policy", url: "https://www.max.live/privacy-policy" },
  ],
  fanAppUrl: `https://towne.live`,
  onsiteAppUrl: `https://onsite.towne.live`,
  privacyPolicyUrl: "https://www.ford.com/help/privacy",
  regionsCollection: "towne_regions",
  menu: {
    sections: {
      engagement: {
        header: "User Engagement",
        items: {
          live: {
            label: "Towne.Live",
            icon: "",
          },
        },
      },
    },
  },
  theme: {
    colors,
    layout: {
      menuBar: {
        background: colors.background,
        color: colors.main,
      },
      sideBar: {
        background: colors.componentBackground,
        color: colors.text,
        active: {
          background: colors.main,
          color: "white",
        },
        sectionHeader: {
          color: colors.text,
        },
      },
      content: {
        background: colors.contentBackground,
        backgroundHeader: {
          background: "linear-gradient(180deg, #2f9e44 20%, #E7F3E6 85%)",
        },
      },
      footer: {
        background: colors.background,
        color: colors.text,
      },
      boxShadowColor: colors.border,
    },
    pages: {
      dashboard: {
        cards: {
          nextShow: {
            background: colors.background,
            color: colors.main,
          },
          fanContacts: {
            background: colors.background,
            color: colors.main,
          },
          fanProfile: {
            background: "gray",
            color: "white",
          },
          shows: {
            background: "linear-gradient(180deg, #00D120 -20%, #007913 110%)",
          },
        },
      },
      contacts: {
        table: {
          row: {
            hover: {
              background: "#69db7c",
            },
            border: { color: "#adb5bd" },
          },
        },
        noContacts: {
          background: colors.componentBackground,
          icon: {
            color: colors.main,
          },
        },
      },
      reporting: {
        setlive: {
          fanReportCard: {
            background: colors.contentBackground,
          },
        },
      },
      account: {
        header: {
          background: `linear-gradient(180deg, ${colors.main} 0%, ${colors.secondary} 100%), #20222A`,
        },
      },
      region: {
        cards: {
          contactsTotal: {
            background: "linear-gradient(180deg, #00D120 -20%, #007913 110%)",
            color: "white",
          },
          contactsLast30Days: {
            background: "linear-gradient(180deg, #00D120 -20%, #007913 110%)",
            color: "white",
          },
        },
      },
    },
    charts: {
      bar: {
        bar: { background: "#d8d8d8" },
      },
      donut: {
        colors: [
          colors.main,
          colors.secondary,
          "#4d9458",
          "#4dd161",
          "#00470b",
          "#008414",
        ],
      },
    },
    icons: {
      mapPin: {
        background: colors.contentBackground,
      },
      calendar: {
        background: colors.contentBackground,
      },
    },
  },
};
