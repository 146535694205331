import * as React from "react";
import { SVGProps } from "react";
export const SvgInformationAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10,2 C14.418278,2 18,5.581722 18,10 C18,14.418278 14.418278,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 Z M11,9 L9,9 L9,15 L11,15 L11,9 Z M10,5 C9.44771525,5 9,5.44771525 9,6 C9,6.55228475 9.44771525,7 10,7 C10.5522847,7 11,6.55228475 11,6 C11,5.44771525 10.5522847,5 10,5 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
