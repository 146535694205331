import * as React from "react";
import { SVGProps } from "react";
export const SvgSetList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17,4 L17,6 L8,6 L8,14 C7.9914027,14.6156334 7.26919689,15.3243628 6.17003546,15.7216124 C4.84020069,16.2022299 3.46217152,16.0449129 3.09212116,15.370235 C2.72207079,14.6955571 3.50012978,13.7590052 4.82996454,13.2783876 C5.60777594,12.9972777 6.40207466,12.9344052 7.00152881,13.0656444 L7,4 L17,4 Z M16,11 L16,12 L9,12 L9,11 L16,11 Z M16,9 L16,10 L9,10 L9,9 L16,9 Z M16,7 L16,8 L9,8 L9,7 L16,7 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
