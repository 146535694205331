import * as React from "react";
import { SVGProps } from "react";
export const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5,3 C14.7761424,3 15,3.22385763 15,3.5 L15,4 L16,4 C16.5522847,4 17,4.44771525 17,5 L17,16 C17,16.5522847 16.5522847,17 16,17 L4,17 C3.44771525,17 3,16.5522847 3,16 L3,5 C3,4.44771525 3.44771525,4 4,4 L5,4 L5,3.5 C5,3.22385763 5.22385763,3 5.5,3 C5.77614237,3 6,3.22385763 6,3.5 L6,4 L14,4 L14,3.5 C14,3.22385763 14.2238576,3 14.5,3 Z M16,8 L4,8 L4,16 L16,16 L16,8 Z M15,13 L15,15 L13,15 L13,13 L15,13 Z M5,5 L4,5 L4,7 L16,7 L16,5 L15,5 L15,5.5 C15,5.77614237 14.7761424,6 14.5,6 C14.2238576,6 14,5.77614237 14,5.5 L14,5 L6,5 L6,5.5 C6,5.77614237 5.77614237,6 5.5,6 C5.22385763,6 5,5.77614237 5,5.5 L5,5 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
