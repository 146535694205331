import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";
import { Button } from "Components";

enum Step {
  Welcome = 1,
  Social = 2,
  Websites = 3,
  Invite = 4,
}

interface NextButton {
  text: string;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
}

type BackButton = Pick<NextButton, "onClick">;

interface Buttons {
  back?: BackButton;
  next: NextButton;
}
interface Props {
  step: Step;
  buttons: Buttons;
  children: React.ReactNode;
  action?: React.ReactNode;
}

export const Layout: React.FC<Props> = ({
  children,
  step,
  buttons,
  action,
}) => {
  let stepEntries = Object.entries(Step);
  stepEntries = stepEntries.slice(stepEntries.length / 2);

  return (
    <Container>
      <Steps>
        {stepEntries.map(([text, number]) => {
          const active = step === number;
          return (
            <div key={text}>
              <StepNumber active={active}> {number}</StepNumber>
              {active && <StepName>{text}</StepName>}
            </div>
          );
        })}
      </Steps>
      <Content>{children}</Content>
      <Actions>
        <div>{action}</div>
        <ButtonsContainer>
          {step > 1 && buttons.back && (
            <BackButton onClick={() => buttons.back.onClick()}>Back</BackButton>
          )}
          <Button
            loading={buttons.next.loading}
            disabled={buttons.next.disabled}
            onClick={() => buttons.next.onClick()}
            {...(step < stepEntries.length &&
              !buttons.next.disabled && { icon: <ArrowRightIcon /> })}
          >
            {buttons.next.text}
          </Button>
        </ButtonsContainer>
      </Actions>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: Poppins;
`;

const Steps = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

interface StepNumberProps {
  active: boolean;
}

const StepNumber = styled.span<StepNumberProps>`
  padding: 0px 8px;
  border-radius: 3px;

  ${({ active }) =>
    active
      ? css`
          color: white;
          background: #1b0076;
        `
      : css`
          color: #999;
          background: #efefef;
        `}
`;

const StepName = styled.span`
  color: #1b0076;
  margin-left: 10px;
`;

const Content = styled.div`
  margin: 20px 0;
  min-height: 50vh;
`;

const Actions = styled.div`
  padding-top: 5px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
    text-align: center;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;
    gap: 10px;
    width: 100%;
  }

  button {
    flex-shrink: 0;
    width: auto;
    padding: 10px 18px;
  }
`;

const BackButton = styled(Button)`
  background: white;
  color: #1b0076;
  border: 1px solid #1b0076;

  &:hover {
    background: none;
  }
`;
