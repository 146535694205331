import { ReactComponent as HeadlineIcon } from "assets/svg/headline.svg";
import { TextInput } from "Components";
import { Select } from "Components/Select";
import { Field, FieldProps, useFormikContext } from "formik";
import styled from "styled-components";
import { Section, SectionHeader, SectionTitle } from "./components";
import { SongsQuestionValues } from "./types";

const OPTIONS = [...Array(20).keys()]
  .map((v) => ({
    label: (v + 1).toString(),
    value: (v + 1).toString(),
  }))
  .concat([
    {
      label: "No Limit",
      value: "no-limit",
    },
  ]);

const FieldList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const HeadlineForm = () => {
  const { values, setFieldValue } = useFormikContext<SongsQuestionValues>();

  return (
    <Section>
      <SectionHeader>
        <SectionTitle>
          <HeadlineIcon /> Headline
        </SectionTitle>
      </SectionHeader>
      <FieldList>
        <Field name="headline">
          {({ field, meta }: FieldProps) => (
            <TextInput
              label="Headline Text"
              hasError={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              asString={false}
              {...field}
            />
          )}
        </Field>
        <Select
          label="How Many Songs Can Fans Select?"
          options={OPTIONS}
          onChange={(v) => setFieldValue("max", v)}
          value={values.max}
        />
      </FieldList>
    </Section>
  );
};
