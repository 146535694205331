import { ConcertData } from "hooks/useUpcomingConcerts";
import { useState } from "react";
import { getEvent } from "../utils";
import { publishBuilderEvent } from "./publishBuilderEvent";
import { softDeleteConcert } from "./softDeleteConcert";
import { BuilderEventData } from "./useSetLiveBuilderEvents";

interface Options {
  events?: BuilderEventData[];
  onSuccess?: () => void;
}

interface ExecuteOptions {
  concert: ConcertData;
}

export const useDeleteConcert = ({ events = [], onSuccess }: Options) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const execute = async ({ concert }: ExecuteOptions) => {
    try {
      setLoading(true);

      const event = getEvent(concert, events);

      if (event) {
        await publishBuilderEvent(event, "deleted");
      }

      await softDeleteConcert(concert);

      onSuccess?.();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return { execute, loading, error } as const;
};
