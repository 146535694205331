import * as React from "react";
import { SVGProps } from "react";
export const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17,5 C17.5522847,5 18,5.44771525 18,6 L18,14 C18,14.5522847 17.5522847,15 17,15 L3,15 C2.44771525,15 2,14.5522847 2,14 L2,6 C2,5.44771525 2.44771525,5 3,5 L17,5 Z M17,7.892 L10,11.0040645 L3,7.892 L3,14 L17,14 L17,7.892 Z M17,6 L3,6 L3,6.798 L10,9.90990577 L17,6.798 L17,6 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
