import React, { useEffect, useState } from "react";
import { Links } from "../Components/Links";
import { useArtistContext } from "Components";

export const SocialLinks: React.FC = () => {
  const { artistGroupDoc } = useArtistContext();
  const artistGroupData = artistGroupDoc?.data();
  const [instagram, setInstagram] = useState(artistGroupData?.instagram || "");
  const [facebook, setFacebook] = useState(artistGroupData?.facebook || "");
  const [tiktok, setTiktok] = useState(artistGroupData?.tiktok || "");
  const [youtube, setYoutube] = useState(artistGroupData?.youtube || "");
  const [twitter, setTwitter] = useState(artistGroupData?.twitter || "");

  useEffect(() => {
    if (artistGroupData) {
      setInstagram(artistGroupData.instagram || "");
      setFacebook(artistGroupData.facebook || "");
      setTiktok(artistGroupData.tiktok || "");
      setYoutube(artistGroupData.youtube || "");
      setTwitter(artistGroupData.twitter || "");
    }
  }, [
    artistGroupData?.instagram,
    artistGroupData?.facebook,
    artistGroupData?.tiktok,
    artistGroupData?.youtube,
    artistGroupData?.twitter,
  ]);

  return (
    <Links
      title="Social Media Links"
      links={[
        {
          label: "Instagram",
          key: "instagram",
          url: instagram,
          setUrl: setInstagram,
        },
        {
          label: "Facebook",
          key: "facebook",
          url: facebook,
          setUrl: setFacebook,
        },
        {
          label: "Tiktok",
          key: "tiktok",
          url: tiktok,
          setUrl: setTiktok,
        },
        {
          label: "Youtube",
          key: "youtube",
          url: youtube,
          setUrl: setYoutube,
        },
        {
          label: "Twitter (X)",
          key: "twitter",
          url: twitter,
          setUrl: setTwitter,
        },
      ]}
    />
  );
};
