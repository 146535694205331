import { httpsCallable } from "firebase/functions";
import { SetLiveEvent } from "models/event";
import { createContext, useContext, useState } from "react";
import { useSetLiveEvents } from "hooks";
import { functions } from "firebase-internal";
import { useParams } from "react-router-dom";
import { PublishRequest, PublishResponse } from "Routes/SetLive/Duplicate";

export interface UseState {
  name?: string;
}

export type FilterOptions = "newest" | "oldest" | "published" | "drafts";
export type SearchType = "upcoming" | "past";

interface ContextProps {
  setLiveEvents: SetLiveEvent[];
  deleteEvent: (id: string) => Promise<boolean>;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  searchInput: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  setFilterOption: React.Dispatch<React.SetStateAction<FilterOptions>>;
  filterOption: FilterOptions;
  totalEvents: number;
  loading: boolean;
  type: SearchType;
  reloadEvents: () => Promise<SetLiveEvent[]>;
  updateEvent: (id: string, data: Partial<SetLiveEvent>) => void;
}

export const SetLiveEventsContext = createContext<ContextProps>(
  {} as ContextProps,
);

interface ProviderProps {
  children?: React.ReactNode;
}

export const RESULTS_PER_PAGE = 10;

export const SetLiveEventsProvider = ({ children }: ProviderProps) => {
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterOption, setFilterOption] = useState<
    "newest" | "oldest" | "published" | "drafts"
  >("newest");
  const { tabParam } = useParams<{ tabParam?: string }>();

  const type: SearchType = tabParam.includes("upcoming") ? "upcoming" : "past";

  const { setLiveEvents, totalEvents, loading, reloadEvents, updateEvent } =
    useSetLiveEvents({
      query: searchInput,
      page: currentPage,
      showType: type,
      filter: filterOption,
      resultsPerPage: RESULTS_PER_PAGE,
    });

  const checkEvents = async (id: string, count: number = 0) => {
    const events = await reloadEvents();
    const event = events?.find((e) => e.id === id);
    if (!!event && count < 4) {
      setTimeout(() => checkEvents(id, count++), 1500);
    }
    return;
  };

  const deleteEvent = async (id: string) => {
    try {
      const publish = httpsCallable<PublishRequest, PublishResponse>(
        functions,
        "setlivev3-event-publish",
      );
      const response = await publish({
        id,
        status: "deleted",
      });
      if (response?.data?.events?.length) {
        await checkEvents(id);
        return true;
      }
    } catch (err) {
      console.error(`Error deleting event ${err.message}`);
    }
  };

  const values = {
    setLiveEvents,
    deleteEvent,
    type,
    setSearchInput,
    searchInput,
    setCurrentPage,
    currentPage,
    setFilterOption,
    filterOption,
    totalEvents,
    loading,
    reloadEvents,
    updateEvent,
  };

  return (
    <SetLiveEventsContext.Provider value={values}>
      {children}
    </SetLiveEventsContext.Provider>
  );
};

export const useSetLiveEventsContext = () => useContext(SetLiveEventsContext);
