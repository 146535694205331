import * as React from "react";
import { SVGProps } from "react";
export const SvgClipboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6,4 L6,5 L5,5 L5,16 L15,16 L15,5 L14,5 L14,4 L15,4 C15.5522847,4 16,4.44771525 16,5 L16,17 C16,17.5522847 15.5522847,18 15,18 L5,18 C4.44771525,18 4,17.5522847 4,17 L4,5 C4,4.44771525 4.44771525,4 5,4 L6,4 Z M13.6212095,7.36738157 L13.6330105,7.37918263 C13.8376044,7.59057411 13.8376044,7.9261564 13.6330105,8.13754788 L8.44299549,13.5 L6.36698948,11.3550192 C6.16239559,11.1436277 6.16239559,10.8080454 6.36698948,10.5966539 C6.5413585,10.4164915 6.81620351,10.3922988 7.017478,10.52692 L7.10096845,10.5966539 L7.10096845,10.5966539 L8.44299549,11.9832695 L12.8990316,7.37918263 C13.0951967,7.17649993 13.4185268,7.17121642 13.6212095,7.36738157 Z M10,2 C10.5522847,2 11,2.44771525 11,3 L12,3 C12.5522847,3 13,3.44771525 13,4 L13,5 L7,5 L7,4 C7,3.44771525 7.44771525,3 8,3 L9,3 C9,2.44771525 9.44771525,2 10,2 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
