import React, { useEffect, useState } from "react";
import { Divider } from "Components/Divider";
import styled, { css } from "styled-components";
import { SvgAccordionClosed } from "svg/AccordionClosed";
import { SvgAccordionOpen } from "svg/AccordionOpen";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";

interface Icons {
  open: JSX.Element;
  closed: JSX.Element;
}

export type SectionProps = {
  id?: string;
  header: string | React.ReactNode;
  body: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  icons?: Icons;
  hasDivider?: boolean;
  openBackground?: string;
  withTransition?: boolean;
};

type Props = {
  sections: SectionProps[];
  style?: any;
  open?: boolean;
  showDivider?: boolean;
  icons?: Icons;
  twoCols?: boolean;
  openBackground?: string;
};

export const Accordion = ({
  sections,
  open = false,
  showDivider,
  icons,
  twoCols,
  openBackground,
  ...props
}: Props) => {
  const isMobile = useMobileMediaQuery();
  return (
    <AccordionWrapper
      {...props}
      showDivider={showDivider}
      hasOpenBackground={!!openBackground}
    >
      {sections?.map((section, index) => (
        <React.Fragment key={section.id ?? index}>
          <SectionItem twoCols={twoCols}>
            <Section
              open={open}
              hasDivider={showDivider}
              openBackground={openBackground}
              {...section}
              {...(icons && { icons })}
            />
          </SectionItem>
          {index < sections.length - 1 && (isMobile || showDivider) && (
            <StyledDivider />
          )}
        </React.Fragment>
      ))}
    </AccordionWrapper>
  );
};

const Section = ({
  header,
  body,
  open,
  icons,
  onOpenChange,
  hasDivider,
  openBackground,
  withTransition = true,
}: SectionProps) => {
  return (
    <SectionContainer
      hasDivider={hasDivider}
      open={open}
      openBackground={openBackground}
    >
      <HeaderContainer onClick={() => onOpenChange(open)}>
        <div>
          {typeof header === "string" ? <Selected>{header}</Selected> : header}
        </div>
        <SvgContainer>
          {open ? (
            icons ? (
              icons.open
            ) : (
              <SvgAccordionOpen />
            )
          ) : icons ? (
            icons.closed
          ) : (
            <SvgAccordionClosed />
          )}
        </SvgContainer>
      </HeaderContainer>
      <BodyContainer $withTransition={withTransition} isOpen={open}>
        <BodyContent>{body}</BodyContent>
      </BodyContainer>
    </SectionContainer>
  );
};

type SectionItemProps = Pick<Props, "twoCols">;

const SectionItem = styled.div<SectionItemProps>`
  ${({ twoCols }) =>
    twoCols &&
    css`
      flex-basis: 48%;

      ${({ theme }) => theme.media.mobile} {
        flex-basis: 100%;
      }
    `}
`;

const SvgContainer = styled.div`
  display: flex;
  align-items: center;
`;

type AccordionWrapperProps = Pick<Props, "showDivider"> & {
  hasOpenBackground?: boolean;
};

const AccordionWrapper = styled.div<AccordionWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  column-gap: 4%;
  row-gap: ${({ showDivider, hasOpenBackground }) =>
    hasOpenBackground ? "0px" : showDivider ? "10px" : "20px"};
  width: 100%;
`;

type SectionContainerProps = Pick<
  SectionProps,
  "hasDivider" | "open" | "openBackground"
>;

export const SectionContainer = styled.div<SectionContainerProps>`
  padding: ${({ hasDivider, openBackground }) =>
    openBackground ? "16px 25px" : hasDivider ? "5px 12px" : "10px 12px"};
  width: 100%;
  ${({ open, openBackground }) =>
    open &&
    openBackground &&
    css`
      background: ${openBackground};
    `}

  ${({ theme }) => theme.media.mobile} {
    padding: ${({ openBackground }) => (openBackground ? "10px" : "0px")};
    row-gap: 10px;
  }
`;

interface TextProps {
  disabled?: boolean;
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
  cursor: pointer;
`;

const Selected = styled.p<TextProps>`
  font-family: var(--max-font-primary);
  font-size: 15;
  font-weight: 600;
  line-height: 22px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #999999;
    `}
`;

const BodyContainer = styled.div<{
  $withTransition?: boolean;
  isOpen: boolean;
}>`
  overflow: hidden;

  ${({ $withTransition, isOpen }) =>
    isOpen
      ? css`
          max-height: 5000px;
          transition: ${$withTransition
            ? "max-height 1.5s ease-in-out"
            : "none"};
        `
      : css`
          max-height: 0px;
          transition: ${$withTransition
            ? "max-height 1s cubic-bezier(0, 1, 0, 1)"
            : "none"};
        `};
`;

const BodyContent = styled.div`
  margin-top: 20px;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;
