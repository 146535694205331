import React from "react";
import styled from "styled-components";
import { Body1, H1 } from "melodies-source/Text";
import { RefreshIcon } from "Components/RefreshIcon";
import { Spinner } from "melodies-source/Spinner";
import { Item } from "./Item";
import { FlexColumn } from "Components/Flex";
import { useArtistEventContext } from "contexts/ArtistEventContext";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { formatNumber } from "Utils/format";

export const Stats: React.FC = () => {
  //const { visits } = useArtistEventContext();
  const {
    totals: entries,
    totalsLoading: entriesLoading,
    totalsError: entriesError,
    clicks,
    clicksLoading,
    clicksError,
    visits,
    visitsLoading,
    visitsError,
  } = useArtistEventContext();
  const { customApp } = useCustomAppContext();
  const eventAudiencePlural = customApp?.event.audience.plural || "Fans";

  const totalClicks =
    clicks?.reduce((total, elem) => total + elem.total, 0) ?? 0;

  return (
    <Container>
      <FlexColumn xCenter>
        {entriesLoading ? (
          <StyledSpinner />
        ) : entriesError ? (
          <StyledRefreshIcon />
        ) : (
          <H1>{formatNumber(entries.all)}</H1>
        )}
        <Body1>Total Entries</Body1>
      </FlexColumn>
      <Divider />
      <ItemsContainer>
        <Item
          label="Total Visits"
          total={visits?.total ?? 0}
          loading={visitsLoading}
          error={visitsError}
        />
        <Item
          label={`Unique ${eventAudiencePlural}`}
          total={Object.entries(visits?.users ?? {}).length}
          loading={visitsLoading}
          error={visitsError}
        />
        <Item
          label="Clicks"
          total={totalClicks}
          loading={clicksLoading}
          error={clicksError}
        />
      </ItemsContainer>
    </Container>
  );
};

const ItemsContainer = styled.div``;
const Divider = styled.div`
  background: #e6e9eb;
  width: 1px;
  height: 80px;
  margin: 0 30px;
`;

const StyledRefreshIcon = styled(RefreshIcon)`
  margin-top: 5px;
  color: #1b0076;
`;

const StyledSpinner = styled(Spinner)`
  margin: 20px 0 15px;
  transform: scale(0.7);
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  ${H1} {
    color: var(--main-color);
  }

  ${Body1} {
    color: var(--secondary-text-color);
  }
`;
