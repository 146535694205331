import { ArrayHelpers, FieldArray, Form, Formik } from "formik";
import { Button } from "melodies-source/Button";
import * as yup from "yup";
import { AlbumRow } from "./AlbumRow";
import { SortableList } from "./SortableList";
import { SpotifySearch } from "./SpotifySearch";
import { SectionFooter, SectionList } from "./components";
import { ALBUM_SCHEMA, Album } from "./types";

const ADD_ALBUMS_SCHEMA = yup.object().shape({
  albums: yup.array().of(ALBUM_SCHEMA).min(1, "Albums are required"),
});

interface AddAlbumsFormValues {
  albums: Album[];
}

interface AddAlbumsFormProps {
  onCancel?: () => void;
  onSubmit?: (albums: Album[]) => void;
  exclusions?: string[];
}

export const AddAlbumsForm = ({
  onCancel,
  onSubmit,
  exclusions,
}: AddAlbumsFormProps) => {
  const handleSubmit = (values: AddAlbumsFormValues) => {
    onSubmit?.(values.albums);
  };
  return (
    <Formik<AddAlbumsFormValues>
      initialValues={{ albums: [] }}
      validationSchema={ADD_ALBUMS_SCHEMA}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form>
          <SectionList>
            <FieldArray name="albums">
              {(helpers: ArrayHelpers<Album[]>) => (
                <SpotifySearch
                  type="album"
                  withCustomButton={false}
                  onSelect={(item) => helpers.push(item)}
                  exclusions={exclusions}
                >
                  <SortableList id="add-albums" helpers={helpers}>
                    {values.albums.map((album, index) => (
                      <AlbumRow
                        key={index}
                        index={index}
                        album={album}
                        helpers={helpers}
                        editable={false}
                      />
                    ))}
                  </SortableList>
                </SpotifySearch>
              )}
            </FieldArray>
            <SectionFooter>
              <Button variant="tertiary" type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" style={{ width: 140 }}>
                Save
              </Button>
            </SectionFooter>
          </SectionList>
        </Form>
      )}
    </Formik>
  );
};
