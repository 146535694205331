import * as React from "react";
import { SVGProps } from "react";
export const SvgErrorAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.921944,3.50253302 L10.9811474,3.60654848 L16.8842054,15.4126645 C16.9603553,15.5649642 17,15.7329019 17,15.9031781 C17,16.4685523 16.5722288,16.9340151 16.022689,16.993564 L15.9031781,17 L4.09706213,17 C3.92678592,17 3.75884816,16.9603553 3.60654848,16.8842054 C3.10086249,16.6313624 2.875845,16.040591 3.0683445,15.5224365 L3.11603483,15.4126645 L9.01909283,3.60654848 C9.12522538,3.3942834 9.2973414,3.22216737 9.50960648,3.11603483 C10.0152925,2.86319184 10.6229202,3.03764073 10.921944,3.50253302 Z M9.9,13.6 C9.40294373,13.6 9,14.0029437 9,14.5 C9,14.9970563 9.40294373,15.4 9.9,15.4 C10.3970563,15.4 10.8,14.9970563 10.8,14.5 C10.8,14.0029437 10.3970563,13.6 9.9,13.6 Z M10.5,7 L9.3,7 L9.3,12.4 L10.5,12.4 L10.5,7 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
