import { Concert } from "@max/common";
import { ReactComponent as CalendarIcon } from "assets/svg/calendar.svg";
import { ReactComponent as SaveIcon } from "assets/svg/save.svg";
import { ReactComponent as TrashIcon } from "assets/svg/trash.svg";
import { ReactComponent as UndoIcon } from "assets/svg/undo.svg";
import { Datepicker } from "Components/DatePicker";
import { FlexColumn, FlexRow } from "Components/Flex";
import { PlacesAutocomplete } from "Components/PlacesAutocomplete";
import { PartialWithFieldValue, Timestamp } from "firebase/firestore";
import { Formik } from "formik";
import { ConcertData } from "hooks/useUpcomingConcerts";
import { DateTime } from "luxon";
import { Button } from "melodies-source/Button";
import { Link } from "melodies-source/Link";
import { SvgEditAlt } from "melodies-source/Svgs/EditAlt";
import { Body1, H4, Label } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { useState } from "react";
import { getDateTime } from "Utils/date";

enum EditableField {
  Venue,
  Location,
}

interface EditConcertProps {
  concert: ConcertData;
  onDelete?: () => void;
  onBack?: () => void;
  onSubmit?: (patch: PartialWithFieldValue<Concert>) => void;
}

export const EditConcert = ({
  concert,
  onDelete,
  onBack,
  onSubmit,
}: EditConcertProps) => {
  const [field, setField] = useState<EditableField>();
  const date = getDateTime(concert.date);

  return (
    <FlexColumn gap="30px">
      <Body1>
        Edit the venue, location, and date/time of your event.
        <br />
        <br />
        Note: for an event with a SET.Live experience, the changes made here
        will be reflected on your SET experience as well.
      </Body1>
      <FlexColumn gap="18px">
        <FlexColumn gap="4px">
          <Label>Venue Name</Label>
          {field === EditableField.Venue ? (
            <Formik
              initialValues={{
                venue: concert.venue ?? "",
              }}
              onSubmit={async (values) => {
                setField(undefined);
                onSubmit?.({ venue: values.venue });
              }}
            >
              {({ values, submitForm, handleChange }) => (
                <FlexRow gap="10px" yCenter={true}>
                  <TextInput
                    name="venue"
                    value={values.venue}
                    rightIcon={<SaveIcon onClick={submitForm} />}
                    onChange={handleChange}
                    asString={false}
                  />
                  <UndoIcon onClick={() => setField(undefined)} />
                </FlexRow>
              )}
            </Formik>
          ) : (
            <FlexRow
              gap="6px"
              yCenter={true}
              onClick={() => setField(EditableField.Venue)}
            >
              <H4>{concert.venue}</H4> <SvgEditAlt />
            </FlexRow>
          )}
        </FlexColumn>
        <FlexColumn gap="4px">
          <Label>Location</Label>
          {field === EditableField.Location ? (
            <FlexRow gap="10px" yCenter={true}>
              <PlacesAutocomplete
                style={{ flex: 1 }}
                onChange={async ({
                  result,
                  placeName,
                  timeZone,
                  addressType,
                }) => {
                  setField(undefined);

                  onSubmit?.({
                    address: result.formatted_address,
                    coords: [
                      result.geometry.location.lat(),
                      result.geometry.location.lng(),
                    ],
                    geocode: JSON.parse(JSON.stringify(result)),
                    source: "manual",
                    state: "",
                    city: "",
                    country: "",
                    title: "",
                    venue_id: "",
                    artists: [],
                    timeZone,
                    addressType,
                    venue: placeName,
                  });
                }}
                placeholder="Enter a venue or address..."
              />
              <UndoIcon onClick={() => setField(undefined)} />
            </FlexRow>
          ) : (
            <FlexRow
              gap="6px"
              yCenter={true}
              onClick={() => setField(EditableField.Location)}
            >
              <Body1>
                {concert.address &&
                  ((concert.address.length || 0) > 20 ? (
                    <>
                      {concert.address?.split(",")[0]}
                      <br />
                      {concert.address?.slice(
                        concert.address?.indexOf(",") + 1,
                      )}
                    </>
                  ) : (
                    concert.address
                  ))}
              </Body1>
              <SvgEditAlt style={{ flexShrink: 0 }} />
            </FlexRow>
          )}
        </FlexColumn>
        <FlexColumn gap="4px">
          <Label>Date and time</Label>
          <Datepicker
            value={date}
            minDate={DateTime.now()}
            timeZone={concert.timeZone}
            onChange={async (value) => {
              onSubmit?.({
                date: Timestamp.fromDate(value.toJSDate()),
              });
            }}
            renderInput={
              <FlexRow gap="6px" yCenter={true}>
                <CalendarIcon
                  width={14}
                  height={14}
                  style={{ flexShrink: 0 }}
                />
                <Body1>{date.toFormat("LL/dd/yyyy • h:mm a (ZZZZ)")}</Body1>
                <SvgEditAlt style={{ flexShrink: 0 }} />
              </FlexRow>
            }
          />
        </FlexColumn>
      </FlexColumn>
      <div>
        <Link
          style={{ display: "inline-flex", alignItems: "center" }}
          onClick={onDelete}
        >
          <TrashIcon /> Delete
        </Link>
      </div>
      <FlexRow xEnd>
        <Button onClick={onBack}>Back to Shows</Button>
      </FlexRow>
    </FlexColumn>
  );
};
