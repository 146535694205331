import { DuplicateViews } from ".";

export const getViewText = (
  view: DuplicateViews,
  sourceEvent,
  companyName: string,
  eventTypeSingular: string,
  eventTypePlural: string,
) => {
  switch (view) {
    case "noConcerts":
      return {
        header: `Create a new ${eventTypeSingular}`,
        description: (
          <>
            Please provide the location, date, and time for the upcoming{" "}
            {eventTypeSingular}.
            <br />
            <br />
            The experience from <span>{sourceEvent?.venue}</span> will be copied
            onto this {eventTypeSingular}.
          </>
        ),
      };
    case "singleConcert":
      return {
        header: `Duplicate an upcoming ${eventTypeSingular}`,
        description: (
          <>
            The experience from <span>{sourceEvent?.venue}</span> will be copied
            onto the next upcoming {eventTypeSingular}:
          </>
        ),
      };
    case "multipleConcerts":
      return {
        header: `Choose one or more upcoming ${eventTypePlural}`,
        description: (
          <>
            The experience from <span>{sourceEvent?.venue}</span> will be copied
            onto the {eventTypeSingular}(s) you select.
            <br />
            <br />
            <i>
              Note: {eventTypePlural} with an existing {companyName}.Live
              experience will be replaced with this one.
            </i>
          </>
        ),
      };
    case "manualConcert":
      return {
        header: `Manually add ${eventTypeSingular}(s)`,
      };
    default:
      return {
        header: `Which ${eventTypeSingular} do you want to duplicate?`,
        description: (
          <>
            Please select which of your current published {companyName}.Live{" "}
            {eventTypePlural} you would like duplicated onto any upcoming{" "}
            {eventTypeSingular}(s).
            <br />
            <br />
            If you wish to make changes to this {eventTypeSingular}, you can
            edit it first.
          </>
        ),
      };
  }
};
