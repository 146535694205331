import { ProfileAvatar } from "Components/ProfileAvatar";
import { yupRequired } from "Utils/yupStrictPassword";
import { useUser } from "auth";
import { DocumentData } from "firebase/firestore";
import { Form, Formik } from "formik";
import { Button } from "melodies-source/Button";
import { ImageUpload } from "melodies-source/ImageUpload";
import { Modal } from "melodies-source/Modal";
import { Body2, H3 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

const Header = styled.div`
  margin: 32px 0;
`;

const Actions = styled.div`
  gap: 12px;
`;

interface Values {
  firstName: string;
  lastName: string;
}

const validationsSchema = yup.object().shape({
  firstName: yupRequired,
  lastName: yupRequired,
});

interface EditProfileProps extends RouteComponentProps {
  parent: string;
  profile?: DocumentData;
}

export const EditProfile = ({ parent, profile, history }: EditProfileProps) => {
  const { updateName, updatePhotoURL, user } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showUploader, setShowUploader] = useState(false);

  const handleClose = () => history.push(parent);

  const onSubmitForm = async (values: Values) => {
    try {
      setIsSubmitting(true);
      await updateName(values.firstName, values.lastName);
      toast.success("Profile updated.");
      handleClose();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUploadComplete = async (url: string) => {
    setShowUploader(false);
    await updatePhotoURL(url);
    toast.success("Profile picture updated.");
  };

  return (
    <Modal
      header={showUploader ? "Profile Image" : undefined}
      withCloseIcon={showUploader}
      isOpen={true}
      onClose={handleClose}
    >
      {showUploader ? (
        <ImageUpload
          uploadPath={`/user_profile/${user.uid}`}
          onComplete={onUploadComplete}
        />
      ) : (
        <>
          <ProfileAvatar
            url={profile?.photoURL ?? user.photoURL}
            onEdit={() => setShowUploader(true)}
            style={{ margin: "0 auto" }}
          />
          <Header
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <H3>Edit Profile Information</H3>
            <Body2>Here you can edit your name and profile photo.</Body2>
          </Header>
          <Formik<Values>
            initialValues={{
              firstName: profile?.name?.firstName ?? "",
              lastName: profile?.name?.lastName ?? "",
            }}
            validationSchema={validationsSchema}
            validateOnChange={false}
            onSubmit={onSubmitForm}
          >
            {({ values, errors, handleChange }) => {
              return (
                <Form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 32,
                  }}
                >
                  <TextInput
                    label="First Name"
                    placeholder="Add first name"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange("firstName")}
                    hasError={!!errors.firstName}
                    helperText={errors.firstName}
                  />
                  <TextInput
                    label="Last Name"
                    placeholder="Add last name"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange("lastName")}
                    hasError={!!errors.lastName}
                    helperText={errors.lastName}
                  />
                  <Actions style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      type="submit"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                    <Button
                      variant="secondary"
                      disabled={isSubmitting}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Actions>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </Modal>
  );
};
