import styled, { CSSProperties, keyframes } from "styled-components";

const CircularAnim = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;
const StyledCircle = styled.div<{ height?: number }>`
  display: inline-block;
  position: relative;
  width: ${({ height }) => height ?? 60}px;
  height: ${({ height }) => height ?? 60}px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ height }) => height ?? 60}px;
    height: ${({ height }) => height ?? 60}px;
    border: 3px solid #6979f8;
    border-radius: 50%;
    animation: ${CircularAnim} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #6979f8 transparent transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }

  & div:nth-child(2) {
    animation-delay: -0.3s;
  }

  & div:nth-child(3) {
    animation-delay: -0.15s;
  }

  & div:nth-child(4) {
    animation: none;
    border-radius: 50%;
    border: 3px solid #6979f833;
  }
`;

export const CircularLoader = ({
  height,
  sx,
}: {
  height?: number;
  sx?: CSSProperties;
}) => (
  <StyledCircle height={height} style={sx}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </StyledCircle>
);

export default CircularLoader;
