import { useState } from "react";
import { Modal } from "melodies-source/Modal";
import { Confirmation } from "melodies-source/Confirmation";
import { useArtistContext } from "Components";
import firebase from "firebase/compat/app";
import { setDoc } from "firebase/firestore";

export const TeammateDelete = ({ target: Target, member, ...props }) => {
  const { artistGroupDoc, getArtistGroup } = useArtistContext();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    await setDoc(
      artistGroupDoc.ref,
      {
        members: {
          [member?.uid]: firebase.firestore.FieldValue.delete(),
        },
      },
      { merge: true },
    );
    getArtistGroup();
    toggleModal();
  };

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <div>
      <Target {...props} onClick={toggleModal} />
      <Modal header="Delete Teammate" isOpen={isOpen} onClose={toggleModal}>
        <Confirmation
          confirmLabel="Delete"
          confirmVariant="destructive"
          loading={loading}
          onCancel={toggleModal}
          onConfirm={handleDelete}
        >
          <p>
            Are you sure you want to delete <b>{member.name}</b> from your
            teammates?
          </p>
        </Confirmation>
      </Modal>
    </div>
  );
};
