import React from "react";
import styled from "styled-components";
import { Body1, Body2 } from "melodies-source/Text";

interface Respondent {
  name: string;
  gender?: string;
  age?: number;
  city?: string;
  state?: string;
  location: string;
}

export interface OtherResponse {
  question: string;
  option: string;
}

interface FreeReponse {
  id: string;
  text: string;
  respondent: Respondent;
  otherReponses: OtherResponse[];
}

export interface Props {
  response: FreeReponse;
  showOtherResponses: boolean;
}

const FreeReponse: React.FC<Props> = ({
  response: { text, respondent, otherReponses },
  showOtherResponses,
}) => {
  const respondentInfo = [
    respondent.name,
    respondent.gender,
    respondent.age,
    respondent.city,
    respondent.state,
    respondent.location,
  ]
    .filter((elem) => !!elem)
    .join(" | ");

  return (
    <Container>
      <Body1>{text}</Body1>
      <Respondent>{respondentInfo}</Respondent>
      {showOtherResponses &&
        otherReponses.map(({ question, option }) => (
          <Body2 key={question}>
            {question} <b>{option}</b>
          </Body2>
        ))}
    </Container>
  );
};

export const Component = FreeReponse;

const Container = styled.div`
  margin-top: 20px;
  ${Body1} {
    color: var(--main-color);
  }

  ${Body2} {
    color: var(--secondary-text-color);

    b {
      font-weight: 700 !important;
    }
  }
`;

const Respondent = styled(Body2)`
  text-transform: capitalize;
`;
