import React from "react";
import { Bar, Container as BarContainer } from "./Bar";
import styled, { css } from "styled-components";

interface NumberCategory {
  singular: string;
  plural: string;
}

interface Image {
  src: string;
  alt: string;
}

export interface Item {
  label: string;
  value: number;
  unit?: NumberCategory;
  image?: Image;
}

export interface Props {
  mode?: "number" | "percentage";
  data: Item[];
  twoCols?: boolean;
  total?: number;
  loading?: boolean;
  impressions?: boolean;
}

export const InfoBars: React.FC<Props> = ({
  data,
  twoCols,
  mode = "number",
  total: propsTotal,
  impressions,
}) => {
  let total = propsTotal ?? data.reduce((total, item) => total + item.value, 0);

  if (impressions) {
    total = Math.max(300, ...data.map((item) => item.value));
  }

  return (
    <Container twoCols={twoCols} hasImage={!!data[0]?.image}>
      {data
        .sort((a, b) => b.value - a.value)
        .map((item) => (
          <Bar key={item.label} total={total} item={item} mode={mode} />
        ))}
    </Container>
  );
};

export const Component = InfoBars;

interface ContainerProps {
  twoCols: Props["twoCols"];
  hasImage: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  row-gap: 20px;
  column-gap: 10%;
  flex-wrap: wrap;
  margin-top: 16px;
  width: 100%;

  ${BarContainer} {
    flex-basis: 100%;
    ${({ twoCols, hasImage }) =>
      twoCols &&
      css`
        flex-basis: 45%;
        ${({ theme }) =>
          theme.media.mobile &&
          !hasImage &&
          css`
            flex-basis: 100%;
          `}
      `}
  }
`;
