import {
  CATEGORIES_OPTIONS_IDS,
  CategoryOptionId,
  PageModule,
  SubmitModule,
  TextModule,
  defaultProfiling,
  generateSurveyConfig,
} from "@max/common/dist/setfan";
import {
  SurveyCard,
  SurveyContainer,
} from "@max/common/dist/setfan/creator/helpers";
import { Divider, TextInput } from "Components";
import {
  Accordion,
  SectionContainer,
  SectionProps,
} from "Components/Accordion";
import { CATEGORIES_OPTIONS } from "Routes/SetFan/constants/profileCategories";
import { AnimatedCaret } from "Routes/styled";
import { Button } from "melodies-source/Button";
import { Modal } from "melodies-source/Modal";
import { Body1 } from "melodies-source/Text";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useBuilderContext } from "../BuilderContext";

const Container = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 70px;
`;

const Main = styled.div`
  flex: 1;
`;

const StyledAccordion = styled(Accordion)`
  flex-direction: column;
  flex-wrap: nowrap;

  ${SectionContainer} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Sidebar = styled.div`
  flex-shrink: 0;
`;

const Preview = styled.iframe`
  width: 328px;
  height: 463px;
  border-radius: 26px 26px 0px 0px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 22px;
  gap: 20px;
`;

const Save = styled(Button)`
  min-width: 156px;
`;

type Values = Partial<Record<CategoryOptionId, string>>;

interface EditCategoriesIntroTextFormProps {
  initialValues?: Values;
  displayedCategories?: CategoryOptionId[];
  onCancel?: () => void;
  onSave?: (values: Values) => void;
}

const EditCategoriesIntroTextForm = ({
  initialValues = {},
  displayedCategories = [...CATEGORIES_OPTIONS_IDS],
  onCancel,
  onSave,
}: EditCategoriesIntroTextFormProps) => {
  const { data } = useBuilderContext();
  const [openId, setOpenId] = useState<CategoryOptionId>(
    displayedCategories[0],
  );
  const [values, setValues] = useState(initialValues);
  const [init, setInit] = useState(false);
  const main = useRef<HTMLDivElement>(null);
  const iframe = useRef<HTMLIFrameElement>(null);
  const category = CATEGORIES_OPTIONS.find(({ id }) => id === openId);

  useEffect(() => {
    setTimeout(() => {
      main.current
        ?.querySelector<HTMLInputElement>(`input[name=${openId}]`)
        ?.focus();
    }, 0);
  }, [openId]);

  useEffect(() => {
    const handleMessage = (e: MessageEvent) =>
      setInit(e.source === iframe.current?.contentWindow);
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    if (!init) {
      return;
    }

    const survey = generateSurveyConfig({
      ...data,
      artistName: "test",
    });

    const page = new PageModule({
      compact: true,
      modules: [
        SurveyContainer(
          [
            SurveyCard({
              variant: "header",
              modules: [
                new TextModule({
                  label: data.fields.header.content,
                  tag: "title",
                  variants:
                    data.fields.header.content.en.length < 25
                      ? { mobile: "Headline3", desktop: "Headline2" }
                      : { mobile: "Headline4", desktop: "Headline3" },
                }),
              ],
              image: {
                src: data.fields.image.content.en,
                align: "left",
              },
              compact: true,
              style: { marginBottom: 30 },
            }),
          ],
          "header",
        ),
        SurveyContainer(
          [
            SurveyCard({
              compact: false,
              variant: "body",
              modules: [
                new TextModule({
                  label: { en: values[category.id] },
                  tag: "title",
                  variants:
                    values[category.id].length < 25
                      ? { mobile: "Headline3", desktop: "Headline2" }
                      : { mobile: "Headline4", desktop: "Headline3" },
                  style: { marginBottom: 15 },
                }),
              ],
            }),
          ],
          "body",
        ),
      ],
    }).toSurveyConfig();

    const profiling = defaultProfiling
      .find((list) => list.find(({ id }) => id.split(".")[1] === category.id))
      .find(({ id }) => id.split(".")[1] === category.id);

    page.modules[1].modules[0].modules.push(
      ...profiling.questions,
      new SubmitModule().toSurveyConfig(),
    );

    iframe.current?.contentWindow?.postMessage({
      survey: {
        ...survey,
        pages: [page],
        profiling: [],
        localProfiling: [],
        config: {
          ...survey.config,
          globalStyles:
            (survey.config.globalStyles += `html::-webkit-scrollbar{display: none;}*{pointer-events:none;}`),
        },
      },
    });
  }, [data, category, values, init]);

  const sections: SectionProps[] = CATEGORIES_OPTIONS.filter(({ id }) =>
    displayedCategories.includes(id),
  ).map((option) => ({
    id: option.id,
    header: option.header,
    body: (
      <TextInput
        name={option.id}
        label="Introduction Text"
        disabled={openId !== option.id}
        value={values[option.id]}
        onChange={(v) => setValues((p) => ({ ...p, [option.id]: v }))}
      />
    ),
    open: openId === option.id,
    onOpenChange: () => setOpenId(option.id),
  }));

  return (
    <Container>
      <Main ref={main}>
        <Body1 style={{ marginBottom: 22 }}>
          You can create your own introduction text for your categories. Please
          choose a category to customize, or leave blank.
        </Body1>
        <Divider />
        <StyledAccordion
          open={false}
          sections={sections}
          icons={{
            open: <AnimatedCaret direction="up" />,
            closed: <AnimatedCaret direction="down" />,
          }}
          showDivider={true}
        />
        <Divider />
      </Main>
      <Sidebar>
        <Preview
          ref={iframe}
          src={`${process.env.PUBLIC_URL}/setfanembed`}
          title="preview"
        />
        <Footer>
          <Button text={true} onClick={() => onCancel?.()}>
            Cancel
          </Button>
          <Save onClick={() => onSave?.(values)}>Save</Save>
        </Footer>
      </Sidebar>
    </Container>
  );
};

interface EditCategoriesIntroTextProps {
  initialValues?: Values;
  displayedCategories?: CategoryOptionId[];
  isOpen?: boolean;
  onClose?: () => void;
  onSave?: (values: Values) => void;
}

export const EditCategoriesIntroText = ({
  initialValues,
  displayedCategories,
  isOpen,
  onClose,
  onSave,
}: EditCategoriesIntroTextProps) => {
  return (
    <Modal
      variant="large"
      header="Customize Category Intro"
      isOpen={isOpen}
      onClose={onClose}
      withBackdropClose={false}
    >
      <EditCategoriesIntroTextForm
        initialValues={initialValues}
        displayedCategories={displayedCategories}
        onCancel={onClose}
        onSave={onSave}
      />
    </Modal>
  );
};
