import styled from "styled-components";
import { Select as MelodiesSelect } from "melodies-source/Select";

export const Select = styled(MelodiesSelect)`
  max-width: 250px;
  width: 20vw;

  & > div:nth-child(2) > div {
    & > p {
      width: 95%;
    }
  }

  & > div:nth-child(3) > div > div > div {
    min-width: unset;
  }

  & > div:nth-child(3) > div > div > div > div {
    display: table;
    table-layout: fixed;
    width: 100%;

    & > div {
      width: 44px;

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    & > * {
      display: table-cell !important;
      vertical-align: middle;
    }
  }

  p {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
    font-weight: 500;
  }

  ${({ theme }) => theme.media.tablet} {
    width: 15vw;
  }

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    max-width: 100%;

    p {
      width: 100%;
      max-width: 100%;
    }
  }
`;
