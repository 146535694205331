import * as React from "react";
import { SVGProps } from "react";
export const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16,12 C16.5522847,12 17,12.4477153 17,13 L17,16 C17,16.5522847 16.5522847,17 16,17 L4,17 C3.44771525,17 3,16.5522847 3,16 L3,13 C3,12.4477153 3.44771525,12 4,12 C4.55228475,12 5,12.4477153 5,13 L5,15 L15,15 L15,13 C15,12.4477153 15.4477153,12 16,12 Z M11,3 L10.999,9.176 L12.2928932,7.88289322 C12.6533772,7.52240926 13.2206082,7.49467972 13.6128994,7.79970461 L13.7071068,7.88289322 C14.0675907,8.24337718 14.0953203,8.81060824 13.7902954,9.20289944 L13.7071068,9.29710678 L10,13.0042136 L6.29289322,9.29710678 C5.90236893,8.90658249 5.90236893,8.27341751 6.29289322,7.88289322 C6.65337718,7.52240926 7.22060824,7.49467972 7.61289944,7.79970461 L7.70710678,7.88289322 L8.999,9.174 L9,3 L11,3 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
