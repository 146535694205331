import * as React from "react";
import { SVGProps } from "react";
export const SvgHelpAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10,2 C14.418278,2 18,5.581722 18,10 C18,14.418278 14.418278,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 Z M10.9648289,13.1026334 L9.09505703,13.1026334 L9.09505703,15 L10.9648289,15 L10.9648289,13.1026334 Z M9.98669202,5 C8.96641318,5 8.14242712,5.28134144 7.51473384,5.84402431 C6.94410358,6.35555419 6.6126871,6.96752994 6.5204844,7.67995156 L6.5,7.89669142 L8.21673004,8.11276165 C8.3365019,7.55007878 8.55608365,7.13144272 8.87547529,6.85685348 C9.19486692,6.58226424 9.59188847,6.44496962 10.0665399,6.44496962 C10.5589354,6.44496962 10.9504119,6.57663741 11.2409696,6.83997299 C11.5315272,7.10330858 11.6768061,7.41953635 11.6768061,7.78865631 C11.6768061,8.05424263 11.5947402,8.29732163 11.4306084,8.51789332 C11.4093156,8.54580239 11.3792395,8.57992348 11.3403802,8.62025658 L11.1974525,8.75989196 L11.1974525,8.75989196 L10.884249,9.03923025 L10.884249,9.03923025 L10.6095722,9.27204591 C10.5594011,9.31395454 10.5070342,9.35741616 10.4524715,9.40243079 C9.90684411,9.85257709 9.54309252,10.2577088 9.36121673,10.6178258 C9.20532319,10.9264975 9.11624117,11.3079276 9.09397067,11.7621161 L9.08858787,12.0303474 L9.08858787,12.0303474 L9.09505703,12.4476705 L10.7918251,12.4476705 C10.7829531,11.9255008 10.8262041,11.563133 10.9215779,11.3605672 C11.0050301,11.1833221 11.2031077,10.9561037 11.5158107,10.6789121 L11.6568441,10.557056 C11.9342954,10.32298 12.1766987,10.1067609 12.384054,9.90839895 L12.6688009,9.62424875 C12.8841849,9.39861757 13.0448064,9.20088802 13.1506654,9.03106009 C13.3835551,8.65743867 13.5,8.26130993 13.5,7.84267387 C13.5,7.08642809 13.1828264,6.42358767 12.5484791,5.8541526 C11.9141318,5.28471753 11.0602028,5 9.98669202,5 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
