import styled from "styled-components";

const RecordingIcon = ({
  label,
  className,
}: {
  label: JSX.Element;
  className?: string;
}) => {
  return (
    <RecordingContainer className={className}>
      {label}
      <RecordingIconContainer>
        <RecordingIconInt />
        <RecordingIconMask />
      </RecordingIconContainer>
    </RecordingContainer>
  );
};

export default RecordingIcon;

const RecordingContainer = styled.div`
  display: flex;
`;

const RecordingIconContainer = styled.div`
  position: relative;
  margin-left: 2px;
`;

const RecordingIconBase = styled.div`
  position: absolute;
  background: #e71e3d;
  width: 11px;
  height: 11px;
  border-radius: 50%;

  ${({ theme }) => theme.media.desktop} {
    top: 5px;
  }

  ${({ theme }) => theme.media.mobile} {
    width: 9px;
    height: 9px;
  }
`;

const RecordingIconInt = styled(RecordingIconBase)`
  box-shadow: 0px 0px 15px 1px #e71e3d;
`;

const RecordingIconMask = styled(RecordingIconBase)`
  box-shadow: 0px 0px 5px #e71e3d;
`;
