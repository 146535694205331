import { doc, getFirestore, writeBatch } from "firebase/firestore";
import { useState } from "react";
import { ConcertDetails } from "Routes/SetLive/Duplicate/types";

interface Options {
  artistId: string;
  onSuccess?: () => void;
}

interface ExecuteOptions {
  data: ConcertDetails<Date>[];
}

export const useBulkCreateConcerts = ({ artistId, onSuccess }: Options) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const execute = async ({ data }: ExecuteOptions) => {
    try {
      const batch = writeBatch(getFirestore());

      data.forEach((concert) => {
        batch.set(
          doc(
            getFirestore(),
            "artist_groups",
            artistId,
            "concerts",
            concert.id,
          ),
          concert,
        );
      });

      setLoading(true);

      await batch.commit();

      onSuccess?.();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return { execute, loading, error } as const;
};
