import { Radio } from "melodies-source/Selectable";
import { Caption, Label } from "melodies-source/Text";
import styled from "styled-components";

interface RadioGroupProps {
  options?: string[];
  onChange: (value: string) => void;
  value?: string;
  label?: string;
  hasError?: boolean;
  helperText?: string;
}

export const RadioGroup = ({
  label,
  options,
  onChange,
  value,
  hasError,
  helperText,
}: RadioGroupProps) => {
  return (
    <RadioGroupContainer>
      {label && <Label>{label}</Label>}
      <RadioGroupOptionContainer>
        {options?.map((o, index) => (
          <Radio
            key={`radio-group-${index}`}
            label={o}
            onChange={(v) => v && onChange(o)}
            value={!!(value === o)}
            style={{ margin: 0 }}
          />
        ))}
      </RadioGroupOptionContainer>
      {helperText && (
        <Caption
          style={{
            marginTop: "4px",
            color: `${hasError ? "#FF0000" : "#ffffff"}`,
          }}
        >
          {helperText}
        </Caption>
      )}
    </RadioGroupContainer>
  );
};

const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${Label} {
    color: #333;
  }
`;

const RadioGroupOptionContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 4px;
`;
