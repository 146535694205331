import * as React from "react";
import { SVGProps } from "react";
export const SvgChart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9,4 L9,11 L16,11 C16,14.8659932 12.8659932,18 9,18 C5.13400675,18 2,14.8659932 2,11 C2,7.13400675 5.13400675,4 9,4 Z M7.99842931,5 C5.14096171,5.47939223 3,7.96453974 3,10.958232 C3,14.2950083 5.70499166,17 9.04176797,17 C9.28970862,17 9.53416099,16.9853037 9.77427453,16.9567221 L7.99842931,12 L7.99842931,5 Z M15,12 L9.091,12 L10.766583,16.7538471 C12.9501045,16.1134952 14.61658,14.285415 15,12 Z M10,2 C14.418278,2 18,5.581722 18,10 L10,10 L10,2 Z M11,3 L11,9 L17,9 C16.5614872,5.93430884 14.0656912,3.43851276 11,3 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
