import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useClickOutside } from "hooks/useClickOutside";
import { ReactComponent as KebabMenuIcon } from "assets/svg/kebab-menu.svg";

const DROPDOWN_WIDTH = 178;

const Popover = ({
  children,
  className,
  forceSide,
  target,
}: {
  children: JSX.Element | JSX.Element[];
  className?: string;
  forceSide?: "left" | "right";
  target?: JSX.Element;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuSide, setMenuSide] = useState<"left" | "right">(
    forceSide ?? "right",
  );

  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, () => setMenuOpen(false));

  useEffect(() => {
    if (forceSide) {
      return;
    }

    if (containerRef?.current) {
      const xPos = containerRef.current.getBoundingClientRect().x;
      const toRightSide = window.innerWidth - xPos;

      if (toRightSide < DROPDOWN_WIDTH) {
        setMenuSide("left");
      } else {
        setMenuSide("right");
      }
    }
  }, [containerRef, forceSide]);

  return (
    <Container ref={containerRef} className={className}>
      <TargetContainer
        onClick={() => setMenuOpen((menuOpen) => !menuOpen)}
        ref={containerRef}
      >
        {!!target ? target : <KebabMenuIcon style={{ height: 24 }} />}
      </TargetContainer>
      <PopoverContainer menuOpen={menuOpen} side={menuSide}>
        {children}
      </PopoverContainer>
    </Container>
  );
};

export default Popover;

const Container = styled.div`
  position: relative;
`;

const PopoverContainer = styled.div<{
  menuOpen: boolean;
  side: "left" | "right";
}>`
  position: absolute;
  width: ${DROPDOWN_WIDTH}px;
  cursor: pointer;

  overflow-y: hidden;
  max-height: ${({ menuOpen }) => (menuOpen ? "500px" : "0px")};
  opacity: ${({ menuOpen }) => (menuOpen ? 1 : 0)};
  transition: opacity 0.25s, max-height 1s;

  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  border-radius: 6px;

  top: 40px;
  ${({ side }) => (side === "right" ? "left: 0px" : "right: 0px")};
  z-index: 10;
`;

const TargetContainer = styled.menu`
  display: flex;
  align-items: center;
  cursor: pointer;
  // enlarge click area a bit
  padding: 0 5px;
  margin: 0 -5px;
`;
