import { Button } from "melodies-source/Button";
import { Card } from "melodies-source/Card";
import styled, { CSSProperties } from "styled-components";
import { Font14, Font24 } from "../../../styled";

const ServiceCard = ({
  buttonText,
  headerText,
  bodyText,
  onButtonClick,
  sx,
}: {
  buttonText: string;
  headerText: string;
  bodyText: string;
  onButtonClick: () => void;
  sx?: CSSProperties;
}) => {
  return (
    <CardContainer style={sx} isElevated>
      <>
        <CardBody>
          <Font24
            fontWeight={700}
            style={{ marginBottom: 10, lineHeight: "30px" }}
            color="black"
          >
            {headerText}
          </Font24>
          <Font14 color="black">{bodyText}</Font14>
        </CardBody>
        <StyledButton variant="branded" onClick={onButtonClick}>
          {buttonText}
        </StyledButton>
      </>
    </CardContainer>
  );
};

export default ServiceCard;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardContainer = styled(Card)`
  padding: 32px 34px 32px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;

  ${({ theme }) => theme.media.tablet} {
    padding: 18px 24px;
  }

  ${({ theme }) => theme.media.mobile} {
    width: 90%;
    padding: 18px 24px;
  }
`;

const StyledButton = styled(Button)`
  width: 180px;
  margin-top: 10px;

  ${({ theme }) => theme.media.tablet} {
    width: 150px;
  }
`;
