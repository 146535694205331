import { useArtistContext } from "Components";
import { useState } from "react";
import { createSurveyBuilder } from "../firestore/createSurveyBuilder";
import { SurveyTemplateConfig } from "../dashboard/Components/Templates/types";

export const useCreateSurveyBuilder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const { id: artistGroupId, name: artistName } = useArtistContext();

  const create = async (data?: SurveyTemplateConfig) => {
    try {
      setIsLoading(true);
      return await createSurveyBuilder({
        artistGroupId,
        artistName,
        ...data,
      });
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return { create, error, isLoading };
};
