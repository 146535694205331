import styled, { css } from "styled-components";
import { Button as MelodiesButton } from "melodies-source/Button";
import { Spinner } from "melodies-source/Spinner";

interface Props {
  text?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

type ButtonProps = React.ComponentProps<typeof MelodiesButton>;

export const Button: React.FC<ButtonProps & Props> = ({
  children,
  disabled,
  loading,
  ...props
}) => {
  return (
    <StyledButton disabled={loading || disabled} {...props}>
      {loading ? <StyledSpinner /> : children}
    </StyledButton>
  );
};

const StyledButton = styled(MelodiesButton)<Props>`
  width: auto;

  ${({ text }) =>
    text &&
    css`
      background-color: transparent !important;
      color: #1b0076;
      border: none;
      :hover {
        box-shadow: none;
        color: #7666ad;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background: #e6e9eb !important;
    `}

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

const StyledSpinner = styled(Spinner)`
  & > div > div {
    background: #999;
  }
`;
