import * as React from "react";
import { SVGProps } from "react";
export const SvgSort = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7,5 L7,14.082 L9.14644661,11.9364466 C9.32001296,11.7628803 9.58943736,11.7435951 9.7843055,11.8785912 L9.85355339,11.9364466 C10.0271197,12.110013 10.0464049,12.3794374 9.91140884,12.5743055 L9.85355339,12.6435534 L6.5,15.9971068 L3.14644661,12.6435534 C2.95118446,12.4482912 2.95118446,12.1317088 3.14644661,11.9364466 C3.32001296,11.7628803 3.58943736,11.7435951 3.7843055,11.8785912 L3.85355339,11.9364466 L6,14.082 L6,5 L7,5 Z M16.5,13 C16.7761424,13 17,13.2238576 17,13.5 C17,13.7761424 16.7761424,14 16.5,14 L11.5,14 C11.2238576,14 11,13.7761424 11,13.5 C11,13.2238576 11.2238576,13 11.5,13 L16.5,13 Z M16.5,11 C16.7761424,11 17,11.2238576 17,11.5 C17,11.7761424 16.7761424,12 16.5,12 L11.5,12 C11.2238576,12 11,11.7761424 11,11.5 C11,11.2238576 11.2238576,11 11.5,11 L16.5,11 Z M16.5,9 C16.7761424,9 17,9.22385763 17,9.5 C17,9.77614237 16.7761424,10 16.5,10 L11.5,10 C11.2238576,10 11,9.77614237 11,9.5 C11,9.22385763 11.2238576,9 11.5,9 L16.5,9 Z M16.5,7 C16.7761424,7 17,7.22385763 17,7.5 C17,7.77614237 16.7761424,8 16.5,8 L11.5,8 C11.2238576,8 11,7.77614237 11,7.5 C11,7.22385763 11.2238576,7 11.5,7 L16.5,7 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
