import { Timestamp } from "firebase/firestore";

export const enum LayloIntegrationStatus {
  NOT_CONNECTED = "notConnected",
  CONNECTED = "connected",
}

export type LayloDocument = {
  lastSynced?: Timestamp;
  secretPath?: string;
};
