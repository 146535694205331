import React from "react";
import { QuestionContent as Content } from "..";
import styled from "styled-components";
import { Body1, Subtitle1 } from "melodies-source/Text";
import { Video } from "./Video";
import { ReactComponent as OpenIcon } from "assets/svg/open.svg";
import { Markdown } from "Components/Markdown";

interface Props {
  content: Content;
}

export const QuestionContent: React.FC<Props> = ({
  content: { answer, video, documents },
}) => {
  return (
    <Container>
      <span>
        {video && <Video video={video} />}
        <Markdown children={answer} />
      </span>
      {documents && (
        <div>
          <Subtitle1>Documents:</Subtitle1>
          <DocumentsContainer>
            {documents.map((doc) => (
              <Document key={doc.title}>
                <a href={doc.url} target="_blank" rel="noreferrer">
                  {doc.title}
                  <OpenIcon />
                </a>
              </Document>
            ))}
          </DocumentsContainer>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${Body1}, ${Subtitle1} {
    color: #333;
  }
`;

const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`;

const Document = styled(Subtitle1)`
  a {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--main-color);
  }
`;
