import React from "react";
import styled from "styled-components";
import { SurveyDetails } from "./SurveyDetail";
import { Surveys } from "./Surveys";
import { Route, Switch } from "react-router-dom";

const SetFan: React.FC = () => {
  return (
    <Container>
      <Switch>
        <Route
          path="/:artistId/reporting/set-fan/surveys/:surveyId"
          component={SurveyDetails}
        />
        <Route path="/:artistId/reporting/set-fan" component={Surveys} />
      </Switch>
    </Container>
  );
};

export default SetFan;

const Container = styled.div`
  padding: 30px 40px;

  ${({ theme }) => theme.media.mobile} {
    padding: 0;
  }
`;
