import { Caption, TextInput } from "Components";
import { ReactComponent as ComposeIcon } from "assets/svg/compose.svg";

import { pluralize } from "Utils/pluralize";
import { ReactComponent as AddTracks } from "assets/svg/add-tracks.svg";
import { Prompt } from "contexts/PromptContext";

import {
  ArrayHelpers,
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
} from "formik";
import { Button } from "melodies-source/Button";
import { Confirmation } from "melodies-source/Confirmation";
import {
  Modal,
  ModalHeaderClose,
  ModalHeaderContainer,
} from "melodies-source/Modal";
import { Checkbox } from "melodies-source/Selectable";
import { Body1, Body2 } from "melodies-source/Text";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { CoverField } from "./CoverField";
import { SongRow } from "./SongRow";
import { SortableList } from "./SortableList";
import { SpotifySearch } from "./SpotifySearch";
import {
  ModalTitle,
  Section,
  SectionFooter,
  SectionList,
  SelectControls,
} from "./components";
import { ALBUM_SCHEMA, Album, Song } from "./types";

const Header = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

interface AlbumFormProps {
  album: Album;
  isEditing?: boolean;
  onCancel?: () => void;
  onSubmit?: (values: Album) => void;
}

export const AlbumForm = ({
  album,
  isEditing = true,
  onCancel,
  onSubmit,
}: AlbumFormProps) => {
  const [confirmCancel, setConfirmCancel] = useState(false);
  const initialValues = useMemo(() => structuredClone(album), []);

  const handleCancel = (values: Album) => {
    if (JSON.stringify(initialValues) === JSON.stringify(values)) {
      onCancel?.();
    } else {
      setConfirmCancel(true);
    }
  };

  const handleSubmit = (values: Album) => {
    onSubmit?.(values);
  };

  const cancelHeader = isEditing
    ? `Cancel editing “${album.name}”?`
    : `Cancel adding tracks to “${album.name}”?`;

  return (
    <>
      <Modal header={cancelHeader} headerColor="navy" isOpen={confirmCancel}>
        <Confirmation
          cancelLabel={
            isEditing ? "Continue Editing" : "Continue Adding Tracks"
          }
          confirmLabel="Cancel"
          onCancel={() => setConfirmCancel(false)}
          onConfirm={() => onCancel?.()}
        >
          <Body1>
            You will lose any unsaved changes and be returned to the album.
          </Body1>
        </Confirmation>
      </Modal>
      <Formik<Album>
        initialValues={initialValues}
        validationSchema={ALBUM_SCHEMA}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, setFieldValue }) => {
          const selected = values.songs.filter(({ visible }) => visible).length;

          return (
            <Form>
              <Prompt
                when={JSON.stringify(initialValues) !== JSON.stringify(values)}
                message={cancelHeader}
              />
              <ModalHeaderContainer>
                <ModalTitle color="navy">
                  {isEditing ? (
                    <>
                      <ComposeIcon /> Edit “{album.name}”
                    </>
                  ) : (
                    <>
                      <AddTracks /> Add Tracks to “{album.name}”
                    </>
                  )}
                </ModalTitle>
                <ModalHeaderClose onClose={() => handleCancel(values)} />
              </ModalHeaderContainer>
              <SectionList>
                <FieldArray name="songs">
                  {(helpers: ArrayHelpers<Song[]>) => (
                    <>
                      <Section>
                        <Header>
                          <CoverField
                            src={values.src}
                            error={touched.src && errors.src}
                            onComplete={(url) => setFieldValue("src", url)}
                          />
                          <Field name="name">
                            {({ field, meta }: FieldProps) => (
                              <TextInput
                                label="Album Title"
                                hasError={meta.touched && !!meta.error}
                                helperText={meta.touched && meta.error}
                                asString={false}
                                {...field}
                              />
                            )}
                          </Field>
                        </Header>
                        <SelectControls>
                          <Checkbox
                            value={selected === values.songs.length}
                            label="Select/Deselect All"
                            onChange={(visible) =>
                              setFieldValue(
                                "songs",
                                values.songs.map((item) => ({
                                  ...item,
                                  visible,
                                })),
                              )
                            }
                          />
                          <Body2>
                            {selected} of{" "}
                            {pluralize(values.songs.length, "Track")} Selected
                          </Body2>
                        </SelectControls>
                        <SortableList id="album" helpers={helpers}>
                          {values.songs.map((song, index) => (
                            <SongRow
                              key={index}
                              index={index}
                              song={song}
                              helpers={helpers}
                            />
                          ))}
                        </SortableList>
                        {touched.songs &&
                          errors.songs &&
                          typeof errors.songs === "string" && (
                            <Caption style={{ marginTop: 20 }} error>
                              {errors.songs}
                            </Caption>
                          )}
                      </Section>
                      <SpotifySearch
                        album={album}
                        type="track"
                        onSelect={(song) => helpers.push(song)}
                      />
                    </>
                  )}
                </FieldArray>
                <SectionFooter>
                  <Button
                    variant="tertiary"
                    type="button"
                    onClick={() => handleCancel(values)}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" style={{ width: 140 }}>
                    Save
                  </Button>
                </SectionFooter>
              </SectionList>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
