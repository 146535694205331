import * as React from "react";
import { SVGProps } from "react";
export const SvgChecklist = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6,15 C6.55228475,15 7,15.4477153 7,16 C7,16.5522847 6.55228475,17 6,17 C5.44771525,17 5,16.5522847 5,16 C5,15.4477153 5.44771525,15 6,15 Z M16,15.5 L16,16.5 L10,16.5 L10,15.5 L16,15.5 Z M7.91197288,8 L8.66188322,8.63628756 L5.80782417,12 L4,10.1356813 L4.70603865,9.45103778 L5.7593917,10.5368683 L7.91197288,8 Z M16,9.5 L16,10.5 L10,10.5 L10,9.5 L16,9.5 Z M7.91197288,2 L8.66188322,2.63628756 L5.80782417,6 L4,4.13568132 L4.70603865,3.45103778 L5.7593917,4.5368683 L7.91197288,2 Z M16,3.5 L16,4.5 L10,4.5 L10,3.5 L16,3.5 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
