import { useRef, useState } from "react";
import styled from "styled-components";
import { useFanTableContext } from "./FanTableContext";
import { DateTime } from "luxon";
import { Body2 } from "melodies-source/Text";
import { SvgCloseLarge } from "melodies-source/Svgs/CloseLarge";
import { useClickOutside } from "hooks";
import { formatNumber } from "Utils/format";

export const PopupSummary = () => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const menuRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(menuRef, () => setShowMore(false));
  const { found, filters } = useFanTableContext();

  const sourceLocations = filters?.source?.reduce(
    (acc, v) =>
      v.type === "setlive" || v.type === "settheset" ? [...acc, v.name] : acc,
    [],
  );
  const sourceSurveys = filters?.source?.reduce(
    (acc, v) => (v.type === "setfan" ? [...acc, v.name] : acc),
    [],
  );

  const dateStartFilter = filters?.dateAddedStart?.toFormat("MMMM dd, yyyy");
  const dateEndFilter = filters?.dateAddedEnd?.toFormat("MMMM dd, yyyy");
  const updatedStartFilter = filters?.updatedStart?.toFormat("MMMM dd, yyyy");
  const updatedEndFilter = filters?.updatedEnd?.toFormat("MMMM dd, yyyy");

  const todayDate = DateTime.now().toLocaleString(DateTime.DATE_FULL);

  const filterSummary = `Showing 
  ${
    !!filters.gender
      ? `${formatNumber(found)} ${filters.gender[0]}${found > 1 ? "s" : ""}`
      : ` ${formatNumber(found)} contacts matching your search`
  } 
  ${!!filters.age ? `age ${filters.age.min}+` : ""} 
  ${
    !!filters?.location?.length
      ? `living near ${filters?.location?.map((l) => l.city).join(", ")}`
      : ""
  }`;
  const sourceEventSummary = `${
    sourceLocations?.length ? `who attended ${sourceLocations?.join(", ")}` : ""
  }`;
  const sourceSurveySummary = `${
    sourceSurveys?.length
      ? `${
          sourceLocations?.length ? "or" : "who"
        } responded to ${sourceSurveys?.join(", ")} surveys.`
      : ""
  }`;

  const dateAddedSummary = `Limited to fans added ${
    !!dateStartFilter
      ? `between ${dateStartFilter} and ${dateEndFilter ?? todayDate}`
      : `before ${dateEndFilter ?? todayDate}`
  }.`;
  const dateUpdatedSummary = `Last active ${
    !!updatedStartFilter
      ? `between ${updatedStartFilter} and ${updatedEndFilter ?? todayDate}`
      : `before ${updatedEndFilter ?? todayDate}`
  }.`;

  return (
    <>
      <span>
        {filterSummary.slice(0, 70)}
        {(filterSummary?.length > 70 ||
          !!sourceEventSummary ||
          !!sourceSurveySummary ||
          !!dateStartFilter ||
          !!updatedStartFilter) && (
          <>
            ...<ShowMore onClick={() => setShowMore(true)}>More</ShowMore>
          </>
        )}
      </span>
      {showMore && (
        <Popup ref={menuRef}>
          <Header>
            <Body2>Filter Parameters</Body2>
            <SvgCloseLarge onClick={() => setShowMore(false)} />
          </Header>
          <Body2>
            {filterSummary}{" "}
            {!!sourceLocations?.length ? sourceEventSummary : ""}
            {!!sourceSurveys?.length ? sourceSurveySummary : ""}
            <Body2>
              {!!dateStartFilter || !!dateEndFilter ? dateAddedSummary : ""}
            </Body2>
          </Body2>
          <Body2>
            {!!updatedStartFilter || !!updatedEndFilter
              ? dateUpdatedSummary
              : ""}
          </Body2>
        </Popup>
      )}
    </>
  );
};

const ShowMore = styled.span`
  display: inline;
  line-height: 22.5px;
  font-weight: 600;
  color: #1b0076;
  margin-left: 7px;
  cursor: pointer;
`;

const Popup = styled.div`
  position: absolute;
  top: 6px;
  left: 0;
  z-index: 30;
  width: 600px;
  min-height: 170px;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 3px #e1e0e0;
  border-radius: 6px;
  ${({ theme }) => theme.media.mobile} {
    width: 80vw;
    box-shadow: 0px 0px 5px 1px #e1e0e0;
  }
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  svg {
    cursor: pointer;
    margin: -10px -10px 0 0;
  }
  ${Body2} {
    font-weight: 600;
    margin-bottom: 15px;
  }
`;
