import * as yup from "yup";

export const REQUIRED_MSG = "This field is required";

export const yupStrictPassword = yup
  .string()
  .required(REQUIRED_MSG)
  .min(8, "Must contain at least 8 characters.")
  .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character.")
  .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character.")
  .matches(
    /^(?=.*[0-9])|(?=.*[!@#\$%\^&\*])/,
    "Must contain at least one number or special character, (e.g., ! @ # $ % ^ & *)",
  );

export const yupPasswordConfirmation = yup
  .string()
  .oneOf([yup.ref("password")], "Passwords do not match")
  .required(REQUIRED_MSG);

export const yupPassword = yup
  .string()
  .min(6, "Password should have at least 6 characters")
  .required(REQUIRED_MSG);

export const yupEmail = yup
  .string()
  .email("Please enter a valid email")
  .required(REQUIRED_MSG);

export const yupRequired = yup.string().nullable().required(REQUIRED_MSG);

export const yupOptional = yup.string().optional();

export const yupNumber = yup.string().matches(/^\d+$/, "Must be a number");
