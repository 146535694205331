import { DefaultLayout, useArtistContext } from "Components";
import styled from "styled-components";
import {
  ArtistName,
  ArtistImage,
  ArtistTeammates,
  ArtistQrCode,
  ArtistLinks,
  ArtistConcerts,
  Advanced,
} from "./Components";
import ArtistIntegrations from "./Components/ArtistIntegrations";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const Settings = () => {
  const { customApp } = useCustomAppContext();
  const { isGlobalAdmin } = useArtistContext();
  return (
    <DefaultLayout>
      <Content>
        <Header>Settings</Header>
        <ArtistName />
        <ArtistImage />
        <ArtistQrCode />
        <ArtistConcerts />
        {isGlobalAdmin && <Advanced />}
        {!customApp && <ArtistLinks />}
        <ArtistTeammates />
        {!customApp && <ArtistIntegrations />}
      </Content>
    </DefaultLayout>
  );
};

const Header = styled.h1`
  color: var(--header-text-color);
  font-family: Poppins;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 57px;
  padding: 32px 0 24px 0;
`;

const Content = styled.div`
  padding: 0 20px;
`;
