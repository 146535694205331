const Arrow = ({ fillColor }: { fillColor?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2029 3.2097C9.81061 2.90468 9.24338 2.93241 8.88289 3.29289L8.7997 3.3871C8.49468 3.77939 8.52241 4.34662 8.88289 4.70711L13.1751 9H3V11H13.1751L8.88289 15.2929L8.7997 15.3871C8.49468 15.7794 8.52241 16.3466 8.88289 16.7071C9.27342 17.0976 9.90658 17.0976 10.2971 16.7071L17.0042 10L10.2971 3.29289L10.2029 3.2097Z"
        fill={fillColor ?? "#979797"}
      />
      <mask
        id="mask0_1_3135"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="15"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2029 3.2097C9.81061 2.90468 9.24338 2.93241 8.88289 3.29289L8.7997 3.3871C8.49468 3.77939 8.52241 4.34662 8.88289 4.70711L13.1751 9H3V11H13.1751L8.88289 15.2929L8.7997 15.3871C8.49468 15.7794 8.52241 16.3466 8.88289 16.7071C9.27342 17.0976 9.90658 17.0976 10.2971 16.7071L17.0042 10L10.2971 3.29289L10.2029 3.2097Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1_3135)">
        <rect width="20" height="20" fill={fillColor ?? "#333333"} />
      </g>
    </svg>
  );
};

export default Arrow;
