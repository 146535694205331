import { useArtistGroupsByIdsQuery } from "hooks";

interface TooltipMessageProps {
  ids: string[];
}

export const TooltipMessage = ({ ids }: TooltipMessageProps) => {
  const { artistGroups } = useArtistGroupsByIdsQuery({ ids });

  return (
    <div
      style={{
        width: 245,
        lineHeight: 1.5,
        textAlign: "left",
        fontWeight: "500",
      }}
    >
      <p>
        Data (including contacts) from this event is being shared with these
        accounts:
      </p>
      <ul
        style={{
          listStyleType: "disc",
          listStylePosition: "inside",
          marginTop: 3,
        }}
      >
        {artistGroups?.map((ag) => (
          <li key={ag.id}>{ag.name}</li>
        ))}
      </ul>
    </div>
  );
};
