import React from "react";
import styled from "styled-components";
import { Item } from "../";

const COLORS = {
  10: "#1b0076",
  9: "#321a84",
  8: "#493391",
  7: "#5f4d9f",
  6: "#7666ad",
  5: "#8d80bb",
  4: "#a499c8",
  3: "#bbb3d6",
  2: "#d1cce4",
  1: "#e8e6f1",
};

export interface Props {
  data: Item[];
}

export const RatingBars: React.FC<Props> = ({ data }) => {
  const totalVotes = data.reduce((sum, item) => sum + item.count, 0);
  const maxVotes = Math.max(...data.map((item) => item.count));

  return (
    <Container>
      <BarsContainer>
        {data.map((item) => {
          const percentage =
            totalVotes > 0 ? Math.round((item.count / totalVotes) * 100) : 0;
          const barPercentage =
            maxVotes > 0
              ? parseFloat(((item.count / maxVotes) * 100).toFixed(1))
              : 0;
          return (
            <BarContainer key={item.score}>
              <Percentage>{percentage}%</Percentage>
              <Bar percentage={barPercentage} color={COLORS[item.score]} />
              <Score>{item.label}</Score>
            </BarContainer>
          );
        })}
      </BarsContainer>
    </Container>
  );
};

export const Component = RatingBars;

const Score = styled.span`
  color: #1b0076;
  font-size: 15px;
  text-align: center;
  height: 10px;

  ${({ theme }) => theme.media.mobile} {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 60px;
  }
`;

const Percentage = styled.span`
  color: #666666;
  font-size: 15px;
`;

interface BarProps {
  color: string;
  percentage: number;
}

const Bar = styled.div<BarProps>`
  background-color: ${({ color }) => color};
  border-radius: 2px;
  width: 100%;
  height: ${({ percentage }) => `${(percentage / 100) * 120}px`};

  ${({ theme }) => theme.media.mobile} {
    height: 30px;
    width: ${({ percentage }) =>
      `${((window.innerWidth - 50) / 2) * (percentage / 100)}px`};
  }
`;

const BarContainer = styled.div`
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: 100%;
  }
`;

const BarsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 2px;
  margin: 0 auto 50px;
  max-width: 700px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    height: auto;
    margin-bottom: 20px;
  }
`;

const Container = styled.div``;
