import React, { forwardRef } from "react";
import styled, { css } from "styled-components";

interface Props {
  children: React.ReactNode;
  className?: string;
  isElevated?: boolean;
  style?: any;
}

export const Card = forwardRef<HTMLDivElement, Props>(
  ({ children, isElevated = false, ...props }, ref) => {
    return (
      <Container ref={ref} isElevated={isElevated} {...props}>
        {children}
      </Container>
    );
  }
);

const Container = styled.div<Props>`
  padding: 32px 48px;
  border-radius: 12px;
  border: solid 1px #999999;

  ${(p) =>
    p.isElevated &&
    css`
      box-shadow: 0 4px 12px 0 var(--box-shadow-color);
      background-color: var(--component-background-color);
      border: none;
    `}

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 22px 32px;
  }
`;
