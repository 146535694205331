import React from "react";
import { FlexRow } from "Routes/styled";
import CollapseContainer from "../Components/CollapseContainer";
import Section from "./Section";
import FanLocations from "../Components/FanLocations";
import styled from "styled-components";
import { REPORTING_PADDING_DESKTOP, REPORTING_PADDING_MOBILE } from "../styled";
import { svg } from "svg";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { DocumentReference, doc, getFirestore } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { FanProfile as FanProfileType } from "models/fanProfile";
import { ChartCard, Props as ChartCardProps } from "../Components/ChartCard";
import { Spinner } from "melodies-source/Spinner";
import { useUser } from "auth";
import { H4, Subtitle1 } from "melodies-source/Text";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";

const SECTIONS = {
  demographics: {
    title: "Music & Social",
  },
  fooddrink: {
    title: "Food & Drink",
  },
  alcohol: {
    title: "Alcohol",
  },
  vehicle: {
    title: "Vehicles",
  },
  entertainment: {
    title: "Entertainment",
  },
  clothing: {
    title: "Clothing & Accessories",
  },
};

const FanProfile = () => {
  const { artistId } = useParams<{
    artistId: string;
  }>();
  const { claims } = useUser();
  const [fanProfile, fanProfileLoading] = useDocumentData<FanProfileType>(
    doc(
      getFirestore(),
      `artist_groups/${artistId}/artist_group_private/profile`,
    ) as DocumentReference<FanProfileType>,
  );
  const isMobile = useMobileMediaQuery();

  const ageChart: ChartCardProps["chart"] = {
    type: "donut",
    data: fanProfile
      ? Object.values(fanProfile.basic.data.demographics.age.data)
          .sort((a, b) => a.label.localeCompare(b.label))
          .map(({ id, label, value }) => ({
            id,
            label,
            value,
          }))
      : [],
  };

  const genderChart: ChartCardProps["chart"] = {
    type: "donut",
    data: fanProfile
      ? Object.values(fanProfile.basic.data.demographics.gender.data)
          .sort((a, b) => a.label.localeCompare(b.label))
          .map(({ id, label, value }) => ({
            id,
            label,
            value,
          }))
      : [],
  };

  const sections = Object.entries(SECTIONS).map(([sectionId, sectionData]) => (
    <Section
      key={sectionData.title}
      label={sectionData.title}
      data={{
        profile: fanProfile?.extended.data?.[sectionId],
        wordCloud: fanProfile?.wordCloud?.[sectionId],
      }}
    />
  ));

  const Message = isMobile ? Subtitle1 : H4;
  return (
    <ReportingBodyContainer>
      <CreatedWithContainer>
        <StyledBarChart />
        <Message>
          Created with data from your contacts, shows, and surveys to better
          understand your fans.
        </Message>
      </CreatedWithContainer>
      <CollapseContainer label="Demographics">
        <DemographicsChartsContainer>
          <ChartCard title="Age" chart={ageChart} loading={fanProfileLoading} />
          <ChartCard
            title="Gender"
            chart={genderChart}
            loading={fanProfileLoading}
          />
        </DemographicsChartsContainer>
        <React.Suspense fallback={<Spinner />}>
          {!fanProfileLoading && (
            <FanLocations
              dmaData={fanProfile?.basic?.data?.demographics?.dma?.data ?? []}
              worldData={
                fanProfile?.basic?.data?.demographics?.world?.data ?? []
              }
            />
          )}
        </React.Suspense>
      </CollapseContainer>
      {sections}
      <LastUpdate>
        {claims.admin &&
          `Last update: ${
            fanProfile?.updatedAt?.toDate().toString() ?? "not found"
          }`}
      </LastUpdate>
    </ReportingBodyContainer>
  );
};

export default FanProfile;

const CreatedWithContainer = styled(FlexRow)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  ${H4}, ${Subtitle1} {
    text-align: center;
    font-weight: 500;
    color: var(--header-text-color);
  }
  ${({ theme }) => theme.media.desktop} {
    padding: ${REPORTING_PADDING_DESKTOP};
  }

  ${({ theme }) => theme.media.mobile} {
    padding: ${REPORTING_PADDING_MOBILE};
    margin-bottom: 10px;
    svg {
      min-width: 25px;
    }
  }
`;

const ReportingBodyContainer = styled.div``;

const StyledBarChart = styled(svg.BarChartIcon)`
  margin-right: 5px;
`;

const DemographicsChartsContainer = styled.div`
  display: flex;
  gap: 2%;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    gap: 0px;
  }
`;

const LastUpdate = styled.span`
  padding: 20px;
  color: var(--text-color);
`;
