import styled, { CSSProperties } from "styled-components";

const LargeCheckIcon = ({
  height,
  width,
  sx,
}: {
  height?: string;
  width?: string;
  sx?: CSSProperties;
}) => {
  return (
    <CheckContainer height={height} width={width} style={sx}>
      <svg
        width="65%"
        height="45%"
        viewBox="0 0 48 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.1101 1.05095C43.4282 -0.320383 45.5988 -0.35407 46.9582 0.975705C48.213 2.20319 48.338 4.16337 47.313 5.53627L47.0328 5.86649L17.1059 37L0.931895 19.6641C-0.365875 18.2731 -0.300122 16.0841 1.07876 14.7749C2.35157 13.5665 4.29835 13.53 5.61106 14.6256L5.92527 14.9231L17.1806 26.9849L42.1101 1.05095Z"
          fill="#180171"
        />
      </svg>
    </CheckContainer>
  );
};

export default LargeCheckIcon;

const CheckContainer = styled.div<{ height?: string; width?: string }>`
  width: ${({ width }) => width ?? "81px"};
  height: ${({ height }) => height ?? "81px"};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(24, 1, 113, 0.2);
  border-radius: 50%;
`;
