import { Modal } from "melodies-source/Modal";
import { Select } from "melodies-source/Select";
import { TextInput } from "melodies-source/TextInput";
import { SvgMail } from "melodies-source/Svgs/Mail";

import { useArtistContext } from "Components";
import firebase from "firebase/compat/app";
import { ArtistGroupRole } from "@max/common/artists";
import { Affiliation, AffiliationMap } from "@max/common/dist/signup";
import { useState } from "react";
import styled from "styled-components";
import { Checkbox } from "melodies-source/Selectable";
import { Confirmation } from "melodies-source/Confirmation";
import { useCustomAppContext } from "contexts/CustomAppContext";

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  ${({ theme }) => theme.media.desktop} {
    flex-wrap: nowrap;
  }
`;

interface AddUserData {
  artistGroupId: string;
  email: string;
  role: Affiliation;
  isAdmin: boolean;
}

export const addUser = async ({
  artistGroupId,
  email,
  role,
  isAdmin,
}: AddUserData) => {
  const roles: ArtistGroupRole[] = [role === "artist" ? "artist" : "manager"];

  if (isAdmin) {
    roles.push("admin");
  }

  const app = firebase.app();
  const functions = app.functions("us-west2");
  return functions.httpsCallable("signup-account")({
    action: "add-user",
    artistGroupId,
    email,
    roles,
    affiliation: role,
  });
};

export const AddTeammateModal = ({ target: Target, ...props }) => {
  const { artistGroupDoc, getArtistGroup } = useArtistContext();
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [role, setRole] = useState<Affiliation>();
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { customApp } = useCustomAppContext();

  const handleSave = async () => {
    setLoading(true);

    await addUser({
      artistGroupId: artistGroupDoc.id,
      email,
      role,
      isAdmin,
    });

    getArtistGroup();
    setLoading(false);
    toggleModal();
  };

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <div>
      <Target {...props} onClick={toggleModal} />
      <Modal
        variant="medium"
        isOpen={isOpen}
        onClose={toggleModal}
        header="Add Another Teammate"
      >
        <Confirmation
          confirmLabel="Done"
          loading={loading}
          onCancel={toggleModal}
          onConfirm={handleSave}
        >
          <InputContainer>
            <TextInput
              label="Email address"
              placeholder="Add a teammate by email address..."
              leftIcon={<SvgMail />}
              value={email}
              onChange={setEmail}
              style={{ flexGrow: 1, minWidth: "60%" }}
            />
            <Select
              label="Role"
              options={Object.entries(customApp?.userRoles || AffiliationMap)
                .filter(([_, label]) => label.toLowerCase() !== "unknown")
                .map(([value, label]) => ({
                  value,
                  label,
                }))}
              value={role}
              onChange={(role: Affiliation) => setRole(role)}
            />
          </InputContainer>
          <Checkbox
            style={{ marginTop: 20 }}
            description="Admin users are able to add and remove other users on the account and may have access to billing information and fan PII."
            label="Make Admin"
            value={isAdmin}
            onChange={setIsAdmin}
          />
        </Confirmation>
      </Modal>
    </div>
  );
};
