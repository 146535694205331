import { Button, DefaultLayout } from "Components";
import { Card } from "melodies-source/Card";
import { Body1, H2, Metric } from "melodies-source/Text";
import { GlobalStyles } from "styled";
import styled from "styled-components";
import { CenterFlexRow, FlexColumn } from "Routes/styled";
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { SvgRight } from "melodies-source/Svgs/Right";
import { Preview } from "../Components/Preview";
import { SurveyInfo } from "./views/SurveyInfo";
import { SurveyQuestions } from "./views/SurveyQuestions";
import { Dispatch, SetStateAction, useState } from "react";
import { ProfilingQuestions } from "../slices/ProfilingQuestions";
import { THANK_YOU_FIELDS, ThankYouMessage } from "../slices/ThankYouMessage";
import { useSurveyBuilder } from "../hooks/useSurveyBuilder";
import {
  BuilderProvider,
  useBuilderContext,
  useConfigSlice,
} from "../Components/BuilderContext";
import { SongsQuestion } from "../Components/SongsQuestion";
import { CONTEST_FIELDS } from "../slices/Contest";
import { INFO_FIELDS } from "../slices/SurveyInfoContent";
import { Divider } from "../Components/Divider";

export const Wizard = ({
  match,
}: RouteComponentProps<{ surveyId: string }>) => {
  const [page, setPage] = useState<number | null>(0);
  const { survey, version } = useSurveyBuilder(match.params.surveyId);

  return (
    <DefaultLayout>
      <GlobalStyles grayBG />
      {survey && version && (
        <BuilderProvider survey={survey} version={version}>
          <Switch>
            <Route
              path={match.path}
              exact={true}
              render={() => <InnerCreator page={page} setPage={setPage} />}
            />
            <Route
              path={`${match.path}/setlist/:setlistId?`}
              render={() => <InnerSetlist backPath={match.url} />}
            />
          </Switch>
        </BuilderProvider>
      )}
    </DefaultLayout>
  );
};

interface InnerSetlistProps {
  backPath: string;
}

const InnerSetlist = ({ backPath }: InnerSetlistProps) => {
  return (
    <div style={{ padding: 40 }}>
      <SongsQuestion backPath={backPath} />
    </div>
  );
};

interface InnerCreatorProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const InnerCreator = ({ page, setPage }: InnerCreatorProps) => {
  const history = useHistory();
  const { artistId: artistGroupId } = useParams() as { artistId: string };
  const currentSlice = slices[page];
  const SliceComponent = currentSlice?.slice;
  const { onPublish, publishingStatus } = useBuilderContext();
  const isPublishNextStep = page === slices.length - 1;
  const { isValid, setFieldsTouched } = useConfigSlice(currentSlice.fields);

  return (
    <Frame>
      <CreatorCard isElevated>
        <TitleText>Survey Creator</TitleText>
        <Body1 style={{ color: "#333" }}>
          Build a survey to meet your needs. Customize with your own questions,
          images, text, and profiling questions to make the most out of your
          efforts.
        </Body1>
        <Divider style={{ marginTop: 40, marginBottom: 28 }} />
        {currentSlice?.title && (
          <CenterFlexRow>
            <H2 style={{ color: "#333", marginBottom: 12 }}>
              {currentSlice.title}
            </H2>
          </CenterFlexRow>
        )}
        <div style={{ minHeight: 400 }}>
          <SliceComponent />
        </div>
        <Divider style={{ marginTop: 28 }} />
        <Footer>
          <Button
            variant="outlined"
            onClick={() =>
              !page
                ? history.replace(`/${artistGroupId}/set-fan/surveys`)
                : setPage((page) => page - 1)
            }
            style={{ marginRight: 12 }}
          >
            {!page ? "Cancel" : "Back"}
          </Button>
          <CenterFlexRow>
            <Button
              loading={publishingStatus === "draft"}
              disabled={publishingStatus === "live"}
              variant="tertiary"
              onClick={() => onPublish({ status: "draft" })}
              style={{ marginRight: 12, minWidth: 156 }}
            >
              Save as Draft
            </Button>
            <Button
              loading={publishingStatus === "live"}
              disabled={publishingStatus === "draft"}
              variant="primary"
              style={{ minWidth: 156 }}
              onClick={async () => {
                if (!isValid) {
                  return setFieldsTouched();
                }

                if (isPublishNextStep) {
                  await onPublish({ status: "live" });
                } else {
                  setPage((page) => page + 1);
                }
              }}
              {...(!isPublishNextStep && { rightIcon: <SvgRight /> })}
            >
              {currentSlice?.buttonText}
            </Button>
          </CenterFlexRow>
        </Footer>
      </CreatorCard>
      <PreviewContainer>
        <Preview requestView={currentSlice?.requestView} />
      </PreviewContainer>
    </Frame>
  );
};

const slices = [
  {
    slice: SurveyInfo,
    buttonText: "Survey Questions",
    fields: [...INFO_FIELDS, ...CONTEST_FIELDS],
  },
  {
    slice: SurveyQuestions,
    buttonText: "Profiling Questions",
    fields: [],
  },
  {
    title: "Profiling Questions",
    slice: ProfilingQuestions,
    buttonText: "Thank You Message",
    requestView: "profiling",
    fields: [],
  },
  {
    title: "Thank You Message",
    slice: ThankYouMessage,
    buttonText: "Publish",
    requestView: "thanks",
    fields: THANK_YOU_FIELDS,
  },
];

const PreviewContainer = styled(FlexColumn)`
  position: sticky;
  top: 40px;
  width: 400px;
  flex-shrink: 0;
`;

const Footer = styled(CenterFlexRow)`
  justify-content: space-between;
  margin-top: 33px;
`;

const Frame = styled.div`
  position: relative;
  padding: 44px;
  width: 100%;
  display: flex;
  align-items: flex-start;

  ${({ theme }) => theme.media.mobile} {
    padding: 20px 0;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const TitleText = styled(Metric)`
  font-weight: 600;
  color: #333;
  margin-bottom: 13px;
`;

const CreatorCard = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  padding: 38px 45px;
  border: none;
  margin-right: 45px;
  flex: 1;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  }
`;
