import { Crop } from "react-image-crop";

export const getCroppedImg = (
  image: HTMLImageElement,
  crop: Crop,
  type = "image/jpeg"
) => {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width * scaleX;
  canvas.height = crop.height * scaleY;

  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return Promise.resolve(null);
  }

  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  return new Promise<Blob | null>((resolve, reject) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, type);
  });
};
