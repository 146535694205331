import * as React from "react";
import { SVGProps } from "react";
export const SvgBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1213203,3.29289322 C13.7608364,2.93240926 13.1936053,2.90467972 12.8013141,3.20970461 L12.7071068,3.29289322 L6,10 L12.7071068,16.7071068 C13.0976311,17.0976311 13.7307961,17.0976311 14.1213203,16.7071068 C14.4818043,16.3466228 14.5095338,15.7793918 14.204509,15.3871006 L14.1213203,15.2928932 L8.82921356,10 L14.1213203,4.70710678 C14.4818043,4.34662282 14.5095338,3.77939176 14.204509,3.38710056 L14.1213203,3.29289322 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
