import * as React from "react";
import { SVGProps } from "react";
export const SvgKey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13,11.5 L12,11.5 L12,10.5 L7.9584039,10.5006962 C7.72007807,11.9192103 6.48625501,13 5,13 C3.34314575,13 2,11.6568542 2,10 C2,8.34314575 3.34314575,7 5,7 C6.48660106,7 7.72065259,8.08129303 7.9585702,9.50029469 L18,9.5 L18,10.5 L17,10.5 L17,12.5 L16,12.5 L16,10.5 L15,10.5 L15,12.5 L14,12.5 L14,10.5 L13,10.5 L13,11.5 Z M5,8 C3.8954305,8 3,8.8954305 3,10 C3,11.1045695 3.8954305,12 5,12 C6.1045695,12 7,11.1045695 7,10 C7,8.8954305 6.1045695,8 5,8 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
