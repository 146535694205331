import { Dispatch, SetStateAction, useState } from "react";
import { doc, getFirestore } from "firebase/firestore";
import { useArtistContext } from "Components";
import IntegrationCard from "../IntegrationCard";
import styled, { CSSProperties } from "styled-components";
import firebase from "firebase/compat/app";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Button } from "melodies-source/Button";
import AlertIcon from "../AlertIcon";
import { useDesktopMediaQuery } from "hooks";
import {
  StripeDocument,
  StripeIntegrationStatus,
  StripeRedirects,
} from "./types";
import StripeLogo from "./StripeLogo";
import { generatePath } from "react-router-dom";
import StripeHeader from "./stripe-header.png";

const StripeIntegration = ({ sx }: { sx?: CSSProperties }) => {
  const { id: artistGroupId } = useArtistContext();

  const [stripeDoc] = useDocumentData<StripeDocument>(
    doc(getFirestore(), `artist_groups/${artistGroupId}`),
  );

  const integrationStatus = () => {
    switch (true) {
      case !!stripeDoc?.stripeConnectId &&
        stripeDoc?.stripeConnectStatus === "active":
        return StripeIntegrationStatus.CONNECTED;
      default:
        return StripeIntegrationStatus.NOT_CONNECTED;
    }
  };

  const openStripe = async (onConnect: Dispatch<SetStateAction<boolean>>) => {
    const redirectPath = generatePath("/settings", {
      redirectTo: StripeRedirects.artistGroupManagement,
      artistGroupId,
    });

    const { data } = await firebase
      .functions()
      .httpsCallable("shoutout-connectAccount")({
      redirectUri: `${window.location.origin}${redirectPath}`,
      artistGroupId,
    });

    if (data?.url) {
      window.location = data.url;
    }

    onConnect(false);
  };

  return (
    <StripeIntegrationCard
      integrationStatus={integrationStatus()}
      onGoToStripe={openStripe}
      sx={sx}
    />
  );
};

export default StripeIntegration;

const StripeIntegrationCard = ({
  integrationStatus,
  onGoToStripe,
  sx,
}: {
  integrationStatus: StripeIntegrationStatus;
  onGoToStripe: (onConnect: Dispatch<SetStateAction<boolean>>) => void;
  sx?: CSSProperties;
}) => {
  const [connectingAccount, setConnectingAccount] = useState(false);
  const isDesktop = useDesktopMediaQuery();

  if (integrationStatus === StripeIntegrationStatus.NEEDS_ATTENTION) {
    return (
      <IntegrationCard
        header={<img src={StripeHeader} alt="stripe header" />}
        body={
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div style={{ marginRight: 5 }}>
              <AlertIcon />
            </div>
            <ErrorText isDesktop={isDesktop}>
              There was a problem connecting to Stripe.
            </ErrorText>
          </div>
        }
        footer={<ContactUs />}
        sx={sx}
      />
    );
  }

  if (integrationStatus === StripeIntegrationStatus.CONNECTED) {
    return (
      <IntegrationCard
        header={<img src={StripeHeader} alt="stripe header" />}
        body={
          <StatusText isDesktop={isDesktop}>
            Your account is now connected!
          </StatusText>
        }
        footer={<ContactUs />}
        sx={sx}
      />
    );
  }

  // stripe is not yet connected
  return (
    <IntegrationCard
      header={<img src={StripeHeader} alt="stripe header" />}
      body="We use Stripe to make sure you get paid on time and to keep your personal bank and details secure. Click Go to Stripe to start accepting payments."
      footer={
        <Button
          onClick={() => {
            setConnectingAccount(true);
            onGoToStripe(() => setConnectingAccount(false));
          }}
          disabled={connectingAccount}
        >
          {!connectingAccount ? (
            <>
              <span style={{ marginRight: "0.2rem" }}>Go To</span>
              <StripeLogo fillColor={"#FFF"} height={40} />
            </>
          ) : (
            <span>Connecting...</span>
          )}
        </Button>
      }
      sx={sx}
    />
  );
};

const ContactUs = () => {
  const isDesktop = useDesktopMediaQuery(1245);

  return (
    <div
      style={{
        display: "flex",
        alignItems: isDesktop ? "baseline" : "center",
        flexDirection: isDesktop ? "row" : "column",
      }}
    >
      <FooterText>Need to make changes?</FooterText>
      <ContactUsLink
        onClick={() => {
          window.open(`${process.env.REACT_APP_SL_URL}/contact`);
        }}
        isDesktop={isDesktop}
      >
        Contact us
      </ContactUsLink>
    </div>
  );
};

const ContactUsLink = styled.div<{ isDesktop: boolean }>`
  color: var(--main-color);
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 12px)" : "13px")};
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
`;

const ErrorText = styled.div<{ isDesktop: boolean }>`
  color: #e45c52;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 12px)" : "13px")};
  line-height: 19.5px;
  margin-left: 5px;
`;

const FooterText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0 auto;
  padding-bottom: 5px;
  bottom: 0;
  margin-right: 5px;
`;

const StatusText = styled.div<{ isDesktop: boolean }>`
  color: #635bff;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 12px)" : "13px")};
  line-height: 19.5px;
  margin-left: 0.25rem;
`;
