import { H1, H3 } from "melodies-source/Text";
import React from "react";
import styled from "styled-components";

interface Data {
  score: number;
  maxScore: number;
}
export interface Props {
  data: Data;
}

const OverallScore: React.FC<Props> = ({ data: { score, maxScore } }) => {
  const portion = score / maxScore;
  const radius = 88;
  const strokeWidth = 12;
  const semiCircleLength = (2 * Math.PI * (radius - strokeWidth)) / 2;
  const initialX = strokeWidth;
  const y = radius - strokeWidth / 3;
  const rx = radius - strokeWidth;
  const ry = radius - strokeWidth;
  const finalX = radius * 2 - strokeWidth;
  const semiCirclePath = `M ${initialX} ${y} A ${rx},${ry} 0 1,1 ${finalX},${y}`;

  const width = radius * 2;
  const height = radius + strokeWidth / 2;

  return (
    <Container>
      <svg width={width} height={height}>
        <defs>
          <linearGradient id="gradient" x1="100%" y1="0%" x2="0%" y2="0%">
            <stop offset="0%" stopColor="#1b0076" />
            <stop offset="100%" stopColor="#e8e5f1" />
          </linearGradient>
        </defs>
        <path
          d={semiCirclePath}
          fill="none"
          stroke="#d4d4d5"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={`${semiCircleLength}, ${semiCircleLength}`}
        />
        <path
          d={semiCirclePath}
          fill="none"
          stroke="url(#gradient)"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={`${portion * semiCircleLength}, ${semiCircleLength}`}
        />
        <circle cx="0" cy="0" fill="#fff" r="3.5">
          <animateMotion
            dur="1s"
            path={semiCirclePath}
            keyPoints={`0;${portion}`}
            keyTimes="0;1"
            calcMode="linear"
            fill="freeze"
          />
        </circle>
      </svg>
      <Info>
        <Min>1</Min>
        <H3>Overall</H3>
        <Max>{maxScore}</Max>
      </Info>
      <Score>{score.toFixed(1).replace(".0", "")}</Score>
    </Container>
  );
};

export const Component = OverallScore;

const Score = styled(H1)`
  position: absolute;
  bottom: 20px;
`;

const MinMax = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-radius: 3px;
  height: 20px;
`;

const Max = styled(MinMax)`
  background-color: #1b0076;
  color: white;
`;

const Min = styled(MinMax)`
  background-color: #e8e5f1;
  color: #1b0076;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Container = styled.div`
  position: relative;
  width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 30px;
`;
