import { createContext, useContext, useState } from "react";

export interface UseState {
  qrCode?: boolean;
}

interface ContextProps {
  dialog: UseState;
  setDialog: React.Dispatch<React.SetStateAction<UseState>>;
}

export const DialogContext = createContext<ContextProps>({} as ContextProps);

interface ProviderProps {
  children?: React.ReactNode;
}

export const DialogProvider = ({ children }: ProviderProps) => {
  const [dialog, setDialog] = useState<UseState>();

  const values = { dialog, setDialog };

  return (
    <DialogContext.Provider value={values}>{children}</DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
