import { Concert } from "@max/common";
import {
  collection,
  CollectionReference,
  getFirestore,
  query,
  Timestamp,
  where
} from "firebase/firestore";
import { useRef } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Data } from "react-firebase-hooks/firestore/dist/firestore/types";

export type ConcertData = Data<Concert, "id", "ref">;

export const useUpcomingConcerts = (artistGroupId: string) => {
  const now = useRef(Timestamp.fromDate(new Date()));

  const [concerts, loading] = useCollectionData<Concert, "id", "ref">(
    query(
      collection(
        getFirestore(),
        "artist_groups",
        artistGroupId,
        "concerts",
      ) as CollectionReference<Concert>,
      where("date", ">", now.current),
      where("deletedAt", "==", null),
    ),
    {
      idField: "id",
      refField: "ref",
    },
  );

  return [concerts, loading] as const;
};
