import { ModalHeaderTitle } from "melodies-source/Modal";
import { H3 } from "melodies-source/Text";
import styled from "styled-components";

export const Action = styled.button`
  display: inline-flex;
  align-items: center;
  font-family: var(--max-font-primary);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${(p) => p.theme.colors.navy};
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  gap: 4px;
  text-decoration: none;

  &:not(:disabled) {
    cursor: pointer;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const SectionList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 24px;
`;

export const Section = styled.div`
  padding: 30px;
  border-radius: 12px;
  background: #f2f5f7;
`;

export const SectionHeader = styled.div<{ $withBorder?: boolean }>`
  margin-bottom: 20px;
  padding-bottom: ${(p) => p.$withBorder && "20px"};
  border-bottom: ${(p) => p.$withBorder && "1px solid #BCBCBC"};
`;

export const SectionTitle = styled(H3)`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(p) => p.theme.colors.navy};

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const StickyModalFooter = styled.div`
  position: sticky;
  margin: -34px -40px -34px -40px;
  padding: 34px 40px 34px 40px;
  bottom: 0;
  background: #fff;
  border-radius: 0 0 12px 12px;
  z-index: 1;
`;

export const SectionFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

export const ModalTitle = styled(ModalHeaderTitle)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SelectControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;
