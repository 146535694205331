import * as React from "react";
import { SVGProps } from "react";
export const SvgSearchAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.47222151,3 C11.494446,3 13.944443,5.44999702 13.944443,8.47222151 C13.944443,9.62214108 13.5897546,10.6892187 12.983812,11.5700201 L16.9968542,15.5826407 L15.5826407,16.9968542 L11.5690237,12.9844973 C10.688414,13.590022 9.62170747,13.944443 8.47222151,13.944443 C5.44999702,13.944443 3,11.494446 3,8.47222151 C3,5.44999702 5.44999702,3 8.47222151,3 Z M8.5,5 C6.56700338,5 5,6.56700338 5,8.5 C5,10.4329966 6.56700338,12 8.5,12 C10.4329966,12 12,10.4329966 12,8.5 C12,6.56700338 10.4329966,5 8.5,5 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
