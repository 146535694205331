import mg from "./mg.png";
import ns from "./ns.png";
import sl from "./sl.png";
import sld from "./sld.png";
import so from "./so.png";
import sts from "./sts.png";
import SetFanBG from "./set-fan-background.png";
import SetLiveBG from "./set-live-background.png";

export const mobileImages = {
  mg,
  ns,
  sl,
  sld,
  so,
  sts,
  SetFanBG,
  SetLiveBG,
};
