export const InstantMessage = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.6 4.35254C22.3456 4.35254 22.95 4.95695 22.95 5.70254V13.8025C22.95 14.5481 22.3456 15.1525 21.6 15.1525V19.2025L17.55 15.1525H9.45005C8.70446 15.1525 8.10005 14.5481 8.10005 13.8025V5.70254C8.10005 4.95695 8.70446 4.35254 9.45005 4.35254H21.6ZM6.75005 11.1025V12.4525H5.73755C5.55115 12.4525 5.40005 12.6036 5.40005 12.79V18.865C5.40005 19.0514 5.55115 19.2025 5.73755 19.2025H14.5125C14.6989 19.2025 14.85 19.0514 14.85 18.865V16.5025H16.2V19.2025C16.2 19.9481 15.5956 20.5525 14.85 20.5525H8.10005L5.40005 23.2525V20.5525C4.65446 20.5525 4.05005 19.9481 4.05005 19.2025V12.4525C4.05005 11.707 4.65446 11.1025 5.40005 11.1025H6.75005ZM9.45005 5.70254H21.6V13.8025H20.25V15.9434L18.1092 13.8025H9.45005V5.70254ZM16.2 9.75254V11.1025H10.8V9.75254H16.2ZM20.25 8.40254V7.05254H10.8V8.40254H20.25Z"
        fill="black"
      />
    </svg>
  );
};
