import {
  BaseFont,
  FlexRow,
  Font12,
  Font14,
  Font15,
  Font16,
  Font20,
  Font26,
  Font38,
} from "Routes/styled";
import Modal from "../Modal";
import styled from "styled-components";
import { useArtistContext } from "Components";
import { useState } from "react";
import { Checkbox } from "../Checkbox";
import { Textarea } from "melodies-source/Textarea";
import SetFanKnockout from "svg/SetFanKnockout";
import { Button } from "melodies-source/Button";
import { FlexColumn } from "Components/Flex";
import { useDesktopMediaQuery } from "hooks";
import { doc, getFirestore, serverTimestamp, setDoc } from "firebase/firestore";
import { useUser } from "auth";

const SetFanInterestModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose?: () => void;
}) => {
  const isDesktop = useDesktopMediaQuery();
  const { name, id: artistGroupId } = useArtistContext();
  const { user } = useUser();
  const [formValues, setFormValues] = useState({ helpList: {}, learnFrom: {} });
  const [freeResponse, setFreeResponse] = useState("");
  const [showValidation, setShowValidation] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!freeResponse) {
      setShowValidation(true);
      return;
    }

    setSubmitting(true);

    await setDoc(doc(getFirestore(), "artist_interest_setfan", artistGroupId), {
      ...formValues,
      howCanWeHelp: freeResponse,
      submitted: serverTimestamp(),
      submittedBy: user.uid,
    });

    setSubmitting(false);
    setFormSubmitted(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose?.();
        setFormValues({ helpList: {}, learnFrom: {} });
        setShowValidation(false);
      }}
      headerText={
        isDesktop ? (
          <Font26>Getting started with SET.Fan</Font26>
        ) : (
          <MobileTitle>Getting started with SET.Fan</MobileTitle>
        )
      }
    >
      {formSubmitted ? (
        <FlexColumn
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            height: isDesktop ? "400px" : "100%",
            maxWidth: "440px",
          }}
        >
          <Font38 fontWeight={600} style={{ marginBottom: 22 }}>
            Thank you!
          </Font38>
          <Font16 fontWeight={500}>
            Your responses have been submitted. A SET representative will reach
            out shortly.
          </Font16>
        </FlexColumn>
      ) : (
        <>
          <BodyContainer style={{ marginBottom: 17 }}>
            <Font15>
              We'd love to build out a SET.Fan experience to help you start
              getting to know all the fans who interact with {name} on social
              media. Please let us know a bit about your goals, and we'd be
              happy to put together a sample digital engagement for you to see.
            </Font15>
          </BodyContainer>
          <BodyContainer style={{ marginBottom: 27 }}>
            {Object.entries(formQuestions).map(([qKey, question]) => (
              <FormSegment key={qKey}>
                <Font20 as="div" fontWeight={500} style={{ marginBottom: 20 }}>
                  {question.header}
                </Font20>
                {question.options.map((opt) => (
                  <Checkbox
                    key={opt.value}
                    label={opt.label}
                    value={formValues[qKey][opt.value]}
                    onChange={(e) =>
                      setFormValues((formValues) => ({
                        ...formValues,
                        [qKey]: {
                          ...formValues[qKey],
                          [opt.value]: e,
                        },
                      }))
                    }
                  />
                ))}
              </FormSegment>
            ))}
            <Font14 fontWeight={500}>Let us know how we can help you*</Font14>
            <Textarea
              value={freeResponse}
              onChange={(e) => {
                if (!e) {
                  setShowValidation(true);
                } else {
                  setShowValidation(false);
                }
                setFreeResponse(e);
              }}
              placeholder="Type your response"
              hasError={showValidation}
            />
            {showValidation && (
              <Font12 fontWeight={500}>
                This is a required field and cannot be blank.
              </Font12>
            )}
          </BodyContainer>
        </>
      )}
      <FooterContainer>
        <SetFanKnockout height={isDesktop ? 34 : 26} />
        <SubmitButton
          variant="secondary"
          disabled={submitting || (!freeResponse && !formSubmitted)}
          onClick={!formSubmitted ? handleSubmit : () => onClose?.()}
        >
          {submitting ? "Submitting..." : formSubmitted ? "Close" : "Submit"}
        </SubmitButton>
      </FooterContainer>
    </Modal>
  );
};

export default SetFanInterestModal;

const BodyContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  background: rgba(0, 83, 109, 0.6);
  padding: 26px 28px;

  ${({ theme }) => theme.media.mobile} {
    padding: 17px 19px;
  }
`;

const SubmitButton = styled(Button)`
  width: 156px;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    margin-bottom: 12px;
  }
`;

const FooterContainer = styled(FlexRow)`
  position: relative;
  justify-content: space-between;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;
    align-items: center;
    bottom: 0;
  }
`;

const FormSegment = styled.div`
  margin-bottom: 29px;
`;

const MobileTitle = styled(BaseFont)`
  font-size: 18px;
  line-height: 26px;
`;

const formQuestions = {
  helpList: {
    header:
      "Here are some things SET.Fan can help with. Which are important to you?",
    options: [
      { label: "Supporting an upcoming tour", value: "upcomingTour" },
      { label: "Supporting a music drop", value: "musicDrop" },
      { label: "Supporting a merch drop", value: "merchDrop" },
      { label: "Generally adding to my fan email list", value: "emailList" },
      { label: "Generally adding to my fan phone list", value: "phoneList" },
      {
        label: "Engaging fans on social media with a fun interactive activity",
        value: "interactiveActivity",
      },
      { label: "Other", value: "helpListOther" },
    ],
  },
  learnFrom: {
    header:
      " These are things you might learn from SET.Fan. Which are important to you?",
    options: [
      {
        label: "Fan consumer info to help find sponsors",
        value: "findSponsors",
      },
      {
        label: "Social media/streaming habits to help target marketing efforts",
        value: "marketingEfforts",
      },
      { label: "Fan locations to help plan", value: "merchDrop" },
      {
        label: "Fan merch/creative preferences",
        value: "merchCreativePreferences",
      },
      { label: 'Who the "super fans" are', value: "superFans" },
      { label: "Other", value: "learnFromOther" },
    ],
  },
};
