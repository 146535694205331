const IncompleteIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79024 0.430743L6.84098 0.519899L11.9007 10.6394C11.966 10.77 12 10.9139 12 11.0599C12 11.5445 11.6333 11.9434 11.1623 11.9945L11.0599 12H0.940339C0.794388 12 0.650441 11.966 0.519899 11.9007C0.0864536 11.684 -0.106419 11.1776 0.058581 10.7335L0.0994584 10.6394L5.15922 0.519899C5.25019 0.337957 5.39772 0.190429 5.57966 0.0994584C6.01311 -0.117264 6.53393 0.0322635 6.79024 0.430743ZM5.91436 9.08523C5.48831 9.08523 5.14293 9.43061 5.14293 9.85666C5.14293 10.2827 5.48831 10.6281 5.91436 10.6281C6.34041 10.6281 6.68579 10.2827 6.68579 9.85666C6.68579 9.43061 6.34041 9.08523 5.91436 9.08523ZM5.39995 3.4288H6.42852V8.05737H5.39995V3.4288Z"
        fill="#E45C52"
      />
    </svg>
  );
};

export default IncompleteIcon;
