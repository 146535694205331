export function formatNumber(
  numToFormat: number | string,
  options?: {
    abbreviated?: boolean;
    toFixed?: number;
    locales?: string | string[];
    intlFormatOptions?: Intl.NumberFormatOptions;
  },
) {
  // unneeded guard but if an empty string/undefined/null return empty string
  if (typeof numToFormat !== "number" && !numToFormat) {
    console.info(`numToFormat was unparseable ${numToFormat}`);
    return "";
  }

  if (typeof numToFormat === "string") {
    const parsedNum = Number(numToFormat);
    if (isNaN(parsedNum)) {
      console.info(
        `${numToFormat} is not a number that can be parsed, returning original string`,
      );
      return numToFormat;
    }
    numToFormat = parsedNum;
  }

  if (options?.abbreviated) {
    if (!options.toFixed || options.toFixed < 0) {
      options.toFixed = 2;
    }

    let abbrLocale = Array.isArray(options?.locales)
      ? options?.locales[0]
      : options?.locales;

    const { billion, million, thousand } = getAbbreviations(abbrLocale);

    switch (true) {
      case numToFormat / 1_000_000_000 > 1:
        return `${(numToFormat / 1_000_000_000).toFixed(
          options.toFixed,
        )} ${billion}`;
      case numToFormat / 1_000_000 > 1:
        return `${(numToFormat / 1_000_000).toFixed(
          options.toFixed,
        )} ${million}`;
      case numToFormat / 1_000 > 1:
        return `${(numToFormat / 1_000).toFixed(options.toFixed)} ${thousand}`;
      default:
        return numToFormat.toLocaleString();
    }
  }

  return Intl.NumberFormat(options?.locales, options?.intlFormatOptions).format(
    numToFormat,
  );
}

function getAbbreviations(
  locale = "en-US",
): Record<"billion" | "million" | "thousand", string> {
  switch (locale) {
    case "en-US":
    default:
      return { billion: "Bil", million: "Mil", thousand: "K" };
  }
}
