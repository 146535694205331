import React from "react";
import { Modal } from "melodies-source/Modal";
import { Body1 } from "melodies-source/Text";
import styled from "styled-components";
import { Button } from "melodies-source/Button";

interface Props {
  isOpen: boolean;
  name: string;
  subject: string;
  parentSubject: string;
  onClose: () => void;
  onDelete: () => void;
  loading: boolean;
}

export const ModalDelete: React.FC<Props> = ({
  name,
  subject,
  parentSubject,
  isOpen,
  onClose,
  onDelete,
  loading,
}) => {
  return (
    <Modal
      header={`Delete ${subject}`}
      isOpen={isOpen}
      onClose={onClose}
      withCloseIcon
    >
      <Body1>
        Are you sure you want to delete <b>{name}</b> from this {parentSubject}?
      </Body1>
      <Buttons>
        <Button variant="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onDelete} loading={loading}>
          Delete
        </Button>
      </Buttons>
    </Modal>
  );
};

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 30px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;
  }
`;
