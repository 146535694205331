import { ReactComponent as RefreshIcon } from "assets/svg/refresh.svg";
import styled from "styled-components";

const Replace = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.navy};
  cursor: pointer;
  margin-top: 8px;

  svg {
    width: 18px;
    height: 18px;
    object-fit: contain;
    color: ${({ theme }) => theme.colors.navy};
  }
`;

interface ReplaceImageButtonProps {
  onClick?: () => void;
}

export const ReplaceImageButton = ({ onClick }: ReplaceImageButtonProps) => {
  return (
    <Replace onClick={onClick}>
      <RefreshIcon /> Replace
    </Replace>
  );
};
