import * as React from "react";
import { SVGProps } from "react";
export const SvgInstantMessage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5,8 L5,9 L4.25,9 C4.11192881,9 4,9.11192881 4,9.25 L4,13.75 C4,13.8880712 4.11192881,14 4.25,14 L10.75,14 C10.8880712,14 11,13.8880712 11,13.75 L11,12 L12,12 L12,14 C12,14.5522847 11.5522847,15 11,15 L6,15 L4,17 L4,15 C3.44771525,15 3,14.5522847 3,14 L3,9 C3,8.44771525 3.44771525,8 4,8 L5,8 Z M16,3 C16.5522847,3 17,3.44771525 17,4 L17,10 C17,10.5522847 16.5522847,11 16,11 L16,14 L13,11 L7,11 C6.44771525,11 6,10.5522847 6,10 L6,4 C6,3.44771525 6.44771525,3 7,3 L16,3 Z M16,4 L7,4 L7,10 L13.4142136,10 L15,11.5857864 L15,10 L16,10 L16,4 Z M12,7 L12,8 L8,8 L8,7 L12,7 Z M15,5 L15,6 L8,6 L8,5 L15,5 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
