import React, { useState } from "react";
import { GtbRegion } from "@max/common/src/thirdparty/gtb";
import {
  Query,
  QueryDocumentSnapshot,
  collection,
  deleteField,
  doc,
  getFirestore,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { toast } from "react-hot-toast";
import { Modal } from "melodies-source/Modal";
import { Body1 } from "melodies-source/Text";
import { useCollection } from "react-firebase-hooks/firestore";
import { Profile } from "@max/common/src/user";
import { MemberCard } from "./MemberCard";
import { Button } from "melodies-source/Button";
import { ReactComponent as AddIcon } from "assets/svg/plus.svg";
import styled from "styled-components";
import { MemberModal } from "./MemberModal";
import { ModalDelete } from "../../Components/ModalDelete";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { GtbCustomApp } from "custom/companies/gtb/types";

interface Props {
  region: QueryDocumentSnapshot<GtbRegion>;
  isOpen: boolean;
  onClose: () => void;
}

export const MembersModal: React.FC<Props> = ({ region, isOpen, onClose }) => {
  const [profiles] = useCollection<Profile>(
    query(
      collection(getFirestore(), "profiles"),
      where("__name__", "in", [
        "-",
        ...Object.keys(region?.data().members || {}),
      ]),
    ) as Query<Profile>,
  );

  const [memberModalIsIopen, setMemberModalIsIopen] = useState(false);
  const [selectedMember, setSelectedMember] =
    useState<QueryDocumentSnapshot<Profile>>(null);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { customApp } = useCustomAppContext<GtbCustomApp>();

  const handleMemberModalOpen = (member?: QueryDocumentSnapshot<Profile>) => {
    setSelectedMember(member || null);
    setMemberModalIsIopen(true);
  };

  const handleMemberDeleteModalOpen = (
    member?: QueryDocumentSnapshot<Profile>,
  ) => {
    setSelectedMember(member || null);
    setModalDeleteIsOpen(true);
  };

  const handleMemberDelete = async () => {
    setLoading(true);
    let members = { [selectedMember.id]: deleteField() };

    try {
      await setDoc(
        doc(getFirestore(), `${customApp.regionsCollection}/${region.id}`),
        {
          members,
          updatedAt: new Date(),
        },
        { merge: true },
      );
      toast.success("Member deleted successfully!");
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    setModalDeleteIsOpen(false);
  };

  const { name } = selectedMember?.data() || {};
  const memberName = `${name?.firstName} ${name?.lastName}`;

  const members =
    profiles?.docs.sort((a, b) =>
      a.data().name?.firstName?.localeCompare(b.data().name?.firstName),
    ) || [];

  return (
    <Modal
      isOpen={isOpen}
      header="Edit Members"
      onClose={onClose}
      withBackdropClose={!(memberModalIsIopen || modalDeleteIsOpen)}
      withCloseIcon
    >
      <Body1>You can add, edit, or delete members for this region.</Body1>
      {members.map((doc) => (
        <MemberCard
          key={doc.id}
          member={doc}
          role={region?.data().roles?.[doc.id] || ""}
          onEdit={handleMemberModalOpen}
          onDelete={handleMemberDeleteModalOpen}
        />
      ))}
      <ButtonAdd
        leftIcon={<AddIcon />}
        onClick={() => handleMemberModalOpen()}
        text
      >
        Add Member
      </ButtonAdd>
      <MemberModal
        isOpen={memberModalIsIopen}
        onClose={() => setMemberModalIsIopen(false)}
        region={region}
        member={selectedMember}
      />
      <ModalDelete
        subject="Member"
        name={memberName}
        parentSubject="program"
        isOpen={modalDeleteIsOpen}
        onClose={() => setModalDeleteIsOpen(false)}
        onDelete={handleMemberDelete}
        loading={loading}
      />
    </Modal>
  );
};

const ButtonAdd = styled(Button)`
  width: fit-content;
  padding: 0;
`;
