import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { PasswordPairForm } from "./PasswordPairForm";
import { RenewCredentialsForm } from "./RenewCredentialsForm";
import { Modal } from "melodies-source/Modal";

interface EditPasswordProps extends RouteComponentProps {
  parent: string;
}

export const EditPassword = ({ parent, history }: EditPasswordProps) => {
  const [shouldRenewCredentials, setShouldRenewCredentials] = useState(false);
  const handleClose = () => history.push(parent);

  return (
    <Modal
      header={shouldRenewCredentials ? "Verify Login" : "Update Password"}
      withCloseIcon={false}
      isOpen={true}
      onClose={handleClose}
    >
      {shouldRenewCredentials ? (
        <RenewCredentialsForm
          onSucess={() => setShouldRenewCredentials(false)}
        />
      ) : (
        <PasswordPairForm
          onError={() => setShouldRenewCredentials(true)}
          onClose={handleClose}
        />
      )}
    </Modal>
  );
};
