import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import remarkDirective from "remark-directive";
import { visit } from "unist-util-visit";
const myRemarkPlugin = () => {
  return (tree) => {
    visit(tree, (node) => {
      if (
        node.type === "textDirective" ||
        node.type === "leafDirective" ||
        node.type === "containerDirective"
      ) {
        if (node.name === "match") {
          const data = node.data || (node.data = {});

          data.hName = "span";
          data.hProperties = {
            style: { color: "#775197", fontWeight: 600 },
          };
        }

        if (node.name === "br") {
          const data = node.data || (node.data = {});

          data.hName = "br";
        }
      }
    });
  };
};

export const Markdown = (props) => {
  return (
    <StyledMarkdown
      {...props}
      remarkPlugins={[remarkDirective, myRemarkPlugin]}
    />
  );
};

const StyledMarkdown = styled(ReactMarkdown)`
  * {
    font: revert;
  }

  strong {
    font-weight: 600;
  }

  ol,
  ul {
    list-style: revert;
    padding-left: 20px;
  }
`;
