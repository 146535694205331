import { useUser } from "auth";
import { useMobileMediaQuery } from "hooks";
import { Radio } from "melodies-source/Selectable";
import { Body1, Body2 } from "melodies-source/Text";

interface ControlsProps {
  single: boolean;
  onSingleChange?: (single: boolean) => void;
}

export const Controls = ({ single, onSingleChange }: ControlsProps) => {
  const { claims } = useUser();
  const isMobile = useMobileMediaQuery();
  const Text = isMobile ? Body2 : Body1;

  return (
    <div>
      <Radio
        value={single}
        label="Add one show"
        onChange={() => onSingleChange?.(true)}
      />
      {claims.admin && (
        <Radio
          value={!single}
          label="Add multiple shows"
          onChange={() => onSingleChange?.(false)}
        />
      )}
      {!single && (
        <Text style={{ marginTop: 20 }}>
          This tool will intelligently add multiple shows to your SET account
          that have been copied from another webpage.
          <br /> <br />
          Start by highlighting and copying a webpage with a list of shows you
          want to import.
        </Text>
      )}
    </div>
  );
};
