import { Button, TopBarLayout } from "Components";
import { Link } from "Components/Link";
import coverBackgroundImage from "assets/images/cover-background.jpg";
import { ReactComponent as UserIcon } from "assets/svg/user.svg";
import { useUser } from "auth";
import { doc, getFirestore } from "firebase/firestore";
import { Avatar } from "melodies-source/Avatar";
import { Body1, H1, H2 } from "melodies-source/Text";
import { colors } from "melodies-source/Theme/colors";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import styled, { css } from "styled-components";
import { EditPassword } from "./EditPassword";
import { EditProfile } from "./EditProfile";
import { useRef, useState } from "react";
import { useCustomAppContext } from "contexts/CustomAppContext";

const Wrapper = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 20px;
`;

const Card = styled.div`
  border-radius: 20px;
  background: var(--component-background-color);
  box-shadow: 0px 0px 50px 0px var(--box-shadow-color);
  margin: 0 auto;
  max-width: 876px;
`;

const Cover = styled.div`
  height: 165px;
  margin-bottom: -165px;
  border-radius: 20px 20px 0 0;
  background: ${({ theme }) =>
    theme.custom?.pages.account.header.background ||
    `linear-gradient(
      0deg,
      rgba(27, 0, 118, 0.5) 0%,
      rgba(27, 0, 118, 0.5) 100%
    ),
    url(${coverBackgroundImage}) center / cover no-repeat, #190235`};
  ${({ theme }) =>
    !theme.custom &&
    css`
      background-blend-mode: darken, normal, normal;
    `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 43px 0 74px 0;
  margin: 0 auto;
  max-width: 380px;

  h1 {
    color: white;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 32px 20px;
  }
`;

const StyledAvatar = styled(Avatar)`
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  width: 145px;
  height: 145px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 16px;
    width: 100px;
    height: 100px;
  }
`;

const StyledUserIcon = styled(UserIcon)`
  && {
    width: 70%;
    height: 70%;
    color: ${({ theme }) => theme.colors.black20};

    ${({ theme }) => theme.mediaQueries.mobile} {
      width: 22px;
      height: 22px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: stretch;
  margin-top: 48px;
`;

export const Account = ({ match, history, location }: RouteComponentProps) => {
  const { user } = useUser();
  const [state] = useState<{ artistId?: string }>(location.state ?? {});
  const [profile] = useDocumentData(
    doc(getFirestore(), `profiles/${user.uid}`),
  );
  const { customApp } = useCustomAppContext();

  return (
    <>
      <TopBarLayout>
        <Wrapper>
          <div>
            <Card>
              <Cover />
              <Content>
                <H1 style={{ textAlign: "center" }}>My Account</H1>
                <StyledAvatar
                  imgUrl={user.photoURL}
                  icon={user.photoURL ? undefined : <StyledUserIcon />}
                  bgColor={colors.gray2}
                  style={{ marginBottom: 8 }}
                />
                <Link
                  style={{ marginBottom: 23 }}
                  onClick={() => history.push(`${match.path}/profile`)}
                >
                  Edit Profile
                </Link>
                <H2 style={{ textAlign: "center" }}>{user.displayName}</H2>
                <Body1 style={{ textAlign: "center" }}>{user.email}</Body1>
                <Actions>
                  <Button
                    onClick={() => history.push(`${match.path}/password`)}
                  >
                    Update Password
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      if (state.artistId) {
                        history.push(state.artistId);
                      } else {
                        history.push("/");
                      }
                    }}
                  >
                    Back to{" "}
                    {customApp ? `${customApp?.company.name} Events` : "SET"}{" "}
                    Portal
                  </Button>
                </Actions>
              </Content>
            </Card>
          </div>
        </Wrapper>
      </TopBarLayout>
      <Switch>
        <Route
          path={`${match.path}/password`}
          render={(props) => <EditPassword parent={match.path} {...props} />}
        />
        <Route
          path={`${match.path}/profile`}
          render={(props) => (
            <EditProfile parent={match.path} profile={profile} {...props} />
          )}
        />
      </Switch>
    </>
  );
};
