import { formatNumber } from "Utils/format";
import { Card } from "melodies-source/Card";
import React from "react";
import styled from "styled-components";

interface Props {
  icon: React.ReactElement;
  total: number;
  title: string;
  className?: string;
}
export const Stats: React.FC<Props> = ({ className, icon, total, title }) => {
  return (
    <StyledCard className={className} isElevated>
      <IconTotal>
        {icon}
        <Total>{formatNumber(total)}</Total>
      </IconTotal>
      <span>{title}</span>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1;
`;

const IconTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Total = styled.span`
  font-size: 45px;
  font-weight: 700;
`;
