import { FlexColumn, FlexRow } from "Components/Flex";
import styled, { css } from "styled-components";
import { Spinner } from "melodies-source/Spinner";

export interface BarChartItemProps {
  label: string;
  value: number;
  highlight?: boolean;
  remaining: number;
}

interface BarChartProps {
  data: BarChartItemProps[];
  loading: boolean;
}

export const BarChart = ({ data, loading }: BarChartProps) => {
  let total = 0;
  if (data?.length) {
    total = Math.max.apply(
      null,
      data.map((item) => item.value),
    );
  }

  return (
    <Wrapper>
      <VerticalLine />
      {data.map((item, index) => {
        const percent = (item.value / total) * 100;
        const isOdd = index % 2 === 1;

        if (!item.remaining && index > 12) {
          return null;
        }

        return (
          <LineItem key={`${item.label}-${index}`}>
            <Label small={isOdd} highlight={item.highlight}>
              {item.label}
            </Label>
            <BarContainer>
              {item.value > 0 ? (
                <Bar style={{ width: `calc(${percent}% + 8px)` }}>
                  <Value>{item.value.toLocaleString()}</Value>
                </Bar>
              ) : (
                <Line small={isOdd} />
              )}
            </BarContainer>
          </LineItem>
        );
      })}
      {loading && (
        <LoadingContainer>
          <Spinner color="#A499C8" />
        </LoadingContainer>
      )}
    </Wrapper>
  );
};

const LoadingContainer = styled(FlexColumn)`
  position: absolute;
  left: calc(50% - 30px);
  top: calc(50% - 12px);
`;

const Wrapper = styled(FlexColumn)`
  align-items: flex-start;
  align-self: flex-start;
  width: 100%;
  padding: 16px 50px 16px 0;
  position: relative;
`;

const LineItem = styled(FlexRow)`
  align-items: center;
  width: 100%;
  & + & {
    margin-top: 4px;
  }
`;

interface LabelProps {
  small?: boolean;
  highlight?: boolean;
}

const Label = styled(FlexRow)<LabelProps>`
  color: #666666;
  flex: 0 0 24px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  text-align: right;
  justify-content: flex-end;
  margin-right: 6px;

  ${(props) =>
    props.small &&
    !props.highlight &&
    css`
      color: #999999;
      font-weight: 400;
      font-size: 8px;
    `};

  ${(props) =>
    props.highlight &&
    css`
      color: var(--main-color);
      font-weight: 600;
    `};
`;

const Bar = styled.div`
  background: var(--main-color);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
  position: relative;
  transition: width 100ms linear;
`;

const BarContainer = styled(FlexRow)`
  align-items: center;
  height: 16px;
  flex-grow: 1;
`;

const Value = styled.p`
  color: #666666;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  position: absolute;
  right: -32px;
  width: 24px;
  white-space: nowrap;
`;

const Line = styled.div<{ small?: boolean }>`
  display: inline-flex;
  background-color: #999999;
  width: ${(props) => (props.small ? 3 : 6)}px;
  height: 1px;
  margin-bottom: 1px;
`;

const VerticalLine = styled.div`
  display: block;
  background-color: #999999;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 29px;
`;
