import * as React from "react";
import { SVGProps } from "react";
export const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10,2 C14.418278,2 18,5.581722 18,10 C18,14.418278 14.418278,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 Z M15.1925368,5.92361398 C14.8247726,5.57056035 14.2570939,5.55441032 13.871108,5.86737599 L13.7786177,5.95246948 L8.51400499,11.4360013 L6.23480844,8.9667212 L6.14400126,8.87983379 C5.76421476,8.55937351 5.19632865,8.56440978 4.82172489,8.91019786 C4.44712113,9.25598594 4.39674264,9.82165546 4.68584469,10.2258245 L4.76520155,10.3232814 L8.48572724,14.3538509 L15.2213923,7.33753313 L15.3026414,7.24164804 C15.5995985,6.84321479 15.560301,6.27666762 15.1925368,5.92361398 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
