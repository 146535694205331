const MusicAltIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="118"
      height="118"
      viewBox="0 0 118 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.3 76.7002C100.249 80.3324 95.9882 84.5139 89.5032 86.8577C81.6571 89.6934 73.5268 88.7652 71.3435 84.7846C69.1602 80.804 73.7507 75.2783 81.5967 72.4427C86.1858 70.7841 90.8722 70.4132 94.409 71.1875L94.4 41.8902L47.2 46.6102V88.5002C47.1492 92.1324 42.8882 96.3139 36.4032 98.6577C28.5571 101.493 20.4268 100.565 18.2435 96.5846C16.0602 92.604 20.6507 87.0783 28.4967 84.2427C33.0858 82.5841 37.7722 82.2132 41.309 82.9875L41.3 23.6002L100.3 17.7002V76.7002Z"
        fill="#E8E5F1"
      />
    </svg>
  );
};

export default MusicAltIcon;
