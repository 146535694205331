import * as React from "react";
import { SVGProps } from "react";
export const SvgShowLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12,6 C15.6818983,6 19.0152317,8 22,12 C19.0152317,16 15.6818983,18 12,18 C8.31810167,18 4.98476833,16 2,12 C4.98476833,8 8.31810167,6 12,6 Z M12.2794237,8.00488432 L12.1996403,8.00489531 C14.3160315,8.10892112 16,9.85780461 16,12 C16,14.209139 14.209139,16 12,16 C9.790861,16 8,14.209139 8,12 C8,9.85780461 9.68396847,8.10892112 11.8003597,8.00489531 L12,8 C9.47961728,8 7.1101386,9.18540133 4.84854191,11.6768312 L4.562,12 L4.59769516,12.0409626 C6.85135961,14.6293007 9.21131263,15.9071427 11.7205763,15.9951157 L12,16 C14.5203827,16 16.8898614,14.8145987 19.1514581,12.3231688 L19.437,12 L19.4023048,11.9590374 C17.1486404,9.37069927 14.7886874,8.0928573 12.2794237,8.00488432 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
