import styled from "styled-components";
import { useFanTableContext } from "..";
import { Filter } from "./Filter";
import { Radio } from "melodies-source/Selectable";

const StyledFilter = styled(Filter)`
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    box-shadow: none;
    margin-bottom: 0px;
  }
`;

export const Gender = () => {
  const { filters, setFilters } = useFanTableContext();

  return (
    <StyledFilter>
      <Radio
        style={{ flex: 1 }}
        onChange={() => {
          setFilters({
            gender: null,
          });
        }}
        value={!filters?.gender}
        label="All Genders"
      />
      <Radio
        style={{ flex: 1 }}
        onChange={() => {
          setFilters({ gender: ["female"] });
        }}
        value={filters?.gender?.includes("female")}
        label="Female Only"
      />
      <Radio
        style={{ flex: 1 }}
        onChange={() => {
          setFilters({ gender: ["male"] });
        }}
        value={filters?.gender?.includes("male")}
        label="Male Only"
      />
    </StyledFilter>
  );
};
