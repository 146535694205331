import { sessionApi } from "@musicaudienceexchange/firebase-auth-ui";
import { getUrl } from "Utils/getUrl";
import { useState } from "react";
import styled from "styled-components";
import { useQueryParams } from "./useQueryParams";

const Message = styled.div`
  padding: 16px;
  font-size: 14px;
  font-weight: 300px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.black80};
  border: 1px solid ${(props) => props.theme.colors.yellow20};
  background-color: ${(props) => props.theme.colors.yellow10};
  margin-top: 26px;
  border-radius: 8px;
  span {
    font-weight: bold;
  }
`;

export const useEmailLogin = () => {
  const [email, setEmail] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { redirect } = useQueryParams(["redirect"]);

  const login = async (email: string, path: string = redirect) => {
    try {
      setIsSendingEmail(true);
      setIsEmailSent(false);

      const success = await sessionApi.login(email, getUrl({ path }));

      if (success) {
        setEmail(email);
        setIsEmailSent(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSendingEmail(false);
    }
  };

  const ui = (
    <>
      {isEmailSent && (
        <Message>
          An email to access your account has been sent to <span>{email}</span>
        </Message>
      )}
    </>
  );

  return {
    login,
    ui,
    isSendingEmail,
  } as const;
};
