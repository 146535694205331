import * as React from "react";
import { SVGProps } from "react";
export const SvgMusic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17,3 L17,13 C16.9914027,13.6156334 16.2691969,14.3243628 15.1700355,14.7216124 C13.8402007,15.2022299 12.4621715,15.0449129 12.0921212,14.370235 C11.7220708,13.6955571 12.5001298,12.7590052 13.8299645,12.2783876 C14.6077759,11.9972777 15.4020747,11.9344052 16.0015288,12.0656444 L16,7.1 L8,7.9 L8,15 C7.9914027,15.6156334 7.26919689,16.3243628 6.17003546,16.7216124 C4.84020069,17.2022299 3.46217152,17.0449129 3.09212116,16.370235 C2.72207079,15.6955571 3.50012978,14.7590052 4.82996454,14.2783876 C5.60777594,13.9972777 6.40207466,13.9344052 7.00152881,14.0656444 L7,4 L17,3 Z M16,4.1 L8,4.9 L8,6.9 L16,6.1 L16,4.1 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
