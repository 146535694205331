// https://github.com/react-csv/react-csv/blob/master/src/core.js

import { downloadFromBlob } from "./file";

/**
 * Simple safari detection based on user agent test
 */
const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const isArrays = (array: unknown[]) =>
  Array.isArray(array) && array.every((row) => Array.isArray(row));

const elementOrEmpty = (element: string) =>
  typeof element === "undefined" || element === null ? "" : element;

const joiner = (
  data: string[][],
  separator = ",",
  enclosingCharacter = '"',
) => {
  return data
    .filter((e) => e)
    .map((row) =>
      row
        .map((element) => elementOrEmpty(element))
        .map((column) => `${enclosingCharacter}${column}${enclosingCharacter}`)
        .join(separator),
    )
    .join(`\n`);
};

const arrays2csv = (
  data: string[][],
  headers: string[],
  separator: "," | "|",
  enclosingCharacter: string,
) => joiner(headers ? [headers, ...data] : data, separator, enclosingCharacter);

const string2csv = (data: string, headers: string[], separator: string) =>
  headers ? `${headers.join(separator)}\n${data}` : data.replace(/"/g, '""');

const toCSV = (
  data: string[][],
  headers: string[],
  separator?: "," | "|",
  enclosingCharacter?: string,
) => {
  if (isArrays(data)) {
    return arrays2csv(data, headers, separator, enclosingCharacter);
  }

  if (typeof data === "string") {
    return string2csv(data, headers, separator);
  }

  throw new TypeError(`Data should be a "String" or "Array of arrays"`);
};

export const downloadCSV = (
  headers: string[],
  csvData: string[][],
  filename: string,
) => {
  const type = isSafari() ? "application/csv" : "text/csv";
  const formattedCSV = toCSV(csvData, headers);
  const blob = new Blob(["\uFEFF", formattedCSV], { type });

  downloadFromBlob(blob, filename);
};
