import { Button } from "Components";
import { FlexRow } from "Components/Flex";
import { Selectable } from "melodies-source/Selectable";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  margin: 0 -40px -40px -40px;
  padding: 24px 40px 40px 40px;
  background: #fff;
  border-radius: 0 0 8px 8px;
  z-index: 1;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 0 -30px -30px -30px;
    padding: 24px 30px 30px 30px;
  }
`;

export const AddQuestionFooter = ({
  required,
  setRequired,
  cancelLabel = "Cancel",
  confirmLabel = "Add",
  confirmType,
  confirmDisabled,
  onCancel,
  onConfirm,
}: {
  required: boolean;
  setRequired: Dispatch<SetStateAction<boolean>>;
  cancelLabel?: string;
  confirmLabel?: string;
  confirmType?: "button" | "submit";
  confirmDisabled?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}) => {
  return (
    <Container>
      <Selectable
        variant="checkbox"
        label="Required"
        value={required}
        onChange={(selected) => setRequired(selected)}
      />
      <FlexRow>
        <Button
          variant="tertiary"
          type="button"
          style={{ marginRight: 24 }}
          onClick={() => onCancel?.()}
        >
          {cancelLabel}
        </Button>
        <Button
          onClick={() => onConfirm?.()}
          type={confirmType}
          disabled={confirmDisabled}
        >
          {confirmLabel}
        </Button>
      </FlexRow>
    </Container>
  );
};
