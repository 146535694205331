import { CenterFlexRow, Font15 } from "Routes/styled";
import styled from "styled-components";
import FanSizeIcon from "./FanSizeIcon";
import { Card } from "Components/Card";

const FanLocationsKeyCard = () => {
  return (
    <LocationKeyCard isElevated>
      <RowOrColumn>
        <CenterFlexRow>
          <LabelText>Audience Size</LabelText>
          <StyledFanSizeIcon />
        </CenterFlexRow>
        <CenterFlexRow style={{ flex: 1, width: "100%" }}>
          <LabelText>Fan Concentration</LabelText>
          <FanConcentrationBar />
        </CenterFlexRow>
      </RowOrColumn>
    </LocationKeyCard>
  );
};

export default FanLocationsKeyCard;

const StyledFanSizeIcon = styled(FanSizeIcon)`
  margin-right: 43px;
`;

const FanConcentrationBar = styled.div`
  height: 12px;
  background: linear-gradient(270deg, var(--main-color) 0%, #e8e5f1 100%);
  border-radius: 20px;
  flex: 1;
`;

const LabelText = styled(Font15)`
  color: inherit;
  font-weight: 600;
  margin-right: 10px;
`;

const LocationKeyCard = styled(Card)`
  background: white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  padding: 12px 30px;

  ${({ theme }) => theme.media.mobile} {
    padding: 10px 15px 21px 12px;
  }
`;

const RowOrColumn = styled.div`
  display: flex;
  width: 100%;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;
