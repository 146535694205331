import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";

interface SortableContainerProps<T> {
  items: T[];
  onChange: (items: T[]) => void;
  children: React.ReactNode;
  droppableId?: string;
}

export function SortableContainer<T>({
  children,
  items,
  onChange,
  droppableId,
}: SortableContainerProps<T>) {
  const handleDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(destination.index, 0, reorderedItem);
    onChange(newItems);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={droppableId || "sortableContainer"}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
