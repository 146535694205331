import { useCustomAppContext } from "contexts/CustomAppContext";
import { PieChart } from "react-minimal-pie-chart";
import styled from "styled-components";

export const ProgressCircle = ({
  percentage,
  className,
  color,
}: {
  percentage: number;
  className?: string;
  color?: string;
}) => {
  const { customApp } = useCustomAppContext();

  return (
    <PieChart
      data={[
        {
          value: 1,
          color: customApp?.theme.colors.main || color || "#1b0076",
        },
      ]}
      reveal={percentage}
      startAngle={-90}
      lengthAngle={-360}
      lineWidth={20}
      background="#E4E4E4"
      animate
      className={className}
    />
  );
};

const DefaultStyledProgressCircle = styled(ProgressCircle)`
  width: 150px;
`;

export default DefaultStyledProgressCircle;
