import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  updateDoc,
  connectFirestoreEmulator,
  addDoc,
  collection,
} from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import type { DocumentReference } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const app = initializeApp(firebaseConfig);

if (process.env.NODE_ENV === "development") {
  //@ts-ignore
  // eslint-disable-next-line no-restricted-globals
  self.FIREBASE_APPCHECK_DEBUG_TOKEN =
    process.env.REACT_APP_FIREBASE_RECAPTCHADEBUG;
  //@ts-ignore
}

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.REACT_APP_FIREBASE_RECAPTCHAKEY,
  ),
  isTokenAutoRefreshEnabled: true,
});

const firestore = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_USE_EMULATOR
) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(firestore, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

const updateFirestoreDoc = async (
  path: string,
  data: Record<string, any>,
): Promise<void> => {
  return updateDoc(doc(firestore, path), data);
};

const addFirestoreDoc = async (
  path: string,
  data: Record<string, any>,
): Promise<DocumentReference> => {
  return addDoc(collection(firestore, path), data);
};

const callable = async (
  name: string,
  data?: { [key: string]: any },
): Promise<{ [key: string]: any | Error }> => {
  const func = httpsCallable(functions, name);
  try {
    return await func(data || {});
  } catch (error) {
    console.log(error);
    return { error };
  }
};

auth.useDeviceLanguage();

export default app;
export {
  firestore,
  auth,
  functions,
  updateFirestoreDoc,
  addFirestoreDoc,
  callable,
  appCheck,
};
