const MicrophoneLogo = ({ className }: { className?: string }) => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g style={{ mixBlendMode: "overlay" }}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5198 32.8935C29.6516 31.4246 26.575 28.348 25.1061 24.4798L10.3879 45.6373L12.3623 47.6117L33.5198 32.8935ZM24.333 17.1274C25.3955 10.1662 31.4081 4.83334 38.6663 4.83334C46.6744 4.83334 53.1663 11.3252 53.1663 19.3333C53.1663 26.5915 47.8334 32.6041 40.8722 33.6666L11.8035 53.8883L4.11133 46.1961L24.333 17.1274ZM47.1771 23.921C47.9144 22.556 48.3329 20.9936 48.3329 19.3333C48.3329 13.9946 44.005 9.66668 38.6663 9.66668C36.99 9.66668 35.4133 10.0934 34.039 10.8441C34.77 13.1252 37.2844 16.9504 39.1668 18.8328C41.0184 20.6845 44.7775 23.1427 47.1771 23.921ZM43.5796 27.66C42.1398 28.5114 40.4601 29 38.6663 29C33.3275 29 28.9996 24.6721 28.9996 19.3333C28.9996 17.5557 29.4794 15.8902 30.3166 14.4592C31.6619 17.3204 33.8206 20.322 35.7491 22.2505C37.6529 24.1543 40.6815 26.308 43.5796 27.66Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default MicrophoneLogo;
