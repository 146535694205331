import styled from "styled-components";
import { WinnerDetails } from "../hooks/useSweepsWinnersDetails";
import { Question } from "../model";
import { Button } from "melodies-source/Button";
import { Modal } from "melodies-source/Modal";
import { addToast } from "melodies-source/Toast";
import { DefaultAlbum } from "Routes/SetFan/Components/SongsQuestion/SortableRow";

export const WinnerDetailModal = ({
  onClose,
  isOpen,
  winnerDetails,
  surveyQuestions,
}: {
  onClose: () => void;
  isOpen: boolean;
  winnerDetails: WinnerDetails;
  surveyQuestions: Record<string, Question>;
}) => {
  const onCopyEmail = () => {
    navigator.clipboard.writeText(winnerDetails.email);
    addToast("Email copied", "success");
  };

  return (
    <Modal header="Response Detail" isOpen={isOpen} onClose={onClose}>
      <Scrollable>
        <NameText>{winnerDetails.name}</NameText>
        <BodyText>
          Email:{" "}
          <span style={{ cursor: "pointer" }} onClick={onCopyEmail}>
            {winnerDetails.email}
          </span>
        </BodyText>
        <BodyText>Phone: {winnerDetails.phone}</BodyText>
        <BodyText>Location: {winnerDetails.location}</BodyText>
        <BodyText>Gender: {winnerDetails.gender}</BodyText>
        <BodyText>Age: {winnerDetails.age}</BodyText>
        <Divider />
        {Object.entries(surveyQuestions).map(([questionId, question]) => {
          let winnerResponse: string | string[] = "";
          if (question.type === "TextQuestion") {
            winnerDetails.free_responses.forEach((freeResponse) => {
              if (freeResponse.startsWith(questionId)) {
                winnerResponse = freeResponse.slice(questionId.length);
              }
            });
          } else if (
            question.type === "MultipleSelectQuestion" ||
            question.type === "SongsQuestion"
          ) {
            winnerResponse = [];
            winnerDetails.responses.forEach((response) => {
              if (response.startsWith(questionId)) {
                (winnerResponse as string[]).push(response.split("#")[1]);
              }
            });
          } else {
            winnerDetails.responses.forEach((response) => {
              if (response.startsWith(questionId)) {
                winnerResponse = response.split("#")[1];
              }
            });
          }

          // if the question is not required we might not have a response for it
          if (!winnerResponse) {
            return (
              <QuestionRow key={`${questionId}-${winnerResponse.slice(0, 10)}`}>
                <QuestionLabel>{question.label}: </QuestionLabel>
                No response given
              </QuestionRow>
            );
          }

          let AnswerComponent: JSX.Element;
          switch (question.type) {
            case "TextQuestion":
              AnswerComponent = <BodyText>{winnerResponse}</BodyText>;
              break;
            case "ImageQuestion":
              AnswerComponent = (
                <img
                  width={75}
                  src={
                    surveyQuestions[questionId].options[
                      winnerResponse as string
                    ].src
                  }
                  alt="question answer"
                />
              );
              break;
            case "SongsQuestion":
              AnswerComponent = (
                <>
                  {(winnerResponse as string[]).map((resp) => {
                    const song = surveyQuestions[questionId].options[resp];
                    return (
                      <div
                        key={resp}
                        style={{
                          display: "flex",
                          marginBottom: 6,
                          alignItems: "center",
                        }}
                      >
                        {song.src ? (
                          <img
                            src={song.src}
                            style={{ width: 33, height: 33, marginRight: 6 }}
                            alt={song.label}
                          />
                        ) : (
                          <StyledDefaultAlbum />
                        )}
                        <div>{song.label}</div>
                      </div>
                    );
                  })}
                </>
              );
              break;
            case "MultipleSelectQuestion":
              AnswerComponent = (
                <ol style={{ listStyle: "inside" }}>
                  {(winnerResponse as string[]).map((resp) => {
                    const questionOption =
                      surveyQuestions[questionId].options[resp];
                    const label = questionOption.userDefined
                      ? `"Other"`
                      : questionOption.label;
                    return <li key={resp}>{label}</li>;
                  })}
                </ol>
              );
              break;
            default:
              AnswerComponent = (
                <BodyText>
                  {
                    surveyQuestions[questionId].options[
                      winnerResponse as string
                    ].label
                  }
                </BodyText>
              );
              break;
          }
          return (
            <QuestionRow key={`${questionId}-${winnerResponse.slice(0, 10)}`}>
              <QuestionLabel>{question.label}: </QuestionLabel>
              {AnswerComponent}
            </QuestionRow>
          );
        })}
      </Scrollable>
      <ButtonContainer>
        <Button onClick={onClose} style={{ width: 156 }}>
          Close
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

const StyledDefaultAlbum = styled(DefaultAlbum)`
  margin-left: 0px !important;

  & > svg {
    top: 2px;
    left: 7px;
    width: 18px;
  }

  & > img {
    width: 33px;
    height: 33px;
    margin-right: 6px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Scrollable = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 20px;

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 40px;
  }
`;

const QuestionRow = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const NameText = styled.div`
  color: #333;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 30px;
`;

const BodyText = styled.div`
  color: #333;
  font-family: Poppins;
  font-size: 15px;
  line-height: 22px;
`;

const Divider = styled.div`
  width: 374px;
  height: 1px;
  background: #cdcdcd;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const QuestionLabel = styled.div`
  color: #999;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;
