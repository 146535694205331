import * as upcomingTour from "./upcomingTour";
import * as merchDrop from "./merchDrop";
import * as musicDrop from "./musicDrop";
import * as marketing from "./marketing";
import * as livestream from "./livestream";
import * as brand from "./brand";
import { SurveyTemplateCategoryList } from "../types";
import { SvgCharity } from "melodies-source/Svgs/Charity";
import { SvgChart } from "melodies-source/Svgs/Chart";
import { SvgInstantMessage } from "melodies-source/Svgs/InstantMessage";
import { SvgLocation } from "melodies-source/Svgs/Location";
import { SvgRecord } from "melodies-source/Svgs/Record";
import { SvgShirt } from "melodies-source/Svgs/Shirt";

export const surveyTemplates: SurveyTemplateCategoryList = {
  supportUpcomingTour: {
    icon: <SvgLocation width={44} height={44} />,
    key: "supportUpcomingTour",
    header: "Support an upcoming tour",
    subHeader:
      "Have your fans build their dream setlist, suggest tour destinations, or share their favorite tour memory.",
    modalHeader: "Templates for Supporting an Upcoming Tour",
    templates: upcomingTour,
  },
  supportMusicDrop: {
    icon: <SvgRecord width={44} height={44} />,
    key: "supportMusicDrop",
    header: "Support a music drop",
    subHeader:
      "Ask fans to share what music they are most excited about, and encourage registrations for first access.",
    modalHeader: "Templates for Supporting a Music Drop",
    templates: musicDrop,
  },
  supportMerchDrop: {
    icon: <SvgShirt width={44} height={44} />,
    key: "supportMerchDrop",
    header: "Support a merch drop",
    subHeader:
      "Ask your fans to choose their favorite merch design, or what merch you should make next.",
    modalHeader: "Templates for Supporting a Merch Drop",
    templates: merchDrop,
  },
  engageFans: {
    icon: <SvgInstantMessage width={44} height={44} />,
    key: "engageFans",
    header: "Learn who is at your social media live",
    subHeader:
      "Allow fans to share what they want from today's live and be able to message them in the future.",
    modalHeader: "Templates for Social Media Live",
    templates: livestream,
  },
  supportMarketing: {
    icon: <SvgChart width={44} height={44} />,
    key: "supportMarketing",
    header: "Support marketing efforts",
    subHeader:
      "The more you understand your fans, the better you can target the most loyal and influential ones.",
    modalHeader: "Templates for Supporting Marketing Efforts",
    templates: marketing,
  },
  findPartners: {
    icon: <SvgCharity width={44} height={44} />,
    key: "findPartners",
    header: "Help find brand partners/sponsors",
    subHeader:
      "Knowing what your fans buy and how they spend their time can lead to a brand partnership.",
    modalHeader: "Templates for Connecting with Brands",
    templates: { tourSponsor: upcomingTour.tourSponsor, ...brand },
  },
};
