import * as React from "react";
import { SVGProps } from "react";
export const SvgDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10,12 L10,17 L4,17 C3.44771525,17 3,16.5522847 3,16 L3,12 L10,12 Z M17,9 L17,16 C17,16.5522847 16.5522847,17 16,17 L11,17 L11,9 L17,9 Z M16,10 L12,10 L12,16 L16,16 L16,10 Z M9,13 L4,13 L4,16 L9,16 L9,13 Z M10,3 L10,11 L3,11 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 Z M9,4 L4,4 L4,10 L9,10 L9,4 Z M16,3 C16.5522847,3 17,3.44771525 17,4 L17,8 L11,8 L11,3 L16,3 Z M16,4 L12,4 L12,7 L16,7 L16,4 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
