import { ArrayHelpers, useFormikContext } from "formik";
import { Modal } from "melodies-source/Modal";
import { useState } from "react";
import { SongForm } from "./SongForm";
import { SortableRow } from "./SortableRow";
import { Song } from "./types";

interface SongRowProps {
  index: number;
  song: Song;
  editable?: boolean;
  draggable?: boolean;
  helpers: ArrayHelpers<Song[]>;
}

export const SongRow = ({
  index,
  song,
  editable,
  draggable,
  helpers,
}: SongRowProps) => {
  const { setFieldValue } = useFormikContext();
  const [editing, setEditing] = useState(false);

  return (
    <>
      <SortableRow
        index={index}
        row={song}
        editable={editable}
        draggable={draggable}
        onVisibleChange={(visible) => {
          setFieldValue(`songs.${index}.visible`, visible);
        }}
        onEdit={() => setEditing(true)}
        onDelete={() => helpers.remove(index)}
      />
      <Modal
        header={`Edit “${song.name}”`}
        headerColor="navy"
        withBackdropClose={false}
        isOpen={editing}
        onClose={() => setEditing(false)}
      >
        <SongForm
          song={song}
          onCancel={() => setEditing(false)}
          onSubmit={(song) => {
            helpers.replace(index, song);
            setEditing(false);
          }}
        />
      </Modal>
    </>
  );
};
