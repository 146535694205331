import { FlexColumn, FlexRow } from "Components/Flex";
import { useMobileMediaQuery } from "hooks";
import { ConcertData } from "hooks/useUpcomingConcerts";
import { ReactComponent as CalendarAddIcon } from "melodies-source/Svgs/src/CalendarAdd.svg";
import { ReactComponent as LocationIcon } from "melodies-source/Svgs/src/Location.svg";
import { Body1, Body2, H3, H4 } from "melodies-source/Text";
import { ConcertDetails } from "Routes/SetLive/Duplicate/types";
import { getDateTime } from "Utils/date";
import { getDisplayAddress, truncate } from "./ConcertCard";

interface ConcertDisplayProps {
  concert: ConcertData | ConcertDetails<Date>;
  isNew?: boolean;
}

export const ConcertDisplay = ({ concert, isNew }: ConcertDisplayProps) => {
  const date = getDateTime(concert.date);
  const isMobile = useMobileMediaQuery();
  const Title = isMobile ? H4 : H3;
  const Text = isMobile ? Body2 : Body1;

  return (
    <FlexRow yCenter>
      <FlexRow
        xCenter
        yCenter
        style={{
          flexShrink: 0,
          width: 46,
          height: 46,
          borderRadius: 46,
          color: isNew ? "white" : "#1B0076",
          background: isNew ? "#1B0076" : "#E8E5F1",
        }}
      >
        {isNew ? (
          <CalendarAddIcon width={28} height={28} />
        ) : (
          <LocationIcon width={28} height={28} />
        )}
      </FlexRow>
      <FlexColumn
        style={{
          minWidth: 0,
          marginLeft: 20,
          paddingLeft: 20,
          borderLeft: "1px solid #656464",
        }}
      >
        <Title>{concert.venue}</Title>
        <Text style={truncate}>{getDisplayAddress(concert)}</Text>
        <Text>
          {date.toFormat("h:mm a (ZZZZ)")} • {date.toFormat("DDD")}
        </Text>
      </FlexColumn>
    </FlexRow>
  );
};
