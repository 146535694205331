import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as SendLargeIcon } from "assets/svg/send.svg";
import { FlexColumn } from "Routes/styled";
import { DateTime } from "luxon";
import { AnimationConditional } from "Components/Animation";
import { WinnerDetails } from "../hooks/useSweepsWinnersDetails";
import { Modal } from "melodies-source/Modal";
import { TextInput } from "Components";
import { useWinnerConversation } from "../hooks/useWinnerConversation";

export const MessageModal = ({
  isOpen,
  onClose,
  winner,
}: {
  isOpen: boolean;
  onClose: () => void;
  winner: WinnerDetails;
}) => {
  const [textInputValue, setTextInputValue] = useState("");
  const [messageSending, setMessageSending] = useState(false);

  const { messages, sendMessage, markMessagesRead, loading } =
    useWinnerConversation({ conversationId: winner.conversationId, winner });

  const mostRecentMessageRef = useRef<HTMLDivElement>();
  const messageInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!loading && messageInputRef?.current) {
      messageInputRef.current.focus();
    }
  }, [loading]);

  // if new message, scroll to
  useEffect(() => {
    if (mostRecentMessageRef?.current) {
      markMessagesRead();
      mostRecentMessageRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [mostRecentMessageRef?.current]);

  async function onMessageSend() {
    setMessageSending(true);
    try {
      await sendMessage({ message: textInputValue });
    } catch (err) {
      console.error(err);
    } finally {
      setTextInputValue("");
      setMessageSending(false);
      messageInputRef?.current?.focus();
    }
  }

  return (
    <StyledModal
      header={`Message ${winner.name.split(" ")[0]}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      {loading ? (
        <Scrollable>
          <MessagesLoader
            message={`Loading conversation with ${winner.name.split(" ")[0]}`}
          />
        </Scrollable>
      ) : (
        <AnimatedChatWindow
          display={!loading}
          duration={1000}
          introKeyframes={[
            { opacity: 0, transform: "translateX(-10px)" },
            { opacity: 1, transform: "translateX(0)", offset: 0.4 },
          ]}
          onIntroAnimationEnd={() =>
            mostRecentMessageRef?.current?.scrollIntoView({
              behavior: "auto",
            })
          }
        >
          <Scrollable>
            {!messages.length && (
              <StatusText
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 100,
                  marginTop: 70,
                  fontStyle: "italic",
                }}
              >
                Send a message to start a conversation
              </StatusText>
            )}
            {messages?.map((message, idx) => {
              const msgDirection =
                message.author === "system" ||
                message.attributes?.direction === "out"
                  ? "out"
                  : "in";
              return (
                <AnimatedMessageBubble
                  key={message.id}
                  display
                  duration={1000}
                  introKeyframes={[
                    { opacity: 0, transform: "translateX(-10px)" },
                    { opacity: 1, transform: "translateX(0)", offset: 0.4 },
                  ]}
                >
                  <MessageRow
                    ref={
                      idx === messages.length - 1 ? mostRecentMessageRef : null
                    }
                    direction={msgDirection}
                  >
                    <MessageBoxColumn direction={msgDirection}>
                      <MessageBox direction={msgDirection}>
                        {message.message}
                      </MessageBox>
                      <StatusText
                        style={{
                          marginLeft: msgDirection === "in" ? 5 : 0,
                          marginRight: msgDirection === "in" ? 0 : 5,
                        }}
                      >
                        {msgDirection === "in" ? `${message.displayName} ` : ""}
                        {message.status === "pending" ||
                        message.status === "sending"
                          ? "Pending..."
                          : message.status === "undelivered"
                          ? "unable to be sent"
                          : message.sentAt?.toLocaleString(
                              DateTime.DATETIME_SHORT,
                            )}
                      </StatusText>
                    </MessageBoxColumn>
                  </MessageRow>
                </AnimatedMessageBubble>
              );
            })}
          </Scrollable>
        </AnimatedChatWindow>
      )}
      <FlexColumn style={{ bottom: 0, position: "sticky" }}>
        <Divider />
        <SendContainer onClick={() => messageInputRef?.current.focus()}>
          <StyledTextInput
            placeholder="Message"
            value={textInputValue}
            onChange={(text) => setTextInputValue(text)}
            disabled={messageSending}
            inputRef={messageInputRef}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onMessageSend();
              }
              if (e.key === "Escape") {
                onClose();
              }
            }}
          />
          <StyledSendIcon
            onClick={async () => onMessageSend()}
            disabled={!textInputValue || messageSending}
          />
        </SendContainer>
      </FlexColumn>
    </StyledModal>
  );
};

const AnimatedMessageBubble = styled(AnimationConditional)`
  display: block !important;
`;

const AnimatedChatWindow = styled(AnimationConditional)``;

const StyledTextInput = styled(TextInput)`
  & > div {
    width: 100%;
    border: none;
  }

  &,
  input {
    border: none;
    padding: 0;
  }
`;

const MessagesLoader = ({ message }: { message: string }) => {
  return (
    <StatusText
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontStyle: "italic",
        height: 100,
        marginTop: 70,
      }}
    >
      {message}
    </StatusText>
  );
};

const MessageBoxColumn = styled(FlexColumn)<{ direction: "out" | "in" }>`
  max-width: 70%;
  align-items: ${({ direction }) =>
    direction === "out" ? "flex-end" : "flex-start"};
`;

const MessageRow = styled.div<{ direction: "out" | "in" }>`
  display: flex;
  justify-content: ${({ direction }) =>
    direction === "out" ? "flex-end" : "flex-start"};
  margin-top: 10px;
  margin-bottom: 10px;
`;

const MessageBox = styled.div<{ direction: "out" | "in" }>`
  padding: 10px;
  margin-bottom: 3px;
  border-radius: 8px;
  background-color: ${({ direction }) =>
    direction === "out" ? "#1b0076" : "#e9e9e9"};
  color: ${({ direction }) => (direction === "out" ? "white" : "black")};
  font-size: 14px;
  font-family: Poppins;
  line-height: 20px;

  ${({ theme }) => theme.media.mobile} {
    font-size: 12px;
    line-height: 18px;
  }
`;

const StatusText = styled.span`
  font-size: 10px;
  line-height: 14px;
  font-family: Poppins;
  color: #333;
`;

const Divider = styled.div`
  width: calc(100% + 44px);
  margin-left: -22px;
  margin-right: -22px;
  height: 1px;
  background: #dedede;
`;

const StyledModal = styled(Modal)`
  & > div > div {
    padding: 22px 22px 0px 22px;
  }
`;

const Scrollable = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 300px;
  margin-top: 70px;
  padding-right: 10px;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 50px;
  }
`;

const SendContainer = styled.div`
  height: 71px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  cursor: text;

  ${({ theme }) => theme.media.mobile} {
    height: 52px;
  }
`;

const StyledSendIcon = styled(SendLargeIcon)<{ disabled: boolean }>`
  margin-left: 20px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: all 0.5s;

  &:hover {
    transform: ${({ disabled }) => (disabled ? "scale(1)" : "scale(1.25)")};
  }

  & > path {
    fill: ${({ disabled }) => (disabled ? "#999" : "#1B0076")};
  }
`;
