import { FlexColumn, FlexRow } from "Components/Flex";
import {
  arrayRemove,
  arrayUnion,
  doc,
  DocumentReference,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { useArtistGroupsByIdsQuery } from "hooks";
import { useArtistGroupsSearch } from "hooks/useArtistGroupsSearch";
import { AutocompleteAsync } from "melodies-source/Autocomplete";
import { Button } from "melodies-source/Button";
import { Modal } from "melodies-source/Modal";
import { SvgCheckCircle } from "melodies-source/Svgs/CheckCircle";
import { Body1 } from "melodies-source/Text";
import { addToast } from "melodies-source/Toast";
import { SetLiveEvent } from "models/event";
import { useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { getArtistImageById } from "Utils/getArtistImageById";
import { ArtistGroupCard } from "./ArtistGroupCard";
import { ArtistGroupOption } from "./ArtistGroupOption";

interface EditAdditionalGroupsProps {
  eventId: string;
  open: boolean;
  onUpdate?: (id: string, data: Partial<SetLiveEvent>) => void;
  onClose: () => void;
}

export const EditAdditionalGroups = ({
  eventId,
  open,
  onUpdate,
  onClose,
}: EditAdditionalGroupsProps) => {
  const [text, setText] = useState("");
  const [remove, setRemove] = useState<{ id: string; name: string }>();
  const { search } = useArtistGroupsSearch();

  const [event] = useDocumentData<SetLiveEvent, "id", "ref">(
    doc(
      getFirestore(),
      `set_fresh_events/${eventId}`,
    ) as DocumentReference<SetLiveEvent>,
    {
      idField: "id",
      refField: "ref",
    },
  );

  const additionalGroups = event?.additionalGroups ?? [];

  const { artistGroups } = useArtistGroupsByIdsQuery({
    ids: additionalGroups,
    limit: 100,
  });

  const showToast = (msg: string) =>
    addToast(msg, "success", { icon: <SvgCheckCircle /> });

  return (
    <>
      <Modal
        header="Delete Artist?"
        isOpen={!!remove}
        onClose={() => setRemove(undefined)}
      >
        <FlexColumn gap="20px">
          <Body1>
            Are you sure you want to delete {remove?.name} from this show?
          </Body1>
          <FlexRow gap="20px" xEnd>
            <Button variant="tertiary" onClick={() => setRemove(undefined)}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                setRemove(undefined);

                await updateDoc(event?.ref, {
                  additionalGroups: arrayRemove(remove?.id),
                });

                showToast(`${remove?.name} removed`);

                onUpdate?.(event.id, {
                  additionalGroups: additionalGroups.filter(
                    (id) => id !== remove?.id,
                  ),
                });
              }}
            >
              Delete
            </Button>
          </FlexRow>
        </FlexColumn>
      </Modal>
      <Modal
        header="Add Artist"
        isOpen={open}
        onClose={onClose}
        withBackdropClose={!remove}
      >
        <FlexColumn gap="20px">
          <Body1>
            You can add artists to this show by searching below, or remove
            artists by clicking the delete icon.
          </Body1>
          <AutocompleteAsync
            label="Artist Search"
            placeholder="Type Artist Name..."
            text={text}
            setText={setText}
            customOption={ArtistGroupOption}
            clearOnSelect={true}
            onChange={async (option) => {
              await updateDoc(event?.ref, {
                additionalGroups: arrayUnion(option.value),
              });

              showToast(`${option.label} added`);

              onUpdate?.(event.id, {
                additionalGroups: additionalGroups.concat([option.value]),
              });
            }}
            getOptions={async (q) => {
              const response = await search({ q, query_by: "name" });
              const documents =
                response?.hits?.map((hit) => hit.document) ?? [];

              return documents
                .filter(({ id }) => id !== event?.groupId)
                .filter(({ id }) => !event?.additionalGroups?.includes(id))
                .map((document) => ({
                  label: document.name,
                  value: document.id,
                  avatarSrc: getArtistImageById(document.id),
                }));
            }}
          />
          {!!artistGroups?.length && (
            <FlexColumn gap="5px">
              {artistGroups.map((artistGroup) => (
                <ArtistGroupCard
                  key={artistGroup.id}
                  artistGroup={artistGroup}
                  onDelete={() => setRemove(artistGroup)}
                />
              ))}
            </FlexColumn>
          )}
        </FlexColumn>
      </Modal>
    </>
  );
};
