import * as Shoutout from "@musicaudienceexchange/shoutout-interface";
import {
  collection,
  CollectionReference,
  documentId,
  getFirestore,
  limit,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { useEffect } from "react";
import { useCollectionOnce } from "react-firebase-hooks/firestore";

// Better UX by returning previously fetched records
const store: Record<
  string,
  QueryDocumentSnapshot<Shoutout.firestore.artists.ArtistGroup>
> = {};

interface Params {
  ids?: string[];
  limit?: number;
}

export const useArtistGroupsByIdsQuery = ({
  ids,
  limit: _limit = 4,
}: Params) => {
  const [artistGroups] = useCollectionOnce(
    ids?.length
      ? query(
          collection(
            getFirestore(),
            "artist_groups",
          ) as CollectionReference<Shoutout.firestore.artists.ArtistGroup>,
          where(documentId(), "in", ids),
          limit(_limit),
        )
      : null,
  );

  useEffect(() => {
    artistGroups?.forEach((ap) => {
      store[ap.id] = ap;
    });
  }, [artistGroups]);

  return {
    artistGroups: Object.values(store)
      .filter((ag) => ids?.includes(ag.id))
      .map((ag) => ({ ...ag.data(), id: ag.id })),
  };
};
