import { TextInput, useArtistContext } from "Components";
import { CenterFlexRow } from "Routes/styled";
import { REQUIRED_MSG } from "Utils/yupStrictPassword";
import { Field, FieldProps, Form, Formik } from "formik";
import { Button } from "melodies-source/Button";
import { ImageUpload } from "melodies-source/ImageUpload";
import { useMemo } from "react";
import * as yup from "yup";
import { PreviewImage } from "./PreviewImage";
import { ReplaceImageButton } from "./ReplaceImageButton";

interface Values {
  src: string;
  label: string;
}

const VALIDATION_SCHEMA = yup.object().shape({
  src: yup.string().required(REQUIRED_MSG),
  label: yup.string().required(REQUIRED_MSG),
});

interface ImageQuestionUploaderProps {
  option?: Values;
  onCancel?: () => void;
  onSubmit?: (values: Values) => void;
}

export const ImageQuestionUploader = ({
  option,
  onCancel,
  onSubmit,
}: ImageQuestionUploaderProps) => {
  const { artistGroupDoc } = useArtistContext();

  const initialValues = useMemo(
    () => ({
      src: option?.src ?? "",
      label: option?.label ?? "",
    }),
    [option],
  );

  const handleSubmit = (values: Values) => {
    onSubmit?.(values);
  };

  return (
    <Formik<Values>
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form style={{ marginTop: 26 }}>
          {values.src ? (
            <>
              <PreviewImage src={values.src} />
              <ReplaceImageButton onClick={() => setFieldValue("src", "")} />
            </>
          ) : (
            <ImageUpload
              uploadPath={`surveys/${artistGroupDoc.id}/questions`}
              uploadWithFileExt={false}
              withAspectRatio={false}
              onComplete={(url) => setFieldValue("src", url)}
            />
          )}
          {values.src && (
            <>
              <div style={{ marginTop: 26, marginBottom: 26 }}>
                <Field name="label">
                  {({ field, meta }: FieldProps) => (
                    <TextInput
                      label="Image Label"
                      hasError={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                      asString={false}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <CenterFlexRow style={{ justifyContent: "flex-end" }}>
                <Button type="button" variant="tertiary" onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="submit">{option ? "Update" : "Add"}</Button>
              </CenterFlexRow>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
