import {
  AutocompleteBase,
  Option,
  OptionProps,
} from "melodies-source/Autocomplete";
import { usePlacesAutocomplete } from "../../hooks/usePlacesAutocomplete";
import styled from "styled-components";
import { FlexColumn, FlexRow } from "Components/Flex";
import { Body2, Li } from "melodies-source/Text";
import {
  GOOGLE_PLACES_ESTABLISHMENT_TYPES,
  getTimeZoneIdByGeocode,
} from "Utils/maps";

export interface PlacesAutocompleteResult {
  addressType: "address" | "establishment";
  placeName: string;
  result: google.maps.GeocoderResult;
  timeZone?: string;
}

interface PlacesAutocompleteProps {
  label?: string;
  onChange: (result: PlacesAutocompleteResult) => void;
  selected?: Option;
  style?: React.CSSProperties;
  placeholder?: string;
}

export const PlacesAutocomplete = ({
  label,
  onChange,
  selected,
  ...props
}: PlacesAutocompleteProps) => {
  const { loading, setQuery, query, results, getAddressDetails } =
    usePlacesAutocomplete() || {};

  const handleSelect = async (option: OptionProps) => {
    try {
      const places = await getAddressDetails(option);
      if (places?.place) {
        const checkPlaceType = places.place.types.findIndex((type) =>
          GOOGLE_PLACES_ESTABLISHMENT_TYPES.includes(type),
        );
        const place: PlacesAutocompleteResult = {
          result: places.place,
          placeName: places.placeName,
          addressType: checkPlaceType > -1 ? "establishment" : "address",
        };
        const { lat, lng } = places.place.geometry.location;
        const timeZone = await getTimeZoneIdByGeocode(lat(), lng());
        if (timeZone) {
          place.timeZone = timeZone;
        }
        onChange(place);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Autocomplete
      setText={setQuery}
      text={query}
      label={label}
      options={results || []}
      onChange={handleSelect}
      isLoading={loading}
      value={selected}
      customOption={CustomOption}
      {...props}
    />
  );
};

const CustomOption = ({
  label,
  value,
  data,
  icon,
  isSelected,
  caption,
  ...props
}: OptionProps & { caption?: string }) => {
  return (
    <Row {...props}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <FlexColumn>
        <Li>{label}</Li>
        {caption && <Body2>{caption}</Body2>}
      </FlexColumn>
    </Row>
  );
};

const Row = styled(FlexRow)`
  padding: 12px;
  ${Li} {
    color: var(--text-color);
    font-family: "Poppins", sans-serif;
    line-height: 20px;
  }
  ${Body2} {
    color: var(--secondary-text-color);
  }
  &:hover {
    cursor: pointer;
    background-color: var(--hover-background-color);
  }
`;

const IconContainer = styled.div`
  margin-right: 12px;
  flex-shrink: 0;
  color: ${(p) => p.theme.colors.black60};
`;

const Autocomplete = styled(AutocompleteBase)`
  div > div > div > div > svg {
    color: var(--secondary-text-color);
  }
  input {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
  input + div svg {
    display: none;
  }
`;
