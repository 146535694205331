import { Timestamp } from "firebase/firestore";
import { Dispatch, SetStateAction } from "react";
import { DateTime } from "luxon";
import { CSSProperties } from "styled-components";

export interface CommunityIntegrationCardProps {
  integrationStatus: CommunityIntegrationStatus;
  lastSynced?: DateTime;
  onRemoveCommunity: () => void;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  sx?: CSSProperties;
}

export const enum CommunityIntegrationStatus {
  NOT_CONNECTED = "notConnected",
  CONNECTED = "connected",
}

export type CommunityDocument = {
  lastSynced?: Timestamp;
  secretPath?: string;
  requestedAt?: Timestamp;
};
