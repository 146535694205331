import { useUser } from "auth";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { query, collection, where, getFirestore } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import MyAccount from "svg/MyAccount";
import SignOut from "svg/SignOut";
import SwitchIcon from "svg/Switch";
import AddArtist from "svg/AddArtist";
import { useClickOutside } from "hooks/useClickOutside";
import { useCustomAppContext } from "contexts/CustomAppContext";

interface UserMenuProps {
  artistId?: string;
}

export const UserMenu = ({ artistId }: UserMenuProps) => {
  const { user, claims, clearSession } = useUser();
  const [open, setOpen] = useState(false);
  const referenceElement = useRef<HTMLDivElement | null>(null);
  useClickOutside(referenceElement, () => setOpen(false));
  const isSelectRoute = window.location.pathname === "/";

  const collectionRef = collection(getFirestore(), "artist_groups");
  const lookup = !claims.admin
    ? query(
        collectionRef,
        where(`members.${user?.uid}`, "array-contains-any", [
          "artist",
          "manager",
          "admin",
        ]),
      )
    : query(collectionRef);

  const [artistGroups] = useCollection(lookup);
  const { customApp } = useCustomAppContext();

  let docs = artistGroups?.docs;

  return (
    <Container>
      <Image onClick={() => setOpen((open) => !open)} name={user.displayName} />
      <Menu
        ref={referenceElement}
        {...(!open && { style: { display: "none" } })}
      >
        {customApp?.UserMenuOptions && (
          <customApp.UserMenuOptions Option={Option} />
        )}
        {!customApp && (
          <>
            {!isSelectRoute && docs?.length !== 1 && (
              <Option as={Link} to="/">
                <SwitchIcon /> Switch Artists
              </Option>
            )}
            <Option href={`${process.env.REACT_APP_SIGNUP_URL}/?start`}>
              <AddArtist /> Add Artist
            </Option>
          </>
        )}
        <Option
          as={Link}
          to={{
            pathname: "/account",
            state: { artistId },
          }}
        >
          <MyAccount /> My Account
        </Option>
        <Option onClick={() => clearSession()}>
          <SignOut /> Sign Out
          <Info>
            {user.displayName} {"("}
            {user.email}
            {")"}
          </Info>
        </Option>
      </Menu>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  background: var(--component-background-color);
  padding: 13px 0;
  border-radius: 6px;
  overflow: hidden;

  svg {
    margin: 3px 4px -3px 12px;
    width: 16px;
    height: 16px;
  }
`;

const Option = styled.a`
  display: block;
  text-decoration: none;
  font-size: 15px;
  line-height: 170%;
  font-family: Poppins;
  color: var(--main-color);
  cursor: pointer;
  user-select: none;
  padding: 7px 0;

  &:hover {
    background: var(--hover-background-color);
  }

  &.active {
    color: #6d5ca7;
  }
`;

const Link = styled(NavLink)`
  color: var(--main-color) !important;
  font-size: 15px;
`;

const Info = styled.div`
  font-family: Poppins;
  font-size: 12px;
  color: var(--secondary-text-color);
  padding-left: 33px;
`;

const Image = ({ name = "", ...props }) => {
  let safeName = name ? name : "SET.Live User";
  return (
    <ImageContainer {...props}>
      {safeName.substr(0, 1).toLocaleUpperCase()}
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: Poppins;
  font-size: 16.5px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  background: #3795bd;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
`;
