import styled from "styled-components";
import { FanRow, useFanTableContext, FanHeaderRow, FanRowLoader } from "./";
import { useIsMobile } from "melodies-source/utils";

export const FanTable = () => {
  const { fans, loading } = useFanTableContext();
  const isMobile = useIsMobile();

  return (
    <Container>
      <FanHeaderRow />
      {loading ? (
        isMobile ? (
          <FanRowLoader />
        ) : (
          <>
            <FanRowLoader />
            <FanRowLoader />
            <FanRowLoader />
            <FanRowLoader />
            <FanRowLoader />
            <FanRowLoader />
            <FanRowLoader />
          </>
        )
      ) : (
        fans?.map((fanDoc) => <FanRow key={fanDoc.id} fan={fanDoc} />)
      )}
    </Container>
  );
};

const Container = styled.div`
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  ${({ theme }) => theme.media.desktop} {
    max-width: 90vw;
    padding: 20px;
    flex-direction: column;
    & > div {
      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & > div:nth-child(1) {
        width: 20%;
        font-weight: 600;
      }
      & > div:nth-child(2) {
        width: 10%;
      }
      & > div:nth-child(3) {
        width: 5%;
      }
      & > div:nth-child(4) {
        width: 8%;
      }
      & > div:nth-child(5) {
        width: 10%;
      }
      & > div:nth-child(6) {
        width: 12%;
      }
      & > div:nth-child(7) {
        width: 25%;
      }
    }
  }
`;
