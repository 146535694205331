import { TextInput } from "Components";
import { Field, FieldProps, Form, Formik } from "formik";
import { Button } from "melodies-source/Button";
import { SectionFooter, SectionList } from "./components";
import { CUSTOM_SONG_SCHEMA, CustomSong } from "./types";

interface CustomSongFormProps {
  initialValues: CustomSong;
  onCancel?: () => void;
  onSubmit?: (values: CustomSong) => void;
}

export const CustomSongForm = ({
  initialValues,
  onCancel,
  onSubmit,
}: CustomSongFormProps) => {
  return (
    <Formik<CustomSong>
      initialValues={initialValues}
      validationSchema={CUSTOM_SONG_SCHEMA}
      onSubmit={onSubmit}
    >
      <Form>
        <SectionList>
          <Field name="name">
            {({ field, meta }: FieldProps) => (
              <TextInput
                label="Song Name"
                hasError={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                asString={false}
                {...field}
              />
            )}
          </Field>
          <Field name="album">
            {({ field, meta }: FieldProps) => (
              <TextInput
                label="Album Name"
                hasError={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                asString={false}
                {...field}
              />
            )}
          </Field>
          <Field name="artist">
            {({ field, meta }: FieldProps) => (
              <TextInput
                label="Artist Name"
                hasError={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                asString={false}
                {...field}
              />
            )}
          </Field>
          <SectionFooter>
            <Button variant="tertiary" type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" style={{ width: 140 }}>
              Save
            </Button>
          </SectionFooter>
        </SectionList>
      </Form>
    </Formik>
  );
};
