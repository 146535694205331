export const enum StripeIntegrationStatus {
  NOT_CONNECTED = "notConnected",
  CONNECTED = "connected",
  NEEDS_ATTENTION = "needsAttention",
}

export type StripeDocument = {
  stripeConnectId?: string;
  stripeConnectStatus?: "active" | "inactive";
};

export enum StripeRedirects {
  artistGroups = "artistGroups",
  artistGroupManagement = "artistGroupManagement",
  artistGroupMessages = "artistGroupMessages",
}
