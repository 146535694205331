import { useArtistContext } from "Components";
import { FlexColumn } from "Components/Flex";
import { pluralize } from "Utils/pluralize";
import { DateTime } from "luxon";
import { Button } from "melodies-source/Button";
import { Confirmation } from "melodies-source/Confirmation";
import { Modal } from "melodies-source/Modal";
import { SvgCheckCircle } from "melodies-source/Svgs/CheckCircle";
import { SvgRefreshLarge } from "melodies-source/Svgs/RefreshLarge";
import { Body1, H3 } from "melodies-source/Text";
import { useState } from "react";
import { Member } from ".";
import { addUser } from "./AddTeammateModal";

const formatToRelative = (date: Date) => {
  const now = DateTime.now();
  const requestedAt = DateTime.fromJSDate(date);
  const diff = now.diff(requestedAt, ["seconds", "minutes"]).toObject();

  let text = "";

  if (diff.minutes === 0) {
    text = `${pluralize(Math.floor(diff.seconds), "second")} ago`;
  } else if (diff.minutes < 60) {
    text = `${pluralize(Math.floor(diff.minutes), "minute")} ago`;
  } else if (now.toISODate() === requestedAt.toISODate()) {
    text = `today at ${requestedAt.toFormat("h:mm a (ZZZZ)")}`;
  } else {
    text = `on ${requestedAt.toFormat("EEEE, MMM d 'at' t")}`;
  }

  return { text, diff };
};

interface ResendInvitationProps {
  member: Member;
}

export const ResendInvitation = ({ member }: ResendInvitationProps) => {
  const { artistGroupDoc } = useArtistContext();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { text, diff } = formatToRelative(member.requestedAt.toDate());

  const handleResend = async () => {
    try {
      setLoading(true);

      await addUser({
        artistGroupId: artistGroupDoc.id,
        email: member.email,
        role: "other",
        isAdmin: false,
      });

      setSuccess(true);
    } catch (e) {
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setOpen(false);
    setLoading(false);
    setSuccess(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button style={{ padding: 0, height: "auto" }} text onClick={openModal}>
        <SvgRefreshLarge width={16} height={16} style={{ marginRight: 4 }} />{" "}
        Resend
      </Button>
      <Modal
        header={success ? undefined : "Resend Invite?"}
        isOpen={open}
        onClose={closeModal}
        withCloseIcon={!success}
      >
        {success ? (
          <FlexColumn gap="10px" xCenter style={{ padding: "20px 0" }}>
            <SvgCheckCircle width={56} height={56} color="#1DAB98" />
            <H3>Invite Resent</H3>
            <Body1 style={{ textAlign: "center" }}>
              An invite was sent to {member.email}
            </Body1>
            <Button
              style={{ minWidth: 210, marginTop: 20 }}
              onClick={closeModal}
            >
              Close
            </Button>
          </FlexColumn>
        ) : (
          <Confirmation
            confirmLabel="Resend Invite?"
            loading={loading}
            onCancel={closeModal}
            onConfirm={handleResend}
            disabled={diff.minutes < 5}
          >
            <Body1>
              {member.email} was sent an invite {text}.
            </Body1>
          </Confirmation>
        )}
      </Modal>
    </div>
  );
};
