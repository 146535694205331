import React from "react";
import styled from "styled-components";
import { H2 } from "melodies-source/Text";

interface Props {
  children: React.ReactNode;
  withDecorator?: boolean;
  withBack?: boolean;
  className?: string;
  action?: React.ReactNode;
}

export const Header: React.FC<Props> = ({
  children,
  withBack,
  withDecorator = true,
  className,
  action,
}) => {
  return (
    <Container className={className}>
      {withDecorator && <Decorator hasBack={withBack} />}
      <H2>{children}</H2>
      {action && <Action>{action}</Action>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 30px;
`;

interface DecoratorProps {
  hasBack: boolean;
}

const Decorator = styled.div<DecoratorProps>`
  width: 4px;
  height: 19px;
  background: ${({ hasBack }) =>
    hasBack ? "var(--main-color)" : "var(--secondary-color)"};
  margin-top: 8px;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 5px;
  }
`;

const Action = styled.div`
  margin-left: auto;
`;
