import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { useArtistContext } from "Components";
import { deleteDoc } from "firebase/firestore";
import { Button } from "melodies-source/Button";
import { Confirmation } from "melodies-source/Confirmation";
import { Modal } from "melodies-source/Modal";
import { Body1 } from "melodies-source/Text";
import { addToast } from "melodies-source/Toast";
import { useState } from "react";
import { Member } from ".";

interface DeleteInvitationProps {
  member: Member;
}

export const DeleteInvitation = ({ member }: DeleteInvitationProps) => {
  const { getArtistGroup } = useArtistContext();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    await deleteDoc(member.ref);
    addToast("User removed", "success");
    getArtistGroup();
    toggleModal();
  };

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <div>
      <Button style={{ padding: 0, height: "auto" }} text onClick={toggleModal}>
        <CloseIcon width={12} height={12} style={{ marginRight: 4 }} /> Remove
      </Button>
      <Modal header="Remove User?" isOpen={isOpen} onClose={toggleModal}>
        <Confirmation
          confirmLabel="Remove User"
          loading={loading}
          onCancel={toggleModal}
          onConfirm={handleDelete}
        >
          <Body1>
            {member.email} will no longer be able to accept their invitation and
            will be removed from this account as a user.
          </Body1>
        </Confirmation>
      </Modal>
    </div>
  );
};
