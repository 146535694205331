import styled from "styled-components";

export const PreviewImage = styled.img`
  border-radius: 10px;
  border: 3px solid #dcdcdc;
  width: 301px;
  height: 276px;
  object-fit: cover;
  object-position: center;
`;
