import * as React from "react";
import { SVGProps } from "react";
export const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5,6.5 C16.4329966,6.5 18,8.06700338 18,10 C18,11.9329966 16.4329966,13.5 14.5,13.5 L11,13.5 L11,12.5 L14.5,12.5 C15.8807119,12.5 17,11.3807119 17,10 C17,8.6745166 15.9684641,7.58996133 14.6643757,7.50531768 L14.5,7.5 L11,7.5 L11,6.5 L14.5,6.5 Z M9,6.5 L9,7.5 L5.5,7.5 C4.11928813,7.5 3,8.61928813 3,10 C3,11.3254834 4.03153594,12.4100387 5.33562431,12.4946823 L5.5,12.5 L9,12.5 L9,13.5 L5.5,13.5 C3.56700338,13.5 2,11.9329966 2,10 C2,8.06700338 3.56700338,6.5 5.5,6.5 L9,6.5 Z M14.5,9.5 C14.7761424,9.5 15,9.72385763 15,10 C15,10.2761424 14.7761424,10.5 14.5,10.5 L5.5,10.5 C5.22385763,10.5 5,10.2761424 5,10 C5,9.72385763 5.22385763,9.5 5.5,9.5 L14.5,9.5 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
