const Microphone = ({ fillColor }: { fillColor?: string }) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8704 13.6111C12.2698 13.0033 10.9967 11.7302 10.3889 10.1296L4.29861 18.8844L5.11563 19.7014L13.8704 13.6111ZM10.069 7.08722C10.5087 4.20671 12.9966 2 16 2C19.3137 2 22 4.68629 22 8C22 11.0034 19.7933 13.4914 16.9128 13.931L4.8844 22.2986L1.70142 19.1156L10.069 7.08722ZM19.5217 9.89832C19.8268 9.33352 20 8.68698 20 8C20 5.79086 18.2092 4 16 4C15.3064 4 14.654 4.17656 14.0853 4.48719C14.3878 5.43113 15.4282 7.01397 16.2071 7.79289C16.9733 8.55909 18.5288 9.5763 19.5217 9.89832ZM18.0331 11.4455C17.4373 11.7978 16.7423 12 16 12C13.7909 12 12 10.2091 12 8C12 7.26444 12.1986 6.57525 12.545 5.9831C13.1017 7.16707 13.9949 8.40909 14.7929 9.20711C15.5807 9.99489 16.8339 10.886 18.0331 11.4455Z"
        fill={fillColor ?? "var(--secondary-color)"}
      />
    </svg>
  );
};

export default Microphone;
