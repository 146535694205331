import * as React from "react";
import { SVGProps } from "react";
export const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4,12 C4.55228475,12 5,12.4477153 5,13 L5,15 L15,15 L15,13 C15,12.4477153 15.4477153,12 16,12 C16.5522847,12 17,12.4477153 17,13 L17,16 C17,16.5522847 16.5522847,17 16,17 L4,17 C3.44771525,17 3,16.5522847 3,16 L3,13 C3,12.4477153 3.44771525,12 4,12 Z M11,13.0042136 L10.999,6.82821356 L12.2928932,8.12132034 C12.6533772,8.48180431 13.2206082,8.50953384 13.6128994,8.20450895 L13.7071068,8.12132034 C14.0675907,7.76083638 14.0953203,7.19360533 13.7902954,6.80131412 L13.7071068,6.70710678 L10,3 L6.29289322,6.70710678 C5.90236893,7.09763107 5.90236893,7.73079605 6.29289322,8.12132034 C6.65337718,8.48180431 7.22060824,8.50953384 7.61289944,8.20450895 L7.70710678,8.12132034 L8.999,6.83021356 L9,13.0042136 L11,13.0042136 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
