import {
  Query,
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { useArtistContext } from "Components";
import { useCollection } from "react-firebase-hooks/firestore";
import { useEffect, useState } from "react";
import { useDocumentModel } from "../../../../../../hooks/useModel";
import {
  Conversation,
  ConversationMessage,
} from "@musicaudienceexchange/communications-interface";
import { DateTime } from "luxon";
import { useUser } from "auth";
import { WinnerDetails } from "./useSweepsWinnersDetails";
import { createHash } from "crypto";

export const useWinnerConversation = ({
  conversationId,
  winner,
}: {
  conversationId: string;
  winner: WinnerDetails;
}) => {
  const db = getFirestore();
  const { id: artistGroupId } = useArtistContext();

  const [creatingConversation, setCreatingConversation] = useState(false);
  const [sweepsId] = conversationId.split("#");
  const conversationRef = doc(db, `conversations/${conversationId}`);
  const [conversation, loadingConversation] = useDocumentModel(
    Conversation,
    conversationRef,
    {
      ignorePendingWrites: true,
      ignoreInvalid: true,
    },
  );
  const {
    user: { uid },
  } = useUser();

  useEffect(() => {
    if (!loadingConversation && !conversation) {
      setCreatingConversation(true);
      const hashedEmail = createHash("sha1")
        .update(winner.email.toLowerCase())
        .digest("hex");
      (async () => {
        await createConversation({
          name: winner.name,
          phone: winner.rawPhone,
          uid: `fan#${hashedEmail}`,
        });
        setCreatingConversation(false);
      })();
    }
  }, [loadingConversation, conversation, winner]);

  const messagesRef = collection(
    db,
    `conversations/${conversationId}/messages`,
  );

  const [messagesQuery, loadingMessages] = useCollection<ConversationMessage>(
    query(
      messagesRef,
      orderBy("createdAt", "asc"),
    ) as Query<ConversationMessage>,
  );

  const [messages, setMessages] = useState<
    (ConversationMessage & { id: string })[]
  >([]);

  useEffect(() => {
    if (messagesQuery?.docs?.length) {
      setMessages(
        messagesQuery.docs.map((mDoc) => ({
          ...mDoc.data(),
          id: mDoc.id,
          createdAt: mDoc.data().createdAt
            ? DateTime.fromMillis(mDoc.data().createdAt.toMillis())
            : undefined,
          updatedAt: mDoc.data().updatedAt
            ? DateTime.fromMillis(mDoc.data().updatedAt.toMillis())
            : undefined,
          sentAt: mDoc.data().sentAt
            ? DateTime.fromMillis(mDoc.data().sentAt.toMillis())
            : undefined,
        })),
      );
    }
  }, [messagesQuery]);

  async function createConversation({
    name,
    phone,
    uid,
  }: {
    name: string;
    phone: string;
    uid: string;
  }) {
    try {
      const artistGroup = await getDoc(
        doc(getFirestore(), `artist_groups/${artistGroupId}`),
      );
      const memberUids = Object.keys(artistGroup.data().members) ?? [];
      const conversation = {
        artistGroupId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        metadata: { sweepsId, memberUids },
        messages: 0,
        readCount: 0,
        status: null,
        writeCount: 0,
        user: { uid, phoneNumber: phone ?? "", displayName: name },
      };
      await setDoc(doc(db, `conversations/${conversationId}`), conversation);
    } catch (err) {
      console.error(
        `there was a problem creating conversation ${conversationId}: ${
          (err as Error).message
        }`,
      );
    }
  }

  async function sendMessage({ message }: { message: string }) {
    if (!message.trim()) {
      console.info(`no message content - no message sent`);
      return;
    }

    try {
      const msgData = {
        author: "system",
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        source: "Firestore",
        status: "pending",
        message,
        // artist group uid
        uid,
      };

      await addDoc(messagesRef, msgData);
      await markRead();
    } catch (err) {
      console.error(`error sending message: ${(err as Error).message}`);
    }
  }

  async function markRead() {
    try {
      await setDoc(
        conversationRef,
        { readCount: conversation.messages },
        { merge: true },
      );
    } catch (err) {
      console.error(
        `unable to set readCount for conversation: ${conversation}`,
      );
    }
  }

  return {
    messages,
    createConversation,
    markMessagesRead: markRead,
    sendMessage,
    loading: loadingMessages || creatingConversation,
  };
};
