import { useFanTableContext } from "..";
import { useTypesenseProxyContext } from 'contexts/TypesenseProxyContext';
import { Filter } from "./Filter";
import {
  AutocompleteAsync as Autocomplete,
  OptionProps,
} from "melodies-source/Autocomplete";
import styled from "styled-components";
import { DateTime } from "luxon";
import { Radio } from "melodies-source/Selectable";
import { useArtistContext } from "Components";

const DisplayNameMap = {
  setlive: "SET.Live",
  meetgreet: "Meet & Greet",
  livestream: "Livestream",
  settheset: "Set The Set",
  setfan: "SET.Fan",
};

const CustomFilter = styled(Filter)`
  display: flex;
  flex-direction: column;
  padding-bottom: 35px;
  & [data-popper-placement="top"] {
    bottom: 45px !important;
  }
`;

export const Source = () => {

  const { filters, setFilters, sourceText, setSourceText } =
    useFanTableContext();

  const { id } = useArtistContext();

  const { proxySearch } = useTypesenseProxyContext();

  const search = async (searchTerm) => 
    proxySearch(
      'lead_sources', 
      {
        q: searchTerm,
        query_by: `name`,
        filter_by: `(groupId:=${id} || additionalGroups:=[${id}]) && startAt:<=${DateTime.utc().toMillis()} && deletedAt:0`,
        sort_by: "startAt:desc",
        page: 1,
        per_page: 30,
      }
    )
    .then((res) => {
      return res.hits
        .map(({ document }: { document: any }) => ({
          label: document?.name,
          value: document?.id,
          ...document,
        }))
        .filter((s) => !filters.source?.find((e) => e.id === s.id));
    });

  const onSelectFilter = (source) => {
    let newSource = [...(filters.source || []), source];
    if (newSource.length) {
      setFilters({
        source: newSource,
      });
    } else {
      let { source, ...newFilters } = filters;
      setFilters(newFilters);
    }
  };

  const onDelete = (source) => {
    if (!filters.source?.length) {
      setFilters({ allSources: null });
    }
    if (filters.source?.find((s) => s.id === source.id)) {
      let newSource = filters.source.filter((s) => s.id !== source.id);
      setFilters({
        source: newSource,
      });
    }
  };

  const handleClear = () => {
    setSourceText("");
    let { source, ...rest } = filters;
    setFilters(rest);
  };

  const handleSelect = (source) => {
    onSelectFilter(source);
    setTimeout(() => {
      setSourceText("");
    }); //hacky way to clear the input after select
  };

  if (!proxySearch) {
    return null;
  }

  let groupFilters = {};

  filters?.source?.map((s) => {
    groupFilters[s.type] = [...(groupFilters[s.type] || []), s];
  });

  return (
    <CustomFilter>
      <AutocompleteAsync
        placeholder="Search for a Source..."
        value={null}
        text={sourceText}
        setText={setSourceText}
        onChange={handleSelect}
        onClear={handleClear}
        //@ts-ignore
        getOptions={search}
        customOption={CustomOption}
      />
      <Radio
        onChange={() =>
          filters?.source?.length && setFilters({ allSources: false })
        }
        value={!filters?.allSources && !!filters?.source?.length}
        label="Include contacts that appear in at least one selected source"
        style={{ marginTop: 15 }}
      />
      <Radio
        onChange={() =>
          filters?.source?.length && setFilters({ allSources: true })
        }
        value={filters?.allSources && !!filters?.source?.length}
        label="Include contacts that appear in all selected sources"
      />
      <ChipContainer>
        {Object.keys(groupFilters).map((g) => (
          <ChipGroup>
            <ChipGroupTitle>{DisplayNameMap[g] ?? g}</ChipGroupTitle>
            <ChipWrapper>
              {groupFilters[g].map((s) => (
                <Chip key={s.id} onClick={() => onDelete(s)}>
                  <ChipDetails>
                    {s.name}
                    {s?.startAt && (
                      <ChipDate>
                        {s?.displayAddress ??
                          (g === "setfan" || g === "settheset"
                            ? "Survey"
                            : DisplayNameMap[g])}{" "}
                        • {getDate(s.startAt)}
                      </ChipDate>
                    )}
                  </ChipDetails>
                  <CustomClose style={{ marginLeft: "10px" }} />
                </Chip>
              ))}
            </ChipWrapper>
          </ChipGroup>
        ))}
      </ChipContainer>
    </CustomFilter>
  );
};

const getDate = (date) => {
  const d = DateTime.fromMillis(date);
  return d.toFormat("MMMM dd, yyyy");
};

interface CustomOptionProps extends OptionProps {
  startAt: number;
  type: string;
}
const CustomOption: React.FC<CustomOptionProps> = ({
  label,
  startAt,
  type,
  ...props
}) => {
  return (
    <OptionContainer {...props}>
      <OptionTitle>{label}</OptionTitle>
      {type && <OptionDetail>{DisplayNameMap[type]}</OptionDetail>}
      {startAt && <OptionDetail>{getDate(startAt)}</OptionDetail>}
    </OptionContainer>
  );
};

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: var(--text-color);
  &:hover {
    background: var(--hover-background-color);
  }
`;

export const OptionTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const OptionDetail = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ChipGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  width: 100%;
`;

export const ChipDate = styled.div`
  font-size: 12px;
`;

export const ChipDetails = styled.div`
  max-width: 290px;
`;

export const Chip = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--main-color);
  color: var(--primary-button-text-color);
  padding: 14px;
  font-size: 14px;
  line-height: 20px;
  margin: 10px 10px 0 0;
  //border: 1px solid #e6e9eb;
  border-radius: 22px;
  align-items: center;
  font-weight: 400;
  width: 100%;
`;

export const ChipWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const ChipGroupTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
`;

const AutocompleteAsync = styled(Autocomplete)`
  input + div svg {
    display: none;
  }
`;

export const CustomClose = (props) => (
  <svg
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0ZM5.613 4.21A1 1 0 0 0 4.21 5.613l.083.094L6.585 8l-2.292 2.293-.083.094A1 1 0 0 0 5.51 11.86l.102-.07.094-.083L8 9.415l2.293 2.292.094.083a1 1 0 0 0 1.403-1.403l-.083-.094L9.415 8l2.292-2.293.083-.094A1 1 0 0 0 10.49 4.14l-.102.07-.094.083L8 6.585 5.707 4.293l-.094-.083Z"
      fill="var(--primary-button-text-color)"
    />
  </svg>
);
