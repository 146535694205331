import * as React from "react";
import { SVGProps } from "react";
export const SvgImportLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15,3 L15,5 L19,5 L19,19 L5,19 L5,15 L3,15 L3,20 C3,20.5522847 3.44771525,21 4,21 L20,21 C20.5522847,21 21,20.5522847 21,20 L21,4 C21,3.44771525 20.5522847,3 20,3 L15,3 Z M17,17.0042136 L17,8.00421356 L15,8.00421356 L15,13.5862136 L7.41421356,6 L6,7.41421356 L13.59,15.0042136 L8,15.0042136 L8,17.0042136 L17,17.0042136 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
