const Switch = ({ fillColor }: { fillColor?: string }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3C10.7952 3 12.3348 3.80902 13.3552 5.25H10.5V6.75H15.75V1.5H14.25V3.95276C12.9562 2.38688 11.1032 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9H3C3 5.68629 5.68629 3 9 3ZM9 15C7.20478 15 5.66515 14.191 4.6448 12.75H7.5V11.25H2.25V16.5H3.75V14.0472C5.04383 15.6131 6.89682 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9H15C15 12.3137 12.3137 15 9 15Z"
        fill={fillColor ?? "var(--main-color)"}
      />
    </svg>
  );
};

export default Switch;
