import React from "react";
import { SettingsRow } from "../SettingsRow";
import { AdvancedFields } from "./AdvancedFields";
import styled from "styled-components";

export const Advanced: React.FC = () => {
  return (
    <SettingsRow
      title={"Advanced Settings"}
      description={
        <>Update MAX Artist ID, Spotify ID. Enable Post-Show Email.</>
      }
    >
      <Container>
        <AdvancedFields />
      </Container>
    </SettingsRow>
  );
};

const Container = styled.div`
  display: flex;
  gap: 70px;
  margin-bottom: 15px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;
