import { AvatarsStack } from "melodies-source/AvatarsStack";
import { Tooltip } from "melodies-source/Tooltip";
import { getArtistImageById } from "Utils/getArtistImageById";
import { TooltipMessage } from "./TooltipMessage";

interface AdditionalGroupsAvatarsProps {
  ids: string[];
  size?: number;
  tooltip?: boolean;
}

export const AdditionalGroupsAvatars = ({
  ids,
  size,
  tooltip = true,
}: AdditionalGroupsAvatarsProps) => {
  const stack = <AvatarsStack urls={ids.map(getArtistImageById)} size={size} />;

  return tooltip ? (
    <Tooltip parent={stack}>
      <TooltipMessage ids={ids} />
    </Tooltip>
  ) : (
    stack
  );
};
