import { ArrayHelpers, useFormikContext } from "formik";
import { Modal } from "melodies-source/Modal";
import { useState } from "react";
import { AlbumForm } from "./AlbumForm";
import { SortableRow } from "./SortableRow";
import { Album, SongsQuestionValues } from "./types";

interface AlbumRowProps {
  index: number;
  album: Album;
  editable?: boolean;
  helpers: ArrayHelpers<Album[]>;
}

export const AlbumRow = ({
  index,
  album,
  editable,
  helpers,
}: AlbumRowProps) => {
  const { setFieldValue } = useFormikContext<SongsQuestionValues>();
  const [editing, setEditing] = useState(false);

  return (
    <>
      <SortableRow
        index={index}
        row={album}
        editable={editable}
        onVisibleChange={(visible) => {
          setFieldValue(`albums.${index}`, {
            ...album,
            visible,
            songs: album.songs.map((song) => ({
              ...song,
              visible,
            })),
          });
        }}
        onEdit={() => setEditing(true)}
        onDelete={() => helpers.remove(index)}
      />
      <Modal
        variant="large"
        withCloseIcon={false}
        withBackdropClose={false}
        isOpen={editing}
      >
        <AlbumForm
          album={album}
          onCancel={() => setEditing(false)}
          onSubmit={(album) => {
            helpers.replace(index, album);
            setEditing(false);
          }}
        />
      </Modal>
    </>
  );
};
