import { withAdminRequired } from "Components";
import { SvgEdit } from "melodies-source/Svgs/Edit";
import styled from "styled-components";
import { NameEditModal } from "./NameEditModal";
const NameEditInternal = () => {
  return (
    <Container>
      <NameEditModal
        target={SvgEdit}
        color="var(--secondary-text-color)"
        style={{ cursor: "pointer" }}
      />
    </Container>
  );
};

export const NameEdit = withAdminRequired(NameEditInternal);

const Container = styled.div`
  margin: 4px 0 0 16px;
`;
