import { CenterFlexRow } from "Routes/styled";
import { Body2 } from "melodies-source/Text";
import { ReactComponent as SvgHand } from "assets/svg/hand.svg";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { ReactComponent as SvgAddImage } from "assets/svg/add-image.svg";
import { ReactComponent as SvgAddText } from "assets/svg/add-text.svg";
import { FlexRow } from "Components/Flex";
import { useScrollLockContext } from "contexts/ScrollLockContext";
import { useAddSurveyModules } from "../hooks/useSurveyModules";
import { AddQuestionModal } from "../Components/Modal/AddQuestion";
import { useEffect } from "react";
import { Button } from "melodies-source/Button";
import { SortableContainer } from "Routes/SetFan/Components/Sortable/SortableContainer";
import { useBuilderContext } from "Routes/SetFan/Components/BuilderContext";
import { ModuleType } from "@max/common/dist/setfan";
import { QuestionType } from "Routes/SetFan/Components/Modal/AddQuestion/BaseQuestionModal";
import { QuestionTypeCard } from "Routes/SetFan/Components/QuestionTypeCard";
import { useHistory, useParams } from "react-router-dom";

export const SurveyQuestions = () => {
  const { data, setData } = useBuilderContext();
  const { lockScroll, unlockScroll } = useScrollLockContext();
  const { state, dispatch } = useAddSurveyModules(data.surveyQuestions);
  const history = useHistory();
  const { surveyId } = useParams() as { surveyId: string };
  const isWizard = history.location.pathname.includes("/wizard");

  useEffect(() => {
    setData((data) => ({
      ...data,
      surveyQuestions: state.questions.map((q) => q.toBuilderConfig()),
    }));
  }, [state.questions, setData]);

  return (
    <>
      <CenterFlexRow
        style={{
          justifyContent: "flex-end",
          color: "#666",
          width: "100%",
          margin: "6px 0 32px",
        }}
      >
        <SvgHand style={{ marginRight: 4 }} />
        <Body2>Click and drag to rearrange questions</Body2>
      </CenterFlexRow>
      <CenterFlexRow
        style={{ justifyContent: "space-between", margin: "0 0 16px" }}
      >
        <Button
          leftIcon={<SvgAddAlt />}
          variant="outlined"
          onClick={() => {
            lockScroll();
            dispatch({ type: "openModal" });
          }}
        >
          Add Question
        </Button>
        <AddQuestionModal
          {...{
            onClose: () => {
              unlockScroll();
              dispatch({ type: "closeModal" });
            },
            isOpen: state.isOpen,
            dispatch,
            existing: state.existing,
            state,
          }}
        />
        <FlexRow>
          <Button
            text
            onClick={() => {
              lockScroll();
              dispatch({ type: "openModal", payload: { route: "image" } });
            }}
            style={{ marginRight: 8, padding: "10px 12px" }}
            leftIcon={<SvgAddImage />}
          >
            Add Image
          </Button>
          <Button
            text
            onClick={() => {
              lockScroll();
              dispatch({ type: "openModal", payload: { route: "text" } });
            }}
            leftIcon={<SvgAddText />}
            style={{ padding: "10px 12px" }}
          >
            Add Text
          </Button>
        </FlexRow>
      </CenterFlexRow>
      <SortableContainer
        items={state.questions}
        onChange={(items) => dispatch({ type: "setModules", payload: items })}
      >
        {state.questions.map(
          (q, index) =>
            q.editProps() && (
              <QuestionTypeCard
                key={q.id}
                {...{
                  ...q.editProps(),
                  index,
                  onEdit:
                    q.type === "SongsQuestion"
                      ? () =>
                          history.push(
                            isWizard
                              ? `wizard/setlist/${q.id}`
                              : `${surveyId}/setlist/${q.id}`,
                            {
                              ...q.toBuilderConfig(),
                            },
                          )
                      : () =>
                          dispatch({
                            type: "openModal",
                            payload: {
                              route: moduleTypeToRoute(q.type),
                              existing: q,
                            },
                          }),
                  onDelete:
                    q.type === "SongsQuestion"
                      ? undefined
                      : () =>
                          dispatch({ type: "removeModule", payload: index }),
                }}
              />
            ),
        )}
      </SortableContainer>
    </>
  );
};

const moduleTypeToRoute = (type: ModuleType): QuestionType => {
  switch (type) {
    case "Autocomplete":
    case "Dropdown":
    case "MultipleSelectQuestion":
    case "SingleSelectQuestion":
      return "multipleChoiceQuestion";
    case "ImageQuestion":
      return "imageQuestion";
    case "TextQuestion":
      return "freeResponseQuestion";
    case "RankedQuestion":
      return "ratingQuestion";
    case "image":
      return "image";
    case "text":
      return "text";
  }
};
