import {
  collection,
  CollectionReference,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Data } from "react-firebase-hooks/firestore/dist/firestore/types";

export interface SetLiveBuilderEvent {
  draftId: string;
  publishedId?: string | null;
}

export type BuilderEventData = Data<SetLiveBuilderEvent, "id", "ref">;

export const useSetLiveBuilderEvents = (artistGroupId: string) => {
  const [data, loading] = useCollectionData<SetLiveBuilderEvent, "id", "ref">(
    query(
      collection(
        getFirestore(),
        "set_fresh_builder_events",
      ) as CollectionReference<SetLiveBuilderEvent>,
      where("artistGroupId", "==", artistGroupId),
    ),
    {
      idField: "id",
      refField: "ref",
    },
  );

  return [data, loading] as const;
};
