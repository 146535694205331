import React from "react";
import styled, { css } from "styled-components";
import { Video } from "Routes/HelpCenter";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  video: Video;
}

export const VideoModal: React.FC<Props> = ({
  open = false,
  setOpen,
  video,
}) => {
  const toggleOpen = () => setOpen((open) => !open);
  return (
    open && (
      <Container>
        <Backdrop />
        <VideoBackground onClick={toggleOpen}>
          <VideoContainer onClick={(e) => e.stopPropagation()}>
            <video width="900" height="500" controls>
              <source src={video.url} />
              Your browser does not support the video tag.
            </video>
            <CloseIconContainer onClick={toggleOpen}>
              <CloseIcon />
            </CloseIconContainer>
          </VideoContainer>
        </VideoBackground>
      </Container>
    )
  );
};

const FixedElement = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
const Container = styled.div`
  ${FixedElement}

  video {
    background: black;

    ${({ theme }) => theme.media.mobile} {
      margin: auto;
      width: 90vw;
      height: 50vw;
    }
  }
`;

const Backdrop = styled.div`
  ${FixedElement}
  background: black;
  opacity: 0.5;
  z-index: -1;
`;

const VideoBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const VideoContainer = styled.div`
  position: relative;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  padding: 7px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;

    ${({ theme }) => theme.media.mobile} {
      width: 22px;
      height: 22px;
    }
  }
`;
