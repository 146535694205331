import { MutableRefObject, useEffect } from "react";

export const useClickOutside = (
  ref: MutableRefObject<HTMLElement> | null,
  onClickOutside: Function,
  preventRef?: MutableRefObject<HTMLElement | null>,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        preventRef?.current &&
        preventRef.current.contains(event.target as Node)
      ) {
        return;
      }
      if (ref?.current && !ref.current.contains(event.target as Node)) {
        onClickOutside(event);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, preventRef]);
};
