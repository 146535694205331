import React, { useState } from "react";
import styled from "styled-components";
import { Body2, Subtitle1 } from "melodies-source/Text";
import { useArtistContext, Button } from "Components";
import { Member } from ".";
import { getDateTimeString } from "./getDateTimeString";
import { ReactComponent as MailIcon } from "assets/svg/mail.svg";
import { ReactComponent as CheckIcon } from "assets/svg/check.svg";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { Modal } from "melodies-source/Modal";

interface Props {
  members: Member[];
}

export const AccessRequestArchive: React.FC<Props> = ({ members }) => {
  const [modalOpen, setModal] = useState(false);
  const { artistGroup } = useArtistContext();

  return (
    <>
      <Button text onClick={() => setModal(true)}>
        Access Request Archive
      </Button>
      <Modal
        isOpen={modalOpen}
        onClose={() => setModal(false)}
        header="Access Request Archive"
      >
        <Container>
          <MemberRequests>
            {members
              .sort(
                (a, b) => a.reviewedAt?.toMillis() - b.requestedAt?.toMillis(),
              )
              .map((member) => (
                <MemberRequest key={member.email}>
                  <Subtitle1>{member.name}</Subtitle1>
                  <Body2>{member.title}</Body2>
                  <Body2>{member.company}</Body2>
                  <Body2>{member.email}</Body2>
                  <StatusInfo>
                    <MailIcon /> Requested{" "}
                    {getDateTimeString(member.requestedAt)}
                  </StatusInfo>
                  <StatusInfo>
                    {member.status === "approved" ? (
                      <>
                        <CheckIcon />
                        Approved
                      </>
                    ) : (
                      <>
                        <CloseIcon />
                        Denied
                      </>
                    )}{" "}
                    {getDateTimeString(member.reviewedAt)} by{" "}
                    {member.reviewedBy
                      ? artistGroup?.members.find(
                          ({ uid }) => uid === member.reviewedBy,
                        )?.name || "System Admin"
                      : "-"}
                  </StatusInfo>
                </MemberRequest>
              ))}
          </MemberRequests>
          <Button onClick={() => setModal(false)}>Close</Button>
        </Container>
      </Modal>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-height: 100%;

  button {
    width: auto;
    margin-left: auto;
  }
`;

const MemberRequests = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;
`;

const MemberRequest = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const StatusInfo = styled(Body2)`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #999;
`;
