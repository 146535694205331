import { useDesktopMediaQuery, useSetLiveEvents } from "hooks";
import { AppCard } from "./AppCard";
import styled, { css } from "styled-components";
import { Font12, Font15, Font16, Font40 } from "Routes/styled";
import { PastShowCard, UpcomingShowCard } from "./ArtistShowCard";
import { svg } from "svg";
import { useHistory } from "react-router-dom";
import { SetLiveAppNoData } from "./AppCardNoData";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import { useArtistContext } from "Components";
import { useTabletMediaQuery } from "hooks/useTabletMediaQuery";
import { Link } from "melodies-source/Link";
import { useCustomAppContext } from "contexts/CustomAppContext";

const ArtistShows = () => {
  const { id: artistGroupId } = useArtistContext();
  const isDesktop = useDesktopMediaQuery(1063);
  const isTablet = useTabletMediaQuery("950px");
  const history = useHistory();
  const { customApp } = useCustomAppContext();

  const { setLiveEvents: pastShows, loading: loadingPastEvents } =
    useSetLiveEvents({
      showType: "past",
      resultsPerPage: 3,
    });

  const { setLiveEvents: upcomingShows, loading: loadingEvents } =
    useSetLiveEvents({
      showType: "upcoming",
      filter: "published",
      resultsPerPage: 3,
    });

  const compact = upcomingShows.length > 3 || !!pastShows.length || !isTablet;

  return upcomingShows.length || pastShows.length ? (
    <StyledAppCard
      buttonText={`View All  ${customApp?.event.type.plural || "Shows"}`}
      footerIcon={
        customApp ? (
          <div />
        ) : (
          <svg.SetLiveKnockout height={isDesktop ? 41 : 33} />
        )
      }
      sx={{
        background: "linear-gradient(44.42deg, #571A80 3.08%, #B54E38 97.4%)",
        minHeight: 324,
      }}
      compact={compact}
      onButtonClick={() =>
        history.push(`/${artistGroupId}/set-live/upcoming-shows`)
      }
    >
      <CardColumn>
        <AppCardHeader>
          Upcoming {customApp?.event.type.plural || "Shows"}
        </AppCardHeader>
        {upcomingShows.map((upcomingShow) => (
          <UpcomingShowCard
            event={upcomingShow}
            compact={compact}
            key={`event-${upcomingShow.id}`}
          />
        ))}
        {!upcomingShows.length && <NoUpcomingShowsCard />}
      </CardColumn>
      <CardColumn>
        <AppCardHeader>
          Past {customApp?.event.type.plural || "Shows"}
        </AppCardHeader>
        {pastShows?.map((pastShow) => (
          <PastShowCard event={pastShow} key={`event-${pastShow.id}`} />
        ))}
      </CardColumn>
    </StyledAppCard>
  ) : (
    <SetLiveAppNoData loading={loadingEvents || loadingPastEvents} />
  );
};

export default ArtistShows;

const NoUpcomingShowsCard = () => {
  const { id: artistGroupId } = useArtistContext();

  const createEvent = async () => {
    try {
      const ref = await addDoc(
        collection(getFirestore(), `set_fresh_builder_events`),
        { artistGroupId },
      );
      const url = new URL(
        `${process.env.REACT_APP_BUILDER_URL?.replace("{id}", ref.id)}theme`,
      );
      url.search = "ref=ap";
      window.location.href = url.toString();
    } catch (err: any) {
      alert("Error creating new event: " + err.message);
    }
  };

  return (
    <NoShowsCard>
      <NoShowsBlendedBG />
      <NoShowsContent>
        <StyledLogo as={svg.MicrophoneLogo} />
        <NoCurrentHeader>You have no upcoming shows.</NoCurrentHeader>
        <NoCurrentBody>
          Every new show on SET.Live will help you build your fan profile.
        </NoCurrentBody>
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={createEvent}
        >
          <NoCurrentFooter>Create a Show</NoCurrentFooter>
          <svg.Arrow fillColor="white" />
        </div>
      </NoShowsContent>
    </NoShowsCard>
  );
};

const StyledAppCard = styled(AppCard)(({ theme }) => {
  if (!theme.custom) return null;
  const { background } = theme.custom.pages.dashboard.cards.shows;
  return css`
    background: ${background} !important;
  `;
});

const QRLink = styled(Link)`
  color: #ffffff;
  ${({ theme }) => theme.mediaQueries.desktop} {
    position: absolute;
    top: 32px;
    right: 32px;
  }
`;

const AppCardHeader = styled(Font40)`
  font-weight: 700;
  margin-bottom: 32px;

  ${({ theme }) => theme.media.tablet} {
    font-size: 30px;
    line-height: 45px;
  }

  ${({ theme }) => theme.media.mobile} {
    margin-bottom: 7px;
  }
`;

const BlendedBG = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  border-radius: 12px;
`;

const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 14px;

  & > div:nth-last-child(n + 2) {
    margin-bottom: 19px;
  }
`;

const NoCurrentHeader = styled(Font15)`
  font-weight: 600;
  margin-bottom: 6px;
`;

const NoCurrentBody = styled(Font12)`
  font-weight: 500;
  text-align: center;
  margin-bottom: 38px;
`;

const NoCurrentFooter = styled(Font16)`
  margin-bottom: 0 5px 0;
  margin-right: 5px;
  cursor: pointer;
`;
const NoShowsCard = styled.div`
  position: relative;
  height: 282px;
  border-radius: 12px;
  display: flex;
`;

const NoShowsContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 39px 27px 48px 27px;
`;

const NoShowsBlendedBG = styled(BlendedBG)`
  background: rgba(27, 0, 118, 0.5);
`;

const StyledLogo = styled.div`
  margin-bottom: 13px;
`;
