import { Label } from "melodies-source/Text";
import { ChangeEvent, ChangeEventHandler } from "react";
import styled from "styled-components";

interface Props {
  checked?: boolean;
  disabled?: boolean;
  onChange: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
  withSwitchLabel?: boolean;
  label?: string;
}

export const Toggle = ({
  checked = false,
  disabled,
  onChange,
  withSwitchLabel,
  label,
  ...props
}: Props) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    return onChange(e.target.checked, e);
  };

  return (
    <Container>
      <ToggleWrapper
        disabled={disabled}
        checked={checked}
        withSwitchLabel={withSwitchLabel}
      >
        <Checkbox
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          {...props}
        />
        <ToggleHandle
          disabled={disabled}
          checked={checked}
          withSwitchLabel={withSwitchLabel}
        />
        {withSwitchLabel && (
          <SwitchLabel disabled={disabled} checked={checked}>
            {checked ? "On" : "Off"}
          </SwitchLabel>
        )}
      </ToggleWrapper>
      {label && <Label>{label}</Label>}
    </Container>
  );
};

const Container = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;
`;

type ScProps = Omit<Props, "onChange">;

const ToggleWrapper = styled.div<ScProps>`
  background: ${(props) =>
    props.checked
      ? props.disabled
        ? props.theme.colors.gray1
        : props.theme.custom
        ? props.theme.colors.main
        : props.theme.colors.green
      : props.disabled
      ? props.theme.colors.gray3
      : "#f5f5f5"};
  border: 1px solid
    ${(props) =>
      props.checked
        ? props.disabled
          ? props.theme.colors.gray1
          : props.theme.custom
          ? props.theme.colors.main
          : props.theme.colors.green
        : props.disabled
        ? props.theme.colors.gray3
        : props.theme.colors.gray4};
  border-radius: 16px;
  cursor: pointer;
  height: 24px;
  position: relative;
  width: ${({ withSwitchLabel }) => (withSwitchLabel ? 56 : 45)}px;
`;

const Checkbox = styled.input`
  opacity: 0;
  position: absolute;
`;

const ToggleHandle = styled.span<ScProps>`
  background: #fff;
  border-radius: 50%;
  box-shadow: ${(props) =>
    props.disabled ? "none" : "0 1px 8px 0 rgba(0, 0, 0, 0.24)"};
  display: inline-block;
  height: 20px;
  transition: all 50ms linear;
  left: ${(props) =>
    props.checked ? (props.withSwitchLabel ? "34px" : "22px") : "1px"};
  top: 1px;
  position: absolute;
  width: 20px;
  z-index: 3;
`;

const SwitchLabel = styled.div<{ disabled: boolean; checked: boolean }>`
  color: ${(props) =>
    props.checked
      ? props.disabled
        ? props.theme.colors.black60
        : "#FFFFFF"
      : props.disabled
      ? props.theme.colors.black60
      : props.theme.colors.black60};
  position: absolute;
  left: ${(props) => (props.checked ? "7px" : "24px")};
  line-height: 1.5;
`;
