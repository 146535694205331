import React, { useState } from "react";
import { AutocompleteAsync, OptionProps } from "melodies-source/Autocomplete";
import { Modal } from "melodies-source/Modal";
import { Body1 } from "melodies-source/Text";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  Query,
  QueryDocumentSnapshot,
  collection,
  doc,
  getFirestore,
  query,
  setDoc,
  deleteField,
  where,
} from "firebase/firestore";
import * as admin from "firebase-admin";
import { toast } from "react-hot-toast";
import { ArtistGroup } from "@max/common/src/artists";
import { ListItem } from "melodies-source/ListItem";
import { ProgramCard } from "./ProgramCard";
import styled from "styled-components";
import { GtbRegion } from "@max/common/src/thirdparty/gtb";
import { ModalDelete } from "../../Components/ModalDelete";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { GtbCustomApp } from "custom/companies/gtb/types";
import { useTypesenseProxyContext } from "contexts/TypesenseProxyContext";

interface Props {
  region: QueryDocumentSnapshot<GtbRegion>;
  isOpen: boolean;
  onClose: () => void;
}

type Option = Pick<OptionProps, "label" | "value">;

interface TypesenseArtistGroup {
  id: string;
  name: string;
}

export const ProgramsModal: React.FC<Props> = ({ region, isOpen, onClose }) => {
  const [groups] = useCollection<ArtistGroup>(
    query(
      collection(getFirestore(), "artist_groups"),
      where("__name__", "in", [
        "-",
        ...Object.keys(region?.data().programs || {}),
      ]),
    ) as Query<ArtistGroup>,
  );

  const [text, setText] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<Option>(null);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] =
    useState<QueryDocumentSnapshot<ArtistGroup>>(null);
  const [loading, setLoading] = useState(false);
  const { customApp } = useCustomAppContext<GtbCustomApp>();
  const { proxySearch } = useTypesenseProxyContext();

  const updatePrograms = async (
    programId: string,
    action: "add" | "delete",
  ) => {
    setLoading(true);
    let programs:
      | GtbRegion["programs"]
      | Record<string, admin.firestore.FieldValue> = {};

    switch (action) {
      case "add": {
        programs[programId] = { name: "", contacts30day: 0, contactsTotal: 0 };
        break;
      }
      case "delete": {
        programs[programId] = deleteField();
        break;
      }
    }

    try {
      await setDoc(
        doc(getFirestore(), `${customApp.regionsCollection}/${region.id}`),
        {
          programs,
          updatedAt: new Date(),
        },
        { merge: true },
      );
      toast.success(
        `Program ${action === "add" ? "added" : "deleted"} successfully!`,
      );
    } catch (error) {
      console.error(error);
      toast.error(
        `There was an error ${
          action === "add" ? "adding" : "deleting"
        } the program`,
      );
    }
    setLoading(false);
    if (action === "add") setSelectedGroup(null);
    if (action === "delete") setModalDeleteIsOpen(false);
  };

  const handleAdd = async (programId: string) => {
    await updatePrograms(programId, "add");
  };

  const handleDelete = async () => {
    await updatePrograms(groupToDelete.id, "delete");
  };

  const handleModalDeleteOpen = (doc: QueryDocumentSnapshot<ArtistGroup>) => {
    setGroupToDelete(doc);
    setModalDeleteIsOpen(true);
  };

  const search = async (searchTerm) =>
    proxySearch("artist_groups", {
      q: searchTerm,
      query_by: `name`,
      sort_by: "name:asc",
      page: 1,
      per_page: 30,
    }).then((res) => {
      return (
        res?.hits.map(({ document }: { document: TypesenseArtistGroup }) => ({
          value: document?.id,
          label: document?.name,
        })) || []
      );
    });

  const programs =
    groups?.docs.sort((a, b) => a.data().name?.localeCompare(b.data().name)) ||
    [];

  return (
    <>
      <Modal
        isOpen={isOpen}
        header="Edit Programs"
        onClose={onClose}
        withBackdropClose={!modalDeleteIsOpen}
        withCloseIcon
      >
        <Body1>
          You can add programs by searching below, or remove programs by
          clicking the delete icon.
        </Body1>
        <StyledAutocomplete
          label="Program Search"
          placeholder="Type Program Name..."
          text={text}
          setText={setText}
          value={selectedGroup}
          getOptions={search}
          customOption={(opt) => (
            <ListItem {...opt}>
              <ProgramCard
                data={{
                  id: opt.value,
                  name: opt.label,
                  image: `${process.env.REACT_APP_ASSETS_ROCK_PATH}/g/${opt.value}`,
                }}
                isOption
              />
            </ListItem>
          )}
          onChange={({ label, value }) => {
            setSelectedGroup({ label, value });
            handleAdd(value);
          }}
          clearOnSelect
        />
        <ProgramList>
          {programs.map((doc) => {
            const { name, assets } = doc.data();
            return (
              <ProgramCard
                key={doc.id}
                data={{
                  id: doc.id,
                  name,
                  image: assets?.headerSmall?.path,
                }}
                onDelete={() => handleModalDeleteOpen(doc)}
              />
            );
          })}
        </ProgramList>
      </Modal>
      <ModalDelete
        subject="Program"
        name={groupToDelete?.data().name}
        parentSubject="region"
        isOpen={modalDeleteIsOpen}
        onClose={() => setModalDeleteIsOpen(false)}
        onDelete={handleDelete}
        loading={loading}
      />
    </>
  );
};

const StyledAutocomplete = styled(AutocompleteAsync)`
  margin: 20px 0;
`;

const ProgramList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
