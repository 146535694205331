import React, { useState } from "react";
import { Dashboard, Social, Websites, Invite } from "./Steps";
import { useHistory, useParams } from "react-router-dom";

const VIEWS = [Dashboard, Social, Websites, Invite];

export interface StepProps {
  handleBack: () => void;
  handleNext: () => void;
  handleClose: () => void;
}

export const Welcome: React.FC = () => {
  const [step, setStep] = useState(1);
  const { artistId } = useParams<{ artistId: string }>();
  const history = useHistory();

  const handleBack = () => {
    setStep((step) => step - 1);
  };

  const handleNext = () => {
    if (step < VIEWS.length) {
      setStep((step) => step + 1);
    } else {
      history.push(`/${artistId}`);
    }
  };

  const handleClose = () => history.push(`/${artistId}`);

  const Step = VIEWS[step - 1];

  return (
    <Step
      handleBack={handleBack}
      handleNext={handleNext}
      handleClose={handleClose}
    />
  );
};
