import { Card } from "melodies-source/Card";
import Arrow from "svg/Arrow";
import styled, { CSSProperties } from "styled-components";
import { Font16 } from "../../../styled";

const StatusCard = ({
  children,
  footerText,
  onClickFooter,
  sx,
  className,
}: {
  children: JSX.Element | JSX.Element[];
  footerText?: string;
  onClickFooter?: () => void;
  sx?: CSSProperties;
  className?: string;
}) => {
  return (
    <DashboardCard style={sx} isElevated className={className}>
      <>
        <DashboardCardBody>{children}</DashboardCardBody>
        <FooterLink
          cursor={onClickFooter ? "pointer" : "default"}
          onClick={onClickFooter}
        >
          <FooterText>{footerText}</FooterText>
          {onClickFooter && <Arrow />}
        </FooterLink>
      </>
    </DashboardCard>
  );
};

export default StatusCard;

const DashboardCard = styled(Card)`
  padding: 0;
  width: 298px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => theme.media.desktop} {
    height: 282px;
  }
  ${({ theme }) => theme.media.tablet} {
    width: 260px;
  }
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
  @media (min-width: 769px) and (max-width: 1400px) {
    width: 100%;
  }
`;

const DashboardCardBody = styled.div`
  padding: 11px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;

  ${({ theme }) => theme.media.mobile} {
    padding: 6px 13px;
  }
`;

const FooterLink = styled.div<{ cursor: "pointer" | "default" }>`
  height: 47px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e7e7e7;
  width: 100%;
  cursor: ${({ cursor }) => cursor};
  position: relative;
`;

const FooterText = styled(Font16)`
  font-weight: 600;
  margin-right: 5px;
  color: black;
`;
