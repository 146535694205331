import { createPortal } from "react-dom";
import styled from "styled-components";
import { useDesktopMediaQuery } from "hooks";
import {
  SyntheticEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

const Modal = ({
  isOpen,
  onClose,
  headerText,
  children,
  className,
}: {
  isOpen: boolean;
  onClose: () => void;
  headerText?: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
  className?: string;
}) => {
  const isDesktop = useDesktopMediaQuery();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [pillOffset, setPillOffset] = useState(0);
  const [viewableRatio, setViewableRatio] = useState(1);
  const [pillHeight, setPillHeight] = useState(0);

  const handleScroll = (e: SyntheticEvent) => {
    setPillOffset(e.currentTarget.scrollTop * viewableRatio);
  };

  useEffect(() => {
    if (!isOpen) {
      setPillOffset(0);
    }
  }, [isOpen]);

  useLayoutEffect(() => {
    if (scrollRef.current) {
      const scrollHeight = scrollRef.current.scrollHeight ?? 1;
      const viewHeight = scrollRef.current.clientHeight ?? 1;

      const viewableRatio = viewHeight / scrollHeight;
      const pillHeight = viewHeight * viewableRatio;

      setViewableRatio(viewableRatio);
      const footerHeight = isDesktop ? 71 : 105;
      setPillHeight(pillHeight - footerHeight);
    }
  }, [
    isDesktop,
    scrollRef?.current?.scrollHeight,
    scrollRef?.current?.clientHeight,
  ]);

  return isOpen
    ? createPortal(
        <PageContainer isDesktop={isDesktop}>
          <ModalBackdrop onClick={onClose} />
          <ModalContainer isDesktop={isDesktop}>
            <CloseBG onClick={onClose}>
              <CloseIcon />
            </CloseBG>
            <ModalBody isDesktop={isDesktop} className={className}>
              <ModalContent isDesktop={isDesktop}>
                <ModalHeader isDesktop={isDesktop}>{headerText}</ModalHeader>
                <BodyContainer ref={scrollRef} onScroll={handleScroll}>
                  {children}
                </BodyContainer>
                {viewableRatio < 1 && (
                  <ScrollPill height={pillHeight} offsetY={pillOffset} />
                )}
              </ModalContent>
            </ModalBody>
          </ModalContainer>
        </PageContainer>,
        document.querySelector("#root"),
      )
    : null;
};

export default Modal;

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  scrollbar-width: none;
`;

const CloseBG = styled.div`
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 44px;
  right: 50px;
  z-index: 10;

  ${({ theme }) => theme.media.mobile} {
    top: 20px;
    right: 19px;
  }
`;

const ModalBody = styled.div<{ isDesktop: boolean }>`
  width: 100%;
  max-height: calc(100vh - 102px);
  display: flex;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

  & ::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.media.mobile} {
    height: calc(100vh - 75px);
    max-height: calc(100vh - 54px);
  }
`;

const ModalContainer = styled.div<{ isDesktop: boolean }>`
  top: 0;
  left: 50%;
  max-width: 676px;
  width: 90%;
  transform: translate(-50%, 0%);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalContent = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(47deg, #018ce9 0%, #01d274 100%), #fff;
  padding: 42px 50px;
  border-radius: 12px;

  ${({ theme }) => theme.media.mobile} {
    padding: 18px 21px;
  }
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: 0.8;
  cursor: pointer;
  pointer-events: none;
  div {
    pointer-events: auto;
  }
`;

const ModalHeader = styled.div<{ isDesktop: boolean }>`
  margin-bottom: ${({ isDesktop }) => (isDesktop ? "20px" : "10px")};
`;

const PageContainer = styled.div<{ isDesktop: boolean }>`
  top: 0px;
  left: 0px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  margin-top: 51px;
  pointer-events: none;
  div {
    pointer-events: auto;
  }
`;

const ScrollPill = styled.div<{
  height: number;
  offsetY: number;
}>`
  font-size: 12px;
  line-height: 18px;
  width: 7px;
  height: ${({ height }) => `${height}px`};
  border-radius: 10px;
  color: white;
  background-color: white;
  position: absolute;
  top: ${({ offsetY }) => offsetY + 103 + "px"};
  right: 25px;

  ${({ theme }) => theme.media.mobile} {
    right: 7px;
    top: ${({ offsetY }) => offsetY + 55 + "px"};
  }
`;

const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6129 5.2097L6.70711 5.29289L12 10.585L17.2929 5.29289L17.3871 5.2097C17.7794 4.90468 18.3466 4.93241 18.7071 5.29289C19.0676 5.65338 19.0953 6.22061 18.7903 6.6129L18.7071 6.70711L13.415 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3466 19.0676 17.7794 19.0953 17.3871 18.7903L17.2929 18.7071L12 13.415L6.70711 18.7071L6.6129 18.7903C6.22061 19.0953 5.65338 19.0676 5.29289 18.7071C4.93241 18.3466 4.90468 17.7794 5.2097 17.3871L5.29289 17.2929L10.585 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.65338 4.93241 6.22061 4.90468 6.6129 5.2097Z"
      fill="white"
    />
  </svg>
);
