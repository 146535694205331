import { Label, Li } from "melodies-source/Text";
import { colors } from "melodies-source/Theme";
import styled, { css } from "styled-components";
import { DataPoint, chartColors } from "./common";
import { useCustomAppContext } from "contexts/CustomAppContext";

type ListItemProps = {
  isSelected: boolean;
};

export const LegendListItem = styled.div<ListItemProps>(
  ({ theme: { colors }, isSelected }) => css`
    padding: 10px;
    border-bottom: solid 1px var(--border-color);
    height: 44px;
    display: flex;
    align-items: center;
    ${isSelected &&
    css`
      background: var(--hover-background-color);
    `}
  `,
);

const StyledLi = styled(Li)`
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
`;

const LegendWrapper = styled.div`
  overflow-y: auto;
`;

type Props = {
  data: DataPoint[];
  selectedItemLabel: string | undefined;
  withValue?: boolean;
  label?: string;
  setSelectedItemLabel: (label: string | undefined) => void;
};

export const Legend = ({
  data,
  selectedItemLabel,
  label,
  withValue,
  setSelectedItemLabel,
  ...props
}: Props) => {
  const { customApp } = useCustomAppContext();

  const handleMouseOver = (item: DataPoint) =>
    setSelectedItemLabel(item?.label);

  const handleMouseLeave = () => setSelectedItemLabel(undefined);

  return (
    <div>
      {label && (
        <div style={{ marginLeft: 2, marginBottom: 5 }}>
          <Label>{label}</Label>
        </div>
      )}
      <LegendWrapper {...props}>
        {data.map((item, index) => {
          const { label, value } = item;
          const total = data.reduce((sum, item) => (sum += item.value), 0);
          const percentage = (value / total) * 100 ?? 0;
          const colorPalette =
            customApp?.theme.charts.donut.colors || chartColors;
          const iconColor = colorPalette[index % colorPalette.length];
          const iconDimension = 16;

          const leftUI = (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: iconDimension,
                  height: iconDimension,
                  borderRadius: "50%",
                  background: iconColor,
                }}
              />
              <div style={{ width: `calc(100% - ${iconDimension}px)` }}>
                <StyledLi style={{ marginLeft: 8 }}>{label}</StyledLi>
              </div>
            </div>
          );

          const rightUI = (
            <div style={{ display: "flex" }}>
              {withValue && <Li>{value}</Li>}
              <Li style={{ marginLeft: 20, color: colors.black40 }}>
                {Math.round(percentage)}%
              </Li>
            </div>
          );

          return (
            <LegendListItem
              key={index}
              isSelected={label === selectedItemLabel}
              onMouseEnter={() => handleMouseOver(item)}
              onMouseLeave={handleMouseLeave}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {leftUI}
                {rightUI}
              </div>
            </LegendListItem>
          );
        })}
      </LegendWrapper>
    </div>
  );
};
