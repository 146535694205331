import { Card } from "melodies-source/Card";
import { Font20 } from "Routes/styled";
import styled from "styled-components";

export const BaseReportingCard = styled(Card).attrs(() => ({
  isElevated: true,
}))`
  background: white;
  border-radius: 20px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);

  min-width: 232px;

  ${({ theme }) => theme.media.desktop} {
    max-width: 432px;
  }

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

export const ReportingCardHeader = styled(Font20)`
  line-height: 22px;
  color: black;
  font-weight: 600;
  text-align: center;
`;

export const REPORTING_PADDING_DESKTOP = "24px 40px";
export const REPORTING_PADDING_TABLET = "";
export const REPORTING_PADDING_MOBILE = "0px 18px";
