import { Li, Subtitle2 } from "melodies-source/Text";
import { colors } from "melodies-source/Theme/colors";
import { NoData } from "Components/NoData";
import { textWithoutParentheses, varHexToRGB } from "Utils";
import useIsMounted from "hooks/useIsMounted";
import { AudienceAttribute } from "models/fanProfile";
import styled, { css } from "styled-components";

export interface Props {
  data: AudienceAttribute[];
}

const CATEGORY_CONSTANTS = {
  age: 0.75,
  ethnicity: 2,
  income: 0.75,
  other: 2,
};

interface ItemProps extends AudienceAttribute {
  position: number;
  selected?: boolean;
  scaledValue?: number;
}

export const DivergingChart: React.FC<Props> = ({ data }) => {
  const absoluteMap = data.map(({ index }) => Math.abs(index));
  const max = Math.max(...absoluteMap);
  const min = Math.min(...absoluteMap);

  const updatedItems = data?.map((item) => {
    const catKey = item.category?.toLowerCase();
    let categoryScale = CATEGORY_CONSTANTS[catKey] ?? 2;
    const range = [max - 1, Math.abs(min) - 1, categoryScale];
    const scale = Math.max(...range.map(Math.abs));
    const scaledValue = Number(
      ((((item.index - 1) / scale + 1) / 2) * 100).toFixed(2),
    );

    return { ...item, scaledValue };
  });

  return (
    <Wrapper>
      {updatedItems.length === 0 ? (
        <NoData />
      ) : (
        updatedItems?.map((item, index) => (
          <DivergingChartItem
            {...item}
            key={`divergent-chart-item-${index}`}
            position={index}
          />
        ))
      )}
    </Wrapper>
  );
};

export const Component = DivergingChart;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DivergingChartItem = ({
  title,
  index,
  position,
  selected,
  scaledValue,
}: ItemProps) => {
  const ranges = ["High", "Above Average", "Average", "Below Average", "Low"];
  const getRange = (range: number) => {
    if (range >= 1.75) return { label: ranges[0], color: colors.green };
    if (range >= 1.6) return { label: ranges[1], color: colors.green80 };
    if (range >= 1.35) return { label: ranges[1], color: colors.green60 };
    if (range >= 1.15) return { label: ranges[1], color: colors.green40 };
    if (range >= 1.07) return { label: ranges[1], color: colors.green20 };
    if (range >= 0.93) return { label: ranges[2], color: colors.green10 };
    if (range >= 0.85) return { label: ranges[3], color: colors.gray3 };
    if (range >= 0.8) return { label: ranges[3], color: colors.gray4 };
    if (range >= 0.7) return { label: ranges[3], color: colors.black20 };
    return { label: ranges[4], color: colors.black40 };
  };

  const { mounted } = useIsMounted();

  return (
    <Container>
      <ItemLabel selected={selected}>
        <Li>{textWithoutParentheses(title)}</Li>
        <Subtitle2>{getRange(index).label}</Subtitle2>
      </ItemLabel>
      <Segments>
        <Segment isFirst />
        <Segment isSecond />
        <Segment />
        <Segment isLast />
        <PillContainer>
          <Pill
            value={!mounted ? 0 : scaledValue}
            color={getRange(index).color}
            delay={position * 0.18}
            opacity={!mounted ? 0 : 1}
          />
        </PillContainer>
      </Segments>
    </Container>
  );
};

const ItemLabel = styled.div<{ selected?: boolean }>`
  border-right: 1px dashed ${varHexToRGB("--main-color", 0.4)};
  display: grid;
  grid-template-rows: auto auto;
  flex: 1 0 50%;
  padding: 7px 12px 20px 0;
  ${Li} {
    line-height: 20px;
    margin-bottom: 1px;
    ${(p) =>
      p.selected &&
      css`
        font-weight: 600;
        color: ${(p) => p.theme.colors.black70};
      `};
  }

  ${Subtitle2} {
    color: var(--main-color);
  }

  ${(p) => p.theme.mediaQueries.mobile} {
    ${Li} {
      font-size: 12px;
      line-height: 15px;
    }
    ${Subtitle2} {
      font-size: 9px;
      line-height: 16px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2px;
`;

const Segments = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  position: relative;
  flex: 1 1 55%;
`;

interface SegmentProps {
  isFirst?: boolean;
  isSecond?: boolean;
  isLast?: boolean;
}

const Segment = styled.div<SegmentProps>`
  display: flex;
  flex: 1 1 100%;
  border-right: 1px dashed ${(props) => props.theme.colors.gray3};
  ${(props) =>
    props.isSecond &&
    css`
      border-style: solid;
      border-color: ${varHexToRGB("--main-color", 0.4)};
      border-right-width: 2px;
      margin-bottom: -2px;
      height: calc(100% + 2px);
    `};
  ${(props) =>
    props.isLast &&
    css`
      border-right: 1px dashed ${varHexToRGB("--main-color", 0.4)};
    `};
`;

const PillContainer = styled.div`
  position: absolute;
  top: 7px;
  left: 0;
  right: 0;
  width: 100%;
  height: 20px;
  display: flex;
`;

const Pill = styled.div<{
  value: number;
  color: string;
  delay: number;
  opacity: number;
}>`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  width: 24px;
  height: 12px;
  position: absolute;
  opacity: ${({ opacity }) => opacity};
  left: calc(${(props) => props.value}% - 12px);

  ${(p) => p.theme.mediaQueries.desktop} {
    border-radius: 6px;
    width: 40px;
    height: 20px;
    left: calc(${(props) => props.value}% - 20px);
  }

  transition: all 0.5s ${({ delay }) => `${delay}s`};
`;
