import styled from "styled-components";

const GreenCheck = ({ className }: { className?: string }) => {
  return (
    <StyledSvg
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="20.5" cy="20.5" r="20.5" fill="white" />
      <path
        d="M28.6872 12.9262C29.2831 12.2918 30.2644 12.2762 30.8789 12.8914C31.4462 13.4592 31.5027 14.366 31.0393 15.0011L30.9127 15.1538L17.3832 29.5562L10.0712 21.5366C9.4845 20.8931 9.51422 19.8805 10.1376 19.2749C10.713 18.7158 11.5931 18.699 12.1866 19.2058L12.3286 19.3434L17.417 24.9232L28.6872 12.9262Z"
        fill="#02D275"
      />
    </StyledSvg>
  );
};

export default GreenCheck;

const StyledSvg = styled.svg`
  width: 41px;
  height: 41px;

  ${({ theme }) => theme.media.mobile} {
    width: 31px;
    height: 31px;
  }
`;
