import { H2, Subtitle1 } from "melodies-source/Text";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

interface Tab {
  param: string;
  label: string;
  content: React.ReactNode;
  icon?: React.ReactNode;
  hasHorizontalPadding?: boolean;
}

interface Props {
  tabs: Tab[];
  basePath: string;
}

export const Tabs: React.FC<Props> = ({ tabs, basePath }) => {
  const { tabParam } = useParams<{ tabParam: string }>();
  const history = useHistory();
  const selectedTab = tabs.find((tab) => tab.param === tabParam);
  const isMobile = useMobileMediaQuery();
  const handleTabClick = (param: string) =>
    history.replace(`${basePath}/${param}`);

  const TabLabel = isMobile ? Subtitle1 : H2;
  return (
    <>
      <TabContainer>
        {tabs.map((tab) => (
          <Tab
            key={tab.param}
            selected={tab.param === tabParam}
            onClick={() => handleTabClick(tab.param)}
          >
            <TabIcon>{tab.icon}</TabIcon>
            <TabLabel>{tab.label}</TabLabel>
          </Tab>
        ))}
      </TabContainer>
      <Content
        hasHorizontalPadding={
          selectedTab.hasHorizontalPadding !== undefined
            ? selectedTab.hasHorizontalPadding
            : true
        }
      >
        {selectedTab.content}
      </Content>
    </>
  );
};

interface ContentProps {
  hasHorizontalPadding: boolean;
}

const Content = styled.div<ContentProps>`
  padding: ${({ hasHorizontalPadding }) =>
    `20px${hasHorizontalPadding ? "" : " 0px"}`};
  background-color: var(--component-background-color);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 12px 12px;

  ${({ theme }) => theme.media.mobile} {
    padding: ${({ hasHorizontalPadding }) =>
      `20px${hasHorizontalPadding ? "15px" : " 0px"}`};
  }
`;

const Tab = styled.div<{ selected?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: var(--component-background-color);
  height: 80px;
  cursor: ${({ selected }) => (selected ? "default" : "pointer")};

  ${({ selected }) => !selected && "opacity: 0.6"};

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    justify-content: center;
    height: 60px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  gap: 2px;

  & > div:first-child {
    border-top-left-radius: 12px 12px;
  }

  & > div:last-child {
    border-top-right-radius: 12px 12px;
  }

  svg {
    margin-right: 5px;
    ${({ theme }) => theme.media.mobile} {
      margin-right: 0px;
      transform: scale(0.8);
    }
  }
`;

const TabIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: var(--secondary-color);
`;
