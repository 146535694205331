import { SurveyTemplate } from "../../types";
import { ReactComponent as IconFavoritePoster } from "assets/svg/favorite-poster.svg";
import { getContestDefaults } from "../helpers";

export const favTourPoster: SurveyTemplate<{ artist?: string }> = {
  icon: <IconFavoritePoster />,
  title: "Favorite Poster Design",
  description:
    "Invite fans to give feedback on the artwork for the tour poster.",
  templateId: "favTourPoster",
  template: ({ artist }, formState) => ({
    fields: {
      description: {
        content: {
          en: "Favorite Tour Poster Design",
        },
      },
      header: {
        content: {
          en: "Pick your Favorite Tour Poster Design",
        },
      },
      body: {
        content: {
          en: `With ${artist} hitting the road, it's your chance help decide which artwork should be used on the tour posters!`,
        },
      },
      numberOfCategories: {
        content: "2",
      },
      displayedCategories: {
        content: [
          "demographics",
          "fooddrink",
          "alcohol",
          "clothing",
          "vehicle",
          "entertainment",
        ],
      },
      thankYouHeader: {
        content: {
          en: "Thanks for Your Vote",
        },
      },
      thankYouBody: {
        content: {
          en: "We'll be announcing the official tour poster soon - thanks for helping us decide!",
        },
      },
      ...getContestDefaults(formState),
    },
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
    optIns: [],
    surveyQuestions: [
      {
        type: "ImageQuestion",
        actionRequired: true,
        showLabels: false,
        required: false,
        label: {
          en: "Which of these tour posters is your favorite?",
        },
      },
      {
        multiline: false,
        actionRequired: false,
        noBorder: false,
        required: true,
        label: {
          en: "Why did you vote for the poster you chose?",
        },
        type: "TextQuestion",
        variants: { mobile: "Headline4", desktop: "Headline3" },
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-tour---pos-32a6aa"
        : "pp-tour---pos-38927d",
    fields: [
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
