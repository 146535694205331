import styled, { css } from "styled-components";
import phoneFrameImg from "assets/images/phone-base.png";
import phoneStatusBarImgLight from "assets/images/phone-status-bar-light.png";
import phoneStatusBarImgDark from "assets/images/phone-status-bar-dark.png";
import { useEffect, useRef, useState } from "react";
import {
  SurveyBuilderConfig,
  TextModule,
  generateSurveyConfig,
  getButtonStyle,
  getCardStyle,
  getQuestions,
} from "@max/common/dist/setfan";
import { useBuilderContext } from "./BuilderContext";
import { Select } from "melodies-source/Select";
import { Button } from "melodies-source/Button";
import { FlexRow } from "Components/Flex";

export const Preview = ({ isThumbnail, requestView, ...props }: any) => {
  const { data } = useBuilderContext();
  const [init, setInit] = useState(false);
  const [page, setPage] = useState(1);
  const iframe = useRef<HTMLIFrameElement>(null);
  const [view, setView] = useState<"new" | "existing">("new");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (requestView === "profiling") {
      setPage(2);
    } else {
      if (requestView === "thanks") {
        setPage(options.length);
      } else {
        setPage(1);
      }
    }
  }, [requestView]);

  useEffect(() => {
    const pagecount = Math.min(
      Math.ceil(
        (data.fields.displayedCategories.content.length +
          (view === "new" ? 1 : 0)) /
          2,
      ),
      Math.ceil(parseInt(data.fields.numberOfCategories.content) / 2),
    );
    let options = [{ label: "Main Survey", value: 1 }];
    for (let start = 2; start < pagecount + 2; start++) {
      options.push({
        label: `Profiling Questions ${start > 2 ? start - 1 : ""}`,
        value: start,
      });
    }
    options.push({ label: "Thank You", value: options.length + 1 });
    setPage(Math.min(page, options.length));
    setOptions(options);
  }, [
    data.fields.displayedCategories.content,
    data.fields.numberOfCategories.content,
    view,
  ]);

  useEffect(() => {
    const handleMessage = (e: MessageEvent) =>
      setInit(e.source === iframe.current?.contentWindow);

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    let survey = generateSurveyConfig({ ...data, artistName: "test" });
    const defaultProfiling = makeDefaultProfiling(data);

    if (!options.length) {
      return;
    }
    if (page === 1) {
      //first page
      if (view === "new") {
        //@ts-ignore
        survey.pages[0].modules[
          survey.pages[0].modules.length - 1
        ].modules[0].modules.splice(
          survey.pages[0].modules[survey.pages[0].modules.length - 1].modules[0]
            .modules.length - 1,
          0,
          //@ts-ignore
          ...defaultProfiling[0][0].modules,
        );
      }
    }
    if (page === options[options.length - 1].value) {
      //last page
      survey.pages = survey.pages.slice(
        survey.pages.length - 1,
        survey.pages.length,
      );
    } else {
      if (page > 1) {
        let categories = [...data.fields.displayedCategories.content];
        if (view === "new") {
          //@ts-ignore
          categories.unshift("core");
        }
        survey.pages = survey.pages.slice(1, 2);
        //there's probably a better way to do this rather than iterating twice
        let module1, module2, preface1, preface2;
        defaultProfiling.find((c) =>
          c.find((i) => {
            let cat = categories[(page - 2) * 2 + 0];
            if (i.id === `profilecategory.${cat}`) {
              module1 = i.modules;
              preface1 = makeHeader(
                data.fields[cat + "IntroText"]?.content?.en,
              );
            }
          }),
        );
        if (
          (page - 2) * 2 + 1 <
          parseInt(data.fields.numberOfCategories.content)
        )
          defaultProfiling.find((c) =>
            c.find((i) => {
              let cat = categories[(page - 2) * 2 + 1];
              if (
                i.id === `profilecategory.${categories[(page - 2) * 2 + 1]}`
              ) {
                module2 = i.modules;
                preface2 = makeHeader(
                  data.fields[cat + "IntroText"]?.content?.en,
                );
              }
            }),
          );

        survey.pages[0].modules = [
          survey.pages[0].modules[0],
          //@ts-ignore
          wrapCard(
            [
              ...(preface1 ? preface1 : []),
              ...(module1 ? module1 : []),
              ...(preface2 ? preface2 : []),
              ...(module2 ? module2 : []),
              getButton(data),
            ],
            data,
          ),
        ];
      }
    }

    //@ts-ignore
    survey.pages[0].inert = "inert";

    iframe.current?.contentWindow?.postMessage({
      survey: {
        ...survey,
        profiling: [],
        localProfiling: [],
        config: {
          ...survey.config,
          globalStyles:
            (survey.config.globalStyles += `html::-webkit-scrollbar {
        display: none;
      }`),
        },
      },
    });
  }, [data, init, page, view, options]);

  return (
    <>
      <Container {...props}>
        <PhoneStatusBarContainer style={{ backgroundColor: "#ccc" }}>
          <PhoneStatusBar src={phoneStatusBarImgDark} />
        </PhoneStatusBarContainer>
        <TemplateContainer>
          <ScrollContainer isThumbnail={isThumbnail}>
            <Prev
              ref={iframe}
              src={`${process.env.PUBLIC_URL}/setfanembed`}
              title="preview"
            />
          </ScrollContainer>
        </TemplateContainer>
        <PhoneFrame src={phoneFrameImg} />
        {!isThumbnail && <DropShadow />}
      </Container>
      {!isThumbnail && (
        <>
          <Select
            label="Preview Screen"
            //@ts-ignore
            onChange={setPage}
            options={options}
            //@ts-ignore
            value={page}
            style={{ marginTop: 16 }}
          />
          <FlexRow style={{ marginTop: 16 }}>
            <ToggleLeft onClick={() => setView("new")} active={view === "new"}>
              New Contact View
            </ToggleLeft>
            <ToggleRight
              onClick={() => setView("existing")}
              active={view === "existing"}
            >
              Existing Contact View
            </ToggleRight>
          </FlexRow>
        </>
      )}
    </>
  );
};

const makeHeader = (text) =>
  text?.length > 0
    ? new TextModule({
        label: { en: text },
        tag: "title",
        variants:
          text.length < 25
            ? { mobile: "Headline3", desktop: "Headline2" }
            : { mobile: "Headline4", desktop: "Headline3" },
        style: { marginBottom: 15 },
      }).toSurveyConfig()
    : null;

const wrapCard = (modules, config: SurveyBuilderConfig) => ({
  id: "fakeid",
  type: "container",
  noBorder: false,
  style: { maxWidth: 660, width: "100%" },
  modules: [
    {
      compact: false,
      id: "fakeid",
      modules: getQuestions(modules, config),
      variant: "body",
      type: "card",
      style: getCardStyle(config),
    },
  ],
});

const getButton = (config: SurveyBuilderConfig) => ({
  id: "fakeid",
  style: getButtonStyle(config),
  complete: false,
  type: "submit",
  children: "Continue",
});

const ToggleLeft = styled(Button)<{ active?: boolean }>`
  background-color: #ffffff;
  border: 1px solid #999999;
  border-radius: 18px 0 0 18px;
  color: #1b0076;
  padding: 6px 0;
  width: 50%;
  height: auto;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  &:hover {
    background-color: #e8e5f1;
    box-shadow: none;
  }

  ${(p) =>
    p.active &&
    css`
      font-weight: 600;
      background-color: #e8e5f1;
    `};
`;

const ToggleRight = styled(ToggleLeft)`
  border-radius: 0 18px 18px 0;
  border-left: none;
`;

const Prev = styled.iframe`
  height: 100%;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  aspect-ratio: 396/800;
  height: 100%;
  min-width: 321px;
`;

const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 5.56% 5% 5.56%;
  position: relative;
  z-index: 2;
  border-bottom-left-radius: 11.65% 5.78%;
  border-bottom-right-radius: 11.65% 5.78%;
  overflow: hidden;
`;

const ScrollContainer = styled.div<{ isThumbnail?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ${(p) => p.isThumbnail && `overflow: hidden;`};
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const PhoneFrame = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const PhoneStatusBarContainer = styled.div`
  background-color: #ffffff;
  border-top-left-radius: 11.65% 73.21%;
  border-top-right-radius: 11.65% 73.21%;
  position: relative;
  margin: 4% 5.56% 0 5.56%;
  height: 7%;
  z-index: 3;
`;

const PhoneStatusBar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const DropShadow = styled.div`
  border-radius: 10.46% 10.46% 10.46% 10.46% / 5.13% 5.13% 5.13% 5.13%;
  box-shadow: 0px 0px 150px rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 8px;
  right: 12px;
  bottom: 8px;
  left: 12px;
  z-index: 0;
`;

const getCoreModules = (config: SurveyBuilderConfig): any => {
  if (config.fields.phoneNumberValidation.content === "optional") {
    return [
      {
        type: "NameQuestion",
        id: "name",
        header: { children: "Please enter your name" },
        validation: {
          type: "name",
          message: "Please enter your name",
        },
      },
      {
        type: "CountryQuestion",
        id: "countryCode",
        header: { children: "Your Country" },
        validation: {
          type: "required",
          message: "Please enter your country",
        },
      },
      {
        type: "PhoneQuestion",
        id: "phone",
        variant: "no-country",
        header: { children: "Your Phone (optional)" },
      },
      {
        type: "ZipQuestion",
        id: "zip",
        validation: {
          type: "zip",
          message: "Please enter your zip code",
        },
      },
    ];
  } else if (config.fields.phoneNumberValidation.content === "no-phone") {
    return [
      {
        type: "NameQuestion",
        id: "name",
        header: { children: "Please enter your name" },
        validation: {
          type: "name",
          message: "Please enter your name",
        },
      },
      {
        type: "CountryQuestion",
        id: "countryCode",
        header: { children: "Your Country" },
        validation: {
          type: "required",
          message: "Please enter your country",
        },
      },
      {
        type: "ZipQuestion",
        id: "zip",
        validation: {
          type: "zip",
          message: "Please enter your zip code",
        },
      },
    ];
  } else {
    return [
      {
        type: "NameQuestion",
        id: "name",
        header: { children: "Please enter your name" },
        validation: {
          type: "name",
          message: "Please enter your name",
        },
      },
      {
        type: "PhoneQuestion",
        id: "phone",
        header: { children: "Your Phone" },
        validation: {
          type: "required",
          message: "Please enter your phone",
        },
      },
      {
        type: "ZipQuestion",
        id: "zip",
        validation: {
          type: "zip",
          message: "Please enter your zip code",
        },
      },
    ];
  }
};

const makeDefaultProfiling = (config: SurveyBuilderConfig) => [
  [
    {
      id: "profilecategory.email",
      modules: [
        {
          type: "EmailQuestion",
          id: "email",
          header: { children: "Enter your Email" },
          validation: {
            type: "email",
            message: "Please enter your email",
          },
        },
      ],
    },
  ],
  [
    {
      id: "profilecategory.core",
      modules: getCoreModules(config),
    },
  ],
  [
    {
      id: "profilecategory.demographics",
      modules: [
        {
          type: "YearBorn",
          id: "profiling.yearborn",
          header: {
            children: "What year were you born?",
          },
          validation: {
            type: "yearborn",
            message: "Enter year as YYYY format (e.g. 1987)",
          },
        },
        {
          type: "SingleSelectQuestion",
          id: "profiling.gender",
          header: {
            children: "Are you?",
          },
          options: [
            { id: "female", label: "Female" },
            { id: "male", label: "Male" },
            { id: "nonbinary", label: "Non-binary" },
            { id: "notsay", label: "Prefer not to say" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.musicsource",
          header: {
            children: "How do you most often listen to music (Choose up to 3)?",
          },
          options: [
            { id: "radio", label: "Radio" },
            { id: "vinyl", label: "Vinyl/Records" },
            { id: "tapes", label: "Tapes" },
            { id: "cd", label: "CDs" },
            { id: "amazon", label: "Amazon Music Unlimited" },
            { id: "apple", label: "Apple Music" },
            { id: "pandorafree", label: "Pandora", description: "(free)" },
            {
              id: "pandorapaid",
              label: "Pandora",
              description: "(paid subscription)",
            },
            { id: "spotifyfree", label: "Spotify", description: "(free)" },
            {
              id: "spotifypaid",
              label: "Spotify",
              description: "(paid subscription)",
            },
            { id: "tidal", label: "Tidal" },
            {
              id: "yt",
              label: "YouTube",
              description: "(the main website/app)",
            },
            {
              id: "ytm",
              label: "YouTube",
              description: "(music streaming service)",
            },
            { id: "none", label: "None of these" },
          ],
          validation: {
            type: "require_count",
            max: 3,
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.socialusage",
          header: {
            children: "Which of these do you use daily?",
          },
          options: [
            { id: "bereal", label: "BeReal" },
            { id: "discord", label: "Discord" },
            { id: "facebook", label: "Facebook" },
            { id: "instagram", label: "Instagram" },
            { id: "snapchat", label: "Snapchat" },
            { id: "tiktok", label: "TikTok" },
            { id: "twitch", label: "Twitch" },
            { id: "twitter", label: "Twitter" },
            { id: "none", label: "None of these" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
      ],
    },
  ],
  [
    {
      id: "profilecategory.fooddrink",
      modules: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.eaten",
          header: {
            children: "Which of the following have you eaten in the last week?",
          },
          options: [
            {
              id: "casual",
              label:
                "A meal from a casual sit-down restaurant (dine-in or takeout)",
            },
            {
              id: "fine",
              label:
                "A meal from a fine dining restaurant (dine-in or takeout)",
            },
            {
              id: "delivery",
              label: "Restaurant food delivered via a service like UberEats",
            },
            { id: "mealkit", label: "A meal kit/service I bought" },
            {
              id: "fastfood",
              label:
                "A meal from a fast food restaurant (dine-in, drive-through or takeout",
            },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.groceries",
          header: {
            children:
              "When you’re getting groceries which are important to you?",
          },
          options: [
            {
              id: "craving",
              label: "What I’m craving",
            },
            {
              id: "healthy",
              label: "How healthy it is",
            },
            {
              id: "price",
              label: "What's on sale/affordable",
            },
            { id: "local", label: "What is local or organic" },
            { id: "deliver", label: "What can be delivered" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.drinks",
          header: {
            children: "Which of these do you drink on a regular basis?",
          },
          options: [
            {
              id: "dietsoft",
              label: "Diet soft drinks",
            },
            {
              id: "water",
              label: "Bottled/flavored water",
            },
            {
              id: "tea",
              label: "Tea",
            },
            { id: "juice", label: "Fruit or vegetable juice" },
            { id: "sports", label: "Sports drinks" },
            { id: "soft", label: "Soft drinks (non-diet)" },
            { id: "coffee", label: "Coffee/coffee drinks" },
            { id: "energy", label: "Energy drinks" },
            { id: "none", label: "None of these" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "TextQuestion",
          id: "profiling.foodpartner",
          header: {
            children:
              "What food/drink brand or restaurant do you think would be a good partner for an upcoming tour?",
          },
          validation: {
            type: "required",
            message: "Please tell us a partner brand",
          },
        },
      ],
    },
    {
      id: "profilecategory.alcohol",
      tags: ["alcohol"],
      modules: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.alcoholchoice",
          header: {
            children:
              "When you’re drinking, which of these do you tend to drink (check all that apply)?",
          },
          options: [
            {
              id: "beer",
              label: "Regular (not light, not craft/import) beer ",
            },
            {
              id: "lightbeer",
              label: "Light beer",
            },
            {
              id: "importedbeer",
              label: "Imported or Craft Beer",
            },
            { id: "wine", label: "Wine" },
            { id: "vodka", label: "Vodka (or Vodka Mixed Drinks)" },
            {
              id: "whiskey",
              label: "Whiskey/Bourbon (or Whiskey/Bourbon Mixed Drinks)",
            },
            { id: "rum", label: "Rum (or Rum Mixed Drinks)" },
            { id: "gin", label: "Gin (or Gin Mixed Drinks)" },
            { id: "tequila", label: "Tequila (or Tequila Mixed Drinks)" },
            { id: "premiumliquor", label: "A premium liquor" },
            { id: "cheapliquor", label: "Budget/cheap liquor" },
            { id: "cider", label: "Hard Cider or Lemonade" },
            { id: "seltzer", label: "Hard Seltzer" },
            { id: "none", label: "I don’t drink alcohol" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          id: "profiling.alcoholpartner",
          type: "TextQuestion",
          header: {
            children:
              "What beer, wine or alcohol brands could you see {artist} partnering with?",
          },
          validation: {
            type: "required",
            message: "Please tell us a partner brand",
          },
        },
      ],
    },
    {
      id: "profilecategory.clothing",
      modules: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.buyclothing",
          header: {
            children:
              "Which of these do you regularly buy clothes/accessories from?",
          },
          options: [
            {
              id: "discount",
              label: "Discount clothing stores",
              description: "(like Ross or Marshalls)",
            },
            {
              id: "mainstream",
              label: "Mainstream mall clothing stores",
              description: "(like Nordstrom, Old Navy, Forever 21, Macy’s)",
            },
            {
              id: "highend",
              label: "High end fashion stores",
              description: "(like Dolce & Gabbana or Coach)",
            },
            {
              id: "onlinemainstream",
              label: "The online versions of mainstream mall stores",
              description: "(like oldnavy.com or macys.com)",
            },
            { id: "walmart", label: "A store like WalMart or Target" },
            {
              id: "online",
              label: "Online-only stores",
              description: "(like Amazon or Zappos)",
            },
            {
              id: "subscription",
              label: "Online subscription services",
              description: "(Stitch Fix, Trunk Club, Foot Cardigan)",
            },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "TextQuestion",
          id: "profiling.artistclothing",
          header: {
            children:
              "What clothing, accessories or cosmetics brands would be a good fit for {artist}? This could be any brands you love or ones you associate with {artist}",
          },
          validation: {
            type: "required",
            message: "Please enter a response",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.beauty",
          header: {
            children:
              "Which of these are true for you in deciding on personal care and beauty products (like cosmetics, fragrances/cologne, teeth whitening, hair styling, shaving)?",
          },
          options: [
            {
              id: "economical",
              label: "I usually look for the most economical option",
            },
            {
              id: "premium",
              label: "I prefer to buy products from premium brands",
            },
            {
              id: "ethics",
              label:
                "The company’s values are an important factor in my decision",
            },
            {
              id: "natural",
              label:
                "I prefer cosmetics/personal care products that are natural or organic",
            },
            {
              id: "subscription",
              label:
                "I use a subscription service (like Dollar Shave Club or Birchbox)",
            },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
      ],
    },
    {
      id: "profilecategory.vehicle",
      modules: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.nextvehicle",
          header: {
            children: "Which types of vehicle would you consider getting next?",
          },
          options: [
            { id: "electric", label: "Electric" },
            { id: "hybrid", label: "Hybrid" },
            { id: "suv", label: "SUV" },
            { id: "pickup", label: "Pickup Truck" },
            { id: "car", label: "Small Car" },
            { id: "hatchback", label: "Hatchback" },
            { id: "motorcycle", label: "Motorcycle" },
            { id: "none", label: "I don’t drive" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.nextvehiclecost",
          header: {
            children:
              "Which of these are likely to describe your next vehicle?",
          },
          options: [
            { id: "budget", label: "Budget/inexpensive" },
            { id: "highend", label: "Full-Featured/High-End Trim" },
            { id: "used", label: "Used" },
            { id: "new", label: "New" },
            { id: "american", label: "American" },
            { id: "Import", label: "Import" },
            { id: "none", label: "I don’t drive" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          id: "profiling.vehiclepartner",
          type: "Autocomplete",
          options: [
            {
              id: "Acura",
              label: "Acura",
            },
            {
              id: "Alfa",
              label: "Alfa",
            },
            {
              id: "Aston Martin",
              label: "Aston Martin",
            },
            {
              id: "Audi",
              label: "Audi",
            },
            {
              id: "Bentley",
              label: "Bentley",
            },
            {
              id: "BMW",
              label: "BMW",
            },
            {
              id: "Buick",
              label: "Buick",
            },
            {
              id: "Buik",
              label: "Buik",
            },
            {
              id: "Cadillac",
              label: "Cadillac",
            },
            {
              id: "Chevy/Chevrolet",
              label: "Chevy/Chevrolet",
            },
            {
              id: "Chrysler",
              label: "Chrysler",
            },
            {
              id: "Dodge",
              label: "Dodge",
            },
            {
              id: "Ferrari",
              label: "Ferrari",
            },
            {
              id: "FIAT",
              label: "FIAT",
            },
            {
              id: "Ford",
              label: "Ford",
            },
            {
              id: "Genesis",
              label: "Genesis",
            },
            {
              id: "GMC",
              label: "GMC",
            },
            {
              id: "Honda",
              label: "Honda",
            },
            {
              id: "HUMMER",
              label: "HUMMER",
            },
            {
              id: "Hyundai",
              label: "Hyundai",
            },
            {
              id: "INFINITI",
              label: "INFINITI",
            },
            {
              id: "Isuzu",
              label: "Isuzu",
            },
            {
              id: "Jaguar",
              label: "Jaguar",
            },
            {
              id: "Jeep",
              label: "Jeep",
            },
            {
              id: "Kia",
              label: "Kia",
            },
            {
              id: "Lamborghini",
              label: "Lamborghini",
            },
            {
              id: "Land Rover",
              label: "Land Rover",
            },
            {
              id: "Lexus",
              label: "Lexus",
            },
            {
              id: "Lincoln",
              label: "Lincoln",
            },
            {
              id: "Maserati",
              label: "Maserati",
            },
            {
              id: "MAZDA",
              label: "MAZDA",
            },
            {
              id: "Mercedes-Benz",
              label: "Mercedes-Benz",
            },
            {
              id: "Mercury",
              label: "Mercury",
            },
            {
              id: "MINI",
              label: "MINI",
            },
            {
              id: "Mitsubishi",
              label: "Mitsubishi",
            },
            {
              id: "NISSAN",
              label: "NISSAN",
            },
            {
              id: "Porsche",
              label: "Porsche",
            },
            {
              id: "RAM",
              label: "RAM",
            },
            {
              id: "Rolls-Royce",
              label: "Rolls-Royce",
            },
            {
              id: "Saab",
              label: "Saab",
            },
            {
              id: "Saturn",
              label: "Saturn",
            },
            {
              id: "Scion",
              label: "Scion",
            },
            {
              id: "smart",
              label: "smart",
            },
            {
              id: "Subaru",
              label: "Subaru",
            },
            {
              id: "Suzuki",
              label: "Suzuki",
            },
            {
              id: "Tesla",
              label: "Tesla",
            },
            {
              id: "Toyota",
              label: "Toyota",
            },
            {
              id: "Volkswagen",
              label: "Volkswagen",
            },
            {
              id: "Volvo",
              label: "Volvo",
            },
          ],
          header: {
            children: "What car brand would make a good tour partner?",
          },
          validation: {
            type: "required",
            message: "Please tell us a partner brand",
          },
        },
      ],
    },
    {
      id: "profilecategory.entertainment",
      modules: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.sparetime",
          header: {
            children:
              "Which of these are things you like to do in your spare time?",
          },
          options: [
            {
              id: "teamsports",
              label: "Playing team sports (basketball/soccer/softball, etc.)",
            },
            { id: "bar", label: "Going to a bar" },
            { id: "socialmedia", label: "Checking social media" },
            { id: "golf", label: "Golf" },
            { id: "music", label: "Listening to music" },
            {
              id: "makingmusic",
              label:
                "Playing or creating music (guitar, piano, sequencing, etc.)",
            },
            { id: "gaming", label: "Gaming (console or computer)" },
            { id: "camping", label: "Camping or Fishing" },
            { id: "crafts", label: "Crafts/Sewing/crocheting" },
            { id: "astrology", label: "Astrology" },
            { id: "volunteer", label: "Volunteer/charity work" },
            {
              id: "createsocialmedia",
              label: "Creating social media content",
            },
            { id: "reading", label: "Reading" },
            { id: "tuning", label: "Work on my car/car tuning" },
            { id: "offroad", label: "Off-roading/overlanding" },
            { id: "traveling", label: "Traveling" },
            { id: "home", label: "Home improvement" },
            { id: "yoga", label: "Yoga" },
            { id: "cooking", label: "Cooking/baking" },
            { id: "church", label: "Church/church activities" },
            { id: "biking", label: "Biking" },
            { id: "concert", label: "Going to a concert" },
            {
              id: "tv",
              label: "Watching TV/movies/streaming content at home",
            },
            { id: "movies", label: "Going to the movies" },
            { id: "cardio", label: "Running/doing cardio workouts" },
            { id: "weights", label: "Lifting weights" },
            { id: "podcast", label: "Listen to podcasts" },
            { id: "hiking", label: "Hiking" },
            { id: "watchsports", label: "Watch sports" },
            { id: "photography", label: "Photography" },
            { id: "none", label: "None of the above" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "TextQuestion",
          id: "profiling.hobbybrands",
          header: {
            children:
              "What brands related to your hobbies do you think would be a good sponsor for {artist}? Please share any favorite stores or brands that you think would be a fit!",
          },
          validation: {
            type: "required",
            message: "Please provide a response",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.watchproviders",
          header: {
            children:
              "You sit down to watch something, which are you likely to be watching?",
          },
          options: [
            {
              id: "tv",
              label: "Traditional TV/cable",
              description: "(like ABC,ESPN,Discovery)",
            },
            {
              id: "streaming",
              label: "Streaming Services",
              description: "(like Netflix/Amazon Prime/Disney+)",
            },
            { id: "yt", label: "YouTube" },
            {
              id: "shortform",
              label: "Short Form Video",
              description: "(TikTok or Instagram Reels)",
            },
            {
              id: "livestream",
              label: "Twitch or live video platform",
              description: "(IG Live/Facebook Live)",
            },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.watchtech",
          header: {
            children: "Which of the following do you have?",
          },
          options: [
            {
              id: "peloton",
              label: "A piece of “smart” gym equipment",
              description: "(like Peloton, Lululemon MIRROR)",
            },
            {
              id: "crypto",
              label: "A crypto wallet",
              description: "(like CoinBase, MetaMask)",
            },
            { id: "vr", label: "A VR headset" },
            {
              id: "smartwatch",
              label: "“Wearable” Tech",
              description: "(like a smartwatch or fitness tracker)",
            },
            {
              id: "smarthome",
              label: "“Smart Home” system",
              description: "(such as connected light bulbs, sensors)",
            },
            {
              id: "smartspeaker",
              label: "Smart Speaker",
              description: "(like an Echo Dot or Google Home)",
            },
            {
              id: "console",
              label: "Newest generation console",
              description: "(Xbox Series X/Nintendo Switch/PS5)",
            },
            { id: "none", label: "None of these" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
      ],
    },
  ],
];
