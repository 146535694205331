import { Concert } from "@max/common";
import { PartialWithFieldValue, setDoc, Timestamp } from "firebase/firestore";
import { ConcertData } from "hooks/useUpcomingConcerts";
import { useState } from "react";
import { isSameISODate } from "Utils/date";
import { getEvent } from "../utils";
import { publishBuilderEvent } from "./publishBuilderEvent";
import { updateBuilderEventFields } from "./updateBuilderEventFields";
import { BuilderEventData } from "./useSetLiveBuilderEvents";

interface Options {
  concerts?: ConcertData[];
  events?: BuilderEventData[];
  onCollision?: (data: ConcertData) => void;
  onSuccess?: () => void;
}

interface ExecuteOptions {
  concert: ConcertData;
  patch: PartialWithFieldValue<Concert>;
  withCollision?: boolean;
}

export const useEditConcert = ({
  concerts = [],
  events = [],
  onCollision,
  onSuccess,
}: Options) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const execute = async ({
    concert,
    patch,
    withCollision = true,
  }: ExecuteOptions) => {
    if (withCollision && patch.date) {
      const hasCollision = concerts.some(({ date }) =>
        isSameISODate(date, patch.date),
      );

      if (hasCollision) {
        return onCollision?.({
          ...concert,
          date: patch.date as Timestamp,
        });
      }
    }

    try {
      setLoading(true);

      await setDoc(concert.ref, patch, { merge: true });

      const event = getEvent(concert, events);

      if (event) {
        await updateBuilderEventFields(concert.ref, event);

        if (event.publishedId) {
          await publishBuilderEvent(event, "published");
        }
      }

      onSuccess?.();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return { execute, loading, error } as const;
};
