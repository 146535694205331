import React, { useEffect, useState } from "react";
import { Links } from "../Components/Links";
import { useArtistContext } from "Components";

export const KeyLinks: React.FC = () => {
  const { artistGroupDoc } = useArtistContext();
  const artistGroupData = artistGroupDoc?.data();
  const [website, setWebsite] = useState(artistGroupData?.website || "");
  const [shopUrl, setShopUrl] = useState(artistGroupData?.shopUrl || "");
  const [privacyPolicy, setPrivacyPolicy] = useState(
    artistGroupData?.privacyPolicyUrl || "",
  );

  useEffect(() => {
    if (artistGroupData) {
      setWebsite(artistGroupData.website || "");
      setShopUrl(artistGroupData.shopUrl || "");
      setPrivacyPolicy(artistGroupData.privacyPolicyUrl || "");
    }
  }, [
    artistGroupData?.website,
    artistGroupData?.shopUrl,
    artistGroupData?.privacyPolicyUrl,
  ]);

  return (
    <Links
      title="Key Artist Links"
      links={[
        {
          label: "Website/Landing Page",
          key: "website",
          url: website,
          setUrl: setWebsite,
        },
        {
          label: "Merch Store",
          key: "shopUrl",
          url: shopUrl,
          setUrl: setShopUrl,
        },
        {
          label: "Privacy Policy",
          key: "privacyPolicyUrl",
          url: privacyPolicy,
          setUrl: setPrivacyPolicy,
        },
      ]}
    />
  );
};
