import { FlexColumn, FlexRow } from "Routes/styled";
import { Body1, Caption } from "melodies-source/Text";
import styled from "styled-components";

interface RatingScaleProps {
  scale?: number;
  labels: [string, string];
}

const ratingColors = {
  10: [
    "#1B007633",
    "#D1CCE4",
    "#A499C8",
    "#A499C8",
    "#7666AD",
    "#7666AD",
    "#493391",
    "#493391",
    "#1B0076",
    "#1B0076",
  ],
  5: ["#1B007633", "#1B007666", "#1B007699", "#1B0076CC", "#1B0076"],
};

export const RatingScale = ({ labels, scale = 5 }: RatingScaleProps) => {
  const [minLabel, maxLabel] = labels;
  const values = Array.from({ length: scale }, (_, i) => i + 1);
  const colors = scale in ratingColors ? ratingColors[scale] : ratingColors[10];
  return (
    <Wrapper>
      <Container>
        {values.map((n, index) => (
          <RatingValue
            key={`${n}-${index}`}
            style={{ backgroundColor: index in colors && colors[index] }}
          >
            <Body1>{n}</Body1>
          </RatingValue>
        ))}
      </Container>
      <FlexRow
        style={{ justifyContent: "space-between", gap: 15, width: "100%" }}
      >
        <Caption>"{minLabel}"</Caption>
        <Caption>"{maxLabel}"</Caption>
      </FlexRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-self: flex-start;
  margin-top: 12px;

  ${Caption} {
    font-size: 10px;
    color: #333;
  }
`;

const Container = styled(FlexRow)`
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
  margin-bottom: 6px;
  justify-content: space-between;
`;

const RatingValue = styled(FlexRow)`
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 22px;
  color: #fff;
`;
