import { LayoutProps } from "./types";

export const DefaultLayout = ({
  image,
  backgroundColor,
  buttonColor,
  width,
  height,
  onClick,
}: LayoutProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width ?? 163}
    height={height ?? 352}
    viewBox="0 0 163 352"
    fill="none"
    onClick={onClick}
  >
    <g clipPath="url(#default-layout_svg__a)">
      <rect
        width={162.666}
        height={351.608}
        fill={backgroundColor}
        rx={8.342}
      />
      <path
        fill="#FAFBFB"
        stroke="#CDCECE"
        strokeWidth={0.834}
        d="M8.759 35.87a7.925 7.925 0 0 1 7.925-7.925h129.298a7.925 7.925 0 0 1 7.925 7.925v75.911a7.925 7.925 0 0 1-7.925 7.924H16.684a7.925 7.925 0 0 1-7.925-7.924z"
      />
      <rect
        width={41.292}
        height={41.709}
        x={21.689}
        y={8.76}
        fill="url(#default-layout_svg__b)"
        rx={20.646}
      />
      <rect width={55} height={7} x={21} y={59.001} fill="#D9D9D9" rx={1.5} />
      <rect width={121} height={5} x={21} y={80.001} fill="#D9D9D9" rx={2.5} />
      <rect width={108} height={5} x={21} y={87.001} fill="#D9D9D9" rx={2.5} />
      <rect width={55} height={5} x={21} y={100} fill="#D9D9D9" rx={2.5} />
      <rect width={121} height={5} x={21} y={73.001} fill="#D9D9D9" rx={2.5} />
      <path
        fill="#FAFBFB"
        stroke="#CDCECE"
        strokeWidth={0.834}
        d="M8.759 138.057a7.925 7.925 0 0 1 7.925-7.925h128.464a7.925 7.925 0 0 1 7.924 7.925v197.701a7.925 7.925 0 0 1-7.924 7.925H16.684a7.925 7.925 0 0 1-7.925-7.925z"
      />
      <g filter="url(#default-layout_svg__c)">
        <rect
          width={119.288}
          height={18.352}
          x={21.271}
          y={310}
          fill={buttonColor}
          rx={2.503}
        />
      </g>
      <rect width={67} height={6} x={21} y={143} fill="#D9D9D9" rx={1.5} />
      <rect width={67} height={6} x={21} y={183} fill="#D9D9D9" rx={1.5} />
      <rect width={30} height={6} x={66} y={316} fill="#fff" rx={3} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M25.234 203.31c1.751 0 3.17-1.494 3.17-3.337 0-1.842-1.419-3.336-3.17-3.336s-3.17 1.494-3.17 3.336c0 1.843 1.42 3.337 3.17 3.337m0-.417c1.532 0 2.774-1.307 2.774-2.92s-1.242-2.919-2.774-2.919-2.773 1.307-2.773 2.919 1.242 2.92 2.773 2.92M25.33 222.08c1.793 0 3.247-1.494 3.247-3.337s-1.454-3.337-3.247-3.337-3.247 1.494-3.247 3.337 1.454 3.337 3.247 3.337m0-.417c1.57 0 2.841-1.308 2.841-2.92s-1.272-2.92-2.84-2.92c-1.57 0-2.842 1.308-2.842 2.92s1.272 2.92 2.841 2.92"
        clipRule="evenodd"
      />
      <ellipse cx={25.33} cy={218.743} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M25.33 240.848c1.793 0 3.247-1.494 3.247-3.336 0-1.843-1.454-3.337-3.247-3.337s-3.247 1.494-3.247 3.337c0 1.842 1.454 3.336 3.247 3.336m0-.417c1.568 0 2.84-1.307 2.84-2.919s-1.272-2.92-2.84-2.92c-1.57 0-2.842 1.307-2.842 2.92s1.272 2.919 2.841 2.919"
        clipRule="evenodd"
      />
      <ellipse cx={25.33} cy={237.512} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M25.33 259.617c1.793 0 3.247-1.494 3.247-3.337s-1.454-3.337-3.247-3.337-3.247 1.494-3.247 3.337 1.454 3.337 3.247 3.337m0-.417c1.57 0 2.841-1.307 2.841-2.92s-1.272-2.92-2.84-2.92c-1.57 0-2.842 1.308-2.842 2.92 0 1.613 1.272 2.92 2.841 2.92"
        clipRule="evenodd"
      />
      <ellipse cx={25.33} cy={256.28} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M25.33 278.384c1.793 0 3.247-1.494 3.247-3.336 0-1.843-1.454-3.337-3.247-3.337s-3.247 1.494-3.247 3.337c0 1.842 1.454 3.336 3.247 3.336m0-.417c1.57 0 2.841-1.307 2.841-2.919s-1.272-2.92-2.84-2.92c-1.57 0-2.842 1.307-2.842 2.92s1.272 2.919 2.841 2.919"
        clipRule="evenodd"
      />
      <ellipse cx={25.33} cy={275.048} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M25.33 297.507c1.793 0 3.247-1.493 3.247-3.336s-1.454-3.337-3.247-3.337-3.247 1.494-3.247 3.337 1.454 3.336 3.247 3.336m0-.417c1.57 0 2.841-1.307 2.841-2.919s-1.272-2.92-2.84-2.92c-1.57 0-2.842 1.307-2.842 2.92s1.272 2.919 2.841 2.919"
        clipRule="evenodd"
      />
      <ellipse cx={25.33} cy={294.171} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <rect
        width={118.871}
        height={17.935}
        x={21.48}
        y={155.377}
        stroke="#CCC"
        strokeWidth={0.417}
        rx={2.294}
      />
      <rect width={27} height={5} x={27} y={162} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={33} y={197} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={33} y={216} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={33} y={235} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={33} y={254} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={33} y={272} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={33} y={292} fill="#D9D9D9" rx={2.5} />
    </g>
    <defs>
      <clipPath id="default-layout_svg__a">
        <rect width={162.666} height={351.608} fill="#fff" rx={8.342} />
      </clipPath>
      <pattern
        id="default-layout_svg__b"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use
          xlinkHref="#default-layout_svg__d"
          transform="matrix(.00377 0 0 .00373 -.239 0)"
        />
      </pattern>
      <filter
        id="default-layout_svg__c"
        width={125.962}
        height={25.026}
        x={17.935}
        y={306.663}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1.668} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2349_173353"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2349_173353"
          result="shape"
        />
      </filter>
      <image href={image} id="default-layout_svg__d" width={392} height={268} />
    </defs>
  </svg>
);
