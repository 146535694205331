import { useEffect, useState } from "react";
import {
  useTypesenseProxyContext,
  ProxyCollection,
  ProxySearchParams,
} from "contexts/TypesenseProxyContext";

export const useTypesenseDocuments = ({
  documentIds,
  collection,
  params,
}: {
  documentIds: string[];
  collection: ProxyCollection;
  params?: Partial<ProxySearchParams>;
}) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const { proxySearch } = useTypesenseProxyContext();

  useEffect(() => {
    // can end up with a race condition here if this hook is run with an empty list of ids and
    // a populated one. To avoid, check the length explicitly to ensure we have doc ids to query.
    if (proxySearch && collection && documentIds.length) {
      (async () => {
        setError("");
        setLoading(true);
        try {
          const response = await proxySearch(collection, {
            query_by: "",
            q: "*",
            filter_by: `id:=[${documentIds.join(",")}]`,
            ...params,
          });
          setDocuments(response.hits.map((hit) => hit.document));
        } catch (error) {
          console.error(error);
          setError((error as Error).message);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [proxySearch, collection, documentIds]);

  return { documents, loading, error };
};
