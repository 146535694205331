import styled from "styled-components";
import {
  yupPasswordConfirmation,
  yupStrictPassword,
} from "Utils/yupStrictPassword";
import { useUser } from "auth";
import { Form, Formik } from "formik";
import { Button } from "melodies-source/Button";
import { SvgKey } from "melodies-source/Svgs/Key";
import { Body2 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { useState } from "react";
import toast from "react-hot-toast";
import * as yup from "yup";

interface Values {
  password: string;
  confirmPassword: string;
}

const validationsSchema = yup.object().shape({
  password: yupStrictPassword,
  confirmPassword: yupPasswordConfirmation,
});

const Actions = styled.div`
  gap: 12px;
`;

interface PasswordPairFormProps {
  isReset?: boolean;
  onError?: () => void;
  onClose?: () => void;
}

export const PasswordPairForm = ({
  isReset,
  onError,
  onClose,
}: PasswordPairFormProps) => {
  const { updatePassword } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: Values) => {
    try {
      setIsLoading(true);
      await updatePassword(values.password);
      toast.success("Password updated.");
      onClose?.();
    } catch (err) {
      onError?.();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik<Values>
      initialValues={{ password: "", confirmPassword: "" }}
      validationSchema={validationsSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ values, errors, handleChange }) => {
        return (
          <Form>
            <TextInput
              label="New Password"
              leftIcon={<SvgKey />}
              type="password"
              name="password"
              placeholder="Choose a new password..."
              value={values.password}
              onChange={handleChange("password")}
              hasError={!!errors.password}
              helperText={errors.password}
            />
            <Body2 style={{ marginTop: 10 }}>
              Password must contain at least 8 characters, one uppercase, one
              lowercase, and one number or special character, (e.g., ! @ # $ % ^
              & *).
            </Body2>
            <div style={{ marginTop: 18 }}>
              <TextInput
                label="Confirm Password"
                leftIcon={<SvgKey />}
                type="password"
                name="confirmPassword"
                placeholder="Passwords must match..."
                value={values.confirmPassword}
                onChange={handleChange("confirmPassword")}
                hasError={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
              />
            </div>
            <Actions
              style={{
                marginTop: 30,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button type="submit" disabled={isLoading} loading={isLoading}>
                Save
              </Button>
              {!isReset && (
                <Button
                  variant="secondary"
                  disabled={isLoading}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              )}
            </Actions>
          </Form>
        );
      }}
    </Formik>
  );
};
