import * as React from "react";
import { SVGProps } from "react";
export const SvgCloseLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.61289944,5.20970461 L6.70710678,5.29289322 L12,10.585 L17.2928932,5.29289322 L17.3871006,5.20970461 C17.7793918,4.90467972 18.3466228,4.93240926 18.7071068,5.29289322 C19.0675907,5.65337718 19.0953203,6.22060824 18.7902954,6.61289944 L18.7071068,6.70710678 L13.415,12 L18.7071068,17.2928932 C19.0976311,17.6834175 19.0976311,18.3165825 18.7071068,18.7071068 C18.3466228,19.0675907 17.7793918,19.0953203 17.3871006,18.7902954 L17.2928932,18.7071068 L12,13.415 L6.70710678,18.7071068 L6.61289944,18.7902954 C6.22060824,19.0953203 5.65337718,19.0675907 5.29289322,18.7071068 C4.93240926,18.3466228 4.90467972,17.7793918 5.20970461,17.3871006 L5.29289322,17.2928932 L10.585,12 L5.29289322,6.70710678 C4.90236893,6.31658249 4.90236893,5.68341751 5.29289322,5.29289322 C5.65337718,4.93240926 6.22060824,4.90467972 6.61289944,5.20970461 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
