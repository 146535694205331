import * as React from "react";
import { SVGProps } from "react";
export const SvgRecord = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10,2 C14.418278,2 18,5.581722 18,10 C18,14.418278 14.418278,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 Z M10.969581,13.8816838 C10.7367517,13.9396521 10.4958556,13.9772208 10.2489005,13.9923822 L10,14 L10,17 C10.4685791,17 10.9264048,16.9539591 11.3692,16.8661544 L11.6984114,16.792537 L10.969581,13.8816838 Z M7.17157288,12.8284271 L5.05025253,14.9497475 C5.85958521,15.7590801 6.86617962,16.3711511 7.9917299,16.7076544 L8.30158864,16.792537 L9.0294272,13.8814367 C8.39413842,13.723093 7.81894031,13.4128643 7.34463031,12.9915482 L7.17157288,12.8284271 Z M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z M10,9 C10.5522847,9 11,9.44771525 11,10 C11,10.5522847 10.5522847,11 10,11 C9.44771525,11 9,10.5522847 9,10 C9,9.44771525 9.44771525,9 10,9 Z M11.6984114,3.20746303 L10.9705728,6.11856327 C11.6058616,6.27690703 12.1810597,6.58713568 12.6553697,7.00845179 L12.8284271,7.17157288 L14.9497475,5.05025253 C14.1404148,4.24091986 13.1338204,3.62884892 12.0082701,3.29234558 L11.6984114,3.20746303 Z M10,3 C9.53142091,3 9.07359524,3.04604087 8.63080002,3.13384559 L8.30158864,3.20746303 L9.0294272,6.11856327 C9.26248151,6.0604752 9.50362266,6.0228274 9.75083651,6.007634 L10,6 L10,3 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
