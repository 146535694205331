import { LayoutProps } from "./types";

export const WaveLayout = ({
  image,
  backgroundColor,
  buttonColor,
  width,
  height,
  onClick,
}: LayoutProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width ?? 163}
    height={height ?? 352}
    viewBox="0 0 163 352"
    fill="none"
    onClick={onClick}
  >
    <g clipPath="url(#wave-layout_svg__a)">
      <rect width={162.666} height={351.608} fill="#fff" rx={8.342} />
      <ellipse
        cx={56.516}
        cy={43.794}
        fill={backgroundColor}
        rx={181.226}
        ry={92.177}
      />
      <mask
        id="wave-layout_svg__b"
        width={425}
        height={240}
        x={-204}
        y={-107}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "alpha",
        }}
      >
        <ellipse
          cx={8.759}
          cy={12.721}
          fill="#D9D9D9"
          rx={211.882}
          ry={119.497}
        />
      </mask>
      <g mask="url(#wave-layout_svg__b)">
        <path fill="url(#wave-layout_svg__c)" d="M0 0h162.666v131.384H0z" />
      </g>
      <path fill="url(#wave-layout_svg__d)" d="M0 0h162.666v56.724H0z" />
      <g filter="url(#wave-layout_svg__e)">
        <rect
          width={145.148}
          height={18.352}
          x={8.76}
          y={311.567}
          fill={buttonColor}
          rx={9.176}
        />
      </g>
      <rect width={55} height={7} x={9} y={148} fill="#D9D9D9" rx={1.5} />
      <rect width={145} height={5} x={9} y={159} fill="#D9D9D9" rx={2.5} />
      <rect width={73} height={5} x={9} y={167} fill="#D9D9D9" rx={2.5} />
      <rect width={120} height={6} x={9} y={191} fill="#D9D9D9" rx={1.5} />
      <rect width={45} height={6} x={9} y={200} fill="#D9D9D9" rx={1.5} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M13.234 222.673c1.751 0 3.17-1.493 3.17-3.336S14.985 216 13.234 216s-3.17 1.494-3.17 3.337 1.42 3.336 3.17 3.336m0-.417c1.532 0 2.774-1.307 2.774-2.919s-1.242-2.92-2.774-2.92c-1.531 0-2.773 1.307-2.773 2.92s1.242 2.919 2.773 2.919M13.33 241.443c1.793 0 3.247-1.494 3.247-3.337s-1.454-3.336-3.247-3.336-3.247 1.493-3.247 3.336 1.454 3.337 3.247 3.337m0-.417c1.57 0 2.841-1.307 2.841-2.92s-1.272-2.919-2.84-2.919c-1.57 0-2.842 1.307-2.842 2.919s1.272 2.92 2.841 2.92"
        clipRule="evenodd"
      />
      <ellipse cx={13.33} cy={238.107} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M13.33 260.212c1.793 0 3.247-1.494 3.247-3.337s-1.454-3.337-3.247-3.337-3.247 1.494-3.247 3.337 1.454 3.337 3.247 3.337m0-.418c1.568 0 2.84-1.307 2.84-2.919s-1.272-2.92-2.84-2.92c-1.57 0-2.842 1.307-2.842 2.92s1.272 2.919 2.841 2.919"
        clipRule="evenodd"
      />
      <ellipse cx={13.33} cy={256.875} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M13.33 278.98c1.793 0 3.247-1.494 3.247-3.337 0-1.842-1.454-3.336-3.247-3.336s-3.247 1.494-3.247 3.336c0 1.843 1.454 3.337 3.247 3.337m0-.417c1.57 0 2.841-1.307 2.841-2.92s-1.272-2.919-2.84-2.919c-1.57 0-2.842 1.307-2.842 2.919s1.272 2.92 2.841 2.92"
        clipRule="evenodd"
      />
      <ellipse cx={13.33} cy={275.644} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <path
        fill="#7E7E7E"
        fillRule="evenodd"
        d="M13.33 297.748c1.793 0 3.247-1.494 3.247-3.337s-1.454-3.337-3.247-3.337-3.247 1.494-3.247 3.337 1.454 3.337 3.247 3.337m0-.417c1.57 0 2.841-1.308 2.841-2.92 0-1.613-1.272-2.92-2.84-2.92-1.57 0-2.842 1.307-2.842 2.92s1.272 2.92 2.841 2.92"
        clipRule="evenodd"
      />
      <ellipse cx={13.33} cy={294.411} fill="#FEFFFF" rx={2.841} ry={2.92} />
      <rect width={27} height={5} x={21} y={216.363} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={21} y={235.363} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={21} y={254.363} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={21} y={273.363} fill="#D9D9D9" rx={2.5} />
      <rect width={27} height={5} x={21} y={291.363} fill="#D9D9D9" rx={2.5} />
      <rect width={30} height={6} x={66} y={317} fill="#fff" rx={3} />
    </g>
    <rect
      width={161.666}
      height={350.608}
      x={0.5}
      y={0.5}
      stroke="#CDCDCD"
      rx={7.842}
    />
    <defs>
      <linearGradient
        id="wave-layout_svg__d"
        x1={81.333}
        x2={81.333}
        y1={0}
        y2={56.724}
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <clipPath id="wave-layout_svg__a">
        <rect width={162.666} height={351.608} fill="#fff" rx={8.342} />
      </clipPath>
      <pattern
        id="wave-layout_svg__c"
        width={1}
        height={1}
        patternContentUnits="objectBoundingBox"
      >
        <use
          xlinkHref="#wave-layout_svg__f"
          transform="matrix(.00301 0 0 .00373 -.09 0)"
        />
      </pattern>
      <filter
        id="wave-layout_svg__e"
        width={151.821}
        height={25.026}
        x={5.423}
        y={308.23}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1.668} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2349_173507"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2349_173507"
          result="shape"
        />
      </filter>
      <image
        href={image}
        id="wave-layout_svg__f"
        width={392}
        height={268}
        preserveAspectRatio="xMidYMid slice"
      />
    </defs>
  </svg>
);
