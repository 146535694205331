import { FlexRow } from "Components/Flex";
import { OptionProps } from "melodies-source/Autocomplete";
import { H4 } from "melodies-source/Text";
import styled from "styled-components";

const OptionContainer = styled(FlexRow)`
  padding: 5px 20px;
  cursor: pointer;

  &:hover {
    background: #f2f5f7;
  }
`;

const OptionAvatar = styled.img`
  border-radius: 35px;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  object-fit: cover;
`;

export const ArtistGroupOption = ({
  label,
  caption,
  caption2,
  avatarSrc,
  ...props
}: OptionProps) => {
  return (
    <OptionContainer {...props} gap="10px" yCenter>
      <OptionAvatar src={avatarSrc} alt="" />
      <H4>{label}</H4>
    </OptionContainer>
  );
};
