import { useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "Utils";
import { SurveyTypesenseDocument } from "models/survey";
import { useTypesenseProxyContext, ProxySearchParams } from "contexts/TypesenseProxyContext";

export const useArtistSurveys = ({
  page = 1,
  query = "*",
  queryBy = "title",
  filterBy = "",
  sortBy = "",
  limitHits = 10,
  resultsPerPage = 10,
} = {}) => {
  const [documents, setDocuments] = useState<SurveyTypesenseDocument[]>([]);
  const [loading, setLoading] = useState(true);

  const { proxySearch } = useTypesenseProxyContext();

  const searchParameters: ProxySearchParams = useMemo(
    () => ({
      q: query,
      query_by: queryBy,
      sort_by: sortBy,
      filter_by: filterBy,
      page: page,
      per_page: resultsPerPage,
      limit_hits: limitHits,
    }),
    [query, page, resultsPerPage, filterBy, queryBy, limitHits, sortBy],
  );

  async function getDocuments(searchParameters: ProxySearchParams) {
    setLoading(true);
    proxySearch('surveys', searchParameters)
      .then((resp) => {
        setDocuments(resp.hits.map((survey) => survey.document));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedQuery = useCallback(debounce(getDocuments), []);

  function forceReload() {
    if (proxySearch) getDocuments(searchParameters);
  }

  useEffect(() => {
    if (proxySearch) {
      debouncedQuery(searchParameters);
    }
  }, [proxySearch, searchParameters, debouncedQuery]);

  return { documents, loading: loading, forceReload };
};
