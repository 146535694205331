import { Button } from "Components";
import { Body1, H2 } from "melodies-source/Text";
import styled, { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { SvgRightLarge } from "melodies-source/Svgs/RightLarge";
import { FlexRow } from "Components/Flex";
import { SelectionCard } from "Routes/SetFan/Components/SelectionCard";
import { Modal } from "melodies-source/Modal";
import { useCreateSurveyBuilder } from "Routes/SetFan/hooks/useCreateSurveyBuilder";
import { useTemplates } from "./TemplateContext";
import { surveyTemplates } from "./defaults";

interface CreatorGoalsProps {
  onClose: VoidFunction;
  onSelect: VoidFunction;
}

export const SelectCategoryModal = ({
  onClose,
  onSelect,
}: CreatorGoalsProps) => {
  const history = useHistory();
  const { create, isLoading } = useCreateSurveyBuilder();
  const { colors } = useTheme();
  const { templateCategory, setTemplateCategory, modal } = useTemplates();

  const templateCategories = Object.values(surveyTemplates).map(
    ({ templates, ...rest }) => rest,
  );

  return (
    <Modal
      isOpen={modal.selectCategory}
      onClose={onClose}
      variant="large"
      header={
        <div>
          <H2>What would you like to do</H2>
          <Body1 style={{ color: "#666", marginBottom: 12 }}>
            Please share your goals, and we'll provide tailored survey templates
            with relevant questions.
          </Body1>
        </div>
      }
    >
      {templateCategories.map((goal, idx) => (
        <StyledGoalNav
          {...{
            ...goal,
            selected: templateCategory === goal.key,
            onClick: () => {
              setTemplateCategory(goal.key);
            },
            last: idx === templateCategories.length - 1,
          }}
        />
      ))}
      <FlexRow style={{ justifyContent: "space-between", marginTop: 32 }}>
        <Button
          variant="tertiary"
          loading={isLoading}
          style={{ color: colors.main }}
          onClick={async () => {
            const { survey } = await create();
            history.push(`surveys/${survey.id}/wizard`);
          }}
        >
          Build My Own Survey
        </Button>
        <FlexRow>
          <Button
            variant="tertiary"
            disabled={isLoading}
            onClick={() => onClose()}
            style={{ marginRight: 24, color: colors.main }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            rightIcon={<SvgRightLarge />}
            onClick={onSelect}
            disabled={!templateCategory || isLoading}
            style={{ minWidth: 199 }}
          >
            See Templates
          </Button>
        </FlexRow>
      </FlexRow>
    </Modal>
  );
};

const StyledGoalNav = styled(SelectionCard)<{ last?: boolean }>`
  ${({ last }) => !last && "margin-bottom: 15px"};
`;
