import { ReactComponent as TemplateIcon } from "assets/svg/fanCensusShort.svg";
import { SurveyTemplate } from "../../types";
import { getContestDefaults } from "../helpers";

export const fanCensusShort: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "Fan Census Short",
  description:
    "Generate a report on the types of music your fans are listening to.",
  templateId: "fanCensusShort",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: "Fan Census Short",
        },
      },
      numberOfCategories: {
        content: "3",
      },
      ...getContestDefaults(formState),
    },
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-brand---fa-c7498e"
        : "pp-brand---fa-745161",
    fields: [
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
