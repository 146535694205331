import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { H1, H3, Body1, H4 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { Select } from "melodies-source/Select";
import { Affiliation, AffiliationMap } from "models/artistSignup";
import { TwoColumns, ActionText, useArtistContext } from "Components";
import { Teammate } from "./Teammate";
import { useDesktopMediaQuery } from "hooks";
import { Layout } from "../../Layout";
import { StepProps } from "../..";
import { ReactComponent as UsersIcon } from "assets/svg/users-2.svg";
import { ReactComponent as EmailIcon } from "assets/svg/email-outlined.svg";
import { addUser } from "Routes/Settings/Components/ArtistTeammates/AddTeammateModal";
import { Checkbox } from "melodies-source/Selectable";

const EMAIL_REGEX = /^(.+)@(.+)$/;

export const Invite: React.FC<StepProps> = ({ handleBack, handleClose }) => {
  const { artistGroupDoc } = useArtistContext();
  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [emailWasAdded, setEmailWasAdded] = useState(false);
  const [role, setRole] = useState<Affiliation>(null);
  const [roleIsValid, setRoleIsValid] = useState(true);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [teammates, setTeammates] = useState<Teammate[]>([]);
  const [loading, setLoading] = useState(false);
  const isDesktop = useDesktopMediaQuery();
  const { hideWelcome } = useArtistContext();

  const Heading = isDesktop ? H1 : H3;
  const SubHeading = isDesktop ? H3 : H4;

  const resetForm = () => {
    setEmail("");
    setRole(null);
    setUserIsAdmin(false);
  };

  const addTeammate = (teammate: Teammate) => {
    const emailValid = EMAIL_REGEX.test(teammate.email);
    let emailAdded = false;
    if (emailValid) {
      const added = teammates.find(({ email }) => email === teammate.email);
      if (added) {
        emailAdded = true;
        setEmailWasAdded(true);
      }
    } else {
      setEmailIsValid(false);
    }
    const roleValid = role !== undefined;
    if (!roleValid) {
      setRoleIsValid(false);
    }

    const valid = emailValid && !emailAdded && roleValid;

    const newTeammates = [...teammates, teammate];
    if (valid) {
      setTeammates(newTeammates);
      resetForm();
    }
    return valid ? newTeammates : false;
  };

  const removeTemmate = (email: string) => {
    setTeammates((teammates) =>
      teammates.filter((teammate) => teammate.email !== email),
    );
  };

  const handleFinish = async () => {
    if (email || role) {
      const teammates = addTeammate({ email, isAdmin: userIsAdmin, role });
      if (teammates) {
        setLoading(true);
        for (const teammate of teammates) {
          await addUser({
            artistGroupId: artistGroupDoc.id,
            email: teammate.email,
            role: teammate.role,
            isAdmin: teammate.isAdmin,
          });
        }
        setLoading(false);
        hideWelcome(true);
      }
    } else {
      hideWelcome(true);
    }
  };

  const teammateList = teammates.map((teammate) => (
    <Teammate
      key={teammate.email}
      teammate={teammate}
      onRemove={() => removeTemmate(teammate.email)}
    />
  ));

  useEffect(() => {
    if (!emailIsValid) setEmailIsValid(true);
    if (emailWasAdded) setEmailWasAdded(false);
  }, [email]);

  useEffect(() => {
    if (!roleIsValid) setRoleIsValid(true);
  }, [role]);

  return (
    <Layout
      step={4}
      buttons={{
        back: { onClick: handleBack },
        next: {
          text: "Finish",
          onClick: handleFinish,
          loading,
        },
      }}
      action={
        <ActionText onClick={() => hideWelcome()}>Set this up later</ActionText>
      }
    >
      {isDesktop && <UsersIcon />}
      <Content>
        <Column>
          <HeadingContainer>
            {!isDesktop && <UsersIcon />}
            <Heading>Invite Teammates</Heading>
          </HeadingContainer>
          <SubHeading>Who else should be on your team?</SubHeading>
          <Body1>
            Here you can give access to different members of your team, and
            determine if you want them to have admin access.
            <br />
            <br />
            You can modify these permissions at any time.
          </Body1>
        </Column>
        <Column>
          <div>
            {teammateList}
            <Form>
              <TextInput
                label="Email Address"
                placeholder="Please insert teammate’s email..."
                value={email}
                onChange={setEmail}
                leftIcon={<EmailIcon />}
                {...(!emailIsValid && {
                  hasError: true,
                  helperText: "Please enter a valid email",
                })}
                {...(emailWasAdded && {
                  hasError: true,
                  helperText: "Email already added",
                })}
              />
              <Select
                label="Role"
                options={Object.entries(AffiliationMap).map(
                  ([value, label]) => ({
                    value,
                    label,
                  }),
                )}
                value={role}
                onChange={(role: Affiliation) => setRole(role)}
                {...(!roleIsValid && {
                  hasError: true,
                  helperText: "Please select a role",
                })}
              />
              <Checkbox
                label="Make Admin?"
                value={userIsAdmin}
                onChange={setUserIsAdmin}
              />
            </Form>
          </div>
          <ActionText
            onClick={() => addTeammate({ email, isAdmin: userIsAdmin, role })}
          >
            Invite another teammate
          </ActionText>
        </Column>
      </Content>
    </Layout>
  );
};

const Content = styled(TwoColumns)`
  margin: 20px 0 30px;

  ${({ theme }) => theme.media.mobile} {
    margin: 10px 0 0 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  svg {
    width: 30px;
    height: 30px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
