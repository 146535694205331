import React from "react";
import { SettingsRow } from "../SettingsRow";
import { KeyLinks } from "./KeyLinks";
import { SocialLinks } from "./SocialLinks";
import { useDesktopMediaQuery } from "hooks";
import styled from "styled-components";

export const ArtistLinks: React.FC = () => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <SettingsRow
      title={"Artist Links"}
      description={
        <>
          Artist links are visible across SET. services and may be seen by the
          general public when publishing content.
        </>
      }
    >
      <Container>
        <KeyLinks />
        {isDesktop && <Divider />}
        <SocialLinks />
      </Container>
    </SettingsRow>
  );
};

const Container = styled.div`
  display: flex;
  gap: 70px;
  margin-bottom: 15px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  width: 1px;
  background: var(--border-color);
  margin: 15px 0;
`;
