import React, {
  useState,
  useRef,
  PropsWithChildren,
  useLayoutEffect,
  MutableRefObject,
} from "react";

type AnimationProps = {
  className?: string;
  introKeyframes?: Keyframe[];
  outroKeyframes?: Keyframe[];
  introEasing?: string;
  outroEasing?: string;
  duration: number;
  iterations?: number;
  display: boolean;
  onIntroAnimationEnd?: () => void;
  onOutroAnimationEnd?: () => void;
};

export const AnimationConditional = ({
  alternativeRef,
  children,
  className,
  introKeyframes,
  introEasing,
  outroKeyframes,
  outroEasing,
  duration,
  iterations = 1,
  display,
  onIntroAnimationEnd,
  onOutroAnimationEnd,
}: PropsWithChildren<
  AnimationProps & {
    alternativeRef?: MutableRefObject<HTMLDivElement>;
  }
>) => {
  const localRef = useRef<HTMLDivElement>();
  const ref = alternativeRef ?? localRef;

  const [removeComponent, setRemoveComponent] = useState(
    introKeyframes ? true : false,
  );

  useLayoutEffect(() => {
    const childElement = ref.current;
    if (display) {
      setRemoveComponent(false);
      if (!childElement) return;
      if (introKeyframes?.length && introKeyframes.length > 1) {
        const animation = childElement.animate(introKeyframes, {
          duration,
          iterations,
          easing: introEasing ?? "linear",
        });
        animation.onfinish = () => {
          onIntroAnimationEnd?.();
        };
      }
    } else {
      if (!childElement) return;
      if (outroKeyframes?.length && outroKeyframes.length > 1) {
        const animation = childElement.animate(outroKeyframes, {
          duration,
          iterations,
          easing: outroEasing ?? "linear",
        });
        animation.onfinish = () => {
          setRemoveComponent(true);
          onOutroAnimationEnd?.();
        };
      } else {
        setRemoveComponent(true);
      }
    }
  }, [display, duration, iterations, ref, removeComponent]);

  return (
    !removeComponent && (
      <div className={className} style={{ display: "inherit" }} ref={ref}>
        {children}
      </div>
    )
  );
};

export const AnimationToggle = ({
  children,
  className,
  duration,
  states,
  toggle,
}: PropsWithChildren<{
  className?: string;
  duration: number;
  states: [Keyframe, Keyframe];
  toggle: boolean;
}>) => {
  const innerRef = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (toggle) {
      innerRef?.current.animate(states[0], {
        duration,
        iterations: 1,
        fill: "forwards",
      });
    } else {
      innerRef?.current.animate(states[1], {
        duration,
        iterations: 1,
        fill: "forwards",
      });
    }
  }, [duration, states, toggle]);

  return (
    <div className={className} style={{ display: "inherit" }} ref={innerRef}>
      {children}
    </div>
  );
};
