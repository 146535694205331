import React from "react";
import styled from "styled-components";
import { Spinner } from "melodies-source/Spinner";
import { Subtitle1 } from "melodies-source/Text";
import { RefreshIcon } from "Components/RefreshIcon";
import { FlexColumn } from "Components/Flex";

interface Props {
  label: string;
  total: number;
  description: string;
  loading: boolean;
  error: boolean;
}

export const Item: React.FC<Props> = ({
  label,
  total,
  description,
  loading,
  error,
}) => {
  return (
    <FlexColumn xCenter>
      <Label>{label}</Label>
      {loading ? (
        <StyledSpinner />
      ) : error ? (
        <StyledRefreshIcon />
      ) : (
        <Total>{total.toLocaleString()}</Total>
      )}
      <Description>{description}</Description>
    </FlexColumn>
  );
};

const StyledRefreshIcon = styled(RefreshIcon)`
  color: var(--main-color);
`;

const StyledSpinner = styled(Spinner)`
  margin: 10px 0 15px;
  transform: scale(0.7);
`;

const Label = styled(Subtitle1)`
  line-height: 15px;
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: 410px) {
    height: 30px;
  }
`;

const Description = styled.p`
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: center;
`;

const Total = styled.span`
  color: var(--main-color);
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
`;
