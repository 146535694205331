import React, { useState } from "react";
import { Button } from "melodies-source/Button";
import { SelectMenu } from "melodies-source/SelectMenu";
import { ListItem } from "melodies-source/ListItem";
import styled from "styled-components";

interface Item {
  label: string;
  onClick: () => void;
  caption?: string;
  icon?: {
    position: "left" | "right";
    element: JSX.Element;
  };
}

interface Props {
  menu: {
    position: "left" | "right";
    width: string;
    items: Item[];
  };
  textButton?: boolean;
}

export const MenuButton: React.FC<Props & Parameters<typeof Button>["0"]> = ({
  menu,
  textButton,
  ...buttonProps
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => setIsOpen((isOpen) => !isOpen);

  const ButtonComponent = textButton ? TextButton : Button;

  return (
    <Container>
      <ButtonComponent {...buttonProps} onClick={toggleIsOpen} />
      <StyledSelectMenu
        isOpen={isOpen}
        onClose={toggleIsOpen}
        width={menu.width}
        position={menu.position}
      >
        {menu.items.map((item) => (
          <StyledListItem
            key={item.label}
            onClick={() => {
              item.onClick();
              toggleIsOpen();
            }}
            {...(item.icon && {
              [item.icon.position === "left" ? "leftIcon" : "rightIcon"]:
                item.icon.element,
            })}
          >
            {item.label}
          </StyledListItem>
        ))}
      </StyledSelectMenu>
    </Container>
  );
};

const TextButton = styled(Button)`
  background: none;
  box-shadow: none;
  &:hover {
    background: none;
    box-shadow: none;
  }
`;

const StyledListItem = styled(ListItem)`
  svg {
    color: var(--text-color);
  }

  & > div > p {
    font-size: 16px;
  }

  & > div > div {
    min-width: unset;
    max-width: unset;
    margin-right: 10px;
  }
`;

interface StyledSelectMenuProps {
  width: Props["menu"]["width"];
  position: Props["menu"]["position"];
}

const StyledSelectMenu = styled(SelectMenu)<StyledSelectMenuProps>`
  position: absolute !important;
  top: 90%;
  ${({ width }) => `width: ${width ?? "300px"};`}
  ${({ position }) => position === "left" && "left: 0;"}
  ${({ position }) => position === "right" && "right: 0;"}
`;

const Container = styled.div`
  position: relative;
`;
