import { useHistory, useLocation } from "react-router-dom";

interface RouteState {
  [key: string | number]: any;
}

export const useHistoryPush = () => {
  const location = useLocation();
  const history = useHistory();
  return (route: string, state?: RouteState, isReplace?: boolean) => {
    const routeOptions = {
      pathname: route,
      search: location.search,
      state: state,
    };
    !isReplace ? history.push(routeOptions) : history.replace(routeOptions);
  };
};
