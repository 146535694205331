import { ReactNode, useState } from "react";
import styled from "styled-components";
import { ImageUpload } from "../ImageUpload";
import { Modal } from "../Modal";
import { SvgUpload } from "../Svgs/Upload";

const Trigger = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.navy};
  margin: 0;
  padding: 0;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    object-fit: contain;
    color: ${({ theme }) => theme.colors.navy};
  }
`;

const Content = styled.div`
  margin-top: 24px;
`;

interface ImageUploadModalProps {
  uploadPath: string;
  uploadFileName?: string;
  uploadWithFileExt?: boolean;
  dropzoneDescription?: string;
  icon?: ReactNode;
  label?: ReactNode;
  header?: string;
  aspectRatio?: number;
  withAspectRatio?: boolean;
  withBackground?: boolean;
  withBackdropClose?: boolean;
  onComplete?: (url: string) => void;
  children?: ({ toggle }: { toggle(): void }) => ReactNode;
}

export const ImageUploadModal = ({
  uploadPath,
  uploadFileName,
  uploadWithFileExt,
  dropzoneDescription,
  icon,
  label,
  header = "Upload an image",
  aspectRatio,
  withAspectRatio,
  withBackground,
  withBackdropClose,
  onComplete,
  children,
}: ImageUploadModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(true);

  return (
    <>
      {children?.({ toggle }) ?? (
        <Trigger type="button" onClick={toggle}>
          {icon || <SvgUpload />} {label || "Upload Image"}
        </Trigger>
      )}
      <Modal
        isOpen={isOpen}
        withBackdropClose={withBackdropClose}
        onClose={() => setIsOpen(false)}
        header={header}
      >
        <Content>
          <ImageUpload
            uploadPath={uploadPath}
            uploadFileName={uploadFileName}
            uploadWithFileExt={uploadWithFileExt}
            aspectRatio={aspectRatio}
            withAspectRatio={withAspectRatio}
            dropzoneDescription={dropzoneDescription}
            withBackground={withBackground}
            onCancel={() => {
              setIsOpen(false);
            }}
            onComplete={(url) => {
              setIsOpen(false);
              onComplete?.(url);
            }}
          />
        </Content>
      </Modal>
    </>
  );
};
