import { CSSProperties } from "styled-components";

const LastSyncIcon = ({ sx }: { sx?: CSSProperties }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={sx}
  >
    <path
      d="M17.4242 17.7079C19.7747 17.7079 21.6279 15.9827 21.6279 13.8281C21.6279 12.2235 20.7163 10.7997 19.2172 10.1744C19.2247 6.76927 16.7688 4.31335 13.6123 4.31335C11.6084 4.31335 10.1017 5.34544 9.15998 6.71654C7.24648 6.20426 5.23504 7.62809 5.1597 9.75253C3.43454 10.0614 2.37231 11.6058 2.37231 13.4967C2.37231 15.7868 4.37622 17.7004 7.01294 17.7004L17.4242 17.7079ZM17.4242 16.2012H7.02047C5.2275 16.2012 3.89408 14.9506 3.89408 13.4967C3.89408 11.99 4.8207 10.875 6.35753 10.875C6.47053 10.875 6.51573 10.8147 6.5082 10.7093C6.463 8.47184 8.06763 7.70342 9.68733 8.2157C9.78526 8.24583 9.84553 8.22323 9.89073 8.14036C10.6365 6.82954 11.7364 5.81252 13.6047 5.81252C15.9703 5.81252 17.6578 7.68835 17.7708 9.8806C17.7934 10.2874 17.7632 10.7243 17.7331 11.086C17.718 11.1914 17.7632 11.2517 17.8612 11.2668C19.2247 11.5304 20.1061 12.4947 20.1061 13.8281C20.1061 15.1465 18.9384 16.2012 17.4242 16.2012ZM9.37845 11.8845C9.37845 13.5645 10.7119 14.9054 12.3692 14.9054C14.0266 14.9054 15.36 13.5645 15.36 11.9071C15.36 11.6284 15.1265 11.4024 14.8478 11.4024C14.569 11.4024 14.3355 11.6284 14.3355 11.9071C14.3355 12.9995 13.4616 13.8809 12.3692 13.8809C11.2694 13.8809 10.3955 12.9995 10.3955 11.9071C10.3955 10.86 11.2091 10.0087 12.2261 9.97853C12.3014 9.971 12.3843 9.971 12.4521 9.97853L11.9097 10.5134C11.8419 10.5963 11.7967 10.7017 11.7967 10.8223C11.7967 11.0935 12.0001 11.2894 12.2638 11.2894C12.3843 11.2894 12.5124 11.2291 12.5953 11.1538L13.8609 9.8806C14.0417 9.69979 14.0417 9.37585 13.8609 9.19505L12.6103 7.92189C12.5199 7.82396 12.3918 7.76369 12.2562 7.76369C11.985 7.76369 11.7892 7.97463 11.7892 8.22323C11.7892 8.35883 11.8419 8.47184 11.9172 8.54717L12.3165 8.96151C12.2638 8.95398 12.2035 8.95398 12.1432 8.95398C10.6742 9.00671 9.37845 10.2799 9.37845 11.8845Z"
      fill="#635BFF"
    />
  </svg>
);

export default LastSyncIcon;
