import styled from "styled-components";
import ServiceCard from "./ServiceCard";
import { useArtistContext } from "Components";

export const ServiceCards = () => {
  const { id: artistId } = useArtistContext();

  return (
    <ServiceCardsContainer>
      <ServiceCard
        headerText="Livestream"
        bodyText="Take the stage for a truly LIVE experience with real-time interactions and gamified revenue opportunities."
        buttonText="Schedule Now"
        onButtonClick={() =>
          window.open(
            "mailto:support@set.live?subject=Schedule%20a%20Livestream",
          )
        }
      />
      <ServiceCard
        headerText="Meet & Greet"
        bodyText="Meet one-on-one with fans in a virtual setting. It’s convenient, fun and you keep 100% of the ticket price."
        buttonText="Get Started"
        onButtonClick={() =>
          window.open(
            `${process.env.REACT_APP_MGA_URL}/artist/${artistId}/events`,
          )
        }
      />
      <ServiceCard
        headerText="ShoutOut"
        bodyText="Offer personalized video messages for your fans. Keep 100% of the price you set."
        buttonText="Get Started"
        onButtonClick={() => window.open(process.env.REACT_APP_SO_URL)}
      />
    </ServiceCardsContainer>
  );
};

const ServiceCardsContainer = styled.div`
  display: flex;
  margin: 36px 46px 48px;
  gap: 20px;
  max-width: 1300px;

  ${({ theme }) => theme.media.tablet} {
    gap: 10px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  ${({ theme }) => theme.media.mobile} {
    align-items: center;
    margin: 36px 0;
  }
`;
