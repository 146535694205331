import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const useDebounce = <T,>(
  value: T,
  delay?: number,
  callback?: () => void,
): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(() => {
        if (callback) callback();
        return value;
      });
    }, delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useDebounceState = <T,>(
  value: T,
  delay?: number,
  callback?: () => void,
): [T, Dispatch<SetStateAction<T>>] => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(() => {
        if (callback) callback();
        return value;
      });
    }, delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return [debouncedValue, setDebouncedValue];
};
