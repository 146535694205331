import * as React from "react";
import { SVGProps } from "react";
export const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.2821025,4.95385491 C15.6665732,4.55736947 16.2996633,4.54762962 16.6961487,4.93210035 C17.0621353,5.28699641 17.0985871,5.85373379 16.7996329,6.25067071 L16.7179033,6.34614658 L7.98921048,15.347611 L3.27180288,10.3353654 C2.89328641,9.93319169 2.91246442,9.30031722 3.31463817,8.92180075 C3.68587548,8.57240093 4.25368618,8.56186505 4.63655846,8.87863215 L4.72820287,8.96463604 L8.01100287,12.4520007 L15.2821025,4.95385491 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
