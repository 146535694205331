import { Timestamp } from "firebase/firestore";
import { Dispatch, SetStateAction } from "react";
import { DateTime } from "luxon";
import { CSSProperties } from "styled-components";

export interface UMGIntegrationCardProps {
  integrationStatus: UMGIntegrationStatus;
  lastSynced?: DateTime;
  onRemoveUMG: () => void;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  sx?: CSSProperties;
}

export const enum UMGIntegrationStatus {
  NOT_CONNECTED = "notConnected",
  SELECT_LISTS = "selectLists",
  CONNECTED = "connected",
}

export type UMGDocument = {
  artistName: string;
  countryIsoCode: string;
  sms?: string;
  email?: string;
  lastSynced?: Timestamp;
  uiVisible?: boolean;
};

export type EntityCountry = {
  id: string;
  label: string;
};

export interface UMGOptInSearchResponse {
  key: string;
  brandId: string;
  name: string;
  category: string;
  channel: string;
  country: string;
  tag: string;
}

export type UMGOptIns = Record<
  "emailOptInKeys" | "smsOptInKeys",
  UMGOptInSearchResponse[]
>;

export interface UMGActionsRequest {
  action: "get_lists" | "connect" | "remove";
  artistGroupId: string;
  umgArtistName: string;
  countryIsoCode: string;
}

export type UMGActionsResponse = UMGOptIns;
