import { SpinnerRing } from "Components/LoadingScreen/SpinnerRing";
import { FlexColumn } from "Routes/styled";
import { SvgClipboard } from "melodies-source/Svgs/Clipboard";
import { SvgInstantMessage } from "melodies-source/Svgs/InstantMessage";
import { SvgChecklist } from "melodies-source/Svgs/Checklist";
import { Body1, H3 } from "melodies-source/Text";
import { useEffect, useState } from "react";
import { LoadingScreenProps } from "Components";

const POPULATING_STEP_NAMES = ["answers", "questions", "building"] as const;
type PopulatingStep = (typeof POPULATING_STEP_NAMES)[number];

const POPULATING_STEPS: Record<PopulatingStep, LoadingScreenProps> = {
  answers: { icon: <SvgInstantMessage />, message: "Analyzing Answers..." },
  questions: { icon: <SvgChecklist />, message: "Creating Questions..." },
  building: {
    icon: <SvgClipboard />,
    message: "Building Survey...",
  },
};

export const LoadingState = ({ message }: { message: string }) => {
  const [populatingStepKey, setPopulatingStepKey] = useState<PopulatingStep>(
    POPULATING_STEP_NAMES[0],
  );

  const showPopulatingLoading = async () => {
    for (const stepKey of Object.keys(POPULATING_STEPS) as PopulatingStep[]) {
      setPopulatingStepKey(stepKey);
      await new Promise((r) => setTimeout(r, 2000));
    }
  };

  useEffect(() => {
    showPopulatingLoading();
  }, []);

  return (
    <FlexColumn>
      <H3 style={{ margin: "24px 0 8px" }}>Please stay on this screen.</H3>
      <Body1>{message}</Body1>
      {populatingStepKey && (
        <SpinnerRing
          icon={POPULATING_STEPS[populatingStepKey].icon}
          message={POPULATING_STEPS[populatingStepKey].message}
          style={{ aspectRatio: "initial", margin: "44px auto" }}
        />
      )}
    </FlexColumn>
  );
};
