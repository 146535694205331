import styled from "styled-components";
import { SpinnerRing } from "./SpinnerRing";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  icon: React.ReactElement;
  message?: string;
}

export type LoadingScreenProps = Props;

export const LoadingScreen: React.FC<Props> = (props) => {
  return (
    <Container>
      <SpinnerContainer>
        <SpinnerRing {...props} />
      </SpinnerContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SpinnerContainer = styled.div`
  width: 300px;
`;
