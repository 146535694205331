import styled, { CSSProperties } from "styled-components";
import { AppCardButton } from "./AppCardButton";

export const AppCard = ({
  compact,
  children,
  footerIcon,
  buttonText,
  onButtonClick,
  className,
  sx,
}: {
  compact?: boolean;
  children: JSX.Element | JSX.Element[];
  footerIcon: JSX.Element;
  buttonText: string;
  onButtonClick?: () => void;
  className?: string;
  sx?: CSSProperties;
}) => {
  return (
    <AppCardContainer style={sx} className={className}>
      <AppCardContent>
        <BodyContainer compact={compact}>{children}</BodyContainer>
        <Footer>
          {footerIcon}
          <StyledButton onClick={onButtonClick}>{buttonText}</StyledButton>
        </Footer>
      </AppCardContent>
    </AppCardContainer>
  );
};

const AppCardContainer = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  max-width: 1300px;
  position: relative;

  ${({ theme }) => theme.media.mobile} {
    border-radius: 0px;
  }
`;

const AppCardContent = styled.div`
  width: 100%;
  padding: 43px 51px;

  ${({ theme }) => theme.media.mobile} {
    padding: 30px 0 41px 0;
    max-width: 350px;
  }
`;

const BodyContainer = styled.div<{ compact?: boolean }>`
  display: flex;
  margin-bottom: 51px;

  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
  }
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    margin-bottom: 27px;
  }

  & > div:first-of-type {
    margin-right: ${({ compact }) => compact && "50px"};
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.desktop} {
    justify-content: space-between;
  }

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;
  }
`;

const StyledButton = styled(AppCardButton)`
  margin-left: 15px;

  ${({ theme }) => theme.media.mobile} {
    margin-bottom: 27px;
  }
`;
