import { SvgCloseLarge } from "melodies-source/Svgs/CloseLarge";
import { useArtistContext, useTimeContext } from "Components";
import { DateTime } from "luxon";
import { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import { MusicIcon } from "./MusicIcon";
import {
  query,
  collection,
  where,
  orderBy,
  getFirestore,
} from "firebase/firestore";

export const SetLiveNotification = () => {
  const [hide, setHide] = useState(false);
  const { id } = useArtistContext();
  const { time } = useTimeContext();
  const [setLives] = useCollectionData(
    query(
      collection(getFirestore(), "setlive_events"),
      where("groupId", "==", id),
      orderBy("startsAt", "desc"),
    ),
    { idField: "id" },
  );

  const data = setLives?.find((s) => !s.endedAt);

  const start = DateTime.fromJSDate(data?.startsAt.toDate());

  const timeToStart = start
    .diff(time)
    .shiftTo("hours", "minutes", "seconds", "milliseconds");

  if (hide) {
    return null;
  }

  if (data && timeToStart.hours < 1) {
    return (
      <Container>
        <SvgCloseLarge
          style={{ position: "absolute", top: "10px", right: "10px" }}
          onClick={() => setHide(true)}
        />
        <Card
          style={{
            borderBottom: "1px solid rgba(255, 255, 255, 0.28)",
            marginBottom: 0,
            paddingBottom: "20px",
          }}
        >
          <Icon />
          <Content>
            <Small>Ongoing Show...</Small>
            <Title>{data.venue}</Title>
            <Time>{start.toFormat("EEEE MMMM d, h:mm ZZZZ")}</Time>
          </Content>
        </Card>
        <Card>
          <Content style={{ paddingLeft: "54px" }}>
            {start > time ? (
              <Countdown>
                Starting in{" "}
                <Timestamp>
                  {timeToStart.days > 0 && `${timeToStart.days} days`}{" "}
                  {timeToStart.hours}:
                  {timeToStart.minutes < 10
                    ? `0${timeToStart.minutes}`
                    : timeToStart.minutes}
                  :
                  {timeToStart.seconds < 10
                    ? `0${timeToStart.seconds}`
                    : timeToStart.seconds}
                </Timestamp>
              </Countdown>
            ) : (
              <Countdown>
                <Timestamp>Live Now</Timestamp>
              </Countdown>
            )}
            <div style={{ paddingTop: "8px" }}>
              <Button
                href={`${process.env.REACT_APP_SL_URL}/artist/${data.id}`}
              >
                View on Backstage
              </Button>
            </div>
          </Content>
        </Card>
      </Container>
    );
  }
  return null;
};

const Icon = styled(MusicIcon)`
  margin: 18px 18px 18px 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(36.28deg, #1b0076 11.38%, #e71e3d 135.84%);
  color: #fff;
  position: relative;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Small = styled.div`
  font-size: 12px;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
`;
const Time = styled.div`
  font-size: 17px;
`;

const Timestamp = styled.span`
  font-weight: 600;
  font-size: 17px;
`;

const Countdown = styled.div``;

const Button = styled.a`
  color: #1b0076;
  background: #fff;
  border-radius: 6px;
  display: inline-block;
  padding: 4px 8px;
  cursor: pointer;
  text-decoration: none;
`;
