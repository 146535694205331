import * as Shoutout from "@musicaudienceexchange/shoutout-interface";
import { ReactComponent as DeleteIcon } from "assets/svg/trash.svg";
import { IconButton } from "Components";
import { FlexColumn, FlexRow } from "Components/Flex";
import { Body2, H4 } from "melodies-source/Text";
import styled from "styled-components";

const Container = styled(FlexRow)`
  border: 1px solid #cccccc;
  border-radius: 6px;
  padding: 20px;
`;

const Avatar = styled.img`
  border-radius: 35px;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  object-fit: cover;
`;

interface ArtistGroupCardProps {
  artistGroup: Shoutout.firestore.artists.ArtistGroup;
  onDelete?: () => void;
}

export const ArtistGroupCard = ({
  artistGroup,
  onDelete,
}: ArtistGroupCardProps) => {
  const genre = (artistGroup as any).primaryGenre;

  return (
    <Container gap="15px" yCenter>
      <Avatar src={artistGroup.assets?.icon?.path} alt="" />
      <FlexColumn flex="1">
        <H4>{artistGroup.name}</H4>
        {genre && <Body2>{genre.join(", ")}</Body2>}
      </FlexColumn>
      <IconButton onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </Container>
  );
};
