import { MenuMode, useMenuContext } from "Components";
import styled, { css } from "styled-components";
import { AppLinks } from "./AppLinks";
import { ArtistGroup } from "./ArtistGroup";
import { NavLinks } from "./NavLinks";
import { Teammates } from "./Teammates";
import { AppSecondaryLinks } from "./AppSecondaryLinks";

export const SideBar = () => {
  const { menuMode } = useMenuContext();
  return (
    <Container menuMode={menuMode}>
      <ArtistGroup />
      <NavLinks />
      <AppLinks />
      <AppSecondaryLinks />
      {menuMode === "expanded" && <Teammates />}
    </Container>
  );
};

const Container = styled.div<{ menuMode: MenuMode }>(
  ({ menuMode }) => {
    return css`
      width: 25vw;
      flex-shrink: 0;
      box-shadow: inset -1px 0 0 0 var(--box-shadow-color);
      z-index: 15;
      background: #fff;
      transition: 300ms ease;

      ${({ theme }) => theme.media.desktop} {
        max-width: ${menuMode === "expanded" ? "350px" : "85px"};
        min-width: ${menuMode === "expanded" ? "270px" : "85px"};
      }
      ${({ theme }) => theme.media.mobile} {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: ${menuMode === "expanded" ? "" : "translateX(-100%)"};
        transition: transform ease-in-out 100ms;
      }

      @media (min-width: 1120px) {
        position: unset;
        transform: none;
        //max-width: 22%;
      }
    `;
  },
  ({ theme }) => {
    if (!theme.custom) return null;
    const { background, color } = theme.custom.layout.sideBar;
    return css`
      background: ${background};
      color: ${color};
    `;
  },
);
