import { SurveyTemplate } from "Routes/SetFan/dashboard/Components/Templates/types";
import { ReactComponent as IconVenue } from "assets/svg/venue.svg";
import { getContestDefaults } from "../helpers";

export const whichVenue: SurveyTemplate = {
  icon: <IconVenue />,
  title: "What Venue Should We Play?",
  description:
    "Learn where your fans are and where they would like you to play and why.",
  templateId: "whichVenue",
  template: (v, formState) => ({
    fields: {
      description: {
        content: { en: "Which Venue Survey" },
      },
      header: {
        content: { en: "Where Should We Play?" },
      },
      body: {
        content: {
          en: "We're excited to hit the road again and want to know where you think we should be performing. No one knows your area better than you, and so we'd love your feedback.",
        },
      },
      displayedCategories: {
        content: [
          "demographics",
          "fooddrink",
          "alcohol",
          "clothing",
          "vehicle",
          "entertainment",
        ],
      },
      demographicsIntroText: {
        content: {
          en: "We'd love to know a bit more about you to help plan how to get the word out about the tour.",
        },
      },
      fooddrinkIntroText: {
        content: {
          en: "A food or drink sponsor could help us make the tour even better. Help us find one that would be a good fit for this tour.",
        },
      },
      alcoholIntroText: {
        content: {
          en: "Please share a bit about what you like to drink. Your feedback could help us find a sponsor for the tour.",
        },
      },
      clothingIntroText: {
        content: {
          en: "Working with a fashion brand as a sponsor could help make our tour even better, but we want to find one that would be a good fit with our fans.",
        },
      },
      vehicleIntroText: {
        content: {
          en: "Your feedback about what you'd like to drive can help us work with a vehicle brand to make the next tour even better.",
        },
      },
      entertainmentIntroText: {
        content: {
          en: "Please take a minute tell us what you like in your free time .Your insights can help us collaborate with tech and entertainment brands that you love, adding an extra spark to our shows.",
        },
      },
      thankYouHeader: {
        content: { en: "Thanks For Your Feedback" },
      },
      thankYouBody: {
        content: {
          en: "We appreciate you taking the time to help us plan our next tour!",
        },
      },
      ...getContestDefaults(formState),
    },
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
    surveyQuestions: [
      {
        type: "TextQuestion",
        label: { en: "Which venue in your area should we play?" },
        required: true,
        multiline: false,
        variants: { mobile: "Headline4", desktop: "Headline3" },
      },
      {
        type: "MultipleSelectQuestion",
        label: { en: "Why should we play there? Choose all that apply" },
        options: [
          { label: { en: "It's close to me" } },
          { label: { en: "Great sound" } },
          { label: { en: "Beautiful venue" } },
          { label: { en: "Not a bad seat in the house" } },
          { label: { en: "Amazing history" } },
          { label: { en: "It's the right size" } },
          { userDefined: true, label: { en: "Other" } },
        ],
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-tour---whi-62de57"
        : "pp-tour---whi-bc222d",
    fields: [
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
