import { ReactComponent as IconShirtStar } from "assets/svg/shirt-star.svg";
import { SurveyTemplate } from "../../types";
import { getContestDefaults } from "../helpers";

export const rateNewDesign: SurveyTemplate = {
  icon: <IconShirtStar />,
  title: "Rate the New Design",
  description:
    "Spread the word about the latest design by asking fans to rate it.",
  templateId: "rateNewDesign",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: "Rate the New Design",
        },
      },
      ...getContestDefaults(formState),
    },
    surveyQuestions: [
      {
        type: "image",
        actionRequired: true,
        showLabels: false,
        required: false,
        src: "",
      },
      {
        type: "RankedQuestion",
        id: "question_1",
        rankRange: 5,
        rankHigh: {
          en: "Love It",
        },
        rankLow: {
          en: "Hate It",
        },
      },
    ],
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-merch---ra-b2513b"
        : "pp-merch---ra-d0b4aa",
    fields: [
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
