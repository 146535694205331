import { IconQRCode } from "Components";
import { SettingsRow } from "../";
import { useDialog } from "Components/DialogProvider";
import { Link } from "Components/Link";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const ArtistQrCode = () => {
  const { setDialog } = useDialog();
  const { language } = useCustomAppContext();

  return (
    <SettingsRow
      title={`${language.event.owner.singular} QR Code`}
      description={`Your QR Code is always the same and will direct people to your next upcoming ${language.event.type.singular.toLowerCase()}, regardless of their location settings.`}
    >
      <div style={{ display: "flex" }}>
        <Link
          style={{ marginTop: 25, marginBottom: 7 }}
          onClick={() => setDialog({ qrCode: true })}
        >
          <IconQRCode style={{ marginRight: 12 }} />
          {language.event.owner.singular} QR Code
        </Link>
      </div>
    </SettingsRow>
  );
};
