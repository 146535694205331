import React, { useEffect, useRef, useState } from "react";
import { Card } from "melodies-source/Card";
import { Body1, Body2, H2, H4, Label } from "melodies-source/Text";
import styled from "styled-components";
import { TextInput } from "melodies-source/TextInput";
import { Checkbox } from "melodies-source/Selectable";
import { Button } from "melodies-source/Button";
import { ReactComponent as ContactInfoIcon } from "assets/svg/contact-info.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";
import * as yup from "yup";
import { yupRequired } from "Utils/yup";
import { FormikConfig, useFormik } from "formik";
import { StepProps } from "..";
import { useDocumentData } from "react-firebase-hooks/firestore";
import {
  DocumentReference,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { useUser } from "auth";
import { Profile } from "@max/common/src/user";
import { Spinner } from "melodies-source/Spinner";
import { useHistory } from "react-router-dom";
import { useCustomAppContext } from "contexts/CustomAppContext";

const INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  companyName: "",
  title: "",
  agreeTerms: false,
};

const VALIDATION_SCHEMA = yup.object().shape({
  firstName: yupRequired,
  lastName: yupRequired,
  companyName: yupRequired,
  title: yupRequired,
  agreeTerms: yup.boolean().oneOf([true], "You must agree to policies"),
});

export const ContactInfo: React.FC<StepProps> = ({ handleNext }) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: hanldeContactSave,
  });
  const { user } = useUser();
  const [profile, profileLoading] = useDocumentData(
    doc(getFirestore(), `profiles/${user.uid}`) as DocumentReference<Profile>,
  );
  const formButtonRef = useRef<HTMLButtonElement>(null);
  const history = useHistory();
  const { customApp } = useCustomAppContext();

  if (profile && !profile.shouldOnboard) {
    history.push("/");
  }

  async function hanldeContactSave(
    ...params: Parameters<FormikConfig<typeof INITIAL_VALUES>["onSubmit"]>
  ) {
    const [{ firstName, lastName, title, companyName }] = params;

    setLoading(true);
    try {
      await setDoc(
        doc(getFirestore(), `profiles/${user.uid}`),
        {
          name: {
            firstName,
            lastName,
          },
          companyName,
          title,
        },
        {
          merge: true,
        },
      );
      handleNext();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (profile) {
      const { name, companyName, title } = profile;
      formik.setValues({
        firstName: name.firstName,
        lastName: name.lastName,
        companyName,
        title,
        agreeTerms: false,
      });
    }
  }, [profileLoading]);

  return (
    <StyledCard isElevated>
      <Header>
        <ContactInfoIcon />
        Contact Info
      </Header>
      <Content>
        <div>
          <H4>Please enter your contact information</H4>
          <Body1>Your email address will also be your username.</Body1>
        </div>
        {profileLoading ? (
          <Spinner />
        ) : (
          <Form onSubmit={formik.handleSubmit}>
            <Name>
              <Label>Your Name</Label>
              <div>
                <TextInput
                  placeholder="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange("firstName")}
                  {...(formik.errors.firstName && {
                    hasError: true,
                    helperText: formik.errors.firstName,
                  })}
                />
                <TextInput
                  placeholder="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange("lastName")}
                  {...(formik.errors.lastName && {
                    hasError: true,
                    helperText: formik.errors.lastName,
                  })}
                />
              </div>
            </Name>
            <>
              <TextInput
                label="Company Name"
                placeholder="Please enter your company name"
                value={formik.values.companyName}
                onChange={formik.handleChange("companyName")}
                {...(formik.errors.companyName && {
                  hasError: true,
                  helperText: formik.errors.companyName,
                })}
              />
              <TextInput
                label="Your Title"
                placeholder="Please enter your title"
                value={formik.values.title}
                onChange={formik.handleChange("title")}
                {...(formik.errors.title && {
                  hasError: true,
                  helperText: formik.errors.title,
                })}
              />
            </>
            <div>
              <Label>Your Email</Label>
              <Email>{profile?.verifiedEmail}</Email>
            </div>
            <TermsCheckbox
              label={
                <Body2>
                  By checking this box, I agree to the{" "}
                  <a
                    href={customApp?.privacyPolicyUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {customApp?.company.name} Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href={`${process.env.REACT_APP_SL_URL}/privacy`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    MAX Privacy Policy
                  </a>
                </Body2>
              }
              value={formik.values.agreeTerms}
              onChange={(value) => formik.setFieldValue("agreeTerms", value)}
              {...(formik.errors.agreeTerms && {
                hasError: true,
                helperText: formik.errors.agreeTerms,
              })}
            />
            <FormButton ref={formButtonRef} type="submit"></FormButton>
          </Form>
        )}
      </Content>
      <Actions>
        <Body1>
          Questions or issues?{" "}
          <a href="mailto:support@gtbevents.live">Contact GTB</a>
        </Body1>
        <Button
          rightIcon={<ArrowRightIcon />}
          loading={loading}
          onClick={() => formButtonRef.current.click()}
        >
          Next
        </Button>
      </Actions>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: min(900px, 90vw);

  a {
    color: var(--main-color);
  }
`;

const Header = styled(H2)`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  svg {
    margin-right: 5px;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 20px;

  & > div {
    width: 50%;
    flex-shrink: 0;
  }

  ${H4} {
    margin-bottom: 20px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Name = styled.div`
  & > div {
    display: flex;

    & > div:nth-child(1) input {
      border-radius: 6px 0 0 6px;
    }

    & > div:nth-child(2) input {
      border-radius: 0 6px 6px 0;
      border-left: none;
    }
  }

  ${Label} {
    margin-bottom: 5px;
  }
`;

const Email = styled(Body2)`
  font-size: 14px;
  color: var(--secondary-text-color);
`;

const TermsCheckbox = styled(Checkbox)`
  margin-top: 15px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

const FormButton = styled.button`
  display: none;
`;
