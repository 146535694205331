import * as React from "react";
import { SVGProps } from "react";
export const SvgRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2028994,3.20970461 L10.2971068,3.29289322 L17.0042136,10 L10.2971068,16.7071068 C9.90658249,17.0976311 9.27341751,17.0976311 8.88289322,16.7071068 C8.52240926,16.3466228 8.49467972,15.7793918 8.79970461,15.3871006 L8.88289322,15.2928932 L13.174,11 L3,11 L3,9 L13.176,9 L8.88289322,4.70710678 C8.52240926,4.34662282 8.49467972,3.77939176 8.79970461,3.38710056 L8.88289322,3.29289322 C9.24337718,2.93240926 9.81060824,2.90467972 10.2028994,3.20970461 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
