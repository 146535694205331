import { ReactComponent as IconFavorite } from "assets/svg/favorite.svg";
import { SurveyTemplate } from "../../types";
import { getContestDefaults } from "../helpers";

export const favoriteArtists: SurveyTemplate = {
  icon: <IconFavorite />,
  title: "Favorite Artists",
  description: "Learn which artists' fans you should be marketing to.",
  templateId: "favoriteArtists",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: "Favorite Artists",
        },
      },
      ...getContestDefaults(formState),
    },
    ...(formState.hasSweeps === "Yes" && {
      flags: {
        showContestConfirmationMessage: true,
      },
    }),
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes" ? "pp-marketing-6db77a" : "pp-marketing-0d3a6f",
    fields: [
      {
        name: "hasSweeps",
        type: "radio",
        label: "Will you be offering a sweepstakes prize?",
        options: ["Yes", "No"],
        getValidation: (fields) =>
          !!fields.hasSweeps ? false : "You must select an option.",
      },
      {
        name: "prize",
        type: "text",
        label: "What's the prize?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.prize || fields.hasSweeps === "No"
            ? false
            : "This field cannot be left blank",
        isVisible: (form) => form.hasSweeps === "Yes",
      },
    ],
  },
};
