import * as React from "react";
import { SVGProps } from "react";
export const SearchSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5,3 C11.5375661,3 14,5.46243388 14,8.5 C14,9.83736495 13.5226763,11.063245 12.7290987,12.0165704 L17,16.2882835 L16.2928935,16.9953903 L12.0216459,12.7248686 C11.0675979,13.5209798 9.83973953,14 8.5,14 C5.46243388,14 3,11.5375661 3,8.5 C3,5.46243388 5.46243388,3 8.5,3 Z M8.5,4 C6.01471863,4 4,6.01471863 4,8.5 C4,10.9852814 6.01471863,13 8.5,13 C10.9852814,13 13,10.9852814 13,8.5 C13,6.01471863 10.9852814,4 8.5,4 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
