import styled from "styled-components";

export const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;

  &:hover {
    background-color: var(--hover-background-color);
    cursor: pointer;
  }
`;
