import { RouteComponentProps } from "react-router-dom";
import { Mailchimp } from "./Components";

type Props = RouteComponentProps<{ platform: string }>;

export const OauthCallback = ({ match }: Props) => {
  const platform = match.params.platform;

  if (platform === "mailchimp") {
    return <Mailchimp />;
  }

  return <h1>No matching platform</h1>;
};
