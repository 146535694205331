import styled, { css } from "styled-components";
import { LinkText } from "../Text";

type LinkProps = {
  disabled?: boolean;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
};

export const Link = styled(LinkText).attrs({ as: "a" })<LinkProps>`
  cursor: pointer;
  color: var(--main-color);
  transition: color 150ms cubic-bezier(0, 0, 0.3, 1);

  &:hover {
    text-decoration: underline;
    color: #7666ad;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: #999999;
      pointer-events: none;
      cursor: default;
    `}
`;
