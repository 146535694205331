import { FlexColumn, FlexRow } from "Components/Flex";
import { ConcertData } from "hooks/useUpcomingConcerts";
import { Button } from "melodies-source/Button";
import { Body1 } from "melodies-source/Text";
import { ConcertCard } from "./ConcertCard";
import { BuilderEventData } from "./hooks/useSetLiveBuilderEvents";

interface DeleteConcertProps {
  concert: ConcertData;
  event?: BuilderEventData;
  loading?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
}

export const DeleteConcert = ({
  concert,
  event,
  loading,
  onCancel,
  onSubmit,
}: DeleteConcertProps) => {
  return (
    <FlexColumn gap="20px">
      <Body1>Are you sure you want to delete this show?</Body1>
      <ConcertCard concert={concert} event={event} />
      <FlexRow gap="20px" xEnd>
        <Button variant="tertiary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onSubmit} loading={loading}>
          Delete
        </Button>
      </FlexRow>
    </FlexColumn>
  );
};
