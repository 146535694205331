import {
  typedFields,
  useConfigSlice,
} from "Routes/SetFan/Components/BuilderContext";
import { TextInput } from "melodies-source/TextInput";
import { Textarea } from "melodies-source/Textarea";
import styled from "styled-components";
import {
  SurveyInputList,
  SurveyInputListItem,
  SurveyInputHelpText,
} from "../Components/SurveyInputList";
import { Divider } from "Components";
import { H2 } from "melodies-source/Text";

interface SurveyInfoContentProps {
  isWizard?: boolean;
}

export const INFO_FIELDS = typedFields(["description", "header", "body"]);

export const SurveyInfoContent = ({ isWizard }: SurveyInfoContentProps) => {
  const { fields, validation, setField, setFieldTouched } =
    useConfigSlice(INFO_FIELDS);

  return (
    <SurveyInputList>
      <SurveyInputListItem>
        <TextInput
          label="Internal Description"
          helperText={
            (fields.description.isTouched && validation.description) || ""
          }
          hasError={fields.description.isTouched && !!validation.description}
          value={fields.description.content.en}
          onChange={(text) => setField("description", { en: text })}
          onBlur={() => setFieldTouched("description")}
        />
        <SurveyInputHelpText>
          A short description used in the SET interface to identify this survey.
          Visible only to your team, NOT to fans.
        </SurveyInputHelpText>
      </SurveyInputListItem>
      <Divider />
      {isWizard && (
        <H2 style={{ color: "#333", marginBottom: 12 }}>Survey Info</H2>
      )}
      <SurveyInputListItem>
        <TextInput
          label="Header"
          value={fields.header.content.en}
          helperText={(fields.header.isTouched && validation.header) || ""}
          hasError={fields.header.isTouched && !!validation.header}
          onChange={(text) => setField("header", { en: text })}
          onBlur={() => setFieldTouched("header")}
        />
        <SurveyInputHelpText>
          Capture fans' attention with a concise Header, conveying the survey's
          purpose and objectives.
        </SurveyInputHelpText>
      </SurveyInputListItem>
      <SurveyInputListItem>
        <SurveyBodyTextArea
          label="Body"
          value={fields.body.content.en}
          helperText={(fields.body.isTouched && validation.body) || ""}
          hasError={fields.body.isTouched && !!validation.body}
          onChange={(text) => setField("body", { en: text })}
          onBlur={() => setFieldTouched("body")}
        />
        <SurveyInputHelpText>
          Use Body text to expand on the purpose of your survey, or to let fans
          know about a sweepstakes you are promoting.
        </SurveyInputHelpText>
      </SurveyInputListItem>
    </SurveyInputList>
  );
};

const SurveyBodyTextArea = styled(Textarea)`
  textarea {
    height: 137px;
    font-size: 15px;
    line-height: 22px;
    font-family: Poppins;
    color: #333;
  }
`;
