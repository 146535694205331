import { appTheme } from "App";
import { useArtistContext } from "Components";
import { DonutChart } from "Components/DonutChart";
import { theme } from "melodies-source/Theme";
import { useHistory, useParams } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

export const AudienceInsights = () => {
  const { privateData } = useArtistContext();
  const songs = privateData?.data()?.settheset?.songs?.map(({ name }) => name);
  const link = privateData?.data()?.settheset?.report;
  const history = useHistory();
  let genderData = privateData?.data()?.gender?.map(({ label, value }) => ({
    label,
    value,
    id: label,
  }));
  const { artistId } = useParams<any>();

  let ageData = privateData?.data()?.ageGroups?.map(({ label, value }) => ({
    label,
    value,
    id: label,
  }));
  let hasData = true;
  if (!genderData && !ageData) {
    hasData = false;
    genderData = sampleGenderData;
    ageData = sampleAgeData;
  }

  return (
    <ThemeProvider theme={{ ...appTheme, ...theme }}>
      <Container>
        <Left>
          <Header>Audience Insights</Header>
          <Charts overlay={!hasData}>
            {genderData && <GenderChart data={genderData} />}
            {ageData && <AgeChart data={ageData} />}
          </Charts>
          {!hasData && (
            <>
              <InteractionBlocker />
              <Sample>
                <SampleHead>Discover who your fans are</SampleHead>
                Engage fans through SET.Live and Set the Set and you'll
                automatically receive insights including demographic breakdowns
                and maps of where your fans are
                <div style={{ paddingTop: "20px" }}>
                  <Button
                    onClick={() =>
                      window.open(process.env.REACT_APP_ABOUT_URL, "_blank")
                    }
                  >
                    Get Started
                  </Button>
                </div>
              </Sample>
            </>
          )}
        </Left>
        <Right>
          <Header>Most Requested Songs</Header>
          <Songs>
            {songs?.map((song, idx) => (
              <Song style={{ fontWeight: idx === 0 ? 600 : 400 }}>
                {idx < 9 && "0"}
                {idx + 1}. {song}
              </Song>
            ))}
            {!songs?.length && (
              <>
                Learn which songs your fans want to hear on your next tour and
                drive ticket sales.
                <br />
                <br />
                Set the Set allows fans to build their dream setlist and for
                artists to discover who will be attending your shows. All the
                insights will flow into the SET reporting platform and your fan
                directory.
              </>
            )}
          </Songs>
          {link ? (
            <Button onClick={() => history.push(`/${artistId}/sts-report`)}>
              More Details
            </Button>
          ) : (
            <Button
              onClick={() =>
                window.open(process.env.REACT_APP_ABOUT_URL, "_blank")
              }
            >
              Get Started
            </Button>
          )}
        </Right>
      </Container>
    </ThemeProvider>
  );
};

const GenderChart = ({ data }) => {
  return (
    <ChartContainer>
      <LegendHeader>Gender</LegendHeader>
      <DonutChart data={data} />
    </ChartContainer>
  );
};

const AgeChart = ({ data }) => {
  return (
    <ChartContainer>
      <LegendHeader>Age</LegendHeader>
      <DonutChart data={data} />
    </ChartContainer>
  );
};

const Container = styled.div`
  margin: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  flex-wrap: wrap;
  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    margin: 20px;
    justify-content: space-between;
  }
`;

const Left = styled.div`
  position: relative;
  padding: 15px;
  flex-grow: 1;
  @media only screen and (min-width: 1300px) {
    padding: 32px 60px;
    max-width: 70%;
  }
`;

const Charts = styled.div<{ overlay: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
  }
  ${({ overlay }) =>
    overlay &&
    `
    filter: blur(2px) opacity(30%);

  `}
`;

const Header = styled.h1`
  color: #000000;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
`;

const LegendHeader = styled.p`
  color: #000000;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 36px;
`;

const ChartContainer = styled.div`
  padding: 14px 0 0 0;
  @media only screen and (min-width: 896px) {
    padding: 24px;
  }
`;

const Right = styled.div`
  background-color: #ccf2e1;
  padding: 36px 50px;
  flex-grow: 1;
  min-width: 30%;
  border-radius: 0px 0px 12px 12px;
  @media only screen and (min-width: 1300px) {
    max-width: 30%;
    justify-self: flex-end;
    border-radius: 0px 12px 12px 0px;
  }
`;

const Songs = styled.div`
  padding: 32px 0 56px 0;
`;

const Song = styled.p`
  color: #333333;
  font-family: Poppins;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 23px;
  margin-bottom: 8px;
`;

const Button = styled.button`
  border: 1px solid #333333;
  background: none;
  border-radius: 24px;
  color: #333333;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  padding: 10px 36px;
  cursor: pointer;
  &:hover {
    background: #000;
    color: #fff;
  }
`;

const Sample = styled.div`
  color: #333333;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 23px;
  position: absolute;
  top: 50%;
  left: 16px;
  right: 16px;
  text-align: center;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-shadow: 0 0 20px 20px rgba(255, 255, 255, 0.5);
  @media only screen and (min-width: 896px) {
    font-size: 18px;
    left: 20%;
    right: 20%;
  }
`;

const SampleHead = styled.h1`
  color: #333333;
  font-family: Poppins;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 23px;
  margin-bottom: 14px;
`;

const InteractionBlocker = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const sampleAgeData = [
  {
    label: "13 - 17 years old",
    value: 36,
    id: "13 - 17 years old",
  },
  {
    label: "18 - 29 years old",
    value: 469,
    id: "18 - 29 years old",
  },
  {
    label: "30 - 44 years old",
    value: 487,
    id: "30 - 44 years old",
  },
  {
    label: "35 - 60 years old",
    value: 408,
    id: "35 - 60 years old",
  },
  {
    label: "Over 60  years old",
    value: 30,
    id: "Over 60  years old",
  },
];

const sampleGenderData = [
  {
    label: "Male",
    value: 444,
    id: "Male",
  },
  {
    label: "Female",
    value: 663,
    id: "Female",
  },
  {
    label: "Other",
    value: 35,
    id: "Other",
  },
];
