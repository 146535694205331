import { Colors } from "custom/types";
import { Routes } from "./Routes";
import { UserMenuOptions } from "./Components/UserMenuOptions";
import { GtbAffiliationMap } from "@max/common/dist/thirdparty/gtb";
import { GtbCustomApp } from "./types";

const colors: Colors = {
  main: "#00b7ec",
  secondary: "#f36c23",
  tertiary: "#ee0c6d",
  background: "black",
  text: "#eeeeee",
  headerText: "white",
  secondaryText: "#cdcdcd",
  primaryButtonText: "black",
  primaryButtonHover: "#33c5f0",
  componentBackground: "#20222a",
  border: "#666666",
  inputBorder: "#eeeeee",
  hoverBackground: "#070a12",
  boxShadow: "rgba(0,0,0,0.4)",
  selected: "#b94100",
  contentBackground: "#070a12",
  disabled: "#565656",
  spinner: "white",
  liveEventCardBackground: "#070a12",
};

const isDev = process.env.NODE_ENV === "development";

export const gtb: GtbCustomApp = {
  app: { name: "SPONSORSHIP PROGRAMS" },
  company: {
    logo: {
      url: "https://storage.googleapis.com/set-live-stage.appspot.com/oie_8fFIqntHEOtt.png",
      description: "gtb",
    },
    name: "GTB",
  },
  event: {
    owner: {
      singular: "Program",
      plural: "Programs",
      singularWithArticle: "a Program",
    },
    type: {
      singular: "Event",
      plural: "Events",
      singularWithArticle: "an Event",
    },
    audience: {
      singular: "Attendee",
      plural: "Attendees",
      singularWithArticle: "an Attendee",
    },
  },
  routes: {
    paths: ["/", "/regions/:regionId"],
    Component: Routes,
  },
  UserMenuOptions,
  footerLinks: [
    { text: "Ford Privacy Policy", url: "https://www.ford.com/help/privacy" },
    { text: "MAX Privacy Policy", url: "https://www.max.live/privacy-policy" },
  ],
  fanAppUrl: `https://${isDev ? "stage." : ""}fordevents.live`,
  onsiteAppUrl: `https://onsite.${isDev ? "stage." : ""}fordevents.live`,
  privacyPolicyUrl: "https://www.ford.com/help/privacy",
  userRoles: GtbAffiliationMap,
  regionsCollection: "gtb_regions",
  menu: {
    sections: {
      engagement: {
        header: "User Engagement",
        items: {
          live: {
            label: "GTB.Live",
            icon: "",
          },
        },
      },
    },
  },
  theme: {
    colors,
    layout: {
      menuBar: {
        background: "black",
        color: "white",
      },
      sideBar: {
        background: colors.componentBackground,
        color: "white",
        active: {
          background: colors.main,
          color: "#20222a",
        },
        sectionHeader: {
          color: "white",
        },
      },
      content: {
        background: colors.contentBackground,
        backgroundHeader: {
          background: "linear-gradient(45deg, #71073D 3.53%, #0E1D31 120.81%);",
        },
      },
      footer: {
        background: "black",
        color: "white",
      },
      boxShadowColor: colors.border,
    },
    pages: {
      dashboard: {
        cards: {
          nextShow: {
            background: "#ec106f",
            color: "white",
          },
          fanContacts: {
            background: "#00b7ec",
            color: "white",
          },
          fanProfile: {
            background: "gray",
            color: "white",
          },
          shows: {
            background:
              "linear-gradient(319deg, #ec106b 2.92%, #f26a25 90.73%)",
          },
        },
      },
      contacts: {
        table: {
          row: {
            hover: {
              background: "#2e2e2e",
            },
            border: { color: "#4e4e4e" },
          },
        },
        noContacts: {
          background: colors.componentBackground,
          icon: {
            color: colors.main,
          },
        },
      },
      reporting: {
        setlive: {
          fanReportCard: {
            background: colors.contentBackground,
          },
        },
      },
      account: {
        header: {
          background:
            "linear-gradient(180deg, #EC106B 0%, #F26A25 100%), #20222A",
        },
      },
      region: {
        cards: {
          contactsTotal: {
            background:
              "linear-gradient(0deg, #e90172 0%, #5ab7c1 119.16%), #20222a",
            color: "white",
          },
          contactsLast30Days: {
            background:
              "linear-gradient(180deg, #ec106b 0%, #f26a25 100%), #20222a",
            color: "white",
          },
        },
      },
    },
    charts: {
      bar: {
        bar: { background: "#5d5d5d" },
      },
      donut: {
        colors: [
          "#00b7ec",
          "#f36c23",
          "#ed266d",
          "#80dbf6",
          "#f9b691",
          "#f693b6",
          "#005c76",
          "#7a3612",
          "#771337",
        ],
      },
    },
    icons: {
      mapPin: {
        background: colors.contentBackground,
      },
      calendar: {
        background: colors.contentBackground,
      },
    },
  },
};
