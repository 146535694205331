import {
  Modal as ModalBase,
  ModalHeaderContainer,
} from "melodies-source/Modal";
import { Body1, H2 } from "melodies-source/Text";
import { LoadingState } from "./LoadingState";
import styled from "styled-components";
import { Form } from "./Form";
import { useState } from "react";
import { FlexRow } from "Routes/styled";
import { Button } from "melodies-source/Button";
import { useTemplates } from "../TemplateContext";
import { usePrompt } from "./usePrompt";
import { useArtistContext } from "Components";
import { Alert } from "melodies-source/Alert";
import { PromptFormState } from "../defaults/types";

interface PromptModalProps {
  onBack: VoidFunction;
}

export const AiPromptModal = ({ onBack }: PromptModalProps) => {
  const [aiPromptLoading, setAiPromptLoading] = useState(false);
  const [aiError, setAiError] = useState(false);
  const { name: artist } = useArtistContext();
  const {
    selectedTemplate,
    modal,
    isLoading: createLoading,
    handleCreate,
    createError,
  } = useTemplates();
  const error = aiError || createError;
  const { prompt } = selectedTemplate ?? {};
  const isLoading = aiPromptLoading || createLoading;
  const { request } = usePrompt();

  const handleSubmit = async (formState: PromptFormState) => {
    if (!prompt.promptId || !formState) {
      return;
    }
    const promptId = prompt.promptId(formState);
    setAiPromptLoading(true);
    try {
      formState.artist = artist;
      const partialTemplate = await request({
        templateId: promptId,
        values: formState,
      });

      await handleCreate({ config: partialTemplate, formState });
    } catch (error) {
      console.error(error);
      setAiError(true);
    } finally {
      setAiPromptLoading(false);
    }
  };

  return (
    <Modal
      header={<H2>{isLoading ? "Almost there..." : "Additional Details"}</H2>}
      isOpen={modal.aiPrompt}
    >
      {isLoading ? (
        <LoadingState message="Thanks for your patience. Behind the scenes we are using your responses to intelligently build a custom survey that will support your needs." />
      ) : (
        <>
          <Body1 style={{ color: "#666", marginTop: 4, marginBottom: 12 }}>
            Please share additional details for a customized SET.Fan experience:
          </Body1>
          <Form
            fields={prompt?.fields}
            onSubmit={handleSubmit}
            style={{ marginTop: 12 }}
          >
            <FlexRow
              style={{ justifyContent: "flex-end", marginTop: 32, gap: 20 }}
            >
              <Button variant="tertiary" onClick={() => onBack()}>
                Back
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  if (error) {
                    setAiError(false);
                  }
                }}
              >
                Create My Draft
              </Button>
            </FlexRow>
          </Form>
        </>
      )}
    </Modal>
  );
};

const Modal = styled(ModalBase)`
  ${ModalHeaderContainer} {
    margin-bottom: 0;
  }
`;
