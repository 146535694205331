import { ReactComponent as EmailIcon } from "assets/svg/mail.svg";
import { useArtistContext } from "Components";
import { FlexColumn, FlexRow } from "Components/Flex";
import { useDesktopMediaQuery } from "hooks";
import { SvgDelete } from "melodies-source/Svgs/Delete";
import { SvgIndividual } from "melodies-source/Svgs/Individual";
import { Body1, Body2 } from "melodies-source/Text";
import styled, { css } from "styled-components";
import { Member } from ".";
import { DeleteInvitation } from "./DeleteInvitation";
import { ResendInvitation } from "./ResendInvitation";
import { ReviewAccessRequest } from "./ReviewAccessRequest";
import { TeammateDelete } from "./TeammateDelete";
import { TeammateRoleSelect } from "./TeammateRoleSelect";

const DeleteIcon = styled.div(
  ({ theme: { colors } }) => css`
    height: 30px;
    width: 30px;
    margin-left: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      cursor: pointer;
      background: var(--hover-backgorund-color);
      border-radius: 100%;
    }
    svg {
      color: var(--main-color);
    }
  `,
);

interface Props {
  member: Member;
  adminCount: number;
}

export const Teammate: React.FC<Props> = ({ member, adminCount }) => {
  const { name, email, verified, status, action } = member;
  const isDesktop = useDesktopMediaQuery(1200);
  const { isAdmin } = useArtistContext();
  const isInvite = action === "add-user" && status === "incomplete";

  return (
    <Container>
      {!action || action === "request-access" ? (
        <SvgIndividual
          style={{
            color: "var(--main-color)",
            alignSelf: "baseline",
            marginRight: 2,
            marginTop: 2,
          }}
        />
      ) : (
        <EmailIcon
          style={{
            alignSelf: "baseline",
            marginTop: 6,
            marginRight: 8,
            marginLeft: 3,
          }}
        />
      )}

      <FlexColumn flex="1">
        {(!action || action === "request-access") && (
          <Body1>
            {name || (email.length > 20 ? `${email.slice(0, 20)}...` : email)}
          </Body1>
        )}
        <Body2>{email}</Body2>
        {!isDesktop && status === "approved" && (
          <TeammateRoleSelect member={member} adminCount={adminCount} />
        )}
        {!isDesktop && isAdmin && isInvite && (
          <FlexRow xEnd gap="10px">
            <ResendInvitation member={member} />
            <DeleteInvitation member={member} />
          </FlexRow>
        )}
      </FlexColumn>

      {isDesktop && status === "approved" && (
        <TeammateRoleSelect member={member} adminCount={adminCount} />
      )}
      {isAdmin && (
        <>
          {status === "approved" && (
            <TeammateDelete
              target={(props) => (
                <DeleteIcon>
                  <SvgDelete {...props} />
                </DeleteIcon>
              )}
              member={member}
            />
          )}
          {isDesktop && isInvite && (
            <FlexRow gap="24px">
              <ResendInvitation member={member} />
              <DeleteInvitation member={member} />
            </FlexRow>
          )}
          {status === "submitted" && <ReviewAccessRequest member={member} />}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 8px 10px;
  display: flex;
  align-items: center;
  border-radius: 0px;
  border-bottom: 1px solid var(--border-color);
`;
