import {
  doc,
  DocumentReference,
  getDoc,
  getFirestore,
  setDoc
} from "firebase/firestore";
import { BuilderEventData } from "./useSetLiveBuilderEvents";

export const updateBuilderEventFields = async (
  nextConcertRef: DocumentReference,
  event: BuilderEventData,
) => {
  const nextConcert = await getDoc(nextConcertRef);

  await setDoc(
    doc(getFirestore(), `${event.ref.path}/versions/${event.draftId}`),
    {
      fields: {
        concert: {
          content: nextConcert.data(),
        },
        startTime: {
          content: nextConcert.get("date"),
        },
      },
    },
    {
      merge: true,
    },
  );
};
