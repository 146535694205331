import * as React from "react";
import { SVGProps } from "react";
export const SvgPreferencesLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11,15 C11.8284271,15 12.5,15.6715729 12.5,16.5 C12.5,17.3284271 11.8284271,18 11,18 C10.1715729,18 9.5,17.3284271 9.5,16.5 C9.5,15.6715729 10.1715729,15 11,15 Z M8.55011858,15.9994607 C8.51725346,16.1611829 8.5,16.3285766 8.5,16.5 C8.5,16.6713864 8.51724601,16.8387448 8.55011858,17.0004345 L4,17 L4,16 L8.55011858,15.9994607 Z M13.4498814,15.9994607 L20,16 L20,17 L13.4498814,17.0004345 C13.482754,16.8387448 13.5,16.6713864 13.5,16.5 C13.5,16.3285766 13.4827465,16.1611829 13.4498814,15.9994607 Z M5.5,10.5 C6.32842712,10.5 7,11.1715729 7,12 C7,12.8284271 6.32842712,13.5 5.5,13.5 C4.67157288,13.5 4,12.8284271 4,12 C4,11.1715729 4.67157288,10.5 5.5,10.5 Z M7.94988142,11.4994607 L20,11.5 L20,12.5 L7.94988142,12.5004345 C7.98275399,12.3387448 8,12.1713864 8,12 C8,11.8285766 7.98274654,11.6611829 7.94988142,11.4994607 Z M15.5,6 C16.3284271,6 17,6.67157288 17,7.5 C17,8.32842712 16.3284271,9 15.5,9 C14.6715729,9 14,8.32842712 14,7.5 C14,6.67157288 14.6715729,6 15.5,6 Z M13.0501186,6.99946074 C13.0172535,7.16118287 13,7.32857655 13,7.5 C13,7.67138643 13.017246,7.83874484 13.0501186,8.00043449 L4,8 L4,7 L13.0501186,6.99946074 Z M20,7 L20,8 L17.9499027,8.00043449 C17.982754,7.83874484 18,7.67138643 18,7.5 C18,7.32857655 17.9827465,7.16118287 17.9498814,6.99946074 L20,7 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
